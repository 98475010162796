<h1 *ngIf="is_offline === false">Заходили в систему</h1>
<h1 *ngIf="is_offline === true">Никогда не заходили в систему</h1>
<h1 *ngIf="is_offline === null">Все сотрудники</h1>
<app-simple-table>
  <app-simple-table-th>№ п/п</app-simple-table-th>
  <app-simple-table-th>ФИО сотрудника</app-simple-table-th>
  <app-simple-table-th>Телефон сотрудника</app-simple-table-th>
  <app-simple-table-th>E-mail сотрудника</app-simple-table-th>
  <!-- // -->
  <app-simple-table-tr *ngFor="let row of staff; index as i">
    <td>{{i+1}}</td>
    <td>{{row['physic_fio']}}</td>
    <td>{{row['physic_phone']}}</td>
    <td>{{row['physic_email']}}</td>
  </app-simple-table-tr>
</app-simple-table>
