import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-reserv-lead-info',
  templateUrl: './reserv-lead-info.component.html',
  styleUrls: ['./reserv-lead-info.component.scss']
})
export class ReservLeadInfoComponent implements OnInit {

  public data: any;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.data = this.activatedRoute.snapshot.data['data'];
  }


}
