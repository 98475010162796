<div class="row no-gutters">
  <div class="col-lg-12">
    <ng-container *ngIf="crmUsage">

      <br>
    </ng-container>
    <a *ngIf="crmUsage" routerLink="/calendar/create" class="button">Создать событие</a>
    <!--<div class="calendar-widget-sm">-->
      <!--<app-calendar-widget-sm [date]="current_date"></app-calendar-widget-sm>-->
    <!--</div>-->
    <div class="calendar">
      <div class="calendar__sidebar">
        <div class="calendar-widget-wrapper">
          <app-calendar-widget [date]="current_date" [month_events]="month_events"
            (choose)="onDateChoose($event)"></app-calendar-widget>
        </div>
      </div>
    </div>
     <div class="calendar__content">
       <router-outlet></router-outlet>
     </div>
  </div>
</div>
