import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataTableParamComponent} from '../../../modules/@datatable/components/datatable-param.component';
import {HttpClient} from '../../../services/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder} from '@angular/forms';
import {OverlayWindowService} from '../../single-window/shared/overlay-window.service';
import {GoBackButtonService} from '../../../services/go-back-button.service';
import {NotificationService} from '../../../services/notification.service';
import {environment} from '../../../../environments/environment';


@Component({
  selector: 'app-start-sales-members',
  templateUrl: './start-sales-members.component.html',
  styleUrls: [
    './start-sales-members.component.scss'
  ]
})
export class StartSalesMembersComponent extends DataTableParamComponent implements OnInit, OnDestroy {

  @Input() public start_of_sales_id = '';
  @Input() public complex_caption = '';
  public api_action = 'StartOfSales.get_members';
  public columns: Array<{ [key: string]: any }>;
  public data = null;
  public urlExcel = environment.apiServer + 'restful/start-of-sales-member/';

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router,
              public fb: UntypedFormBuilder, private overlayWindowService: OverlayWindowService, private goBack: GoBackButtonService,
              public notify: NotificationService) {
    super(http, route, router);
  }

  @ViewChild('subagentTmpl', {static: true}) private subagentTmpl: TemplateRef<any>;
  @ViewChild('orgTmpl', {static: true}) private orgTmpl: TemplateRef<any>;
  @ViewChild('clientTmpl', {static: true}) private clientTmpl: TemplateRef<any>;

  ngOnInit() {
    this.columns = [
      {'caption': 'Дата создания', 'name': 'create_date', 'sortable': false},
      {'caption': 'СА организация', 'name': 'subagent_organisation_caption', 'sortable': false, template: this.orgTmpl},
      {'caption': 'СА пользователь', 'name': 'subagent_user_fio', 'sortable': false, template: this.subagentTmpl},
      {'caption': 'Клиент', 'name': 'client_fio', 'sortable': false, template: this.clientTmpl},
      {'caption': 'Телефон клиента',  'name': 'client_phone'},
    ];
    super.ngOnInit();
  }

  ngOnDestroy() {
  }

  prepareSort(column_name: string) {
    for (const key in this.sort) {
      if (column_name !== key) {
        delete this.sort[key];
      }
    }
  }

  prepareQuery() {
    return {
      'start_of_sales_id': this.start_of_sales_id
    };
  }

  sortChange(column_name: string) {
    this.prepareSort(column_name);
    this.sort[column_name] = !this.sort[column_name] || ('desc' === this.sort[column_name]) ? 'asc' : 'desc';
  }

  routerFunc(link, id) {
    this.router.navigate([link, id]);
    this.overlayWindowService.closeHost();
  }

}
