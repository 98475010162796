<form [formGroup]="form">
  <app-tab-wrapper>
    <app-tab-element
      [caption]="'Основное'">
      <app-flat-info-common
        [flat_id]="flat_id"
        [floor_id]="floor_id">
      </app-flat-info-common>
    </app-tab-element>
    <app-tab-element
      [caption]="'Дополнительно'">
      <app-flat-info-advanced
        [flat_id]="flat_id"
        [floor_id]="floor_id">
      </app-flat-info-advanced>
    </app-tab-element>
    <app-tab-element
      [caption]="'Реализация'">
      <app-flat-conditions
        [flat_id]="flat_id">
      </app-flat-conditions>
    </app-tab-element>
    <app-tab-element
      [caption]="'Собственник'">
      <app-flat-info-proprietors
        [flat_id]="flat_id"
        [parent]="_parent">
      </app-flat-info-proprietors>
    </app-tab-element>
    <app-tab-element
      [caption]="'Планировка'">
      <app-flat-info-planning
        [form]="form"
        [flat_id]="flat_id">
      </app-flat-info-planning>
    </app-tab-element>
    <app-tab-element
      [caption]="'Поэтажка'">
      <app-flat-info-floor-planning
        [form]="form"
        [flat_id]="flat_id">
      </app-flat-info-floor-planning>
    </app-tab-element>
  </app-tab-wrapper>
  <div class="mt-2">
    <button type="button" class="button" [disabled]="!form.valid" (click)="save()">Сохранить</button>
  </div>
</form>
