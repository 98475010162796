
<div class="mt-3 mb-4">

  <h4>Загружаемые отчеты</h4>
  <ng-container *ngFor="let el of [
    {caption: 'Субагенты',
     url: apiServer + 'restful/get_subagents_xlsx',
     description: 'XLSX таблица со всеми сотрудниками и организациями активных субагентов'},
    {caption: 'ЛПР',
     url: apiServer + 'restful/lpr_list',
     description: 'XLSX таблица с ЛПР всех активных СА'},
    {caption: 'Анкеты',
     url: apiServer + 'restful/questioning/answers',
     description: 'XLSX таблица с ответами анкетирования сотрудников СА'},
    {caption: 'Онлайн',
     url: apiServer + 'restful/online_report',
     description: 'CSV отчет с онлайном сотрудников и СА организаций'},
    {caption: 'Физ лица',
     url: apiServer + 'restful/get_list_to_type',
     description: 'XLSX таблица всех физических лиц системы с разганичением по типам'},
    {caption: 'Прайс',
     url: apiServer + 'restful/price_all',
     description: 'CSV отчет по всем помещениям на витрине'},
  ]; let ind = index">
    <a class="mr-4 mb-2 text-nowrap" [href]="el['url']" target="_blank" [title]="el['description']">
      <i class="fa fa-download"></i>
      {{el['caption']}} <i class="fa fa-question-circle"></i>
    </a>
  </ng-container>

</div>

<div class="report__group">
  <div class="row">
    <div class="col-md-6 mb-4" *ngFor="let group of reports">

      <div class="report__group-element">

        <h4 *ngIf="group['group_name']">{{group['group_name']}}</h4>

        <ul class="report__group-element__reports pl-3">
          <ng-container
            *ngFor="let report of group['reports'];">
            <li class="report__group-element__report">
              <a [routerLink]="getUrl(report)[0]" [queryParams]="getUrl(report)[1]">
                {{report['name']}}
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>
