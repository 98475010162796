import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialInputModule } from '../../modules/@material-input/material-input.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import {PreloaderModule} from '../../modules/@preloader/preloader.module';
import {EstatetResolve} from './estatet.resolve';
import {EstatetComponent} from './estatet.component';
import {PaginatorModule} from '../../shared/housing-modules/modules';
import {AlternativeModule} from '../../shared/alternative/alternative.module';
import {StagesDotsModule} from '../../modules/@stages-dots/stages-dots.module';
import {PopoverModule} from '../../modules/@popover/popober.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
    SharedModule,
    AlternativeModule,
    PreloaderModule,
    PaginatorModule,
    StagesDotsModule,
    PopoverModule,
  ],
  declarations: [
    EstatetComponent
  ],
  exports: [
    EstatetComponent
  ],
  providers: [
    EstatetResolve
  ],
})
export class EstatetModule {
}
