import {NgModule} from '@angular/core';
import {GoogleChartsService} from './shared/google-charts.service';
import {DonutChartComponent} from './donut-chart/donut-chart.component';
import {CommonModule} from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    DonutChartComponent,
  ],
  providers: [
    GoogleChartsService,
  ],
  exports: [
    DonutChartComponent,
  ]
})
export class GoogleChartsModule { }
