import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NotificationService} from '../../../services/notification.service';
import { SLIDE_LEFT_ANIMATION } from '../../../animations/slide-left.animation';

@Component({
  selector: 'app-notification',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: [SLIDE_LEFT_ANIMATION]
})
export class NotificationsComponent implements OnInit, OnDestroy {

  private subscriptionQueue: Subscription = null;
  private subscriptionStatus: Subscription = null;

  public notificationsQueue: any = null;
  public opened = false;
  public localEvent = false;

  constructor(private notification: NotificationService) { }

  ngOnInit() {
    /*
     * Prepare notification queue
     */
    this.notificationsQueue = this.notification.queue;
    this.subscriptionQueue = this.notification.queue_emitter.subscribe(x => this.notificationsQueue = x);
    /*notificationsQueue
     * Prepare status subscription
     */
    this.subscriptionStatus = this.notification.status_emitter.subscribe(x => {
      this.localEvent = true;
      this.opened = x;
    });
  }

  ngOnDestroy() {
    if (this.subscriptionQueue !== null) {
      this.subscriptionQueue.unsubscribe();
    }

    if (this.subscriptionStatus !== null) {
      this.subscriptionStatus.unsubscribe();
    }
  }

  removeNotification(notification) {
    this.notification.splice(notification);
  }

  clickOutside() {
    if (this.opened && !this.localEvent) {
      this.notification.toggleStatus();
    }
    this.localEvent = false;
  }

}
