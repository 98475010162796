import {Component} from '@angular/core';

@Component({
  templateUrl: './analytic-wrapper.component.html',
  styleUrls: [
    './analytic-wrapper.component.scss'
  ]
})
export class AnalyticWrapperComponent {

}
