import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  templateUrl: './all-events.component.html',
  styleUrls: ['./all-events.component.scss']
})
export class AllEventsComponent implements OnInit {

  public statistic;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.statistic = this.activatedRoute.snapshot.data['statistic'];
  }

}
