<h1 class="mb-3">Редактирование старта продаж в {{complex_caption}}</h1>
<form class="form form_crm" [formGroup]="form" *ngIf="form" novalidate (submit)="onSubmit()">
  <div class="row">
    <div class="col-lg-2">
    <app-material-input-datepicker formControlName="date_start"
                                     [placeholder]="'Дата начала'"
                                     [require]="true">
        Дата начала старта продаж</app-material-input-datepicker>
    </div>
    <div class="col-lg-2" *ngIf="!form.value.end_when_publish">
      <app-material-input-datepicker formControlName="date_end"
                                     [placeholder]="'Дата окончания'"
                                     [require]="true">
        Дата окончания старта продаж</app-material-input-datepicker>
    </div>
    <div class="col-lg-2 mt-3">
      <app-material-input-checkbox formControlName="end_when_publish" [cssClass]="'mb-0 text-sm'" [border]="true">
        <small>До публикации на витрине</small>
      </app-material-input-checkbox>
    </div>
    <div class="col-lg-2 mt-3">
      <app-material-input-checkbox formControlName="is_archive" [cssClass]="'mb-0'" [border]="true">
        В архиве
      </app-material-input-checkbox>
    </div>
  </div>
  <div class="row no-gutters gutters-4">
    <div class="col-lg-8 mt-3">
      <app-wysiwyg-editor [rows]="20" [placeholder]="'Описание старта продаж'" formControlName="description">
      </app-wysiwyg-editor>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-8 mt-3">
      <app-material-input-dropzone-sortable
        formControlName="files" [hasComments]="true">
      </app-material-input-dropzone-sortable>
    </div>
  </div>
  <div class="col-lg-10 mt-3 mb-5 mb-md-0">
    <button type="submit" class="button button_sm" [disabled]="!form.valid">Сохранить и закрыть</button>
  </div>
</form>
