
<div class="calendar__wrapper">
  <div class="calendar__statistic">
    <app-calendar-statisctic-digits #commonStatistic>
    </app-calendar-statisctic-digits>
  </div>
  <div class="calendar__content">
    <app-calendar-statistic-table [columns]="columns" #commonTable>
    </app-calendar-statistic-table>
  </div>
</div>

<ng-template #eventCaption let-row="row">
  <a [routerLink]="['/calendar/edit', row['event_id']]" fragment="top"
     [queryParams]="{'backref': '/calendar/statistic'}">
    {{row['event_caption']}}
  </a>
  <!--<a *ngIf="row['event_status'] === null" [routerLink]="['/calendar/edit', row['event_id']]" fragment="top"-->
     <!--[queryParams]="{'backref': '/calendar/statistic'}">-->
    <!--{{row['event_caption']}}-->
  <!--</a>-->
  <!--<span *ngIf="row['event_status'] !== null">{{row['event_caption']}}</span>-->
</ng-template>

<ng-template #eventPlaces let-row="row">
  <a *ngIf="row['event_status'] === null" [routerLink]="['/calendar/edit', row['event_id']]" fragment="top"
     [queryParams]="{'backref': '/calendar/statistic'}">
    {{row['event_places_count']}}
  </a>
  <span *ngIf="row['event_status'] !== null">{{row['event_places_count']}}</span>
</ng-template>

<ng-template #eventParticipants let-row="row">
  <a *ngIf="row['event_status'] === null" [routerLink]="['/calendar/edit', row['event_id']]" fragment="participants"
     [queryParams]="{'backref': '/calendar/statistic'}">
    {{row['subscriptions_count']}}
  </a>
  <span *ngIf="row['event_status'] !== null">{{row['subscriptions_count']}}</span>
</ng-template>

<ng-template #eventType let-row="row">
  <a *ngIf="row['event_status'] === null" [routerLink]="['/calendar/edit', row['event_id']]" fragment="top"
     [queryParams]="{'backref': '/calendar/statistic'}">
    {{row['event_type_caption']}}
  </a>
  <span *ngIf="row['event_status'] !== null">{{row['event_type_caption']}}</span>
</ng-template>

<ng-template #eventStatus let-row="row">
  <span *ngIf="row['event_status'] === null">Запланировано</span>
  <span *ngIf="row['event_status'] === true">Проведено</span>
  <span *ngIf="row['event_status'] === false">Отменено</span>
</ng-template>

<ng-template #eventApproved let-row="row" let-ind="row_index">
  <div [ngClass]="{'activeBox': goBack.activeHash === 'approved-' + ind}">
    <a href="/calendar/users/{{row.event_id}}"
       (click)="goBack.newLink('/calendar/users/' + row.event_id, 'approved-' + ind, $event)">
      {{row.approve_subscriptions_count}}
    </a>
  </div>
</ng-template>

<ng-template #eventComplexes let-row="row">
  {{row['event_complexes']}}
</ng-template>

<ng-template #eventDeclined let-row="row" let-ind="row_index">
  <div [ngClass]="{'activeBox': goBack.activeHash === 'declined-' + ind}">
    <a href="/calendar/users/{{row.event_id}}"
       (click)="goBack.newLink('/calendar/users/' + row.event_id, 'declined-' + ind, $event)">
      {{row.cnt_not_approve_users}}
    </a>
  </div>
</ng-template>
