<div class="dropimage" [ngClass]="cssClass">
  <div class="dropzone dz-clickable" (click)="onClick()" #dropZone [class.hidden]="disable" [class.disabled]="disabled"
       [ngClass]="cssClassZone">
    <div class="dz-default dz-message" *ngIf="!current_value">
      <ng-content></ng-content>
    </div>
    <input type="file" [hidden]="true" (change)="onFileChange()" #fileInput>
  </div>
  <div class="image" *ngIf="current_value">
    <a [href]="upload_url + current_value.filepath" *ngIf="current_value.filepath" (click)="false">
      <div class="img img_border bg_crop" *ngIf="current_value.preview"
           [ngStyle]="{'background-image': 'url('+upload_url + current_value.preview+')'}">
      </div>
      <img src="/assets_common/images/no_img.svg"
           class="img-thumbnail" alt="" *ngIf="!current_value.preview">
    </a>
    <ng-container *ngIf="!disable">
      <a href="javascript: void(0)" class="remove button button_icon"
         (click)="onFileRemove(current_value.filepath)">
        <i class="icon icon_Small_Close"></i>
      </a>
    </ng-container>
  </div>
</div>

