import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../services/notification.service';
import { OverlayWindowService } from '../../single-window/shared/overlay-window.service';
import { HttpClient } from '../../../services/http.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-create-holiday',
  templateUrl: './holiday-create.component.html',
  styleUrls: ['./holiday-create.component.scss']
})
export class HolidayCreateComponent implements OnInit {

  @Output('onCreate') public onCreate: EventEmitter<any> = new EventEmitter<any>();

  public form: UntypedFormGroup;
  public marginLeft = 0;
  public marginTop = 0;

  public new_dates = (value) => {
    let new_value = value;
    if (value !== '') {
      const splitted = value.split(' - ');
      if (splitted[0] === splitted[1]) {
        new_value = splitted[0];
      }
    }
    return new_value;
  }

  constructor(private http: HttpClient, private overlayWindowService: OverlayWindowService,
              private fb: UntypedFormBuilder, private notify: NotificationService) {
  }

  ngOnInit() {
    const date = new DatePipe('en-US').transform(Date.now(), 'dd.MM.yyyy');
    this.form = this.fb.group({
      'time_start': [date, Validators.required],
      'time_stop': [date, Validators.required],
      'dates_string': ['', Validators.required],
      'image': ['', Validators.required],
      'image_icon': ['', Validators.required],
    });
  }

  replaceMarginLeft(value) {
    this.marginLeft = value.value.value;
  }

  replaceMarginTop(value) {
    this.marginTop = value.value.value * -1;
  }

  onSubmit() {
    const data = {
      'data': {
        'time_start': this.form.value.time_start,
        'time_stop': this.form.value.time_stop,
        'dates_string': this.form.value.dates_string,
        'image': this.form.value.image,
        'image_icon': this.form.value.image_icon,
        'margin_top': this.marginTop,
        'margin_left': this.marginLeft,
      }
    };

    this.http.post('Holiday.create', data).subscribe(
      response => {
        this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
        this.onCreate.emit({'value': 'График работы'});
        this.overlayWindowService.closeHost();
      },
      error => this.notify.notifyError('Ошибка', error, 5000)
    );
  }

}
