<div class="form__group" [ngClass]="cssClass">
  <div class="form__label mb-2"
       *ngIf="!suppressLabel"
       [class.form__label_require]="require && !disabled"
       [class.form__label_error]="error"
  >
    <ng-content></ng-content>
  </div>
  <div class="form__group" *ngFor="let radio of data | keys">
    <label class="form__checkbox" [ngClass]="'form__checkbox_'+icon"> <!-- (click)="onChanges(radio.key)"-->
      <input
        [type]="type"
        (change)="onChanges(radio.key, $event)"
        [value]="radio.key"
        [checked]="type === 'radio' && radio.key == current_value
        || type === 'checkbox' && current_value && current_value.indexOf(radio.key) !== -1"
        [disabled]="disabled"
      />
      <span class="form__checkbox__wrapper">
        <span class="form__checkbox__wrapper__toggler icon" [ngClass]="[icon]"></span>
      </span>
      <span class="form__checkbox__content">{{radio.value}}</span>
    </label>
  </div>
</div>
