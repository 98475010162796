<div class="row mt-4 mb-2" [formGroup]="form">
  <div class="col-6">
    Всего: <b>{{count}}</b>
  </div>
</div>
<div class="material-datatable-wrapper">
  <div class="table-responsive">
    <table class="table">
      <tr>
        <th *ngFor="let el of head" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
          <div [formGroup]="form">
            <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
              <div class="col table__input">
                <ng-container *ngIf="el.input">
                  <app-material-input
                    [placeholder] = "el.caption"
                    [formControlName] = "el.name"
                    [cssClass]="'mb-0'">
                  </app-material-input>
                </ng-container>
                <ng-container *ngIf="el.select">
                  <app-material-select
                    [formControlName]="el.name"
                    [cssClass]="'mb-0'">
                    <option [value]="''">{{el.caption}}</option>
                    <option *ngFor="let opt of el.select" [value]="opt.id">{{opt.caption}}</option>
                  </app-material-select>
                </ng-container>
                <ng-container *ngIf="!el.input && !el.select">
                  {{el.caption}}
                </ng-container>
              </div>
              <div class="col-auto" *ngIf="el.sort">
                <a href="javascript:void(0)" (click)="sortChange(el.name)">
                  <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                      'icon_new-sort-down': 'asc' == sort[el.name],
                                      'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                </a>
              </div>
            </div>
          </div>
        </th>
      </tr>
      <!-- // -->
      <tr *ngFor="let row of rows; let row_index = index">
        <td>{{row['date_time']}}</td>
        <td>
          <a *ngIf="row['user_transfer_id']" [routerLink]="['/physical/edit', row['user_transfer_id'], 'common']">{{row['user_transfer']}}</a>
        </td>
        <td>
          <a *ngIf="row['transfer_affairs_user_id']" [routerLink]="['/physical/edit', row['transfer_affairs_user_id'], 'common']">{{row['transfer_affairs_user']}}</a>
        </td>
        <td>
          <a *ngIf="row['subagent_id']" [routerLink]="['/entity/edit', row['subagent_id'], 'common']">{{row['subagent']}}</a>
        </td>
        <td>
          <a *ngIf="row['new_subagent_id']" [routerLink]="['/entity/edit', row['new_subagent_id'], 'common']">{{row['new_subagent']}}</a>
        </td>
        <td>
          <a *ngIf="row['author_id']" [routerLink]="['/physical/edit', row['author_id'], 'common']">{{row['author_fio']}}</a>
        </td>
        <td>
          <ng-container *ngIf="row['leads']; else empty">
            <div *ngFor="let lead of row['leads']">
              <a *ngIf="lead" [routerLink]="['/reservation/lead-info', lead.id]"> {{lead.fio}} </a><br>
            </div>
          </ng-container>
          <ng-template #empty>
            -
          </ng-template>
        </td>
      </tr>
    </table>
  </div>
</div>

<div class="alt-pagination">
  <app-paginator
    [queryParams]="filter_columns"
    [itemsPerPage]="50"
    [itemCount]="count"
    [currentPage]="current_page"
    [baseUrl]="'/transfer-log'">
  </app-paginator>
</div>
