import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {JsonClientService} from '../../../shared/json-client/index';


@Injectable()
export class ReglTableResolve implements Resolve<any> {

  private filter_columns = [
    'developer_caption',
    'complex_caption',
    'search_fields',
  ];

  constructor(private jsonRpc: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {

    const prepared_filter = {};
    const prepared_filter_keys = this.filter_columns.filter(x => !!route.parent.queryParams[x]);
    const sort = {};

    prepared_filter_keys.forEach(x => prepared_filter[x] = route.parent.queryParams[x]);

    if (!!route.queryParams['sort_name']) {
      sort[route.queryParams['sort_name']] = route.queryParams['sort_key'] || 'asc';
    }
    let obj = {};
    if (prepared_filter.hasOwnProperty('search_fields')) {
      obj = {
        'search_fields': {}
      };
      if (typeof(prepared_filter['search_fields']) === 'string') {
        obj['search_fields'][prepared_filter['search_fields']] = true;
      } else if (typeof(prepared_filter['search_fields']) === 'object') {
        for (const el of prepared_filter['search_fields']) {
          obj['search_fields'][el] = true;
        }
      }
      delete prepared_filter['search_fields'];
    } else {
      obj['search_fields'] = {'only_published': true, 'not_fill': true};
    }


    const options = {
      'limit': 50,
      'offset': (+(route.queryParams['page'] || 1) - 1) * 50,
      'filter': {
        'fields': prepared_filter,
      },
      'sort': sort
    };

    return this.jsonRpc.post('Housing_complex.get_akt_reglam_table', Object.assign(obj, options));
  }

}
