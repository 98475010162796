<div class="row">
  <div class="col-7 appeal__wrapper">
    <div class="appeal__block_head">
      <p><strong>Тип операции:</strong> {{data['appeal_operation']['operation_type']}}</p>
      <p><strong>Дата проведения:</strong> {{data['appeal_create_date']}}</p>
      <ng-container *ngIf="data['appeal_manager']">
        <p><strong>Менеджер:</strong> {{data['appeal_manager']['manager_fio']}}</p>
      </ng-container>
    </div>
    <div class="appeal__block" *ngIf="data['appeal_realty']">
      <h5>Объект</h5>
      <p>
        <a [routerLink]="['/housing2/flat/', data['appeal_realty']['realty_id']]">
          <i class="fa fa-external-link"></i> {{data['appeal_realty']['realty_parent_caption']}}, {{data['appeal_realty']['realty_caption']}}
        </a>
      </p>
    </div>
    <div class="appeal__block" *ngIf="data['appeal_realty'] && data['appeal_realty']['realty_developer_caption']">
      <h5>Правообладатель</h5>
      <p><strong>Застройщик:</strong> {{data['appeal_realty']['realty_developer_caption']}}</p>
      <ng-container
        *ngIf="data['appeal_realty'] && data['appeal_realty']['realty_owners']
          && data['appeal_realty']['realty_owners'].length">
            <p><strong>Правообладатель</strong></p>
            <ul>
              <li *ngFor="let el of data['appeal_realty']['realty_owners']">
                <ng-container *ngIf="el['organisation_id']">
                  <a [routerLink]="['/entity/edit', el['organisation_id'], 'common']">
                    {{el['organisation_caption']}}
                  </a>
                </ng-container>
                <ng-container *ngIf="el['physical_id']">
                  <a [routerLink]="['/physical/edit', el['physical_id'], 'common']">
                    {{el['physical_fio']}}
                  </a>
                </ng-container>
              </li>
            </ul>
      </ng-container>
    </div>
    <div class="appeal__block" *ngIf="data['appeal_estatet'] && data['appeal_estatet']['manager_fio']">
      <div class="row">
        <div class="col-md-6">
          <h5>Менеджер Est-A-Tet</h5>
          <p>{{data['appeal_estatet']['manager_fio']}}</p>
          <p *ngIf="data['appeal_estatet']['manager_email']">
            <a [href]="'mailto:'+data['appeal_estatet']['manager_email']">
              {{data['appeal_estatet']['manager_email']}}
            </a>
          </p>
          <p *ngIf="data['appeal_estatet']['manager_phone']">
            {{data['appeal_estatet']['manager_phone']}}
          </p>
        </div>
        <div class="col-md-6">
          <h5>Руководитель отдела Est-A-Tet</h5>
          <p>{{data['appeal_estatet']['boss_fio']}}</p>
          <p *ngIf="data['appeal_estatet']['boss_email']">
            <a [href]="'mailto:'+data['appeal_estatet']['boss_email']">
              {{data['appeal_estatet']['boss_email']}}
            </a>
          </p>
          <p *ngIf="data['appeal_estatet']['boss_phone']">
            {{data['appeal_estatet']['boss_phone']}}
          </p>
        </div>
      </div>
    </div>
    <div class="appeal__block" *ngIf="data['appeal_author']">
      <h5>Субагент</h5>
      <p><strong>Автор:</strong> {{data['appeal_author']['author_fio']}}</p>
      <p><strong>Организация:</strong> {{data['appeal_author']['author_organisation']}}</p>
      <p><strong>Телефон:</strong> {{data['appeal_author']['author_phone']}}</p>
    </div>
    <div class="appeal__block" *ngIf="data['appeal_client']">
      <h5>Клиент</h5>
      <p *ngIf="data['appeal_client']['client_fio']">
        <strong>ФИО:</strong> {{data['appeal_client']['client_fio']}}
      </p>
      <p *ngIf="data['appeal_client']['client_phone']">
        <strong>Телефон:</strong> {{data['appeal_client']['client_phone']}}
      </p>
      <p *ngIf="data['appeal_client']['client_email']">
        <strong>E-mail:</strong> {{data['appeal_client']['client_email']}}
      </p>
      <p *ngIf="data['appeal_client']['client_data_rozhd']">
        <strong>Дата рождения:</strong> {{data['appeal_client']['client_data_rozhd']}}
      </p>
      <p *ngIf="data['appeal_client']['client_korr_addr']">
        <strong>Кор. адрес:</strong> {{data['appeal_client']['client_korr_addr']}}
      </p>
      <p *ngIf="data['appeal_client']['client_mj_fakt']">
        <strong>Место жит. факт.:</strong> {{data['appeal_client']['client_mj_fakt']}}
      </p>
      <p *ngIf="data['appeal_client']['client_mj_reg']">
        <strong>Место жит. по рег.:</strong> {{data['appeal_client']['client_mj_reg']}}
      </p>
      <p *ngIf="data['appeal_client']['client_nationality']">
        <strong>Гражданство:</strong> {{data['appeal_client']['client_nationality']}}
      </p>
      <p *ngIf="data['appeal_client']['client_pasp_date']">
        <strong>Дата выдачи паспорта:</strong> {{data['appeal_client']['client_pasp_date']}}
      </p>
      <p *ngIf="data['appeal_client']['client_pasp_num']">
        <strong>Номер паспорта:</strong> {{data['appeal_client']['client_pasp_num']}}
      </p>
      <p *ngIf="data['appeal_client']['client_pasp_seriya']">
        <strong>Серия паспорта:</strong> {{data['appeal_client']['client_pasp_seriya']}}
      </p>
    </div>
    <div class="appeal__block">
      <form [formGroup]="form" (submit)="saveFiles()">
        <app-material-input-dropzone
          formControlName="files">
        </app-material-input-dropzone>
        <div class="mt-2 text-center">
          <button type="submit" class="button">Сохранить изменения</button>
        </div>
      </form>
    </div>
  </div>
</div>

