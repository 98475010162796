import { Injectable } from '@angular/core';
import { SingleWindowComponent } from '../single-window.component';
import { NavigationEnd, ResolveEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SingleWindowService } from './single-window.service';
import { filter } from 'rxjs/operators';

@Injectable()
export class BackrefWindowService {

  public url: any;
  public subscription: Subscription;

  constructor(public router: Router, public singleWindowService: SingleWindowService) { }

  routerInit() {
    this.subscription = this.router.events.pipe(filter(x => x instanceof NavigationEnd || x instanceof ResolveEnd))
      .subscribe(x => {
        if (x instanceof NavigationEnd) {
          this.url = x.url;
        } else if (x instanceof ResolveEnd) {
          this.singleWindowService.backref = this.url;
          if (this.url && x && x['state'] && x['state'].root.firstChild.component === SingleWindowComponent) {
            this.singleWindowService.backref = this.url;
          }
        }
      });
  }

  routerDestroy() {
    this.subscription.unsubscribe();
  }
}
