import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import { HttpClient } from '../../../../services/http.service';
import { OverlayWindowService } from '../../../single-window/shared/overlay-window.service';
import { NotificationService } from '../../../../services/notification.service';
import {JsonClientService} from '../../../../shared/json-client/index';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-entity-create-fields',
  templateUrl: './entity-create-fields.component.html',
  styleUrls: ['./entity-create-fields.component.scss']
})
export class EntityCreateFieldsComponent implements OnInit {

  @Input('forms') public  forms: any;
  @Input('form') public  form: any;
  @Output('formChange') public formChange: EventEmitter<any> = new EventEmitter<any>();

  @Input('types') public  types: any;
  @Output('dataChange') public dataChange: EventEmitter<any> = new EventEmitter<any>();

  public sameAdress = false;
  constructor(private http: HttpClient, private overlayWindowService: OverlayWindowService,
              private fb: UntypedFormBuilder, private j: JsonClientService) {
  }
  public formadded  = false;
  ngOnInit() {
    this.form.addControl('organisation_caption', this.fb.control('', Validators.required));
    this.form.addControl('organisation_socrachcaption', this.fb.control(''));
    this.form.addControl('organisation_type_id', this.fb.control('', Validators.required));
    this.form.addControl('organisation_address', this.fb.control('', Validators.required));
    this.form.addControl('organisation_phone', this.fb.control('', Validators.required));
    this.form.addControl('organisation_komm_caption', this.fb.control(''));
    this.form.addControl('organisation_form_id', this.fb.control('', Validators.required));
    this.form.addControl('organisation_fact_addr', this.fb.control(''));
    this.form.addControl('organisation_post_address', this.fb.control(''));
    this.form.addControl('organisation_email', this.fb.control(''));
    this.form.addControl('organisation_overall_unique_is', this.fb.control(false));

    this.formadded  = true;
    if (!this.forms || !this.types) {
      this.loadDictionaries().then( (r) => {
        [this.forms, this.types] = r;
      });
    }
    this.formChange.next(this.form);
    this.form.valueChanges.subscribe((value) => {
      const data = {
        'organisation_caption': value.organisation_caption,
        'organisation_socrachcaption': value.organisation_socrachcaption,
        'organisation_komm_caption': value.organisation_komm_caption,
        'organisation_form_id': value.organisation_form_id,
        'organisation_type_id': value.organisation_type_id,
        'organisation_address': value.organisation_address,
        'organisation_fact_addr': this.sameAdress ? value.organisation_address : value.organisation_fact_addr,
        'organisation_post_address': value.organisation_post_address,
        'organisation_email': value.organisation_email,
        'organisation_phone': value.organisation_phone,
        'organisation_overall_unique_is': value.organisation_overall_unique_is,
      };
      this.formChange.next(this.form);
      this.dataChange.next(data);
    });
  }

  private loadDictionaries() {
    return Promise.all([
      this.j.post('Organisation.get_forms'),
      this.j.post('Organisation.get_type'),
    ]);
  }
}
