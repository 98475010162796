<div>
  <h2>Условия покупки</h2>
  <div>
    <form [formGroup]="form" novalidate (submit)="formSubmit()">
      <app-tab-wrapper [mode]="'vertical'">
        <app-tab-element [caption]="'Акции'">
          <app-wysiwyg-editor formControlName="up_akcii"></app-wysiwyg-editor>
        </app-tab-element>
        <app-tab-element [caption]="'Банки и ипотека'">
          <app-wysiwyg-editor formControlName="up_banki"></app-wysiwyg-editor>
        </app-tab-element>
        <app-tab-element [caption]="'Рассрочка'">
          <app-wysiwyg-editor formControlName="up_rassroch"></app-wysiwyg-editor>
        </app-tab-element>
        <app-tab-element [caption]="'Взаимозачет'">
          <app-wysiwyg-editor formControlName="up_vzaimozach"></app-wysiwyg-editor>
        </app-tab-element>
      </app-tab-wrapper>
      <button type="submit" [disabled]="!form.valid" class="button">Сохранить изменения</button>
    </form>
  </div>
</div>
