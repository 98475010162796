<div class="row">
  <div class="col-12">
    <h1 *ngIf="!event_data">Пригласить пользователей</h1>
    <h1 *ngIf="event_data && event_data['type']">Пригласить пользователей на &laquo;{{event_data['caption']}}&raquo;
      {{event_data['event_date']}} &mdash; {{event_data['type']['type_caption']}}</h1>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="form__group">
      <div class="form__input">
        <input class="input" type="text" placeholder="Фамилия, организация, эл. почта, телефон" [(ngModel)]="searchString">
      </div>
    </div>
    <app-simple-table (currentPageEmitter)="onPageSet($event)">
      <app-simple-table-th>ФИО</app-simple-table-th>
      <app-simple-table-th>Организация</app-simple-table-th>
      <app-simple-table-th>Телефон</app-simple-table-th>
      <app-simple-table-th>Эл. почта</app-simple-table-th>
      <app-simple-table-th></app-simple-table-th>
      <!-- rows -->
      <app-simple-table-tr *ngFor="let row of rows; index as i" [template]="template">
        <ng-template #template>
          <td><a href="javascript:void(0)" (click)="append(row)">{{row['physical_fio']}}</a></td>
          <td>{{row['organisation_caption']}} <a target="_blank" [routerLink]="['/entity/edit', row['organisation_id'], 'physical']">перейти</a></td>
          <td>{{row['physical_phone']}}</td>
          <td>{{row['physical_email']}}</td>
          <td></td>
        </ng-template>
      </app-simple-table-tr>
    </app-simple-table>
    <div class="invites">
      <span>Пригласить:</span>
      <ul class="invites__list">
        <li class="invites__elememnt" *ngFor="let row of invitesUsers">
          <a href="javascript:void(0)" (click)="dropInvite(row)"><i class="fa fa-times"></i> {{row['physical_fio']}}</a>
        </li>
      </ul>
    </div>
    <button class="button" (click)="submit()" [disabled]="!invitesUsers.length">Пригласить</button>
  </div>
</div>

