import {Component, Host, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {CreatePhysicalAltComponent} from '../create-physical.component';
import {HttpClient} from '../../../../services/http.service';

@Component({
  selector: 'app-alt-physical-organisations',
  templateUrl: './create-physical-organisations.component.html',
  styleUrls: [
    './create-physical-organisations.component.scss'
  ]
})
export class CreatePhysicalAltOrganisationsComponent implements OnInit {

  public posts: Array<any>;
  public form: UntypedFormGroup;

  public get relationships(): UntypedFormArray {
    return this.form.get('organisations').get('relationships') as UntypedFormArray;
  }

  constructor(@Host() private hostComponent: CreatePhysicalAltComponent,
              private fb: UntypedFormBuilder, private http: HttpClient) { }

  ngOnInit() {

    this.getPosts().then(x => this.posts = x);

    this.form = this.hostComponent.form;

    this.hostComponent.form.addControl('organisations', this.fb.group({
      'relationships': this.fb.array([])
    }));
  }

  appendRelationship() {
    this.relationships.push(this.fb.group({
      'organisation_id': [''],
      'post_id': [''],
    }));
  }

  dropRelationship(index) {
    this.relationships.removeAt(index);
  }

  getPosts(): Promise<any> {
    return new Promise<any>(resolve => {
      this.http.post('Dict.get_post').subscribe(x => resolve(x));
    });
  }

}
