import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataTableComponent} from '../../../modules/@datatable/components/datatable.component';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {HttpClient} from '../../../services/http.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-online-view-table',
  templateUrl: './online-compare-view-table.component.html',
  styleUrls: ['./online-compare-view-table.component.scss']
})
export class OnlineCompareViewTableComponent extends DataTableComponent implements OnInit, OnDestroy {

  public form: UntypedFormGroup;

  public api_action = 'History.dt_online';

  private subscription: Array<Subscription> = [];

  constructor(public http: HttpClient, private fb: UntypedFormBuilder) {
    super(http);
  }

  ngOnInit() {
    this.form = this.fb.group({
      'date_from': ['', Validators.required],
      'date_end': ['', Validators.required],
      'search_string': [''],
    });

    this.subscription.push(this.form.get('search_string').valueChanges.subscribe(value => {
       this.loadList();
    }));

    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    for (let i = 0; i < this.subscription.length; i++) {
      this.subscription[i].unsubscribe();
    }
  }

  prepareQuery(): any {
    const query = this.getPagination();
    query['filter'] = {'global_search_string': this.form.value['search_string']};
    query['sort'] = this.sort;

    if (this.form.value['date_from']) {
      query['date_from'] = this.form.value['date_from'];
    }

    if (this.form.value['date_end']) {
      query['date_end'] = this.form.value['date_end'];
    }

    return query;
  }

  formSubmit() {
    this.loadList();
  }

}
