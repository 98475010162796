import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {AlternativeDatatablesInterface} from '../../../../shared/alternative-datatables/alternative-datatables.interface';
import {AlternativeDatatablesComponent} from '../../../../shared/alternative-datatables/alternative-datatables.component';
import {JsonClientService} from '../../../../shared/json-client/index';
import {GoBackButtonService} from '../../../../services/go-back-button.service';
import {BreadcrumbsService} from '../../../../shared/breadcrumbs/services/index';

@Component({
  templateUrl: './get-realty-summary-table.component.html',
  styleUrls: [
    './get-realty-summary-table.component.scss'
  ]
})
export class GetRealtySummaryTableComponent
  extends AlternativeDatatablesComponent
  implements AlternativeDatatablesInterface, OnInit, OnDestroy, AfterViewInit {

  public readonly head = [
    {'name': 'realty_caption', 'value': '', 'caption': {'housing_complex': 'Комплекс', 'housing': 'Корпус', 'flat': 'Квартира'}, 'width': '', 'realty_type': ['housing_complex', 'housing', 'flat'], 'sort': false},
  ];
  public filter_columns = {};
  public form: UntypedFormGroup;
  public filter: string;

  private parasites = true;

  public filterSubject: Subject<void> = new Subject<void>();
  private filterSubscription: Subscription;
  private formSubscription: Subscription;

  public current_page = 1;

  public count;
  public rows;
  public realty_type;

  public sort: {[key: string]: string} = {};

  public captions = {
    'total_complex': 'Всего комплексов',
    'est_complex': 'Всего комплексов Estatet',
    'spn24_complex': 'Всего комплексов Spn24',
    'total_housings': 'Всего корпусов',
    'est_housings': 'Всего корпусов Estatet',
    'spn24_housings': 'Всего корпусов Spn24',
    'total_complex_moscow': 'Всего комплексов в Москве',
    'est_complex_moscow': 'Всего комплексов в Москве Estatet',
    'spn24_complex_moscow': 'Всего комплексов в Москве Spn24',
    'total_complex_not_moscow': 'Всего комплексов не в Москве',
    'est_complex_not_moscow': 'Всего комплексов не в Москве Estatet',
    'spn24_complex_not_moscow': 'Всего комплексов не в Москве Spn24',
    'total_flats': 'Всего квартир',
    'est_flats': 'Всего квартир Estatet',
    'spn24_flats': 'Всего квартир Spn24',
    'total_rooms_1': 'Всего 1-комнатных',
    'est_rooms_1': 'Всего 1-комнатных Estatet',
    'spn24_rooms_1': 'Всего 1-комнатных Spn24',
    'total_rooms_2': 'Всего 2-комнатных',
    'est_rooms_2': 'Всего 2-комнатных Estatet',
    'spn24_rooms_2': 'Всего 2-комнатных Spn24',
    'total_rooms_3': 'Всего 3-комнатных',
    'est_rooms_3': 'Всего 3-комнатных Estatet',
    'spn24_rooms_3': 'Всего 3-комнатных Spn24',
    'total_rooms_4+': 'Всего 4+-комнатных',
    'est_rooms_4+': 'Всего 4+-комнатных Estatet',
    'spn24_rooms_4+': 'Всего 4+-комнатных Spn24',
    'total_rooms_1_studio': 'Всего 1-комнатных студий',
    'est_rooms_1_studio': 'Всего 1-комнатных студий Estatet',
    'spn24_rooms_1_studio': 'Всего 1-комнатных студий Spn24',
    'total_rooms_2_studio': 'Всего 2-комнатных студий',
    'est_rooms_2_studio': 'Всего 2-комнатных студий Estatet',
    'spn24_rooms_2_studio': 'Всего 2-комнатных студий Spn24',
    'total_rooms_3_studio': 'Всего 3-комнатных студий',
    'est_rooms_3_studio': 'Всего 3-комнатных студий Estatet',
    'spn24_rooms_3_studio': 'Всего 3-комнатных студий Spn24',
    'total_rooms_4+_studio': 'Всего 4+-комнатных студий',
    'est_rooms_4+_studio': 'Всего 4+-комнатных студий Estatet',
    'spn24_rooms_4+_studio': 'Всего 4+-комнатных студий Spn24',
    'total_rooms_1_apartment': 'Всего 1-комнатных апартаментов',
    'est_rooms_1_apartment': 'Всего 1-комнатных апартаментов Estatet',
    'spn24_rooms_1_apartment': 'Всего 1-комнатных апартаментов Spn24',
    'total_rooms_2_apartment': 'Всего 2-комнатных апартаментов',
    'est_rooms_2_apartment': 'Всего 2-комнатных апартаментов Estatet',
    'spn24_rooms_2_apartment': 'Всего 2-комнатных апартаментов Spn24',
    'total_rooms_3_apartment': 'Всего 3-комнатных апартаментов',
    'est_rooms_3_apartment': 'Всего 3-комнатных апартаментов Estatet',
    'spn24_rooms_3_apartment': 'Всего 3-комнатных апартаментов Spn24',
    'total_rooms_4+_apartment': 'Всего 4+-комнатных апартаментов',
    'est_rooms_4+_apartment': 'Всего 4+-комнатных апартаментов Estatet',
    'spn24_rooms_4+_apartment': 'Всего 4+-комнатных апартаментов Spn24',
  }

  private default_query = {};

  public set data(value) {
    this.count = value['realty_count'];
    this.rows = value['realty_info'];
    this.realty_type = value['realty_type'];
  }

  constructor(private router: Router, private fb: UntypedFormBuilder, public activatedRoute: ActivatedRoute,
              public goBack: GoBackButtonService, private b: BreadcrumbsService,
              public http: JsonClientService) {
    super(router, activatedRoute, fb, http);

    this.filterSubscription = this.filterSubject
      .pipe(debounceTime(500))
      .subscribe(_ => this.onFilterChanges());

    this.head = this.head.map(x => {
      x.value = this.activatedRoute.snapshot.queryParams[x.name] || x.value;
      return x;
    });
    if (this.activatedRoute.snapshot.queryParams['sort_name']) {
      this.sort[this.activatedRoute.snapshot.queryParams['sort_name']] = this.activatedRoute.snapshot.queryParams['sort_key'];
    }
  }

  ngOnInit() {
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/analytic/index', caption: 'Аналитика'},
    ]);

    this.default_query = {
      'date_end': this.activatedRoute.snapshot.queryParams['date_end'],
      'query_type': this.activatedRoute.snapshot.queryParams['query_type'],
      'method': this.activatedRoute.snapshot.queryParams['method'],
    };
    this.goBack.header = '<h1>Отдел проектов - ' + this.captions[this.default_query['query_type']] + '</h1>';


    this.filter_columns = Object.assign({}, this.default_query);

    this.form = this.fb.group({});

    this.formSubscription = this.form.valueChanges
      .subscribe(_ => this.filterSubject.next());

    for (const el of this.head) {
      this.form.addControl(el.name, new UntypedFormControl(el.value));
    }

    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.onInitCall();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }

    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  onFilterChanged(): void {
    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.data = this.activatedRoute.snapshot.data['data'];
    this.current_page = +this.activatedRoute.snapshot.queryParams['page'] || 1;
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/analytic/index', caption: 'Аналитика'},
    ]);
  }

  onFilterChanges(text = null) {

    if (text !== null) {
      this.filter = text;
    }

    this.filter_columns = Object.assign({}, this.default_query);
    for (const el of this.head) {
      if (!this.form.get(el.name).value.length) {
        continue;
      }

      this.filter_columns[el.name] = this.form.get(el.name).value;
    }
    if (this.filter !== '') {
      this.filter_columns['global_search_string'] = this.filter;
    }
    if (this.sort) {
      this.filter_columns['sort_name'] = Object.keys(this.sort)[0];
      this.filter_columns['sort_key'] = this.sort[this.filter_columns['sort_name']];
    }
    if (this.parasites) {
      return this.parasites = !this.parasites;
    }

    this.router.navigate(['analytic', 'realty-summary', 'table'], {
      queryParams: this.getQueryParams(),
    });
  }

  getQueryParams() {
    const query_params = Object.assign({}, this.default_query);
    for (const el of this.head) {
      if (!this.form.get(el.name).value.length) {
        continue;
      }
      query_params[el.name] = this.form.get(el.name).value;
    }
    /*
    if (this.sort) {
      query_params['sort_name'] = Object.keys(this.sort)[0];
      query_params['sort_key'] = this.sort[query_params['sort_name']];
    }
    if (this.filter !== '') {
      query_params['global_search_string'] = this.filter;
    }
    */
    return query_params;
  }

  prepareSort(column_name: string) {
    for (const key in this.sort) {
      if (column_name !== key) {
        delete this.sort[key];
      }
    }
  }

  sortChange(column_name: string) {
    this.prepareSort(column_name);
    this.sort[column_name] = !this.sort[column_name] || ('desc' === this.sort[column_name]) ? 'asc' : 'desc';
    this.onFilterChanges();
  }
  ngAfterViewInit() {
    this.goBack.goScroll();
  }
}
