import {Component, EventEmitter, Host, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';

import {OsmService} from '../osm.service';
import {OsmMapComponent} from '../osm-map/osm-map.component';


@Component({
  selector: 'osm-cluster',
  templateUrl: './osm-cluster.component.html',
  styleUrls: [
    './osm-cluster.component.scss'
  ]
})
export class OsmClusterComponent implements OnInit, OnDestroy {

  private L;
  private markers;

  public placemarks = [];

  public ready: Promise<void>;
  public ready_resolve: (...args) => void;

  private ngDestroy = new Subject();
  private mapSubscribe: Subscription;

  @Input() private options: {[p: string]: any} = {};
  @Output() private click: EventEmitter<[any, any]> = new EventEmitter();

  constructor(private o: OsmService,
              @Host() private host_map: OsmMapComponent) {
    this.ready = new Promise<void>(resolve => this.ready_resolve = resolve);
  }

  ngOnInit(): void {
    this.mapSubscribe = this.o.$getMap
      .pipe(takeUntil(this.ngDestroy))
      .subscribe((L) => {
        if (!L) { return; }

        this.L = L;

        this.host_map.ready.then(_ => this.whenMapReady());
      });
  }

  whenMapReady() {
    this.markers = this.L.markerClusterGroup(this.options);
    this.o.registerCluster(this.markers, this, this.host_map);
    this.ready_resolve();

    this.markers.on('clusterclick', e => {
      const placemarks = e.layer.getAllChildMarkers()
        .map(p => this.o.getPlacemarkClsByValue(p).shift());

      this.click.emit(placemarks);
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next(null);
    this.ngDestroy.complete();

    this.markers.off('clusterclick');

    this.o.dropCluster(this, this.host_map);
    if (this.mapSubscribe) {
      this.mapSubscribe.unsubscribe();
    }
  }

}
