import {Injectable} from '@angular/core';
import {StorageInterface} from './storage.interface';

@Injectable()
export class LocalStorageService implements StorageInterface {


  loadData(key): any {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);

    }
  }

  saveData(key, data): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

}
