import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { MaterialSelect2Service } from './material-select2.service';

declare const jQuery: any;

@Directive({
  selector: '[appMaterialSelect]',
})
export class MaterialSelect2Directive implements AfterViewInit, OnDestroy {

  constructor(private elementRef: ElementRef, public select2Service: MaterialSelect2Service) {
  }

  @Input('appMaterialSelect') public appMaterialSelect = [];
  @Output() public select2_change: EventEmitter<any> = new EventEmitter();
  public updateSubscription: Subscription;
  public subscriptionClear: Subscription;

  ngAfterViewInit() {
    this.appMaterialSelect = Object.assign(this.appMaterialSelect, {
      language: {
        errorLoading: function () {
          return 'Результат не может быть загружен.';
        },
        inputTooLong: function (args) {
          let overChars = args.input.length - args.maximum;
          let message = 'Пожалуйста, удалите ' + overChars + ' символ';
          if (overChars >= 2 && overChars <= 4) {
            message += 'а';
          } else if (overChars >= 5) {
            message += 'ов';
          }
          return message;
        },
        inputTooShort: function (args) {
          let remainingChars = args.minimum - args.input.length;
          let message = 'Пожалуйста, введите ' + remainingChars + ' или более символов';
          return message;
        },
        loadingMore: function () {
          return 'Загружаем ещё ресурсы…';
        },
        maximumSelected: function (args) {
          let message = 'Вы можете выбрать ' + args.maximum + ' элемент';
          if (args.maximum >= 2 && args.maximum <= 4) {
            message += 'а';
          } else if (args.maximum >= 5) {
            message += 'ов';
          }
          return message;
        },
        noResults: function () {
          return 'Ничего не найдено';
        },
        searching: function () {
          return 'Поиск…';
        }
      },
      templateResult: this.formatState,
      templateSelection: this.formatState,
      tokenSeparators: [','],
      tags: false,
    });
    setTimeout(() => {

      jQuery(this.elementRef.nativeElement).select2(this.appMaterialSelect)
        .on('change', () => {

          if (jQuery(this.elementRef.nativeElement).val() === 'null') {
            jQuery(this.elementRef.nativeElement).val(null);
          }

          this.select2_change.emit(jQuery(this.elementRef.nativeElement).val());

        });
    }, 1);
    this.updateSubscription = this.select2Service.onReload.subscribe((response) => {
      jQuery(this.elementRef.nativeElement).select2(this.appMaterialSelect);
    });
    this.subscriptionClear = this.select2Service.onClear.subscribe(_ => {
      jQuery(this.elementRef.nativeElement).val('').trigger('change');
    });
  }

  ngOnDestroy() {
    try {
      jQuery(this.elementRef.nativeElement).select2('destroy');
    }
    catch (e) {
      return;
    }
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
    if (this.subscriptionClear) {
      this.subscriptionClear.unsubscribe();
    }
  }

  private formatState (opt) {
    const opticon = jQuery(opt.element).attr('data-icon');
    let $opt = jQuery('<span>' + opt.text + '</span>');
    if (opticon && opticon === 'metro') {
      $opt = jQuery(
        '<span><span class="select2-icon icon2 icon2_metro"></span>' + opt.text + '</span>'
      );
    }
    if (opticon && opticon === 'train') {
      $opt = jQuery(
        '<span><span class="select2-icon icon2 icon2_train"></span>' + opt.text + '</span>'
      );
    }
    if (opticon && opticon === 'lines') {
      const colorIcon = jQuery(opt.element).attr('data-color');
      $opt = jQuery(
        '<span class="d-flex o-hidden"><span class="select2-icon line-icon" style="background-color:' + colorIcon + '"></span>' + opt.text + '</span>'
      );
    }
    return $opt;
  }

}
