import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpClient } from '../../../../../services/http.service';
import { MaterialSelect2Service } from '../../../../../modules/@material-input/component/material-select2.service';
import { DataTableComponent } from '../../../../../modules/@datatable/components';
import { NotificationService } from '../../../../../services/notification.service';

@Component({
  selector: 'app-calendar-physical-table',
  templateUrl: './calendar-physical-table.component.html',
  styleUrls: ['./calendar-physical-table.component.scss']
})
export class CalendarPhysicalTableComponent extends DataTableComponent implements OnInit, OnDestroy {
  @Input() public subagent_id = null;
  @Input() public event_list = [];
  @Input() public subagent_caption = null;
  @Output() public chageTable: EventEmitter<any> = new EventEmitter();

  public columns;
  public api_action = 'Map.dt_show_subagent_staff';
  public formGroup: UntypedFormGroup;
  private changeSubscribtion: Subscription;

  @ViewChild('checkTmpl')
  private checkTmpl: TemplateRef<any>;

  @ViewChild('checkHeadTmpl')
  private checkHeadTmpl: TemplateRef<any>;

  constructor(public http: HttpClient, public fb: UntypedFormBuilder,
              public select2Service: MaterialSelect2Service, public notify: NotificationService) {
    super(http);
    this.items_per_page = 200;
    this.formGroup = this.fb.group({
      'event_id': [null],
    });
    this.changeSubscribtion = this.formGroup.valueChanges.subscribe((data) => {
      this.tbody.rows = null;
      this.loadList();
    });
  }

  ngOnInit() {
    this.columns = [
      {'name': '', 'caption': '', 'sortable': false, 'template': this.checkTmpl, 'templateHead': this.checkHeadTmpl},
      {'name': 'physic_name', 'caption': 'ФИО', 'sortable': false},
      {'name': 'physic_phone', 'caption': 'Телефон', 'sortable': false},
      {'name': 'physic_post', 'caption': 'Должность', 'sortable': false},
    ];
    super.ngOnInit();
  }

  prepareQuery() {
    const query = super.prepareQuery();
    query['sub_id'] = this.subagent_id;
    query['event_id'] = this.formGroup.value.event_id;
    // query['filter']['fields'] = {
    //   'organisation_socrachcaption': this.formGroup.value.realty_id,
    // };
    return query;
  }

  clearSelect2() {
    this.select2Service.clear();
  }

  ngOnDestroy() {
    this.changeSubscribtion.unsubscribe();
  }

  onCheck($event, row) {
    $event.preventDefault();
    if (row.check_event) {
      NotificationService.swalConfirm(`Отписать ${row.physic_name}?`,
        `Вы уверены что хотите отписать сотрудника от выбранного события?`, 'Да')
      .then(() => {
        return this.http.post('Calendar.unsubscribe_users', {
          'event_id': this.formGroup.value.event_id,
          'users': [row.physic_id]
        }).subscribe(
          data => {
            this.tbody.rows.forEach(item => (row === item) ? item.check_event = !item.check_event : false);
          },
          error => false
        );
      }).catch(() => $event.preventDefault());
    } else {
      NotificationService.swalConfirm(`Записать ${row.physic_name}?`,
        `Вы уверены что хотите записать сотрудника на выбранное событие?`, 'Да')
      .then(() => {
        return this.http.post('Calendar.update_subscribers_event', {
          'event_id': this.formGroup.value.event_id,
          'actual_users': [row.physic_id]
        }).subscribe(
          data => {
            this.tbody.rows.forEach(item => (row === item) ? item.check_event = !item.check_event : false);
          },
          error => false
        );
      }).catch(() => $event.preventDefault());
    }
  }

  getCheck(row) {
    this.tbody.rows.forEach(item => (row === item) ? row.check_event = item.check_event : row.check_event);
    return row.check_event;
  }

  onCheckAll() {
    NotificationService.swalConfirm('Записать всех?', 'Вы уверены что хотите записать всех сотрудников субагента?', 'Да')
      .then(() => {
        const user_list = this.tbody.rows.filter((user) => user.check_event !== true).map((user) => user.physic_id);
        this.http.post('Calendar.update_subscribers_event', {
          'event_id': this.formGroup.value.event_id,
          'actual_users': user_list
        }).subscribe(
          data => this.loadList(),
          error => {
            if (error.error.name && error.error.name === 'NotEnoughSpace') {
              error = error.error.message;
            } else {
              error = 'Ошибка записи сотрудников';
            }
            this.notify.notifyError(error);
          }
        );
      }).catch(() => false);
  }


  onBack() {
    this.chageTable.emit();
  }

}
