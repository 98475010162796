import {NgModule} from '@angular/core';
import {PositionSelectorComponent} from './position-selector.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    PositionSelectorComponent,
  ],
  exports: [
    PositionSelectorComponent
  ]
})
export class PositionSelectorModule { }
