import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalComponent } from '../@modal';
import { FADE_ANIMATION } from '../../animations/fade.animation';
import { SLIDE_UP_ANIMATION } from '../../animations/slide-up.animation';

@Component({
  selector: 'app-modal-help',
  templateUrl: './modal-help.component.html',
  styleUrls: ['./modal-help.component.scss'],
  animations: [SLIDE_UP_ANIMATION, FADE_ANIMATION]
})
export class ModalHelpComponent extends ModalComponent implements OnInit, OnDestroy, AfterViewInit {
  public closeOutside = false;

}
