import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import * as c from './components';
import * as s from './services';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
    c.BreadcrumbsComponent,
  ],
  providers: [
    s.BreadcrumbsService,
  ],
  exports: [
    c.BreadcrumbsComponent,
  ]
})
export class BreadcrumbsModule { }
