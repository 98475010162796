import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DataTableParamComponent } from '../../../../modules/@datatable/components/datatable-param.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpClient } from '../../../../services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../../services/notification.service';
import { debounceTime } from 'rxjs/operators';
import {FancyboxService} from '../../../fancybox.service';
import {ImportEditService} from '../import-edit.service';

@Component({
  templateUrl: './import-edit-confirm.component.html',
  styleUrls: ['./import-edit-confirm.component.scss']
})
export class ImportEditConfirmComponent extends DataTableParamComponent implements OnInit, OnDestroy {

  public api_action = 'Import.dt_modifications';
  public columns: Array<{ [key: string]: any }>;
  public formGroup: UntypedFormGroup;
  public createSubscription: Subscription;
  public feed_id: any;
  public modifications_id = [];
  public views_id = [];
  public check_all = false;
  public objects: any;
  public modification: any;
  public statuses: any;
  public now_in_update_prices = false;
  public now_in_update_plans = false;
  public now_in_update_procs = false;
  public now_in_update_statuses = false;
  public now_in_update_modifications = false;

  @Input() public items_per_page = 20;

  @ViewChild('checkTmpl', {static: true}) private checkTmpl: TemplateRef<any>;
  @ViewChild('checkHeadTmpl', {static: true}) private checkHeadTmpl: TemplateRef<any>;
  @ViewChild('modificationTmpl', {static: true}) private modificationTmpl: TemplateRef<any>;

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router,
              public fb: UntypedFormBuilder, public notify: NotificationService, private lightbox: FancyboxService,
              private importEditService: ImportEditService) {
    super(http, route, router);
    this.feed_id = this.route.snapshot.parent.params['id'];
    this.objects = this.route.snapshot.data['objects'];
    this.modification = this.route.snapshot.data['modification'];
    this.statuses = this.route.snapshot.data['statuses'];
    this.formGroup = this.fb.group({
      'object': [''],
      'category': [''],
      'status': ['waiting'],
    });
    this.formGroup.valueChanges.pipe(debounceTime(500)).subscribe((data) => {
      this.router_param['page'] = 1;
      this.current_page = 1;
      this.loadList();
    });
  }

  ngOnInit() {
    this.columns = [
      {'caption': '', 'name': '', 'sortable': false, 'template': this.checkTmpl, 'templateHead': this.checkHeadTmpl},
      {'caption': 'Обновление от', 'name': 'time', 'sortable': true},
      {'caption': 'Статус', 'name': 'status_caption', 'sortable': false},
      {'caption': 'Тип', 'name': 'category_caption', 'sortable': false},
      {'caption': 'ЖК/корпус', 'name': 'object_caption', 'sortable': false},
      {'caption': 'Изменение', 'name': 'modification_caption', 'sortable': false, 'template': this.modificationTmpl},
    ];
    super.ngOnInit();
  }

  public prepareQuery() {
    const query = super.prepareQuery();
    query['setting_id'] = this.feed_id;
    query['filter']['object'] = this.formGroup.value.object;
    query['filter']['category'] = this.formGroup.value.category;
    query['filter']['status'] = this.formGroup.value.status;

    return query;
  }

  public loadList() {
    super.loadList();
    this.modifications_id = [];
    this.views_id = [];
    this.check_all = false;
  }

  onView(value) {
    this.views_id.push(value);
  }

  getView(value): boolean {
    return this.views_id.indexOf(value) !== -1;
  }

  onCheck(value) {
    const index = this.modifications_id.indexOf(value);
    if (index === -1) {
      this.modifications_id.push(value);
    } else {
      this.modifications_id.splice(index, 1);
    }
  }

  getCheck(value): boolean {
    return this.modifications_id.indexOf(value) !== -1;
  }

  onCheckAll() {
    this.check_all = !this.check_all;
    this.modifications_id = [];
    if (this.check_all) {
      for (const key of Object.keys(this.tbody.rows)) {
        if (this.tbody.rows[key]['is_waiting']) {
          this.modifications_id.push(this.tbody.rows[key]['id']);
        }
      }
    }
  }

  onDenyChecked() {
    if (this.now_in_update_modifications) {
      return false;
    }
    if (!this.modifications_id.length) {
      this.onError('Выберите события');
      return false;
    }
    const data = {
      'setting_id': this.feed_id,
      'modifications_id': this.modifications_id,
    };

    this.http.post('Import.modifications_deny', data).subscribe(
      (response) => {
        this.onSuccess('События успешно отклонены');
        this.loadList();
      },
      (error) => {
        this.onError(error);
      }
    );
  }

  onAcceptChecked() {
    if (this.now_in_update_modifications) {
      return false;
    }
    if (!this.modifications_id.length) {
      this.onError('Выберите события');
      return false;
    }
    const data = {
      'setting_id': this.feed_id,
      'modifications_id': this.modifications_id,
    };

    this.http.post('Import.modifications_accept', data).subscribe(
      (response) => {
        this.onSuccess('События успешно приняты');
        this.loadList();
      },
      (error) => {
        this.onError(error);
      }
    );
  }

  onAcceptAll() {
    if (this.now_in_update_modifications) {
      return false;
    }
    NotificationService.swalConfirm('Принять все?',
      'Вы уверены что хотите принять все изменения целиком (не только на данной странице)?')
      .then(_ => {
        this.now_in_update_modifications = true;
        const data = {
          'setting_id': this.feed_id,
        };
        this.http.post('Import.modifications_all_accept', data).subscribe(
          (response) => {
            this.now_in_update_modifications = false;
            this.importEditService.eraseModifications();
            this.onSuccess('Все события успешно приняты');
            this.loadList();
          },
          (error) => {
            this.now_in_update_modifications = false;
            this.onError(error);
          }
        );
      })
      .catch(_ => false);
  }

  onDenyAll() {
    if (this.now_in_update_modifications) {
      return false;
    }
    NotificationService.swalConfirm('Отменить все?',
      'Вы уверены что хотите отменить все изменения целиком (не только на данной странице)?')
      .then(_ => {
        this.now_in_update_modifications = true;
        const data = {
          'setting_id': this.feed_id,
        };
        this.http.post('Import.modifications_all_deny', data).subscribe(
          (response) => {
            this.now_in_update_modifications = false;
            this.importEditService.eraseModifications();
            this.onSuccess('Все события успешно отменены');
            this.loadList();
          },
          (error) => {
            this.now_in_update_modifications = false;
            this.onError(error);
          }
        );
      })
      .catch(_ => false);
  }

  saveForm() {
    this.router.navigateByUrl('/import');
  }

  displayImage(image) {
    this.lightbox.open([{'src': image, 'thumb': image, 'caption': ''}], 0);
  }

  ngOnDestroy() {
    if (this.createSubscription) {
      this.createSubscription.unsubscribe();
    }
  }

  onSuccess(response?) {
    this.notify.notifySuccess('Поздравляем', response || '', 3000);
  }

  onError(error) {
    this.notify.notifyError('Ошибка', error, 5000);
  }

  onPriceRefresh() {
    if (this.now_in_update_prices) {
      return;
    }
    this.now_in_update_prices = true;

    this.http.post('Import.price_refresh', {'setting_id': this.feed_id}).subscribe(
      (response) => {
        this.notify.notifySuccess('Поздравляем', 'Цены на объектах фида перезаписаны', 3000);
        this.now_in_update_prices = false;
      },
      (error) => {
        this.onError(error);
        this.now_in_update_prices = false;
      }
    );
  }

  onStatusRefresh() {
    if (this.now_in_update_statuses) {
      return;
    }
    this.now_in_update_statuses = true;

    this.http.post('Import.statuses_rewrite', {'setting_id': this.feed_id}).subscribe(
      (response) => {
        this.notify.notifySuccess('Поздравляем', 'Статусы успешно перезаписаны.', 3000);
        this.now_in_update_statuses = false;
      },
      (error) => {
        this.onError(error);
        this.now_in_update_statuses = false;
      }
    );
  }

  onPlansRefresh() {
    if (this.now_in_update_plans) {
      return;
    }
    this.now_in_update_plans = true;

    this.http.post('Import.plans_erase', {'setting_id': this.feed_id}).subscribe(
      (response) => {
        this.notify.notifySuccess('Поздравляем', 'Информация об обработанных ранее планировках была удалена.', 3000);
        this.now_in_update_plans = false;
      },
      (error) => {
        this.onError(error);
        this.now_in_update_plans = false;
      }
    );
  }

  onProcsRefresh() {
    if (this.now_in_update_procs) {
      return;
    }
    this.now_in_update_procs = true;

    this.http.post('Import.procs_rewrite', {'setting_id': this.feed_id}).subscribe(
      (response) => {
        this.notify.notifySuccess('Поздравляем', 'Информация о процентах реализации успешно перезаписана.', 3000);
        this.now_in_update_procs = false;
      },
      (error) => {
        this.onError(error);
        this.now_in_update_procs = false;
      }
    );
  }

}
