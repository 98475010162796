import {AfterContentInit, Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {HttpClient} from '../../../services/http.service';

declare const jQuery: any;

@Directive({
  selector: '[appMaterialInputAutocomplete]'
})
export class MaterialInputAutocompleteDirective implements AfterContentInit {

  @Input('appMaterialInputAutocomplete') private appMaterialInputAutocomplete = '';
  @Output() public autocompleteChange: EventEmitter<string> = new EventEmitter();

  constructor(private elementRef: ElementRef, private http: HttpClient) { }

  ngAfterContentInit() {
    jQuery(this.elementRef.nativeElement).autocomplete({
      appendTo: this.elementRef.nativeElement.parentNode,
      select: (ux, obj) => {
        this.autocompleteChange.emit(obj.item.value);
      },
      source: (request, response) => {
        const data = {'value': jQuery(this.elementRef.nativeElement).val(), 'limit': 10};
        this.http.post(this.appMaterialInputAutocomplete, data)
          .subscribe((r: Array<any>) => {
            response(r.map((x) => {

              if (x.description) {
                return {
                  'label': x.description,
                  'value': x.value,
                };
              }

              return {
                'label': x,
                'value': x,
              };
            }));
          });
      }
    });
  }

}
