import {Component, EventEmitter, HostListener, Input, OnInit, Optional, Output} from '@angular/core';
import {EmbededService} from '../../../services/embeded.service.';

@Component({
    /* tslint:disable-next-line:component-selector-prefix component-selector-name */
    selector: '[app-data-table-head]',
    templateUrl: './datatable-head.component.html',
    styleUrls: ['./datatable-head.component.scss']
})
export class DataTableHeadComponent implements OnInit {

    public sortTrView = false;
    public multisort = false;

    @Output() public sort_emmit: EventEmitter<{[key: string]: string}> = new EventEmitter();
    @Input() public sort: {[key: string]: string} = {};
    @Input() public columns: Array<any> = [];
    @Input() multisortEvent = false;

    @Input('mobile_sort_caption') private mobile_sort_caption: string;
    constructor(@Optional() public embeddedService: EmbededService) { }

    ngOnInit() {
      for (const column of this.columns) {
        if (column.templateHead) {
          this.sortTrView = true;
        }
      }
    }

    prepareSort(column_name: string) {
      if (!this.multisort) {
        for (const key in this.sort) {
          if (column_name !== key) {
            delete this.sort[key];
          }
        }
      }
    }

    @HostListener('document:keydown', ['$event'])
    keyDownEvent(event: KeyboardEvent) {
      if (event.key === 'Shift' && this.multisortEvent) {
        this.multisort = true;
      }
    }

    @HostListener('document:keyup', ['$event'])
    keyUpEvent(event: KeyboardEvent) {
      if (event.key === 'Shift' && this.multisortEvent) {
        this.multisort = false;
      }
    }

    sortChange(column_name: string) {
      this.prepareSort(column_name);

      this.sort[column_name] = !this.sort[column_name] || ('desc' === this.sort[column_name]) ? 'asc' : 'desc';
      this.sort_emmit.emit(this.sort);
    }

    mobileSortChange(direction: string, column_name: string) {
      this.prepareSort(column_name);

      this.sort[column_name] = direction;
      this.sort_emmit.emit(this.sort);
    }
}
