import {AfterViewInit, Component, ComponentRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { DataTableParamComponent } from '../../modules/@datatable/components/datatable-param.component';
import { HttpClient } from '../../services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PhysicalCreateComponent } from './physical-create/physical-create.component';
import { OverlayWindowService } from '../single-window/shared/overlay-window.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {GoBackButtonService} from '../../services/go-back-button.service';

@Component({
  selector: 'app-physical',
  templateUrl: './physical-list.component.html',
  styleUrls: ['./physical-list.component.scss']
})
export class PhysicalListComponent extends DataTableParamComponent implements OnInit, OnDestroy, AfterViewInit {

  public api_action = 'Data_tables.get_crm_physical';
  public columns: Array<{ [key: string]: any }>;
  public formGroup: UntypedFormGroup;
  public subscription: Subscription;
  public createSubscription: Subscription;
  public routerSubscription: Subscription;
  private firstLoad = true;

  @ViewChild('clientTmpl', {static: true}) private clientTmpl: TemplateRef<any>;
  @ViewChild('isUserTmpl', {static: true}) private isUserTmpl: TemplateRef<any>;
  @ViewChild('org2phyCaptiondTmpl', {static: true}) private org2phyCaptiondTmpl: TemplateRef<any>;
  @ViewChild('physicalIdHead', {static: true}) private physicalIdHead: TemplateRef<any>;
  @ViewChild('physicalFio', {static: true}) private physicalFiodHead: TemplateRef<any>;
  @ViewChild('org2phyCaption', {static: true}) private org2phyCaptiondHead: TemplateRef<any>;
  @ViewChild('isUser', {static: true}) private isUserdHead: TemplateRef<any>;
  @ViewChild('userLogin', {static: true}) private userLogindHead: TemplateRef<any>;
  @ViewChild('physicalPhone', {static: true}) private physicalPhonedHead: TemplateRef<any>;
  @ViewChild('physicalEmail', {static: true}) private physicalEmaildHead: TemplateRef<any>;
  @ViewChild('physicalAuthor', {static: true}) private physicalAuthorHead: TemplateRef<any>;

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router,
              public fb: UntypedFormBuilder, private overlayWindowService: OverlayWindowService, public goBack: GoBackButtonService) {
    super(http, route, router);
    this.formGroup = this.fb.group({
      'physical_id': [''],
      'physical_author': [''],
      'physical_fio': [''],
      'org2phy_caption': [''],
      'is_user': [''],
      'user_login': [''],
      'physical_phone': [''],
      'physical_email': [''],
    });
    this.subscription = this.formGroup.valueChanges.pipe(debounceTime(500)).subscribe((data) => {
      if (this.current_page < 2) {
        this.router_param['page'] = 1;
        this.current_page = 1;
      }
      if (this.firstLoad) {
        this.firstLoad = false;
      } else {
        this.changeForm(this.formGroup);
      }
    });
    this.routerSubscription = this.router.events.subscribe((val) => {
      this.goBack.header = '<h1>Физические лица</h1>';
    });
    this.updateForm(this.formGroup);
  }

  ngOnInit() {
    this.goBack.header = '<h1>Физические лица</h1>';
    this.columns = [
      {'caption': '№', 'name': 'physical_id', 'sortable': true, 'templateHead': this.physicalIdHead},
      {'caption': 'ФИО', 'name': 'physical_fio', 'sortable': true, 'templateHead': this.physicalFiodHead},
      {'caption': 'Организация', 'name': 'org2phy_caption', 'sortable': true, 'template': this.org2phyCaptiondTmpl,
        'templateHead': this.org2phyCaptiondHead},
      {'caption': 'Пользователь', 'name': 'is_user', 'sortable': true, 'template': this.isUserTmpl, 'templateHead': this.isUserdHead},
      {'caption': 'Логин', 'name': 'user_login', 'sortable': true, 'templateHead': this.userLogindHead},
      {'caption': 'Телефон', 'name': 'physical_phone', 'sortable': true, 'templateHead': this.physicalPhonedHead},
      {'caption': 'Email', 'name': 'physical_email', 'sortable': true, 'templateHead': this.physicalEmaildHead},
      {'caption': 'Автор', 'name': 'physical_author', 'sortable': true, 'templateHead': this.physicalAuthorHead},
      {'caption': '', 'template': this.clientTmpl}
    ];
    super.ngOnInit();
  }

  ngOnDestroy() {
    if (this.createSubscription) {
      this.createSubscription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  prepareQuery() {
    const result = super.prepareQuery();
    result['filter']['fields'] = {};
    let is_user_bool: any = '';
    if (this.formGroup.value.is_user === 'true') {
      is_user_bool = true;
    } else if (this.formGroup.value.is_user === 'false') {
      is_user_bool = false;
    }

    result['filter']['fields'] = {
      'physical_id': this.formGroup.value.physical_id,
      'physical_author': this.formGroup.value.physical_author,
      'physical_fio': this.formGroup.value.physical_fio,
      'org2phy_caption': this.formGroup.value.org2phy_caption,
      'is_user': is_user_bool,
      'user_login': this.formGroup.value.user_login,
      'physical_phone': this.formGroup.value.physical_phone,
      'physical_email': this.formGroup.value.physical_email,
    };

    return result;
  }

  createPhysical() {
    this.overlayWindowService.openComponentInPopup(PhysicalCreateComponent)
      .then((factory: ComponentRef<PhysicalCreateComponent>) => {
        this.createSubscription = factory.instance.onCreate.subscribe(_ => this.prepareQuery());
      });
  }
  ngAfterViewInit() {
    this.goBack.goScroll();
  }
}
