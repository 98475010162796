<div class="row align-items-center form_crm">
  <div class="col-md-8">
    <app-analytic-filter
      [base_url]="base_url"
      [lock_type]="lock_type">
    </app-analytic-filter>
  </div>
  <div class="col-12 col-md-auto ml-auto">
    <a [href]="urlAttrKpi + user.session_id + '/' + date_options['date_start'] + '/' + date_options['date_end']"
       class="button" target="_blank"
    >
      Скачать<span class="d-none d-md-inline"> все лиды</span>
    </a>
  </div>
</div>

<div style="overflow: auto">
  <div class="pb-4">
    <table class="table">
      <thead>
      <tr>
        <th>Название</th>
        <th *ngFor="let th of data['months']">{{th}}</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let el of data['sources']">
        <tr>
          <td>{{el['fio']}}</td>
          <td *ngFor="let num of el['months']">
              <div [ngClass]="{'activeBox analytic': goBack.activeHash === 'user-' + num.month + '_' + el['id']}">
                <ng-container *ngIf="num.fail">
                  <a href="/analytic/report-attraction-activate/table?month={{num.month}}&date_start={{date_options['date_start']}}&date_end={{date_options['date_end']}}&user_id={{el['id']}}&activated=false'"
                     (click)="goBack.newLink('/analytic/report-attraction-activate/table?month=' + num.month + '&date_start=' + date_options['date_start'] +
                    '&date_end=' + date_options['date_end'] + '&user_id=' + el['id'] + '&activated=false', 'user-' + num.month + '_' + el['id'], $event)">
                    Отклонено {{num.fail}}
                  </a>
                </ng-container>
                <ng-container *ngIf="!num.fail">
                  Отклонено {{num.fail}}
                </ng-container>
                 /
                <ng-container *ngIf="num.finish">
                  <a href="/analytic/report-attraction-activate/table?month={{num.month}}&date_start={{date_options['date_start']}}&date_end={{date_options['date_end']}}&user_id={{el['id']}}&activated=true"
                     (click)="goBack.newLink('/analytic/report-attraction-activate/table?month=' + num.month + '&date_start=' + date_options['date_start'] +
                    '&date_end=' + date_options['date_end'] + '&user_id=' + el['id'] + '&activated=true', 'user-' + num.month + '_' + el['id'], $event)">
                    Активировано {{num.finish}}
                  </a>
                </ng-container>
                <ng-container *ngIf="!num.finish">
                  Активировано {{num.finish}}
                </ng-container>
              </div>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</div>
