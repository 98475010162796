import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

declare const moment;


@Component({
  selector: 'app-analytic-filter',
  templateUrl: './report-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: [
    './report-filter.component.scss'
  ]
})
export class ReportFilterComponent implements OnInit, OnDestroy {
  @Input() private base_url;
  @Input() private base_params;
  @Input() public leadSources;
  @Input() public lock_type = null;

  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
              private r: Router,
              private cd: ChangeDetectorRef,
              private a: ActivatedRoute) { }

  ngOnInit() {

    const start = this.a.params['_value']['start'];
    const stop = this.a.params['_value']['stop'];

    const options = {
      'date_start': start || moment()
        .startOf('year').format('DD.MM.YYYY'),

      'date_end': stop || moment()
        .endOf('year').format('DD.MM.YYYY')
    };
    const params = this.a.parent.snapshot.queryParams['lock'];
    if (params === 'on_period') {
      options['date_start'] = start || moment().startOf('month').format('DD.MM.YYYY');
      options['date_end'] = stop || moment().endOf('month').format('DD.MM.YYYY');
    }
    if (params === 'attraction_source_deal_kpi') {
      options['date_start'] = start || moment().startOf('year').format('DD.MM.YYYY');
      options['date_end'] = stop || moment().endOf('month').format('DD.MM.YYYY');
    }
    if (params === 'report-attraction-activate') {
      options['date_start'] = start || moment().startOf('year').format('DD.MM.YYYY');
      options['date_end'] = stop || moment().endOf('month').format('DD.MM.YYYY');
    }


    if (this.lock_type === 'report-get-sub-activity') {
      options['date_start'] = start || moment().startOf('year').format('DD.MM.YYYY');
      options['date_end'] = stop || moment().endOf('month').format('DD.MM.YYYY');
      options['fact_type'] = this.a.params['_value']['fact_type'];
      options['result_type'] = this.a.params['_value']['result_type'];
      options['sub_date_from'] = this.a.params['_value']['sub_date_from'];
      this.form = this.fb.group({
        'start': [options['date_start'] || '', Validators.required],
        'end': [options['date_end'] || '', Validators.required],
        'fact_type': [options['fact_type'], Validators.required],
        'result_type': [options['result_type'], Validators.required],
        'sub_date_from': [options['sub_date_from'] || ''],
      });
    } else if (this.lock_type === 'report-get-sub-user-activity') {
      options['date_start'] = start || moment().startOf('year').format('DD.MM.YYYY');
      options['date_end'] = stop || moment().endOf('month').format('DD.MM.YYYY');
      options['fact_type'] = this.a.params['_value']['fact_type'];
      options['result_type'] = this.a.params['_value']['result_type'];
      options['sub_date_from'] = this.a.params['_value']['sub_date_from'];
      options['subagent_id'] = this.a.params['_value']['subagent_id'];
      this.form = this.fb.group({
        'start': [options['date_start'] || '', Validators.required],
        'end': [options['date_end'] || '', Validators.required],
        'fact_type': [options['fact_type'], Validators.required],
        'result_type': [options['result_type'], Validators.required],
        'sub_date_from': [options['sub_date_from'] || ''],
        'subagent_id': [options['subagent_id'] || ''],
      });
    } else if (this.lock_type === 'report-get-complex-activity') {
      options['date_start'] = start || moment().startOf('year').format('DD.MM.YYYY');
      options['date_end'] = stop || moment().endOf('month').format('DD.MM.YYYY');
      options['fact_type'] = this.a.params['_value']['fact_type'];
      options['result_type'] = this.a.params['_value']['result_type'];
      options['complex_type'] = this.a.params['_value']['complex_type'];
      options['events'] = this.a.params['_value']['events'] === 'exist' ? true : false;
      options['vitrina'] = this.a.params['_value']['vitrina'] === 'exist' ? true : false;
      this.form = this.fb.group({
        'start': [options['date_start'] || '', Validators.required],
        'end': [options['date_end'] || '', Validators.required],
        'fact_type': [options['fact_type'], Validators.required],
        'result_type': [options['result_type'], Validators.required],
        'sub_date_from': [options['sub_date_from'] || ''],
        'complex_type': [options['complex_type'] || 'all'],
        'events': [options['events'] || false],
        'vitrina': [options['vitrina'] || false],
      });
    } else if (this.lock_type === 'report-get-activity-complex-id') {
      options['date_start'] = start || moment().startOf('year').format('DD.MM.YYYY');
      options['date_end'] = stop || moment().endOf('month').format('DD.MM.YYYY');
      options['fact_type'] = this.a.params['_value']['fact_type'];
      options['result_type'] = this.a.params['_value']['result_type'];
      options['one_complex_id'] = this.a.params['_value']['one_complex_id'];
      this.form = this.fb.group({
        'start': [options['date_start'] || '', Validators.required],
        'end': [options['date_end'] || '', Validators.required],
        'fact_type': [options['fact_type'], Validators.required],
        'result_type': [options['result_type'], Validators.required],
        'sub_date_from': [options['sub_date_from'] || ''],
        'one_complex_id': [options['one_complex_id'] || ''],
      });
    } else if (this.lock_type === 'common_attraction') {
      options['bp_source'] = this.a.parent.snapshot.queryParams['bp_source'];
      this.form = this.fb.group({
        'start': [options['date_start'] || '', Validators.required],
        'end': [options['date_end'] || '', Validators.required],
        'bp_source': [options['bp_source'] || ''],
      });
    } else {
      this.form = this.fb.group({
        'start': [options['date_start'] || '', Validators.required],
        'end': [options['date_end'] || '', Validators.required],
      });
    }



  }

  submit() {
    if (this.lock_type === 'report-get-sub-activity') {
      this.r.navigate(
        [this.base_url, this.form.value['fact_type'], this.form.value['result_type'], this.form.value['start'], this.form.value['end'], this.form.value['sub_date_from']]
      );
    } else if (this.lock_type === 'report-get-sub-user-activity') {
      this.r.navigate(
        [this.base_url, this.form.value['subagent_id'], this.form.value['fact_type'], this.form.value['result_type'], this.form.value['start'], this.form.value['end'], this.form.value['sub_date_from']]
      );
    } else if (this.lock_type === 'report-get-complex-activity') {
      this.r.navigate(
        [this.base_url, this.form.value['fact_type'], this.form.value['result_type'],  this.form.value['complex_type'],  this.form.value['events'] ? 'exist' : 'all',  this.form.value['vitrina'] ? 'exist' : 'all', this.form.value['start'], this.form.value['end']]
      );
    } else if (this.lock_type === 'report-get-activity-complex-id') {
      this.r.navigate(
        [this.base_url, this.form.value['fact_type'], this.form.value['result_type'], this.form.value['start'], this.form.value['end'],  this.form.value['one_complex_id']]
      );
    } else if (this.lock_type === 'common_attraction') {
      const obj = {
        'lock': this.lock_type
      }
      if (this.form.value['bp_source']) {
        obj['bp_source'] = this.form.value['bp_source'];
      }
      this.r.navigate(
        [this.base_url, this.form.value['start'], this.form.value['end']],
        {queryParams: obj}
      );
    } else {
      this.r.navigate(
        [this.base_url, this.form.value['start'], this.form.value['end']],
        {queryParams: {'lock': this.lock_type}}
      );
    }
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }
  ngOnDestroy(): void {
    if (!this.cd['destroyed']) {
      this.cd.detach();
    }
  }
}
