<form [formGroup]="form" *ngIf="form" novalidate (submit)="onSubmit()">
  <div class="row no-gutters gutters-4">
    <div class="col-12">
      <app-material-input formControlName="fio"
                          [placeholder]="'ФИО'"
                          [require]="true">
        ФИО</app-material-input>
    </div>

    <div class="col-12">
      <app-material-input-mask formControlName="phone" [mask]="'+7 (000) 000-00-00'" [require]="true"
                               [placeholder]="'+7 (___) ___-__-__'">
        Телефон</app-material-input-mask>
    </div>
    <div class="col-12">
      <app-material-input formControlName="email"
                          [placeholder]="'Email'">
        Email</app-material-input>
    </div>
  </div>
  <div class="row no-gutters gutters-2 mt-3">
    <div class="col-md-10">
      <button type="submit" class="button" [disabled]="!form.valid">Создать менеджера</button>
    </div>
  </div>
</form>
