<div class="form__group">
  <div class="form__label" [class.form__label_require]="require">{{caption}}
    <ng-container *ngIf="multiple && usageCrm && noCopy">
      <i class="fa fa-fw fa-copy c-pointer" (click)="copyToClipboard()"></i>
    </ng-container>
  </div>
  <span class="multiselect-native-select">
    <select class="md-form-control" #select [attr.multiple]="multiple ? 'multiple' : null"
            [disabled]="disabled" [attr.data-placeholder]="placeholder"
            [appMaterialSelect]="options" (select2_change)="onSelectChange($event)" data-placeholder="">
      <ng-content></ng-content>
    </select>
  </span>
</div>
