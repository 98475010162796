Всего: <b>{{count}}</b>
<div class="mb-2">
  <div class="row no-gutters align-items-center m-0">
    <div class="col-md-6 col-lg-6 col-xl-4">
      <div class="form__group">
        <div class="form__input">
          <input class="input" type="text" placeholder="Поиск"
                 [ngModel]="filter" (ngModelChange)="onFilterChanges($event)">
          <div class="form__input_icon icon icon_Small_Search c-pointer ng-star-inserted"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="material-datatable-wrapper table-color">
  <div class="table-responsive">
    <table class="table">
      <tr>
        <ng-container *ngFor="let el of head" >
          <th [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
            <div [formGroup]="form">
              <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
                <div class="col table__input">
                  <ng-container *ngIf="el.name === 'new_org' || el.name === 'count_leads' || el.name === 'count_leads_db'">
                    {{el.caption}}
                  </ng-container>
                  <ng-container *ngIf="el.name !== 'new_org' && el.name !== 'count_leads' && el.name !== 'count_leads_db'">
                    <app-material-input
                      [placeholder] = "el.caption"
                      [formControlName] = "el.name"
                      [cssClass]="'mb-0'">
                    </app-material-input>
                  </ng-container>
                </div>
                <div class="col-auto" *ngIf="el.sort">
                  <a href="javascript:void(0)" (click)="sortChange(el.name)">
                    <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                        'icon_new-sort-down': 'asc' == sort[el.name],
                                        'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                  </a>
                </div>
              </div>
            </div>
          </th>
        </ng-container>
      </tr>
      <tr *ngFor="let row of rows; let ind = index">
        <td>
          {{row.organisation_subagent_manager_id}}
        </td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'organisation_caption-' + ind}">
            <a href="/entity/edit/{{row.organisation_id}}/common"
               (click)="goBack.newLink('/entity/edit/' + row.organisation_id + '/common', 'organisation_caption-' + ind, $event)">
              {{row.organisation_caption}}
            </a>
          </div>
        </td>
        <td>
          {{row.organisation_address}}
        </td>
        <td>
          {{row.organisation_inn}}
        </td>
        <td>
          {{row.organisation_author}}
        </td>
        <td>
          {{row.count_leads}}
        </td>
        <td>
          {{row.count_leads_db}}
        </td>
        <td>
          {{row.new_org ? 'Новая организация' : ''}}
        </td>
        <td>
          <div *ngFor="let obj of row.event_list; let indEvent=index">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'event_caption-' + ind + indEvent}">
              <a href="/calendar/edit/{{obj.id}}"
                 (click)="goBack.newLink('/calendar/edit/' + obj.id, 'event_caption-' + ind + indEvent, $event)">
                {{obj.caption}} - {{obj.event_date}}
              </a>
            </div>
          </div>
        </td>
      </tr>
      </table>
  </div>
</div>

<div class="alt-pagination">
  <app-paginator
    [queryParams]="filter_columns"
    [itemsPerPage]="20"
    [itemCount]="count"
    [currentPage]="current_page"
    [baseUrl]="'/analytic/calendar-summary-organisation/table'">
  </app-paginator>
</div>
