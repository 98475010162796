import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef, ContentChild,
  Directive, ElementRef, EventEmitter,
  HostListener, Input, OnDestroy, Output, Renderer2, Type, ViewContainerRef,
} from '@angular/core';
import {MobileSortComponent, MobileSortVitrineComponent} from './components';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[mobile-sort]',
})
export class MobileSortDirective implements OnDestroy {

  public _mobile_sort_sort: string;
  @Input('mobile_sort_caption') private mobile_sort_caption: string;
  @Input('mobile_sort_vitrine') private mobile_sort_vitrine: false;
  @Input('mobile_sort_exist') private mobile_sort_exist: string;
  @Input('mobile_sort_sort') public set mobile_sort_sort(value: string) {
    this._mobile_sort_sort = value;
  }
  public get mobile_sort_sort(): string {
    return this._mobile_sort_sort;
  }
  @Input('mobile_sort_template') private mobile_sort_template;
  @Output() mobile_sort_change = new EventEmitter<any>();
  @ContentChild('mobileSortContainer', { read: ViewContainerRef }) public ct: ViewContainerRef;

  private cr: ComponentRef<any> = null;
  public formGroup: UntypedFormGroup;
  private formSubscription: Subscription;
  private closeSubscription: Subscription;

  private loaded = false;

  constructor(private factoryResolver: ComponentFactoryResolver, private renderer: Renderer2, private fb: UntypedFormBuilder, private elemRef: ElementRef) { }

  @HostListener('click', ['$event'])
  private onClick(event) {
    if (!this.loaded && !this.mobile_sort_vitrine) {
      this.formGroup = this.fb.group({
        'sort': [this.mobile_sort_sort ? this.mobile_sort_sort : '']
      });
      this.formSubscription = this.formGroup.valueChanges
        .subscribe(x => {
          this.mobile_sort_change.next(x.sort);
        });
      this.loadComponent(MobileSortComponent, {
        'caption': this.mobile_sort_caption,
        'template': this.mobile_sort_template,
        'sort_exist': this.mobile_sort_exist,
        'formGroup': this.formGroup
      });
      this.loaded = true;
    }
    if (!this.loaded && this.mobile_sort_vitrine) {
      this.formGroup = this.fb.group({
        'sort_caption': this.mobile_sort_caption,
        'sort': this.mobile_sort_sort
      });
      this.formSubscription = this.formGroup.valueChanges.pipe(debounceTime(100))
        .subscribe(x => {
          this.mobile_sort_change.next({
              'sort_caption': x.sort_caption,
              'sort': x.sort
            });
        });
      this.loadComponent(MobileSortVitrineComponent, {
        'formGroup': this.formGroup
      });
      this.loaded = true;
    }
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onDocClick(event: MouseEvent, targetElement: HTMLElement) {
    if (this.loaded && targetElement) {
      const clickedInside = this.elemRef.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.unloadComponent();
      }
    }
  }
  public loadComponent<T>(componentType: Type<T>, options?: {[k: string]: any}) {
    const factory: ComponentFactory<T> = this.factoryResolver.resolveComponentFactory(
      componentType);
    if (!this.cr) {
      this.cr = this.ct.createComponent(factory);
      if (options) {
        for (const key in options) {
          this.cr.instance[key] = options[key];
        }
      }
      this.renderer.addClass(this.ct.element.nativeElement.parentElement, 'active');
      this.renderer.addClass(this.elemRef.nativeElement, 'c-red');
      this.closeSubscription = this.cr.instance.close.subscribe(x => {
        if (x) {
          this.unloadComponent();
        }
      });
    }
  }

  public unloadComponent() {
    this.renderer.removeClass(this.ct.element.nativeElement.parentElement, 'active');
    this.renderer.removeClass(this.elemRef.nativeElement, 'c-red');
    if (this.cr) {
      this.cr.destroy();
      this.cr = null;
      this.loaded = false;
    }
    this.ct.clear();
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    if (this.closeSubscription) {
      this.closeSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    if (this.closeSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

}
