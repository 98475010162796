import {Injectable} from '@angular/core';

declare const google;

@Injectable()
export class GoogleChartsService {

  private readonly script_url = 'https://www.gstatic.com/charts/loader.js';
  private readonly script_type = 'text/javascript';
  private readonly script_id = 'g-charts';

  private loading: Promise<any> = null;

  public load(): Promise<any>{
    if (!this.loading) {
      const script = document.createElement('script');
      script.type = this.script_type;
      script.src = this.script_url;
      script.id = this.script_id;

      this.loading = new Promise<void>((resolve: (google) => void, reject: () => void) => {
        script.onload = () => resolve(google);
        script.onerror = () => reject();
      });

      document.body.appendChild(script);
    }

    return this.loading;
  }
}
