import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BreadcrumbsService} from '../../../../shared/breadcrumbs/services';
import {GoBackButtonService} from '../../../../services/go-back-button.service';
import {Subscription} from 'rxjs';
import {ReportComponent} from '../report/report.component';
import {environment} from '../../../../../environments/environment';
import {AuthenticateService} from '../../../../services/authenticate.service';

declare const moment;

@Component({
  templateUrl: './report-kpi.component.html',
  styleUrls: [
    './report-kpi.component.scss'
  ]
})
export class ReportKpiComponent extends ReportComponent implements OnInit, OnDestroy {

  public urlKpi = environment.urlKpi;

  public routerSubscription: Subscription = null;
  public subscribtionUser: Subscription = null;


  public data: any;
  public lock_type = null;
  public user = null;
  public date_options = {};

  constructor(public r: Router,
              public a: ActivatedRoute,
              public b: BreadcrumbsService,
              public goBack: GoBackButtonService,
              private auth: AuthenticateService) {
    super(r, a, b);
  }

  public get base_url() {
    return '/analytic/report-kpi/';
  }

  public get filter_url() {
    const a = [this.base_url];

    if (this.a.snapshot.params['start']) {
      a.push(this.a.snapshot.params['start']);
    }

    if (this.a.snapshot.params['stop']) {
      a.push(this.a.snapshot.params['stop']);
    }

    return a.join('/');
  }

  ngOnInit() {
    super.ngOnInit();
    this.lock_type = this.a.snapshot.queryParams['lock'] || null;
    this.subscribtionUser = this.auth.emit_user.subscribe((user) => this.user = user);
    this.data = this.a.snapshot.data['data'];

    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/analytic/index', caption: 'Аналитика'},
    ]);
    this.goBack.header = '<h2>KPI отдела привлечения по закрытым сделкам</h2>';
    this.routerSubscription = this.r.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.goBack.header = '<h2>KPI отдела привлечения по закрытым сделкам</h2>';
      }
    });
    this.date_options['date_start'] = this.a.snapshot.params['start'] || moment().startOf('year').format('DD.MM.YYYY');
    this.date_options['date_end'] = this.a.snapshot.params['stop'] || moment().endOf('month').format('DD.MM.YYYY');
  }

  whenUrlChange() {
    super.whenUrlChange();
    this.lock_type = this.a.snapshot.queryParams['lock'] || null;
    this.date_options['date_start'] = this.a.snapshot.params['start'] || moment().startOf('year').format('DD.MM.YYYY');
    this.date_options['date_end'] = this.a.snapshot.params['stop'] || moment().endOf('month').format('DD.MM.YYYY');
    this.data = this.a.snapshot.data['data'];
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/analytic/index', caption: 'Аналитика'},
    ]);
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.subscribtionUser) {
      this.subscribtionUser.unsubscribe();
    }
  }

}
