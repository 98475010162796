import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {JsonClientService} from '../../../shared/json-client';
import {NotificationService} from '../../../services/notification.service';
import {ActivatedRoute} from '@angular/router';
import {GoBackButtonService} from '../../../services/go-back-button.service';

@Component({
  selector: 'app-managers-edit',
  templateUrl: './managers-edit.component.html',
  styleUrls: [
    './managers-edit.component.scss'
  ]
})
export class ManagersEditComponent implements OnInit {

  public form: UntypedFormGroup;
  public id: any = null;
  public data: any = null;
  constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder, public http: JsonClientService, private notify: NotificationService,
              public goBack: GoBackButtonService) {

    this.id = this.route.snapshot.params['id'];
    this.data = this.route.snapshot.data['data'];
  }

  ngOnInit() {
    this.goBack.header = '<h1>Редактирование менеджера - ' + this.data.fio + '</h1>';
    this.form = this.fb.group({
      'fio': [this.data.fio, Validators.required],
      'phone': [this.data.phone, Validators.required],
      'email': [this.data.email],
    });
  }

  onSubmit() {
    const data = {
      'fio': this.form.value.fio,
      'phone': this.form.value.phone,
      'email': this.form.value.email,
    };

    this.http.post('Developer.manager_update', {'manager_id': this.id,  data}).then(
      response => {
        const fio = this.form.value.fio;
        this.notify.notifySuccess('Менеджер успешно изменен', fio, 5000);
      },
      error => { }
    );
  }
}
