import {Component, EventEmitter, Input, Output} from '@angular/core';
import {HttpClient} from '../../../services/http.service';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'app-settings-constraints-subagents',
  templateUrl: './settings-constraints-subagents.component.html',
  styleUrls: ['./settings-constraints-subagents.component.scss']
})

export class SettingsConstraintsSubagentsComponent {

  private _subagent_id = null;
  public layout_opened = false;
  public constrains: Array<any>;
  public loading = false;

  constructor(public http: HttpClient, public notify: NotificationService) {}

  @Input() private set subagent_id(value) {
    if (!value) { return; }
    this._subagent_id = value;
    this.loading = true;
    this.loadData();
  }

  private get subagent_id() {
    return this._subagent_id;
  }

  @Output() public onClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onDeleted: EventEmitter<void> = new EventEmitter<void>();

  private loadData() {
    this.layout_opened = true;
    this.http.post('RealtyList.get_constrains_subagent', {'subagent_id': this.subagent_id})
      .subscribe(x => {
        this.constrains = x;
        this.loading = false;
      });
  }

  deleteContraints() {
    NotificationService.swalConfirm('Удалить?', 'Ограничения будут удалены безвозвратно, Вы уверены?')
      .then(() => {
        this.http.post('RealtyList.delete_constrains_subagent', {'subagent_id': this.subagent_id})
          .subscribe(x => {
            this.notify.notifySuccess('Поздравляем', 'Ограничения успешно удалены', 3000);
            this.loading = false;
            this.onDeleted.emit();
            this.closeEvent();
          });
      }).catch(() => this.loading = false);
  }

  closeEvent() {
    this.layout_opened = false;
    this.onClosed.emit();
  }
}
