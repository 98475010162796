<div class="form__group">
  <div class="form__label" [class.form__label_require]="require && !disabled" [class.form__label_error]="error">
    <ng-content></ng-content>
  </div>
  <div class="form__input" [class.form__input_disabled]="disabled"  [class.form__input_focus]="focus"
       [class.form__input_error]="error">
    <input [type]="type" class="input" [value]="current_value" [disabled]="disabled"
           [appMaterialInputAutocompleteCustom]="api_action" (autocompleteChange)="onAutocopleteChange($event)"
           (focus)="focus = true" [placeholder]="placeholder"
           (blur)="focus = false"
           [ngClass]="{'md-valid': current_value && current_value.length}"
           (keyup)="onChanges($event.target)" [nameValue]="nameValue" [nameLabel]="nameLabel">
  </div>
</div>

