import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { HttpClient } from '../../../services/http.service';
import { DataTableComponent } from './datatable.component';


@Component({
  selector: 'app-material-table-param',
  templateUrl: './datatable.component.html'
})
export class DataTableParamComponent extends DataTableComponent implements OnInit, OnDestroy {

  public current_url: any;
  public params: { [key: string]: string };
  public router_param = {};
  public fields = {};

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router) {
    super(http);

    const url = this.router.url;
    const urlTree = this.router.parseUrl(url);
    this.current_url = urlTree.root.children['primary'].segments.map(it => it.path).join('/');
    this.params = this.route.snapshot.queryParams;
    this.getQueryParams(this.params);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onGlobalSearch(value) {
    super.onGlobalSearch(value);

    this.router_param['global_search_string'] = this.global_search_string;
    this.router_param['page'] = 1;
    this.setRoute();
  }

  updateUrl() {
    const url = this.router.url;
    const urlTree = this.router.parseUrl(url);
    this.current_url = urlTree.root.children['primary'].segments.map(it => it.path).join('/');
    this.params = this.route.snapshot.queryParams;
  }

  changePage(page: number) {
    super.changePage(page);

    this.router_param['page'] = String(page);
    this.setRoute();
  }

  onSortChange(sort: any) {
    super.onSortChange(sort);

    for (const sort_key of Object.keys(sort)) {
      this.router_param['sort_name'] = sort[sort_key];
      this.router_param['sort_key'] = sort_key;
    }
    this.setRoute();
  }

  setRoute() {
    const newValues = {
      'fields_name': [],
      'fields_value': [],
    }
    if (this.router_param['fields_value'] && this.router_param['fields_value'].length > 0) {
      for (let i = 0; i < this.router_param['fields_value'].length; i++) {
        if (this.router_param['fields_value'][i]) {
          newValues.fields_name.push(this.router_param['fields_name'][i]);
          newValues.fields_value.push(this.router_param['fields_value'][i]);
        }
      }
      if (newValues.fields_name.length > 0) {
        this.router_param['fields_name'] = newValues.fields_name;
        this.router_param['fields_value'] = newValues.fields_value;
      } else {
        delete this.router_param['fields_name'];
        delete this.router_param['fields_value'];
      }
    }
    this.router.navigate([this.current_url], {queryParams: this.router_param});
  }

  getQueryParams(params: {}) {
    if (params['page']) {
      this.router_param['page'] = String(params['page']);
      this.current_page = params['page'];
    }
    if (params['global_search_string']) {
      this.router_param['global_search_string'] = String(params['global_search_string']);
      this.global_search_string = params['global_search_string'];
    }
    if (params['sort_name'] && params['sort_key']) {
      this.router_param['sort_name'] = String(params['sort_name']);
      this.router_param['sort_key'] = String(params['sort_key']);
      const sorting = {};
      sorting[params['sort_key']] = params['sort_name'];
      this.sort = sorting;
    }
    if (params['fields_name'] && params['fields_value']) {
      this.router_param['fields_name'] = params['fields_name'];
      this.router_param['fields_value'] = params['fields_value'];
      const fields = {};
      if (typeof params['fields_name'] === 'string') {
        fields[params['fields_name']] = params['fields_value'];
      } else {
        for (let i = 0; i < params['fields_name'].length; i++) {
          fields[params['fields_name'][i]] = params['fields_value'][i];
        }
      }

      this.fields = fields;
    }
  }

  getField(field) {
    if (field in this.fields) {
      return this.fields[field];
    } else {
      return '';
    }
  }

  changeForm(form) {
    this.router_param['fields_name'] = Object.keys(form.value);
    this.router_param['fields_value'] = Object.keys(form.value).map(key => form.value[key]);
    this.setRoute();
    this.loadList();
  }

  updateForm(form, exclude = null, array = null) {
    const formFields = {};
    Object.keys(form.value).forEach(key => {
      if (exclude) {
        if (exclude.indexOf(key) >= 0 && this.getField(key) !== '') {
          formFields[key] = this.getField(key);
        }
      } else if (array) {
        if (array.indexOf(key) >= 0) {
          formFields[key] = this.getField(key) ? this.getField(key).split(',') : [];
        }
      } else {
        formFields[key] = this.getField(key);
      }
    });
    form.patchValue(formFields);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
