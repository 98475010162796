import {Component, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../services/notification.service';
import {Observable, Subscription} from 'rxjs';
import {JsonClientService} from '../../../../shared/json-client';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticateService} from '../../../../services/authenticate.service';
import {BreadcrumbsService} from '../../../../shared/breadcrumbs/services';
import {GoBackButtonService} from '../../../../services/go-back-button.service';

@Component({
  selector: 'app-attraction-lead-page-create',
  templateUrl: './attraction-lead-page-create.component.html',
  styleUrls: ['./attraction-lead-page-create.component.scss']
})
export class AttractionLeadPageCreateComponent implements OnInit, OnDestroy {

  public form: UntypedFormGroup;
  public stage_forms: any;
  public leadSources: any;
  public subagentAreaWork: any;
  public posts: any = null;
  public nalogForms: Array<any> = [];
  private stageSubscription: Subscription;

  public entityRequisitesData: any;
  public entityData: any;
  public physicalData: any;
  public descriptionStage = '';

  constructor(private http: JsonClientService, private fb: UntypedFormBuilder, private b: BreadcrumbsService,
              private notify: NotificationService, private router: Router, private goBack: GoBackButtonService,
              private auth: AuthenticateService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/attraction/lead-info/today', caption: 'Лиды по привлечению'},
    ]);
    this.goBack.header = '<h1>Создание лида</h1>';
    this.stage_forms = this.route.snapshot.data['forms'];
    this.leadSources = this.route.snapshot.data['leadSources'];
    this.subagentAreaWork = this.route.snapshot.data['subagentAreaWork'];
    this.nalogForms = this.route.snapshot.data['nalogForms'];
    this.posts = this.route.snapshot.data['posts'];
    this.descriptionStage = this.stage_forms[0].description;

    this.form = this.fb.group({
      'lastName': [''],
      'firstName': ['', Validators.required],
      'middleName': [''],
      'newsubagent': [false],
      'email': [''],
      'phone': ['', Validators.required],
      'notice': [''],
      'ci_subagent_id': [''],
      'ci_source_physical_id': ['', Validators.required],
      'source_id': [this.leadSources[0].id],
      'worker_id': [this.auth.user.id],
      'worker_caption': [this.auth.user.fio + ' ' + this.auth.user.ucaption],
      'stage_id': [this.stage_forms[0].id, Validators.required],
      'stage_caption': [this.stage_forms[0].caption],
      'next_contact_date': [this.stage_forms[0].next_contact_date, Validators.required],
      'next_contact_time': [this.stage_forms[0].next_contact_time, [Validators.pattern(/^(0?\d:[0-5]\d|1\d:[0-5]\d|2[0-3]:[0-5]\d|)$/)]],
      'next_contact_comment': [''],
      'ci_subagent_area_worker_id': ['']
    });
    this.form.addControl('physical', new UntypedFormGroup({}));
    this.form.addControl('entity', new UntypedFormGroup({}));
    this.form.addControl('entityRequisitesData', new UntypedFormGroup({}));

    this.stageSubscription = this.form.get('stage_id').valueChanges.subscribe(val => {

      if (val) {
        const result = this.stage_forms.filter(function( obj ) {
          return obj.id === val;
        });
        this.descriptionStage = result[0].description;
        this.form.patchValue({
          'next_contact_date': result[0].next_contact_date,
          'next_contact_time': result[0].next_contact_time,
        });
      } else {
        this.descriptionStage = '';
      }

    });
    this.form.get('newsubagent').valueChanges.subscribe(val => {
      if (!val) {
        this.form.setControl('physical', new UntypedFormGroup({}));
        this.form.setControl('entity', new UntypedFormGroup({}));
        this.form.setControl('entityRequisitesData', new UntypedFormGroup({}));
        this.form.get('ci_subagent_id').patchValue('');
      } else {
        const group = this.form.get('physical') as UntypedFormGroup;
        group.addControl('user_login', this.fb.control('', Validators.required));
        group.addControl('pass_new', this.fb.control('', Validators.required));
        group.addControl('pass_new_repeat', this.fb.control('', Validators.required));
        group.addControl('organisation_post_id', this.fb.control('', Validators.required));
      }
    });
  }

  ngOnDestroy() {
    if (this.stageSubscription) {
      this.stageSubscription.unsubscribe();
    }
  }

  onSubmit() {

    if (this.form.get('physical').value.pass_new !== this.form.get('physical').value.pass_new_repeat) {
      this.notify.notifyError('Ошибка', 'пароли не совпадают',  5000);
      return false;
    }

    const data = {
      'data': {
        'worker_id': this.form.value.worker_id,
        'lastName': this.form.value.lastName,
        'firstName': this.form.value.firstName,
        'middleName': this.form.value.middleName,
        'email': this.form.value.email,
        'source_id': this.form.value.source_id,
        'newsubagent': this.form.value.newsubagent,
        'entity': this.entityData,
        'entity_requisites': this.entityRequisitesData,
        'physical': Object.assign(this.physicalData, {
                      'user_login': this.form.get('physical').value.user_login,
                      'user_password': this.form.get('physical').value.pass_new,
                      'organisation_post_id': this.form.get('physical').value.organisation_post_id,
                      'user_is_subagent': true,
                    }),
        'ci_source_physical_id': this.form.value.ci_source_physical_id,
        'phone': this.form.value.phone,
        'notice': this.form.value.notice,
        'ci_subagent_area_worker_id': this.form.value.ci_subagent_area_worker_id
      },
      'stage_id': this.form.value.stage_id,
      'next_contact_date': this.form.value.next_contact_date,
      'next_contact_time': this.form.value.next_contact_time,
      'next_contact_comment': this.form.value.next_contact_comment,
    };

    if (!data.data.newsubagent) {
      data['ci_subagent_id'] = this.form.value.ci_subagent_id;
    }
    this.http.post('Bp.lead_ci_create', data).then(
      response => {
        const fio = this.form.value.lastName + ' ' + this.form.value.firstName + ' ' + this.form.value.middleName;
        this.notify.notifySuccess('Лид успешно создан', fio,  5000);
        this.router.navigate(['/attraction', 'lead-info', response]);
      },
      error => { }
    );
  }

}
