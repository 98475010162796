<div class="dropzone dz-clickable" (click)="onClick()" #dropZone [class.d-none]="disable" [style]="{'min-height': min_height + 'px'}">
  <div class="dz-default dz-message">
    <span *ngIf="dropzoneCaption" [innerHtml]="dropzoneCaption"></span>
    <p *ngIf="!dropzoneCaption" class="mb-0">
      Перетащите файл сюда или нажмите <span class="dz-dash">тут</span>
    </p>
  </div>
  <input type="file" [hidden]="true" (change)="onFileChange()" #fileInput>
</div>
<app-material-dropzone-table *ngIf="current_value && current_value.length" [disable]="disable" [files]="current_value"
                             [hasComments]="hasComments" (file_remove)="onFileRemove($event)"
                             (comment_cahnge)="onCommentChange($event)">
</app-material-dropzone-table>
