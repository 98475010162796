import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataTableParamComponent} from '../../modules/@datatable/components/datatable-param.component';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {BackrefWindowService} from '../single-window/shared/backref-window.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '../../services/http.service';
import {OverlayWindowService} from '../single-window/shared/overlay-window.service';
import {GoBackButtonService} from '../../services/go-back-button.service';

@Component({
  selector: 'app-reference',
  templateUrl: './reference.component.html',
  styleUrls: [
    './reference.component.scss'
  ]
})
export class ReferenceComponent extends DataTableParamComponent implements OnInit, OnDestroy, AfterViewInit {

  public api_action = 'Data_tables.contacts_reference';
  public columns: Array<{ [key: string]: any }>;
  public formGroup: UntypedFormGroup;
  public createSubscription: Subscription;
  public subscription: Subscription;
  private firstLoad = true;

  @ViewChild('idHead', {static: true}) private idHead: TemplateRef<any>;
  @ViewChild('nameHead', {static: true}) private nameHead: TemplateRef<any>;
  @ViewChild('nameTmpl', {static: true}) private nameTmpl: TemplateRef<any>;
  @ViewChild('namekorpHead', {static: true}) private namekorpHead: TemplateRef<any>;
  @ViewChild('namekorpTmpl', {static: true}) private namekorpTmpl: TemplateRef<any>;
  @ViewChild('addressHead', {static: true}) private addressHead: TemplateRef<any>;
  @ViewChild('contactsHead', {static: true}) private contactsHead: TemplateRef<any>;
  @ViewChild('developerTmpl', {static: true}) private developerTmpl: TemplateRef<any>;
  @ViewChild('developerHead', {static: true}) private developerHead: TemplateRef<any>;
  @ViewChild('managerTmpl', {static: true}) private managerTmpl: TemplateRef<any>;
  @ViewChild('managerObjTmpl', {static: true}) private managerObjTmpl: TemplateRef<any>;

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router,
              public fb: UntypedFormBuilder, private overlayWindowService: OverlayWindowService, public goBack: GoBackButtonService,
              public backrefWindowService: BackrefWindowService) {
    super(http, route, router);
    this.formGroup = this.fb.group({
      'complex_id': [''],
      'complex_caption': [''],
      'housing_id': [''],
      'housing_caption': [''],
      'housing_address': [''],
      'developer_caption': [''],
      'contacts': [''],
    });
    this.subscription = this.formGroup.valueChanges.pipe(debounceTime(500))
      .subscribe((data) => {
        this.router_param['page'] = 1;
        this.current_page = 1;
        if (this.firstLoad) {
          this.firstLoad = false;
        } else {
          this.changeForm(this.formGroup);
        }
      });

    this.updateForm(this.formGroup);
    this.backrefWindowService.routerInit();
  }

  ngOnInit() {
    this.goBack.header = '<h1>Справка - сотрудники на объектах</h1>';
    this.columns = [
      {'caption': '№', 'name': 'complex_id', 'sortable': true, 'width': '90px',
        'templateHead': this.idHead},
      {'caption': 'Название комплекса', 'name': 'complex_caption', 'sortable': true,
        'template': this.nameTmpl, 'templateHead': this.nameHead},
      {'caption': 'Название корпуса', 'name': 'housing_caption', 'sortable': true,
        'template': this.namekorpTmpl, 'templateHead': this.namekorpHead},
      {'caption': 'Адрес', 'name': 'housing_address', 'sortable': true,
        'templateHead': this.addressHead},
      {'caption': 'Контакты', 'name': 'contacts', 'sortable': true,
        'templateHead': this.contactsHead},
      {'caption': 'Застройщик', 'name': 'developer_caption', 'sortable': true,
        'template': this.developerTmpl, 'templateHead': this.developerHead},
      {'caption': 'Руководители', 'name': 'eat_bosses', 'sortable': true,
        'template': this.managerTmpl},
      {'caption': 'Менеджеры на объекте', 'name': 'eat_managers', 'sortable': true,
        'template': this.managerObjTmpl},
    ];
    super.ngOnInit();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.createSubscription) {
      this.createSubscription.unsubscribe();
    }
    this.backrefWindowService.routerDestroy();
  }

  prepareQuery() {
    const result = super.prepareQuery();
    result['filter']['fields'] = {
      'complex_id': this.formGroup.value.id,
      'complex_caption': this.formGroup.value.complex_caption,
      'housing_id': this.formGroup.value.housing_id,
      'housing_caption': this.formGroup.value.housing_caption,
      'housing_address': this.formGroup.value.housing_address,
      'developer_caption': this.formGroup.value.developer_caption,
      'contacts': this.formGroup.value.contacts,
    };

    return result;
  }

  ngAfterViewInit() {
    this.goBack.goScroll();
  }
}
