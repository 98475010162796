import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {JsonClientService} from '../../shared/json-client';


@Injectable()
export class EmailExceptionResolve implements Resolve<any> {

  constructor(private jsonRpc: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    return this.jsonRpc.post('Subagent.get_email_excepted');
  }

}
