import {NgModule} from '@angular/core';
import {ComplaintsDatatableModule} from './complaints-datatble.module';

@NgModule({
  imports: [
    ComplaintsDatatableModule,
  ],
  exports: [
    ComplaintsDatatableModule,
  ]
})
export class ComplaintsDatatableCrmModule { }
