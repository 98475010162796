
<form [formGroup]="form" *ngIf="form" novalidate (submit)="onSubmit()">
  <div class="row no-gutters gutters-4">
    <ng-container *ngIf="data.leads_as_boss.length">
      <div class="col-12 mb-3">
        <p class="bold mb-0">Руководитель лидов</p>
        <p *ngFor="let lead of data.leads_as_boss" class="mb-0">
          <a href="javascript: void(0)" (click)="goBack.newLink('/reservation/lead-info/' + lead.id, 'boss' + lead.id)" >
            {{lead.fio}}
          </a>
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="data.leads_as_manager.length">
      <div class="col-12 mb-3">
        <p class="bold mb-0">Менеджер лидов</p>
        <p *ngFor="let lead of data.leads_as_manager" class="mb-0">
          <a href="javascript: void(0)" (click)="goBack.newLink('/reservation/lead-info/' + lead.id, 'manager' + lead.id)" >
            {{lead.fio}}
          </a>
        </p>
      </div>
    </ng-container>
    <div class="col-12">
      <app-material-input formControlName="fio"
                          [placeholder]="'ФИО'"
                          [require]="true">
        ФИО</app-material-input>
    </div>

    <div class="col-12">
      <app-material-input-mask formControlName="phone" [mask]="'+7 (000) 000-00-00'" [require]="true"
                               [placeholder]="'+7 (___) ___-__-__'">
        Телефон</app-material-input-mask>
    </div>
    <div class="col-12">
      <app-material-input formControlName="email"
                          [placeholder]="'Email'">
        Email</app-material-input>
    </div>
  </div>
  <div class="row no-gutters gutters-2 mt-3">
    <div class="col-md-10">
      <button type="submit" class="button" [disabled]="!form.valid">Изменить</button>
    </div>
  </div>
</form>
