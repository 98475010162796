<div class="dropzone dz-clickable" (click)="onClick()" #dropZone [class.d-none]="disable">
  <div class="dz-default dz-message">
    <span *ngIf="dropzoneCaption" [innerHtml]="dropzoneCaption"></span>
    <p *ngIf="!dropzoneCaption" class="mb-0">
      Перетащите файл сюда или нажмите <span class="dz-dash">тут</span>
    </p>
  </div>
  <input type="file" [hidden]="true" (change)="onFileChange()" #fileInput>
</div>
<div class="mb-4 min-height-auto" *ngIf="current_value && current_value.length">
  <div class="wrapper" [ngStyle]="{'min-height' : position.length * rowHeight + 'px'}">
    <div class="wrapper__row_drag"
        *ngFor="let file of current_value; let i = index"
        ngDraggable
        [preventDefaultEvent]="true"
        [zIndexMoving]="'9999'"
        [trackPosition]="false"
        [position]="getPosition(i)"
        (movingOffset)="onMoving($event, i)"
        [handle]="sorthandle"
    >
      <div class="handle" #sorthandle>
        <i class="fa fa-th" aria-hidden="true"></i>
      </div>
      <div class="td-img">
        <div class="button-picture">
          <a href="javascript: void(0)" (click)="lightbox.open([{'src': upload_url + file.filepath, 'thumb': upload_url + file.preview}], 0)"
             *ngIf="file.preview">
            <img [src]="upload_url + file.filepath" class="button-picture__img no-auto-zoom-in" alt="">
          </a>
          <a [href]="upload_url + file.filepath" *ngIf="!file.preview">
            <img [src]="'/assets_common/images/no_img.svg'" class="button-picture__img no-auto-zoom-in" alt="">
          </a>
          <a class="button-picture__icon" target="_blank" [href]="upload_url + file.filepath"></a>
          <a [href]="upload_url + file.filepath" target="_blank" class="button-picture__url" *ngIf="!file.preview"></a>
        </div>
      </div>
      <div class="d-none d-sm-block flex-grow-1">
        <div class="form__group mb-0">
          <div class="form__label mb-0" title="{{file['filename']}}">
            {{file['filename']}}
          </div>
          <div class="form__input" [class.form__input_disabled]="disable"  *ngIf="hasComments">
            <input [type]="'text'" class="input" [value]="file.comment" [disabled]="disable"
                   (keyup)="onCommentChange([file.filepath, $event.target.value])"
                   [ngClass]="{'md-valid': file.comment && (file.comment.length || file.comment > 0)}"
                   [placeholder]="(!disable) ? 'Описание' : ''">
          </div>
        </div>
      </div>
      <div class="td-in-flat-visible" *ngIf="hasInFlatVisible">
        <div class="form__group  mb-0">
          <label class="form__checkbox form__checkbox_icon_done_mark form__checkbox_border height-47">
            <input type="checkbox"
                   (change)="in_flat_visible_change([file.filepath, $event.target.checked])"
                   [checked]="file.in_flat_visible"
                   [disabled]="disabled"
            >
            <span class="form__checkbox__wrapper">
              <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>

            <span class="form__checkbox__content"
                  [title]="'Если переключатель установлен, то файл будет выводиться \n' +
                  'в карточках квартир данного ЖК, а также в тарифной карте.'"
            > Показывать в Квартире и ТК</span>
          </label>
        </div>
      </div>
      <div class="td-button text-right text-sm-center" *ngIf="!disable">
        <button type="button" (click)="onFileRemove(file.filepath)" class="button">Удалить</button>
      </div>
    </div>
  </div>

</div>
