import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReportComponent} from '../report/report.component';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BreadcrumbsService} from '../../../../shared/breadcrumbs/services';
import {GoBackButtonService} from '../../../../services/go-back-button.service';
import {Subscription} from 'rxjs';

@Component({
  templateUrl: './report-common.component.html',
  styleUrls: [
    './report-common.component.scss',
    '../../stylesheets/table.scss',
  ]
})
export class ReportCommonComponent extends ReportComponent implements OnInit, OnDestroy {

  public readonly view_type = {
    'realty': 'Адреса',
    'subagents': 'Субагенты',
  };

  public readonly report_types = {
    'sale_call': 'Кол-во входящих и исходящих звонков',
    'sale_unique_check': 'Проверка на уникальность по адресу',
    'sale_preview': 'Назначение просмотра',
    'sale_view': 'Подтверждение просмотра квартиры',
    'sale_view_processing': 'Проведение просмотра квартиры',
    'sale_pre_reserve': 'Ожидание резервирования квартиры',
    'sale_reserve': 'Проведение резервирования квартиры',
    'sale_db': 'Квартира забронирована, получение док-ов',
    'sale_ddu': 'Подписание договора долевого участия',
    'sale_ddu_payment': 'Оплата договора долевого участия',
    'sale_developer_payment': 'Оплата от застройщика',
    'sale_developer_docs': 'Получение документов от застройщика',
    'sale_subagent_docs': 'Получение документов от субагента',
    'sale_subagent_payment': 'Оплата субагенту',
    'sale_archive': 'Архив',
    'ci_register': 'Первичная регистрация пользователя субагента',
    'ci_master': 'Заполнение мастера субагентом',
    'ci_master_finish': 'Акцепт оферты субагентом',
    'ci_approved': 'Подтверждение субагента менеджером',
    'ci_archive': 'Регистрация пользователя архив',
  };

  public get search_string(): string {
    return this._search_string;
  }

  public set search_string(value: string) {
    this._search_string = value;
    this.whenUrlChange();
  }

  public type;
  public is_realty;
  public routerSubscription: Subscription = null;

  private _search_string = '';

  public head = [];
  public total = [];
  public body = [];

  constructor(public r: Router,
              public a: ActivatedRoute,
              public b: BreadcrumbsService,
              private goBack: GoBackButtonService) {
    super(r, a, b);
  }

  public get base_url() {
    return '/analytic/report/' + this.type + '/' + this.is_realty;
  }

  public get filter_url() {
    const a = [this.base_url];

    if (this.a.snapshot.params['start']) {
      a.push(this.a.snapshot.params['start']);
    }

    if (this.a.snapshot.params['stop']) {
      a.push(this.a.snapshot.params['stop']);
    }

    return a.join('/');
  }

  ngOnInit() {
    super.ngOnInit();

    this.type = this.a.snapshot.params['type'];
    this.is_realty = this.a.snapshot.params['is_realty'];

    const data = ReportCommonComponent
      .deployData(this.a.snapshot.data['data']);
    this.head = data.shift();
    this.total = data.shift();
    this.body = data;

    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/analytic/index', caption: 'Аналитика'},
    ]);
    if (this.getViewType(this.is_realty)) {
      this.goBack.header = '<h2>' + this.getViewType(this.is_realty) + '</h2>';
    }
    if (this.getReportType(this.type)) {
      this.goBack.header = '<h2>' + this.getReportType(this.type) + '</h2>';
    }
    this.routerSubscription = this.r.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.getViewType(this.is_realty)) {
          this.goBack.header = '<h2>' + this.getViewType(this.is_realty) + '</h2>';
        }
        if (this.getReportType(this.type)) {
          this.goBack.header = '<h2>' + this.getReportType(this.type) + '</h2>';
        }
      }
    });



  }

  whenUrlChange() {
    super.whenUrlChange();

    const data = ReportCommonComponent
      .deployData(this.a.snapshot.data['data'],
        this.sort_field, this.sort_direction, this.search_string);

    this.head = data.shift();
    this.total = data.shift();
    this.body = data;

    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/analytic/index', caption: 'Аналитика'},
    ]);
  }

  getReportType(identifier) {
    return this.report_types[identifier];
  }

  getViewType(identifier) {
    return this.view_type[identifier];
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
