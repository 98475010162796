import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../services/notification.service';
import { OverlayWindowService } from '../../single-window/shared/overlay-window.service';
import { HttpClient } from '../../../services/http.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-news-create',
  templateUrl: './news-create.component.html',
  styleUrls: ['./news-create.component.scss']
})
export class NewsCreateComponent implements OnInit {

  @Output('onCreate') public onCreate: EventEmitter<any> = new EventEmitter<any>();

  public form: UntypedFormGroup;

  constructor(private http: HttpClient, private overlayWindowService: OverlayWindowService,
              private fb: UntypedFormBuilder, private notify: NotificationService) {
  }

  ngOnInit() {
    const date = new DatePipe('en-US').transform(Date.now(), 'dd.MM.yyyy');
    this.form = this.fb.group({
      'news_title': ['', Validators.required],
      'news_description': ['', Validators.required],
      'news_link': ['', Validators.required],
      'news_date': [date, Validators.required],
      'always_show': [false, Validators.required],
      'is_archive': [false, Validators.required],
      'public_status': ['site_crm', Validators.required],
    });
  }

  onSubmit() {
    const data = {
      'data': {
        'news_title': this.form.value.news_title,
        'news_description': this.form.value.news_description,
        'news_url': this.form.value.news_link,
        'news_date': this.form.value.news_date,
        'public_status': this.form.value.public_status,
        'always_show': this.form.value.always_show,
        'is_archive': this.form.value.is_archive,
      }
    };

    this.http.post('News.create', data).subscribe(
      response => {
        const title = this.form.value.news_title;
        this.notify.notifySuccess('Новость успешно создана', title, 5000);
        this.onCreate.emit({'value': title});
        this.overlayWindowService.closeHost();
      },
      error => this.notify.notifyError('Ошибка', error, 5000)
    );
  }

}
