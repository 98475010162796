<div class="notify" *ngIf="alert.length" [@fade]>
  <div class="notify__item" [@fade] *ngFor="let notification of alert.notifications" [ngClass]="{
      'notify__item_success': 1 === notification.type,
      'notify__item_danger': 2 === notification.type
    }"
     (click)="alert.splice(notification)">
    <button class="notify__item-close">
      <i class="icon icon_Small_Close"></i>
    </button>
    <div class="notify__item-header">
      <i *ngIf="1 === notification.type" class="icon icon_Small_Message_Good"></i>
      <i *ngIf="2 === notification.type" class="icon icon_Small_Message_Bad"></i>
      {{notification.subject}}
    </div>
    <div class="notify__item-content" *ngIf="!notification.link">{{notification.body}}</div>
    <div class="notify__item-content" *ngIf="notification.link">
      <a  href="javascript:void(0)" (click)="onLink(notification.link)">{{notification.body}}</a>
    </div>
  </div>
</div>
