
<ng-container *ngIf="!loading">
  <ng-container *ngIf="!show">
    <div class="row no-gutters justify-content-center import">
      <div class="col-2">
        <div class="icon icon_Feed import__icon"></div>
      </div>
      <div class="col-6">
        <p class="mb-2">Автоматический импорт данных о квартирах позволяет связать СПН24 с вашей собственной системой
          учета квартир и
          управления, так что данные от вас автоматически попадают в СПН24.</p>
        <p>Например, когда квартира будет отмечена как проданная в вашей системе, она сразу же исчезнет из списка
          продажи
          здесь.</p>
        <a class="button mt-3" routerLink="/import/create">Настроить импорт...</a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="show">
    <app-import-list></app-import-list>
  </ng-container>
</ng-container>

<ng-container *ngIf="loading">
  <div class="import">
    <app-preloader></app-preloader>
  </div>
</ng-container>

