import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {GoBackButtonService} from '../../../services/go-back-button.service';

@Component({
  templateUrl: './settings-constraints.component.html',
  styleUrls: ['./settings-constraints.component.scss']
})
export class SettingsConstraintsComponent implements OnInit {


  public opened = [];
  public columns = [];
  public subagent_id = null;
  public whenDel: EventEmitter<void> = new EventEmitter<void>();

   @ViewChild('btn', {static: true}) private btn;
   @ViewChild('caption', {static: true}) private caption;

   constructor(private goBack: GoBackButtonService) {}

  ngOnInit() {
    this.goBack.header = '<h1>Ограничения видимости</h1>';
    this.columns = [
      {'name': 'subagent_caption', 'caption': 'Название субагента', 'template': this.caption, 'sortable': true},
      {'name': 'phy_name', 'caption': 'Имя директора', 'sortable': true},
      {'name': 'phy_phone', 'caption': 'Телефон директора', 'sortable': true},
      {'name': 'phy_phone', 'caption': '', 'template': this.btn},
    ];
  }

  OpenData(subagent_id) {
    this.opened = [];
    this.subagent_id = subagent_id;
    this.opened[subagent_id] = true;
  }

  WhenDataClosed() {
    this.subagent_id = null;
    this.opened = [];
  }

  whenDeleted() {
    this.whenDel.emit();
  }

}
