import {EventEmitter, Injectable} from '@angular/core';
import {AuthenticateService} from '../../../services/authenticate.service';
import {HttpClient} from '../../../services/http.service';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class HelpWindowService {

  private _setter: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public emitter: EventEmitter<string> = new EventEmitter<string>();

  public readonly setter = this._setter.asObservable();

  constructor(private auth: AuthenticateService, private http: HttpClient) { }

  public wouldDisplay(name) {
    return !this.auth.checkPageViewed(name);
  }

  public markAsViewed(name) {
    this.auth.addPageViewed(name);
    this.http.post('Menu.help_page_viewed', {'page_name': name})
      .subscribe();
  }

  public setCurrent(name) {
    this._setter.next(name);
  }

  public displayHelp(name) {
    this.emitter.emit(name);
  }

}
