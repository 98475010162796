import {Component, ViewChild, OnInit, OnDestroy, TemplateRef, AfterViewInit} from '@angular/core';
import { HttpClient } from '../../services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

import { DataTableParamComponent } from '../../modules/@datatable/components/datatable-param.component';
import { debounceTime } from 'rxjs/operators';
import {BackrefWindowService} from '../single-window/shared/backref-window.service';
import {GoBackButtonService} from '../../services/go-back-button.service';



@Component({
  selector: 'app-reserve',
  templateUrl: './reserve.component.html',
  styleUrls: [
    './reserv-global.component.scss',
  ]
})
export class ReserveListComponent extends DataTableParamComponent implements OnInit, OnDestroy, AfterViewInit {


  public api_action = 'Data_tables.get_reserve_crm';
  public columns: Array<{ [key: string]: any }>;
  public formGroup: UntypedFormGroup;
  public subscription: Subscription;
  public data: any;
  private firstLoad = true;

  @ViewChild('operation_create_dateHead', {static: true}) private operation_create_dateHead: TemplateRef<any>;
  @ViewChild('operation_end_dateHead', {static: true}) private operation_end_dateHead: TemplateRef<any>;
  @ViewChild('physical_manager_fioTmpl', {static: true}) private physical_manager_fioTmpl: TemplateRef<any>;
  @ViewChild('object_captionTmpl', {static: true}) private object_captionTmpl: TemplateRef<any>;
  @ViewChild('physical_subagent_fioTmpl', {static: true}) private physical_subagent_fioTmpl: TemplateRef<any>;
  @ViewChild('appeal_client_fioTmpl', {static: true}) private appeal_client_fioTmpl: TemplateRef<any>;
  @ViewChild('subagent_captionTmpl', {static: true}) private subagent_captionTmpl: TemplateRef<any>;
  @ViewChild('housing_complex_captionHead', {static: true}) public housing_complex_captionHead: TemplateRef<any>;
  @ViewChild('object_captionHead', {static: true}) public object_captionHead: TemplateRef<any>;
  @ViewChild('physical_subagent_fioHead', {static: true}) public physical_subagent_fioHead: TemplateRef<any>;
  @ViewChild('subagent_captionHead', {static: true}) public subagent_captionHead: TemplateRef<any>;
  @ViewChild('physical_subagent_phoneHead', {static: true}) public physical_subagent_phoneHead: TemplateRef<any>;
  @ViewChild('appeal_client_phoneHead', {static: true}) public appeal_client_phoneHead: TemplateRef<any>;
  @ViewChild('appeal_client_fioHead', {static: true}) public appeal_client_fioHead: TemplateRef<any>;
  @ViewChild('physical_manager_fioHead', {static: true}) public physical_manager_fioHead: TemplateRef<any>;
  @ViewChild('stage_captionTmpl', {static: true}) private stage_captionTmpl: TemplateRef<any>;
  @ViewChild('stage_captionHead', {static: true}) private stage_captionHead: TemplateRef<any>;

  constructor(public http: HttpClient, public route: ActivatedRoute,
              private backrefWindowService: BackrefWindowService,
              public fb: UntypedFormBuilder, router: Router, public goBack: GoBackButtonService) {
    super(http, route, router);
    this.formGroup = this.fb.group({
      'operation_create_date': [''],
      'operation_end_date': [''],
      'date_change_start': [''],
      'date_change_end': [''],
      'date_start': [''],
      'date_end': [''],
      'tab': [''],
      'stage_caption': [''],
      'housing_complex_caption': [''],
      'object_caption': [''],
      'physical_subagent_fio': [''],
      'subagent_caption': [''],
      'physical_subagent_phone': [''],
      'appeal_client_fio': [''],
      'appeal_client_phone': [''],
      'physical_manager_fio': [''],
    });
    this.subscription = this.formGroup.valueChanges.pipe(debounceTime(500))
      .subscribe((data) => {
        this.router_param['page'] = 1;
        this.current_page = 1;
        if (this.firstLoad) {
          this.firstLoad = false;
        } else {
          this.changeForm(this.formGroup);
        }
      });
    this.updateForm(this.formGroup);
    this.backrefWindowService.routerInit();
  }

  ngOnInit() {
    this.goBack.header = '<h1>Резервы</h1>';
    this.data = this.route.snapshot.data['data'];
    this.columns = [
      {'name': 'operation_create_date', 'caption': 'Дата резерва', 'sortable': true,
        'templateHead': this.operation_create_dateHead},
      {'name': 'operation_end_date', 'caption': 'Окончание резерва', 'sortable': true,
        'templateHead': this.operation_end_dateHead},
      {'name': 'physical_subagent_fio', 'caption': 'Субагент', 'sortable': true, 'width': '120',
        'template': this.physical_subagent_fioTmpl, 'templateHead': this.physical_subagent_fioHead},
      {'name': 'physical_subagent_phone', 'caption': 'Тел. субагента', 'sortable': true,
        'templateHead': this.physical_subagent_phoneHead},
      {'name': 'appeal_client_fio', 'caption': 'Клиент', 'sortable': true,
        'template': this.appeal_client_fioTmpl, 'templateHead': this.appeal_client_fioHead},
      {'name': 'appeal_client_phone', 'caption': 'Тел. клиента', 'sortable': true, 'templateHead': this.appeal_client_phoneHead},
      {'name': 'object_caption', 'caption': 'Объект', 'sortable': true,
        'template': this.object_captionTmpl, 'templateHead': this.object_captionHead},
      {'name': 'physical_manager_fio', 'caption': 'Ответственный', 'sortable': true,
        'template': this.physical_manager_fioTmpl, 'templateHead': this.physical_manager_fioHead},
      {'name': 'status', 'caption': 'Статус', 'sortable': true},
      {'caption': 'Этап', 'name': 'stage_caption', 'sortable': true, 'template': this.stage_captionTmpl,  'templateHead': this.stage_captionHead},
    ];
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  ngAfterViewInit() {
    this.goBack.goScroll();
  }

  prepareQuery() {
    const result = super.prepareQuery();
    result['filter']['fields'] = {};
    result['filter']['fields'] = {
      'operation_create_date': this.formGroup.value.operation_create_date,
      'operation_end_date': this.formGroup.value.operation_end_date,
      'date_start': this.formGroup.value.date_start,
      'date_end': this.formGroup.value.date_end,
      'date_change_start': this.formGroup.value.date_change_start,
      'date_change_end': this.formGroup.value.date_change_end,
      'tab': this.formGroup.value.tab,
      'stage_caption': this.formGroup.value.stage_caption,
      'housing_complex_caption': this.formGroup.value.housing_complex_caption,
      'object_caption': this.formGroup.value.object_caption,
      'physical_subagent_fio': this.formGroup.value.physical_subagent_fio,
      'subagent_caption': this.formGroup.value.subagent_caption,
      'physical_subagent_phone': this.formGroup.value.physical_subagent_phone,
      'appeal_client_fio': this.formGroup.value.appeal_client_fio,
      'appeal_client_phone': this.formGroup.value.appeal_client_phone,
      'physical_manager_fio': this.formGroup.value.physical_manager_fio,
    };
    return result;
  }

  filter_state(string) {
    if (this.formGroup.value.tab === string) {
      string = '';
    }
    this.formGroup.patchValue({
      'tab': string,
    });
  }
}

