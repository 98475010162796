<div class="row no-gutters gutters-4">
  <div class="col-sm-12">
    <div class="allDrop" (click)="activeDropdown = !activeDropdown">Схлопнуть / расхлопнуть всё</div>
    <ng-container *ngIf="data['ci_subagent_caption']">
      <p class="small"><span class="bold">{{data['ci_subagent_caption']}}</span></p>
    </ng-container>
    <ng-container *ngIf="data['stage_text_prolongation'] && data['stage_is_active']">
      <p class="small">
        <span class="small"><span class="bold"> Статус этапа: </span>{{data['stage_text_prolongation']}}</span>
      </p>
    </ng-container>
  </div>
  <div class="col-sm-12">
    <app-attraction-lead-page-substep-edit (closeEdit)="closeEdit($event)" [data]="data"></app-attraction-lead-page-substep-edit>
  </div>


  <div class="col-sm-12">
    <form [formGroup]="form" *ngIf="form" novalidate (submit)="onSubmit()">

      <div class="row no-gutters gutters-4 pb-5r">
        <!--Общая информация:-->
        <div class="col-sm-12 mt-1 mb-0">
          <app-easy-dropdown [cssClass]="'w100 max-height-none'" [activeDropdown]="activeDropdown"
                             [minusPadding]="true"
                             [titleText]="'Общая информация:'">
            <div class="mb-2r">
              <p>
                <ng-container *ngIf="data['author_fio']">
                  <ng-template #header>
                    <span>Автор - </span>{{data['author_fio']}}
                  </ng-template>
                  <span class="d-inline-block mr-3">
                    <span class="bold">Автор: </span>
                    <a [routerLink]="['/physical/edit/', data['author_id']]">
                      {{data['author_fio']}}
                    </a>
                  </span>
                </ng-container>
                <ng-container *ngIf="data['create_time']">
                  <span class="d-inline-block"><span class="bold">Дата создания:</span> {{data['create_time']}}</span>
                </ng-container>
              </p>
              <p>
                <ng-container *ngIf="data['author_phone']">
                  <span class="bold">Телефон:</span> {{data['author_phone']}}
                </ng-container>
              </p>

              <ng-container *ngIf="data['worker_id'] && data['worker_fio']">
                <p>
                  <span class="bold">Ответственный: </span>
                  <a [routerLink]="['/physical/edit/', data['worker_id']]">
                    {{data['worker_fio']}}
                  </a>
                </p>
              </ng-container>
              <ng-container *ngIf="data['ci_subagent_activated_date']">
                <p>
                  <span class="bold">Дата начала работы: </span>
                  {{data['ci_subagent_activated_date']}}
                </p>
              </ng-container>

            </div>

            <div class="row">
              <div class="col-sm-12">
                <app-material-select2-autocomplete formControlName="worker_id"
                                                   [placeholder]="'Ответственный'"
                                                   [caption]="'Ответственный'" [api_action]="'Dict.bp_ci_worker_ac'">
                  <option [value]="form.value.worker_id">{{form.value.worker_fio}}</option>
                </app-material-select2-autocomplete>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <app-material-select2 formControlName="source_id" [require]="true" [caption]="'Источник лида'">
                  <option *ngFor="let option of leadSources" [value]="option.id">
                    {{option.caption}}
                  </option>
                </app-material-select2>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <app-material-select2-autocomplete formControlName="ci_source_physical_id"
                                                   [require]="true"
                                                   [placeholder]="'Источник - привлеченец'"
                                                   [caption]="'Источник - привлеченец'" [api_action]="'Dict.bp_ci_worker_ac'">
                  <option [value]="form.value.ci_source_physical_id">{{form.value.ci_source_physical_fio}}</option>
                </app-material-select2-autocomplete>
              </div>
              <div class="col-sm-12">
                <app-material-select2 formControlName="ci_subagent_area_worker_id"
                                      [require]="false"
                                      [placeholder]="'Направление работ'"
                                      [caption]="'Направление работ'">
                  <option *ngFor="let option of subagentAreaWork" [value]="option.id">
                    {{option.caption}}
                  </option>
                </app-material-select2>
              </div>
            </div>
            <ng-container *ngIf="data['stage_description']">
              <p class="mt-2"><span class="bold">Описание Этапа:</span> {{data['stage_description']}}</p>
            </ng-container>

          </app-easy-dropdown>
        </div>

        <!--Субагент:-->
        <div class="col-sm-12 mb-0">
          <app-easy-dropdown [cssClass]="'w100 max-height-none'" [activeDropdown]="activeDropdown"
                             [minusPadding]="true"
                             [titleText]="'Субагент:'">
            <div *ngIf="data['ci_user_id'] || data['ci_subagent_caption']" class="mb-2r">
              <ng-container *ngIf="data['ci_user_id']">
                <ng-template #header>
                  {{data['ci_user_fio']}}
                </ng-template>
                <p>
                  <span class="bold">ФИО: </span>
                  <a [routerLink]="['/physical/edit/', data['ci_user_id']]">
                    {{data['ci_user_fio']}}
                  </a>
                </p>
              </ng-container>
              <ng-container *ngIf="data['ci_subagent_caption']">
                <p>
                  <span class="bold">Организация: </span>
                  <a [routerLink]="['/entity/edit', data['ci_subagent_id'], 'physical']">
                    {{data['ci_subagent_caption']}}
                  </a>
                </p>
              </ng-container>
              <ng-container *ngIf="data['ci_subagent_user_phone']">
                <p>
                  <span class="bold"> Телефон: </span>
                  {{data['ci_subagent_user_phone']}}
                </p>
              </ng-container>
              <ng-container *ngIf="data['ci_subagent_user_email']">
                <p>
                  <span class="bold">Email: </span>
                  {{data['ci_subagent_user_email']}}
                </p>
              </ng-container>
            </div>

            <app-alert-warning *ngIf="data['ci_subagent_id']">
              <div><i class="fa-fw fa fa-warning"></i> Если изменить субагента,
                <span class="text-bold">выбранный до этого субагент будет преобразован в юр лицо
                  и у него пропадёт доступ к системе SPN24</span></div>
            </app-alert-warning>
            <app-material-select2-autocomplete formControlName="ci_subagent_id"
                                               [placeholder]="'Введите субагента'"
                                               [caption]="'Субагент'" [api_action]="'Dict.bp_ci_subagent_ac'">
              <option [value]="form.value.ci_subagent_user_id">{{data.ci_user_fio}} {{data.ci_subagent_caption}}</option>
            </app-material-select2-autocomplete>
            <p><span class="bold">Описание: </span> выбирается руководитель организации или организация, которую необходимо
              сделать субагентом. <span class="bold">Уже преобразованных субагентов выбрать нельзя</span></p>
          </app-easy-dropdown>
        </div>

        <!--Клиент:-->
        <div class="col-sm-12 mb-0">
          <app-easy-dropdown [cssClass]="'w100 max-height-none'" [activeDropdown]="activeDropdown" [minusPadding]="true" [titleText]="'Клиент:'">
            <div class="row">
              <div class="col-4">
                <app-material-input formControlName="firstName"
                                    [placeholder]="'Имя'"
                                    [require]="true">
                  Имя</app-material-input>
              </div>
              <div class="col-4">
                <app-material-input formControlName="lastName"
                                    [placeholder]="'Фамилия'">
                  Фамилия</app-material-input>
              </div>
              <div class="col-4">
                <app-material-input formControlName="middleName"
                                    [placeholder]="'Отчество'">
                  Отчество</app-material-input>
              </div>
              <div class="col-6">
                <app-material-input-mask formControlName="phone" [mask]="'+7 (000) 000-00-00'" [require]="true"
                                         [placeholder]="'+7 (___) ___-__-__'">
                  Телефон</app-material-input-mask>
              </div>
              <div class="col-6">
                <app-material-input formControlName="email"
                                    [placeholder]="'Email'">
                  Email</app-material-input>
              </div>
            </div>

          </app-easy-dropdown>
        </div>


        <div class="col-sm-12 mt-2r">
          <app-material-textarea [rows]="4" [placeholder]="'Комментарий к лиду'"
                                 formControlName="notice">
            Комментарий к лиду
          </app-material-textarea>
        </div>
        <div class="col-12">
          <app-material-input-dropzone formControlName="lead_files"
                                       [hasComments]="false" [ngClass]="'small_dropzone'">
          </app-material-input-dropzone>
        </div>

        <div class="fixed_buttons_block">
          <button type="submit" class="button mr-2"  [disabled]="!form.valid">Изменить лид</button>
          <a href="javascript: void(0)" class="button button_main" (click)="deleteLead()">Удалить лид</a>
        </div>

      </div>
    </form>

  </div>
</div>
