Всего: <b>{{theadline.showed_lines}}</b>
<div class="material-datatable-wrapper">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)">
  </app-datatable-headline>
  <div class="table-responsive">
    <table class="table table-striped datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>


<ng-template #isSubagentTmpl let-row="row">
  {{(row.is_subagent) ? 'Да' : 'Нет'}}
</ng-template>

<ng-template #resendPackageTmpl let-row="row">
  <a href="javascript:void(0)" (click)="resendPackage(row['org_id'])">
    <i class="fa fa-refresh"></i> Отправить пакет документов
  </a>
</ng-template>

<ng-template #physicalFio let-row="row">
  <a [routerLink]="['/physical/edit', row.physical_id]"
    *ngIf="row.physical_id">
      <i class="fa fa-user"></i> {{row.physical_fio}}
  </a>
</ng-template>

<ng-template #entityCaption let-row="row">
  <a [routerLink]="['/entity/edit', row.org_id]"
    *ngIf="row.org_id">
      <i class="fa fa-user"></i> {{row.org_caption}}
  </a>
</ng-template>

<ng-template #partnerCertTmpl let-row="row">
  <ng-container *ngIf="row['partner_cert']">
    <a [href]="row['partner_cert']" target="_blank">
      <i class="fa fa-download"></i>
      Скачать
    </a>
  </ng-container>
</ng-template>

<ng-template #docsPackTmpl let-row="row">
  <ng-container *ngIf="row['docs']">
    <a [href]="row['docs']" target="_blank">
      <i class="fa fa-download"></i>
      Скачать
    </a>
  </ng-container>
</ng-template>

<ng-template #orgIdHeadTmpl let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="org_id" [cssClass]="'mb-0'" [placeholder]="'№'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #orgCaptionHeadTmpl let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="org_caption" [cssClass]="'mb-0'" [placeholder]="'Название орг-ции'">
    </app-material-input>
  </form>
</ng-template>



<ng-template #access_idHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-select formControlName="access_id" [cssClass]="'mb-0'">
      <option [value]="''">Все</option>
      <option [value]="'active'">Полный доступ</option>
      <option [value]="'not_sub'">Не субагент</option>
      <option [value]="'restrict'">Ограниченный доступ</option>
      <option [value]="'not_active'">Нет доступа</option>
    </app-material-select>
  </form>
</ng-template>

<ng-template #access_idTmpl let-row="row">
  {{row.access_caption}}
</ng-template>

<ng-template #orgPhysicalFioTmpl let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_fio" [cssClass]="'mb-0'" [placeholder]="'Руководитель'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #orgPhysicalPhoneTmpl let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_phone" [cssClass]="'mb-0'" [placeholder]="'Телефон'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #typeHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="notice" [cssClass]="'mb-0'" [placeholder]="'Комментарий'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #typeTmpl let-row="row">
  <div class="form__group">
    <div class="form__input">
      <textarea [(ngModel)]="row['notice']"
                class="textarea md-valid textarea-small" placeholder="Комментарий"></textarea>
    </div>
  </div>
  <button (click)="saveData(row)" class="button-small button">Сохранить</button>
</ng-template>


<ng-template #commentTmpl let-row="row">
  <p>{{row['status_caption']}}</p>
  <p>
    <ng-container *ngIf="row.wizard_step">
      <b>Шаг мастера: {{row['wizard_step']}}</b><br>
    </ng-container>
    <ng-container *ngIf="row.wizard_step_caption">
      ({{row['wizard_step_caption']}})
    </ng-container>
  </p>

<!--  <button *ngFor="let elements of row.available_statuses" (click)="loadData(row['org_id'], elements.status_id, row['org_caption'],  elements.caption, elements.description)"
          class="button-small button mb-2 mt-2 d-block">{{elements.caption}}</button>-->

</ng-template>

<app-attraction-status-change
  [subagent_id]="subagent_id"
  [subagent_caption]="subagent_caption"
  [subagent_type]="subagent_type"
  [window_caption]="window_caption"
  [window_text]="window_text"
  (onClosed)="WhenDataClosed()">
</app-attraction-status-change>
