Всего за период: <b>{{count_all}}</b> | С ответом: <b>{{count_answered}}</b> | Без ответа: <b>{{count_not_answered}}</b>
<div class="mt-3">
  Мин. время ожидания: <b>{{tta_min_caption}}</b> | Макс. время ожидания: <b>{{tta_max_caption}}</b> | Сред. время ожидания: <b>{{tta_avg_caption}}</b>
</div>
<div class="mb-2">
  <div class="row no-gutters align-items-end m-0">
    <div class="col-md-6 col-lg-6 col-xl-4 mr-0 mr-md-5 mb-3">
      <div class="form__group mb-0">
        <div class="form__input">
          <input class="input" type="text" placeholder="Поиск"
                 [ngModel]="filter" (ngModelChange)="onFilterChanges($event)">
          <div class="form__input_icon icon icon_Small_Search c-pointer ng-star-inserted"></div>
        </div>
      </div>
    </div>
    <div class="col-md-auto col-12 mb-3">
      <form [formGroup]="form">
        <div class="row no-gutters gutters-0">
          <div class="col-sm-6">
            <app-material-input-datepicker formControlName="date_start" [cssClass]="'mb-0'" [placeholder]="'Создан с'">
            </app-material-input-datepicker>
          </div>
          <div class="col-sm-6">
            <app-material-input-datepicker formControlName="date_end" [cssClass]="'mb-0'" [placeholder]="'Создан по'">
            </app-material-input-datepicker>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-auto col-12 mb-3 ml-auto">
      <a [href]="urlIntegration + user.session_id + queryLink"
         class="button" target="_blank"
      >
        Скачать<span class="d-none d-md-inline"> все лиды</span>
      </a>
    </div>
  </div>

</div>

<div class="material-datatable-wrapper table-color">
  <div class="table-responsive">
    <table class="table">
      <tr>
        <th class="align-middle" *ngFor="let el of head_display" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
          <div [formGroup]="form">
            <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
              <div class="col table__input">
                <ng-container *ngIf="el.name !== 'spn_requests' && el.name !== 'eat_requests' && el.name !== 'time_to_answer' && el.name !== 'lead_status'">
                  <ng-container *ngIf="el.name === 'next_contact_date' || el.name === 'stage'">
                    <app-material-input-datepicker formControlName="next_contact_date" [cssClass]="'mb-0'" [placeholder]="'След. контакт (созд./изм.)'">
                    </app-material-input-datepicker>
                  </ng-container>
                  <ng-container *ngIf="el.name !== 'next_contact_date' && el.name !== 'stage'">
                    <app-material-input
                      [placeholder] = "el.caption"
                      [formControlName] = "el.name"
                      [cssClass]="'mb-0'">
                    </app-material-input>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="el.name === 'spn_requests' || el.name === 'time_to_answer'">
                  <p class="nowrap mb-0">{{el.caption}}</p>
                </ng-container>

                <ng-container *ngIf="el.name === 'lead_status'">
                  <app-material-select
                    formControlName="{{el.name}}"
                    [caption]=""
                    [cssClass]="'mb-0'">
                      <option [value]="'new||success||prolongation||roll_back'">Все в работе</option>
                      <option [value]="'finish||fail'">Не в работе</option>
                      <option [value]="'new'">Новый</option>
                      <option [value]="'success'">В работе</option>
                      <option [value]="'prolongation'">Пролонгация</option>
                      <option [value]="'finish'">Завершен</option>
                      <option [value]="'fail'">Отказ</option>
                      <option [value]="'roll_back'">Возвращен</option>
                      <option [value]="'all'">Все</option>
                  </app-material-select>
                </ng-container>
                <ng-container *ngIf="el.name === 'eat_requests'">
                  <app-material-select [caption]=""
                    formControlName="{{el.name}}"
                    [cssClass]="'mb-0'">
                    <option [value]="''">Все</option>
                    <option [value]="'1+'">С ответом</option>
                    <option [value]="'0'">Без ответа</option>
                  </app-material-select>
                </ng-container>
              </div>
              <ng-container *ngIf="el.name !== 'stage' && el.name !== 'spn_requests' && el.name !== 'eat_requests'">
                <div class="col-auto" *ngIf="el.sort">
                  <a href="javascript:void(0)" (click)="sortChange(el.name)">
                    <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                      'icon_new-sort-down': 'asc' == sort[el.name],
                                      'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                  </a>
                </div>
              </ng-container>

            </div>
          </div>
        </th>
      </tr>
      <!-- // -->
      <tr *ngFor="let row of rows; let ind = index" [attr.data-color]="row.tab?row.tab:''">

        <td>
          <ng-container *ngIf="row.spn_requests.length">
            <span [popover]="i">
              <i class="fa fa-2x fa-fw fa-question-circle c-pointer"></i>
              {{row.spn_requests.length}}
            </span>
            <popover-content #i
                             [title]="'Запросы'"
                             [placement]="'right'"
                             [closeOnClickOutside]="true"
                             [closeOnMouseOutside]="false">
              <div *ngFor="let el of row.spn_requests">
                {{el}}
              </div>
            </popover-content>
            <p class="font-weight-bold mb-0">Дата запроса</p>
            <p>{{row.request_time}}</p>
          </ng-container>
          <ng-container *ngIf="!row.spn_requests.length">
            0
          </ng-container>
        </td>
        <td>
          <ng-container *ngIf="row.eat_requests.length">
            <span [popover]="i">
              <i class="fa fa-2x fa-fw fa-question-circle c-pointer"></i>
              {{row.eat_requests.length}}
            </span>
            <popover-content #i
                             [title]="'Запросы'"
                             [placement]="'right'"
                             [closeOnClickOutside]="true"
                             [closeOnMouseOutside]="false">
              <div *ngFor="let el of row.eat_requests">
                {{el}}
              </div>
            </popover-content>
            <p class="font-weight-bold mb-0">Дата запроса</p>
            <p>{{row.answer_time}}</p>
          </ng-container>
          <ng-container *ngIf="!row.eat_requests.length">
            0
          </ng-container>
        </td>
        <td>
          {{row.time_to_answer}}
        </td>
        <td>
          <p class="bold mb-1" *ngIf="row.next_contact_date">
            {{datediff(row.next_contact_date)}}<br>
            ({{row.next_contact_date}} {{row.next_contact_time}})
          </p>
          {{row.create_time}} / {{row.stage_change_time}}
        </td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'organisation_caption-' + ind}">
            <a href="/entity/edit/{{row.organisation_id}}"
               (click)="goBack.newLink('/entity/edit/' + row.organisation_id, 'organisation_caption-' + ind, $event)">
              {{row.organisation_caption}}
            </a>
          </div>
        </td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'subagent_user_fio-' + ind}">
            <a href="/physical/edit/{{row.subagent_user_id}}"
               (click)="goBack.newLink('/physical/edit/' + row.subagent_user_id, 'subagent_user_fio-' + ind, $event)">
              {{row.subagent_user_fio}}
            </a>
          </div>
        </td>
        <td>{{row['subagent_phone']}}</td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'fio-' + ind}">
            <a href="/reservation/lead-info/{{row.id}}"
               (click)="goBack.newLink('/reservation/lead-info/' + row.id, 'fio-' + ind, $event)">
              {{row.fio}}
            </a>
          </div>
        </td>
        <td>{{row['lead_phone']}}</td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'object_caption-' + ind}">
            <a href="/housing2/flat/{{row.object_id}}"
               (click)="goBack.newLink('/housing2/flat/' + row.object_id, 'object_caption-' + ind, $event)">
              {{row.object_caption}}
            </a>
          </div>
        </td>
        <td>
          <ng-container *ngIf="row.sales_manager_fio">
            {{row.sales_manager_fio}}<br>
            {{row.sales_manager_phone}}
          </ng-container>
          <ng-container *ngIf="!row.sales_manager_fio">
            Ожидайте назначения менеджера<br>
            +74997047047
          </ng-container>
        </td>

        <td><app-stages-dots [data]="row.stage_information"></app-stages-dots></td>
        <td>{{row['stage_information']['status_description_short'] || '-'}}</td>
    </table>
  </div>
</div>

<div class="alt-pagination">
  <app-paginator
    [queryParams]="filter_columns"
    [itemsPerPage]="20"
    [itemCount]="count"
    [currentPage]="current_page"
    [baseUrl]="'/estatet'">
  </app-paginator>
</div>
