import {Component, Input, OnInit} from '@angular/core';
import {AlertComponent} from './alert.component';

@Component({
  selector: 'app-alert-danger',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertDangerComponent extends AlertComponent implements OnInit {

  @Input() public type = 'danger';

  ngOnInit() {
  }

}
