import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient} from '../../../services/http.service';

@Injectable()
export class FlatAboutResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Flat.get_flat_about', {'flat_id': +route.params['id']});
  }
}

@Injectable()
export class FlatCommonResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Flat.get_common', {'flat_id': +route.parent.params['id']});
  }
}

@Injectable()
export class FlatConditionsResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Flat.get_conditions', {'flat_id': +route.parent.params['id']});
  }
}

@Injectable()
export class FlatLayoutResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Files.get_list_to_type', {'owner': [
      'realty',
      +route.parent.params['id'],
      3
    ]});
  }
}

@Injectable()
export class FlatFloorsResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Files.get_list_to_type', {'owner': [
      'realty',
      +route.parent.params['id'],
      20
    ]});
  }
}

@Injectable()
export class FlatImagesResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Files.get_list_to_type', {'owner': [
      'realty',
      +route.parent.params['id'],
      10
    ]});
  }
}

@Injectable()
export class FlatProprietorsResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Realty.get_proprietors', {'realty_id': +route.parent.params['id']});
  }
}

@Injectable()
export class FlatOperationsResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Flat.get_common', {'flat_id': +route.parent.params['id']});
  }
}
