<div class="accordion-panel" [ngClass]="{'open': is_active}">
  <div class="accordion-heading" role="tab">
    <h3 class="card-title accordion-title">
      <a class="accordion-msg scale_active" (click)="is_active = !is_active">{{caption}}</a>
    </h3>
  </div>
  <div class="panel-collapse pl-3" [ngClass]="{'collapse': !is_active}" role="tabpanel">
    <div class="accordion-content accordion-desc">
      <ng-content></ng-content>
    </div>
  </div>
</div>
