import { NgModule } from '@angular/core';
import {TransferEntityComponent} from "./transfer-entity.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
  PhyToOrganisationNotCurrentPhyResolve,
  PhyToOrganisationResolve,
} from "./transfer.resolve";
import {MaterialInputModule} from "../../modules/@material-input";
import {CommonModule} from "@angular/common";
import {TransferServices} from "./transfer.services";
import {TransferPhysicalComponent} from "./transfer-physical.component";
import {FilterPhysicsListPipe} from "../../pipes/transfer.pipes";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
  ],
  declarations:[
    TransferEntityComponent,
    TransferPhysicalComponent,
    FilterPhysicsListPipe
  ],
  providers:[
    PhyToOrganisationResolve,
    PhyToOrganisationNotCurrentPhyResolve,
    TransferServices,
  ]

})
export class TransferModule {

}
