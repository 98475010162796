import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '../../../services/http.service';

@Injectable()
export class ImportFeedHousingResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Import.setting_exceptions_get', {'setting_id': route.parent.params['id']});
  }
}
