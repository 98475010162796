import {
  Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, EventEmitter, HostListener, OnInit, Output,
  Renderer2, Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {IOverlayHost} from '../interfacers/overlay-host';
import {OverlayWindowService} from '../shared/overlay-window.service';

@Component({
  selector: 'app-single-window-layout',
  templateUrl: './single-window-layout.component.html',
  styleUrls: ['./single-window-layout.component.scss']
})
export class SingleWindowLayoutComponent implements IOverlayHost, OnInit {

  @ViewChild('componentContainer', { read: ViewContainerRef }) private container;
  @ViewChild('wrapper') private wrapper;

  @Output() public closeEmitter: EventEmitter<void> = new EventEmitter<void>();

  private componentRef: ComponentRef<any> = null;

  private _opened = false;
  private last_position;
  private oldScrollY;

  public get opened(): boolean {
    return this._opened;
  }

  public set opened(value: boolean) {
    this._opened = value;

    // if (value) {
    //   this.renderer.setStyle(this.wrapper.nativeElement, 'top', `${window.pageYOffset}px`);
    //   this.last_position = window.pageYOffset;
    // }

    /*
     * Destroy child component if window is closing
     */

    if (value) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
      this.destroyComponent();
      this.closeEmitter.emit();
    }
  }

  constructor(private resolver: ComponentFactoryResolver, private overlayService: OverlayWindowService,
              private renderer: Renderer2) { }

  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(event) {
  //   if (!this._opened) {
  //     return;
  //   }
  //
  //   if (this.last_position > 0 && window.pageYOffset < this.last_position) {
  //     this.renderer.setStyle(this.wrapper.nativeElement, 'top', `${window.pageYOffset}px`);
  //     this.last_position = window.pageYOffset;
  //   }
  // }

  ngOnInit() {
    this.overlayService.registerHost(this);
  }

  openComponentInPopup<T>(componentType: Type<T>, options?: {[key: string]: any}): Promise<ComponentRef<T>> {
    this.oldScrollY = window.scrollY || window.pageYOffset;
    if (this.oldScrollY) {
      this.scrollTo(0);
    }

    const factory: ComponentFactory<T> = this.resolver.resolveComponentFactory(componentType);
    return this.openFactoryInPopup(factory, options);
  }

  openFactoryInPopup<T>(componentFactory: ComponentFactory<T>, options?: {[key: string]: any}): Promise<ComponentRef<T>> {
    return new Promise<ComponentRef<T>>((resolve: (componentRef: ComponentRef<T>) => void) => {
      this.container.clear();
      this.componentRef = this.container.createComponent(componentFactory);

      if (options) {
        const keys = Object.keys(options);

        for (let i = 0; i < keys.length; i++) {
          this.componentRef.instance[keys[i]] = options[keys[i]];
        }
      }

      this.opened = true;
      resolve(this.componentRef);
    });
  }

  destroyComponent() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  closeWindow() {
    if (this.oldScrollY) {
      this.scrollTo(this.oldScrollY);
    }

    this.opened = false;
  }

  scrollTo(yPos) {
    try {
      window.scrollTo({
        top: yPos
      });
    } catch (err) {
      window.scroll(0, yPos);
    }
  }

}
