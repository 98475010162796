
import {map, filter} from 'rxjs/operators';
import {Injectable, Optional} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import {Observable} from 'rxjs';

import {OutdateVersionService} from '../../outdate-version';

@Injectable()
export class JsonAppVersionInterceptor implements HttpInterceptor {

  private readonly header_name = 'X-Front-Version';

  constructor(@Optional() private s: OutdateVersionService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /*
     * Обработка обновления версии системы
     */
    return next.handle(req).pipe(filter(x => x instanceof HttpResponse),
      map((response: HttpResponse<any>) => {

        if (response.headers.has(this.header_name) && this.s) {
          this.s.version_id = response.headers.get(this.header_name);
        }

        return response;
      }));
  }


}
