<h1>Сделки</h1>

<div class="filter_state">
  <div class="filter_state__tab all_work" (click)="filter_state('all_work')" [ngClass]="{'active': formGroup.value.tab === 'all_work'}">
    Все в работе ({{data.all_work}})
  </div>
  <div class="filter_state__tab done" (click)="filter_state('done')" [ngClass]="{'active': formGroup.value.tab === 'done'}">
    Подтверждены ({{data.done}})
  </div>
  <div class="filter_state__tab fail" (click)="filter_state('fail')" [ngClass]="{'active': formGroup.value.tab === 'fail'}">
    Отклонены ({{data.fail}})
  </div>
  <div class="filter_state__create">
    <form [formGroup]="formGroup" novalidate>
      <div class="row no-gutters gutters-0">
        <div class="col-sm-6">
          <app-material-input-datepicker formControlName="date_start" [cssClass]="'mb-0'" [placeholder]="'Просмотр с'">
          </app-material-input-datepicker>
        </div>
        <div class="col-sm-6">
          <app-material-input-datepicker formControlName="date_end" [cssClass]="'mb-0'" [placeholder]="'Просмотр до'">
          </app-material-input-datepicker>
        </div>
      </div>
    </form>
  </div>
</div>

Всего: <b>{{theadline.showed_lines}}</b>
<div class="material-datatable-wrapper table-color">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)">
  </app-datatable-headline>
  <div class="table-responsive">
    <table class="table datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns"
             [rowClass]="'tab'">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>

<ng-template #object_captionTmpl let-row="row" let-ind="row_index">
  <a routerLink="/housing2/flat/{{row.realty_flat_id}}">{{row.object_caption}}</a>
</ng-template>

<ng-template #physical_subagent_fioTmpl let-row="row" let-ind="row_index">
  <a href="/physical/edit/{{row.physical_subagent_id}}">{{row.physical_subagent_fio}}</a>
</ng-template>

<ng-template #subagent_captionTmpl let-row="row" let-ind="row_index">
  <a href="/entity/edit/{{row.subagent_entity_id}}">{{row.subagent_caption}}</a>
</ng-template>

<ng-template #physical_manager_fioTmpl let-row="row" let-ind="row_index">
  <a href="/physical/edit/{{row.physical_manager_id}}">{{row.physical_manager_fio}}</a>
</ng-template>

<ng-template #appeal_client_fioTmpl let-row="row" let-ind="row_index">
  <ng-container *ngIf="row.lead_id !== ''">
    <a href="/reservation/lead-info/{{row.lead_id}}">{{row.appeal_client_fio}}</a>
  </ng-container>
  <ng-container *ngIf="row.lead_id === ''">
    {{row.appeal_client_fio}}
  </ng-container>
</ng-template>

<ng-template #stage_captionTmpl let-row="row">
  <app-stages-dots [data]="row.stage_information"></app-stages-dots>
</ng-template>
