<div class="tab-content-wrapper mt-3">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <div>
      <div *ngIf="!check_boss" class="mb-3">
        <app-alert-danger>
          <div><i class="fa-fw fa fa-warning"></i> Не установлен <span class="text-bold">руководитель</span></div>
        </app-alert-danger>
      </div>
      <div *ngIf="isSubagent">
        <app-alert-warning>
          <div><i class="fa-fw fa fa-warning"></i> <b>Внимание!</b> Это субагент!</div>
          <div class="mt-2">Крайне не рекомендуется менять связи с организацией/ями, у субагента должна быть
            единственная
            организация, которая была создана при регистрации субагента
          </div>
        </app-alert-warning>
        <button (click)="transformationOrg()"
                type="button" class="button button_main mt-3">
          Преобразовать в юр. лицо
        </button>
        <button *ngIf="about.lead_id" [routerLink]="['/attraction/lead-info', about.lead_id]"
                type="button" class="button button_main mt-3 ml-3">
          Перейти в лид по привлечению
        </button>
        <button [routerLink]="['/transfer', 'entity', entityId]"
                type="button" class="button button_main mt-3 ml-3">
          Перенести сотрудника в другую организацию
        </button>
      </div>
      <div *ngIf="!isSubagent">
        <button (click)="transformationSub()"
                type="button" class="button button_main">
          Преобразовать в субагента
        </button>
      </div>
    </div>
    <div formArrayName="physicals" class="mt-4">
      <div class="row no-gutters gutters-4 align-items-center" *ngIf="data.length">
        <div class="col-3 text-bold">ФИО</div>
        <div class="col-3 text-bold">Должность</div>
        <div class="col-1 text-bold" title="Руководитель организации">Р-ль</div>
        <div class="col-1 text-bold">Админ</div>
        <div class="col-1 text-bold" title="Ответственный за новостройки">Отв. за СПН</div>
        <div class="col-1 text-bold">Видимость ТК</div>
        <div class="col-2 text-bold"></div>
        <div class="col-12"><hr class="clearfix"></div>

        <ng-container *ngFor="let relation of physicals.controls; let id = index" [formGroupName]="id">
          <ng-container *ngIf="!relation.value.edit">
            <div class="col-3">
              <a [routerLink]="['/physical/edit/', relation.value.physical_id]">
                <i class="fa fa-user"></i> {{relation.value.physical_fio_text}}
              </a>
            </div>
            <div class="col-3">
              {{relation.value.post_caption}}
            </div>
            <div class="col-1">
              <div class="form__group">
                <label class="form__checkbox" [ngClass]="'form__checkbox_icon_done_mark'"
                       (click)="$event.preventDefault();
                       (relation.value.is_boss) ? false : onChangeBoss(relation, !relation.value.is_boss);">
                  <input type="checkbox" [checked]="relation.value.is_boss" [disabled]="relation.value.is_boss">
                  <span class="form__checkbox__wrapper">
                    <span class="form__checkbox__wrapper__toggler icon" [ngClass]="'icon_done_mark'"></span>
                  </span>
                </label>
              </div>
            </div>
            <div class="col-1">
              <div class="form__group">
                <label class="form__checkbox" [ngClass]="'form__checkbox_icon_done_mark'"
                       (click)="$event.preventDefault(); onChangeAdmin(relation, !relation.value.is_admin);">
                  <input type="checkbox" [checked]="relation.value.is_admin">
                  <span class="form__checkbox__wrapper">
                    <span class="form__checkbox__wrapper__toggler icon" [ngClass]="'icon_done_mark'"></span>
                  </span>
                </label>
              </div>
            </div>
            <div class="col-1">
              <div class="form__group">
                <label class="form__checkbox" [ngClass]="'form__checkbox_icon_done_mark'"
                       (click)="$event.preventDefault();
                       (relation.value.is_spn) ? false : onChangeSpn(relation, !relation.value.is_spn);">
                  <input type="checkbox" [checked]="relation.value.is_spn" [disabled]="relation.value.is_spn">
                  <span class="form__checkbox__wrapper">
                    <span class="form__checkbox__wrapper__toggler icon" [ngClass]="'icon_done_mark'"></span>
                  </span>
                </label>
              </div>
            </div>
            <div class="col-1">
              <div class="form__group">
                <label class="form__checkbox" [ngClass]="'form__checkbox_icon_done_mark'"
                       (click)="$event.preventDefault(); onChangeIsShowTarif(relation, !relation.value.is_show_tarif);">
                  <input type="checkbox"
                         [checked]="relation.value.is_show_tarif"
                         [disabled]="relation.value.is_boss || relation.value.is_admin"
                  >
                  <span class="form__checkbox__wrapper">
                    <span class="form__checkbox__wrapper__toggler icon" [ngClass]="'icon_done_mark'"></span>
                  </span>
                </label>
              </div>
            </div>
            <div class="col-2">
              <a class="button button_icon button_icon_sub mr-1" (click)="relationEdit(relation)">
                <i class="fa fa-edit"></i></a>
              <a class="button button_icon button_icon_sub mr-1"
                 (click)="relationDelete(relation)">
                <i class="fa fa-trash-o"></i></a>
            </div>
          </ng-container>
          <ng-container *ngIf="relation.value.edit">
            <div class="col-6">
              <app-material-input formControlName="physical_fio" [require]="true"
                                  [placeholder]="'Сотрудник'">
                Сотрудник
              </app-material-input>
            </div>
            <div class="col-6">
              <app-material-select2 formControlName="post_id" [caption]="'Должность'" [require]="true">
                <option [value]="post.post_id" *ngFor="let post of posts">
                  {{post.post_caption}}
                </option>
              </app-material-select2>
            </div>
            <div class="col-12 mt-2">
              <div class="row justify-content-center">
                <button class="button button_sub" (click)="relationEdit(relation)">Отменить</button>
                <button class="button ml-3" (click)="relationChange(relation)">Отредактировать</button>
              </div>
            </div>
          </ng-container>
          <div class="col-12">
            <hr class="clearfix">
          </div>
        </ng-container>
      </div>
    </div>
  </form>
</div>

