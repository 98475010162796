
<ng-container *ngIf="statistics">
  <div class="mb-3">
    <a href="javascript: void(0)" (click)="showStatistics()">
      <ng-container *ngIf="statistics_view">Скрыть статистику</ng-container>
      <ng-container *ngIf="!statistics_view">Показать статистику</ng-container>
    </a>
  </div>
  <div class="row mb-3" *ngIf="statistics_view">
    <div class="col-6">
      <table class="table table_middle pr-3">
        <tr>
          <th>Агенства недвижимости</th>
          <th>Кол-во</th>
        </tr>
        <tr><td>Зарегистрированных в системе</td><td>{{statistics['all_subagents']}}</td></tr>
        <tr><td><a href="javascript: void(0)" (click)="click_is_approve('Да')">Сотрудники которые прошли обучение <i *ngIf="this.formGroup.value.is_approve === 'Да'" class="icon small ml-2 icon_done_mark"></i></a></td><td>{{statistics['cnt_org_approved_staff']}}</td></tr>
        <tr><td><a href="javascript: void(0)" (click)="click_is_approve('Нет')">Ни один сотрудник не прошел обучение <i *ngIf="this.formGroup.value.is_approve === 'Нет'" class="icon small ml-2 icon_done_mark"></i></a></td><td>{{statistics['cnt_org_not_approved_staff']}}</td></tr>
        <tr>
          <th><br>Статистика по обучению за последние 30 дней</th>
          <th><br>Кол-во</th>
        </tr>
        <tr><td>АН прошедшие обучение</td><td>{{statistics['cnt_org_approve_on_month']}}</td></tr>
        <tr><td>Сотрудники прошедшие обучение</td><td>{{statistics['cnt_staff_approve_on_month']}}</td></tr>
      </table>
    </div>
    <div class="col-6">
      <table class="table pl-3">
        <tr>
          <th>Сотрудники</th>
          <th>Кол-во</th>
        </tr>
        <tr><td>Зарегистрированных сотрудников АН</td><td>{{statistics['all_staff']}}</td></tr>
        <tr><td>Обученных сотрудников</td><td>{{statistics['cnt_staff_approve']}}</td></tr>
        <tr><td>Не обученных сотрудников</td><td>{{statistics['cnt_staff_not_approve']}}</td></tr>
        <tr>
          <th><br>Общая статистика по АН</th>
          <th><br>Кол-во</th>
        </tr>
        <tr><td>Среднее кол-во сотрудников в АН</td><td>{{statistics['avg_staff_in_org']}}</td></tr>
        <tr><td>Максимальное кол-во сотрудников в АН</td><td>{{statistics['max_staff_in_org']}}</td></tr>
      </table>
    </div>
    <!--<div class="col-4">-->
      <!--<table class="table">-->
        <!--<tr>-->
          <!--<th>АН по категориям сотрудников</th>-->
          <!--<th>Кол-во</th>-->
        <!--</tr>-->
        <!--<tr><td>1 сотрудник в АН</td><td>{{statistics['org_staff_1']}}</td></tr>-->
        <!--<tr><td>2-3 сотрудника в АН</td><td>{{statistics['org_staff_2_3']}}</td></tr>-->
        <!--<tr><td>4-10 сотрудников в АН</td><td>{{statistics['org_staff_4_10']}}</td></tr>-->
        <!--<tr><td>11-20 сотрудников в АН</td><td>{{statistics['org_staff_11_20']}}</td></tr>-->
        <!--<tr><td>21-30 сотрудников в АН</td><td>{{statistics['org_staff_21_30']}}</td></tr>-->
        <!--<tr><td>31-50 сотрудников в АН</td><td>{{statistics['org_staff_31_50']}}</td></tr>-->
        <!--<tr><td>Более 50 сотрудников в АН</td><td>{{statistics['org_staff_more_50']}}</td></tr>-->
      <!--</table>-->
    <!--</div>-->
  </div>
</ng-container>

<div class="material-datatable-wrapper">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)"
    [templateRight]="buttonCreate">
  </app-datatable-headline>
  <div class="table-responsive">
    <table class="table table-striped datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>

<ng-template #buttonCreate>
  <div class="text-right">
    <a class="button button_sm mr-2" href="javascript: void(0)" (click)="onExcel()">Скачать в Excel</a>
  </div>
</ng-template>

<ng-template #is_approveTmpl let-row="row">
  {{(row.is_approve) ? 'Да' : 'Нет'}}
</ng-template>

<ng-template #org2phyCaptiondTmpl let-row="row">
  <a [innerHtml]="row.org_socrachcaption" [routerLink]="['/entity/edit', row.org_id]"></a>
</ng-template>

<ng-template #userTmpl let-row="row">
  <a [innerHtml]="row.physical_fio" [routerLink]="['/physical/edit', row.physical_id]"></a>
</ng-template>

<ng-template #userIsActive let-row="row">
  <span *ngIf="row['is_active']">Да</span>
  <span *ngIf="!row['is_active']">Нет</span>
</ng-template>


<ng-template #physicalFio let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_fio" [cssClass]="'mb-0'" [placeholder]="'ФИО'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #org2phyCaption let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="org_socrachcaption" [cssClass]="'mb-0'" [placeholder]="'Организация'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #is_approve let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="is_approve" [cssClass]="'mb-0'" [placeholder]="'Обучен'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #physicalPhone let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_phone" [cssClass]="'mb-0'" [placeholder]="'Телефон'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #physicalCreate let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_create" [cssClass]="'mb-0'" [placeholder]="'Дата регистрации'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #physical_access let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_access" [cssClass]="'mb-0'" [placeholder]="'Доступ'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #is_active let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-select formControlName="is_active" [cssClass]="'mb-0'">
      <option [value]="''">Все</option>
      <option [value]="true">Да</option>
      <option [value]="false">Нет</option>
    </app-material-select>
  </form>
</ng-template>

<ng-template #physical_role let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_role" [cssClass]="'mb-0'" [placeholder]="'Роль'">
    </app-material-input>
  </form>
</ng-template>
