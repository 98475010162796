import { Injectable } from '@angular/core';

@Injectable()
export class StatisticPluginService {

  private _backref = '/statistic/plugin';

  set backref(value) {
    this._backref = value;
  }

  get backref() {
    return this._backref;
  }

  constructor() {
  }
}
