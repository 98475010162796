import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {filter, takeUntil} from 'rxjs/operators';
import {BreadcrumbsService} from '../../../../shared/breadcrumbs/services';

declare const moment;

@Injectable()
export abstract class ReportComponent implements OnInit, OnDestroy {
  /*
   * Абстрактный класс для отчетов
   */

  private static readonly date_format = 'MM.YYYY';

  // public readonly base_url = '';

  public sort_direction = 'desc';
  public sort_field = null;

  public get next_sort_direction() {
    return this.sort_direction === 'asc' ? 'desc' : 'asc';
  }

  public static deployData(data: Array<{[p: string]: any}>,
                           sort_field: number = null,
                           sort_direction: string = 'asc',
                           search_string: string = '') {
    /*
     * Первым проходом находим уникальные индексы для ЖК и дат отчета
     */
    const unique_builds: Set<string> = new Set<string>();
    const unique_months: Set<string> = new Set<string>();

    for (const el of data) {
      unique_builds.add(el['caption']);
      unique_months.add(el['date']);
    }

    const builds = Array.from(unique_builds);
    let months = Array.from(unique_months)
      .map(x => moment(x, this.date_format))
      .sort((a, b) => a.unix() - b.unix());

    /*
     * Считаем все пропущенные месяцы, ведь на бэкэнде это сделать невозможно
     */

    let from$ = moment(months[0], this.date_format);
    const to$ = moment(months[months.length - 1], this.date_format);

    while (from$.unix() < to$.unix()) {
      from$.add(1, 'month');

      if (unique_months.has(from$.format(this.date_format))) {
        continue;
      }

      unique_months.add(from$.format(this.date_format));
    }

    months = Array.from(unique_months)
      .map(x => moment(x, this.date_format))
      .sort((a, b) => a.unix() - b.unix());

    /*
     * Финализация данных таблицы
     */

    const head = ['Название'];

    for (const month of months) {
      head.push(month.format('MMM<br>YYYY'));
    }

    head.splice(1, 0, '<b>Итого</b>');

    let rows = [];

    for (const name of builds) {
      const row = [name];

      if (search_string && name.toLowerCase().indexOf(search_string.toLowerCase()) === -1) {
        continue;
      }

      for (let i = 0; i < months.length; i++) {
        const value = data.filter(
          x => x['caption'] === name &&
            x['date'] === months[i].format(this.date_format)
        ).pop();

        row.push((value ? value['count'] : '0').toString());
      }

      const sum = row.filter((v, i) => i).reduce((a ,b) => +a+ +b, 0);

      row.splice(1, 0, sum.toString());

      rows.push(row);
    }

    /*
     * Формирование статистики по месяцам
     * gap = 1 потому что в первой ячейке лежит название ЖК
     */
    const gap = 1;
    const total = Array.apply(null, new Array<any>(months.length + gap))
      .map(_ => []);

    for (let i = 0; i < months.length + gap; i++) {
      for (const row of rows) {
        total[i].push(row[i + gap]);
      }
    }

    for (let i = 0; i < total.length; i++) {
      total[i] = total[i].reduce((a, b) => +a + +b, 0);
    }

    total.unshift('<b>Итого</b>');

    /*
     * Сортировка готовой таблицы
     */

    if (sort_field && 'asc' === sort_direction) {
      rows = rows.sort((a, b) => a[sort_field] - b[sort_field]);
    }
    else if (sort_field && 'desc' === sort_direction) {
      rows = rows.sort((a, b) => b[sort_field] - a[sort_field]);
    }

    return [head, total, ...rows];
  }

  public static resort(data: Array<{[p: string]: any}>,
                      sort_field: number = null,
                      sort_direction: string = 'asc') {
    if (sort_field && 'asc' === sort_direction) {
      for (const sources of data['groups']) {
        sources['sources'].sort((a, b) => a.data[sort_field] - b.data[sort_field]);
      }
    } else if (sort_field && 'desc' === sort_direction) {
      for (const sources of data['groups']) {
        sources['sources'].sort((a, b) => b.data[sort_field] - a.data[sort_field]);
      }
    }


    return data;
  }

  private ngUnsubscribe = new Subject();

  constructor(public r: Router,
              public a: ActivatedRoute,
              public b: BreadcrumbsService) {  }

  ngOnInit() {
    this.r.events.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(e => e instanceof NavigationEnd )
    )
      .subscribe(_ => this.whenUrlChange());
  }

  whenUrlChange() {
    this.sort_direction = this.a.snapshot.queryParams['sort_direction'] || 'desc';
    this.sort_field = this.a.snapshot.queryParams['sort_field'] || null;
  }

  ngOnDestroy() {
    if (this.ngUnsubscribe) {
      this.ngUnsubscribe.next(false);
      this.ngUnsubscribe.unsubscribe();
    }
  }
}
