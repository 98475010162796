import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialInputModule } from '../../modules/@material-input/material-input.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PreloaderModule } from '../../modules/@preloader/preloader.module';
import { TabsModule } from '../../modules/@tabs/tabs.module';
import { RouterModule } from '@angular/router';
import { SimpleTableModule } from '../../modules/@simple-table/simple-table.module';
import { DataTableModule } from '../../modules/@datatable/datatables.module';
import { SharedModule } from '../../shared/shared.module';
import {SubagentsActionsComponent} from './subagents-actions.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DataTableModule,
    TabsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
    SharedModule,
    PreloaderModule,
    SimpleTableModule,
  ],
  declarations: [
    SubagentsActionsComponent,
  ],
  exports: [
    SubagentsActionsComponent,
  ]
})
export class SubagentsActionsModule { }
