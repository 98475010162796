import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Subscription } from 'rxjs';
import { SingleWindowService } from '../../single-window/shared/single-window.service';
import { BackrefWindowService } from '../../single-window/shared/backref-window.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HttpClient } from '../../../services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableParamComponent } from '../../../modules/@datatable/components/datatable-param.component';
import { debounceTime } from 'rxjs/operators';
import {GoBackButtonService} from '../../../services/go-back-button.service';


@Component({
  selector: 'app-control-conditions',
  templateUrl: './control-conditions.component.html',
  styleUrls: ['./control-conditions.component.scss']
})
export class ControlConditionsComponent extends DataTableParamComponent implements OnInit, AfterViewInit, OnDestroy {


  @ViewChild('complex_captionHead', {static: true}) private complex_captionHead;
  @ViewChild('housing_captionHead', {static: true}) private housing_captionHead;
  @ViewChild('section_captionHead', {static: true}) private section_captionHead;
  @ViewChild('flatTmpl', {static: true}) private flatTmpl;

  @ViewChild('viewTmpl', {static: true}) private viewTmpl;
  @ViewChild('errorTmpl', {static: true}) private errorTmpl;

  @ViewChild('statusHead', {static: true}) private statusHead;
  @ViewChild('activeHead', {static: true}) private activeHead;

  public current_flat: any = null;
  public columns;
  public formGroup: UntypedFormGroup;
  public flat_statuses: any;
  private subscriptionForm: Subscription;
  public api_action = 'Data_tables.get_control_realization';
  public url: any;
  private firstLoad = true;
  constructor(public http: HttpClient, private fb: UntypedFormBuilder, public router: Router,
              public activatedRoute: ActivatedRoute, public goBack: GoBackButtonService,
              public singleWindowService: SingleWindowService, public backrefWindowService: BackrefWindowService) {
    super(http, activatedRoute, router);
    const published = this.getField('is_published');
    this.formGroup = this.fb.group({
      'complex_caption': [this.getField('complex_caption')],
      'housing_caption': [this.getField('housing_caption')],
      'section_caption': [this.getField('section_caption')],
      'status_id': [this.getField('status_id')],
      'is_published': [published ? published : 'true'],
      'flat_caption': [''],
    });
    this.flat_statuses = activatedRoute.snapshot.data['flat_statuses'];
    this.subscriptionForm = this.formGroup.valueChanges.pipe(debounceTime(500))
      .subscribe((data) => {
        this.router_param['page'] = 1;
        this.current_page = 1;
        if (this.firstLoad) {
          this.firstLoad = false;
        } else {
          this.changeForm(this.formGroup);
        }
    });
    this.backrefWindowService.routerInit();
  }

  ngOnInit() {
    this.columns = [
      {'name': 'complex_caption', 'caption': 'Комплекс', 'sortable': true, 'templateHead': this.complex_captionHead},
      {'name': 'housing_caption', 'caption': 'Корпус', 'sortable': true, 'templateHead': this.housing_captionHead},
      {'name': 'section_caption', 'caption': 'Секция', 'sortable': true, 'templateHead': this.section_captionHead},
      {'name': 'time_created', 'caption': 'Дата создания', 'sortable': true},
      {'name': 'caption', 'caption': 'Квартира', 'sortable': true, 'template': this.flatTmpl},
      {'name': 'status_caption', 'caption': 'Статус', 'sortable': true,
        'templateHead': this.statusHead},
      {'name': 'is_published', 'caption': 'Опубликовано', 'sortable': false,
        'template': this.viewTmpl, 'templateHead': this.activeHead},
      {'name': 'problem_caption', 'caption': 'Проблемы', 'template': this.errorTmpl},
    ];
    super.ngOnInit();
  }

  public prepareQuery() {
    const query = super.prepareQuery();
    query['filter']['fields'] = this.formGroup.value;
    return query;
  }

  public loadList() {
    super.loadList();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscriptionForm) {
      this.subscriptionForm.unsubscribe();
    }
    this.backrefWindowService.routerDestroy();
  }

  ngAfterViewInit() {
    this.goBack.goScroll();
  }

  getProblem(value) {
    if (value === 'fractions') {
      return 'Не заполнены условия реализации';
    } else if (value === 'commissions') {
      return 'Не заполнены суммы коммиссий субагента';
    }
  }
}
