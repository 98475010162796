<a [routerLink]="url" class="a-no-format" [class.incorrect]="count > 0">
  <div class="card-header">
    <h4 class="my-0 font-weight-normal">{{title}}</h4>
  </div>
  <div class="card-body">
    <h2 *ngIf="loading" class="card-title pricing-card-title">
      <i class="fa fa-spin fa-spinner mr-2"></i>
    </h2>
    <h2 *ngIf="count !== null && !loading" class="card-title pricing-card-title">
      {{!count && count !== 0 ? '&mdash;' : count}}
      <small class="low-opacity"> шт</small>
    </h2>
    <p class="mb-0">
      {{description}}
    </p>
  </div>
  <!--<div class="card-footer">-->
  <!--<a [routerLink]="url" class="button btn-block">Перейти</a>-->
  <!--</div>-->
</a>
