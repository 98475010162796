
<form [formGroup]="form" *ngIf="form" novalidate (submit)="onSubmit()">
  <div class="row no-gutters gutters-4">
    <div class="col-12 mt-2r">
      <app-material-select2-autocomplete formControlName="sale_subagent_user_id"
                                         [placeholder]="'Введите субагента'"
                                         [caption]="'Введите субагента'"
                                         [api_action]="'Dict.bp_sale_subagent_user_ac'"
                                         [require]="true">
        <option [value]="form.value.sale_subagent_user_id"></option>
      </app-material-select2-autocomplete>
    </div>
    <div class="col-12">
      <app-material-select2-autocomplete formControlName="worker_id"
                                         [placeholder]="'Ответственный от SPN24'"
                                         [caption]="'Ответственный от SPN24'" [api_action]="'Dict.bp_ci_worker_ac'">
        <option [value]="form.value.worker_id">{{form.value.worker_caption}}</option>
      </app-material-select2-autocomplete>
    </div>
    <div class="col-12">
      <app-material-select2 formControlName="source_id" [require]="true" [caption]="'Источник лида'">
        <option *ngFor="let option of leadSources" [value]="option.id">
          {{option.caption}}
        </option>
      </app-material-select2>
    </div>

    <div class="col-12">
      <app-material-select2 formControlName="sale_lead_transfer" [require]="true" [caption]="'Расширенное бронирование'" placeholder="Не выбрано">
        <option [value]="''"></option>
        <option [value]="false">Самостоятельная работа</option>
        <option [value]="true">Расширенное бронирование (передача лида)</option>
      </app-material-select2>
    </div>

    <div class="col-12">
      <app-material-select2-autocomplete formControlName="realty_id"
                                         [placeholder]="'Выбор объекта'"
                                         [caption]="'Выберите объект'" [require]="true" [api_action]="'Dict.bp_sale_realty_ac'">
        <option [value]="form.value.realty_id">{{form.value.realty_caption}}</option>
      </app-material-select2-autocomplete>
    </div>

    <div class="col-12">
      <app-material-input formControlName="sale_eat_id"
                          [placeholder]="'Идентификатор EST-A-TET'"
                          [require]="false"
                          [titleDescription]="'Указывается, если создается заявка по ' +
                           'проблеме отсутствия связей из периодической обработки квалификаций ЭСТ-А-ТЕТ'"
      >
        Идентификатор EST-A-TET</app-material-input>
    </div>

    <ng-container *ngIf="showStage">
      <div class="col-12">
        <app-material-select2 formControlName="stage_id" [require]="true" [caption]="'Этап работы с лидом'">
          <option *ngFor="let option of stage_forms" [value]="option.id">
            {{option.caption}}
          </option>
        </app-material-select2>
      </div>
      <ng-container *ngIf="descriptionStage">
        <div class="col-12">
          <p><span class="bold">Описание этапа: </span> {{descriptionStage}}</p>
        </div>
      </ng-container>
    </ng-container>

    <div class="col-12">
      <h5 class="mt-2r">Данные клиента</h5>
    </div>
    <div class="col-4">
      <app-material-input formControlName="lastName"
                          [placeholder]="'Фамилия'">
        Фамилия</app-material-input>
    </div>
    <div class="col-4">
      <app-material-input formControlName="firstName"
                          [placeholder]="'Имя'"
                          [require]="true">
        Имя</app-material-input>
    </div>
    <div class="col-4">
      <app-material-input formControlName="middleName"
                          [placeholder]="'Отчество'">
        Отчество</app-material-input>
    </div>
    <div class="col-6">
      <app-material-input-mask formControlName="phone" [mask]="'+7 (000) 000-00-00'" [require]="true"
                               [placeholder]="'+7 (___) ___-__-__'">
        Телефон</app-material-input-mask>
    </div>
    <div class="col-6">
      <app-material-input formControlName="email"
                          [placeholder]="'Email'">
        Email</app-material-input>
    </div>

    <div class="col-12">
      <app-material-textarea [rows]="4" [placeholder]="'Примечание'"
                             formControlName="notice">
        Комментарий к лиду
      </app-material-textarea>
    </div>
    <div class="col-12">
      <h5 class="mt-2r">Этапы бизнес процесса</h5>
    </div>
    <div class="col-6">
      <app-material-input-datepicker formControlName="next_contact_date" [require]="true"
                          [placeholder]="'Дата следующего контакта'">
        Дата следующего контакта</app-material-input-datepicker>
    </div>
    <div class="col-6">
      <app-material-input-mask formControlName="next_contact_time"
                               [placeholder]="'__:__'" [mask]="'00:00'">
        Время следующего контакта</app-material-input-mask>
    </div>
    <div class="col-12">
      <app-material-textarea [rows]="4" [placeholder]="'Комментарий для контакта'"
                             formControlName="next_contact_comment">
        Комментарий для контакта бизнес процесса
      </app-material-textarea>
    </div>
  </div>
  <div class="row no-gutters gutters-2 mt-3 pb-5">
    <div class="col-md-10">
      <button type="submit" class="button" [disabled]="!form.valid">Создать лид</button>
    </div>
  </div>
</form>
