import { Component, Input, Output, EventEmitter, SimpleChange, OnChanges } from '@angular/core';

@Component({
  /* tslint:disable-next-line:component-selector-prefix component-selector-name */
  selector: '[app-data-table-body]',
  templateUrl: './datatable-body.component.html'
})
export class DataTableBodyComponent implements OnChanges {
  public fields: Array<any> = [];

  @Input() public loading = true;
  @Input() public rowClass: string = null;
  @Input() rows: Array<any> = null;
  @Input() public columns: Array<any> = null;

  @Output() action_element_edit: EventEmitter<any> = new EventEmitter<any>();
  @Output() action_element_remove: EventEmitter<any> = new EventEmitter<any>();
  @Output() public rowClick: EventEmitter<any> = new EventEmitter();

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    const change = changes['columns'];
    if (!change.isFirstChange()) {
      this.loading = false;
    }
  }

  onIsNull(value) {
    return value === undefined || value === null;
  }

  fireEdit(row: any) {
    this.action_element_edit.emit(row);
  }

  fireRemove(row: any) {
    this.action_element_remove.emit(row);
  }
}
