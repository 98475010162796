<ul class="navigation__tabs" role="tablist">
    <li class="navigation__tab">
      <a class="navigation__tab__link" [routerLink]="['/entity/edit', entityId, 'common']"
        [ngClass]="{'navigation__tab__link_active': 'common' === currentUrl}">
        Основное
        <span class="navigation__tab__link_active__slide"></span>
      </a>
    </li>
    <li class="navigation__tab">
      <a class="navigation__tab__link" [routerLink]="['/entity/edit', entityId, 'additionally']"
        [ngClass]="{'navigation__tab__link_active': 'additionally' === currentUrl}">
        Реквизиты
        <span class="navigation__tab__link_active__slide"></span>
      </a>
    </li>
    <li class="navigation__tab">
      <a class="navigation__tab__link" [routerLink]="['/entity/edit', entityId, 'physical']"
        [ngClass]="{'navigation__tab__link_active': 'physical' === currentUrl}">
        Сотрудники
        <span class="navigation__tab__link_active__slide"></span>
      </a>
    </li>
    <li class="navigation__tab">
      <a class="navigation__tab__link" [routerLink]="['/entity/edit', entityId, 'documents']"
        [ngClass]="{'navigation__tab__link_active': 'documents' === currentUrl}">
        Документы
        <span class="navigation__tab__link_active__slide"></span>
      </a>
    </li>
    <li class="navigation__tab">
      <a class="navigation__tab__link" [routerLink]="['/entity/edit', entityId, 'address']"
        [ngClass]="{'navigation__tab__link_active': 'address' === currentUrl}">
        Адрес
        <span class="navigation__tab__link_active__slide"></span>
      </a>
    </li>
    <li class="navigation__tab">
      <a class="navigation__tab__link" [routerLink]="['/entity/edit', entityId, 'certificate']"
        [ngClass]="{'navigation__tab__link_active': 'certificate' === currentUrl}">
        Мероприятия
        <span class="navigation__tab__link_active__slide"></span>
      </a>
    </li>
</ul>

<router-outlet></router-outlet>
