import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {UsageVariant} from '../../../models/usage_variants';
import {CalendarListService} from './calendar-list.service';
import {Subscription} from 'rxjs';
import {HttpClient} from '../../../services/http.service';
import {AuthenticateService} from '../../../services/authenticate.service';
import {NotificationService} from '../../../services/notification.service';
import { Component as ICSComponent, Property } from 'immutable-ics';
import {FancyboxService} from '../../fancybox.service';

@Component({
  selector: 'app-calendar-event',
  templateUrl: './calendar-list-event.component.html',
  styleUrls: ['./calendar-list-event.component.scss']
})
export class CalendarListEventComponent implements OnInit, OnDestroy {

  private readonly spn_placement = {
    'address': 'г. Москва, Саввинская наб., д. 23 стр. 1',
    'filepath': '87e2dc33-b7cb-4b60-815f-6e6f1546eb10.jpg',
    'latitude': 55.731338,
    'longitude': 37.560179,
  };

  @Output() private onDelete: EventEmitter<void> = new EventEmitter<void>();

  private _data;
  private subscription: Subscription;
  private quota;

  public event_id: string;
  public url: string;

  public usageCrm;

  public displayMoreInfo = false;

  public displayMap = false;
  public displaSubscribe = false;
  public latitude = null;
  public longitude = null;

  public subscriptionIsAdmin = false;
  public subscriptionDisplay = false;

  public userForSubscription = [];
  public userSubscribed = [];

  public sys: {[p: string]: boolean | string};

  private imagesList: any = [];

  @Input('data') public set data(value) {
    this._data = value;
    this.event_id = value['event_id'];
    this.latitude = value['event_placement_lat'];
    this.longitude = value['event_placement_lon'];

    this.url = environment.apiServer + 'restful/ics/event/' + this.event_id;

    if (value['place_spn']) {
      value['event_placement'] = this.spn_placement['address'];

      this.latitude = this.spn_placement['latitude'];
      this.longitude = this.spn_placement['longitude'];
    }

    this.sys = value['sys'];
    this.imagesList = value['images'].map(x => {
      return {
        'src': x['url'],
        'thumb': x['url'],
        'caption': '',
      };
    });
  }

  public get data() {
    return this._data;
  }

  public get free_places_count() {
    return this.quota - this.userForSubscription.filter(x => x.event_subscribed).length;
  }

  constructor(private elementRef: ElementRef, private calendarListService: CalendarListService,
              private http: HttpClient, private auth: AuthenticateService, private notify: NotificationService,
              private lightbox: FancyboxService) { }

  ngOnInit() {
    this.usageCrm = environment.usage === UsageVariant.CRM;
    // this.usageCrm = false;

    this.subscription = this.calendarListService.clickEventEmitter.subscribe(x => {
      if (x === this.event_id) {
        this.scrollViewport();
      }
    });

    this.subscriptionIsAdmin = this.auth.user.is_admin || this.auth.user.access && this.auth.user.access['raw']['is_admin'];
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  scrollViewport() {
    this.elementRef.nativeElement.scrollIntoView();
  }

  displayInfo() {
    this.displayMoreInfo = true;
  }

  hideInfo() {
    this.displayMoreInfo = false;
  }

  displaySubscriptionSection() {
    this.displaSubscribe = true;

    this.http.post('Calendar.get_subscription_list', {'event_id': this.event_id})
      .subscribe(x => {
        this.userForSubscription = x;
        this.quota = +this.data['places_free'] + this.userForSubscription.filter(y => y.event_subscribed).length;
      });
  }

  subscribeUser(user) {
    this.userForSubscription.forEach(x => {
      if (x.physical_user_id === user.physical_user_id) {
        x.event_subscribed = !x.event_subscribed;
      }
    });
  }

  subscribeSelf() {
    NotificationService.swalConfirm('Подписка на мероприятие', 'Вы будете подписаны на мероприятие, продолжить?')
      .then(_ => this.http.post('Calendar.subscribe_me', {'event_id': this.event_id})
        .subscribe(response => {
          if (response) {
            this.data['is_subscribed'] = true;
            this.notify.notifySuccess('Готово', 'Подписка сохранена', 3000);
          }
          else {
            NotificationService.swalError('Ошибка', 'Невозможно подписаться на это мероприятие');
          }
        }, error => {
            NotificationService.swalError('Ошибка', 'Невозможно подписаться на это мероприятие');
        })).catch(() => false);
  }

  unsubscribeSelf() {
    NotificationService.swalConfirm('Подписка на мероприятие', 'Ваша подписка будет отменена, продолжить?')
      .then(_ => this.http.post('Calendar.unsubscribe_me', {'event_id': this.event_id})
        .subscribe(response => {
          if (response) {
            this.data['is_subscribed'] = false;
            this.notify.notifySuccess('Готово', 'Подписка отменена', 2000);
          }
          else {
            NotificationService.swalError('Ошибка', 'Невозможно подписаться на это мероприятие');
          }
        })).catch(() => false);
  }

  safeEventSubscription() {
    NotificationService.swalConfirm('Подписка на мероприятие', 'Подписка будет сохранена, продолжить?')
      .then(_ => {
        const physicals = this.userForSubscription.filter(x => x.event_subscribed).map(x => {
          return {'id': x['physical_user_id']};
        });
        this.http.post('Calendar.subscribe', {'event_id': this.event_id, 'users_list': physicals})
          .subscribe(response => {
            if (response) {
              this.displaSubscribe = false;
              this.notify.notifySuccess('Готово', 'Подписка сохранена', 2000);
              this.data['places_free'] = this.free_places_count;
            } else {
              NotificationService.swalError('Ошибка', 'Невозможно подписаться на это мероприятие');
            }
          }, error => {
            NotificationService.swalError('Ошибка', 'Невозможно подписаться на это мероприятие');
          });
      }).catch(() => false);
  }

  displayImage(image) {
    const index = this.imagesList.findIndex(x => x['src'] === image['url']);
    this.lightbox.open(this.imagesList, index);
  }

  downloadIcs() {
    const calendarProperties: Array<Property> = [
      new Property({ name: 'VERSION', value: 2 }),
      new Property({ name: 'METHOD', value: 'REQUEST' }),
      new Property({ name: 'PRODID', value: '-//SPN24//ICS Generator//RU' }),
    ];

    const timezoneProperties: Array<Property> = [
      new Property({ name: 'TZID', value: 'Москва, Санкт-Петербург, Волгоград' }),
      new Property({ name: 'X-ENTOURAGE-CFTIMEZONE', value: 'Europe/Moscow' }),
      new Property({ name: 'X-ENTOURAGE-TZID', value: '22' }),
    ];

    const timezoneStandartProperties: Array<Property> = [
      new Property({ name: 'TZOFFSETFROM', value: '+0300' }),
      new Property({ name: 'TZOFFSETTO', value: '+0300' }),
      new Property({ name: 'DTSTART', value: '20150101T000000' }),
    ];

    const eventProperties: Array<Property> = [
      new Property({name: 'UID', value: this.event_id}),
      new Property({name: 'X-ENTOURAGE_UUID', value: this.event_id}),
      new Property({name: 'SUMMARY', value: this.data['event_caption']}),
      new Property({name: 'DTSTART', parameters: { TZID: '"Москва, Санкт-Петербург, Волгоград"' }, value: this.data['ics_time_begin']}),
      new Property({name: 'DTEND', parameters: { TZID: '"Москва, Санкт-Петербург, Волгоград"' }, value: this.data['ics_time_end']}),
      new Property({name: 'LOCATION', value: this.data['event_placement']}),
      new Property({name: 'ORGANIZER', parameters: { MAILTO: 'info@spn24.ru' }, value: 'SPN24'}),
      new Property({name: 'SEQUENCE', value: '0'}),
    ];

    const alarmProperties: Array<Property> = [
      new Property({name: 'ACTION', value: 'DISPLAY'}),
      new Property({name: 'DESCRIPTION', value: 'REMINDER'}),
      new Property({name: 'TRIGGER', parameters: { RELATED: 'START' }, value: '-PT01H00M00S'}),
    ];

    let calendar = new ICSComponent({ name: 'VCALENDAR' });

    for (let i = 0; i < calendarProperties.length; i++) {
      calendar = calendar.pushProperty(calendarProperties[i]);
    }

    let timezone = new ICSComponent({ name: 'VTIMEZONE' });

    for (let i = 0; i < timezoneProperties.length; i++) {
      timezone = timezone.pushProperty(timezoneProperties[i]);
    }

    let tzstandard = new ICSComponent({ name: 'STANDARD' });

    for (let i = 0; i < timezoneStandartProperties.length; i++) {
      tzstandard = tzstandard.pushProperty(timezoneStandartProperties[i]);
    }

    timezone = timezone.pushComponent(tzstandard);
    calendar = calendar.pushComponent(timezone);

    let event = new ICSComponent({ name: 'VEVENT' });

    for (let i = 0; i < eventProperties.length; i++) {
      event = event.pushProperty(eventProperties[i]);
    }

    let alarm = new ICSComponent({ name: 'VALARM' });

    for (let i = 0; i < alarmProperties.length; i++) {
      alarm = alarm.pushProperty(alarmProperties[i]);
    }

    event = event.pushComponent(alarm);
    calendar = calendar.pushComponent(event);

    const blob = new Blob([calendar.toString()], {type: 'text/calendar'});
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  public displayPlacement() {
    const images = [
      {
        'src': this.data['placement_map']['url'],
        'thumb': this.data['placement_map']['url'],
        'caption': '',
      }
    ];
    this.lightbox.open(images, 0);
  }

  public removeEvent(event_id) {
    NotificationService.swalConfirm('Удалить?', 'Событие будет удалено безвозвратно, продолжить?')
      .catch(_ => false)
      .then(_ => {
        this.http.post('Calendar.remove_single_event', {'event_id': event_id})
          .subscribe(() => this.onDelete.emit());
      });
  }

}
