<form class="form form_crm" [formGroup]="form" novalidate *ngIf="form">
  <div class="row no-gutters gutters-4" formGroupName="advanced">
    <div class="col-6">
      <div class="input__group__wrapper">
        <div class="input__group__wrapper__label">Тип отделки</div>
        <div class="mt-2">
          <app-material-input-checkbox formControlName="bool_tip_otd_id">
            Поле из корпуса
          </app-material-input-checkbox>
        </div>
        <div>
          <app-material-select formControlName="flat_tip_otd_id"  *ngIf="!form.value['advanced'].bool_tip_otd_id">
            <option [value]="''">-- Не выбрано --</option>
            <option *ngFor="let otd of type_otd" [value]="otd.dict_realty_type_otd_id">
              {{otd.dict_realty_type_otd_caption}}
            </option>
          </app-material-select>
          <app-material-select formControlName="housing_tip_otd_id"  *ngIf="form.value['advanced'].bool_tip_otd_id">
            <option [value]="''">-- Не выбрано --</option>
            <option *ngFor="let otd of type_otd" [value]="otd.dict_realty_type_otd_id">
              {{otd.dict_realty_type_otd_caption}}
            </option>
          </app-material-select>
        </div>
      </div>
      <div class="input__group__wrapper">
        <div class="input__group__wrapper__label">Описание отделки</div>
        <div class="mt-2">
          <app-material-input-checkbox formControlName="bool_description_finish">
            Поле из корпуса
          </app-material-input-checkbox>
        </div>
        <div>
          <app-material-textarea formControlName="flat_description_finish"
                              *ngIf="!form.value['advanced'].bool_description_finish"
                              [placeholder]="'Описание отделки'">
          </app-material-textarea>
          <app-material-textarea formControlName="housing_description_finish"
                              *ngIf="form.value['advanced'].bool_description_finish"
                              [placeholder]="'Описание отделки'">
          </app-material-textarea>
        </div>
      </div>

      <div class="input__group__wrapper">
        <div class="input__group__wrapper__label">Условия выплат субагентам</div>
        <div class="mt-2">
          <app-material-input-checkbox formControlName="bool_pay_conditions">
            Поле из корпуса
          </app-material-input-checkbox>
        </div>
        <div>
          <app-material-textarea [rows]="5" formControlName="flat_subagent_pay_conditions"
                                 *ngIf="!form.value['advanced'].bool_pay_conditions"
                                 [placeholder]="'Условия выплат субагентам'">
          </app-material-textarea>
          <app-material-textarea [rows]="5" formControlName="housing_subagent_pay_conditions"
                                 *ngIf="form.value['advanced'].bool_pay_conditions"
                                 [placeholder]="'Условия выплат субагентам'">
          </app-material-textarea>
        </div>
      </div>
      <div class="input__group__wrapper mt-3">
        <div class="input__group__wrapper__label">Условия бронирования</div>
        <div class="mt-2">
          <app-material-input-checkbox formControlName="bool_br_conditions">
            Поле из корпуса
          </app-material-input-checkbox>
        </div>
        <div>
          <app-material-textarea [rows]="6" formControlName="flat_subagent_br_conditions"
                                 *ngIf="!form.value['advanced'].bool_br_conditions"
                                 [placeholder]="'Условия бронирования'">
          </app-material-textarea>
          <app-material-textarea [rows]="6" formControlName="housing_subagent_br_conditions"
                                 *ngIf="form.value['advanced'].bool_br_conditions"
                                 [placeholder]="'Условия бронирования'">
          </app-material-textarea>
        </div>
      </div>
      <div class="input__group__wrapper mt-3">
        <div class="input__group__wrapper__label">Стоимость оформления</div>
        <div class="mt-2">
          <app-material-input-checkbox formControlName="bool_oform_price">
            Поле из корпуса
          </app-material-input-checkbox>
        </div>
        <div>
          <app-material-input formControlName="flat_subagent_oform_price"
                              *ngIf="!form.value['advanced'].bool_oform_price"
                              [placeholder]="'Стоимость оформления'">
          </app-material-input>
          <app-material-input formControlName="housing_subagent_oform_price"
                              *ngIf="form.value['advanced'].bool_oform_price"
                              [placeholder]="'Стоимость оформления'">
          </app-material-input>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <app-material-input-checkbox formControlName="flat_is_duplex">
        Двухуровневая
      </app-material-input-checkbox>
      <app-material-input-checkbox formControlName="flat_is_apartment">
        Апартаменты
      </app-material-input-checkbox>
      <app-material-input-checkbox formControlName="flat_is_mansarda">
        Мансардный этаж
      </app-material-input-checkbox>
      <app-material-input-checkbox formControlName="flat_is_studio">
        Квартира-студия
      </app-material-input-checkbox>
      <app-material-input-checkbox formControlName="flat_add_export">
        Рекламирование
      </app-material-input-checkbox>
      <!-- // -->
      <div class="input__group__wrapper">
        <div class="input__group__wrapper__label">Количество раздельных санузлов</div>
        <div class="input__group">
          <app-material-input
            formControlName="flat_separate_wcs_count"
            [placeholder]="'Количество раздельных санузлов'">
          </app-material-input>
        </div>
      </div>
      <!-- // -->
      <div class="input__group__wrapper">
        <div class="input__group__wrapper__label">Количество совмещенных санузлов</div>
        <div class="input__group">
          <app-material-input
            formControlName="flat_combined_wcs_count"
            [placeholder]="'Количество совмещенных санузлов'">
          </app-material-input>
        </div>
      </div>
    </div>
  </div>
</form>
