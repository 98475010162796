import {AfterContentInit, Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {HttpClient} from '../../../services/http.service';
import { debounceTime } from 'rxjs/operators';

declare const jQuery: any;

@Directive({
  selector: '[appMaterialInputAutocompleteCustom]'
})
export class MaterialInputAutocompleteCustomDirective implements AfterContentInit {

  @Input('appMaterialInputAutocompleteCustom') private appMaterialInputAutocompleteCustom = '';
  @Input('nameValue') private nameValue = '';
  @Input('nameLabel') private nameLabel = '';
  @Output() public autocompleteChange: EventEmitter<{}> = new EventEmitter();

  constructor(private elementRef: ElementRef, private http: HttpClient) { }

  ngAfterContentInit() {
    jQuery(this.elementRef.nativeElement).autocomplete({
      appendTo: this.elementRef.nativeElement.parentNode,
      select: (ux, obj) => {
        this.autocompleteChange.emit({'value': obj.item.value, 'label': obj.item.label});
      },
      focus: (ux, obj) => {
        // this.autocompleteChange.emit({'value': obj.item.value, 'label': obj.item.label});
      },
      source: (request, response) => {
        const data = {'value': jQuery(this.elementRef.nativeElement).val(), 'limit': 10};
        this.http.post(this.appMaterialInputAutocompleteCustom, data)
          .pipe(debounceTime(500))
          .subscribe((r: Array<any>) => {
            response(r.map((x) => {
              return {
                'label': x[this.nameLabel],
                'value': x[this.nameValue],
              };
            }));
          });
      }
    });
  }

}
