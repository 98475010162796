<ng-template #prices_updating>
  <i class="fa fa-spinner fa-spin"></i> пожалуйста, подождите...
</ng-template>
<div class="import-edit">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <div class="row no-gutters gutters-4 align-items-center">
      <div class="col-12 col-sm-6 col-md-5">
        <app-material-input formControlName="caption" [placeholder]="'https://site.ru/'" [require]="true"
                            [cssClass]="'c-pointer'">
          Название фида
        </app-material-input>
      </div>
      <div class="col-6 col-sm-3 col-md-5">
        <div class="d-flex align-items-end">
          <div class="w-100">
            <app-material-input formControlName="url" [placeholder]="'https://site.ru/'" [require]="true"
                                [cssClass]="'c-pointer'">
              URL фида
            </app-material-input>
          </div>
          <button class="button button_sm btn-imp-analyze" type="button" (click)="analyzeShown = true">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>
      <div class="col-6 col-sm-3 col-md-2">
        <app-material-input formControlName="specification_caption" [placeholder]="'Формат'" [require]="true">
          Формат
        </app-material-input>
      </div>
    </div>
    <div *ngIf="now_in_update" class="mt-3">
      <i class="fa fa-spin fa-spinner mr-2"></i>
      <span class="text-normal">Фид обрабатывается. Это может занять некоторое время.</span>
    </div>
    <div [class.d-none]="now_in_update" class="mt-3">
      <div class="row no-gutters justify-content-between">
        <div class="col-auto">
          <ng-container
            *ngIf="date_first"
          >
            Фид распознан в первый раз {{date_first}}<ng-container
              *ngIf="date_last"
            >, обновлен в {{date_last}}</ng-container>.
          </ng-container>
          <a class="mx-4" href="javascript: void(0)" (click)="onRefresh()">Обновить вручную</a>
        </div>
        <div class="col-auto">
          <a *ngIf="feed.counts && feed.counts.null_jumpers > 0" [href]="feed.null_jumpers_url" target="_blank">
            Несвязанные объекты в Excel ({{feed.counts.null_jumpers}})
          </a>
        </div>
      </div>
      <div class="mt-4">
        <ul class="navigation__tabs" role="tablist">
          <li class="navigation__tab">
            <a class="navigation__tab__link" [routerLink]="['/import/edit', feed_id]"
               routerLinkActive="navigation__tab__link_active" [routerLinkActiveOptions]="{exact: true}">
              Общие
              <span class="navigation__tab__link_active__slide"></span>
            </a>
          </li>
          <li class="navigation__tab">
            <a class="navigation__tab__link" [routerLink]="['/import/edit', feed_id, 'housing']"
               routerLinkActive="navigation__tab__link_active">
              Проекты
              <span class="navigation__tab__link_active__slide"></span>
            </a>
          </li>
          <li class="navigation__tab">
            <a class="navigation__tab__link" [routerLink]="['/import/edit', feed_id, 'realization']"
               routerLinkActive="navigation__tab__link_active">
              % Квартиры
              <span class="navigation__tab__link_active__slide"></span>
            </a>
          </li>
          <li class="navigation__tab">
            <a class="navigation__tab__link" [routerLink]="['/import/edit', feed_id, 'commerce']"
               routerLinkActive="navigation__tab__link_active">
              % Коммерция
              <span class="navigation__tab__link_active__slide"></span>
            </a>
          </li>
          <li class="navigation__tab">
            <a class="navigation__tab__link" [routerLink]="['/import/edit', feed_id, 'confirm']"
               routerLinkActive="navigation__tab__link_active">
              Данные <ng-container *ngIf="modifications">(ожидают {{modifications}} шт.)</ng-container>
              <span class="navigation__tab__link_active__slide"></span>
            </a>
          </li>
          <li class="navigation__tab">
            <a class="navigation__tab__link" [routerLink]="['/import/edit', feed_id, 'history']"
               routerLinkActive="navigation__tab__link_active">
              История запусков <ng-container *ngIf="rejects">({{rejects}})</ng-container>
              <span class="navigation__tab__link_active__slide"></span>
            </a>
          </li>
        </ul>
        <div class="tab-pane m-t-15">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </form>
</div>

<app-modal
  [closeOutside]="false"
  [caption]="'Анализ фида'"
  *ngIf="analyzeShown === true"
  (onClosed)="analyzeShown = false"
  [size]="'xl'"
>
  <app-import-analyze
    [feedUrl]="formGroup.get('url').value"
  />
  <button (click)="analyzeShown = false" class="button button_sub" type="button">Закрыть</button>
</app-modal>
