import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {HttpClient} from '../../../services/http.service';
import {NotificationService} from '../../../services/notification.service';

@Component({
  templateUrl: './create-entity.component.html',
  styleUrls: [
    './create-entity.component.scss'
  ]
})
export class CreateEntityAltComponent implements OnInit {

  @Output('onCreate') public onCreate: EventEmitter<any> = new EventEmitter<any>();

  public _parent: any;

  public title = 'Создание юридического лица';

  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private http: HttpClient,
              private notify: NotificationService) { }

  ngOnInit() {
    this.form = this.fb.group({});
  }

  saveData() {
    this.http.post('Organisation.organisation_create_full', {'data': this.form.value})
      .subscribe(
        response => this.onCreate.emit(response),
        error => this.notify.notifyError('Ошибка', 'Этот номер уже существует', 5000)
      );
  }

}
