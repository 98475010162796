import {Component, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../services/notification.service';
import {Subscription} from 'rxjs';
import {JsonClientService} from '../../../../shared/json-client';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticateService} from '../../../../services/authenticate.service';
import {BreadcrumbsService} from '../../../../shared/breadcrumbs/services';
import {GoBackButtonService} from '../../../../services/go-back-button.service';

@Component({
  selector: 'app-reserv-lead-page-create',
  templateUrl: './reserv-lead-page-create.component.html',
  styleUrls: ['./reserv-lead-page-create.component.scss']
})
export class ReservLeadPageCreateComponent implements OnInit, OnDestroy {


  public form: UntypedFormGroup;
  public stage_forms: any;
  public leadSources: any;

  private showStageSubscription: Subscription;
  private stageSubscription: Subscription;

  public showStage = false;
  public descriptionStage = '';

  constructor(private http: JsonClientService, private fb: UntypedFormBuilder, private b: BreadcrumbsService,
              private notify: NotificationService, private router: Router, private goBack: GoBackButtonService,
              private auth: AuthenticateService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.leadSources = this.route.snapshot.data['leadSources'];
    this.form = this.fb.group({
      'lastName': [''],
      'firstName': ['', Validators.required],
      'middleName': [''],
      'email': [''],
      'phone': ['', Validators.required],
      'notice': [''],
      'realty_id': ['', Validators.required],
      'sale_eat_id': [''],
      'source_id': ['2'],
      'worker_id': [this.auth.user.id],
      'worker_caption': [this.auth.user.fio + ' ' + this.auth.user.ucaption],
      'stage_id': ['', Validators.required],
      'stage_caption': [''],
      'sale_subagent_user_id': ['', Validators.required],
      'next_contact_date': ['', Validators.required],
      'next_contact_time': ['', [Validators.pattern(/^(0?\d:[0-5]\d|1\d:[0-5]\d|2[0-3]:[0-5]\d|)$/)]],
      'next_contact_comment': [''],
      'sale_lead_transfer': ['', Validators.required],
    });
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/attraction/lead-info/today', caption: 'Лиды по бронированию'},
    ]);
    this.goBack.header = '<h1>Создание лида</h1>';
    this.showStageSubscription = this.form.get('realty_id').valueChanges.subscribe(val => {
      if (val) {
        this.http.post('Dict.bp_sale_stages',  {'realty_id': parseInt(val, 10)}).then(
          response => {
            this.stage_forms = response;
            this.showStage = true;
            this.form.get('stage_id').setValue(this.stage_forms[0].id);
          },
          error => {
            this.showStage = false;
            this.form.get('stage_id').setValue('');
          }
        );

      } else {
        this.stage_forms = {};
        this.showStage = true;
      }
    });

    this.stageSubscription = this.form.get('stage_id').valueChanges.subscribe(val => {

      if (val) {
        const result = this.stage_forms.filter(function( obj ) {
          return obj.id === val;
        });
        this.descriptionStage = result[0].description;
        this.form.patchValue({
          'next_contact_date': result[0].next_contact_date,
          'next_contact_time': result[0].next_contact_time,
        });
      } else {
        this.descriptionStage = '';
      }

    });

  }

  ngOnDestroy() {
    if (this.showStageSubscription) {
      this.showStageSubscription.unsubscribe();
    }
    if (this.stageSubscription) {
      this.stageSubscription.unsubscribe();
    }
  }

  onSubmit() {
    const data = {
      'data': {
        'worker_id': this.form.value.worker_id,
        'lastName': this.form.value.lastName,
        'firstName': this.form.value.firstName,
        'middleName': this.form.value.middleName,
        'source_id': this.form.value.source_id,
        'email': this.form.value.email,
        'phone': this.form.value.phone,
        'notice': this.form.value.notice,
        'sale_lead_transfer': this.form.value.sale_lead_transfer === 'true' ? true : false,
      },
      'realty_id': this.form.value.realty_id,
      'sale_eat_id': this.form.value.sale_eat_id,
      'stage_id': this.form.value.stage_id,
      'next_contact_date': this.form.value.next_contact_date,
      'next_contact_time': this.form.value.next_contact_time,
      'next_contact_comment': this.form.value.next_contact_comment,
      'sale_subagent_user_id': this.form.value.sale_subagent_user_id,
    };

    this.http.post('Bp.lead_sale_create', data).then(
      response => {
        const fio = this.form.value.lastName + ' ' + this.form.value.firstName + ' ' + this.form.value.middleName;
        this.notify.notifySuccess('Лид успешно создан', fio,  5000);
        this.router.navigate(['/reservation', 'lead-info', response]);
      },
      error => { }
    );
  }
}
