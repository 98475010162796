<ng-container *ngIf="header_info">
  <p class="d-inline-block mr-2r" *ngFor="let el of header_info">
    {{el.caption}}: <b>{{el.count}}</b>
  </p>
</ng-container>
<div class="material-datatable-wrapper">
  <form [formGroup]="form" novalidate (submit)="formSubmit()">
    <div class="row align-bottom">
      <div class="col-md-3">
        <app-material-input-datepicker formControlName="date_from">Дата начала</app-material-input-datepicker>
      </div>
      <div class="col-md-3">
        <app-material-input-datepicker formControlName="date_end">Дата окончания</app-material-input-datepicker>
      </div>
      <div class="col-md-3">
        <div class="form__group">
          <button type="submit" [disabled]="!form.valid" class="button">Искать</button>
        </div>
      </div>
    </div>
    <app-material-input formControlName="search_string">Общий поиск</app-material-input>
  </form>
  <div class="table-responsive">
    <table class="table datatable" [ngClass]="cssClass">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>

