import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[pageFor]'
})
export class PaginatorDirective {

  @Input('pageForOf') set pageForOf([loop, queryParams]) {

    this.viewContainer.clear();

    if (loop) {
      for (let i = 0; i < loop.length; i++) {
        this.viewContainer.createEmbeddedView(this.templateRef, {
          $implicit: loop[i],
          queryParams: Object.assign({...queryParams}, {
            'page': loop[i]['caption'],
          }),
        });
      }
    }
  }

  constructor( private templateRef: TemplateRef<any>,
               private viewContainer: ViewContainerRef) {

  }

}
