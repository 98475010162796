<div class="form__group" [ngClass]="cssClass">
  <div class="form__label" [ngClass]="labelClass" [class.form__label_require]="require && !disabled" [class.form__label_error]="error">
    <ng-content></ng-content>
  </div>
  <div class="form__group">
    <div class="btn-group button-group">
      <a  *ngFor="let radio of data | keys" class="button button_grey" [class.button_active]="radio.key == current_value"
         [class.button_disabled]="disabled" (click)="onChanges(radio.key)">
        {{radio.value}}
        <input type="radio" [value]="radio.key" [checked]="radio.key == current_value" [disabled]="disabled">
      </a>
    </div>
  </div>
</div>
