<ng-container *ngIf="header_info">
  <p class="d-inline-block mr-2r" *ngFor="let el of header_info">
    {{el.caption}}: <b>{{el.count}}</b>
  </p>
</ng-container>

<div class="control-layouts">
  <div class="material-datatable-wrapper">
    <app-datatable-headline
      [global_search_string]="global_search_string"
      [items_per_page_vars]="items_per_page_vars"
      (items_per_page_change)="itemPerPageChange($event)"
      (global_search_change)="onGlobalSearch($event)"
      [templateRight]="templateRight">
    </app-datatable-headline>
    <div class="table-responsive">
      <table class="table datatable" [ngClass]="cssClass">
        <thead app-data-table-head
               [sort]="sort"
               [columns]="columns"
               (sort_emmit)="onSortChange($event)">
        </thead>
        <tbody app-data-table-body
               (rowClick)="rowClick.emit($event)"
               [loading]="true"
               [columns]="columns">
        </tbody>
      </table>
    </div>
    <app-datatable-pagination
      [current_page]="current_page"
      (change_page)="changePage($event)">
    </app-datatable-pagination>
  </div>
</div>


<ng-template #complex_captionHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="complex_caption" [cssClass]="'mb-0'" [placeholder]="'Комплекс'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #housing_captionHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="housing_caption" [cssClass]="'mb-0'" [placeholder]="'Корпус'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #section_captionHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="section_caption" [cssClass]="'mb-0'" [placeholder]="'Секция'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #activeHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-select formControlName="is_published" [cssClass]="'mb-0'">
      <option [value]="''">Все квартиры</option>
      <option [value]="'true'">Опубликованные</option>
      <option [value]="'false'">Не опубликованные</option>
    </app-material-select>
  </form>
</ng-template>

<ng-template #statusHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-select formControlName="status_id" [cssClass]="'mb-0'">
      <option [value]="''">Статус</option>
      <option [value]="item.id" *ngFor="let item of flat_statuses">{{item.caption}}</option>
    </app-material-select>
  </form>
</ng-template>


<ng-template #flatTmpl let-row="row" let-ind="row_index">
  <div [ngClass]="{'activeBox': goBack.activeHash === 'flat-caption-' + ind}">
    <a href="/housing2/flat/{{row.id}}"
       (click)="goBack.newLink('/housing2/flat/' + row.id, 'flat-caption-' + ind, $event)">
      {{row.caption}}
    </a>
  </div>
</ng-template>

<ng-template #viewTmpl let-row="row">
  <ng-container [ngSwitch]="row['is_published']">
    <ng-container *ngSwitchCase="true">Опубликованный</ng-container>
    <ng-container *ngSwitchCase="false">Не опубликованный</ng-container>
  </ng-container>
</ng-template>

<ng-template #errorTmpl let-row="row">
  <div class="mb-1" *ngFor="let item of row.problems">
    <a [routerLink]="['/housing/house/', row['housing_id']]">
      <i class="fa fa-warning c-yellow2"></i>&nbsp;<span [innerHtml]="getProblem(item)"></span>
    </a>
  </div>
</ng-template>
