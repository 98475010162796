///<reference path="_crm/cian-yandex/cian-yandex.module.ts"/>
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppCrmComponent } from './app-crm.component';
import { AppCrmRoutingModule } from './app-crm-routing.module';
import { CrmDashboardModule } from './_crm/dashboard/crm-dashboard.module';
import { SidebarComponent } from './_crm/core/sidebar/sidebar.component';
import { NavbarComponent } from './_crm/core/navbar/navbar.component';
import { CoreModule } from './_crm/core/core.module';
import { SingleWindowModule } from './_crm/single-window/single-window.module';
import { NewlyModule } from './_crm/newly/newly.module';
import { CrmCalendarModule } from './_crm/calendar/crm-calendar.module';
import { AuthenticateModule } from './shared/authenticate/authenticate.module';
import { AuthGuard } from './guards/auth.guard';
import { AuthenticateService } from './services/authenticate.service';
import { HttpClient } from './services/http.service';
import { ConfigService } from './services/configuration.service';
import { HttpSessionServiceLS } from './services/http-session-ls.service';
import { HttpSessionService } from './services/http-session.service';
import { NotificationService } from './services/notification.service';
import { LockerService } from './services/locker.service';
import { LockerComponent } from './shared/authenticate/locker.component';
import { NotificationsComponent } from './_crm/core/notifications/notifications.component';
import { HistoryComponent } from './_crm/core/history/history.component';
import { NotificationAlertComponent } from './shared/notification-alert/notification-alert.component';
import { NotificationAlertModule } from './shared/notification-alert/notification-alert.module';
import { DictionaryResolveModule } from './resolvers/dictionary-resolve.module';
import { SingleWindowLayoutComponent } from './_crm/single-window/single-window-layout/single-window-layout.component';
import { DictionariesService } from './services/dictionaries.service';
import { ChainListResolve } from './_crm/newly/shared/chain-list.resolve';
import { PhysicalModule } from './_crm/physical/physical.module';
import { EntitiesModule } from './_crm/entity/entity.module';
import { CalendarModule } from './shared/calendar/calendar.module';
import { OnlineModule } from './_crm/online/online.module';
import { SettingsCrmModule } from './shared/settings/settings-crm.module';
import { SettingsModule } from './_front/settings/settings.module';
import { TemplateService } from './services/template.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NewsModule } from './_crm/news/news.module';
import { StatisticModule } from './_crm/statistic/statistic.module';
import { ReserveModule } from './_crm/reserve/reserve.module';
import { ImportModule } from './shared/import/import.module';
import { ControlModule } from './_crm/control/control.module';
import { AttractionModule } from './_crm/attraction/attraction.module';
import { FlatModule } from './shared/housing/shared/flat/flat.module';
import { ServerDownComponent } from './shared/authenticate/server-down.component';
import { NavigationLoaderComponent } from './shared/navigation-loader/navigation-loader.component';
import {ImportXlsxModule} from './_crm/import-xlsx/import-xlsx.module';
import {AppealsModule} from './_crm/appeals';
import {SubagentsActionsModule} from './_crm/subagents-actions/subagents-actions.module';
import {ReferenceModule} from './_crm/reference/reference.module';
import {SessionRestoreFactory, StyleRestoreFactory} from './shared/factories';
import {StyleControlService} from './services/style-control.service';

import {AnalyticModule} from './_crm/analytic';

import { HolidayListComponent } from './_crm/holiday/holiday-list.component';
import { HolidayModule } from './_crm/holiday/holiday.module';
import { StartSalesModule } from './_crm/start-sales/start-sales.module';

import {
  JsonErrorHandler401Interceptor,
  JsonErrorHandler303Interceptor,
  JsonSessionInterceptor,
  JsonAppVersionInterceptor,
  JsonClientService,
} from './shared/json-client';
import {GoBackButtonService} from './services/go-back-button.service';
import {ManagersModule} from './_crm/managers/managers.module';
import {LocalStorageService} from './services/storages/local_storage.service';
import {DeactivateService} from './shared/housing/shared/guards';

export function getBackrefNewly() {
  return '/housing/compare';
}
export function getBackrefNewlyStrC() {
  return '/housing/structural/complexes';
}
export function getBackrefNewlyStrH() {
  return '/housing/structural/korpuses';
}
export function getBackrefNewlyStrF() {
  return '/housing/structural/flats';
}
export function getBackrefPhysical() {
  return '/physical/list';
}
export function getBackrefEntity() {
  return '/entity/list';
}
export function getBackrefCalendar() {
  return '/calendar/list';
}
export function getBackrefLead() {
  return '/lead/list';
}

import {
  OutdateVersionComponent,
  OutdateVersionModule
} from './shared/outdate-version';

import {BreadcrumbsModule} from './shared/breadcrumbs';
import {NotFoundCrmComponent} from './errors/404-not-found-crm/not-found-crm.component';
import {HousingOrderModule} from './_crm/housing-order/housing-order.module';
import {TariffMapModule} from './_crm/tariff-map/tariff-map.module';
import {EstatetModule} from './_crm/estatet/estatet.module';
import {UserAgentService} from './services';
import {FancyboxService} from './shared/fancybox.service';
import {CianYandexModule} from './_crm/cian-yandex/cian-yandex.module';
import {RealtyShowLogsModule} from './_crm/realty-show-logs/realty-show-logs.module';
import {DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {NgxMaskDirective, provideNgxMask} from 'ngx-mask';
import {EmailExceptionModule} from './_crm/email-exсeption/email-exception.module';
import {TransferModule} from './_crm/transfer/transfer.module';
import {TransferLogModule} from './_crm/transfer-log/transfer-log.module';
import {ComplaintsDatatableCrmModule} from './shared/complaints-datatable/complaints-datatable-crm.module';
import {RegisterWizardModule} from './_front/register-wizard/register-wizard.module';
import {InstatekaModule} from './_crm/instateka/instateka.module';
import {EatComplexAssocModule} from './_crm/eat-complex-assoc/eat-complex-assoc.module';
import {MassFillingModule} from './_crm/mass-filling/mass-filling';
import {HousingControlModule} from './_crm/housing-control/housing.module';
import {BlankComponent} from './_front/blank/blank.component';
import {ContactsUnregModule} from './_crm/contacts-unreg/contacts-unreg.module';
import {EstCardModule} from './_crm/est-card/est-card.module';

@NgModule({ declarations: [
        AppCrmComponent,
        NotFoundCrmComponent,
        BlankComponent,
    ],
    bootstrap: [
        AppCrmComponent,
        SidebarComponent,
        NavbarComponent,
        LockerComponent,
        ServerDownComponent,
        NotificationsComponent,
        NotificationAlertComponent,
        HistoryComponent,
        SingleWindowLayoutComponent,
        NavigationLoaderComponent,
        OutdateVersionComponent,
    ], imports: [NgxMaskDirective,
        AnalyticModule,
        AppCrmRoutingModule,
        CrmDashboardModule,
        CrmCalendarModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        /* Common application module */
        NewlyModule,
        AuthenticateModule,
        SingleWindowModule,
        NotificationAlertModule,
        DictionaryResolveModule,
        PhysicalModule,
        EntitiesModule,
        CalendarModule,
        OnlineModule,
        SettingsModule,
        SettingsCrmModule,
        NewsModule,
        StatisticModule,
        ReserveModule,
        HousingOrderModule,
        ImportModule,
        ControlModule,
        AttractionModule,
        SubagentsActionsModule,
        FlatModule,
        ImportXlsxModule,
        AppealsModule,
        ReferenceModule,
        ManagersModule,
        OutdateVersionModule,
        BreadcrumbsModule,
        TariffMapModule,
        CianYandexModule,
        EstatetModule,
        RealtyShowLogsModule,
        EmailExceptionModule,
        TransferModule,
        TransferLogModule,
        ComplaintsDatatableCrmModule,
        InstatekaModule,
        EatComplexAssocModule,
        MassFillingModule,
        HousingControlModule,
        HolidayModule,
        StartSalesModule,
        ContactsUnregModule,
        EstCardModule], providers: [
        /* Common services */
        AuthenticateService,
        HttpClient,
        ConfigService,
        NotificationService,
        LockerService,
        DictionariesService,
        TemplateService,
        StyleControlService,
        JsonClientService,
        GoBackButtonService,
        LocalStorageService,
        UserAgentService,
        FancyboxService,
        // ~
        // -- filled form close warning
        DeactivateService,
        /* HttpSession injector */
        { provide: HttpSessionService, useExisting: HttpSessionServiceLS },
        { provide: HTTP_INTERCEPTORS, useClass: JsonSessionInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JsonErrorHandler303Interceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JsonErrorHandler401Interceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JsonAppVersionInterceptor, multi: true },
        HttpSessionServiceLS,
        /* Common guards */
        AuthGuard,
        /* Resolvers */
        ChainListResolve,
        /* Static backrefs */
        { provide: 'backref_newly', useValue: getBackrefNewly },
        { provide: 'backref_newly_struct_c', useValue: getBackrefNewlyStrC },
        { provide: 'backref_newly_struct_h', useValue: getBackrefNewlyStrH },
        { provide: 'backref_newly_struct_f', useValue: getBackrefNewlyStrF },
        { provide: 'backref_physical', useValue: getBackrefPhysical },
        { provide: 'backref_entity', useValue: getBackrefEntity },
        { provide: 'backref_calendar', useValue: getBackrefCalendar },
        { provide: 'backref_lead', useValue: getBackrefLead },
        { provide: APP_INITIALIZER, useFactory: SessionRestoreFactory,
            deps: [AuthenticateService], multi: true },
        { provide: APP_INITIALIZER, useFactory: StyleRestoreFactory,
            deps: [StyleControlService, AuthenticateService], multi: true },
        provideNgxMask(),
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppCrmModule {
}
