import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../../services/notification.service';
import {PurchTermTableService} from '../purch-term-table/purch-term-table.service';


@Component({
  selector: 'app-right-view-term',
  templateUrl: './right-view-term.component.html',
  styleUrls: [
    './right-view-term.component.scss'
  ]
})
export class RightViewTermComponent implements OnInit {

  @Input() public complexes_ids = [];
  @Output() public afterSubmit: EventEmitter<any> = new EventEmitter();

  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
              private s: PurchTermTableService,
              private notify: NotificationService) { }

  ngOnInit() {
    this.form = this.fb.group({
      'up_akcii': [''],
      'up_banki': [''],
      'up_rassroch': [''],
      'up_vzaimozach': [''],
    });
  }

  formSubmit() {
    const values = this.form.value;

    this.s.saveTerm(this.complexes_ids, values)
      .then(() => {
        this.afterSubmit.emit();
        this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
      });
  }
}
