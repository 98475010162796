import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {ConfigService} from '../../../services/configuration.service';


@Component({
  selector: 'app-preview-holiday',
  templateUrl: './holiday-preview.component.html',
  styleUrls: ['./holiday-preview.component.scss']
})
export class HolidayPreviewComponent implements OnInit {

  @Input() public dates_string = '';
  @Input() public image_icon: any;
  @Input() public upload_url = '';
  @Output() public marginLeft: EventEmitter<any> = new EventEmitter<any>();
  @Output() public marginTop: EventEmitter<any> = new EventEmitter<any>();
  @Input() public margin_left = 0;
  @Input() public margin_top = 0;
  public margin_top_style = 0;
  public styles: string;

  constructor(public config: ConfigService,
              private clipboard: Clipboard) {
  }

  ngOnInit() {
    this.upload_url = this.config.data['url_file_upload'] + this.image_icon.filepath;
    this.margin_top_style = this.margin_top;
    if (this.margin_top !== 0) {
      this.margin_top = this.margin_top * -1;
    }
  }
  editLeftValue(value) {
    this.margin_left = value.value;
    this.marginLeft.emit({'value': value});
  }

  editTopValue(value) {
    this.margin_top = value.value;
    this.margin_top_style = value.value * -1;
    this.marginTop.emit({'value': value});
  }
  copyStyles() {
    const stylesString = String(this.margin_left) + ';' + String(this.margin_top);
    this.clipboard.copy(stylesString);
  }
  insertStyles(text = null) {
    try {
      const arrayOfStrings = text.split(';');
      if (arrayOfStrings[0] && arrayOfStrings[1]) {
        this.editLeftValue({'value': parseFloat(arrayOfStrings[0])});
        this.editTopValue({'value': parseFloat(arrayOfStrings[1])});
      }
    } catch (Error) {
      return false;
    }
  }
}
