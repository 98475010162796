

<div class="row form_crm">
  <div class="col-md-6">
    <app-material-input [(ngModel)]="search_string">Поиск</app-material-input>
  </div>
  <div class="col-md-8">
    <app-analytic-filter  [base_url]="base_url"></app-analytic-filter>
  </div>
</div>

<div style="overflow-x: auto;">

  <table class="table rep__table">
    <thead>
    <tr>
      <th></th>
      <ng-container *ngFor="let el of head; index as i;" class="text-nowrap">
        <ng-container *ngIf="i">
          <th class="text-center">
            <a [routerLink]="filter_url"
               [queryParams]="{'sort_field': i,
                               'sort_direction': next_sort_direction}">
              <span [ngClass]="{'font-weight-bold': '<b>Итого</b>' === el}" [innerHtml]="el"></span>
              <i class="fa fa-sort"></i>
            </a>
          </th>
        </ng-container>
        <ng-container *ngIf="!i">
          <th>{{el}} </th>
        </ng-container>
      </ng-container>
    </tr>
    </thead>
    <tbody>
    <tr class="r-w-r">
      <td>
        <span class="rep__cell_centered-orange">{{body.length}}</span>
      </td>
      <ng-container *ngFor="let el of total; index as i;">
        <td>
          <span [ngClass]="{
            'rep__cell': !i,
            'rep__cell_centered-orange': i}"
                [innerHtml]="el">
          </span>
        </td>
      </ng-container>
    </tr>
    <tr class="r-w-r" *ngFor="let row of body; index as i;">
      <td>
        <span class="rep__cell text-center">{{i+1}}</span>
      </td>
      <td *ngFor="let td of row; index as i;">
        <span [ngClass]="{
          'rep__cell': !i,
          'rep__cell_centered-orange': i === 1,
          'rep__cell_centered': i && i !== 1 && '0' === td,
          'rep__cell_centered-green': i && i !== 1 && '0' !== td}">
            {{td}}
        </span>
      </td>
    </tr>
    </tbody>
  </table>

</div>
