import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {JsonClientService} from '../../shared/json-client';


@Injectable()
export class CrmDashboardResolve {

  constructor(private http: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.http.post('Session.check');
  }

}
