<app-tab-wrapper>
  <app-tab-element [caption]="'Основное'">
    <app-alt-physical-common></app-alt-physical-common>
  </app-tab-element>
  <app-tab-element [caption]="'Дополнительно'">
    <app-alt-physical-advanced></app-alt-physical-advanced>
  </app-tab-element>
  <app-tab-element [caption]="'Организации'">
    <app-alt-physical-organisations></app-alt-physical-organisations>
  </app-tab-element>
</app-tab-wrapper>
<br>
<button class="button" (click)="saveData()" [disabled]="!form.valid">Сохранить</button>
