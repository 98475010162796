
<div class="wrapper">
  <p><button (click)="open(null)" class="button">Создать шаблон</button></p>
  <div class="invites__wrapper">
    <app-material-table [api_action]="'Calendar.dt_invite_templates'" [columns]="columns" #table>
    </app-material-table>
  </div>

  <ng-template let-row="row" #rowBtn>
    <button class="button" (click)="open(row.calendarinvitetemplate_id)">Подробнее</button>
  </ng-template>

</div>
