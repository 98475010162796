import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {HttpClient} from '../../../services/http.service';

@Injectable()
export class CalendarEditResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const event_id = route.params['id'];
    return this.http.post('Calendar.get_event_new', {'event_id': event_id});
  }
}
