import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient} from '../../../services/http.service';

@Injectable()
export class HousingComplexAboutResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Housing_complex.get_complex_data', {'complex_id': +route.params['id'], 'tab': 'common'});
  }
}

@Injectable()
export class HousingComplexCommonResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Housing_complex.get_complex_data', {'complex_id': +route.parent.params['id'], 'tab': 'common'});
  }
}

@Injectable()
export class HousingComplexPresentationResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Housing_complex.get_complex_data', {'complex_id': +route.parent.params['id'], 'tab': 'presentation'});
  }
}

@Injectable()
export class HousingComplexPhotoResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Housing_complex.get_complex_data', {'complex_id': +route.parent.params['id'], 'tab': 'photo'});
  }
}

@Injectable()
export class HousingComplexHousingsResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Housing_complex.get_complex_data', {'complex_id': +route.parent.params['id'], 'tab': 'housings'});
  }
}
