import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, ResolveEnd, Router } from '@angular/router';
import { HistoryService } from './shared/history.service';
import { SingleWindowService } from './shared/single-window.service';
import { Subscription } from 'rxjs';
import { SingleWindowChildComponent } from '../interfaces/single-window-child.component';
import { filter } from 'rxjs/operators';
import {SingleWindowChild} from '../interfaces/single-window-child';


@Component({
  templateUrl: './single-window.component.html',
  styleUrls: ['./single-window.component.scss']
})
export class SingleWindowComponent implements OnInit, OnDestroy {

  public backref: string = null;
  public favorite = false;
  public routerSubscribtion: Subscription;

  public header: string = null;

  private childSubscription: Subscription;
  private _childComponent: SingleWindowChild;

  public get childComponent(): SingleWindowChild {
    return this._childComponent;
  }

  public set childComponent(value: SingleWindowChild) {
    this._childComponent = value;

    if (value !== null && this.childComponent.url) {
      this.historyService.append(this.childComponent);
      this.resetDefault();
    }
  }

  constructor(private activatedRoute: ActivatedRoute, private historyService: HistoryService,
              private singleWindowService: SingleWindowService, private router: Router) {
    /*
     * Register new child
     */
    this.singleWindowService.registerHost(this);
    this.childSubscription = this.singleWindowService.childEmitter
      .subscribe(x => this.childComponent = x);
    this.routerSubscribtion = this.router.events.pipe(filter(x => x instanceof ResolveEnd)).subscribe(x => {
      if (x['state'] && x['state'].root.firstChild.component !== SingleWindowComponent) {
        this.singleWindowService.backref = null;
      }
    });
  }

  resetDefault() {
    this.favorite = this.historyService.isFavorites(this.childComponent.uuid);
  }

  markFavorite() {
    this.favorite = !this.favorite;

    this.historyService.setFavorite(this.childComponent.uuid, this.favorite);
  }

  ngOnInit() {
    this.backref = this.singleWindowService.backref
      ? this.singleWindowService.backref : this.activatedRoute.firstChild.snapshot.data['backref'];
  }

  onClose() {
    this.historyService.purgeHistory();
    this.singleWindowService.backref = null;
    this.router.navigateByUrl(this.backref);
  }

  ngOnDestroy() {
    this.singleWindowService.unregisterHost();
    if (this.routerSubscribtion) {
      this.routerSubscribtion.unsubscribe();
    }
  }

}
