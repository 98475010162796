import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'realty_constraint_filter'
})

@Injectable()
export class SettingsConstraintsElementPipe implements PipeTransform {

  transform(value: any, search_string: string, allow_all: boolean, allow_frame_only: boolean, allow_xml_only: boolean) {

    const sort = (a, b) => {
      if (a.realty_caption < b.realty_caption) {
        return -1;
      }

      if (a.realty_caption > b.realty_caption) {
        return 1;
      }

      return 0;
    };

    if ((search_string && search_string.length) || allow_all || allow_frame_only || allow_xml_only) {
      return value.sort(sort).filter(x => {
        if (allow_all && !x['allowed_all']) {
          return false;
        }
        if (allow_frame_only && !x['allowed_iframe']) {
          return false;
        }
        if (allow_xml_only && !x['allowed_xml']) {
          return false;
        }

        if (search_string && search_string.length) {
          return -1 !== x['realty_caption'].toLowerCase().indexOf(search_string.toLowerCase()) ||
            -1 !== (x['manufacturer'] || '').toLowerCase().indexOf(search_string.toLowerCase()) ||
            -1 !== (x['subway_caption'] || '').toLowerCase().indexOf(search_string.toLowerCase());
        }
        return true;
      });
    }

    return value.sort(sort);
  }

}
