import { Component, OnDestroy, OnInit } from '@angular/core';
import { HistoryService } from '../../single-window/shared/history.service';
import { Subscription } from 'rxjs';
import { SLIDE_LEFT_ANIMATION } from '../../../animations/slide-left.animation';
import { Router } from '@angular/router';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  animations: [SLIDE_LEFT_ANIMATION]
})
export class HistoryComponent implements OnInit, OnDestroy {

  public opened = false;
  public localEvent = false;
  public historyElements;

  private subscription: Subscription = null;
  private subscription2: Subscription = null;

  constructor(private history: HistoryService, private router: Router) {
  }

  ngOnInit() {
    this.subscription = this.history.global_history.subscribe(x => this.historyElements = x);
    this.subscription2 = this.history.status_emitter.subscribe(x => {
      this.localEvent = true;
      this.opened = x;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.subscription2) {
      this.subscription.unsubscribe();
    }
  }

  clickOutside() {
    if (this.opened && !this.localEvent) {
      this.history.toggleOpened();
    }
    this.localEvent = false;
  }

  routerLink(link: any) {
    this.router.navigateByUrl(link);
    this.history.toggleOpened();
  }

}
