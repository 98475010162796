import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import { SingleWindowChild } from './single-window-child';
import {SingleWindowService} from '../single-window/shared/single-window.service';
import {Subscription} from 'rxjs';

/*
 * All proxy's children components MUST BE EXTENDED of this component class
 */
@Injectable()
export abstract class SingleWindowChildComponent implements SingleWindowChild, OnDestroy {

  private routeSubscription: Subscription;

  public url: string;
  public uuid: string;

  constructor(public activatedRoute: ActivatedRoute, public router: Router,
              public singleWindowService: SingleWindowService) {
    /*
     * Register child component
     * Unregister child component and register it again to get a new component hash
     */
    this.uuid = this.singleWindowService.registerChild(this);

    this.routeSubscription = this.activatedRoute.paramMap.subscribe((params) => {
      this.singleWindowService.unregisterChild();
      this.uuid = this.singleWindowService.registerChild(this);
    });
  }

  ngOnDestroy() {
    this.singleWindowService.unregisterChild();
    this.routeSubscription.unsubscribe();
  }

}
