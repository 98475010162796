<div class="row mt-4 mb-2" [formGroup]="form">
  <div class="col-6">
    Всего: <b>{{count}}</b>
  </div>
</div>
<div class="material-datatable-wrapper">
  <div class="table-responsive">
    <table class="table">
      <tr>
        <th *ngFor="let el of head" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
          <div [formGroup]="form">
            <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
              <div class="col table__input">
                <ng-container *ngIf="el.input">
                  <app-material-input
                    [placeholder] = "el.caption"
                    [formControlName] = "el.name"
                    [cssClass]="'mb-0'">
                  </app-material-input>
                </ng-container>
                <ng-container *ngIf="el.select">
                  <app-material-select
                    [formControlName]="el.name"
                    [cssClass]="'mb-0'">
                    <option [value]="''">{{el.caption}}</option>
                    <option *ngFor="let opt of el.select" [value]="opt.id">{{opt.caption}}</option>
                  </app-material-select>
                </ng-container>
                <ng-container *ngIf="!el.input && !el.select">
                  {{el.caption}}
                </ng-container>
              </div>
              <div class="col-auto" *ngIf="el.sort">
                <a href="javascript:void(0)" (click)="sortChange(el.name)">
                  <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                      'icon_new-sort-down': 'asc' == sort[el.name],
                                      'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                </a>
              </div>
            </div>
          </div>
        </th>
      </tr>
      <!-- // -->
      <tr *ngFor="let row of rows; let row_index = index">
        <td>
          <a [routerLink]="['/physical/edit', row['physicals_id'], 'common']">{{row['physicals_fio']}}</a>
        </td>
        <td>
          {{row['physicals_create_date']}}
        </td>
        <td>
          {{row['physicals_email']}}
        </td>
        <td>
          {{row['phones_phone']}}
        </td>
        <td>
          {{row['event_date']}}
        </td>
        <td>
          <a href="/calendar/edit/{{row['event_id']}}"
             (click)="goBack.newLink('/calendar/edit/' + row['event_id'], 'event_caption-' + row_index, $event)">
            {{row['event_caption']}}
          </a>
        </td>
        <td>
          <p *ngIf="row['status'] === true">Обработано</p>
          <p *ngIf="row['status'] === false">Не обработано</p>
        </td>
        <td>
          <a *ngIf="row['status'] === false" [routerLink]="['/physical/edit', row['physicals_id'], 'common']">Добавить в систему</a>
        </td>
      </tr>
    </table>
  </div>
</div>

<div class="alt-pagination">
  <app-paginator
    [queryParams]="filter_columns"
    [itemsPerPage]="50"
    [itemCount]="count"
    [currentPage]="current_page"
    [baseUrl]="'/transfer-log'">
  </app-paginator>
</div>
