import {Component, Input} from '@angular/core';

@Component({
  templateUrl: './online-compare-view-staff.component.html',
  styleUrls: ['./online-compare-view-staff.component.scss']
})
export class OnlineCompareViewStaffComponent {

  @Input('staff') public staff;
  @Input('is_offline') public is_offline = true;

}
