import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

import {AuthGuard} from '../../guards/auth.guard';

import * as c from './components';
import * as r from './resolvers';
import {DictionaryLeadSourcesResolve} from '../../resolvers/dictionary/dictionary-lead-sources.resolve';

const routes: Routes = [
  {path: 'analytic', component: c.AnalyticWrapperComponent,
    canActivate: [AuthGuard], children: [

      {path: 'index', component: c.AnalyticIndexComponent,
        resolve: {
          'reports': r.IndexResolve,
        }
      },

      {path: 'report-common', component: c.ReportCompareComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          'data': r.ReportCompareResolve,
          'leadSources': DictionaryLeadSourcesResolve,
        }
      },

      {path: 'report-common/:start/:stop', component: c.ReportCompareComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          'data': r.ReportCompareResolve,
          'leadSources': DictionaryLeadSourcesResolve,
        }
      },

      {path: 'report/' +
          ':type/:is_realty', component: c.ReportCommonComponent,
        resolve: {'data': r.ReportCommonResolve}
      },

      {path: 'report/:type/:is_realty/:start/:stop', component: c.ReportCommonComponent,
        resolve: {'data': r.ReportCommonResolve}
      },

      {path: 'report-kpi', component: c.ReportKpiComponent,
        resolve: {'data': r.ReportKpiResolve}
      },

      {path: 'report-kpi/:start/:stop', component: c.ReportKpiComponent,
        resolve: {'data': r.ReportKpiResolve}
      },

      {path: 'report-kpi/table', component: c.ReportKpiTableComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {'data': r.ReportKpiTableResolve}
      },

      {path: 'report-attraction-activate', component: c.ReportAttractionActivateComponent,
        resolve: {'data': r.ReportAttractionActivateResolve}
      },

      {path: 'report-attraction-activate/:start/:stop', component: c.ReportAttractionActivateComponent,
        resolve: {'data': r.ReportAttractionActivateResolve}
      },

      {path: 'report-attraction-activate/table', component: c.ReportAttractionActivateTableComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {'data': r.ReportAttractionActivateTableResolve}
      },
      {path: 'report-get-sub-activity/:fact_type/:result_type', component: c.GetActivityComponent,
        resolve: {'data': r.GetSubActivityResolve}
      },
      {path: 'report-get-sub-activity/:fact_type/:result_type/:start/:stop', component: c.GetActivityComponent,
        resolve: {'data': r.GetSubActivityResolve}
      },
      {path: 'report-get-sub-activity/:fact_type/:result_type/:start/:stop/:sub_date_from', component: c.GetActivityComponent,
        resolve: {'data': r.GetSubActivityResolve}
      },
      {path: 'report-get-sub-user-activity/:subagent_id/:fact_type/:result_type', component: c.GetActivityComponent,
        resolve: {
          'data': r.GetSubUserActivityResolve,
          'org': r.GetSubActivityOneOrganisationResolve
        },
      },
      {path: 'report-get-sub-user-activity/:subagent_id/:fact_type/:result_type/:start/:stop', component: c.GetActivityComponent,
        resolve: {
          'data': r.GetSubUserActivityResolve,
          'org': r.GetSubActivityOneOrganisationResolve
        },
      },
      {path: 'report-get-sub-user-activity/:subagent_id/:fact_type/:result_type/:start/:stop/:sub_date_from', component: c.GetActivityComponent,
        resolve: {
          'data': r.GetSubUserActivityResolve,
          'org': r.GetSubActivityOneOrganisationResolve
        },
      },
      {path: 'report-get-complex-activity/:fact_type/:result_type', component: c.GetActivityComponent,
        resolve: {'data': r.GetComplexActivityResolve}
      },
      {path: 'report-get-complex-activity/:fact_type/:result_type/:complex_type/:events/:vitrina/:start/:stop', component: c.GetActivityComponent,
        resolve: {'data': r.GetComplexActivityResolve}
      },
      {path: 'report-get-activity-complex-id/:fact_type/:result_type/:start/:stop/:one_complex_id', component: c.GetActivityComplexIdComponent,
        resolve: {'data': r.GetActivityComplexIdResolve}
      },
      {path: 'inner-lead/table', component: c.GetActivityTableComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {'data': r.GetActivityTableResolve}
      },
      {path: 'calendar-summary/table', component: c.GetCalendarSummaryTableComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {'data': r.GetCalendarSummaryTableResolve}
      },
      {path: 'calendar-summary-organisation/table', component: c.GetCalendarSummaryOrganisationTableComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {'data': r.GetCalendarSummaryOrganisationTableResolve}
      },
      {path: 'realty-summary/table', component: c.GetRealtySummaryTableComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {'data': r.GetRealtySummaryTableResolve}
      },
      {path: '', redirectTo: 'index', pathMatch: 'prefix'}
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule
  ]
})
export class AnalyticRoutingModule { }
