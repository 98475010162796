
<div class="constrain">
  <div class="constrain__wrapper">
    <app-settings-constraints-table
      [columns]="columns"
      [whenDelete]="whenDel"
    ></app-settings-constraints-table>
  </div>
</div>

<ng-template #btn let-row="row">
  <a [routerLink]="['/settings/constraints/edit', row['subagent_id']]" class="button button_sm">
    Редактировать ограничения</a>
</ng-template>

<app-settings-constraints-subagents
  [subagent_id]="subagent_id"
  (onClosed)="WhenDataClosed()"
  (onDeleted)="whenDeleted()"
></app-settings-constraints-subagents>

<ng-template #caption let-row="row">
  <div class="contrain__holder">
    <p>{{row['subagent_caption']}}</p>
    <div *ngIf="row['constrain_local']||row['constrain_system']" class="text-danger">
      Персональные ограничения:
      <span *ngIf="row['constrain_local']||row['constrain_system']" class="contrain__holder__value_constraints text-danger animation"
            (click)="OpenData(row['subagent_id'])"
            [class.opened]="opened[row['subagent_id']]">
        <span title="Ограничения, установленные для субагента с управляющий части">
          {{row['constrain_system']}} на УПР</span>,
        <span title="Ограничения, которые субагент установил себе самостоятельно в ЛК">
          {{row['constrain_local']}} в ЛК СА</span>
      </span>
    </div>
  </div>
</ng-template>
