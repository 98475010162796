import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GoBackButtonService} from '../../../services/go-back-button.service';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-booking-info',
  templateUrl: './booking-info.component.html',
  styleUrls: ['./booking-info.component.scss']
})
export class BookingInfoComponent implements OnInit, OnDestroy {

  public data: any;
  private routerSubscription: Subscription;


  constructor(private activatedRoute: ActivatedRoute, private goBack: GoBackButtonService, private router: Router) {}

  ngOnInit() {
    this.goBack.header = '<h1>Сделки</h1>';
    this.data = this.activatedRoute.snapshot.data['data'];
    this.routerSubscription = this.router.events.subscribe((val) => {
      this.goBack.header = '<h1>Сделки</h1>';
    });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

}
