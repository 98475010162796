<div class="material-datatable-wrapper">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)">
  </app-datatable-headline>
  <!-- // -->
  <div class="table-responsive">
    <table class="table table-striped datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <!-- // -->
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>


<!--<ng-template #buttonCreate>-->
  <!--<div class="text-right">-->
    <!--<a class="button button_sm" href="javascript: void(0)" (click)="createAppeal()">Создать обращение</a>-->
  <!--</div>-->
<!--</ng-template>-->

<ng-template #headAppealId let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="appeal_id" [cssClass]="'mb-0'" [placeholder]="'#'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #headAppealDate let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input-datepicker
      formControlName="appeal_date" [cssClass]="'mb-0'" [placeholder]="'Дата обращения'">
    </app-material-input-datepicker>
  </form>
</ng-template>

<ng-template #headAppealFio let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input
      formControlName="appeal_fio" [cssClass]="'mb-0'" [placeholder]="'Кто обратился'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #headAppealPhone let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input
      formControlName="appeal_phone" [cssClass]="'mb-0'" [placeholder]="'Телефон'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #headAppealManager let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input
      formControlName="appeal_manager" [cssClass]="'mb-0'" [placeholder]="'Отвественный'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #headAppealAuthor let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input
      formControlName="appeal_author" [cssClass]="'mb-0'" [placeholder]="'Автор'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #headOperationType let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-select formControlName="appeal_operation_type">
      <option [value]="''">Все операции</option>
      <option [value]="'Зарезервировать квартиру'">Зарезервировать квартиру</option>
      <option [value]="'Просмотр объекта'">Просмотр объекта</option>
    </app-material-select>
  </form>
</ng-template>

<ng-template #tmplEstManager let-row="row">
  <ng-container *ngIf="row['appeal_est_manager']">
    <p class="mb-1">{{row['appeal_est_manager']}}</p>
    <p class="mb-0">{{row['appeal_est_manager_phone']}}</p>
  </ng-container>
</ng-template>

<ng-template #tmplEditBtn let-row="row">
  <a [routerLink]="['/appeals/element', row['appeal_id']]" class="button button_icon button_icon_sub">
    <i class="fa fa-user"></i>
  </a>
</ng-template>

