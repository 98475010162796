
<ng-container *ngIf="header_info">
  <p class="d-inline-block mr-2r" *ngFor="let el of header_info">
    {{el.caption}}: <b>{{el.count}}</b>
  </p>
</ng-container>
<form class="online__form form_crm" [formGroup]="form" novalidate (submit)="onSearch()">
  <div class="row">
    <div class="col-md-3">
      <app-material-input-datepicker formControlName="date">Дата</app-material-input-datepicker>
    </div>
    <div class="col-md-3">
      <app-material-select formControlName="modification" [caption]="'Тип графика '">
        <option value="workday">Рабочий день</option>
        <option value="fullday">Полный день</option>
      </app-material-select>
    </div>
    <div class="col-md-3">
      <button type="submit" [disabled]="!form.valid" class="button">Искать</button>
    </div>
  </div>
</form>
<div class="online__wrapper">
  <div class="online__element" *ngFor="let element of data">
    <p class="online__element__caption">{{element['physical_fio']}}</p>
    <p class="online__element__ogranisation_mute">{{element['organisation_socrachcaption']}}</p>
    <div class="online__element__progress-wrapper">
      <div class="progress online__element__progress">
        <div class="progress-bar online__element__progress-bar" *ngFor="let time of element['online_list']"
          [ngStyle]="{'left': time['start_perc'] + '%', 'width': time['stop_perc'] - time['start_perc'] + '%'}"></div>
      </div>
      <ul [ngClass]="'online__element__progress-hours_' + hours.length">
        <li *ngFor="let el of hours" class="online__element__progress-hours__element">{{el}}:00</li>
      </ul>
    </div>
  </div>
</div>
