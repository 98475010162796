import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {DictionariesService} from '../../../services/dictionaries.service';

@Injectable()
export class CalendarStatisticResole {

  constructor(private dictionariesService: DictionariesService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return this.dictionariesService.get('Calendar.types_list');
  }

}
