import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { MaterialInputModule } from '../../modules/@material-input/material-input.module';
import {PaginatorModule} from '../../shared/housing-modules/modules/paginator/paginator.module';
import {ManagersComponent} from './managers.component';
import {ManagersResolve} from './managers.resolve';
import {ManagersCreateComponent} from './managers-create/managers-create.component';
import {ManagersEditResolve} from './managers-edit/managers-edit.resolve';
import {ManagersEditComponent} from './managers-edit/managers-edit.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
    RouterModule,
    SharedModule,
    PaginatorModule,
  ],
  declarations: [
    ManagersComponent,
    ManagersCreateComponent,
    ManagersEditComponent,
  ],
  providers: [
    ManagersResolve,
    ManagersEditResolve
  ],
  exports: [
    ManagersCreateComponent,
  ]
})
export class ManagersModule {
}
