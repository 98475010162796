import {EventEmitter, Injectable} from '@angular/core';
import {SingleWindowComponent} from '../single-window.component';
import {SingleWindowChild} from '../../interfaces/single-window-child';
import {SingleWindowChildComponent} from '../../interfaces/single-window-child.component';
import {Router} from '@angular/router';
import {Md5} from 'ts-md5/dist/md5';

@Injectable()
export class SingleWindowService {

  private _host: SingleWindowComponent = null;
  private _child: SingleWindowChild = null;
  private _backref: any = null;

  public childEmitter: EventEmitter<SingleWindowChild> = new EventEmitter();

  private set host(value: SingleWindowComponent) {
    this._host = value;
  }

  private get host(): SingleWindowComponent {
    return this._host;
  }

  private set child(value: SingleWindowChild) {
    this._child = value;
    this.childEmitter.emit(this._child);
  }

  private get child(): SingleWindowChild {
    return this._child;
  }

  constructor(public router: Router) { }

  public registerHost(host: SingleWindowComponent) {
    this.host = host;
  }

  public unregisterHost() {
    this.host = null;
  }

  public registerChild(child: SingleWindowChildComponent) {
    this.child = child;

    const url = this.router.url, urlTree = this.router.parseUrl(url),
      request_uri = '/' + urlTree.root.children['primary'].segments.map(it => it.path).join('/');

    this.child.url = request_uri;

    return Md5.hashStr(request_uri) + '';
  }

  public unregisterChild() {
    this.child = null;
  }

  public setProxyHeader(head: string) {
    this.host.header = head;
  }

  public set backref(value: any) {
    this._backref = value;
  }

  public get backref(): any {
    return this._backref;
  }

}
