<div class="material-datatable-wrapper">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)"
    [templateLeft]="excelUnload"
    [templateRight]="buttonCreate">
  </app-datatable-headline>
  <div class="table-responsive">
    <table class="table table-striped datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>

<ng-template #buttonCreate>
  <button type="button" class="button button_sm" (click)="createStartSales()">
    Создать<span class="d-none d-md-inline"> старт продаж</span>
  </button>
</ng-template>

<ng-template #excelUnload>
  <div>
    <app-material-input-checkbox [(ngModel)]="is_archive" [cssClass]="'mb-0'" [border]="true" [withMb0]="true">
      <i class="fa fa-trash-o"></i>
    </app-material-input-checkbox>
  </div>
  <div>
    <a [href]="urlExcel" class="button button_sm ml-2 mr-2" target="_blank" title="Скачать список участников">
      <i class="fa fa-download"></i>
    </a>
  </div>
</ng-template>

<ng-template #endTmpl let-row="row">
  <p *ngIf="row.end_when_publish"> До публикации на витрине</p>
  <p *ngIf="!row.end_when_publish">{{row.date_end}}</p>
</ng-template>

<ng-template #buttonTmpl let-row="row">
  <div class="button button_icon my-2" (click)="editStartSales(row)">
    <i class="fa fa-pencil"></i>
  </div>
  <ng-container *ngIf="!row.is_archive">
    <div class="button button_icon" (click)="deleteStartSales(row.start_of_sales_id)">
      <i class="fa fa-trash"></i>
    </div>
  </ng-container>
</ng-template>

<ng-template #complTmpl let-row="row">
  <a class="d-block mb-2" *ngIf="row.complex_id"
     [routerLink]="['/housing2/complex/', row.complex_id]">
    {{row.complex_caption}}
  </a>
</ng-template>

<ng-template #authorTmpl let-row="row">
  <a
    [routerLink]="['/physical/edit/', row.author_id]">
    {{row.author_fio}}
  </a>
</ng-template>

<ng-template #membersTmpl let-row="row">
  <a *ngIf="row.members_count" href="javascript: void(0)" (click)="participantsStartSales(row)">
    {{row.members_count}}
  </a>
  <p *ngIf="!row.members_count">Нет</p>
</ng-template>
