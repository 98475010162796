import {EventEmitter, Injectable} from '@angular/core';
import {SingleWindowChildComponent} from '../../interfaces/single-window-child.component';
import {HttpClient} from '../../../services/http.service';
import {SingleWindowChild} from "../../interfaces/single-window-child";

export class HistoryElement {

  public hash: string;
  public caption: string;
  public tooltip: string;
  public url: string;

  public favorites = false;

  constructor(private component: SingleWindowChildComponent | any) {
    this.hash = component.uuid;
    this.url = component.url;
  }

  public toServerJSON() {
    return {
      'element_hash': this.hash,
      'obj_caption': this.caption,
      'obj_url': this.url,
      'is_favorite': this.favorites,
    };
  }
}

export class History {

  public static readonly max_history_length = 30;

  private _history: Array<HistoryElement> = [];

  public get history(): Array<HistoryElement> {
    /*
     * Public getter for @History variable
     */
    return this._history;
  }

  private trimHistory(): void {
    /*
     * Function which trim history queue if it length more than @max_history_length
     */
    if (this.history.length <= History.max_history_length) {
      return;
    }

    const target_index = this.history.findIndex(x => !x.favorites);
    this.history.splice(target_index, 1);
  }

  public append(element: HistoryElement | any) {
    /*
     * Append element in history queue
     */
    const exist_element = this.history.filter(x => x.hash === element.hash).length > 0;

    if (!exist_element) {
      this.history.push(element);
    }

    this.trimHistory();

    return !exist_element;
  }

  public setHeader(hash: string, caption: string, tooltip: string = null) {
    /*
     * Set history element's header
     */
    const element = this.history.find(x => x.hash === hash);

    if (!element) {
      return;
    }

    element.caption = caption;
    element.tooltip = tooltip;

    return true;
  }

  public setFavorite(hash: string, favorite: boolean = true) {
    /*
     * Mark history element as favorite (or not)
     */
    const element = this.history.find(x => x.hash === hash);

    if (!element) {
      return;
    }

    element.favorites = favorite;

    return true;
  }

  public purge() {
    /*
     * Purge all history
     */
    this._history = [];
  }

  public getByHash(hash: string) {
    const element = this.history.find(x => x.hash === hash);

    if (!element) {
      return;
    }

    return element;
  }

}


@Injectable()
export class HistoryService {

  private _global_history: History = new History();
  private _local_history: History = new History();
  private _opened = false;

  public global_history: EventEmitter<History> = new EventEmitter();
  public local_history: EventEmitter<History> = new EventEmitter();
  public status_emitter: EventEmitter<boolean> = new EventEmitter();

  public set opened(value: boolean) {
    this._opened = value;
    this.status_emitter.emit(this._opened);
  }

  public get opened(): boolean {
    return this._opened;
  }

  constructor(private http: HttpClient) { }

  public getLocalHistory(): History {
    return this._local_history;
  }

  public getGlobalHistory(): History {
    return this._global_history;
  }

  public append(component: SingleWindowChild): string {
    /*
     * Append element into two histories and emit them
     */
    const element: HistoryElement = new HistoryElement(component);

    this._global_history.append(element);
    this._local_history.append(element);

    this.global_history.emit(this._global_history);
    this.local_history.emit(this._local_history);

    return element.hash;
  }

  public append_alt(element: any) {
    /*
     * Alternative append element from server response
     */
    this._global_history.append(element);
    this.global_history.emit(this._global_history);
  }

  public setFavorite(hash: string, favorite: boolean = true) {
    /*
     * Mark history element as favorite (or not)
     */
    if (this._global_history.setFavorite(hash, favorite)) {
      const options = {'element_hash': hash, 'is_favorites': favorite};
      this.http.post('History.mark_favorites', options).subscribe();
    }

    this.global_history.emit(this._global_history);
  }

  public purgeHistory() {
    /*
     * Purge local history
     */
    this._local_history.purge();
    this.local_history.emit(this._local_history);
  }

  public setHeader(hash: string, caption: string, tooltip: string = null) {
    /*
     * Set history element's header
     */
    this._local_history.setHeader(hash, caption, tooltip);

    if (this._global_history.setHeader(hash, caption, tooltip)) {
      this.http.post('History.add', this._global_history.getByHash(hash).toServerJSON()).subscribe();
    }

    this.global_history.emit(this._global_history);
    this.local_history.emit(this._local_history);
  }

  public isFavorites(hash) {
    return this._global_history.history.filter(x => x.hash === hash && x.favorites).length > 0;
  }

  public toggleOpened() {
    this.opened = !this.opened;
    return this.opened;
  }

}
