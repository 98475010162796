<div class="material-datatable-wrapper">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)">
  </app-datatable-headline>
  <div class="table-responsive">
    <table class="table table-striped datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>

<ng-template #isUserTmpl let-row="row">
  {{(row.is_user) ? 'Да' : 'Нет'}}
</ng-template>

<ng-template #org2phyCaptiondTmpl let-row="row">
  <div [innerHtml]="row.org2phy_caption"></div>
</ng-template>

<ng-template #clientTmpl let-row="row">
  <a [routerLink]="['/physical/edit', row.physical_id, 'organization']">
    <i class="fa fa-user"></i> {{row.physical_fio}}
  </a>
</ng-template>

<ng-template #statusTmpl let-row="row">
  <b>Шаг мастера: 0/5</b>
</ng-template>

<ng-template #physicalIdHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_id" [cssClass]="'mb-0'" [placeholder]="'№'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #physicalFio let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_fio" [cssClass]="'mb-0'" [placeholder]="'Имя'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #userLogin let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_login" [cssClass]="'mb-0'" [placeholder]="'Логин'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #physicalPhone let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_phone" [cssClass]="'mb-0'" [placeholder]="'Телефон'">
    </app-material-input>
  </form>
</ng-template>
