
<a class="button my-3" routerLink="/attraction/lead-info/create/">Создать лид</a>
<ul class="navigation__tabs">
  <li class="navigation__tab">
    <a class="navigation__tab__link" routerLink="today" routerLinkActive="navigation__tab__link_active">
      Сегодня ({{data.count_today}}) <span class="navigation__tab__link_active__slide"></span>
    </a>
  </li>
  <li class="navigation__tab">
    <a class="navigation__tab__link" routerLink="all" routerLinkActive="navigation__tab__link_active">
      Все обращения ({{data.count_all}}) <span class="navigation__tab__link_active__slide"></span>
    </a>
  </li>
</ul>
<router-outlet></router-outlet>
