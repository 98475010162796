import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {StagesDotsComponent} from './component/stages-dots.component';
import {PopoverModule} from '../@popover/popober.module';
import {RightWindowModule} from '../../shared/right-window/right-window.module';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    PopoverModule,
    RightWindowModule,
    RouterModule
  ],
  declarations: [
    StagesDotsComponent,
  ],
  exports: [
    StagesDotsComponent,
  ],
})
export class StagesDotsModule {

}
