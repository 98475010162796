<form class="form form_crm" [formGroup]="form" *ngIf="form" novalidate>
  <div class="row no-gutters gutters-4">
    <div class="col-6">
      <app-material-input formControlName="organisation_inn" [require]="true" [placeholder]="'ИНН'">
        ИНН
      </app-material-input>
      <app-material-input formControlName="organisation_ogrn" [placeholder]="'ОГРН / ОГРНИП'">
        ОГРН / ОГРНИП
      </app-material-input>
      <app-material-input formControlName="organisation_who_register" [require]="true" [placeholder]="'Кем зарегистрировано'">
        Кем зарегистрировано
      </app-material-input>
      <app-material-input-datepicker formControlName="organisation_register_date" [require]="true" [placeholder]="'Дата регистрации'">
        Дата регистрации
      </app-material-input-datepicker>
      <app-material-input formControlName="organisation_kpp" [placeholder]="'КПП'">
        КПП
      </app-material-input>
      <app-material-select formControlName="organisation_tax_id" [require]="true" [caption]="'Форма налогообложения'">
        <option [value]="''">-- Не выбрано --</option>
        <option *ngFor="let nalogForm of nalogForms" [value]="nalogForm.organisation_tax_forms_id">
          {{nalogForm.organisation_tax_forms_caption}}
        </option>
      </app-material-select>
    </div>
    <div class="col-6">
      <app-material-input formControlName="organisation_okpo" [placeholder]="'ОКПО'">
        ОКПО
      </app-material-input>
      <app-material-input formControlName="organisation_okved" [placeholder]="'ОКВЭД'">
        ОКВЭД
      </app-material-input>
      <app-material-input formControlName="organisation_bank_bik" [require]="true" [placeholder]="'Бик'">
        Бик
      </app-material-input>
      <app-material-input formControlName="organisation_bank_scheta" [require]="true" [placeholder]="'Банк счет'">
        Банк счет
      </app-material-input>
      <app-material-input formControlName="organisation_rachetniy_schet" [require]="true" [placeholder]="'Расчетный счет'">
        Расчетный счет
      </app-material-input>
      <app-material-input formControlName="organisation_kor_schet" [require]="true" [placeholder]="'Корреспондентский счет'">
        Корреспондентский счет
      </app-material-input>
    </div>
  </div>
</form>
