<tr *ngIf="loading">
  <td [attr.colspan]="fields.length" class="pt-3 pb-3">
    <div class="text-center c-dark-grey">Загрузка <i class="fa fa-spinner fa-spin"></i></div>
  </td>
</tr>
<tr *ngIf="!onIsNull(rows) && !rows.length && !loading">
  <td [attr.colspan]="fields.length" class="pt-3 pb-3">
    Записи отсутствуют.
  </td>
</tr>
<ng-container *ngIf="!onIsNull(rows) && rows && rows.length && !loading">
  <tr *ngFor="let row of rows; let row_index = index" [ngClass]="{'info': 'info' == row._rowCls}" [attr.data-color]="rowClass?row[rowClass]:''" (click)="rowClick.emit(row)">
    <ng-container *ngIf="!columns.length">
      <td *ngFor="let cell of fields">
        <span *ngIf="!cell.template">{{row[cell.name]}}</span>
        <div>
          <ng-template
            *ngIf="cell.template"
            [ngTemplateOutlet]="cell.template"
            [ngTemplateOutletContext]="{row: row}"></ng-template>
        </div>
      </td>
    </ng-container>
    <ng-container *ngIf="columns.length">
      <td *ngFor="let cell of fields; let i = index" [attr.data-th]="columns[i].caption"
          [class.data-th]="columns[i].caption">
        <span *ngIf="!cell.template" [innerHtml]="row[cell.name]"></span>
        <div>
          <ng-template
            *ngIf="cell.template"
            [ngTemplateOutlet]="cell.template"
            [ngTemplateOutletContext]="{row: row, row_index: row_index}"></ng-template>
        </div>
      </td>
    </ng-container>
  </tr>
</ng-container>
