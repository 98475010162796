<p class="mt-4 mb-2">
  Всего: <b>{{count}}</b>
</p>
<div class="row">
  <div [formGroup]="form" class="col-md-6 col-lg-6 col-xl-4">
    <!--<p class="form-group"><strong>Найдено результатов: {{showed_lines}}</strong></p>-->
    <div class="form-group mb-0 mr-3">
      <app-material-input-search formControlName="search"
                                 [placeholder]="'Поиск'" [withMb0]="true"></app-material-input-search>
    </div>
  </div>
</div>
<div class="material-datatable-wrapper">
  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th *ngFor="let el of head" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
            <div [formGroup]="form">
              <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
                <div class="col table__input">
                  <ng-container *ngIf="el.input">
                    <app-material-input
                      [placeholder] = "el.caption"
                      [formControlName] = "el.name"
                      [cssClass]="'mb-0'">
                    </app-material-input>
                  </ng-container>
                  <ng-container *ngIf="!el.input && el.name !== 'search_fields'">
                    {{el.caption}}
                  </ng-container>
                </div>
                <div class="col-auto" *ngIf="el.sort">
                  <a href="javascript:void(0)" (click)="sortChange(el.name)">
                    <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                        'icon_new-sort-down': 'asc' == sort[el.name],
                                        'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                  </a>
                </div>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <!-- // -->
      <tbody>
        <tr *ngFor="let row of rows; let row_index = index">
          <td>
            {{row['datetime_created']}}
          </td>
          <td *ngIf="row['housing_id']">
            {{row['housing_id']}}
          </td>
          <td *ngIf="!row['housing_id']">
            {{row['complex_id']}}
          </td>
          <td>
            <a class="d-block mb-2" *ngIf="row['complex_id']" href="/housing2/complex/{{row['complex_id']}}"
               (click)="goBack.newLink('/housing2/complex/' + row['complex_id'], 'complex_caption' + row_index, $event)">
              {{row['complex_caption']}}
            </a>
          </td>
          <td>
            <a class="d-block mb-2" *ngIf="row['housing_id']" href="/housing2/house/{{row['housing_id']}}"
               (click)="goBack.newLink('/housing2/house/' + row['housing_id'], 'housing_caption' + row_index, $event)">
              {{row['housing_caption']}}
            </a>
            <p *ngIf="!row['housing_id']">&mdash;</p>
          </td>
          <td>
            <p *ngIf="row['flat_count']">{{row['flat_count']}}</p>
            <p *ngIf="!row['flat_count']">&mdash;</p>
          </td>
          <td>
            <p *ngIf="row['import_caption']">{{row['import_caption']}}</p>
            <p *ngIf="!row['import_caption']">&mdash;</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
