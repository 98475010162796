import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {HttpClient} from '../../../services/http.service';
import {NotificationService} from '../../../services/notification.service';
import {OverlayWindowService} from '../../../_crm/single-window/shared/overlay-window.service';

declare const swal;

@Component({
  templateUrl: './calendar-statistic-popup.component.html',
  styleUrls: ['./calendar-statistic-popup.component.scss']
})
export class CalendarStatisticPopupComponent implements OnInit {

  public form: UntypedFormGroup;
  public search_string;

  @Input() public event_id;
  @Input() public rows;
  @Input() public caption;
  @Input() public editable = true;

  @Input() public is_approved = null;

  public get closeable() {
    return this.rows.filter(x => x.approve).length > 0;
  }

  constructor(private fb: UntypedFormBuilder, private http: HttpClient, private overlayWindowService: OverlayWindowService,
              private notify: NotificationService) { }

  ngOnInit() {
    this.form = this.fb.group({
      'search': [''],
      'status': ['']
    });
  }

  toggleApproved(row) {
    const options = {
      'event_id': this.event_id,
      'physical_id': row['user_id'],
      'organisation_id': row['organisation_id'],
      'approve_is': !row['approve'],
    };

    this.http.post('Calendar.set_approve_subscription', options).subscribe();
  }

  toggleStatus(is_completed) {
    const promise = is_completed ? this.displayCompleteConfirm() : this.displayDeclineConfirm();

    promise.then(_ => {
      this.http.post('Calendar.set_event_status', {'event_id': this.event_id, 'status': is_completed})
        .subscribe(__ => this.overlayWindowService.closeHost());
    }).catch(() => false);
  }

  displayCompleteConfirm() {
    return NotificationService.swalConfirm('Внимание', 'Мероприятие будет отмечено как завершенное.' +
      ' Изменения мероприятия будут невозможны. Продолжить?');
  }

  displayDeclineConfirm() {
    return NotificationService.swalConfirm('Внимание', 'Мероприятие будет отмечено как отмененное. ' +
      ' Изменения мероприятия будут невозможны. Продолжить?');
  }
}
