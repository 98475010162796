<div class="mb-2">
  <div class="row no-gutters align-items-center m-0">
    <div class="col-md-6 col-lg-6 col-xl-4">
      <div class="form__group">
        <div class="form__input">
          <input class="input" type="text" placeholder="Поиск"
                 [ngModel]="filter" (ngModelChange)="onFilterChanges($event)">
          <div class="form__input_icon icon icon_Small_Search c-pointer ng-star-inserted"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="material-datatable-wrapper">
  <div class="table-responsive">
    <table class="table">
      <tr>
        <th *ngFor="let el of head" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
          <div [formGroup]="form">
            <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
              <div class="col table__input">
                <app-material-input
                  [placeholder] = "el.caption"
                  [formControlName] = "el.name"
                  [cssClass]="'mb-0'">
                </app-material-input>
              </div>
              <div class="col-auto" *ngIf="el.sort">
                <a href="javascript:void(0)" (click)="sortChange(el.name)">
                  <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                      'icon_new-sort-down': 'asc' == sort[el.name],
                                      'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                </a>
              </div>
            </div>
          </div>
        </th>
      </tr>
      <!-- // -->
      <tr *ngFor="let row of rows; let row_index = index">
        <td>{{row['realty_id']}}</td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'realty_complex_caption' + row_index}">
            <a href="/housing2/complex/{{row.realty_complex_id}}"
               (click)="goBack.newLink('/housing2/complex/' + row.realty_complex_id, 'realty_complex_caption' + row_index, $event)">
              <i class="fa fa-user"></i> {{row.realty_complex_caption}}
            </a>
          </div>
        </td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'realty_housing_caption' + row_index}">
            <a href="/housing2/house/{{row.realty_housing_id}}"
               (click)="goBack.newLink('/housing2/house/' + row.realty_housing_id, 'realty_housing_caption' + row_index, $event)">
              <i class="fa fa-user"></i> {{row.realty_housing_caption}}
            </a>
          </div>
        </td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'realty_caption' + row_index}">
            <a href="/housing2/flat/{{row.realty_id}}"
               (click)="goBack.newLink('/housing2/flat/' + row.realty_id, 'realty_caption' + row_index, $event)">
                {{row.realty_caption}}
            </a>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>

<div class="alt-pagination">
  <app-paginator
    [queryParams]="filter_columns"
    [itemsPerPage]="50"
    [itemCount]="count"
    [currentPage]="current_page"
    [baseUrl]="'/control/not-imported'">
  </app-paginator>
</div>
