import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../services/notification.service';
import { OverlayWindowService } from '../../single-window/shared/overlay-window.service';
import { HttpClient } from '../../../services/http.service';

@Component({
  selector: 'app-action-edit',
  templateUrl: './action-edit.component.html',
  styleUrls: ['./action-edit.component.scss']
})
export class ActionEditComponent implements OnInit {

  @Input() public description = '';
  @Input() public id;
  @Input() public title = '';
  @Input() public url = '';
  @Input() public is_url = '';
  @Input() public banner = [];
  @Input() public icon = [];
  @Input() public files = [];
  @Input() public history = [];
  @Output() public onEdit: EventEmitter<any> = new EventEmitter<any>();

  public form: UntypedFormGroup;

  constructor(private http: HttpClient, private overlayWindowService: OverlayWindowService,
              private fb: UntypedFormBuilder, private notify: NotificationService) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      'title': [this.title, Validators.required],
      'description': [this.description, Validators.required],
      'url': [this.url],
      'is_url': [this.is_url],
      'file_bn': [this.banner, Validators.required],
      'file_icon': [this.icon, Validators.required],
      'files': [this.files],
    });
  }

  onSubmit() {
    const files = [];
    if (this.form.value.files) {
      for (const f of this.form.value.files) {
        f['type_id'] = 42;
        files.push(f);
      }
    }
    if (this.form.value.file_bn) {
      for (const fb of this.form.value.file_bn) {
        fb['type_id'] = 40;
        files.push(fb);
      }
    }
    if (this.form.value.file_icon) {
      for (const fi of this.form.value.file_icon) {
        fi['type_id'] = 41;
        files.push(fi);
      }
    }
    const data = {
      'id': this.id,
      'data': {
        'title': this.form.value.title,
        'description': this.form.value.description,
        'url': this.form.value.url,
        'is_url': this.form.value.is_url,
        'files': files,
      }
    };
    this.http.post('ActionBanners.edit', data).subscribe(
      response => {
        const title = this.form.value.news_title;
        this.notify.notifySuccess('Акция успешно отредактирована', title, 5000);
        this.onEdit.emit({'value': title});
        this.overlayWindowService.closeHost();
      },
      error => this.notify.notifyError('Ошибка', error, 5000)
    );
  }

}
