<div [formGroup]="form">
  <div class="table-responsive no-min-height">
    <table
      class="table table_middle table-striped"
      [formArrayName]="field_name">
      <tr>
        <th>Основное</th>
        <th>Станция</th>
        <th>До метро, метров</th>
        <th>Транспортом, мин</th>
        <th>Пешком, мин</th>
        <th></th>
      </tr>
      <tr
        *ngFor="let el of subways.controls; index as i"
        [formGroupName]="i">
        <td>
          <app-material-input-checkbox
            [disabled]="subways.controls[i].get('is_main').value"
            formControlName="is_main">
          </app-material-input-checkbox>
        </td>
        <td style="min-width: 15rem;">
          <ng-container *ngIf="subway_list && subway_list.length">
            <app-material-select2
              formControlName="id">
              <option [value]="''">Не выбрано</option>
              <option
                *ngFor="let el of subway_list | exclude: chosen_elements:el.get('id').value"
                [value]="el['dict_subway_id']">
                {{el['dict_subway_caption']}}
              </option>
            </app-material-select2>
          </ng-container>
        </td>
        <td>
          <app-material-input
            formControlName="distance">
          </app-material-input>
        </td>
        <td>
          <app-material-input
            formControlName="distance_min_transport">
          </app-material-input>
        </td>
        <td>
          <app-material-input
            formControlName="distance_min_walk">
          </app-material-input>
        </td>
        <td>
          <a
            (click)="dropSubway(i)"
            class="button button_icon ng-star-inserted"
            href="javascript:void(0)"
            title="Информация">
            <i class="fa fa-fw fa-trash"></i>
          </a>
        </td>
      </tr>
    </table>
  </div>
</div>
<span class="button mt-3" (click)="addSubway()">Добавить</span>
