import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {JsonClientService} from '../../../../shared/json-client';

declare const moment;

@Injectable()
export class GetActivityComplexIdResolve {

  constructor(private j: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot, ...args): Promise<any> | any {
    const options = {
      'date_start': route.params['start'] || moment()
        .startOf('year').format('DD.MM.YYYY'),
      'date_end': route.params['stop'] || moment()
        .endOf('month').format('DD.MM.YYYY'),
      'fact_type': route.params['fact_type'] || '',
      'result_type': route.params['result_type'] || '',
      'complex_type': '',
      'one_complex_id': route.params['one_complex_id'] || '',
    };
    return this.j.post('Analytics.get_one_complex_activity', options);
  }

}
