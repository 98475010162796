import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {KeysPipe} from '../../../pipes/keys.pipe';


@Component({
  selector: 'app-material-input-radio',
  templateUrl: './material-input-radio.component.html',
  styleUrls: ['./material-input-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialInputRadioComponent),
      multi: true
    }
  ]
})
export class MaterialInputRadioComponent {
  @Input() public require = false;
  @Input() public cssClass = '';
  @Input() public icon = 'icon_done_mark';
  @Input() public disabled = false;
  @Input() public data = {};
  @Input() public error = false;
  @Input() public type = 'radio';
  @Input() public suppressLabel = false;

  private _current_value;

  public get current_value(): any {
    return this._current_value;
  }

  public set current_value(value: any) {
    this._current_value = value;
    this.propagateChange(this._current_value);
  }

  constructor(public keys: KeysPipe) {
  }

  propagateChange = (_: any) => {
  }

  onChange(value: any) {
    this.current_value = value;
  }

  writeValue(value: any) {
    this.current_value = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
  }

  onChanges(value: any, e: any) {
    if (!this.disabled) {
      if (this.type === 'radio') {
        this.current_value = value;
      } else {
        if (e.target.checked) {
          this._current_value.push(value);
          this.propagateChange(this._current_value);
        } else {
          this.current_value = this.current_value.filter(val => val !== value);
        }
      }
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

}
