import { AfterContentInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '../../../services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableParamComponent } from '../../../modules/@datatable/components/datatable-param.component';
import { StatisticPluginService } from '../shared/statistic-plugin.service';
import { BackrefWindowService } from '../../single-window/shared/backref-window.service';
import {GoBackButtonService} from '../../../services/go-back-button.service';


@Component({
  selector: 'app-statistic-plugin-site',
  templateUrl: './statistic-plugin-site.component.html',
  styleUrls: ['./statistic-plugin-site.component.scss']
})
export class StatisticPluginSiteComponent extends DataTableParamComponent implements OnInit, AfterContentInit, OnDestroy {

  @ViewChild('date_appeal', {static: true}) private date_appeal;
  @ViewChild('client_email', {static: true}) private client_email;
  @ViewChild('client_fio', {static: true}) private client_fio;
  @ViewChild('client_phone', {static: true}) private client_phone;
  @ViewChild('flat_address', {static: true}) private flat_address;
  @ViewChild('flat_caption', {static: true}) private flat_caption;
  @ViewChild('flat_price', {static: true}) private flat_price;

  public columns;
  public api_action = 'IFrame.appeals_from_plugin';
  public plugin: any;
  public id: any;

  constructor(public http: HttpClient, public router: Router,
              public activatedRoute: ActivatedRoute, private goBack: GoBackButtonService,
              public backrefWindowService: BackrefWindowService,
              public statisticPluginService: StatisticPluginService) {
    super(http, activatedRoute, router);

    this.plugin = this.activatedRoute.snapshot.data['data'];
    this.id = this.activatedRoute.snapshot.params['id'];
    this.backrefWindowService.routerInit();
  }


  ngOnInit() {
    this.goBack.header = '<h1>Статистика плагина по сайту</h1>';
    this.columns = [
      {'name': 'date_appeal', 'caption': 'Дата', 'sortable': true, 'template': this.date_appeal},
      {'name': 'client_fio', 'caption': 'ФИО клиента', 'sortable': true, 'template': this.client_fio},
      {'name': 'client_email', 'caption': 'Email', 'sortable': true, 'template': this.client_email},
      {'name': 'client_phone', 'caption': 'Телефон', 'sortable': true, 'template': this.client_phone},
      {'name': 'flat_caption', 'caption': 'Объект', 'sortable': true, 'template': this.flat_caption},
      {'name': 'flat_address', 'caption': 'Адрес', 'sortable': true, 'template': this.flat_address},
      {'name': 'flat_price', 'caption': 'Цена', 'sortable': true, 'template': this.flat_price},
    ];

    super.ngOnInit();
  }

  public prepareQuery() {
    const query = super.prepareQuery();
    query['token'] = this.id;

    return query;
  }

  public loadList() {
    super.loadList();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.backrefWindowService.routerDestroy();
  }

  ngAfterContentInit() {

  }

  backrefRoute() {
    this.router.navigateByUrl(this.statisticPluginService.backref);
  }

}
