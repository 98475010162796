import { NgModule } from '@angular/core';
import { DictionaryBuildStageResolver } from './dictionary/dictionary-build-stages.resolve';
import { DictionaryBuyConditionsResolver } from './dictionary/dictionary-buy-conditions.resolve';
import { DictionaryHighwayResolver } from './dictionary/dictionary-highway.resolve';
import { DictionaryRailwayResolver } from './dictionary/dictionary-railway.resolve';
import { DictionarySubwayResolver } from './dictionary/dictionary-subway.resolve';
import { DictionaryTypeOtdResolver } from './dictionary/dictionary-type-otd.resolve';
import { DictionarySrokSdachiResolver } from './dictionary/dictionary-srok-sdachi.resolve';
import { DictionaryClassResolver } from './dictionary/dictionary-class.resolve';
import { DictionaryHousingTypeResolve } from './dictionary/dictionary-housing-type.resolve';
import { DictionaryHousingClassResolve } from './dictionary/dictionary-housing-class.resolve';
import { DictionaryNationalityResolver } from './dictionary/dictionary-nationality.resolve';
import { DictionaryHousingManagersResolve } from './dictionary/dictionary-housing-managers.resolve';
import { DictionaryOrganisationPostsResolver } from './dictionary/dictionary-organisation-posts.resolve';
import { DictionaryUserRolesResolver } from './dictionary/dictionary-user-roles.resolve';
import { DictionaryOrganisationTaxFormsResolver } from './dictionary/dictionary-organisation-tax-forms.resolve';
import { DictionaryOrganisationFormsResolver } from './dictionary/dictionary-organisation-forms.resolve';
import { DictionaryOrganisationTypesResolver } from './dictionary/dictionary-organisation-types.resolve';
import { DictionaryLeadsSoursesResolver } from './dictionary/dictionary-leads-sources.resolve';
import { DictionaryManagersResolve } from './dictionary/dictionary-managers.resolve';
import { DictionaryBanks } from './dictionary/dictionary-banks.resolve';
import { DictionaryFlatStatusesResolve } from './dictionary/dictionary-flat-statuses.resolve';
import {DictionaryLeadStageList} from './dictionary/dictionary-lead-stage-list';
import {DictionaryBpSaleListResolve} from './dictionary/dictionary-bp-sale-list.resolve';
import {DictionaryLeadSourcesResolve} from './dictionary/dictionary-lead-sources.resolve';
import {DictionaryAddressDistrictsResolve} from './dictionary/dictionary-address-districts.resolve';
import {DictionaryMainSearchResolve} from './dictionary/dictionary-main-search.resolve';
import {DictionaryDevelopmentsResolver} from './dictionary/dictionary-developments.resolve';
import {DictionaryLeadSubagentAreaWorkResolve} from './dictionary/dictionary-lead-subagent-area-work.resolve';

@NgModule({
  providers: [
    DictionaryBuildStageResolver,
    DictionaryBuyConditionsResolver,
    DictionaryHighwayResolver,
    DictionaryRailwayResolver,
    DictionarySubwayResolver,
    DictionaryTypeOtdResolver,
    DictionarySrokSdachiResolver,
    DictionaryClassResolver,
    DictionaryHousingTypeResolve,
    DictionaryHousingClassResolve,
    DictionaryNationalityResolver,
    DictionaryHousingManagersResolve,
    DictionaryOrganisationPostsResolver,
    DictionaryUserRolesResolver,
    DictionaryOrganisationTaxFormsResolver,
    DictionaryOrganisationFormsResolver,
    DictionaryOrganisationTypesResolver,
    DictionaryLeadsSoursesResolver,
    DictionaryLeadSubagentAreaWorkResolve,
    DictionaryManagersResolve,
    DictionaryBanks,
    DictionaryFlatStatusesResolve,
    DictionaryLeadStageList,
    DictionaryBpSaleListResolve,
    DictionaryLeadSourcesResolve,
    DictionaryAddressDistrictsResolve,
    DictionaryMainSearchResolve,
    DictionaryDevelopmentsResolver,
  ]
})
export class DictionaryResolveModule {
}
