import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../services/notification.service';
import { OverlayWindowService } from '../../single-window/shared/overlay-window.service';
import { HttpClient } from '../../../services/http.service';


@Component({
  selector: 'app-start-sales-edit',
  templateUrl: './start-sales-edit.component.html',
  styleUrls: ['./start-sales-edit.component.scss']
})
export class StartSalesEditComponent implements OnInit {

  @Output() public onEdit: EventEmitter<any> = new EventEmitter<any>();
  @Input() public start_of_sales_id = '';
  @Input() public is_archive = '';

  public form: UntypedFormGroup;
  public data = null;
  public files = [];
  public complex_caption = '';

  constructor(private http: HttpClient, private overlayWindowService: OverlayWindowService,
              private fb: UntypedFormBuilder, private notify: NotificationService) {
  }

  ngOnInit() {
    this.http.post('StartOfSales.get_item', {'start_of_sales_id': this.start_of_sales_id})
      .subscribe(
        (response) => {
          this.data = response;
          this.form = this.fb.group({
            'description': [this.data.description, Validators.required],
            'date_start': [this.data.date_start, Validators.required],
            'date_end': [this.data.date_end],
            'end_when_publish': [this.data.end_when_publish],
            'files': [this.data.files],
            'is_archive': [this.data.is_archive, Validators.required],
          });
          this.complex_caption = this.data.complex_caption;
        },
        (error) => null
      );
  }


  onSubmit() {
    if (this.form.value.end_when_publish) {
      this.form.value.date_end = null;
    }
    this.form.value['files'].map(x => {
      this.files.push(x['id']);
    });
    const data = {
      'start_of_sales_id': this.start_of_sales_id,
      'data': {
        'description': this.form.value.description,
        'date_start': this.form.value.date_start,
        'date_end': this.form.value.date_end,
        'end_when_publish': this.form.value.end_when_publish,
        'files': this.files,
        'is_archive': this.form.value.is_archive,
      }
    };
    this.http.post('StartOfSales.update', data).subscribe(
      response => {
        this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
        this.onEdit.emit({'value': 'Старт продаж'});
        this.overlayWindowService.closeHost();
      },
      error => this.notify.notifyError('Ошибка', error, 5000)
    );
  }
}
