import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {TblFixedColumnsDirective} from './tbl-fixed-columns/tbl-fixed-columns.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    TblFixedColumnsDirective,
  ],
  exports: [
    TblFixedColumnsDirective,
  ]
})
export class TableHelpersModule { }
