<div class="tab-content-wrapper mt-4">
  <ng-container *ngIf="formGroup">
    <app-entity-additionally-fields [data]="data" [nalogForms]="nalogForms" [(form)]="formGroup" (postDataChange)="postData = $event"></app-entity-additionally-fields>
  </ng-container>

  <div class="col-12 mt-4">
    <button (click)="saveForm()" type="button" [disabled]="!formGroup.valid" class="button">
      Сохранить
    </button>
  </div>
</div>
