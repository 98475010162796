import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {AlternativeDatatablesInterface} from '../../shared/alternative-datatables/alternative-datatables.interface';
import {AlternativeDatatablesComponent} from '../../shared/alternative-datatables/alternative-datatables.component';
import {JsonClientService} from '../../shared/json-client';
import {GoBackButtonService} from '../../services/go-back-button.service';
import {BreadcrumbsService} from '../../shared/breadcrumbs/services';

@Component({
  templateUrl: './housing.component.html',
  styleUrls: [
    './housing.component.scss'
  ]
})
export class HousingControlComponent
  extends AlternativeDatatablesComponent
  implements AlternativeDatatablesInterface, OnInit, OnDestroy, AfterViewInit {

  public readonly head = [
    {'name': 'datetime_created', 'value': '', 'caption': 'Дата добавления', 'sort': false, 'input': false, 'width': '150px'},
    {'name': 'complex_id', 'value': '', 'caption': '#', 'sort': false, 'input': false, 'width': '50px'},
    {'name': 'complex_caption', 'value': '', 'caption': 'Комплекс', 'sort': false, 'input': false, 'width': '400px'},
    {'name': 'housing_caption', 'value': '', 'caption': 'Корпус', 'sort': false, 'input': false},
    {'name': 'flat_count', 'value': '', 'caption': 'Количество лотов', 'sort': false, 'input': false},
    {'name': 'import_caption', 'value': '', 'caption': 'Название фида', 'sort': false, 'input': false},
  ];

  public filter_columns = {};
  public form: UntypedFormGroup;
  public filter: string;

  public housing_id = null;
  private parasites = true;

  private innSubscription: Subscription = new Subscription();

  public count;
  public rows;

  public current_flat: any = null;

  public set data(value) {
    this.count = value['count'];
    this.rows = value['rows'];
  }

  constructor(private router: Router, private fb: UntypedFormBuilder, public activatedRoute: ActivatedRoute,
              public goBack: GoBackButtonService, private b: BreadcrumbsService,
              public http: JsonClientService) {
    super(router, activatedRoute, fb, http);

    this.head = this.head.map(x => {
      x.value = this.activatedRoute.snapshot.queryParams[x.name] || x.value;
      return x;
    });
    this.housing_id = this.activatedRoute.snapshot.params['housing_id'];
  }

  ngOnInit() {
    this.goBack.header = '<h1>Последние добавленные объекты</h1>';
    this.b.set([
      {url: '/', caption: 'Главная'},
      {caption: 'Последние добавленные объекты'},
    ]);
    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.form = this.fb.group({
      'search': [this.filter]
    });
    this.innSubscription = this.form.get('search').valueChanges
      .pipe(debounceTime(500))
      .subscribe(value => this.onFilterChanges(value));
    this.onInitCall();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.innSubscription.unsubscribe();
  }

  onFilterChanged(): void {
    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.data = this.activatedRoute.snapshot.data['data'];
  }

  onFilterChanges(text = null) {
    if (text !== null) {
      this.filter = text;
    }

    this.filter_columns = {};
    if (this.filter !== '') {
      this.filter_columns['global_search_string'] = this.filter;
    }
    if (this.parasites) {
      return this.parasites = !this.parasites;
    }
    this.goBack.header = '<h1>Последние добавленные объекты</h1>';
    this.b.set([
      {url: '/', caption: 'Главная'},
      {caption: 'Последние добавленные объекты'},
    ]);
    this.router.navigate(['housing-control'], {
      queryParams: this.getQueryParams(),
    });
  }

  getQueryParams() {
    const query_params = {};

    if (this.filter !== '') {
      query_params['global_search_string'] = this.filter;
    }
    return query_params;
  }

  ngAfterViewInit() {
    this.goBack.goScroll();
  }
}
