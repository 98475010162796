
<div class="material-datatable-wrapper">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)"
    [templateLeft]="additInputs"
    [templateRight]="buttonCreate">
  </app-datatable-headline>
  <div class="table-responsive">
    <table class="table table-striped datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>

<ng-template #buttonCreate>
  <button type="button" class="button button_sm" (click)="createHoliday()">
    Создать<span class="d-none d-md-inline"> график</span>
  </button>
</ng-template>

<ng-template #additInputs>
  <app-material-input-checkbox [(ngModel)]="is_archive" [cssClass]="'mb-0'" [border]="true" [withMb0]="true">
    В архиве
  </app-material-input-checkbox>
</ng-template>

<ng-template #fileTmpl  let-row="row">
  <div class="holiday__banner"  (click)="displayImage(row.file_url)"
       style="width: 32px; height: 32px"
       [style.background-image]="'url('+row.file_url+')'"></div>
</ng-template>

<ng-template #fileIconTmpl  let-row="row">
  <div class="holiday__banner"  (click)="displayImage(row.file_icon_url)"
       style="width: 32px; height: 32px"
       [style.background-image]="'url('+row.file_icon_url+')'" ></div>
</ng-template>

<ng-template #viewTmpl  let-row="row">
  <p>{{row.time_start}} - {{row.time_stop}}</p>
</ng-template>

<ng-template #archiveTmpl  let-row="row">
  <ng-container *ngIf="row.is_archive">
    <p class="badge badge-warning font-weight-bold">Архив</p>
  </ng-container>
</ng-template>

<ng-template #buttonTmpl let-row="row">
  <div class="button button_icon my-2" (click)="editNews(row)">
    <i class="fa fa-pencil"></i>
  </div>
  <ng-container *ngIf="!row.is_archive">
    <div class="button button_icon" (click)="deleteNews(row.id)">
      <i class="fa fa-trash"></i>
    </div>
  </ng-container>
</ng-template>
