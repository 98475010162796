import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {BreadcrumbsService} from '../../../../shared/breadcrumbs/services';
import {GoBackButtonService} from '../../../../services/go-back-button.service';
import {environment} from '../../../../../environments/environment';

@Component({
  templateUrl: './analytic-index.component.html',
  styleUrls: [
    './analytic-index.component.scss'
  ]
})
export class AnalyticIndexComponent implements OnInit {

  public reports;
  public apiServer;

  constructor(private b: BreadcrumbsService, private goBack: GoBackButtonService,
              private a: ActivatedRoute) { }

  ngOnInit() {
    this.goBack.header = '<h1>Аналитика</h1>';
    this.reports = this.a.snapshot.data['reports'];
    this.apiServer = environment.apiServer;
  }

  getUrl(report) {

    if (!report['lock_func']) {
      return [['/analytic/report-common'], {}];
    }

    if (['common_reserved', 'common_events', 'common_events_realty', 'common_attraction', 'common_realty', 'on_period', 'on_month', 'on_moment'].indexOf(report['lock_func']) !== -1) {
      return [['/analytic/report-common'], {'lock': report['lock_func']}];
    }

    if (['attraction_source_deal_kpi'].indexOf(report['lock_func']) !== -1) {
      return [['/analytic/report-kpi'], {'lock': report['lock_func']}];
    }

    if (['report-attraction-activate'].indexOf(report['lock_func']) !== -1) {
      return [['/analytic/report-attraction-activate'], {'lock': report['lock_func']}];
    }

    if (['get_sub_activity_create_lead'].indexOf(report['lock_func']) !== -1) {
      return [['/analytic/report-get-sub-activity/create_lead/months']];
    }

    if (['get_sub_activity_db'].indexOf(report['lock_func']) !== -1) {
      return [['/analytic/report-get-sub-activity/db/months']];
    }

    if (['get_sub_activity_finish'].indexOf(report['lock_func']) !== -1) {
      return [['/analytic/report-get-sub-activity/finish/months']];
    }

    if (['get_complex_activity_create_lead'].indexOf(report['lock_func']) !== -1) {
      return [['/analytic/report-get-complex-activity/create_lead/months']];
    }

    if (['get_complex_activity_db'].indexOf(report['lock_func']) !== -1) {
      return [['/analytic/report-get-complex-activity/db/months']];
    }

    if (['get_complex_activity_finish'].indexOf(report['lock_func']) !== -1) {
      return [['/analytic/report-get-complex-activity/finish/months']];
    }

    return [['/analytic/report', report['lock_func'], report['is_realty'] ? 'realty' : 'subagents'], {}];
  }
}
