import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {IObj} from './material-select2-autocomplete.directive';

declare const jQuery;

@Component({
  selector: 'app-material-select2-autocomplete',
  templateUrl: './material-select2-autocomplete.component.html',
  styleUrls: ['./material-select2-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialSelect2AutocompleteComponent),
      multi: true
    }
  ]
})
export class MaterialSelect2AutocompleteComponent implements AfterViewInit {

  @Input() public multiple;
  @Input() public options = {};
  @Input() public api_options = {};
  @Input() public caption = '';
  @Input() public api_action = '';
  @Input() public require = false;
  @Input() public cssClass = '';
  @Input() public placeholder = '';
  @Input() public focusOnInit = false;
  @Input() public sendSelected = false;
  @Input() public set preSelectValues(data) {
    let needToNext = false;
    const selectedValues = this.selectedValues.getValue();
    for (const el of data) {
      if (!selectedValues[el.value]) {
        selectedValues[el.value] = el;
        needToNext = true;
      }
    }
    if (needToNext) {
      this.selectedValues.next(selectedValues);
    }
    this.preSelectedValues = selectedValues;
  }
  @Output() public label: EventEmitter<any> = new EventEmitter();
  @Output() public outObj: EventEmitter<any> = new EventEmitter();
  @ViewChild('select') private select: ElementRef;

  public preSelectedValues;
  public disabled = false;
  public valid = false;
  public refresh = false;
  public selectedValues = new BehaviorSubject<{ [key: string]: IObj; }>({});
  // selectedValues$ = this.selectedValues.asObservable();

  private _value: any;

  public set current_value(value: any) {
    if (value === 'refresh') {
      this.refresh = true;
      setTimeout(() => {
        this.refresh = false;
      }, 1);
    } else {
      this._value = value;
      this.propagateChange(this._value);
    }
  }

  public get current_value(): any {
    return this._value;
  }

  constructor() {
  }

  propagateChange = (_: any) => { };

  ngAfterViewInit() {

    for (let i = 0; i < this.select.nativeElement.options.length; i++) {

      if (this.multiple) {
        this.select.nativeElement.options[i].selected =
          -1 !== this.current_value.indexOf(this.select.nativeElement.options[i].value);
      } else {
        this.select.nativeElement.options[i].selected = this.current_value === this.select.nativeElement.options[i].value;
      }

    }
  }

  appendOption(value, caption) {
    const selectedValues = this.selectedValues.getValue();
    if (!selectedValues[value]) {
      let needToAdd = true;
      for (let i = 0; i < this.select.nativeElement.options.length; i++) {
        if (this.select.nativeElement.options[i].value === value) {
          needToAdd = false;
          this.select.nativeElement.options[i].selected = true;
          jQuery(this.select.nativeElement).trigger('change');
          break;
        }
      }
      if (needToAdd) {
        const newOption = new Option(caption, value, true, true);
        jQuery(this.select.nativeElement).append(newOption).trigger('change');
      }
      selectedValues[value] = {
        value: value,
        description: caption,
        complex_not_in_overall_unique_is: undefined,
        developer_caption: undefined,
        developer_complexes_in_unique: undefined,
        developer_id: undefined,
        developer_overall_unique_is: undefined,
      };
      this.selectedValues.next(selectedValues);
    }
  }

  writeValue(value: any) {
    this.current_value = value;
  }

  onSelectChange(obj) {
    this.current_value = obj.val;
    this.label.emit(obj.text);
    this.outObj.emit(obj.obj);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
  }

}
