import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {HttpClient} from '../../../services/http.service';
import {GoBackButtonService} from '../../../services/go-back-button.service';
import {AuthenticateService} from '../../../services/authenticate.service';


@Component({
  selector: 'app-control-index',
  templateUrl: './control-index.component.html',
  styleUrls: ['./control-index.component.scss']
})
export class ControlIndexComponent implements OnInit, OnDestroy {
  public counts = {};
  private subscription: Subscription = new Subscription();
  public load = true;
  public load_long = true;

  constructor(private http: HttpClient, private goBack: GoBackButtonService, public auth: AuthenticateService) {
  }

  ngOnInit() {
    this.goBack.header = '<h1>Главная</h1>';
    this.counts = {};
    if (this.auth.isMenu('projects_flag')) {
      this.subscription.add(this.http.post('Control.get_index_counts').subscribe(data => {
        this.counts = Object.assign(this.counts, data);
        this.load = false;
      }));
      this.subscription.add(this.http.post('Control.get_index_counts_long').subscribe(data => {
        this.counts = Object.assign(this.counts, data);
        this.load_long = false;
      }));
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
