import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {JsonClientService} from '../../../shared/json-client';


@Injectable()
export class ControlCrmNotImportedFlatsResolve {

  private filter_columns = [
    'realty_id',
    'realty_complex_caption',
    'realty_housing_caption',
    'realty_caption',
  ];

  constructor(private jsonRpc: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {

    const prepared_filter = {};
    const prepared_filter_keys = this.filter_columns.filter(x => !!route.parent.queryParams[x]);
    const sort = {};

    prepared_filter_keys.forEach(x => prepared_filter[x] = route.parent.queryParams[x]);

    if (!!route.queryParams['sort_name']) {
      sort[route.queryParams['sort_name']] = route.queryParams['sort_key'] || 'asc';
    }

    const options = {
      'limit': 50,
      'offset': (+(route.queryParams['page'] || 1) - 1) * 50,
      'filter': {
        'global_search_string': route.queryParams['global_search_string'] || null,
        'fields': prepared_filter,
      },
      'sort': sort
    };
    return this.jsonRpc.post('Data_tables.get_unrelated_realty', options);
  }

}
