<app-modal-help *ngIf="displayed">
  <app-page-slider (complete)="helpComplete()" (close)="helpClose()" (allPages)="forAllPages()">
    <app-page-slider-element [caption]="'График мероприятий'">
      <div class="row no-gutters">
        <div class="col-auto">
          <div class="row no-gutters flex-column justify-content-start justify-content-lg-between help-main__page_3">
            <div class="col-auto">
              <p><b>График мероприятий</b> – раздел с календарным планом мероприятий, проводимых для партнёров SPN24 на
                месяц. Содержит расписание очного и онлайн мероприятий, даты инфо-туров по объектам, презентаций ЖК,
                мастер-классов с бизнес тренерами и других тематических мероприятий.</p>
              <p>
                Для онлайн презентаций и вебинаров, в день их проведения, в правом верхнем углу на экране системы,
                появляется уведомление о времени начала. При клике откроется список вебинаров проходящих в этот день,
                если их несколько и ссылки на подключение.
              </p>
              <p><em>Повышайте свою экспертность, увеличивайте количество сделок – записывайтесь для участия!</em></p>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <img class="help-main__img_3" src="/assets/images/help/XS5image.png" alt="">
        </div>
      </div>
    </app-page-slider-element>
  </app-page-slider>
</app-modal-help>
