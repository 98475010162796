import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../services/notification.service';
import { OverlayWindowService } from '../../single-window/shared/overlay-window.service';
import { HttpClient } from '../../../services/http.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-action-create',
  templateUrl: './action-create.component.html',
  styleUrls: ['./action-create.component.scss']
})
export class ActionCreateComponent implements OnInit {

  @Output('onCreate') public onCreate: EventEmitter<any> = new EventEmitter<any>();

  public form: UntypedFormGroup;

  constructor(private http: HttpClient, private overlayWindowService: OverlayWindowService,
              private fb: UntypedFormBuilder, private notify: NotificationService) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      'title': ['', Validators.required],
      'description': ['', Validators.required],
      'is_url': [false, Validators.required],
      'url': [''],
      'file_bn': [[], Validators.required],
      'file_icon': [[], Validators.required],
      'files': [[]],
    });
  }

  onSubmit() {
    const files = [];
    if (this.form.value.files) {
      for (const f of this.form.value.files) {
        f['type_id'] = 42;
        files.push(f);
      }
    }
    if (this.form.value.file_bn) {
      for (const fb of this.form.value.file_bn) {
        fb['type_id'] = 40;
        files.push(fb);
      }
    }
    if (this.form.value.file_icon) {
      for (const fi of this.form.value.file_icon) {
        fi['type_id'] = 41;
        files.push(fi);
      }
    }

    const data = {
      'data': {
        'title': this.form.value.title,
        'description': this.form.value.description,
        'is_url': this.form.value.is_url,
        'url': this.form.value.url,
        'files': files,
      }
    };
    this.http.post('ActionBanners.create', data).subscribe(
      response => {
        const title = this.form.value.news_title;
        this.notify.notifySuccess('Акция успешно создана', title, 5000);
        this.onCreate.emit({'value': title});
        this.overlayWindowService.closeHost();
      },
      error => this.notify.notifyError('Ошибка', error, 5000)
    );
  }
}
