import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TabsWrapperComponent} from './component/tabs-wrapper.component';
import {TabsElementComponent} from './component/tabs-element.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    TabsWrapperComponent,
    TabsElementComponent,
  ],
  exports: [
    TabsWrapperComponent,
    TabsElementComponent,
  ]
})
export class TabsModule { }
