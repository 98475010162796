import {Component, OnDestroy, OnInit} from '@angular/core';
import {HistoryService} from '../shared/history.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-single-window-history',
  templateUrl: './single-window-history.component.html',
  styleUrls: ['./single-window-history.component.scss']
})
export class SingleWindowHistoryComponent implements OnInit, OnDestroy {

  private subscription: Subscription;

  public history: any = null;

  constructor(private historyService: HistoryService) { }

  ngOnInit() {
    this.history = this.historyService.getLocalHistory();
    this.subscription = this.historyService.local_history.subscribe(elements => this.history = elements);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
