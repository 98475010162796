import {NgModule} from '@angular/core';
import {AuthenticateLoginComponent} from './authenticate-login.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialInputModule} from '../../modules/@material-input';
import { PreloaderModule } from '../../modules/@preloader/preloader.module';
import { LockerComponent } from './locker.component';
import { ServerDownComponent } from './server-down.component';
import {RouterModule} from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialInputModule,
        PreloaderModule,
        RouterModule,
    ],
  declarations: [
    AuthenticateLoginComponent,
    LockerComponent,
    ServerDownComponent,
  ]
})
export class AuthenticateModule { }
