import { Component, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UploadFile } from '../../../models/files';
import { ConfigService } from '../../../services/configuration.service';
import { HttpClient } from '../../../services/http.service';
import { NotificationService } from '../../../services/notification.service';
import {defer, fromEvent, merge, first, mergeMap, switchMap, takeUntil, tap} from 'rxjs';


@Component({
  selector: 'app-material-input-dropimage',
  templateUrl: './material-input-dropimage.component.html',
  styleUrls: ['./material-input-dropimage.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialInputDropimageComponent),
      multi: true
    }
  ]
})
export class MaterialInputDropimageComponent implements OnInit {

  public upload_url = 'baz/';
  public _current_value: any;
  public disabled;

  @Input() public disable = false;
  @Input() public cssClass = '';
  @Input() public cssClassZone = '';
  @Output() public file_upload: EventEmitter<any> = new EventEmitter<any>();
  @Output() public file_delete: EventEmitter<any> = new EventEmitter<any>();

  @Input() public uploadOptions = {};

  @ViewChild('fileInput') public fileInput;
  @ViewChild('dropZone', {static: true}) private dropZone;

  dragEnter$ = defer(() => fromEvent(this.dropZone.nativeElement, 'dragenter')).pipe(
    tap((event: any) => event.target.style.background = 'rgb(220, 255, 226)')
  );

  dragExit$ = defer(() => fromEvent(this.dropZone.nativeElement, 'dragleave')).pipe(
    tap((event: any) => event.target.style.background = '')
  );

  dragEnd$ = defer(() => fromEvent(this.dropZone.nativeElement, 'dragend')).pipe(
    tap((event: any) => event.target.style.background = '')
  );
  dragOver$ = defer(() => fromEvent(this.dropZone.nativeElement, 'dragover')).pipe(
    tap((event: any) => { event.preventDefault(); })
  );
  drop$ = defer(() => fromEvent(this.dropZone.nativeElement, 'drop')).pipe(
    tap((event: any) => {
      event.preventDefault();
      event.target.style.background = '';
    }));

  dragAndDrop$ = this.dragEnter$.pipe(
    mergeMap(() => this.dragOver$),
    switchMap(() => merge(this.dragExit$.pipe(first()),  this.drop$.pipe(takeUntil(this.dragEnd$)))));

  constructor(public http: HttpClient, public renderer: Renderer2, public config: ConfigService) { }

  public get current_value(): any {
    return this._current_value;
  }

  public set current_value(value: any) {
    this._current_value = value;
    if (value) {
      this.propagateChange(this._current_value.toDict());
    } else {
      this.propagateChange(this._current_value);
    }
  }

  propagateChange = (_: any) => { };

  ngOnInit() {
    this.upload_url = this.config.data['url_file_upload'];
    const self = this,
      drop_disable = function (e) {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
      },
      custom_drop = function (e) {
        e.stopPropagation();
        e.preventDefault();
        self.uploadFile(e.dataTransfer.files[0]);
      };

    if (!this.disable) {
      this.dropZone.nativeElement.removeEventListener('dragover', drop_disable, false);
      this.dropZone.nativeElement.addEventListener('dragover', drop_disable, false);
      this.dropZone.nativeElement.removeEventListener('drop', custom_drop, false);
      this.dropZone.nativeElement.addEventListener('drop', custom_drop, false);
      this.dragAndDrop$.subscribe();
    }
  }

  uploadFile(file) {

    if (this.disabled || !file) {
      return;
    }

    if (!file.type.match('image.*')) {
      NotificationService.swalError('Ошибка', 'Файл не является изображением');
      return false;
    }
    const reader: FileReader = new FileReader();
    reader.onprogress = (event) => {
    };

    reader.onload = (event) => {
      const fileResult = (event.srcElement || event.target)['result'].split(',').pop();
      const params = Object.assign(this.uploadOptions, {
        'filename': file['name'],
        'b64data': fileResult
      });

      this.http.post('Files.upload', params)
        .subscribe(
          (response) => {
            const new_file = new UploadFile(response.file_id, response.filename, response.uploaded, '');
            this._current_value = new_file;
            this.file_upload.emit(new_file);
            this.propagateChange(this._current_value.toDict());
          },
          (error) => NotificationService.swalError('Ошибка', 'Ошибка загрузки файла')
        );
    };

    reader.readAsDataURL(file);
  }

  onFileRemove(filepath: string) {
    NotificationService.swalConfirm('Вы уверены?', 'Выбранный файл будет безвозвратно удален!')
      .then(() => {
        if (this.current_value.filepath === filepath) {
          if (this.current_value.id) {
            this.http.post('Files.remove', {'file_id': this.current_value.id}).subscribe((response) => {
              this.file_delete.emit();
            }, error => false);
          } else {
            this.http.post('Files.remove_by_name', {'file_name': this.current_value.filepath}).subscribe((response) => {
              this.file_delete.emit();
            }, error => false);
          }
          this.current_value = '';
        }
        this.propagateChange(this._current_value.toDict());
      }).catch(() => false);
  }

  onFileChange() {
    this.uploadFile(this.fileInput.nativeElement.files[0]);
  }

  onClick() {

    if (this.disabled) {
      return;
    }

    this.fileInput.nativeElement.click();
  }

  onChange(value: UploadFile) {
    this.current_value = value;
  }

  writeValue(value: { [key: string]: string }) {
    if (value) {
      this.current_value = new UploadFile(value.id, value.filename, value.filepath, value.comment || '');
    } else {
      this.current_value = '';
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }


  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
  }

}
