import { Component, ComponentRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DataTableParamComponent } from '../../../modules/@datatable/components/datatable-param.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { OverlayWindowService } from '../../single-window/shared/overlay-window.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '../../../services/http.service';
import { NewsCreateComponent } from '../news-create/news-create.component';
import { NotificationService } from '../../../services/notification.service';
import {NewsEditComponent} from '../news-edit/news-edit.component';
import {GoBackButtonService} from '../../../services/go-back-button.service';

@Component({
  selector: 'app,yamap-news',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent extends DataTableParamComponent implements OnInit, OnDestroy {

  public api_action = 'Data_tables.get_news';
  public columns: Array<{ [key: string]: any }>;
  public formGroup: UntypedFormGroup;
  public createSubscription: Subscription;
  public editSubscription: Subscription;
  private _is_archive = false;

  @ViewChild('buttonTmpl', {static: true}) private buttonTmpl: TemplateRef<any>;
  @ViewChild('urlTmpl', {static: true}) private urlTmpl: TemplateRef<any>;
  @ViewChild('pubTmpl', {static: true}) private pubTmpl: TemplateRef<any>;
  @ViewChild('descriptionTmpl', {static: true}) private descriptionTmpl: TemplateRef<any>;
  @ViewChild('nameTmpl', {static: true}) private nameTmpl: TemplateRef<any>;

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router,
              public fb: UntypedFormBuilder, private overlayWindowService: OverlayWindowService, private goBack: GoBackButtonService,
              public notify: NotificationService) {
    super(http, route, router);
  }

  ngOnInit() {
    this.goBack.header = '<h1>Новости</h1>';
    this.columns = [
      {'caption': '№', 'name': 'news_id', 'sortable': false},
      {'caption': 'Заголовок', 'name': 'news_title', 'sortable': false, 'template': this.nameTmpl},
      {'caption': 'Краткое описание', 'name': 'news_description', 'sortable': false, 'template': this.descriptionTmpl},
      {'caption': 'Ссылка', 'name': 'news_url', 'sortable': false, 'template': this.urlTmpl},
      {'caption': 'Дата создания', 'name': 'news_date', 'sortable': false},
      {'caption': 'Публикация', 'name': 'public_status', 'sortable': false, 'template': this.pubTmpl},
      {'caption': '', 'template': this.buttonTmpl}
    ];
    super.ngOnInit();
  }

  ngOnDestroy() {
    if (this.createSubscription) {
      this.createSubscription.unsubscribe();
    }
    if (this.editSubscription) {
      this.editSubscription.unsubscribe();
    }
  }

  createNews() {
    this.overlayWindowService.openComponentInPopup(NewsCreateComponent)
      .then((factory: ComponentRef<NewsCreateComponent>) => {
        this.createSubscription = factory.instance.onCreate.subscribe(_ => this.loadList());
      });
  }

  deleteNews(news_id) {
    NotificationService.swalConfirm('Вы уверены?', 'Выбранная новость будет перемещена в архив')
      .then(() => {
        this.http.post('News.delete',
          {'news_id': +news_id}
        ).subscribe(
          (response) => {
            this.notify.notifySuccess('Новость успешно удалена', '', 2000);
            this.loadList();
          },
          (error) => this.notify.notifyError('Ошибка', error, 5000)
        );
      }).catch(() => false);
  }


  editNews(news_item) {
    this.overlayWindowService.openComponentInPopup(NewsEditComponent, news_item)
      .then((factory: ComponentRef<NewsEditComponent>) => {
        this.editSubscription = factory.instance.onEdit.subscribe(_ => this.loadList());
      });
  }

  public get is_archive() {
    return this._is_archive;
  }

  public set is_archive(value: any) {
    this._is_archive = !(value === 'false' || value === false);
    this.loadList();
  }

  prepareQuery() {
    const query = super.prepareQuery();
    query['filter']['is_archive'] = this._is_archive;
    return query;
  }

}
