import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { DataTableComponent } from '../../../../../modules/@datatable/components/datatable.component';
import { HttpClient } from '../../../../../services/http.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MaterialSelect2Service } from '../../../../../modules/@material-input/component/material-select2.service';
import { CalendarMapService } from '../../calendar-map.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-calendar-subagent-table',
  templateUrl: './calendar-subagent-table.component.html',
  styleUrls: ['./calendar-subagent-table.component.scss']
})
export class CalendarSubagentTableComponent extends DataTableComponent implements OnInit, OnDestroy {
  @Input() realty_list = null;
  @Output() chageTable: EventEmitter<any> = new EventEmitter();

  public columns;
  public view_list = [];
  public api_action = 'Map.dt_show_distance_subagent';
  public formGroup: UntypedFormGroup;
  private changeSubscribtion: Subscription;
  private mapSubscribtion: Subscription;
  private formSubscribtion: Subscription;

  @ViewChild('buttonTmpl')
  private button_tmpl: TemplateRef<any>;

  @ViewChild('mapTmpl')
  private mapTmpl: TemplateRef<any>;

  @ViewChild('mapTmplHead')
  private mapTmplHead: TemplateRef<any>;

  @ViewChild('socrachcaptionTmplHead')
  private socrachcaptionTmplHead: TemplateRef<any>;

  constructor(public http: HttpClient, public fb: UntypedFormBuilder, public select2Service: MaterialSelect2Service,
              public calendarMapService: CalendarMapService) {
    super(http);
    this.items_per_page = 15;
    this.formGroup = this.fb.group({
      'realty_id': [null],
      'organisation_socrachcaption': [null]
    });
    this.mapSubscribtion = this.calendarMapService.onChangeRealtyMap.subscribe((realty) => {
      this.formGroup.get('realty_id').setValue(realty);
    }, (error) => false);
    this.changeSubscribtion = this.formGroup.get('realty_id').valueChanges.subscribe((realty_id) => {
      if (realty_id) {
        this.realty_list.forEach(item => {
          if (realty_id === (item.housing_id).toString()) {
            this.calendarMapService.markerMap(item.housing_caption);
          }
        });
      }
      this.tbody.rows = null;
      this.formGroup.patchValue({
        'organisation_socrachcaption': null
      });
      this.current_page = 1;
      this.loadList();
    });
    this.formSubscribtion = this.formGroup.get('organisation_socrachcaption').valueChanges
      .pipe(debounceTime(500))
      .subscribe((data) => {
      this.loadList();
    });
  }

  ngOnInit() {
    this.columns = [
      {'name': '', 'caption': '', 'sortable': false, 'template': this.mapTmpl, 'templateHead': this.mapTmplHead},
      {'name': 'organisation_socrachcaption', 'caption': 'Субагент', 'sortable': false,
        'templateHead': this.socrachcaptionTmplHead},
      {'name': 'views_ogr', 'caption': 'Сделок', 'sortable': false},
      {'name': 'range', 'caption': 'Км.', 'sortable': false},
      {'name': '', 'caption': '', 'sortable': false, 'template': this.button_tmpl},
    ];
    super.ngOnInit();
  }

  prepareQuery() {
    const query = super.prepareQuery();
    query['realty_id'] = this.formGroup.value.realty_id;
    query['filter']['fields'] = {
      'organisation_socrachcaption': this.formGroup.value.organisation_socrachcaption,
    };
    return query;
  }

  getSubagent(value, caption) {
    this.chageTable.emit({'id': value, 'caption': caption});
    this.view_list.push(value);
  }

  getColor(value) {
    return this.view_list.indexOf(value) !== -1;
  }

  getMap(value) {
    this.calendarMapService.markerMap(value);
  }

  getMapRealty() {
    this.realty_list.forEach(item => {
      if (this.formGroup.value.realty_id === (item.housing_id).toString()) {
        this.calendarMapService.markerMap(item.housing_caption);
      }
    });
  }

  clearSelect2() {
    this.select2Service.clear();
  }

  ngOnDestroy() {
    if (this.changeSubscribtion) {
      this.changeSubscribtion.unsubscribe();
    }
    if (this.mapSubscribtion) {
      this.mapSubscribtion.unsubscribe();
    }
    if (this.formSubscribtion) {
      this.formSubscribtion.unsubscribe();
    }
  }

}
