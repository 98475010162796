import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  templateUrl: './calendar-list.component.html',
  styleUrls: ['./calendar-list.component.scss']
})
export class CalendarListComponent implements OnInit, OnDestroy {

  private subscription: Subscription;

  public list: Array<{[p: string]: string | number}> = [];

  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.subscription = this.router.events.pipe(filter(x => x instanceof NavigationEnd))
      .subscribe(_ => {
        this.list = this.activatedRoute.snapshot.data['list'];
      });
    this.list = this.activatedRoute.snapshot.data['list'];
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  whenDelete(data) {
    this.list['events'].splice(this.list['events'].indexOf(data), 1);
  }

}
