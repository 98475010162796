import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {AuthenticateService} from '../services/authenticate.service';

@Injectable()
export class AuthGuard {

  constructor(private auth: AuthenticateService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.auth.user.is_anonymous) {
      return true;
    }

    this.router.navigate(['/authenticate'], { queryParams: { returnUrl: state.url }});
    return false;
  }

}
