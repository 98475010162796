<div class="mt-4">
  <ul class="navigation__tabs" role="tablist">
    <li class="navigation__tab">
      <a class="navigation__tab__link" [routerLink]="['/mass/regl_table']"
         routerLinkActive="navigation__tab__link_active">
        Регламенты и акты
        <span class="navigation__tab__link_active__slide"></span>
      </a>
    </li>
    <li class="navigation__tab">
      <a class="navigation__tab__link" [routerLink]="['/mass/purch_temp']"
         routerLinkActive="navigation__tab__link_active">
        Условия покупки
        <span class="navigation__tab__link_active__slide"></span>
      </a>
    </li>
    <li class="navigation__tab">
      <a class="navigation__tab__link" [routerLink]="['/mass/help']"
         routerLinkActive="navigation__tab__link_active">
        Инструкция
        <span class="navigation__tab__link_active__slide"></span>
      </a>
    </li>
  </ul>
  <div class="tab-pane m-t-15">
    <router-outlet></router-outlet>
  </div>
</div>
