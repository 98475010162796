<div id="tooltip-wrapper" class="tooltip-wrapper"></div>

<main class="main-wrapper" [ngClass]="{'content-wrapper': !user.is_anonymous, 'login': user.is_anonymous}">
  <section class="content">
    <app-breadcrumbs></app-breadcrumbs>

      <div class="go_back_header">
        <ng-container *ngIf="newLink.url && !user.is_anonymous">
          <a class="icon icon_back go_back_url mr-3" (click)="goBackUrl()"></a>
        </ng-container>
        <div [innerHtml]="goBack.header"></div>
      </div>

    <router-outlet></router-outlet>
  </section>
</main>

