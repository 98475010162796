<div class="material-datatable-wrapper">
  <div class="material-datatable-wrapper__header">
    <form [formGroup]="form" class="form_crm" novalidate (submit)="submitForm()">
      <div class="row">
        <div class="col-md-3">
          <app-material-input-datepicker formControlName="event_date_from"></app-material-input-datepicker>
        </div>
        <div class="col-md-3">
          <app-material-input-datepicker formControlName="event_date_to"></app-material-input-datepicker>
        </div>
        <div class="col-md-3">
          <button type="submit" class="button button_block">Искать</button>
        </div>
      </div>
    </form>
  </div>
  <div class="table-responsive">
    <table class="table datatable" [ngClass]="cssClass">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>

<ng-template #participants let-row="row">
  <a class="block" href="javascript:void(0)" (click)="openEvent(row['event_id'])">{{row['subscriptions_count']}}</a>
</ng-template>

<ng-template #participantsApproved let-row="row">
  <a class="block" href="javascript:void(0)" (click)="openEvent(row['event_id'])">{{row['approve_subscriptions_count']}}</a>
</ng-template>
