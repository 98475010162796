import {Component, ViewChild, OnInit, OnDestroy, TemplateRef, Input, AfterViewInit} from '@angular/core';
import { HttpClient } from '../../services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataTableParamComponent } from '../../modules/@datatable/components/datatable-param.component';
import { debounceTime } from 'rxjs/operators';
import {BackrefWindowService} from '../single-window/shared/backref-window.service';
import {GoBackButtonService} from '../../services/go-back-button.service';


@Component({
  selector: 'app-views',
  templateUrl: './views.component.html',
  styleUrls: [
    './reserv-global.component.scss',
  ]
})
export class ViewsListComponent extends DataTableParamComponent implements OnInit, OnDestroy, AfterViewInit {

  public api_action = 'Data_tables.get_views_crm';
  public columns: Array<{ [key: string]: any }>;
  public formGroup: UntypedFormGroup;
  public subscription: Subscription;
  public data: any;
  private firstLoad = true;

  @ViewChild('physical_manager_fioTmpl', {static: true}) public physical_manager_fioTmpl: TemplateRef<any>;
  @ViewChild('object_captionTmpl', {static: true}) public object_captionTmpl: TemplateRef<any>;
  @ViewChild('physical_subagent_fioTmpl', {static: true}) public physical_subagent_fioTmpl: TemplateRef<any>;
  @ViewChild('appeal_client_fioTmpl', {static: true}) public appeal_client_fioTmpl: TemplateRef<any>;
  @ViewChild('organisation_captionTmpl', {static: true}) public organisation_captionTmpl: TemplateRef<any>;

  @ViewChild('housing_complex_captionHead', {static: true}) public housing_complex_captionHead: TemplateRef<any>;
  @ViewChild('object_captionHead', {static: true}) public object_captionHead: TemplateRef<any>;
  @ViewChild('physical_subagent_fioHead', {static: true}) public physical_subagent_fioHead: TemplateRef<any>;
  @ViewChild('organisation_captionHead', {static: true}) public organisation_captionHead: TemplateRef<any>;
  @ViewChild('physical_subagent_phoneHead', {static: true}) public physical_subagent_phoneHead: TemplateRef<any>;
  @ViewChild('appeal_client_phoneHead', {static: true}) public appeal_client_phoneHead: TemplateRef<any>;
  @ViewChild('appeal_client_fioHead', {static: true}) public appeal_client_fioHead: TemplateRef<any>;
  @ViewChild('physical_manager_fioHead', {static: true}) public physical_manager_fioHead: TemplateRef<any>;
  @ViewChild('stage_captionTmpl', {static: true}) private stage_captionTmpl: TemplateRef<any>;
  @ViewChild('stage_captionHead', {static: true}) private stage_captionHead: TemplateRef<any>;

  constructor(public http: HttpClient, public route: ActivatedRoute,
              private backrefWindowService: BackrefWindowService,
              public fb: UntypedFormBuilder, router: Router, public goBack: GoBackButtonService) {
    super(http, route, router);
    this.formGroup = this.fb.group({
      'date_start': [''],
      'date_end': [''],
      'date_change_start': [''],
      'date_change_end': [''],
      'tab': [''],
      'stage_caption': [''],
      'housing_complex_caption': [''],
      'object_caption': [''],
      'physical_subagent_fio': [''],
      'organisation_caption': [''],
      'physical_subagent_phone': [''],
      'appeal_client_fio': [''],
      'appeal_client_phone': [''],
      'physical_manager_fio': [''],
    });
    this.subscription = this.formGroup.valueChanges.pipe(debounceTime(500))
      .subscribe((data) => {
        this.router_param['page'] = 1;
        this.current_page = 1;
        if (this.firstLoad) {
          this.firstLoad = false;
        } else {
          this.changeForm(this.formGroup);
        }
      });
    this.updateForm(this.formGroup);
    this.backrefWindowService.routerInit();
  }

  ngOnInit() {
    this.goBack.header = '<h1>Просмотры</h1>';
    this.data = this.route.snapshot.data['data'];
    this.columns = [
      {'name': 'operation_data_date_views', 'caption': 'Дата просмотра', 'sortable': true},
      {'name': 'organisation_caption', 'caption': 'Организация', 'sortable': true, 'width': '200',
        'template': this.organisation_captionTmpl, 'templateHead': this.organisation_captionHead},
      {'name': 'physical_subagent_fio', 'caption': 'Субагент', 'sortable': true, 'width': '120',
        'template': this.physical_subagent_fioTmpl, 'templateHead': this.physical_subagent_fioHead},
      {'name': 'physical_subagent_phone', 'caption': 'Тел. субагента', 'sortable': true,
        'templateHead': this.physical_subagent_phoneHead},
      {'name': 'appeal_client_fio', 'caption': 'Клиент', 'sortable': true,
        'template': this.appeal_client_fioTmpl, 'templateHead': this.appeal_client_fioHead},
      {'name': 'appeal_client_phone', 'caption': 'Тел. клиента', 'sortable': true, 'templateHead': this.appeal_client_phoneHead},
      {'name': 'object_caption', 'caption': 'Объект', 'sortable': true,
        'template': this.object_captionTmpl, 'templateHead': this.object_captionHead},
      {'name': 'physical_manager_fio', 'caption': 'Ответственный', 'sortable': true,
        'template': this.physical_manager_fioTmpl, 'templateHead': this.physical_manager_fioHead},
      {'name': 'operation_view_status', 'caption': 'Статус', 'sortable': true},
      {'caption': 'Этап', 'name': 'stage_caption', 'sortable': true, 'template': this.stage_captionTmpl,  'templateHead': this.stage_captionHead},
    ];
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  ngAfterViewInit() {
    this.goBack.goScroll();
  }

  prepareQuery() {
    const result = super.prepareQuery();
    result['filter']['fields'] = {};
    result['filter']['fields'] = {
      'date_start': this.formGroup.value.date_start,
      'date_end': this.formGroup.value.date_end,
      'date_change_start': this.formGroup.value.date_change_start,
      'date_change_end': this.formGroup.value.date_change_end,
      'tab': this.formGroup.value.tab,
      'stage_caption': this.formGroup.value.stage_caption,
      'housing_complex_caption': this.formGroup.value.housing_complex_caption,
      'object_caption': this.formGroup.value.object_caption,
      'physical_subagent_fio': this.formGroup.value.physical_subagent_fio,
      'organisation_caption': this.formGroup.value.organisation_caption,
      'physical_subagent_phone': this.formGroup.value.physical_subagent_phone,
      'appeal_client_fio': this.formGroup.value.appeal_client_fio,
      'appeal_client_phone': this.formGroup.value.appeal_client_phone,
      'physical_manager_fio': this.formGroup.value.physical_manager_fio,
    };
    return result;
  }

  filter_state(string) {
    if (this.formGroup.value.tab === string) {
      string = '';
    }
    this.formGroup.patchValue({
      'tab': string,
    });
  }
}
