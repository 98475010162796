import {Injectable} from '@angular/core';
import {HttpClient} from '../../../services/http.service';
import {NotificationService} from '../../../services/notification.service';

@Injectable()
export class AppealsElementService {

  private readonly file_type = '1';

  constructor(private http: HttpClient, private notify: NotificationService) { }

  attachFile(appeal_id, files) {
    const request = {
      'file_ids': files.map(x => x['id']),
      'types': new Array(files.length).fill(this.file_type),
      'attach_to': ['appeal', +appeal_id]
    };

    return new Promise<void>(resolve => {
      this.http.post('Files.attach', request).subscribe(_ => resolve(), error => { });
    });
  }

}
