import {Component, ElementRef, EventEmitter, Input,
  OnDestroy, OnInit, Output, ViewChild} from '@angular/core';

import {OsmService} from '../osm.service';
import {Subscription} from 'rxjs';


@Component({
  selector: 'osm-map',
  templateUrl: './osm-map.component.html',
  styleUrls: [
    './osm-map.component.scss'
  ]
})
export class OsmMapComponent implements OnInit, OnDestroy {

  private readonly tiles = {
    'osm1': 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    'osm2': 'http://{s}.tiles.wmflabs.org/osm-no-labels/{z}/{x}/{y}.png',
    'osm3': 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png',
    'sputnik': 'https://map.spn24.ru/{z}/{x}/{y}.png?apikey=5032f91e8da6431d8605-f9c0c9a00357',
    'map': 'https://map2.spn24.ru/jawg-sunny/{z}/{x}/{y}.png?access-token=' +
    'GtnzxxDEZuRzTJjh5Pz831wffHniHUhRScCfLx1UfecuTTmvy9VUhdlfUKN7usIY',
  };

  private latitude;
  private longitude;
  private lastSubscription: Subscription;

  public ready: Promise<void>;
  public ready_resolve: (...args) => void;

  @Output() private move: EventEmitter<any> = new EventEmitter();
  @Output() private click: EventEmitter<any> = new EventEmitter();
  @Output() private mousemove: EventEmitter<any> = new EventEmitter();
  @Output() private readyMap: EventEmitter<any> = new EventEmitter();

  @Input() style = {};
  @Input() private zoom = 15;
  @Input() private options = {
    'scrollWheelZoom': false,
  };

  @Input() private set center(value: [number, number]) {
    if (!value.length || value.length !== 2) {
      throw new Error('Invalid coordinates');
    }

    this.latitude = Math.round(value[0] * 1000000) / 1000000;
    this.longitude = Math.round(value[1] * 1000000) / 1000000;
    this.setCenter();
  }

  private get center(): [number, number] {
    if (this.latitude && this.longitude) {
      return [this.latitude, this.longitude];
    }

    return null;
  }

  private map;

  @ViewChild('osmMap') private $osmMap: ElementRef<any>;

  public placemarks = [];
  public clusters = [];

  constructor(private o: OsmService) {
    this.ready = new Promise<void>(resolve => this.ready_resolve = resolve);
  }

  ngOnInit(): void {
    this.o.ready.then(L => this.whenMapReady(L));
    this.lastSubscription = this.o.lastElement.subscribe(last => {
      if (last) {
        this.readyMap.next(this.map);
      }
    });
  }

  ngOnDestroy(): void {
    this.map.off('mousemove');
    this.map.off('moveend');
    this.map.off('click');
    this.map.off('zoomend');
    this.o.dropMap(this);
    if (this.lastSubscription) {
      this.lastSubscription.unsubscribe();
    }
  }

  whenMapReady(L): void {
    if (!this.map && this.center) {
      this.map = L.map(this.$osmMap.nativeElement, this.options)
        .setView(this.center, this.zoom);
    }

    const layer = new L.TileLayer(this.tiles['map'], {
      crossOrigin: true,
      attribution: '<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank" ' +
      'class="jawg-attrib">&copy; <b>Jawg</b>Maps</a> | <a href="https://www.openstreetmap.org/copyright" ' +
      'title="OpenStreetMap is open data licensed under ODbL" target="_blank" class="osm-attrib">&copy; ' +
      'OSM contributors</a>',
      maxZoom: 18
    });
    // const layer1 = new L.TileLayer(this.tiles['osm1'], {
    //   useCache: true,
    //   crossOrigin: true
    // });
    // const layer2 = new L.TileLayer(this.tiles['osm2'], {
    //   useCache: true,
    //   crossOrigin: true
    // });
    // const layer3 = new L.TileLayer(this.tiles['osm3'], {
    //   useCache: true,
    //   crossOrigin: true
    // });

    // Uncomment lines bellow to display tiles selector

    // this.map.addControl(new L.Control.Layers({
    //   'Sputnik': sputnik,
    //   'Option 1': layer1,
    //   'Option 2': layer2,
    //   'Option 3': layer3,
    // }));

    this.map.addLayer(layer);
    this.map.on('moveend', _ => this.move.emit(this.map));
    this.map.on('click', e => this.click.emit(e));
    this.map.on('mousemove', e => this.mousemove.emit(e));
    this.map.on('zoomend', _ => this.zoom = this.map.getZoom());
    this.o.registerMap(this.map, this);
    this.ready_resolve();
  }

  redraw() {
    this.map.invalidateSize();
  }

  public setCenterMap(center, zoom = null) {
    if (zoom) {
      this.zoom = zoom;
    }

    this.center = center;
  }

  private setCenter() {
    if (this.map && this.center) {
      this.map.setView(this.center, this.zoom);
    }
  }

}
