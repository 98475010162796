import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { SingleWindowService } from '../../single-window/shared/single-window.service';
import { HttpClient } from '../../../services/http.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BackrefWindowService } from '../../single-window/shared/backref-window.service';
import { Subscription } from 'rxjs';
import { StatisticPluginService } from '../../statistic/shared/statistic-plugin.service';
import { DataTableParamComponent } from '../../../modules/@datatable/components/datatable-param.component';
import { debounceTime, filter } from 'rxjs/operators';
import {BreadcrumbsService} from '../../../shared/breadcrumbs/services';
import {GoBackButtonService} from '../../../services/go-back-button.service';

@Component({
  selector: 'app-control-import',
  templateUrl: './control-xmlon.component.html',
  styleUrls: ['./control-xmlon.component.scss']
})
export class ControlXmlonComponent extends DataTableParamComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('complex_captionHead', {static: true}) private complex_captionHead;
  @ViewChild('housing_captionHead', {static: true}) private housing_captionHead;
  @ViewChild('section_captionHead', {static: true}) private section_captionHead;
  @ViewChild('flatTmpl', {static: true}) private flatTmpl;
  @ViewChild('errorTmpl', {static: true}) private errorTmpl;

  @ViewChild('statusHead', {static: true}) private statusHead;
  @ViewChild('activeHead', {static: true}) private activeHead;

  public current_flat: any = null;
  public columns;
  public formGroup: UntypedFormGroup;
  private subscription: Subscription;
  public flat_statuses: any;
  private subscriptionForm: Subscription;
  public api_action = 'Data_tables.get_control_xml_no_sell';
  public url: any;

  constructor(public http: HttpClient, private fb: UntypedFormBuilder, public router: Router,
              public activatedRoute: ActivatedRoute, public goBack: GoBackButtonService,
              public singleWindowService: SingleWindowService, public backrefWindowService: BackrefWindowService,
              public statisticPluginService: StatisticPluginService) {
    super(http, activatedRoute, router);
    this.formGroup = this.fb.group({
      'complex_caption': [this.getField('complex_caption')],
      'housing_caption': [this.getField('housing_caption')],
      'section_caption': [this.getField('section_caption')],
      'status_id': [this.getField('status_id')],
    });
    this.flat_statuses = activatedRoute.snapshot.data['flat_statuses'];
    this.subscriptionForm = this.formGroup.valueChanges.pipe(debounceTime(500))
      .subscribe((data) => {
      this.changeForm(this.formGroup);
    });
    this.subscription = this.router.events.pipe(filter(x => x instanceof NavigationEnd))
      .subscribe((x: NavigationEnd) => {
        this.statisticPluginService.backref = x.url;
      });
    this.backrefWindowService.routerInit();
  }

  ngOnInit() {
    this.columns = [
      {'name': 'complex_caption', 'caption': 'Комплекс', 'sortable': true, 'templateHead': this.complex_captionHead},
      {'name': 'housing_caption', 'caption': 'Корпус', 'sortable': true, 'templateHead': this.housing_captionHead},
      {'name': 'section_caption', 'caption': 'Секция', 'sortable': true, 'templateHead': this.section_captionHead},
      {'name': 'time_created', 'caption': 'Дата создания', 'sortable': true},
      {'name': 'caption', 'caption': 'Квартира', 'sortable': true, 'template': this.flatTmpl},
      {'name': 'status_caption', 'caption': 'Статус', 'sortable': true,
        'templateHead': this.statusHead,},
      {'name': 'problem_caption', 'caption': 'Проблемы', 'template': this.errorTmpl},
    ];

    super.ngOnInit();
  }

  public prepareQuery() {
    const query = super.prepareQuery();
    query['filter']['fields'] = this.formGroup.value;
    return query;
  }

  public loadList() {
    super.loadList();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscriptionForm) {
      this.subscriptionForm.unsubscribe();
    }
    this.backrefWindowService.routerDestroy();
  }

  ngAfterViewInit() {
    this.goBack.goScroll();
  }

}
