
<div class="card-deck text-center mt-4">
  <app-control-index-card class="card mb-4 box-shadow"
                          *ngIf="auth.isAdmin()"
                          [title]="'Роли'"
                          [description]="'Настройка доступов к системе'"
                          [url]="'/settings/roles'"
  ></app-control-index-card>
  <app-control-index-card class="card mb-4 box-shadow"
                          *ngIf="auth.isMenu('email_exception_is')"
                          [title]="'Email исключения рассылки'"
                          [description]="'Интерфейс для email исключений почтовых рассылок'"
                          [url]="'/email-exception'"
  ></app-control-index-card>
  <app-control-index-card class="card mb-4 box-shadow"
                          *ngIf="auth.isMenu('instateka_is')"
                          [title]="'Инстатека'"
                          [description]="'Интерфейс для слежения за интеграцией с сервисом Инстатека'"
                          [url]="'/instateka'"
  ></app-control-index-card>
</div>
<div class="card-deck text-center"
     *ngIf="auth.isAdmin()"
>
  <app-control-index-card class="card mb-4 box-shadow"
                          [title]="'Ограничения объекты'"
                          [description]="'Настройка общих ограничений субагентов к проектам'"
                          [url]="'/settings/constraints-consolidate'"
  ></app-control-index-card>
  <app-control-index-card class="card mb-4 box-shadow"
                          [title]="'Ограничения субагенты'"
                          [description]="'Настройка индивидуальных ограничений субагентов к проектам'"
                          [url]="'/settings/constraints'"
  ></app-control-index-card>
</div>
