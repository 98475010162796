import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {JsonClientService} from '../../../../shared/json-client';

declare const moment;


@Injectable()
export class ReportCompareResolve {

  constructor(private j: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot, ...args): Promise<any> | any {
    const options = {
      'date_start': route.params['start'] || moment()
        .startOf('year').format('DD.MM.YYYY'),

      'date_end': route.params['stop'] || moment()
        .endOf('year').format('DD.MM.YYYY')
    };
    const params = route.queryParams['lock'];
    if (
      params === 'on_period' ||
      params === 'on_month' ||
      params === 'on_moment'
    ) {
      if (params === 'on_period') {
        options['date_start'] = route.params['start'] || moment().startOf('month').format('DD.MM.YYYY');
        options['date_end'] = route.params['stop'] || moment().endOf('month').format('DD.MM.YYYY');
      }
      options['flag'] = params;
      return this.j.post('Analytics.get_statistic_for_leads_on_period', options);
    } else {
      if (params === 'common_attraction') {
        options['bp_source'] = route.queryParams['bp_source'];
      }
      return this.j.post('Analytics.get_summary_statistic', options);
    }
  }

}
