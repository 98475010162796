import {Component, OnDestroy, OnInit, Optional} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {UsageVariant} from '../../../models/usage_variants';
import {CalendarListService} from './calendar-list.service';
import {TemplateService} from '../../../services/template.service';
import { filter } from 'rxjs/operators';
import {GoBackButtonService} from '../../../services/go-back-button.service';

declare const moment;

@Component({
  templateUrl: './calendar-list-wrapper.component.html',
  styleUrls: ['./calendar-list-wrapper.component.scss']
})
export class CalendarListWrapperComponent implements OnInit, OnDestroy {

  private subscription: Subscription;

  public crmUsage;
  public current_date;
  public month_events;

  private current_moment;

  public get year() {
    return this.current_moment.format('YYYY');
  }

  public get month() {
    return this.current_moment.format('MMMM');
  }

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
              private calendarListService: CalendarListService, private goBack: GoBackButtonService,
              @Optional() private templateService: TemplateService) { }

  onDateChoose(event_id) {
    this.calendarListService.clickEventEmitter.emit(event_id);
  }

  updME() {
    this.current_date = this.activatedRoute.firstChild.snapshot.params;

    if (-1 === Object.keys(this.current_date).indexOf('month')) {
      this.current_moment = moment();
    } else {
      this.current_moment = moment(`${this.current_date['year']}/${this.current_date['month']}`, 'YYYY/MM');
    }

    const list = this.activatedRoute.firstChild.snapshot.data['list'] || {};

    this.month_events = list['calendar'] || [];
  }

  ngOnInit() {
    this.subscription = this.router.events.pipe(filter(x => x instanceof NavigationEnd))
      .subscribe(_ => {
        this.updME();
      });
    this.updME();

    if (this.templateService) {
      this.templateService.setHeader('Календарь');
    }

    this.crmUsage = environment.usage === UsageVariant.CRM;
    if (this.crmUsage) {
      this.goBack.header = '<h1>Календарь событий на ' + this.month + ' ' + this.year + '</h1>';
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
