import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {AlternativeDatatablesComponent} from '../alternative-datatables/alternative-datatables.component';
import {AlternativeDatatablesInterface} from '../alternative-datatables/alternative-datatables.interface';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {GoBackButtonService} from '../../services/go-back-button.service';
import {BreadcrumbsService} from '../breadcrumbs/services';
import {JsonClientService} from '../json-client';
import {debounceTime} from 'rxjs/operators';
import {TemplateService} from '../../services/template.service';
import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'app-complaints-datatable',
  templateUrl: './complaints-datatable.component.html',
  styleUrls: ['./complaints-datatable.component.css']
})
export class ComplaintsDatatableComponent extends AlternativeDatatablesComponent
  implements AlternativeDatatablesInterface, OnInit, OnDestroy, AfterViewInit {

  public head;

  public filter_columns = {};
  public form: UntypedFormGroup;
  public filter: string;

  public housing_id = null;
  private parasites = true;

  public filterSubject: Subject<void> = new Subject<void>();
  private filterSubscription: Subscription;
  private formSubscription: Subscription;

  public current_page = 1;

  public count;
  public rows;

  public sort: { [key: string]: string } = {};

  public current_flat: any = null;

  public set data(value) {
    this.count = value['count'];
    this.rows = value['rows'];
  }

  private cause_list = [];
  public is_crm_part: boolean;
  private pref_archive: string;

  constructor(private router: Router, private fb: UntypedFormBuilder, public activatedRoute: ActivatedRoute,
              private template: TemplateService,
              public goBack: GoBackButtonService, private b: BreadcrumbsService,
              public http: JsonClientService, public notify: NotificationService) {
    super(router, activatedRoute, fb, http);

    this.filterSubscription = this.filterSubject
      .pipe(debounceTime(500))
      .subscribe(_ => this.onFilterChanges());
  }

  bSet() {
    this.goBack.header = '<h2>Таблица жалоб</h2>';
    this.template.setHeader('Таблица жалоб');
  }

  ngOnInit() {
    this.bSet();
    this.form = this.fb.group({
      'is_archive': [this.activatedRoute.snapshot.queryParams['is_archive'] === 'true' || false]
    });
    this.cause_list = this.activatedRoute.snapshot.data['cause'];
    this.cause_list.push({'id': null, caption: 'Причина не выбрана' });

    this.is_crm_part = this.activatedRoute.snapshot.data['is_crm_part'];

    this.head = [
      {'name': 'create_date', 'value': '', 'caption': 'Дата жалобы', 'sort': true},
      {'name': 'resale_realty_caption', 'value': '', 'caption': 'Объект на который поступила жалоба', 'sort': false, 'input': true},
      {
        'name': 'cause_caption', 'value': '', 'caption': 'Причина', 'sort': false, 'input': false, 'select': this.cause_list
      },
      {'name': 'text', 'value': '', 'caption': 'Текст жалобы', 'sort': false, 'input': false},
    ];

    if (this.is_crm_part) {
      this.head.push({'name': 'author_organisation', 'value': '', 'caption': 'Компания оставившая жалобу', 'sort': false, 'input': true});
      this.head.push({'name': 'owner_fio', 'value': '', 'caption': 'Сотрудник чей объект', 'sort': false, 'input': true});
      this.head.push({'name': 'owner_organisation', 'value': '', 'caption': 'Организация чей объект', 'sort': false, 'input': true});
      this.head.push({'name': 'author_fio', 'value': '', 'caption': 'Автор жалобы', 'sort': false, 'input': true});
      this.head.push({'name': '', 'value': '', 'caption': '', 'sort': false, 'input': false});
    }

    this.head = this.head.map(x => {
      x.value = this.activatedRoute.snapshot.queryParams[x.name] || x.value;
      return x;
    });
    if (this.activatedRoute.snapshot.queryParams['sort_name']) {
      this.sort[this.activatedRoute.snapshot.queryParams['sort_name']] = this.activatedRoute.snapshot.queryParams['sort_key'];
    } else {
      this.sort['create_date'] = 'desc';
    }

    this.formSubscription = this.form.valueChanges
      .subscribe(_ => this.filterSubject.next());

    for (const el of this.head) {
      this.form.addControl(el.name, new UntypedFormControl(el.value));
    }

    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.onInitCall();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }

    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  onFilterChanged(): void {
    this.data = this.activatedRoute.snapshot.data['data'];
    this.current_page = +this.activatedRoute.snapshot.queryParams['page'] || 1;
  }

  onFilterChanges(text = null) {

    if (text !== null) {
      this.filter = text;
    }

    this.filter_columns = {};
    for (const el of this.head) {
      if (!this.form.get(el.name).value.length) {
        continue;
      }
      this.filter_columns[el.name] = this.form.get(el.name).value;
    }
    if (this.filter !== '') {
      this.filter_columns['global_search_string'] = this.filter;
    }
    if (this.sort) {
      this.filter_columns['sort_name'] = Object.keys(this.sort)[0];
      this.filter_columns['sort_key'] = this.sort[this.filter_columns['sort_name']];
    }
    if (this.parasites) {
      return this.parasites = !this.parasites;
    }
    this.bSet();
    this.router.navigate(['complaints-datatable'], {
      queryParams: this.getQueryParams(),
    });
  }

  getQueryParams() {
    const query_params = {};

    for (const el of this.head) {
      let _val = this.form.get(el.name).value;
      if (!_val.length) {
        continue;
      }
      if (_val === 'true' || _val === 'false') {
        _val = _val === 'true';
      }
      query_params[el.name] = _val;
    }
    if (this.sort) {
      query_params['sort_name'] = Object.keys(this.sort)[0];
      query_params['sort_key'] = this.sort[query_params['sort_name']];
    }
    if (this.filter !== '') {
      query_params['global_search_string'] = this.filter;
    }
    query_params['is_archive'] = this.form.get('is_archive').value;
    return query_params;
  }

  prepareSort(column_name: string) {
    for (const key in this.sort) {
      if (column_name !== key) {
        delete this.sort[key];
      }
    }
  }

  sortChange(column_name: string) {
    this.prepareSort(column_name);
    this.sort[column_name] = !this.sort[column_name] || ('desc' === this.sort[column_name]) ? 'asc' : 'desc';
    this.onFilterChanges();
  }

  ngAfterViewInit() {
    this.goBack.goScroll();
  }

  updateComplaint(id, is_archive) {
    if (is_archive) {
      this.pref_archive = 'удалена в архив';
    } else {
      this.pref_archive = 'восстановлена';
    }
    NotificationService.swalConfirm('Вы уверены?', 'Данная жалоба на вторичный объект будет ' + this.pref_archive)
      .then(() => {
        this.http.postObs('Complaint.update_archive',
          {'complaint_id': id, 'is_archive': is_archive}
        ).subscribe(
          (response) => {
            this.notify.notifySuccess('Жалоба на вторичный объект ' + this.pref_archive, '', 2000);
            const currUrl = this.router.url.split('?')[0];
            this.router.navigate(['/blank'], {replaceUrl: true}).then(() => {
              this.router.navigate([currUrl], {replaceUrl: true});
            });
          },
          (error) => this.notify.notifyError('Ошибка', error, 5000)
        );
      }).catch(() => false);
  }
}
