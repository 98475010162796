
<form [formGroup]="form" *ngIf="form" novalidate (submit)="onSubmit()">
  <div class="row no-gutters gutters-4">
    <div class="col-12" *ngIf="!this.form.value.newsubagent">
      <app-material-select2-autocomplete formControlName="ci_subagent_id"
                                         [placeholder]="'Введите субагента'"
                                         [caption]="'Субагент'" [api_action]="'Dict.bp_ci_subagent_ac'">
      </app-material-select2-autocomplete>
      <p><span class="bold">Описание: </span> выбирается руководитель организации или организация, которую необходимо
        сделать субагентом. <span class="bold">Уже преобразованных субагентов выбрать нельзя</span></p>
    </div>

    <div class="col-12">
      <app-material-input-checkbox formControlName="newsubagent" [border]="true">Новый субагент</app-material-input-checkbox>
    </div>
    <ng-container *ngIf="this.form.value.newsubagent">
      <div class="col-12">
        <h5 class="mt-2r">Создание физ. лица</h5>
      </div>
      <div class="col-12">
        <app-physical-create-fields [(form)]="form.controls['physical']" (dataChange)="physicalData = $event"></app-physical-create-fields>
      </div>
      <div class="col-12">
        <form [formGroup]="form.get('physical')" novalidate>
          <div class="row">
            <div class="col-md-6">
              <app-material-input formControlName="user_login" [autofill]="false" [require]="true" [placeholder]="'Логин'">Логин
              </app-material-input>
            </div>
            <div class="col-md-6">
              <app-material-input formControlName="pass_new" [autofill]="false" [require]="true" [type]="'text'" [placeholder]="'Не менее 8 символов'">
                Новый пароль
              </app-material-input>
            </div>
            <div class="col-md-6">
              <app-material-input formControlName="pass_new_repeat" [autofill]="false" [require]="true" [type]="'text'" [placeholder]="'Повторите пароль'">
                Повторите пароль
              </app-material-input>
            </div>
            <div class="col-md-6">
              <app-material-select2 formControlName="organisation_post_id" [caption]="'Должность'" [require]="true">
                <option [value]="null">Выберите должность</option>
                <option [value]="post.post_id" *ngFor="let post of posts">
                  {{post.post_caption}}
                </option>
              </app-material-select2>
            </div>
          </div>
        </form>
      </div>
      <div class="col-12">
        <h5 class="mt-2r">Создание юр. лица</h5>
      </div>
      <div class="col-12">
        <ng-container *ngIf="form">
          <app-entity-create-fields [(form)]="form.controls['entity']" (dataChange)="entityData = $event"></app-entity-create-fields>
        </ng-container>
      </div>
      <div class="col-12">
        <h5 class="mt-2r">Реквизиты юр. лица</h5>
      </div>
      <div class="col-12">
        <ng-container *ngIf="form">
          <app-entity-additionally-fields [nalogForms]="nalogForms" [(form)]="form.controls['entityRequisitesData']" (postDataChange)="entityRequisitesData = $event"></app-entity-additionally-fields>
        </ng-container>
      </div>
    </ng-container>
    <div class="col-12">
      <h5 class="mt-2r">Данные лида</h5>
    </div>
    <div class="col-12">
      <app-material-select2-autocomplete formControlName="worker_id"
                                         [placeholder]="'Ответственный'"
                                         [caption]="'Ответственный'" [api_action]="'Dict.bp_ci_worker_ac'">
        <option [value]="form.value.worker_id">{{form.value.worker_caption}}</option>
      </app-material-select2-autocomplete>
    </div>
    <div class="col-12">
      <app-material-select2 formControlName="source_id" [require]="true" [caption]="'Источник лида'">
        <option *ngFor="let option of leadSources" [value]="option.id">
          {{option.caption}}
        </option>
      </app-material-select2>
    </div>
    <div class="col-sm-12">
      <app-material-select2-autocomplete formControlName="ci_source_physical_id"
                                         [require]="true"
                                         [placeholder]="'Источник - привлеченец'"
                                         [caption]="'Источник - привлеченец'" [api_action]="'Dict.bp_ci_worker_ac'">
        <option [value]="form.value.ci_source_physical_id">{{form.value.ci_source_physical_fio}}</option>
      </app-material-select2-autocomplete>
    </div>
    <div class="col-12">
      <app-material-select2 formControlName="stage_id" [require]="true" [caption]="'Этап работы с лидом'">
        <option *ngFor="let option of stage_forms; let i = index" [value]="option.id" >
          {{option.caption}}
        </option>
      </app-material-select2>
    </div>
    <div class="col-sm-12">
      <app-material-select2 formControlName="ci_subagent_area_worker_id"
                                         [require]="false"
                                         [placeholder]="'Направление работ'"
                                         [caption]="'Направление работ'">
        <option *ngFor="let option of subagentAreaWork" [value]="option.id">
          {{option.caption}}
        </option>
      </app-material-select2>
    </div>

    <ng-container *ngIf="descriptionStage">
      <div class="col-12">
        <p><span class="bold">Описание этапа: </span> {{descriptionStage}}</p>
      </div>
    </ng-container>

    <div class="col-12">
      <h5 class="mt-2r">Данные клиента</h5>
    </div>
    <div class="col-4">
      <app-material-input formControlName="firstName"
                          [placeholder]="'Имя'"
                          [require]="true">
        Имя</app-material-input>
    </div>

    <div class="col-4">
      <app-material-input formControlName="lastName"
                          [placeholder]="'Фамилия'">
        Фамилия</app-material-input>
    </div>
    <div class="col-4">
      <app-material-input formControlName="middleName"
                          [placeholder]="'Отчество'">
        Отчество</app-material-input>
    </div>
    <div class="col-6">
      <app-material-input-mask formControlName="phone" [mask]="'+7 (000) 000-00-00'" [require]="true"
                               [placeholder]="'+7 (___) ___-__-__'">
        Телефон</app-material-input-mask>
    </div>
    <div class="col-6">
      <app-material-input formControlName="email"
                          [placeholder]="'Email'">
        Email</app-material-input>
    </div>

    <div class="col-12">
      <app-material-textarea [rows]="4" [placeholder]="'Примечание'"
                             formControlName="notice">
        Комментарий к лиду
      </app-material-textarea>
    </div>
    <div class="col-12">
      <h5 class="mt-2r">Этапы бизнес процесса</h5>
    </div>
    <div class="col-6">
      <app-material-input-datepicker formControlName="next_contact_date" [require]="true"
                                     [placeholder]="'Дата следующего контакта'">
        Дата следующего контакта</app-material-input-datepicker>
    </div>
    <div class="col-6">
      <app-material-input-mask formControlName="next_contact_time"
                               [placeholder]="'__:__'" [mask]="'00:00'">
        Время следующего контакта</app-material-input-mask>
    </div>
    <div class="col-12">
      <app-material-textarea [rows]="4" [placeholder]="'Комментарий для контакта'"
                             formControlName="next_contact_comment">
        Комментарий для контакта бизнес процесса
      </app-material-textarea>
    </div>
  </div>
  <div class="row no-gutters gutters-2 mt-3">
    <div class="col-md-10">
      <button type="submit" class="button" [disabled]="!form.valid">Создать лид</button>
    </div>
  </div>
</form>
