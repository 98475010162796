
<ng-container *ngIf="header_info">
  <p class="d-inline-block mr-2r" *ngFor="let el of header_info">
    {{el.caption}}: <b>{{el.count}}</b>
  </p>
</ng-container>

<div class="statistic-plagin__wrapper">
  <div class="material-datatable-wrapper">
    <app-datatable-headline
      [global_search_string]="global_search_string"
      [items_per_page_vars]="items_per_page_vars"
      (items_per_page_change)="itemPerPageChange($event)"
      (global_search_change)="onGlobalSearch($event)"
      [templateRight]="templateRight">
    </app-datatable-headline>
    <div class="table-responsive">
      <table class="table datatable" [ngClass]="cssClass">
        <thead app-data-table-head
               [sort]="sort"
               [columns]="columns"
               (sort_emmit)="onSortChange($event)">
        </thead>
        <tbody app-data-table-body
               (rowClick)="rowClick.emit($event)"
               [loading]="true"
               [columns]="columns">
        </tbody>
      </table>
    </div>
    <app-datatable-pagination
      [current_page]="current_page"
      (change_page)="changePage($event)">
    </app-datatable-pagination>
  </div>
</div>

<ng-template #subagent_id let-row="row">
  {{row['subagent_id']}}
</ng-template>

<ng-template #organisation_caption let-row="row">
  <a [routerLink]="['/entity/edit/', row['subagent_id']]">
    {{row['organisation_caption']}}
  </a>
</ng-template>

<ng-template #frame_is_active let-row="row">
  <ng-container [ngSwitch]="row['frame_is_active']">
    <ng-container *ngSwitchCase="true">Да</ng-container>
    <ng-container *ngSwitchCase="false">Нет</ng-container>
  </ng-container>
</ng-template>

<ng-template #frame_site let-row="row">
  <ng-container *ngIf="row['correct_url']">
    <a href="{{row['correct_url']}}" target="_blank">{{row['correct_url']}}</a>
  </ng-container>
  <ng-container *ngIf="!row['correct_url']">
    {{row['frame_site']}}
  </ng-container>
</ng-template>

<ng-template #cnt_complexes let-row="row">
  <ng-container [ngSwitch]="row['cnt_complexes']">
    <ng-container *ngSwitchCase="'all'">Все</ng-container>
    <ng-container *ngSwitchDefault>{{row['cnt_complexes']}}</ng-container>
  </ng-container>
</ng-template>

<ng-template #frame_cnt_requests let-row="row">
  {{row['frame_cnt_requests']}}
</ng-template>

<ng-template #frame_cnt_appeals let-row="row">
  <a title="Просмотр" [routerLink]="['/statistic/plugin/', row.frame_www_uuid]">
    {{row['frame_cnt_appeals']}}
  </a>
</ng-template>

<ng-template #frame_remote_url let-row="row">
  <ng-container *ngIf="row['frame_cnt_appeals'].length">
    <p *ngFor="let el of row['frame_cnt_appeals']">
      {{el}}
    </p>
  </ng-container>
</ng-template>

<ng-template #button let-row="row">
  <a class="button button_icon" title="Просмотр" [routerLink]="['/statistic/plugin/', row.frame_www_uuid]">
    <i class="fa fa-fw fa-cog"></i>
  </a>
</ng-template>
