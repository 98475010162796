import { Component, ViewChild, OnInit, OnDestroy, TemplateRef, Input } from '@angular/core';
import { HttpClient } from '../../services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataTableParamComponent } from '../../modules/@datatable/components/datatable-param.component';
import { debounceTime } from 'rxjs/operators';
import {BackrefWindowService} from '../single-window/shared/backref-window.service';


@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: [
    './reserv-global.component.scss',
  ]
})
export class BookingListComponent extends DataTableParamComponent implements OnInit, OnDestroy {

    @ViewChild('physical_manager_fioTmpl', {static: true}) private physical_manager_fioTmpl: TemplateRef<any>;
    @ViewChild('object_captionTmpl', {static: true}) private object_captionTmpl: TemplateRef<any>;
    @ViewChild('physical_subagent_fioTmpl', {static: true}) private physical_subagent_fioTmpl: TemplateRef<any>;
    @ViewChild('appeal_client_fioTmpl', {static: true}) private appeal_client_fioTmpl: TemplateRef<any>;
    @ViewChild('subagent_captionTmpl', {static: true}) private subagent_captionTmpl: TemplateRef<any>;
    @ViewChild('stage_captionTmpl', {static: true}) private stage_captionTmpl: TemplateRef<any>;

    public api_action = 'Data_tables.get_deals_crm';
    public columns: Array<{ [key: string]: any }>;
    public formGroup: UntypedFormGroup;
    public current_operation_id: number;
    public tab_active = 1;
    public subscription: Subscription;
    public data: any;
    private firstLoad = true;



    constructor(public http: HttpClient, public route: ActivatedRoute,
                public fb: UntypedFormBuilder, router: Router, private backrefWindowService: BackrefWindowService) {
      super(http, route, router);
      this.formGroup = this.fb.group({
        'date_start': [''],
        'date_end': [''],
        'tab': ['']
      });
      this.subscription = this.formGroup.valueChanges.pipe(debounceTime(500))
        .subscribe((data) => {
          this.router_param['page'] = 1;
          this.current_page = 1;
          if (this.firstLoad) {
            this.firstLoad = false;
          } else {
            this.changeForm(this.formGroup);
          }
        });
      this.updateForm(this.formGroup);
      this.backrefWindowService.routerInit();
    }

  ngOnInit() {
    this.data = this.route.snapshot.data['data'];
    this.columns = [
      {'name': 'operation_create_date', 'caption': 'Дата бронирования', 'sortable': true},
      {'name': 'housing_complex_caption', 'caption': 'Название ЖК', 'sortable': true},
      {'name': 'object_caption', 'caption': 'Квартира', 'sortable': true,
        'template': this.object_captionTmpl},
      {'name': 'physical_subagent_fio', 'caption': 'Субагент', 'sortable': true, 'width': '120',
        'template': this.physical_subagent_fioTmpl},
      {'name': 'subagent_caption', 'caption': 'Организация', 'sortable': true, 'width': '200',
        'template': this.subagent_captionTmpl},
      {'name': 'physical_subagent_phone', 'caption': 'Тел. субагента', 'sortable': true},
      {'name': 'appeal_client_fio', 'caption': 'Клиент', 'sortable': true,
        'template': this.appeal_client_fioTmpl},
      {'name': 'physical_manager_fio', 'caption': 'Ответственный', 'sortable': true,
        'template': this.physical_manager_fioTmpl},
      {'caption': 'Этап', 'name': 'stage_caption', 'sortable': false, 'template': this.stage_captionTmpl},
    ];
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  prepareQuery() {
    const result = super.prepareQuery();
    result['filter']['fields'] = {};
    result['filter']['fields'] = {
      'date_start': this.formGroup.value.date_start,
      'date_end': this.formGroup.value.date_end,
      'tab': this.formGroup.value.tab,
    };
    return result;
  }

  filter_state(string) {
    if (this.formGroup.value.tab === string) {
      string = '';
    }
    this.formGroup.patchValue({
      'tab': string,
    });
  }
}
