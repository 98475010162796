import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient} from '../../../services/http.service';

@Injectable()
export class PluginResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('IFrame.detail_frame', {'token': route.params['id']});
  }
}
