<form [formGroup]="form" *ngIf="form" novalidate>
  <div class="row no-gutters gutters-4">
    <div class="col-4">
      <app-material-input formControlName="physical_fam"
                          [placeholder]="'Имя'"
                          [require]="true">
        Фамилия</app-material-input>
    </div>
    <div class="col-4">
      <app-material-input formControlName="physical_name"
                          [placeholder]="'Имя'"
                          [require]="true">
        Имя</app-material-input>
    </div>
    <div class="col-4">
      <app-material-input formControlName="physical_otch"
                          [placeholder]="'Отчество'"
                          [require]="true">
        Отчество</app-material-input>
    </div>
    <div class="col-6">
      <app-material-input formControlName="physical_email" [placeholder]="'E-mail'">E-mail</app-material-input>
    </div>
    <div class="col-6">
      <app-material-input-mask formControlName="physical_phone" [mask]="'+7 (000) 000-00-00'" [require]="true"
                               [placeholder]="'+7 (___) ___-__-__'">
        Телефон</app-material-input-mask>
    </div>
  </div>
</form>
