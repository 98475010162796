Всего: <b>{{count}}</b>
<div class="mb-2">
  <div class="row no-gutters align-items-center m-0">
    <div class="col-md-6 col-lg-6 col-xl-4">
      <div class="form__group">
        <div class="form__input">
          <input class="input" type="text" placeholder="Поиск"
                 [ngModel]="filter" (ngModelChange)="onFilterChanges($event)">
          <div class="form__input_icon icon icon_Small_Search c-pointer ng-star-inserted"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="material-datatable-wrapper table-color">
  <div class="table-responsive">
    <table class="table">
      <tr>
        <ng-container *ngFor="let el of head" >
          <ng-container *ngIf="!(activatedRoute.snapshot.queryParams['method'] === 'get_summary_statistic' && el.name === 'object_caption') && !(activatedRoute.snapshot.queryParams['method'] !== 'get_summary_statistic' && el.name === 'bp_source')">
            <th [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
              <div [formGroup]="form">
                <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
                  <div class="col table__input">
                    <ng-container *ngIf="el.name === 'next_contact_date' || el.name === 'stage'">
                      <app-material-input-datepicker formControlName="next_contact_date" [cssClass]="'mb-0'" [placeholder]="'След. контакт (созд./изм.)'">
                      </app-material-input-datepicker>
                    </ng-container>
                    <ng-container *ngIf="el.name !== 'next_contact_date' && el.name !== 'stage' && el.name !== 'bp_source'">
                      <app-material-input
                        [placeholder] = "el.caption"
                        [formControlName] = "el.name"
                        [cssClass]="'mb-0'">
                      </app-material-input>
                    </ng-container>
                    <ng-container *ngIf="el.name === 'bp_source'">
                      {{el.caption}}
                    </ng-container>
                  </div>
                  <ng-container *ngIf="el.name !== 'stage'">
                    <div class="col-auto" *ngIf="el.sort">
                      <a href="javascript:void(0)" (click)="sortChange(el.name)">
                        <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                        'icon_new-sort-down': 'asc' == sort[el.name],
                                        'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                      </a>
                    </div>
                  </ng-container>


                </div>
              </div>
            </th>
          </ng-container>
        </ng-container>
      </tr>
      <!-- // -->
      <tr *ngFor="let row of rows; let ind = index" [attr.data-color]="row.subagent_exist? 'done' : '' ">
        <td>
          {{row.stage_change_time}}
        </td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'organisation_caption-' + ind}">
            <a href="/entity/edit/{{row.organisation_id}}"
               (click)="goBack.newLink('/entity/edit/' + row.organisation_id, 'organisation_caption-' + ind, $event)">
              {{row.organisation_caption}}
            </a>
          </div>
        </td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'subagent_user_fio-' + ind}">
            <a href="/physical/edit/{{row.subagent_user_id}}"
               (click)="goBack.newLink('/physical/edit/' + row.subagent_user_id, 'subagent_user_fio-' + ind, $event)">
              {{row.subagent_user_fio}}
            </a>
            <ng-container *ngIf="row.subagent_exist">
              <p class="font-weight-bold">Был на мероприятии</p>
            </ng-container>
          </div>
        </td>
        <td>{{row['subagent_phone']}}</td>
        <ng-container *ngIf="activatedRoute.snapshot.queryParams['method'] === 'get_summary_statistic'">
          <td>
            <div [ngClass]="{'activeBox': goBack.activeHash === 'fio-' + ind}">
              <a href="/attraction/lead-info/{{row.id}}"
                 (click)="goBack.newLink('/attraction/lead-info/' + row.id, 'fio-' + ind, $event)">
                {{row.fio}}
              </a>
            </div>
          </td>
        </ng-container>
        <ng-container *ngIf="activatedRoute.snapshot.queryParams['method'] !== 'get_summary_statistic'">
          <td>
            <div [ngClass]="{'activeBox': goBack.activeHash === 'fio-' + ind}">
              <a href="/reservation/lead-info/{{row.id}}"
                 (click)="goBack.newLink('/reservation/lead-info/' + row.id, 'fio-' + ind, $event)">
                {{row.fio}}
              </a>
            </div>
          </td>
        </ng-container>
        <td>{{row['lead_phone']}}</td>
        <ng-container *ngIf="activatedRoute.snapshot.queryParams['method'] !== 'get_summary_statistic'">
          <td>
            <ng-container *ngIf="row.object_type === 'flat'">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'object_caption-' + ind}">
                <a href="/housing2/flat/{{row.object_id}}"
                   (click)="goBack.newLink('/housing2/flat/' + row.object_id, 'object_caption-' + ind, $event)">
                  {{row.object_caption}}
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="row.object_type === 'housing'">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'object_caption-' + ind}">
                <a href="/housing2/house/{{row.object_id}}"
                   (click)="goBack.newLink('/housing2/house/' + row.object_id, 'object_caption-' + ind, $event)">
                  {{row.object_caption}}
                </a>
              </div>
            </ng-container>
          </td>
        </ng-container>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'physical_manager_fio-' + ind}">
            <a href="/physical/edit/{{row.physical_manager_id}}"
               (click)="goBack.newLink('/physical/edit/' + row.physical_manager_id, 'physical_manager_fio-' + ind, $event)">
              {{row.physical_manager_fio}}
            </a>
          </div>
        </td>
        <td><app-stages-dots [data]="row.stage_information" [info]="row.stage_information.stage_changes_list"></app-stages-dots></td>
        <td>{{row['bp_source']}}</td>
      </tr>
    </table>
  </div>
</div>

<div class="alt-pagination">
  <app-paginator
    [queryParams]="filter_columns"
    [itemsPerPage]="20"
    [itemCount]="count"
    [currentPage]="current_page"
    [baseUrl]="'/analytic/inner-lead/table'">
  </app-paginator>
</div>
