import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { HttpClient } from '../../../services/http.service';
import { NotificationService } from '../../../services/notification.service';
import { filter } from 'rxjs/operators';
import {BreadcrumbsService} from '../../../shared/breadcrumbs/services';
import {GoBackButtonService} from '../../../services/go-back-button.service';




@Component({
  selector: 'app,yamap-entity-edit',
  templateUrl: './entity-edit.component.html',
  styleUrls: ['./entity-edit.component.scss']
})
export class EntityEditComponent implements OnInit, OnDestroy {

  private _data: any = null;
  private _subscription: Subscription = null;
  public currentUrl = null;
  public entityId = null;
  private routerSubscription: Subscription;

  public set data(value: any) {
    this._data = value;
  }

  public get data(): any {
    return this._data;
  }

  constructor(public activatedRoute: ActivatedRoute, public goBack: GoBackButtonService,
              public router: Router, public fb: UntypedFormBuilder,
              private http: HttpClient, public notify: NotificationService, private b: BreadcrumbsService) {

    this._subscription = this.router.events.pipe(filter(x => x instanceof NavigationEnd))
      .subscribe(x => {
        // this.data = this.activatedRoute.snapshot.data['about'];
        this.currentUrl = this.activatedRoute.firstChild.routeConfig.path;
      });
  }

  ngOnInit() {
    this.data = this.activatedRoute.snapshot.data['about'];
    this.entityId = this.activatedRoute.snapshot.params['id'];
    this.goBack.header = '<h3>Карточка юридического лица "' + (this._data['organisation_socrachcaption']
      || this._data['organisation_caption'] || this._data['organisation_komm_caption']) + '"</h3>';
    this.routerSubscription = this.router.events.subscribe((val) => {
      this.goBack.header = '<h3>Карточка юридического лица "' + (this._data['organisation_socrachcaption']
        || this._data['organisation_caption'] || this._data['organisation_komm_caption']) + '"</h3>';
    });
  }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

}
