import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { OverlayWindowService } from '../../single-window/shared/overlay-window.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HttpClient } from '../../../services/http.service';
import { Subscription } from 'rxjs';
import { DataTableParamComponent } from '../../../modules/@datatable/components/datatable-param.component';
import { BackrefWindowService } from '../../single-window/shared/backref-window.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-attraction-not-registered',
  templateUrl: './attraction-not-registered.component.html',
  styleUrls: ['./attraction-not-registered.component.scss']
})
export class AttractionNotRegisteredComponent extends DataTableParamComponent implements OnInit, OnDestroy {

  public api_action = 'Physical.get_users_castrated';
  public columns: Array<{ [key: string]: any }>;
  public formGroup: UntypedFormGroup;
  public createSubscription: Subscription;

  @ViewChild('clientTmpl', {static: true}) private clientTmpl: TemplateRef<any>;
  @ViewChild('statusTmpl', {static: true}) private statusTmpl: TemplateRef<any>;
  @ViewChild('physicalIdHead', {static: true}) private physicalIdHead: TemplateRef<any>;
  @ViewChild('physicalFio', {static: true}) private physicalFiodHead: TemplateRef<any>;
  @ViewChild('userLogin', {static: true}) private userLogindHead: TemplateRef<any>;
  @ViewChild('physicalPhone', {static: true}) private physicalPhonedHead: TemplateRef<any>;

  private subscription: Subscription;
  private firstLoad = true;

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router,
              public fb: UntypedFormBuilder, private overlayWindowService: OverlayWindowService,
              public backrefWindowService: BackrefWindowService) {
    super(http, route, router);
    this.formGroup = this.fb.group({
      'physical_id': [''],
      'physical_author': [''],
      'physical_fio': [''],
      'physical_login': [''],
      'physical_phone': [''],
    });
    this.subscription = this.formGroup.valueChanges.pipe(debounceTime(500))
      .subscribe((data) => {
        this.router_param['page'] = 1;
        this.current_page = 1;
        if (this.firstLoad) {
          this.firstLoad = false;
        } else {
          this.changeForm(this.formGroup);
        }
      });

    this.updateForm(this.formGroup);
    this.backrefWindowService.routerInit();
  }

  ngOnInit() {
    this.columns = [
      {'caption': '№', 'name': 'physical_id', 'sortable': true, 'width': '90px',
        'templateHead': this.physicalIdHead},
      {'caption': 'Имя', 'name': 'physical_fio', 'sortable': true,
        'template': this.clientTmpl, 'templateHead': this.physicalFiodHead},
      {'caption': 'Логин', 'name': 'physical_login', 'sortable': true,
        'templateHead': this.userLogindHead},
      {'caption': 'Телефон', 'name': 'physical_phone', 'sortable': true,
        'templateHead': this.physicalPhonedHead},
      {'caption': 'Дата создания', 'name': 'physical_create'},
      {'caption': 'Статус', 'template': this.statusTmpl}
    ];
    super.ngOnInit();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    super.ngOnDestroy();
    this.backrefWindowService.routerDestroy();
  }

  prepareQuery() {
    const result = super.prepareQuery();
    result['filter']['fields'] = {
      'physical_id': this.formGroup.value.physical_id,
      'physical_author': this.formGroup.value.physical_author,
      'physical_fio': this.formGroup.value.physical_fio,
      'physical_login': this.formGroup.value.physical_login,
      'physical_phone': this.formGroup.value.physical_phone,
    };

    return result;
  }

}
