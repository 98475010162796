<form class="form form_crm" [formGroup]="form" novalidate *ngIf="form">
  <div class="row no-gutters gutters-4" formGroupName="common">
    <div class="col-6">
      <app-material-input formControlName="housing_caption" [placeholder]="'Корпус'">Корпус
      </app-material-input>
      <app-material-input formControlName="section_caption" [placeholder]="'Секция'">Секция
      </app-material-input>
      <app-material-input formControlName="floor_caption" [placeholder]="'Этаж'">Этаж
      </app-material-input>
      <app-material-input formControlName="flat_num" [require]="true" [placeholder]="'Номер квартиры'">Номер квартиры
      </app-material-input>
      <app-material-input formControlName="flat_num_pp" [require]="true" [placeholder]="'Номер на площадке'">Номер на площадке
      </app-material-input>
      <app-material-input formControlName="flat_pom_type" [placeholder]="'Тип планировки (буквенное обозначение)'">
        Тип планировки (буквенное обозначение)
      </app-material-input>
      <app-material-input formControlName="flat_category" [placeholder]="'Категория'"> Категория
      </app-material-input>
      <app-material-input formControlName="flat_rooms" [require]="true" [placeholder]="'Количество комнат'">Количество комнат
      </app-material-input>
      <app-material-input formControlName="flat_price" [require]="true" [placeholder]="'Cтоимость'">Cтоимость
      </app-material-input>
      <app-material-input formControlName="flat_price_promo" [placeholder]="'Цена по акции'">Цена по акции
      </app-material-input>
    </div>
    <div class="col-6">
      <app-material-input formControlName="flat_stoimost_kv_metra" [require]="true" [placeholder]="'Cтоимость кв метра'">
        Cтоимость кв метра
      </app-material-input>
      <app-material-input formControlName="flat_plochad_obsh" [require]="true" [placeholder]="'Общая площадь'"> Общая площадь
      </app-material-input>
      <app-material-input formControlName="flat_ploshad_shil" [placeholder]="'Жилая площадь'"> Жилая площадь
      </app-material-input>
      <app-material-input formControlName="flat_ploshad_kuchni" [placeholder]="'Площадь кухни'"> Площадь кухни
      </app-material-input>
      <div class="input__group__wrapper">
        <div class="input__group__wrapper__label">Кол-во, Площадь балконов</div>
        <div class="input__group">
          <app-material-input formControlName="flat_kol_vo_balkonov" [placeholder]="'Кол-во балконов'"></app-material-input>
          <app-material-input formControlName="flat_plosch_balkonov" [placeholder]="'Площадь балконов'"></app-material-input>
        </div>
      </div>
      <div class="input__group__wrapper">
        <div class="input__group__wrapper__label">Кол-во, Площадь лоджий</div>
        <div class="input__group">
          <app-material-input formControlName="flat_kol_vo_lodjiy" [placeholder]="'Кол-во лоджий'"></app-material-input>
          <app-material-input formControlName="flat_plosch_lodjiy" [placeholder]="'Площадь лоджий'"></app-material-input>
        </div>
      </div>
      <div class="input__group__wrapper">
        <div class="input__group__wrapper__label">Коэф. балконов, лоджий</div>
        <div class="input__group">
          <app-material-input formControlName="flat_koef_balkonov" [placeholder]="'Коэф. балконов'"></app-material-input>
          <app-material-input formControlName="flat_koef_lodjiy" [placeholder]="'Коэф. лоджий'"></app-material-input>
        </div>
      </div>
      <app-material-input formControlName="flat_plochad_bez_neotaplivaemich" [placeholder]="'Площадь без н. пом'"> Площадь без н. пом
      </app-material-input>
      <div class="rooms-list" formArrayName="flat_rooms_description">
        <p class="text-bold">Комнаты</p>
        <div class="rooms-list__wrapper" *ngFor="let room of rooms.controls; index as i" [formGroupName]="i">
          <div class="rooms-list__element__1">
            <app-material-input formControlName="caption" [placeholder]="'Название помещения'">
            </app-material-input>
          </div>
          <div class="rooms-list__element__2">
            <app-material-input formControlName="area" [placeholder]="'Площадь'"></app-material-input>
          </div>
          <div class="rooms-list__element__3">
            <a (click)="dropRoom(i)"
               class="button button_icon ng-star-inserted icon-housing_remove-round" href="javascript:void(0)"></a>
          </div>
        </div>
        <p><a (click)="addRoom()" href="javascript:void(0)">Добавить комнату</a></p>
      </div>
    </div>
  </div>
</form>
