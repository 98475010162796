import {Component, OnInit, Optional} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {GoBackButtonService} from '../../../services/go-back-button.service';
import {HttpClient} from '../../../services/http.service';


@Component({
  templateUrl: './settings-roles.component.html',
  styleUrls: ['./settings-roles.component.scss']
})
export class SettingsRolesComponent implements OnInit {
  roles;

  constructor(private goBack: GoBackButtonService, private http: HttpClient, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.goBack.header = '<h1>Настройка ролей</h1>';
    this.roles = this.activatedRoute.snapshot.data['data']['roles'];
  }

  onRoleChange(key: string, role, event: any) {
    const flagPos = key.indexOf('flag');
    const data = {};
    if (flagPos !== -1) {
      const realKey = key.substring(0, flagPos + 4);
      let val = 'no';
      for (const k of ['_no_is', '_readonly_is', '_full_is']) {
        if (realKey + k === key) {
          if (event.target.checked) {
            if (k === '_readonly_is') {
              val = 'readonly';
            } else if (k === '_full_is') {
              val = 'full';
            }
          }
          continue;
        }
        role['access'][realKey + k] = false;
        // role = Object.assign({}, role);
      }
      role['access'][realKey] = val;
      data[realKey] = val;
    } else {
      data[key] = event.target.checked;
    }
    this.updateRole(role['id'], data);
  }

  updateRole(roleId, data) {
    this.http.post('Roles.update', {
      role_id: roleId,
      data: data,
    })
      .subscribe(_ => {});
  }

}
