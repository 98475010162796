// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `env-sub-dev.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import {UsageVariant} from '../app/models/usage_variants';

const protocol = 'https://';
const api_host = protocol + 'test.spn24.ru/';

export const environment = {
  test_version_raven: false,
  test_version: true,
  production: true,
  usage: UsageVariant.CRM,
  apiUrl: api_host + 'api/v1',
  apiServer: api_host,
  urlFileUpload: api_host + 'files/upload/',
  urlPriceHousing: api_host + 'restful/price/housing/',
  urlPresentationComplex: api_host + 'restful/pdf/complex/',
  urlPresentationHouse: api_host + 'restful/pdf/house/',
  urlPresentationFlat: api_host + 'restful/pdf/flat/',
  urlLead: api_host + 'restful/all_leads/',
  urlIntegration: api_host + 'restful/integration_leads/',
  urlKpi: api_host + 'restful/attraction_deal_kpi/',
  urlAttrKpi: api_host + 'restful/attraction_org_activation_kpi/',
  autocomplite: true,
  //
  commonAppUrl: `${protocol}test.spn24.ru/`,
  tinyMcePluginName: 'tinyFilesTest',
  yaMetrikaCounter: '50254170',
};
