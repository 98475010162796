<form class="form form_crm" [formGroup]="form" novalidate *ngIf="form">
  <div class="row no-gutters gutters-4" formGroupName="conditions">
    <div class="col-6">
      <app-material-input formControlName="flat_voznagr_agenta" [placeholder]="'Вознаграждение агента (ДБ)'">
        Вознаграждение агента (ДБ)</app-material-input>
      <app-material-input formControlName="flat_voznagr_agenta_from_ddu" [placeholder]="'Вознаграждение агента (ДДУ)'">
        Вознаграждение агента (ДДУ)
      </app-material-input>
    </div>
    <div class="col-6">
      <app-material-input formControlName="flat_dolya_subagenta_db" [placeholder]="'Доля субагента (ДБ)'">
        Процент субагента ДБ (Доля <span class="bold">{{ form.get('conditions').get('flat_voznagr_agenta').value | partRate : form.get('conditions').get('flat_dolya_subagenta_db').value : 'front'}}</span>)
      </app-material-input>
      <app-material-input formControlName="flat_dolya_subagenta_agd" [placeholder]="'Доля субагента (АГД)'">
        Процент субагента АГД (Доля <span class="bold">{{ form.get('conditions').get('flat_voznagr_agenta_from_ddu').value | partRate : form.get('conditions').get('flat_dolya_subagenta_agd').value : 'front'}}</span>)
      </app-material-input>
    </div>
    <div class="col-6">
      <app-material-input-datepicker formControlName="flat_srok_realisation" [placeholder]="'Срок реализации'">
        Срок реализации
      </app-material-input-datepicker>
    </div>
    <div class="col-6">
      <app-material-select2 formControlName="flat_status_id" [require]="true" [caption]="'Статус'">
        <option *ngFor="let status of flat_statuses" [value]="status.id">
          {{status.caption}}
        </option>
      </app-material-select2>
    </div>
    <div class="col-sm-12">
      <app-material-input formControlName="flat_unique_term_days" [placeholder]="'Срок уникальности клиента'">
        Срок уникальности клиента
      </app-material-input>
    </div>
    <div class="col-sm-12">
      <app-material-input-checkbox formControlName="flat_lead_transfer_capability">
        Возможность передачи лида субагентом
      </app-material-input-checkbox>
    </div>
    <div class="col-sm-12">
      <div [hidden]="!form.get('conditions').get('flat_lead_transfer_capability').value">
        <app-material-input formControlName="flat_lead_transfer_sub_percent_db" [placeholder]="'Процент субагента ДБ при передаче лида'">
          Процент субагента ДБ при передаче лида
        </app-material-input>
        <app-material-input formControlName="flat_lead_transfer_sub_percent_agd" [placeholder]="'Процент субагента АГД при передаче лида'">
          Процент субагента АГД при передаче лида
        </app-material-input>
      </div>
    </div>
  </div>
</form>

