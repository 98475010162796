import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '../../../services/http.service';
import { Subscription, filter } from 'rxjs';
import {BreadcrumbsService} from '../../../shared/breadcrumbs/services';

@Component({
  selector: 'app,yamap-physical-edit-user',
  templateUrl: './physical-edit-user.component.html',
  styleUrls: ['./physical-edit-user.component.scss']
})
export class PhysicalEditUserComponent implements OnInit, OnDestroy {

  public data: any = null;
  public roles: any = null;
  public formGroup: UntypedFormGroup;
  public physicalId = null;
  public isUser = false;
  private _subscription: Subscription = null;

  constructor(public activeRoute: ActivatedRoute, public router: Router, public fb: UntypedFormBuilder, public http: HttpClient,
              public notify: NotificationService, private b: BreadcrumbsService) { }

  ngOnInit() {
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/physical/list', caption: 'Физические лица'},
    ]);
    this.data = this.activeRoute.snapshot.data['data'];
    this.roles = this.activeRoute.snapshot.data['roles'];
    this.physicalId = this.activeRoute.snapshot.parent.params['id'];
    this.isUser = !!(this.data);
    if (this.isUser) {
      this.initEditForm();
    } else {
      this.initAddForm();
    }

    this._subscription = this.router.events.pipe(filter(x => x instanceof NavigationEnd))
      .subscribe(x => {
        this.data = this.activeRoute.snapshot.data['data'];
        this.roles = this.activeRoute.snapshot.data['roles'];
      });
  }

  initEditForm() {
      this.formGroup = this.fb.group({
        'user_active': [this.data['user_active'].toString()],
        'user_is_subagent': [this.data['user_is_subagent'].toString()],
        'user_login': [this.data['user_login']],
        'user_role_id': ['' + this.data['user_role_id']],
        'pass_new': ['', Validators.minLength(8)],
        'pass_new_repeat': ['', Validators.minLength(8)],
      });
  }

  initAddForm() {
      this.formGroup = this.fb.group({
        'user_active': ['', Validators.required],
        'user_is_subagent': ['', Validators.required],
        'user_login': ['', [Validators.required]],
        'user_role_id': ['', Validators.required],
        'pass_new': ['', [Validators.minLength(8), Validators.required]],
        'pass_new_repeat': ['', [Validators.minLength(8), Validators.required]],
      });
  }

  saveForm() {
    if (!this.formGroup.valid) {
      this.onError('Минимальная длина пароля 8 символов');
      return false;
    }
    if (this.formGroup.value.pass_new && this.formGroup.value.pass_new !== this.formGroup.value.pass_new_repeat) {
      this.onError('Пароли не совпадают');
      return false;
    }
    const post_data = {
      'user_login': this.formGroup.value.user_login,
      'user_role_id': +this.formGroup.value.user_role_id,
      'user_active': (this.formGroup.value.user_active === 'true'),
      'user_is_subagent': (this.formGroup.value.user_is_subagent === 'true'),
      'user_password': (this.formGroup.value.pass_new) ? this.formGroup.value.pass_new : null,
    };
    this.http.post('Physical.save_physical_user',
      {'physical_id': +this.physicalId, 'data': post_data}
    ).subscribe(
      (response) => this.onSuccess(response),
      (error) => { });
  }

  addForm() {
    if (!this.formGroup.valid) {
      this.onError('Заполните обязательные поля. Минимальная длина пароля 8 символов');
      return false;
    }
    if (this.formGroup.value.pass_new && this.formGroup.value.pass_new !== this.formGroup.value.pass_new_repeat) {
      this.onError('Пароли не совпадают');
      return false;
    }
    const post_data = {
      'user_login': this.formGroup.value.user_login,
      'user_role_id': +this.formGroup.value.user_role_id,
      'user_active': (this.formGroup.value.user_active === 'true'),
      'user_is_subagent': (this.formGroup.value.user_is_subagent === 'true'),
      'user_password': (this.formGroup.value.pass_new) ? this.formGroup.value.pass_new : null,
    };
    this.http.post('Physical.save_physical_user',
      {'physical_id': +this.physicalId, 'data': post_data}
    ).subscribe(
      (response) => {
        this.onSuccess('Пользователь успешно создан');
        this.data = this.formGroup.value;
        this.isUser = true;
        this.initEditForm();
      },
      (error) => { }
    );
  }

  onSuccess(response) {
    this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
  }

  onError(error) {
    this.notify.notifyError('Ошибка', error, 5000);
  }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

}
