<ul class="navigation__tabs" role="tablist">
    <li class="navigation__tab">
      <a class="navigation__tab__link" [routerLink]="['/physical/edit', physicalId, 'common']"
        [ngClass]="{'navigation__tab__link_active': 'common' === currentUrl}">
        Основное
        <span class="navigation__tab__link_active__slide"></span>
      </a>
    </li>
    <li class="navigation__tab">
      <a class="navigation__tab__link" [routerLink]="['/physical/edit', physicalId, 'additionally']"
        [ngClass]="{'navigation__tab__link_active': 'additionally' === currentUrl}">
        Дополнительно
        <span class="navigation__tab__link_active__slide"></span>
      </a>
    </li>
    <li class="navigation__tab">
      <a class="navigation__tab__link" [routerLink]="['/physical/edit', physicalId, 'organization']"
        [ngClass]="{'navigation__tab__link_active': 'organization' === currentUrl}">
        Организации
        <span class="navigation__tab__link_active__slide"></span>
      </a>
    </li>
    <li class="navigation__tab">
      <a class="navigation__tab__link" [routerLink]="['/physical/edit', physicalId, 'user']"
        [ngClass]="{'navigation__tab__link_active': 'user' === currentUrl}">
        Пользователь
        <span class="navigation__tab__link_active__slide"></span>
      </a>
    </li>
    <li class="navigation__tab">
      <a class="navigation__tab__link" [routerLink]="['/physical/edit', physicalId, 'photo']"
        [ngClass]="{'navigation__tab__link_active': 'photo' === currentUrl}">
        Фото
        <span class="navigation__tab__link_active__slide"></span>
      </a>
    </li>
    <li class="navigation__tab">
      <a class="navigation__tab__link" [routerLink]="['/physical/edit', physicalId, 'documents']"
        [ngClass]="{'navigation__tab__link_active': 'documents' === currentUrl}">
        Документы
        <span class="navigation__tab__link_active__slide"></span>
      </a>
    </li>
    <li class="navigation__tab">
      <a class="navigation__tab__link" [routerLink]="['/physical/edit', physicalId, 'certificate']"
        [ngClass]="{'navigation__tab__link_active': 'certificate' === currentUrl}">
        Мероприятия
        <span class="navigation__tab__link_active__slide"></span>
      </a>
    </li>
</ul>

<router-outlet></router-outlet>
