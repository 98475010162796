<div class="navigation" [ngClass]="cssClass">
  <div class="line-holder">
    <div class="overflow-a">
      <ul class="navigation__tabs" [ngClass]="headCssClass">
        <li class="navigation__tab" *ngFor="let tab of tabs" [ngClass]="tabClass">
          <a href="javascript:void(0)" class="navigation__tab__link" (click)="activate(tab)"
             [ngClass]="{'navigation__tab__link_active': tab.is_active}">
            {{tab.caption}}
            <span class="navigation__tab__link_active__slide"></span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="navigation__tab__content" [ngClass]="contentCssClass">
    <ng-content></ng-content>
  </div>
</div>
