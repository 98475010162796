<h1 class="mb-3" *ngIf="displayHeader">Создание юридического лица</h1>
<ng-container *ngIf="form && nalogForms">
  <app-entity-create-fields [forms]="forms" [form]="form" [types]="types" (dataChange)="data = $event"></app-entity-create-fields>
  <ng-container *ngIf="form.value.organisation_type_id !== '4'">
    <app-entity-additionally-fields [nalogForms]="nalogForms" [form]="form.controls['requisites']" (postDataChange)="entityRequisitesData = $event"></app-entity-additionally-fields>
  </ng-container>



</ng-container>
<div class="row no-gutters gutters-2 mt-3">
  <div class="col-md-10">
    <button type="submit" (click)="onSubmit()" class="button" [disabled]="!form.valid">Сохранить и закрыть</button>
  </div>
</div>

