<form [formGroup]="form">
  <div class="row">
    <div class="col-3">
      <app-material-select formControlName="user_id"
                           (onSelected)="selectUser($event)"
                           [require]="true"
                           [caption]="'Выбрать пользователя для переноса'">
        <option [value]="''">-- Не выбрано --</option>
        <option *ngFor="let physical of physicals"
                [value]="physical.organisation_owner_id">
          {{physical.organisation_owner_caption}}
        </option>
      </app-material-select>
    </div>


    <div class="col-3">
      <app-material-select2 formControlName="user_transfer_id"
        [caption]="'Выбрать пользователя для передачи дел'"
        [require]="true"
        [placeholder]="'Выбрать пользователя для передачи дел'"
      >

        <option [value]="''">-- Не выбрано --</option>
        <option
          *ngFor="let physical of physicals | filterphysicslist: physicals_user_select "
          [value]="physical.organisation_owner_id">
          {{physical.organisation_owner_caption}}
        </option>
      </app-material-select2>
    </div>

    <div class="col-6">
      <app-material-select2-autocomplete formControlName="new_subagent_id"
                                         [require]="true"
                                         [placeholder]="'Субагенты'"
                                         [caption]="'Субагенты'"
                                         [api_action]="'Dict.get_all_organisations'">
        <option
          [value]="form.value.new_subagent_id">{{form.value.new_subagent_caption}}</option>
      </app-material-select2-autocomplete>
    </div>
  </div>
  <div>
    <div class="mt-4">
      <div (click)="transferUser()"
           class="button button_main">
        Перенести пользователя
      </div>
    </div>
  </div>
</form>
