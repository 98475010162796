import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Subscription} from 'rxjs';
import {JsonClientService} from '../../shared/json-client';
import {GoBackButtonService} from '../../services/go-back-button.service';

@Component({
  selector: 'app-import-xlsx',
  templateUrl: './import-xlsx.component.html',
  styleUrls: ['./import-xlsx.component.scss']
})
export class ImportXlsxComponent implements OnInit, OnDestroy {
  public formGroup: UntypedFormGroup;
  public formGroupAuto: UntypedFormGroup;
  public formSource: UntypedFormGroup;
  public errorTable: any;
  public dict: any;
  public import_history: any;
  public load = false;
  public questInfo = [];
  public complex_list = [];
  private resourseSubscription: Subscription;
  @ViewChild('input') private input;

  constructor(public fb: UntypedFormBuilder, private http: JsonClientService, private notify: NotificationService,
              public route: ActivatedRoute, private goBack: GoBackButtonService) {
  }

  ngOnInit() {
    this.goBack.header = '<h1> Импорт из файла XLSX</h1>';
    this.formGroup = this.fb.group({
      'resourse': ['', Validators.required],
    });
    this.formGroupAuto = this.fb.group({
      'realty_id': ['', Validators.required],
      'caption': [''],
    });
    this.formSource = this.fb.group({
      'id': ['', Validators.required],
      'caption': ['', Validators.required],
    });

    this.import_history = (this.route.snapshot.data['information'] || []).map(x => {
      return Object.assign(x, {
        'url': `${environment.urlFileUpload}${x['file']}`,
      });
    });

    this.dict = this.route.snapshot.data['dict'];

    this.resourseSubscription = this.formGroup.get('resourse').valueChanges.subscribe(val => {

      if (val) {
        this.complexDeataLoad(val);
      }

    });
  }

  ngOnDestroy() {
    this.resourseSubscription.unsubscribe();
  }

  dictReload() {
    this.http.post('Import.get_category_import').then(
      response => {

        this.dict = response;

      }
    );
  }


  complexDeataLoad(val) {
    if (val) {
      this.http.post('Import.get_complex_from_import',  {'resource': val}).then(
        response => {
          this.complex_list = response.realty;
          this.questInfo = response.info;
        },
        error => { }
      );

    } else {
      this.complex_list = [{}];
    }
  }

  onFileChange(...args) {
    const file_input = this.input.nativeElement;

    if (!file_input.files || !file_input.files[0]) {
      return;
    }
    const file_to_upload = file_input.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const file_result = (event.srcElement || event.target)['result'].split(',').pop();
      const resourse = this.formGroup.value.resourse;
      const options = {
        'excel': file_result,
        'resource': this.formGroup.value.resourse,
      };
      this.load = true;
      this.http.post('Import.get_file_xlsx', options).then(
        response => {
            this.errorTable = response;
            this.load = false;
            this.notify.notifySuccess('Поздравляем', 'Успешное выполнение импорта', 3000);
            this.complexDeataLoad(resourse);
            this.input.nativeElement.value = '';
          },
        _ => {
            this.notify.notifyError('Внимание', 'Ошибка загрузки XLSX файла', 3000);
            this.load = false;
            this.input.nativeElement.value = '';
          },
      );
    };
    reader.readAsDataURL(file_to_upload);
  }

  complexDelete(id) {
    NotificationService.swalConfirm('Удалить?', 'Данные будут удалены, продолжить?')
      .then(_ => {
        this.http.post('Import.remove_complex_for_import',  {'realty_id': id}).then(
          response => {
            const val = this.formGroup.value.resourse;
            this.complexDeataLoad(val);
          },
          error => { }
        );
      }).catch(() => false);
  }

  complexActivate(id, state) {
    this.http.post('Import.set_state_complex_import',  {'realty_id': id, 'active': state}).then(
      response => {

      },
      error => { }
    );
  }

  sourceAdd() {
    this.dict = [];
    const id = this.formSource.value.id;
    const caption = this.formSource.value.caption;

    const obj = {
      'id': id,
      'caption': caption,
    }

    this.http.post('Import.add_resource_complex_import',  {'data': obj}).then(
      response => {
        this.notify.notifySuccess('Поздравляем', 'Источник успешно добавлен', 3000);
        this.dictReload();
      },
      error => { }
    );
  }

  complexAdd() {
    const id = this.formGroupAuto.value.realty_id;
    const resource = this.formGroup.value.resourse;
    const caption = this.formGroupAuto.value.caption;

    const obj = {
      'id': id,
      'resource': resource,
      'caption': caption,
      'comment': '',
    }

    this.http.post('Import.add_complex_in_import',  {'data': obj}).then(
      response => {
        this.notify.notifySuccess('Поздравляем', 'Комплекс успешно добавлен', 3000);
        this.complexDeataLoad(resource);
      },
      error => { }
    );
  }

  saveComment(id, comment) {
    this.http.post('Import.update_comments_complex_import',  {'realty_id': id, 'comment': comment}).then(
      response => {
        this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
      },
      error => { }
    );
  }


}
