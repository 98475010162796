import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import {TransferLogComponent} from "./transfer-log.component";
import {RouterModule} from "@angular/router";
import {MaterialInputModule} from "../../modules/@material-input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TransferLogResolve} from "./transfer-log.resolve";
import {PaginatorModule} from "../../shared/housing-modules/modules";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialInputModule,
    FormsModule,
    PaginatorModule,
    ReactiveFormsModule,
  ],
  declarations:[
    TransferLogComponent
  ],
  providers:[
    TransferLogResolve
  ]

})
export class TransferLogModule {

}
