<ng-container *ngIf="!refresh">
<div class="form__group" [ngClass]="cssClass">
  <div class="form__label" [class.form__label_require]="require">{{caption}}</div>
  <span class="multiselect-native-select">
    <select class="md-form-control" #select [attr.multiple]="multiple ? 'multiple' : null"
            [disabled]="disabled"
            [appMaterialSelectAutocomplete]="options"
            [appMaterialSelectAutocompleteSendSelected]="sendSelected"
            [focusOnInit]="focusOnInit"
            [api_options]="api_options"
            [apiUrl]="api_action"
            [selectedValues]="selectedValues"
            (select2_change)="onSelectChange($event)"
            [attr.data-placeholder]="placeholder"
            [preSelectedValues]="preSelectedValues"
    >
      <ng-content></ng-content>
    </select>
  </span>
</div>
</ng-container>
