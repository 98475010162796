import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableParamComponent } from '../../../modules/@datatable/components/datatable-param.component';
import { NotificationService } from '../../../services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '../../../services/http.service';
import { SingleWindowService } from '../../../_crm/single-window/shared/single-window.service';
import { CalendarStatisticService } from './calendar-statistic.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BackrefWindowService } from '../../../_crm/single-window/shared/backref-window.service';
import { OverlayWindowService } from '../../../_crm/single-window/shared/overlay-window.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {GoBackButtonService} from '../../../services/go-back-button.service';

@Component({
  selector: 'app-statistic-plugin-site',
  templateUrl: './calendar-statistic-users.component.html',
  styleUrls: ['./calendar-statistic-users.component.scss']
})

export class CalendarStatisticUsersComponent extends DataTableParamComponent implements OnInit, OnDestroy {

  public createSubscription: Subscription;
  public event_id;
  public data;
  public editable = null;
  public formGroup: UntypedFormGroup;
  public unreg: null;
  @ViewChild('presence', {static: true}) private presence;
  @ViewChild('physical_fio', {static: true}) private physical_fio;
  @ViewChild('organisation_socrachcaption', {static: true}) private organisation_socrachcaption;
  @ViewChild('physical_fio_head', {static: true}) private physical_fio_head;
  @ViewChild('organisation_socrachcaption_head', {static: true}) private organisation_socrachcaption_head;
  @ViewChild('physical_phone_head', {static: true}) private physical_phone_head;
  @ViewChild('physical_email_head', {static: true}) private physical_email_head;
  @ViewChild('presence_head', {static: true}) private presence_head;
  @ViewChild('agenstvo_head', {static: true}) private agenstvo_head;
  @ViewChild('dostup_head', {static: true}) private dostup_head;
  @ViewChild('input') private input;

  public api_action = 'Calendar.dt_event_subscriptions';

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router,
              public singleWindowService: SingleWindowService, public overlayWindowService: OverlayWindowService,
              public calendarStatisticService: CalendarStatisticService, private goBack: GoBackButtonService,
              public fb: UntypedFormBuilder, public backrefWindowService: BackrefWindowService, private notify: NotificationService) {
    super(http, route, router);
    this.formGroup = this.fb.group({
      'physical_fio': [this.getField('physical_fio')],
      'organisation_socrachcaption': [this.getField('organisation_socrachcaption')],
      'physical_phone': [this.getField('physical_phone')],
      'physical_email': [this.getField('physical_email')],
      'organisation_address': [this.getField('organisation_address')],
      'post': [this.getField('post')],
      'presence': [this.getField('presence')],
    });
    this.formGroup.valueChanges.pipe(debounceTime(500))
      .subscribe((data) => {
      this.changeForm(this.formGroup);
    });
    this.backrefWindowService.routerInit();
  }

  ngOnInit() {
    this.data = this.route.snapshot.data['data'];
    this.editable = this.data['status'];
    this.event_id = this.route.snapshot.params['id'];
    this.goBack.header = '<h1>Список участников &laquo;' + this.data.caption + '&raquo;</h1>';
    this.columns = [
      {
        'caption': 'ФИО', 'name': 'physical_fio', 'sortable': true,
        'template': this.physical_fio, 'templateHead': this.physical_fio_head
      },
      {
        'caption': 'Агентство', 'name': 'organisation_socrachcaption', 'sortable': true,
        'template': this.organisation_socrachcaption, 'templateHead': this.organisation_socrachcaption_head
      },
      {'caption': 'Адрес', 'name': 'organisation_address', 'sortable': true, 'templateHead': this.agenstvo_head},
      {'caption': 'Доступ', 'name': 'post', 'sortable': true, 'templateHead': this.dostup_head},
      {'caption': 'Телефон', 'name': 'physical_phone', 'sortable': true, 'templateHead': this.physical_phone_head},
      {'caption': 'Эл. почта', 'name': 'physical_email', 'sortable': true, 'templateHead': this.physical_email_head},
      {
        'caption': 'Присутствовал(а)', 'name': 'presence', 'sortable': true,
        'template': this.presence, 'templateHead': this.presence_head
      },
    ];
    this.http.post('Calendar.get_unreg_physical', {'event_id': this.event_id}).subscribe(
      (response) => {
        if (response.length !== 0) {
          this.unreg = response;
        }
      },
      (error) => { }
    );

    super.ngOnInit();
  }

  prepareQuery() {
    const options = super.prepareQuery();

    options['event_id'] = this.event_id;
    options['filter']['fields'] = this.formGroup.value;

    return options;
  }

  changeStatus(row, approve_is) {
    this.tbody.rows.forEach(item => {
      if (item === row) {
        item['approved'] = approve_is;
      }
    });
    if (approve_is !== null) {
      const options = {
        'event_id': this.event_id,
        'physical_id': row['physical_id'],
        'organisation_id': row['organisation_id'],
        'approve_is': approve_is,
      };
      this.http.post('Calendar.set_approve_subscription', options).subscribe();
    }
  }

  getRowApproved(row) {
    this.tbody.rows.map(item => {
      if (item === row) {
        return item['approved'];
      }
    });
    return row['approved'];
  }

  toggleStatus(is_completed) {
    const promise = is_completed ? this.displayCompleteConfirm() : this.displayDeclineConfirm();

    promise.then(_ => {
      this.http.post('Calendar.set_event_status', {'event_id': this.event_id, 'status': is_completed})
        .subscribe(__ => {
          this.router.navigateByUrl(this.calendarStatisticService.backref);
        });
    }).catch(() => false);
  }

  displayCompleteConfirm() {
    return NotificationService.swalConfirm('Внимание', 'Мероприятие будет отмечено как завершенное.' +
      ' Изменения мероприятия будут невозможны. Продолжить?');
  }

  displayDeclineConfirm() {
    return NotificationService.swalConfirm('Внимание', 'Мероприятие будет отмечено как отмененное. ' +
      ' Изменения мероприятия будут невозможны. Продолжить?');
  }

  createPhysical() {
    // this.overlayWindowService.openComponentInPopup(PhysicalCreateComponent)
    //   .then((componentRef: ComponentRef<PhysicalCreateComponent>) => {
    //     this.createSubscription = componentRef.instance.onCreate.subscribe(physical => {
    //       this.http.post('Calendar.subscribe', {'event_id': this.event_id, 'users_list': [{'id': physical.value}]})
    //         .subscribe(__ => {
    //           this.prepareQuery();
    //         });
    //     });
    //   });
  }

  backrefRoute() {
    this.router.navigateByUrl(this.calendarStatisticService.backref);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.backrefWindowService.routerDestroy();
    if (this.createSubscription) {
      this.createSubscription.unsubscribe();
    }
  }

  onFileChange(...args) {
    const file_input = this.input.nativeElement;

    if (!file_input.files || !file_input.files[0]) {
      return;
    }
    const file_to_upload = file_input.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const file_result = (event.srcElement || event.target)['result'].split(',').pop();
      const resourse = this.formGroup.value.resourse;
      const options = {
        'excel': file_result,
        'event_id': this.event_id,
      };
      this.http.post('Calendar.get_file_xlsx', options)
        .subscribe(
          (response) => {
            this.notify.notifySuccess('Поздравляем', 'Файл успешно обработан', 3000);
            const currUrl = this.router.url.split('?')[0];
            this.router.navigate(['/blank'], {replaceUrl: true}).then(() => {
              this.router.navigate([currUrl], {replaceUrl: true});
            });
          },
          (error) => {
          }
        );
    };
    reader.readAsDataURL(file_to_upload);
  }

}
