import { Directive, ElementRef, Input, HostListener, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { NgControl, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';


const MaskTemplates = [
  {symbol: '0', mask: /[0-9]/},
  {symbol: '_', mask: /\w/},
  {symbol: 'A', mask: /[A-Z]/},
  {symbol: 'a', mask: /[a-z]/},
  {symbol: 'А', mask: /[А-ЯЁ]/},
  {symbol: 'а', mask: /[а-яё]/},
  {symbol: 'Н', mask: /[abcehkmoptxy]/i}
];

@Directive({
  selector: '[inputMask]'
})
export class InputMaskDirective implements OnInit, OnDestroy {
  @Input() public inputMask: string;
  private _valueChangesSubscription: Subscription;
  private _maskTemplates = MaskTemplates;
  public _masks: any[] = null;
  private _pasteMasks: any[];
  private _element: any;
  private _pastedData: any;
  private _pastedLength: number;
  private _lastValues: string[] = [];

  private skipCheck = false;

  private state = {
    'ctrl': false,
    'alt': false,
    'meta': false,
  };

  private readonly blackListKeys = [
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
    'Backspace',
    'Delete',
  ];

  private readonly removeListKeys = [
    'Backspace',
    'Delete',
  ];

  constructor(private _elementRef: ElementRef,
              private _ngControl: NgControl) {
  }

  public ngOnInit(): void {
    this._element = this._elementRef.nativeElement;
    this._createMasks();
    this._subscribeValueChanges();
  }

  public ngOnDestroy(): void {
    if (this._valueChangesSubscription) this._valueChangesSubscription.unsubscribe();
  }

  private _subscribeValueChanges(): void {
    const control = this._ngControl.control as AbstractControl;
    this._valueChangesSubscription = control.valueChanges
      .pipe(filter((value) => value))
      .subscribe((value: string) => {

        if (this.skipCheck) {
          this.skipCheck = false;
          return;
        }

        let i;
        for (i = 0; i < value.length; i++) {
          if ((typeof this._masks[i] === 'string' && this._masks[i] !== value[i]) || (this._masks[i] instanceof RegExp && !this._masks[i].test(value[i]))) {
            this._pasteValue(value);
            break;
          }
        }
        if (value.length && i === value.length) {
          this._lastValues.push(value);
        }
      });
  }

  private _pasteValue(newValue: string): void {
    this._writeValue('');
    if (newValue.length) {
      newValue = newValue.substring(0, this._masks.length);
      for (let i = 0, symbol; i < newValue.length; i++) {
        symbol = newValue[i];
        this._pasteSymbol(symbol);
      }
      this._writeValue(this._element.value);
    }
  }

  private compileInputFromString(str: string) {
    this._pastedLength = str.length;
    for (let x = 0; x < this._pastedLength; x++) {
      const newEvent = new KeyboardEvent('keypress',  {
        'key': str.charAt(x)
      });
      this.onKeypress(newEvent);
    }
  }

  @HostListener('paste', ['$event'])
  public onPaste(event) {
    event.preventDefault();
    this._pastedData = event.clipboardData;
    this._pastedData = this._pastedData.getData('Text');
    this._pastedData = this._pastedData.replace(/\D/g, '').slice(-10);

    this.compileInputFromString(this._pastedData);
  }

  @HostListener('keydown', ['$event'])
  public onKeypress(event: KeyboardEvent): void {
    // Alt, Ctrl, Cmd excluded from global handler
    //
    // Target combinations:
    // OS Window / Linux: Ctrl + A, Ctrl + C, Ctrl + V, Cmd + A, Cmd + C,
    // MacOS X: Cmd (Meta) + A, Cmd (Meta) + C, Cmd (Meta) + V
    //
    // Disable Delete / Backspace handling
    let eventKey = event.key;
    if (eventKey === undefined) {
      eventKey = event.code.replace('Digit', '');
    }
    this.state = {
      'ctrl': event.ctrlKey,
      'alt': event.ctrlKey,
      'meta': event.metaKey,
    };

    if (this.removeListKeys.indexOf(eventKey) !== -1) {
      this._writeValue('');
      return;
    }

    if (!this.state['ctrl'] && !this.state['alt'] && !this.state['meta'] &&
        this.blackListKeys.indexOf(eventKey) === -1) {
      event.preventDefault();
      const selection = getSelection(this._element);
      const oldValue = this._element.value;

      if (selection.start === oldValue.length) {
        this._pasteSymbol(eventKey, true);
      }
      else {
        this._element.value = oldValue.substring(0, selection.start);
        const substr = eventKey + oldValue.substring(selection.end);
        let i;
        for (i = 0; i < substr.length; i++) {
          this._pasteSymbol(substr[i], true);
        }
        for (i = selection.start; typeof this._masks[i] === 'string'; i++);
        setCaretPosition(this._element, i + 1);
      }

      let value = this._element.value;
      if (value.length >= this._masks.length) {
        value = value.substring(0, this._masks.length);
      }

      this._writeValue(value);
    }
  }

  @HostListener('blur', ['$event'])
  public onBlur(event: KeyboardEvent): void {
    const oldValue = this._element.value;
    if (this._masks.length > oldValue.length) {
      this._writeValue('');
    }
  }


  private _writeValue(value: string): void {
    (this._ngControl.control as AbstractControl).setValue(value);
  }

  private _createMasks(): void {
    const output1 = this.inputMask.split('') as any[];
    const output2 = this.inputMask.split('') as any[];

    const reservedSymbols = this._maskTemplates.map((value) => value.symbol);
    const masks = this._maskTemplates.map((value) => value.mask);

    for (let i = 0, j, symbol, index; i < output1.length; i++) {
      symbol = output1[i];
      if ((index = reservedSymbols.indexOf(symbol)) > -1) {
        output1[i] = masks[index];
        output2[i] = masks[index];
      } else {
        for (j = 0; j < masks.length; j++) {
          if (masks[j].test(symbol)) {
            output2[i] = masks[j];
          }
        }
      }
    }
    this._masks = output1;
    this._pasteMasks = output2;
  }

  private _pasteSymbol(symbol: string, keypress: boolean = false): void {
    const index = this._element.value.length;
    if (index < this._masks.length) {
      const masks = keypress ? this._masks : this._pasteMasks;
      let i, mask;
      for (i = index; typeof (mask = masks[i]) === 'string'; i++) {
        this._element.value += mask;
      }
      if (mask instanceof RegExp && mask.test(symbol)) {
        this._element.value += symbol;
      }
    }
  }
}

function getSelection(target: any): { start: number, end: number } {
  const selection = {start: 0, end: 0};
  const doc = document as any;

  if (typeof target.selectionStart === 'number' && typeof target.selectionEnd === 'number') {
    selection.start = target.selectionStart;
    selection.end = target.selectionEnd;
  }
  else if (doc.selection && target.createTextRange) {
    const bookmark = doc.selection.createRange().getBookmark();
    const sel = target.createTextRange();
    const bfr = sel.duplicate();

    sel.moveToBookmark(bookmark);
    bfr.setEndPoint('EndToStart', sel);
    selection.start = bfr.text.length;
    selection.end = selection.start + sel.text.length;
  }
  return selection;
}

function setCaretPosition(target: any, pos: number): void {
  if (target.setSelectionRange) {
    target.focus();
    target.setSelectionRange(pos, pos);
  } else if (target.createTextRange) {
    const range = target.createTextRange();
    range.collapse(true);
    range.moveEnd('character', pos);
    range.moveStart('character', pos);
    range.select();
  }
}
