import {Component, OnDestroy, OnInit} from '@angular/core';
import { OverlayWindowService } from '../../../single-window/shared/overlay-window.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../services/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {JsonClientService} from '../../../../shared/json-client';
import {GoBackButtonService} from '../../../../services/go-back-button.service';
import {BreadcrumbsService} from '../../../../shared/breadcrumbs/services';

@Component({
  selector: 'app-attraction-lead-page-edit',
  templateUrl: './attraction-lead-page-edit.component.html',
  styleUrls: ['./attraction-lead-page-edit.component.scss']
})
export class AttractionLeadPageEditComponent implements OnInit, OnDestroy {

  public form: UntypedFormGroup;
  public data: any = null;
  public lead_id: any = null;
  public leadSources: any;
  public subagentAreaWork: any;
  public activeDropdown = true;
  constructor(private http: JsonClientService, private overlayWindowService: OverlayWindowService, private b: BreadcrumbsService, private router: Router,
              private fb: UntypedFormBuilder, private notify: NotificationService, private route: ActivatedRoute, private goBack: GoBackButtonService) { }

  ngOnInit() {

    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/attraction/lead-info/today', caption: 'Лиды по привлечению'},
    ]);
    this.lead_id = this.route.snapshot.params['id'];
    this.data = this.route.snapshot.data['data'];
    this.leadSources = this.route.snapshot.data['leadSources'];
    this.subagentAreaWork = this.route.snapshot.data['subagentAreaWork'];

    this.formCreate();
    this.goBack.header = '<h2>' + (this.data['ci_user_fio'] ? this.data['ci_user_fio'] : 'Лид') + '</h2>';
  }


  ngOnDestroy() {

  }

  formUpdate() {
    this.http.post('Bp.lead_ci_get', {'lead_id': this.lead_id}).then(
      response => {
        this.data = response;
        this.goBack.header = '<h2>' + (this.data['ci_user_fio'] ? this.data['ci_user_fio'] : 'Лид') + '</h2>';
      },
      error => { }
    );

  }

  formCreate() {
    const fileArr = [];
    for (const file of this.data['files']) {
      fileArr.push({'id': file.file_id, 'filename': file.file_name, 'filepath': file.file});
    }
    this.form = this.fb.group({
      'lastName': [this.data.lastName],
      'firstName': [this.data.firstName, Validators.required],
      'middleName': [this.data.middleName],
      'email': [this.data.email],
      'phone': [this.data.phone, Validators.required],
      'notice': [this.data.notice],
      'stage_id': [this.data.id],
      'source_id':  [this.data.source_id ? this.data.source_id.toString() : this.leadSources[0].id ],
      'ci_user_id': [this.data.ci_user_id],
      'ci_user_fio': [this.data.ci_user_fio],
      'ci_source_physical_id': [this.data.ci_source_physical_id, Validators.required],
      'ci_source_physical_fio': [this.data.ci_source_physical_fio],
      'ci_subagent_id': [this.data.ci_subagent_id ? this.data.ci_subagent_id.toString() : null],
      'ci_subagent_caption': [this.data.ci_subagent_caption],
      'ci_subagent_user_id': [this.data.ci_subagent_id ? this.data.ci_subagent_id.toString() : null],
      'ci_subagent_user_caption': [this.data.ci_subagent_caption],
      'worker_id': [this.data.worker_id ? this.data.worker_id.toString() : null],
      'worker_fio': [this.data.worker_fio],
      'lead_files': [fileArr],
      'ci_subagent_area_worker_id': [this.data.ci_subagent_area_worker_id ? this.data.ci_subagent_area_worker_id.toString() : this.subagentAreaWork[0].id]
    });
  }

  onSubmit() {

    const names = [];
    const files = [];
    const types = [];
    for (const file of this.form.value.lead_files) {
      names.push(file.filename);
      files.push(file.filepath);
      types.push(35);
    }

    const filesObject = {
      'names': names,
      'files': files,
      'types': types,
      'comments': [],
    }

    const data = {
      'lastName': this.form.value.lastName,
      'firstName': this.form.value.firstName,
      'middleName': this.form.value.middleName,
      'email': this.form.value.email,
      'phone': this.form.value.phone,
      'notice': this.form.value.notice,
      'source_id': this.form.value.source_id,
      'ci_subagent_id': this.form.value.ci_subagent_id,
      'worker_id': this.form.value.worker_id,
      'ci_user_id': this.form.value.ci_user_id,
      'ci_source_physical_id': this.form.value.ci_source_physical_id,
      'lead_files': filesObject,
      'ci_subagent_area_worker_id': this.form.value.ci_subagent_area_worker_id
    };

    this.http.post('Bp.lead_ci_update', {'lead_id': this.lead_id, data}).then(
      response => {
        const fio = this.form.value.lastName + ' ' + this.form.value.firstName + ' ' + this.form.value.middleName;
        this.notify.notifySuccess('Лид успешно изменён', fio, 5000);
        this.closeEdit(response);
      },
      error => { }
    );
  }

  deleteLead() {
    NotificationService.swalConfirm('Вы уверены?', 'Выбранный лид будет удален!')
      .then(() => {
        this.http.post('Bp.lead_ci_delete', {'lead_id': this.lead_id}).then(
          (response) => {
            const fio = this.form.value.lastName + ' ' + this.form.value.firstName + ' ' + this.form.value.middleName;
            this.notify.notifySuccess('Лид успешно удалён', fio, 5000);
            this.router.navigate(['/attraction/lead-info/today']);
          },
          error => { }
        );
      }).catch(() => false);
  }

  closeEdit(value) {
    this.formUpdate();
  }

}
