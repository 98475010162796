import {Component, OnDestroy, OnInit} from '@angular/core';
import { OverlayWindowService } from '../../../single-window/shared/overlay-window.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../services/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {JsonClientService} from '../../../../shared/json-client';
import {BreadcrumbsService} from '../../../../shared/breadcrumbs/services';
import {GoBackButtonService} from '../../../../services/go-back-button.service';
import {environment} from '../../../../../environments/environment';
import {Subscription} from 'rxjs';
import {AuthenticateService} from '../../../../services/authenticate.service';

@Component({
  selector: 'app-reserv-lead-page-edit',
  templateUrl: './reserv-lead-page-edit.component.html',
  styleUrls: ['./reserv-lead-page-edit.component.scss']
})
export class ReservLeadPageEditComponent implements OnInit, OnDestroy {

  public form: UntypedFormGroup;
  public data: any = null;
  public lead_id: any = null;

  public viewObj: any = null;

  public activeDropdown = true;

  public rightWindow: boolean;
  public rightWindowType: string;
  public manager_show = true;
  public boss_show = true;

  public leadSources: any;

  public buttonLink = '';
  public urlLead = environment.apiServer + 'restful/docx/subagent_doc_act/';
  public subscribtionUser: Subscription;
  public user = null;

  constructor(private http: JsonClientService, private overlayWindowService: OverlayWindowService, private goBack: GoBackButtonService, private router: Router,
              private fb: UntypedFormBuilder, private notify: NotificationService, private route: ActivatedRoute, private b: BreadcrumbsService, private auth: AuthenticateService) { }

  ngOnInit() {

    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/reservation/lead-info/today', caption: 'Обращения'},
    ]);

    this.subscribtionUser = this.auth.emit_user.subscribe((user) => this.user = user);
    this.lead_id = this.route.snapshot.params['id'];
    this.data = this.route.snapshot.data['data'];
    this.leadSources = this.route.snapshot.data['leadSources'];
    this.goBack.header = '<h2>' + (this.data['sale_subagent_user_fio'] ? this.data['sale_subagent_user_fio'] : 'Лид') + '</h2>';
    this.buttonLink = '/' + this.lead_id;
    this.formCreate();
  }


  ngOnDestroy() {
    if (this.subscribtionUser) {
      this.subscribtionUser.unsubscribe();
    }
  }

  formUpdate() {

    this.http.post('Bp.lead_sale_get', {'lead_id': this.lead_id}).then(
      response => {
        this.data = response;
        this.goBack.header = '<h2>' + this.data['sale_subagent_user_fio'] + '</h2>';
      },
      error => { }
    );

  }

  formCreate() {
    const fileArr = [];
    for (const file of this.data['files']) {
      fileArr.push({'id': file.file_id, 'filename': file.file_name, 'filepath': file.file});
    }
    this.form = this.fb.group({
      'lastName': [this.data.lastName],
      'firstName': [this.data.firstName, Validators.required],
      'middleName': [this.data.middleName],
      'email': [this.data.email],
      'phone': [this.data.phone, Validators.required],
      'notice': [this.data.notice],
      'stage_id': [this.data.id],
      'source_id': [this.data.source_id ? this.data.source_id.toString() : this.leadSources[0].id ],
      'sale_realty_caption': [this.data.sale_realty_caption],
      'sale_realty_id': [this.data.sale_realty_id],
      'sale_realty_flat_id': [this.data.sale_realty_flat_id],
      'sale_realty_flat_caption': [this.data.sale_realty_flat_caption],
      'sale_appeal_fio': [this.data.sale_appeal_fio],
      'sale_appeal_id': [this.data.sale_appeal_id ? this.data.sale_appeal_id.toString() : null],
      'sale_appeal_operations': [this.data.sale_appeal_operations],
      'sale_realty_status_caption': [this.data.sale_realty_status_caption],
      'sale_client_fio': [this.data.sale_client_fio],
      'sale_realty_price': [this.data.sale_realty_price],
      'sale_realty_price_promo': [this.data.sale_realty_price_promo],
      'sale_subagent_user_id': [this.data.sale_subagent_user_id],
      'sale_subagent_user_fio': [this.data.sale_subagent_user_fio],
      'sale_client_id': [this.data.sale_client_id],
      'sale_sales_manager_fio': [this.data.sale_sales_manager_fio],
      'sale_sales_manager_id': [this.data.sale_sales_manager_id],
      'sale_developer_manager_id': [this.data.sale_developer_manager_id],
      'sale_developer_manager_boss_id': [this.data.sale_developer_manager_boss_id],
      'sale_developer_manager_fio': [this.data.sale_developer_manager_fio],
      'sale_developer_manager_boss_fio': [this.data.sale_developer_manager_boss_fio],
      'realty_id': [this.data.realty_id],
      'realty_caption': [this.data.realty_caption],
      'worker_id': [this.data.worker_id ? this.data.worker_id.toString() : null],
      'worker_fio': [this.data.worker_fio],

      'db_num': [this.data.sale_deal_data ? this.data.sale_deal_data.db_num : ''],
      'db_date': [this.data.sale_deal_data ? this.data.sale_deal_data.db_date : ''],
      'ddu_num': [this.data.sale_deal_data ? this.data.sale_deal_data.ddu_num : ''],
      'ddu_date': [this.data.sale_deal_data ? this.data.sale_deal_data.ddu_date : ''],
      'ddu_payment_date': [this.data.sale_deal_data ? this.data.sale_deal_data.ddu_payment_date : ''],
      'commission_developer': [this.data.sale_deal_data ? this.data.sale_deal_data.commission_developer : ''],

      'flat_deal_num': [this.data.sale_deal_data ? this.data.sale_deal_data.flat_deal_num : ''],
      'square': [this.data.sale_deal_data ? this.data.sale_deal_data.square : ''],
      'price': [this.data.sale_deal_data ? this.data.sale_deal_data.price : ''],
      'commission_proc': [this.data.sale_deal_data ? this.data.sale_deal_data.commission_proc : ''],
      'commission': [this.data.sale_deal_data ? this.data.sale_deal_data.commission : ''],
      'commission_subagent': [this.data.sale_deal_data ? this.data.sale_deal_data.commission_subagent : ''],
      'commission_spn': [this.data.sale_deal_data ? this.data.sale_deal_data.commission_spn : ''],
      'annotation': [this.data.sale_deal_data ? this.data.sale_deal_data.annotation : ''],
      'sale_lead_transfer': [this.data.sale_lead_transfer ? 'true' : 'false'],
      'sale_eat_id': [this.data.sale_eat_id || ''],

      'lead_files': [fileArr],
    });
    if (this.data.sale_eat_id) {
      this.form.get('sale_eat_id').disable();
    }
  }

  onSubmit() {

    const names = [];
    const files = [];
    const types = [];
    for (const file of this.form.value.lead_files) {
      names.push(file.filename);
      files.push(file.filepath);
      types.push(35);
    }

    const filesObject = {
      'names': names,
      'files': files,
      'types': types,
      'comments': [],
    };

    const data = {
      'lastName': this.form.value.lastName,
      'firstName': this.form.value.firstName,
      'middleName': this.form.value.middleName,
      'email': this.form.value.email,
      'phone': this.form.value.phone,
      'notice': this.form.value.notice,
      'source_id': this.form.value.source_id,
      'worker_id': this.form.value.worker_id,
      'sale_realty_id': this.form.value.sale_realty_id,
      'sale_sales_manager_fio': this.form.value.sale_sales_manager_fio,
      'sale_sales_manager_id': this.form.value.sale_sales_manager_id,
      'sale_developer_manager_id': this.form.value.sale_developer_manager_id,
      'sale_developer_manager_boss_id': this.form.value.sale_developer_manager_boss_id,
      'sale_subagent_user_id': this.form.value.sale_subagent_user_id,
      'sale_eat_id': this.form.value.sale_eat_id,
      'sale_lead_transfer': this.form.value.sale_lead_transfer === 'true' ? true : false,

      'sale_deal_data': {
        'db_num': this.form.value.db_num,
        'db_date': this.form.value.db_date,
        'ddu_num': this.form.value.ddu_num,
        'ddu_payment_date': this.form.value.ddu_payment_date,
        'ddu_date': this.form.value.ddu_date,
        'commission_developer': this.form.value.commission_developer,
        'flat_deal_num': this.form.value.flat_deal_num,
        'square': this.form.value.square,
        'price': this.form.value.price,
        'commission_proc': this.form.value.commission_proc,
        'commission': this.form.value.commission,
        'commission_subagent': this.form.value.commission_subagent,
        'commission_spn': this.form.value.commission_spn,
        'annotation': this.form.value.annotation,
      },
      'lead_files': filesObject,
    };

    this.http.post('Bp.lead_sale_update', {'lead_id': this.lead_id, data}).then(
      response => {
        const fio = this.form.value.lastName + ' ' + this.form.value.firstName + ' ' + this.form.value.middleName;
        this.notify.notifySuccess('Лид успешно изменён', fio, 5000);
        this.closeEdit(response);
      },
      error => { }
    );
  }

  deleteLead() {
    NotificationService.swalConfirm('Вы уверены?', 'Выбранный лид будет удален!')
      .then(() => {
        this.http.post('Bp.lead_sale_delete', {'lead_id': this.lead_id}).then(
          (response) => {
            const fio = this.form.value.lastName + ' ' + this.form.value.firstName + ' ' + this.form.value.middleName;
            this.notify.notifySuccess('Лид успешно удалён', fio, 5000);
            this.router.navigate(['/reservation/lead-info/today']);
          },
          error => { }
        );
      }).catch(() => false);
  }

  closeEdit(value) {
    this.formUpdate();
  }

  addNewManager(type) {
    this.rightWindowType = type;
    this.rightWindow = true;
  }

  viewConfirm(type, view_date, view_time, operation_id) {
    this.viewObj = {
      'view_date': view_date,
      'view_time': view_time,
      'operation_id': operation_id,
    }
    this.rightWindowType = type;
    this.rightWindow = true;
  }
  managerAdded(response) {
    switch (this.rightWindowType) {
      case 'manager':
        this.manager_show = false;
        this.form.patchValue({sale_developer_manager_fio: response.fio});
        this.form.patchValue({sale_developer_manager_id: response.id});
        setTimeout( _ => {
          this.manager_show = true;
        }, 0);
        break;
      case 'boss':
        this.boss_show = false;
        this.form.patchValue({sale_developer_manager_boss_fio: response.fio});
        this.form.patchValue({sale_developer_manager_boss_id: response.id});
        setTimeout( _ => {
          this.boss_show = true;
        }, 0);
        break;
    }
    this.onClosedRight();
  }
  viewChanged(response) {
    this.onClosedRight();
    this.formUpdate();
  }
  onClosedRight() {
    this.rightWindow = false;
    this.rightWindowType = null;
  }

}
