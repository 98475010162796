import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {DictionariesService} from '../../services/dictionaries.service';

@Injectable()
export class DictionaryNationalityResolver {

  constructor(private dict_helper: DictionariesService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.dict_helper.get('Dict.get_nationality');
  }

}
