import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '../../../services/http.service';
import { OverlayWindowService } from '../../single-window/shared/overlay-window.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app,yamap-physical-create',
  templateUrl: './physical-create.component.html',
  styleUrls: ['./physical-create.component.scss']
})
export class PhysicalCreateComponent implements OnInit {

  public title = 'Создание физического лица';

  @Input('displayHeader') public displayHeader = true;
  @Output('onCreate') public onCreate: EventEmitter<any> = new EventEmitter<any>();

  public form: UntypedFormGroup;
  public data: any;

  constructor(private http: HttpClient, private overlayWindowService: OverlayWindowService,
              private fb: UntypedFormBuilder, private notify: NotificationService) {
  }

  ngOnInit() {
    this.form = this.fb.group({});
  }

  onSubmit() {
    const data = {
      'data': this.data
    };
    this.http.post('Physical.physical_create', data).subscribe(
      response => {
        const fio = this.form.value.physical_fam + ' ' + this.form.value.physical_name + ' ' + this.form.value.physical_otch;
        this.notify.notifySuccess('Физическое лицо успешно создано', fio, 5000, '/physical/edit/' + response);
        this.onCreate.emit({'value': response, 'description': fio});
        this.overlayWindowService.closeHost();
      },
      error => { }
    );
  }

}
