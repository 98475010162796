import {Component, OnDestroy} from '@angular/core';
import {OutdateVersionService} from './outdate-version.service';
import {Subscription, timer} from 'rxjs';
import {map, take} from 'rxjs/operators';

@Component({
  selector: 'app-outdate-component',
  templateUrl: './outdate-version.component.html',
  styleUrls: [
    './outdate-version.component.scss'
  ]
})
export class OutdateVersionComponent implements OnDestroy {

  public countDown;
  private count = 60;

  public set version(value) {
    if (!this.last_version) {
      this.last_version = value;
    }

    if (this.last_version !== value) {
      this.reload_warn = true;
      this.last_version = value;

      this.countDown = timer(0, 1000).pipe(
        take(this.count),
        map(() => --this.count),
      );

      setTimeout(_ => this.reloadNow(), 60000);
    }
  }

  private subscription: Subscription;
  public last_version;
  public reload_warn = false;

  constructor(private s: OutdateVersionService) {
    this.subscription = this.s.version_emitter.subscribe(value => this.version = value);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  reloadNow() {
    location.reload();
  }

}
