import {AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output} from '@angular/core';

declare const jQuery;

@Directive({
  selector: '[appInitializeDatepicker]'
})
export class MaterialInputDatePickerDirective implements AfterViewInit, OnDestroy {

  @Output('onDateSelect') public onDateSelect: EventEmitter<string> = new EventEmitter();

  constructor(private elementRef: ElementRef) { }

  ngAfterViewInit() {
    jQuery(this.elementRef.nativeElement).datepicker({
      todayHighlight: true,
      language: 'ru',
      autoclose: true,
      defaultDate: this.elementRef.nativeElement.value,
    })
    .on('changeDate', (e) => {
      this.onDateSelect.emit(e);
    });
  }

  ngOnDestroy() {
    jQuery(this.elementRef.nativeElement).datepicker('destroy');
  }

}
