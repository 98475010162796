import {NgModule} from '@angular/core';
import {HousingCatalogComponent} from './housing-catalog.component';
import {HousingCatalogAboutComponent} from './housing-catalog-about.component';
import {RouterModule} from '@angular/router';
import {TabsModule} from '../../../modules/@tabs/tabs.module';
import {CommonModule} from '@angular/common';
import {HousingCatalogBuyCondsComponent} from './housing-catalog-buy-conds.component';
import {FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialInputModule} from '../../../modules/@material-input/material-input.module';
import {HousingCatalogPhotoreportComponent} from './housing-catalog-photoreport.component';
import {HousingCatalogResolve} from './housing-catalog.resolve';
import {HousingCatalogContactsComponent} from './housing-catalog-contacts.component';
import {HousingPhotoreportsResolve} from './housing-catalog-photoreport.resolve';
import {HousingCatalogPhotoreportEditComponent} from './housing-catalog-photoreport-edit.component';
import {HousingCatalogPhotoreportCreateComponent} from './housing-catalog-photoreport-create.component';
import {HousingCatalogDocumentsComponent} from './housing-catalog-documents.component';
import {HousingCatalogDocumentsResolve} from './housing-catalog-documents.resolve';

@NgModule({
    imports: [
        RouterModule,
        TabsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialInputModule,
    ],
    declarations: [
        HousingCatalogComponent,
        HousingCatalogAboutComponent,
        HousingCatalogBuyCondsComponent,
        HousingCatalogPhotoreportComponent,
        HousingCatalogContactsComponent,
        HousingCatalogPhotoreportEditComponent,
        HousingCatalogPhotoreportCreateComponent,
        HousingCatalogDocumentsComponent,
    ],
    exports: [
        HousingCatalogComponent,
        HousingCatalogAboutComponent,
        HousingCatalogBuyCondsComponent,
        HousingCatalogPhotoreportComponent,
        HousingCatalogContactsComponent,
        HousingCatalogDocumentsComponent,
    ],
    providers: [
        HousingCatalogResolve,
        HousingPhotoreportsResolve,
        HousingCatalogDocumentsResolve,
    ]
})
export class HousingCatalogModule { }
