import {Component, ElementRef, forwardRef, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {SimpleTableComponent} from './simple-table.component';

@Component({
  selector: 'app-simple-table-th',
  templateUrl: './simple-table-header.component.html',
  styleUrls: ['./simple-table-header.component.scss']
})
export class SimpleTableHeaderComponent implements OnInit, OnDestroy {

  @Input('name') public name: string;

  constructor(@Inject(forwardRef(() => SimpleTableComponent)) private parent: SimpleTableComponent,
              public elementRef: ElementRef) { }

  ngOnInit() {
    this.parent.registerHeader(this);
  }

  ngOnDestroy() {
    this.parent.unregisterHeader(this);
  }

}

