<div *ngIf="load_data; else formData">
  <i class="fa fa-spin fa-spinner mr-2"></i>
  <span class="text-normal">Загрузка информации о фиде.</span>
</div>
<ng-template #formData>
<form class="form form_crm" [formGroup]="formGroup" novalidate>
  <div class="row no-gutters gutters-4 align-items-center">
    <div class="col-6">
      <app-material-input formControlName="caption" [placeholder]="'Название фида'" [require]="true">
        Название
      </app-material-input>
    </div>
    <div class="col-6">
      <app-material-select formControlName="periodic" [cssClass]="''" [caption]="'Периодичность обновления'"
                           [require]="true">
        <option [value]="''">-- Не выбрано --</option>
        <option *ngFor="let periodic of periodic_list" [value]="periodic.id">{{periodic.caption}}</option>
      </app-material-select>
    </div>
  </div>
  <h2 class="mt-4">Общие настройки импорта квартир</h2>
  <div class="row no-gutters gutters-4 align-items-center mt-2">
    <div class="col-12 caption mb-2 mt-2">Цены</div>
    <div class="col-6">
      <app-material-input-radio-button formControlName="settings_type_prices"
                                       [data]="types" [labelClass]="''">Обновлять данные
      </app-material-input-radio-button>
    </div>
  </div>
  <div class="row no-gutters gutters-4 align-items-center mt-2">
    <div class="col-12 caption mb-2 mt-2">Статусы квартир (в продаже, бронь и т.п.)</div>
    <div class="col-6">
      <app-material-input-radio-button formControlName="settings_type_statuses"
                                       [data]="types" [labelClass]="''">Обновлять данные
      </app-material-input-radio-button>
    </div>
  </div>
  <div class="row no-gutters gutters-4 align-items-center mt-2">
    <div class="col-12 caption mb-2 mt-2">Площади</div>
    <div class="col-6">
      <app-material-input-radio-button formControlName="settings_type_squares"
                                       [data]="types" [labelClass]="''">Обновлять данные
      </app-material-input-radio-button>
    </div>
  </div>
  <div class="row no-gutters gutters-4 align-items-center mt-2">
    <div class="col-12 caption mb-2 mt-2">Планировки</div>
    <div class="col-6">
      <app-material-input-radio-button formControlName="settings_type_plans"
                                       [data]="types" [labelClass]="''">Обновлять данные
      </app-material-input-radio-button>
    </div>
  </div>
  <div class="row no-gutters gutters-4 align-items-center mt-2">
    <div class="col-12 caption mb-2 mt-2">Структурные данные (этажи, номера на площадке и т.п.)</div>
    <div class="col-6">
      <app-material-input-radio-button formControlName="settings_type_structure"
                                       [data]="types" [labelClass]="''">Обновлять данные
      </app-material-input-radio-button>
    </div>
  </div>
  <div class="row no-gutters gutters-4 align-items-center mt-2 end_block_padding">
    <div class="col-12 caption mb-2 mt-2">Названия</div>
    <div class="col-6">
      <app-material-input-radio-button formControlName="settings_type_captions"
                                       [data]="types" [labelClass]="''">Обновлять данные
      </app-material-input-radio-button>
    </div>
  </div>
  <div class="row no-gutters gutters-4 align-items-center mt-4 mb-4">
    <div class="col-6 ">
      <button (click)="saveForm()" type="button" [disabled]="loading"
              class="button fixed_buttons_block_main" [ngClass]="{'button_disabled': loading}">
        Сохранить
      </button>
    </div>
    <div class="col-6 ">
      <button (click)="onDelete()" type="button" [disabled]="loading"
              class="button fixed_buttons_block_main right" [ngClass]="{'button_disabled': loading}">
        Удалить фид
      </button>
    </div>
  </div>
</form>
</ng-template>
