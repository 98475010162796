<div class="row mb-3">
  <div class="col-6">
    <div class="mb-2">
      <span class="bold">Организация: </span>
      <a [routerLink]="['/entity/edit/', plugin['subagent_id']]">{{plugin['organisation_caption']}}</a>
    </div>
    <div class="mb-2">
      <span class="bold">Сайт: </span>
      <ng-container *ngIf="plugin['correct_url']">
        <a href="{{plugin['correct_url']}}" target="_blank">{{plugin['correct_url']}}</a>
      </ng-container>
      <ng-container *ngIf="!plugin['correct_url']">
        {{plugin['site_url']}}
      </ng-container>
    </div>
    <div class="mb-2">
      <span class="bold">Включен: </span>
      {{plugin['is_active'] == true ? 'Да' : 'Нет'}}
    </div>
  </div>
  <div class="col-6">
    <!--<div>{{plugin['constrains']}}</div>-->
    <div class="mb-2">
      <span class="bold">Кол-во запросов: </span>
      {{plugin['cnt_all_request']}}
    </div>
    <div class="mb-2">
      <span class="bold">Кол-во заявок: </span>
      {{plugin['cnt_frame_appeal']}}
    </div>
  </div>
</div>
<div class="statistic-plagin__wrapper">
  <div class="material-datatable-wrapper">
    <app-datatable-headline
      [global_search_string]="global_search_string"
      [items_per_page_vars]="items_per_page_vars"
      (items_per_page_change)="itemPerPageChange($event)"
      (global_search_change)="onGlobalSearch($event)"
      [templateRight]="templateRight">
    </app-datatable-headline>
    <div class="table-responsive">
      <table class="table datatable">
        <thead app-data-table-head
               [sort]="sort"
               [columns]="columns"
               (sort_emmit)="onSortChange($event)">
        </thead>
        <tbody app-data-table-body
               (rowClick)="rowClick.emit($event)"
               [loading]="true"
               [columns]="columns">
        </tbody>
      </table>
    </div>
    <app-datatable-pagination
      [current_page]="current_page"
      (change_page)="changePage($event)">
    </app-datatable-pagination>
  </div>
</div>

<ng-template #date_appeal let-row="row">
  {{row['date_appeal']}}
</ng-template>

<ng-template #client_fio let-row="row">
  {{row['client_fio']}}
</ng-template>

<ng-template #client_phone let-row="row">
  {{row['client_phone']}}
</ng-template>

<ng-template #client_email let-row="row">
  {{row['client_email']}}
</ng-template>

<ng-template #flat_address let-row="row">
  {{row['flat_address']}}
</ng-template>

<ng-template #flat_caption let-row="row">
  <a [routerLink]="['/newly/flat/', row['realty_id']]">{{row['flat_caption']}}</a>
</ng-template>

<ng-template #flat_price let-row="row">
  <span [innerHTML]="row['flat_price'] | priceRank:'md'"></span>
</ng-template>
