<ng-container *ngIf="pages_count && pages_count > 1">
  <ul class="pagination">
    <ng-container *pageFor="let page of [pages, queryParams]; queryParams as queryParams">
      <li class="paginate_button page-item ng-star-inserted"
        [ngClass]="{'active': page['is_active']}">
        <ng-container *ngIf="page['is_url']">
          <a class="page-link button ng-star-inserted"
             [routerLink]="baseUrl"
             [queryParams]="queryParams">
            {{page['caption']}}
          </a>
        </ng-container>
        <ng-container *ngIf="!page['is_url']">
          <a class="page-link button ng-star-inserted" href="javascript:void(0)">
            {{page['caption']}}
          </a>
        </ng-container>
      </li>
    </ng-container>
  </ul>
</ng-container>
