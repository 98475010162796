import { NgModule } from '@angular/core';
import {ContactsUnregComponent} from './contacts-unreg.component';
import {ContactsUnregResolve} from './contacts-unreg.resolve';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MaterialInputModule} from '../../modules/@material-input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PaginatorModule} from '../../shared/housing-modules/modules';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialInputModule,
    FormsModule,
    PaginatorModule,
    ReactiveFormsModule,
  ],
  declarations:[
    ContactsUnregComponent,
  ],
  providers:[
    ContactsUnregResolve,
  ]
})

export class ContactsUnregModule {

}
