<form class="form form_crm" [formGroup]="form" novalidate *ngIf="form && posts">
  <div class="relationships__wrapper" formGroupName="physicals">
    <div formArrayName="relationships"
         *ngFor="let item of relationships.controls; index as i;">
      <div [formGroupName]="i" class="relationships__element">
        <div class="row">
          <div class="col-6">
            <app-material-select2-autocomplete
              #select
              formControlName="physical_id"
              [placeholder]="'Выберите физическое лицо'"
              [caption]="'Выберите физическое лицо'"
              [api_action]="'Physical.search'">

            </app-material-select2-autocomplete>
            <p><a href="javascript:void(0)" (click)="appendPhysical(select)">Создать физическое лицо</a></p>
          </div>
          <div class="col-6">
            <app-material-select2
              formControlName="post_id"
              [caption]="'Выберите должность'">
              <option *ngFor="let el of posts" [value]="el.post_id">{{el.post_caption}}</option>
            </app-material-select2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <a href="javascript:void(0)" (click)="addRelationship()">Добавить связь</a>
</form>
