import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MaterialInputModule} from '../../modules/@material-input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PaginatorModule} from '../../shared/housing-modules/modules';
import {EatComplexAssocComponent} from './eat-complex-assoc.component';
import {EatComplexAssocResolve} from './eat-complex-assoc.resolve';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialInputModule,
    FormsModule,
    PaginatorModule,
    ReactiveFormsModule,
  ],
  declarations: [
    EatComplexAssocComponent
  ],
  providers: [
    EatComplexAssocResolve,
  ]

})
export class EatComplexAssocModule {

}
