import {Injectable} from '@angular/core';
import {JsonClientService} from '../../shared/json-client';

@Injectable()
export class TransferServices {

  constructor(private http: JsonClientService) {
  }

  transferUserSubagent(user_id, user_transfer_id, new_subagent_id)
  {
    return this.http.post('Subagent.transfer_user', {
      "user_id": user_id,
      "user_transfer_id": user_transfer_id,
      "new_subagent_id": new_subagent_id,
    })
  }

}
