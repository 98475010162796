import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ImportComponent } from './import.component';
import { ImportCreateComponent } from './import-create/import-create.component';
import { ImportEditComponent } from './import-edit/import-edit.component';
import { ImportFormatsResolve } from './shared/import-formats.resolve';
import { ImportFeedResolve } from './shared/import-feed.resolve';
import { ImportFeedReportResolve } from './shared/import-feed-report.resolve';
import { ImportFeedHousingResolve } from './shared/import-feed-housing.resolve';
import { ImportEditHistoryComponent } from './import-edit/import-edit-history/import-edit-history.component';
import { ImportEditConfirmComponent } from './import-edit/import-edit-confirm/import-edit-confirm.component';
import { ImportEditHousingComponent } from './import-edit/import-edit-housing/import-edit-housing.component';
import { ImportEditReportComponent } from './import-edit/import-edit-report/import-edit-report.component';
import { ImportPeriodicResolve } from './shared/import-periodic.resolve';
import { ImportFeedObjectsResolve } from './shared/import-feed-objects.resolve';
import { ImportModificationResolve } from './shared/import-modification.resolve';
import { ImportStatusesResolve } from './shared/import-statuses.resolve';
import { ImportFeedRealizationResolve } from './shared/import-feed-realization.resolve';
import { ImportEditRealizationComponent } from './import-edit/import-edit-realization/import-edit-realization.component';
import {ImportEditCommerceComponent} from './import-edit/import-edit-commerce/import-edit-commerce.component';
import {ImportFeedCommerceResolve} from './shared/import-feed-commerce.resolve';

const routes: Routes = [
  {path: 'create', component: ImportCreateComponent, pathMatch: 'prefix', resolve: {formats: ImportFormatsResolve}},
  {
    path: 'edit/:id', component: ImportEditComponent, pathMatch: 'prefix', resolve: {feed: ImportFeedResolve},
    children: [
      {
        path: '', component: ImportEditReportComponent, pathMatch: 'full',
        resolve: {
          data: ImportFeedReportResolve, periodic: ImportPeriodicResolve
        }
      },
      {
        path: 'housing',
        component: ImportEditHousingComponent,
        pathMatch: 'prefix',
        resolve: {
          data: ImportFeedHousingResolve,
        }
      },
      {
        path: 'confirm', component: ImportEditConfirmComponent, pathMatch: 'prefix',
        resolve: {
          objects: ImportFeedObjectsResolve,
          modification: ImportModificationResolve,
          statuses: ImportStatusesResolve,
        }
      },
      {path: 'history', component: ImportEditHistoryComponent, pathMatch: 'prefix'},
      {
        path: 'realization',
        component: ImportEditRealizationComponent,
        pathMatch: 'prefix',
        resolve: {
          data: ImportFeedRealizationResolve,
        }
      },
      {
        path: 'commerce',
        component: ImportEditCommerceComponent,
        pathMatch: 'prefix',
        resolve: {
          data: ImportFeedCommerceResolve,
        }
      },
    ]
  },
  {path: '', component: ImportComponent, pathMatch: 'prefix'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImportRoutingModule {
}
