import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppealsListComponent} from './list/appeals-list.component';
import {AuthGuard} from '../../guards/auth.guard';
import {AppealsElementComponent} from './element/appeals-element.component';
import {SingleWindowComponent} from '../single-window/single-window.component';
import {PhysicalAboutResolve} from '../physical/shared/physical.resolve';
import {AppealsElementResolve} from './element/appeals-element.resolve';

const routes: Routes = [
  {path: 'appeals/list', component: AppealsListComponent, canActivate: [AuthGuard]},
  {
    path: 'appeals',
    component: SingleWindowComponent,
    pathMatch: 'prefix',
    children: [
      {path: 'element/:id', component: AppealsElementComponent, canActivate: [AuthGuard],
        resolve: {
          backref: 'backref_appeals',
          data: AppealsElementResolve,
        }
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule,
  ]
})
export class AppealsRoutingModule { }
