<div class="filter_state">
  <div class="filter_state__tab all_work" (click)="filter_state('new')" [ngClass]="{'active': formGroup.value.tab === 'new'}">
    Новые ({{data.new}})
  </div>
  <div class="filter_state__tab done" (click)="filter_state('today')" [ngClass]="{'active': formGroup.value.tab === 'today'}">
    Сегодня ({{data.today}})
  </div>
  <div class="filter_state__tab fail" (click)="filter_state('bad')" [ngClass]="{'active': formGroup.value.tab === 'bad'}">
    Просроченные ({{data.bad}})
  </div>
  <div class="filter_state__create">
    <form [formGroup]="formGroup" novalidate>
      <div class="row no-gutters gutters-0">
        <div class="col-sm-6">
          <app-material-input-datepicker formControlName="date_start" [cssClass]="'mb-0'" [placeholder]="'Создан с'">
          </app-material-input-datepicker>
        </div>
        <div class="col-sm-6">
          <app-material-input-datepicker formControlName="date_end" [cssClass]="'mb-0'" [placeholder]="'Создан до'">
          </app-material-input-datepicker>
        </div>
      </div>
    </form>
  </div>
</div>
Всего: <b>{{theadline.showed_lines}}</b>
<div class="material-datatable-wrapper table-color">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)"
    [templateRight]="saveimport">
  </app-datatable-headline>
  <div class="table-responsive">
    <table class="table datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns"
             [rowClass]="'tab'">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>

<ng-template #saveimport>
  <ng-container *ngIf="theadline.showed_lines">
    <div class="text-right">
      <a [href]="urlLead + user.session_id + buttonLink"
         class="button mb-3" target="_blank"
      >
        Скачать<span class="d-none d-md-inline"> импорт</span>
      </a>
    </div>
  </ng-container>
</ng-template>

<ng-template #organisation_captionHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="organisation_caption" [cssClass]="'mb-0'" [placeholder]="'Организация'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #organisation_captionTmpl let-row="row" let-ind="row_index">
  <div [ngClass]="{'activeBox': goBack.activeHash === 'organisation_caption-' + ind}">
    <a href="/entity/edit/{{row.organisation_id}}"
       (click)="goBack.newLink('/entity/edit/' + row.organisation_id, 'organisation_caption-' + ind, $event)">
      {{row.organisation_caption}}
    </a>
  </div>
</ng-template>

<ng-template #phoneHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="phone" [cssClass]="'mb-0'" [placeholder]="'Телефон субагента'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #phoneTmpl let-row="row">
  {{row.phone | phone}}
</ng-template>

<ng-template #fioHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="fio" [cssClass]="'mb-0'" [placeholder]="'Субагент'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #fioTmpl let-row="row" let-ind="row_index">
  <ng-container *ngIf="row.fio">
    <div [ngClass]="{'activeBox': goBack.activeHash === 'lead' + row.id + '_' + ind}">
      <a href="/attraction/lead-info/{{row.id}}" (click)="goBack.newLink('/attraction/lead-info/' + row.id, 'lead' + row.id + '_' + ind, $event)">{{row.fio}}</a>
    </div>
  </ng-container>
  <ng-container *ngIf="!row.fio">
    <div [ngClass]="{'activeBox': goBack.activeHash === 'lead' + row.id + '_' + ind}">
      <a href="/attraction/lead-info/{{row.id}}" (click)="goBack.newLink('/attraction/lead-info/' + row.id, 'lead' + row.id + '_' + ind, $event)">-</a>
    </div>
  </ng-container>
</ng-template>

<ng-template #next_contact_dateHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="next_contact_date" [cssClass]="'mb-0'" [placeholder]="'Следующий контакт'">
    </app-material-input>
  </form>
</ng-template>
<ng-template #next_contact_dateTmpl let-row="row">
  <p class="bold">
    {{row.next_contact_date}} {{row.next_contact_time}}
  </p>
</ng-template>
<ng-template #access_idHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-select formControlName="access_id" [cssClass]="'mb-0'">
      <option [value]="''">Все</option>
      <option [value]="'active'">Полный доступ</option>
      <option [value]="'not_sub'">Не субагент</option>
      <option [value]="'restrict'">Ограниченный доступ</option>
      <option [value]="'not_active'">Нет доступа</option>
      <option [value]="'only_wizard'">Только мастер</option>
    </app-material-select>
  </form>
</ng-template>
<ng-template #access_idTmpl let-row="row">
  {{row.access_caption}}
</ng-template>
<ng-template #stage_captionHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="stage_caption" [cssClass]="'mb-0'" [placeholder]="'Этап'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #create_timeTmpl let-row="row">
  <p class="mb-1">
    <span class="bold">{{row.create_time}} </span>
    дней с создания {{row.last_days}}
  </p>
  <p class="mb-1">
    Дата изменения
    <span class="bold">{{row.stage_change_time}} </span>
  </p>
</ng-template>

<ng-template #status_descriptionTmpl let-row="row">
  <ng-container *ngIf="row.status_description">
    {{row.status_description}}
  </ng-container>
  <ng-container *ngIf="row.wizard_step">
    <p class="bold">Шаг мастера: {{row.wizard_step}}</p>
  </ng-container>
  <ng-container *ngIf="row.wizard_step_caption">
    {{row.wizard_step_caption}}
  </ng-container>
</ng-template>

<ng-template #ci_sourceHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="ci_source" [cssClass]="'mb-0'" [placeholder]="'Источник'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #ci_sourceTmpl let-row="row" let-ind="row_index">
  <div [ngClass]="{'activeBox': goBack.activeHash === 'ci_source-' + ind}">
    <a href="/physical/edit/{{row.ci_source_physical_id}}"
       (click)="goBack.newLink('/physical/edit/' + row.ci_source_physical_id, 'ci_source-' + ind, $event)">
      {{row.ci_source}}
    </a>
  </div>
</ng-template>


<ng-template #subagent_area_workHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-select formControlName="ci_subagent_area_worker" [cssClass]="'mb-0'">
      <option [value]="''">Все</option>
      <option [value]="'1'">Новостройки</option>
      <option [value]="'2'">Вторичная недвижимость</option>
      <option [value]="'3'">Коммерческая недвижимость</option>
      <option [value]="'4'">Элитная недвижимость</option>
    </app-material-select>
  </form>
</ng-template>
<ng-template #subagent_area_workTmpl let-row="row">
  {{row.ci_subagent_area_worker}}
</ng-template>


<form [formGroup]="formGroup" novalidate>
  <app-material-input formControlName="tab" [hidden]="true"></app-material-input>
</form>
