<div class="mt-4 mb-2 row">
  <div class="col-12 col-sm-3 col-md-2">
    Всего: <b>{{count}}</b>
    <app-material-input-checkbox [(ngModel)]="is_resolve">
      В архиве
    </app-material-input-checkbox>
  </div>
  <div class="col-12 col-sm-9 col-md-10" *ngIf="checkedLength > 0">
    <span
      class="mr-4">Выбрано: <b>{{checkedLength}}</b>
    </span>
    <a href="javascript:void(0)" (click)="onClearChecked()"
       class="mr-4">снять выбор</a>
    <a *ngIf="_is_resolve === false" href="javascript:void(0)" (click)="onResolveError()"
       class="mr-4">отметить решенным</a>
    <a *ngIf="_is_resolve === true" href="javascript:void(0)" (click)="onUnresolvedError()"
       class="mr-4">достать из архива</a>
  </div>
  <div class="col-12 mt-2 d-md-none no-wrap fix-ch-box">
    Выбрать все на странице: <input
    class="checkbox ml-3"
    type="checkbox"
    [value]="true"
    [(ngModel)]="isAllChecked"
    [ngModelOptions]="{standalone: true}"
  />
  </div>
</div>
<app-modal *ngIf="modal_show" [caption]="'JSON текст'" (onClosed)="modalClose()">
  <pre>{{modal_content|json}}</pre>
</app-modal>
<div class="material-datatable-wrapper">
  <div class="table-responsive">
    <table class="table">
      <tr>
        <th>
          <input
            class="checkbox"
            type="checkbox"
            [value]="true"
            [(ngModel)]="isAllChecked"
            [ngModelOptions]="{standalone: true}"
          />
        </th>
        <th *ngFor="let el of head" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
          <div [formGroup]="form">
            <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
              <div class="col table__input">
                <ng-container *ngIf="el.input">
                  <app-material-input
                    [placeholder] = "el.caption"
                    [formControlName] = "el.name"
                    [cssClass]="'mb-0'">
                  </app-material-input>
                </ng-container>
                <ng-container *ngIf="!el.input && el.name !== 'search_fields'">
                  {{el.caption}}
                </ng-container>
              </div>
            </div>
          </div>
        </th>
      </tr>
      <!-- // -->
      <tr *ngFor="let row of rows; let row_index = index">
        <td>
          <input class="checkbox" type="checkbox" value="{{row['error_id']}}"
                 [checked]="isChecked(row)" (click)="onChecking($event, row)">
        </td>
        <td>
          <p>{{row['user_id']}}</p>
        </td>
        <td>
          <p>{{row['create_date']}}</p>
        </td>
        <td>
          <p>{{row['code']}}</p>
        </td>
        <td>
          <p>{{row['status']}}</p>
        </td>
        <td>
          <p>{{row['method']}}</p>
        </td>
        <td>
          <a class="" href="javascript: void(0)"
             (click)="viewPayConditions(row['request'], $event)">
            Подробнее
          </a>
        </td>
        <td>
          <a class="" href="javascript: void(0)"
             (click)="viewPayConditions(row['response'], $event)">
            Подробнее
          </a>
        </td>
      </tr>
    </table>
  </div>
</div>
<div class="alt-pagination">
  <app-paginator
    [itemsPerPage]="50"
    [itemCount]="count"
    [currentPage]="current_page"
    [baseUrl]="'/errors'">
  </app-paginator>
</div>
