import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';

import {FieldSubwayService} from './field-subway.service';

@Component({
  selector: 'field-housing-subway',
  templateUrl: './field-subway.component.html',
  styleUrls: [
    './field-subway.component.scss',
  ],
  providers: [
    FieldSubwayService,
  ]
})
export class FieldSubwayComponent implements OnInit, OnDestroy {

  @Input() public form: UntypedFormGroup;
  @Input() public field_name: string;
  @Input() public values: Array<any> = [];

  public subway_list: Array<any>;
  private subscriptions: Array<Subscription> = [];
  private allreadyMain = false;

  public get subways(): UntypedFormArray {
    return this.form.get(this.field_name) as UntypedFormArray;
  }

  public get chosen_elements() {
    return this.subways.controls.map(x => +x.get('id').value);
  }

  constructor(private s: FieldSubwayService, private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.form.addControl(this.field_name, new UntypedFormArray(this.getSubways()));
    this.s.loadHelpers().then(args => [this.subway_list] = args);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  dropSubway(i) {
    const need_main = this.subways.controls[i].get('is_main').value === true;
    this.subways.removeAt(i);
    if (need_main && this.subways.controls.length) {
      this.subways.controls[0].patchValue({
        is_main: true
      });
    }
  }

  addSubway() {
    const options = {};
    if (!this.subways.length) {
      options['is_main'] = true;
    }
    const [control, subscription] = this.getControl(options);

    this.subways.push(control);
    this.subscriptions.push(subscription);
  }

  private getSubways() {
    if (!this.values.length) {
      return [];
    }

    const controls = [];

    for (const el of this.values) {
      el['id'] = el['id'].toString();
      if (el['is_main'] && this.allreadyMain) {
        el['is_main'] = false;
      } else if (el['is_main']) {
        this.allreadyMain = true;
      }
      const [_c, _s] = this.getControl(el);
      controls.push(_c);
      this.subscriptions.push(_s);
    }

    return controls;
  }

  private getControl(options = {}): [UntypedFormGroup, Subscription] {
    const control = this.fb.group({
      'id': [options['id'] || '', Validators.required],
      'is_main': [options['is_main'] || false],
      'distance': [
        options['distance'] || '',
        Validators.pattern(/^\d*$/)
      ],
      'distance_min_transport': [
        options['distance_min_transport'] || '',
        Validators.pattern(/^\d*$/)
      ],
      'distance_min_walk': [
        options['distance_min_walk'] || '',
        Validators.pattern(/^\d*$/)
      ],
    });

    const subscription = control.get('is_main').valueChanges
      .subscribe(value => {
        if (!value || !this.form) {
          return;
        }


        this.subways.controls
          .filter(x => x !== control)
          .forEach(_control => _control.patchValue({
            'is_main': false,
          }, {onlySelf: true, emitEvent: false}));
      });

    return [control, subscription];
  }

}
