import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConfigService} from '../../../services/configuration.service';
import {FancyboxService} from '../../../shared/fancybox.service';

@Component({
  selector: 'app-material-dropzone-table',
  templateUrl: './material-input-dropzone-table.component.html',
  styleUrls: ['./material-input-dropzone-table.component.scss']
})
export class MaterialInputDropzoneTableComponent implements OnInit {

  public upload_url = 'baz/';
  public album;

  @Input() public hasComments = false;
  @Input() public disable = false;
  @Input() public files: Array<{[key: string]: string}> = [];

  @Output() public file_remove: EventEmitter<string> = new EventEmitter();
  @Output() public comment_cahnge: EventEmitter<[string, string]> = new EventEmitter();

  constructor(private config: ConfigService, public lightbox: FancyboxService) { }

  ngOnInit() {
    this.upload_url = this.config.data['url_file_upload'];
  }

  removeFile(file_id) {
    this.file_remove.emit(file_id);
  }

  onCommentChange(comment, file_id) {
    this.comment_cahnge.emit([file_id, comment]);
  }

}
