<div class="caption-sm mb-2">Поиск субагентов</div>
<div class="material-datatable-wrapper">
  <form class="form" [formGroup]="formGroup" >
    <div class="row no-gutters align-items-center">
      <div class="col">
        <app-material-select2 formControlName="realty_id" [placeholder]="'-- Выберите ЖК --'">
          <option [value]="''">-- Выберите ЖК --</option>
          <option *ngFor="let realty of realty_list" [value]="realty.housing_id">
            {{realty.housing_caption}}
          </option>
        </app-material-select2>
      </div>
      <div class="col-auto">
        <button class="close close_sm ml-2 mb-1" tabindex="-1" type="button" (click)="clearSelect2()">
          <i class="icon icon_Small_Close"></i>
        </button>
      </div>
    </div>
  </form>
  <!--<app-datatable-headline-->
    <!--[global_search_string]="global_search_string"-->
    <!--[items_per_page_vars]="items_per_page_vars"-->
    <!--(items_per_page_change)="itemPerPageChange($event)"-->
    <!--(global_search_change)="onGlobalSearch($event)"-->
    <!--[templateRight]="templateRight">-->
  <!--</app-datatable-headline>-->
  <div class="table-responsive" [ngClass]="{'table-responsive': responsive}">
    <table class="table datatable" [ngClass]="cssClass">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>


<ng-template #buttonTmpl let-row="row">
  <a href="javascript:void(0)" (click)="getSubagent(row.organisation_id, row.organisation_socrachcaption)"
     class="button button_icon button_icon_sub mr-1"><i class="icon icon_Small_Crowd"
                                                        [class.icon_grey]="getColor(row.organisation_id)"></i>
  </a>
</ng-template>

<ng-template #mapTmpl let-row="row">
  <a href="javascript:void(0)" title="Показать на карте"
     (click)="getMap(row.organisation_socrachcaption)"
     class="button button_icon button_icon_sub"><i class="icon icon_Small_Broker-Tour"></i>
  </a>
</ng-template>

<ng-template #mapTmplHead let-row="row">
  <a href="javascript:void(0)" title="Показать на карте" *ngIf="formGroup.value.realty_id"
     (click)="getMapRealty()"
     class="button button_icon button_icon_sub"><i class="icon icon_Presentation-Doc"></i>
  </a>
</ng-template>

<ng-template #socrachcaptionTmplHead let-row="row">
  <form class="form from_crm" [formGroup]="formGroup">
    <app-material-input formControlName="organisation_socrachcaption" [placeholder]="'Субагент'" [cssClass]="['mb-0']">
    </app-material-input>
  </form>
</ng-template>
