import {EventEmitter, Injectable} from '@angular/core';
import {JsonClientService} from '../../shared/json-client';
import {Subject} from 'rxjs';


export interface IPreSelectData {
  isFixationTabActive?: boolean;
  isTransferActive?: boolean;
  closeAfterSelect?: boolean;
  isViewTabActive?: boolean;
  complexId?: number;
  callbackWhenSubmitted?: () => void;
}


@Injectable()
export class ClientModeService {

  public onClientInfoShow$: Subject<IPreSelectData> = new Subject();
  public onClientInfoSubmitted$: Subject<void> = new Subject();
  public clientEmitter: EventEmitter<{[p: string]: any}> = new EventEmitter();
  public clientListEmitter: EventEmitter<boolean> = new EventEmitter();
  public clientFormChanged = false;

  private _client: {[p: string]: any} = null;

  public get client(): { [p: string]: any} {
    return this._client;
  }

  public set client(value: { [p: string]: any}) {
    this._client = value;
    this.clientEmitter.emit(value);
  }

  constructor(private j: JsonClientService) { }

  public addFavorites(realty_id) {
    if (!this.client) {
      return;
    }

    const index = this.client['fav_list'].indexOf(realty_id);

    if (index === -1) {
      this.client['fav_list'].push(realty_id);
      this.clientEmitter.emit(this.client);
    }
  }

  public removeFavorites(realty_id) {
    if (!this.client) {
      return;
    }

    const index = this.client['fav_list'].indexOf(realty_id);

    if (index !== -1) {
      this.client['fav_list'].splice(index, 1);
      this.clientEmitter.emit(this.client);
    }
  }

  public checkFavorites(realty_id) {
    if (!this.client) {
      return;
    }

    const index = this.client['fav_list'].indexOf(realty_id);

    return index !== -1;
  }

  public setClientList(value) {
    this.clientListEmitter.emit(value);
  }

  public restoreFavorites(flat_id, client_id) {
    return this.j.post('Clients.toggle_favorites', {
      'client_id': client_id,
      'realty_id': flat_id,
      'add': true
    }).then(_ => {
      this.addFavorites(flat_id);
    });
  }

  public restoreFavoritesResale(resale_realty_id, client_id) {
    return this.j.post('Clients.toggle_favorites', {
      'client_id': client_id,
      'realty_id': resale_realty_id,
      'add': true,
      'resale_realty': true
    }).then(_ => {
      this.addFavorites(resale_realty_id);
    });
  }
}
