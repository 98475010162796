
import {from as observableFrom, BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

import {IUrl, UrlList} from '../types';
import {BreadcrumbsUrl} from '../models/url';
import {environment} from '../../../../environments/environment';
import {UsageVariant} from '../../../models/usage_variants';

@Injectable()
export class BreadcrumbsService {

  /*
   * Service contains all url's in @elements public property
   * provides next public functions:
   *
   *  - emptyChain: Destroy all breadcrumbs
   *  - append: Push new element into elements collection
   */
  private env_crm = environment.usage === UsageVariant.CRM;

  private _flush_on_navigate = true;

  private _elements$: BehaviorSubject<UrlList> =
    new BehaviorSubject<UrlList>([]);

  public readonly elements$: Observable<UrlList> =
    observableFrom(this._elements$);

  constructor(private r: Router) {
    this.r.events
      .pipe(filter(x => x instanceof NavigationEnd))
      .subscribe(data => {
        if (data['url'].indexOf('authenticate') === 1 || data['url'].indexOf('crm') === 1 || data['url'] === '/' || (!this.env_crm && data['url'].indexOf('housing2') !== 1)) {
          this._elements$.next([]);
        } else {
          this.emptyChainNavigate();
        }
      });
  }

  private get_elements_value() {
    /*
     * Return scalar value from BehaviorSubject
     */
    return this._elements$.getValue();
  }

  setNavigationFlush(value = true) {
    this._flush_on_navigate = value;
  }

  emptyChainNavigate() {
    if (this._flush_on_navigate) {
      this.emptyChain();
    }
  }

  emptyChain() {
    /*
     * Empty breadcrumbs chain
     */
    const url_obj = new BreadcrumbsUrl('', 'Главная', {});
    this._elements$.next([url_obj]);
  }

  append(url, caption, opts = {}) {
    /*
     * Append url into elements collection
     */
    const elements = this.get_elements_value();
    const url_obj = new BreadcrumbsUrl(url, caption, opts);

    elements.push(url_obj);
    this._elements$.next(elements);
  }

  set(elements: Array<IUrl>) {
    /*
     * Set new breadcrumbs value
     */
    const urls: UrlList = elements.map(x => new BreadcrumbsUrl(
      x.url, x.caption, x.opts));
    this._elements$.next(urls);
  }

}
