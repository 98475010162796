<div class="filter_state">
  <div class="filter_state__tab all_work" (click)="filter_state('all_work')" [ngClass]="{'active': form.value.tab === 'all_work'}">
    ВСЕ В РАБОТЕ ({{filterdata.all_work}})
  </div>
  <div class="filter_state__tab done" (click)="filter_state('done')" [ngClass]="{'active': form.value.tab === 'done'}">
    ЗАВЕРШЕНО УСПЕШНО ({{filterdata.done}})
  </div>
  <div class="filter_state__tab fail mr-2r" (click)="filter_state('fail')" [ngClass]="{'active': form.value.tab === 'fail'}">
    ЗАВЕРШЕНО С ПРОВАЛОМ ({{filterdata.fail}})
  </div>
  <div class="filter_state__create mr-2r">
    <form [formGroup]="form">
      <div class="row no-gutters gutters-0">
        <div class="col-sm-6">
          <app-material-input-datepicker formControlName="date_start" [cssClass]="'mb-0'" [placeholder]="'Создан с'">
          </app-material-input-datepicker>
        </div>
        <div class="col-sm-6">
          <app-material-input-datepicker formControlName="date_end" [cssClass]="'mb-0'" [placeholder]="'Создан по'">
          </app-material-input-datepicker>
        </div>
      </div>
    </form>
  </div>
  <div class="filter_state__create">
    <form [formGroup]="form" novalidate>
      <div class="row no-gutters gutters-0">
        <div class="col-sm-6">
          <app-material-input-datepicker formControlName="date_change_start" [cssClass]="'mb-0'" [placeholder]="'Изменён с'">
          </app-material-input-datepicker>
        </div>
        <div class="col-sm-6">
          <app-material-input-datepicker formControlName="date_change_end" [cssClass]="'mb-0'" [placeholder]="'Изменён до'">
          </app-material-input-datepicker>
        </div>
      </div>
    </form>
  </div>
</div>
Всего: <b>{{count}}</b>
<div class="mb-2">
  <div class="row no-gutters align-items-center m-0">
    <div class="col-md-6">
      <div class="form__group">
        <div class="form__input">
          <input class="input" type="text" placeholder="Поиск"
                 [ngModel]="filter" (ngModelChange)="onFilterChanges($event)">
          <div class="form__input_icon icon icon_Small_Search c-pointer ng-star-inserted"></div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="text-right ml-auto">
        <a [href]="urlLead + user.session_id + buttonLink"
           class="button mb-3" target="_blank"
        >
          Скачать<span class="d-none d-md-inline"> импорт</span>
        </a>
      </div>
    </div>
  </div>
</div>

<div class="material-datatable-wrapper table-color">
  <div class="table-responsive">
    <table class="table">
      <tr>
        <th *ngFor="let el of headDisplay" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
          <div [formGroup]="form">
            <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
              <div class="col table__input">
                <ng-container *ngIf="el.name === 'next_contact_date' || el.name === 'stage'">
                  <app-material-input-datepicker formControlName="next_contact_date" [cssClass]="'mb-0'" [placeholder]="'След. контакт (созд./изм.)'">
                  </app-material-input-datepicker>
                </ng-container>
                <ng-container *ngIf="el.name !== 'next_contact_date' && el.name !== 'stage'">
                  <app-material-input
                    [placeholder] = "el.caption"
                    [formControlName] = "el.name"
                    [cssClass]="'mb-0'">
                  </app-material-input>
                </ng-container>
              </div>
              <ng-container *ngIf="el.name !== 'stage'">
                <div class="col-auto" *ngIf="el.sort">
                  <a href="javascript:void(0)" (click)="sortChange(el.name)">
                    <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                      'icon_new-sort-down': 'asc' == sort[el.name],
                                      'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                  </a>
                </div>
              </ng-container>

            </div>
          </div>
        </th>
      </tr>
      <!-- // -->
      <tr *ngFor="let row of rows; let ind = index" [attr.data-color]="row.tab?row.tab:''">
        <td>
          <p class="bold mb-1" *ngIf="row.next_contact_date">
            {{datediff(row.next_contact_date)}}<br>
            ({{row.next_contact_date}} {{row.next_contact_time}})
          </p>
          {{row.lead_create_date}} / {{row.stage_change_time}}
        </td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'subagent_caption-' + ind}">
            <a href="/entity/edit/{{row.subagent_entity_id}}"
               (click)="goBack.newLink('/entity/edit/' + row.subagent_entity_id, 'subagent_caption-' + ind, $event)">
              {{row.subagent_caption}}
            </a>
          </div>
        </td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'physical_subagent_fio-' + ind}">
            <a href="/physical/edit/{{row.physical_subagent_id}}"
               (click)="goBack.newLink('/physical/edit/' + row.physical_subagent_id, 'physical_subagent_fio-' + ind, $event)">
              {{row.physical_subagent_fio}}
            </a>
          </div>
        </td>
        <td>{{row['physical_subagent_phone']}}</td>
        <td>
          <ng-container *ngIf="row.lead_id !== ''">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'appeal_client_fio-' + ind}">
              <a href="/reservation/lead-info/{{row.lead_id}}"
                 (click)="goBack.newLink('/reservation/lead-info/' + row.lead_id, 'appeal_client_fio-' + ind, $event)">
                {{row.appeal_client_fio}}
              </a>
            </div>
          </ng-container>
          <ng-container *ngIf="row.lead_id === ''">
            {{row.appeal_client_fio}}
          </ng-container>
        </td>
        <td>{{row['lead_phone']}}</td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'object_caption-' + ind}">
            <a href="/housing2/flat/{{row.realty_flat_id}}"
               (click)="goBack.newLink('/housing2/flat/' + row.realty_flat_id, 'object_caption-' + ind, $event)">
              {{row.object_caption}}
            </a>
          </div>
        </td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'physical_manager_fio-' + ind}">
            <a href="/managers/edit/{{row.physical_manager_id}}"
               (click)="goBack.newLink('/managers/edit/' + row.physical_manager_id, 'physical_manager_fio-' + ind, $event)">
              {{row.physical_manager_fio}}
            </a>
          </div>
        </td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'developer_caption-' + ind}">
            <a href="/entity/edit/{{row.developer_id}}"
               (click)="goBack.newLink('/entity/edit/' + row.developer_id, 'developer_caption-' + ind, $event)">
              {{row.developer_caption}}
            </a>
          </div>
        </td>
        <td><app-stages-dots [data]="row.stage_information"></app-stages-dots></td>
    </table>
  </div>
</div>

<div class="alt-pagination">
  <app-paginator
    [queryParams]="filter_columns"
    [itemsPerPage]="50"
    [itemCount]="count"
    [currentPage]="current_page"
    [baseUrl]="'/reservation/booking-info/' + tab">
  </app-paginator>
</div>

