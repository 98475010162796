import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../../../services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HttpClient } from '../../../../services/http.service';
import { debounceTime } from 'rxjs/operators';



@Component({
  templateUrl: './import-edit-housing.component.html',
  styleUrls: ['./import-edit-housing.component.scss']
})
export class ImportEditHousingComponent implements OnInit {

  public feed_id: any;
  public data: any;
  public report: any;
  public formGroup: UntypedFormGroup;
  public loading = false;
  public complexes_id = [];
  public settings_id = [];
  public types = {'auto': 'Автоматически', 'manual': 'Вручную', 'no': 'Не импортировать'};

  constructor(public http: HttpClient, public fb: UntypedFormBuilder,
              public notify: NotificationService, public router: Router, public activatedRoute: ActivatedRoute) {
    this.feed_id = activatedRoute.snapshot.parent.params['id'];
    this.data = activatedRoute.snapshot.data['data'];
    this.report = this.data['default'];
    this.complexes_id = this.data['complexes'].map((item) => item['id']);
    this.settings_id = this.data['settings'].map((item) => item['id']);
    this.formGroup = this.fb.group({
      'complexes': this.fb.array(this.data['complexes'].map((item) => {
          return this.fb.group({
            'id': item['id'],
            'realty_id': item['realty_id'],
            'caption': item['caption'],
            'housings': this.fb.array(item['housings'].map((_item) => {
              return this.fb.group({
                'realty_id': _item['realty_id'],
                'caption': _item['caption'],
              });
            })),
            'is_checked': item['is_checked'],
          });
        })),
      'settings': this.fb.array(this.data['settings'].map((item) => {
          return this.fb.group({
            'id': item['id'],
            'caption': item['caption'],
            'settings_type_prices': item['settings_type_prices'],
            'settings_type_statuses': item['settings_type_statuses'],
            'settings_type_squares': item['settings_type_squares'],
            'settings_type_plans': item['settings_type_plans'],
            'settings_type_structure': item['settings_type_structure'],
            'settings_type_captions': item['settings_type_captions'],
          });
        }))
    });
    this.complexes.valueChanges.pipe(debounceTime(100)).subscribe((data) => {
      data.forEach((item) => {
        const index = this.settings_id.indexOf(item.id);
        if (item.is_checked && index !== -1) {
          this.settings_id.splice(index, 1);
          this.settings.removeAt(index);
        }
        if (!item.is_checked && index === -1) {
          this.settings_id.push(item.id);
          this.appendSettings(item);
        }
      });
    });
  }

  ngOnInit() {
  }

  public get complexes(): UntypedFormArray {
    return this.formGroup.get('complexes') as UntypedFormArray;
  }

  public get settings(): UntypedFormArray {
    return this.formGroup.get('settings') as UntypedFormArray;
  }

  appendSettings(data) {
    this.settings.push(
      this.fb.group({
        'id': data.id,
        'caption': data.caption,
        'settings_type_prices': this.report['settings_type_prices'],
        'settings_type_statuses': this.report['settings_type_statuses'],
        'settings_type_squares': this.report['settings_type_squares'],
        'settings_type_plans': this.report['settings_type_plans'],
        'settings_type_structure': this.report['settings_type_structure'],
        'settings_type_captions': this.report['settings_type_captions'],
      })
    );
  }

  linkImport(event, id) {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['/housing', id]);
  }

  saveForm() {
    if (!this.formGroup.valid) {
      this.onError('Заполните обязательные поля');
      return false;
    }
    this.loading = true;
    const data = {
      'setting_id': this.feed_id,
      'data': this.formGroup.value,
    };

    this.http.post('Import.setting_exceptions_update', data).subscribe(
      (response) => {
        this.onSuccess(response);
        this.loading = false;
      },
      (error) => {
        this.onError(error);
        this.loading = false;
      }
    );
  }

  onSuccess(response?) {
    this.notify.notifySuccess('Поздравляем', 'Настройки успешно сохранена', 3000);
  }

  onError(error) {
    this.notify.notifyError('Ошибка', error, 5000);
  }

}
