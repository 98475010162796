<div class="filter_state">
  <div class="filter_state__tab all_work" (click)="filter_state('all_work')" [ngClass]="{'active': formGroup.value.tab === 'all_work'}">
    Все в работе ({{data.all_work}})
  </div>
  <div class="filter_state__tab done" (click)="filter_state('done')" [ngClass]="{'active': formGroup.value.tab === 'done'}">
    Завершено успешно ({{data.done}})
  </div>
  <div class="filter_state__tab fail mr-2r" (click)="filter_state('fail')" [ngClass]="{'active': formGroup.value.tab === 'fail'}">
    Завершено с провалом ({{data.fail}})
  </div>
  <div class="filter_state__create mr-2r">
    <form [formGroup]="formGroup" novalidate>
      <div class="row no-gutters gutters-0">
        <div class="col-sm-6">
          <app-material-input-datepicker formControlName="date_start" [cssClass]="'mb-0'" [placeholder]="'Создан с'">
          </app-material-input-datepicker>
        </div>
        <div class="col-sm-6">
          <app-material-input-datepicker formControlName="date_end" [cssClass]="'mb-0'" [placeholder]="'Создан до'">
          </app-material-input-datepicker>
        </div>
      </div>
    </form>
  </div>
  <div class="filter_state__create">
    <form [formGroup]="formGroup" novalidate>
      <div class="row no-gutters gutters-0">
        <div class="col-sm-6">
          <app-material-input-datepicker formControlName="date_change_start" [cssClass]="'mb-0'" [placeholder]="'Изменён с'">
          </app-material-input-datepicker>
        </div>
        <div class="col-sm-6">
          <app-material-input-datepicker formControlName="date_change_end" [cssClass]="'mb-0'" [placeholder]="'Изменён до'">
          </app-material-input-datepicker>
        </div>
      </div>
    </form>
  </div>
</div>
Всего: <b>{{theadline.showed_lines}}</b>
<div class="material-datatable-wrapper table-color">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)"
    [templateRight]="saveimport">
  </app-datatable-headline>
  <div class="table-responsive">
    <table class="table datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns"
             [rowClass]="'tab'">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>

<ng-template #saveimport>
  <ng-container *ngIf="theadline.showed_lines">
    <div class="text-right">
      <a [href]="urlLead + user.session_id + buttonLink"
         class="button mb-3" target="_blank"
      >
        Скачать<span class="d-none d-md-inline"> все</span>
      </a>
    </div>
  </ng-container>
</ng-template>


<ng-template #organisation_captionHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="organisation_caption" [cssClass]="'mb-0'" [placeholder]="'Организация'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #organisation_captionTmpl let-row="row" let-ind="row_index">
  <div [ngClass]="{'activeBox': goBack.activeHash === 'organisation_caption-' + ind}">
    <a href="/entity/edit/{{row.organisation_id}}"
       (click)="goBack.newLink('/entity/edit/' + row.organisation_id, 'organisation_caption-' + ind, $event)">
      {{row.organisation_caption}}
    </a>
  </div>
</ng-template>

<ng-template #subagent_user_fioHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="subagent_user_fio" [cssClass]="'mb-0'" [placeholder]="'Субагент'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #subagent_user_fioTmpl let-row="row" let-ind="row_index">
  <div [ngClass]="{'activeBox': goBack.activeHash === 'subagent_user_fio-' + ind}">
    <a href="/physical/edit/{{row.subagent_user_id}}"
       (click)="goBack.newLink('/physical/edit/' + row.subagent_user_id, 'subagent_user_fio-' + ind, $event)">
      {{row.subagent_user_fio}}
    </a>
  </div>
</ng-template>

<ng-template #subagent_phoneHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="subagent_phone" [cssClass]="'mb-0'" [placeholder]="'Телефон субагента'">
    </app-material-input>
  </form>
</ng-template>
<ng-template #lead_phoneHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="lead_phone" [cssClass]="'mb-0'" [placeholder]="'Телефон клиента'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #fioHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="fio" [cssClass]="'mb-0'" [placeholder]="'Клиент'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #fioTmpl let-row="row" let-ind="row_index">
  <ng-container *ngIf="row.fio">
    <div [ngClass]="{'activeBox': goBack.activeHash === 'fio-' + ind}">
      <a href="/reservation/lead-info/{{row.id}}"
         (click)="goBack.newLink('/reservation/lead-info/' + row.id, 'fio-' + ind, $event)">
        {{row.fio}}
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="!row.fio">
    <div [ngClass]="{'activeBox': goBack.activeHash === 'fio-' + ind}">
      <a href="/reservation/lead-info/{{row.id}}"
         (click)="goBack.newLink('/reservation/lead-info/' + row.id, 'fio-' + ind, $event)">
        -
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="row.lead_est_double_with">
    <hr class="my-2">
    <div [ngClass]="{'activeBox': goBack.activeHash === 'lead_est_double_with-' + ind}">
      <a href="/reservation/lead-info/{{row.lead_est_double_with}}"
         (click)="goBack.newLink('/reservation/lead-info/' + row.lead_est_double_with, 'lead_est_double_with-' + ind, $event)">
        Эстатет: <span class="font-weight-bold">Дубль</span>
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="!row.lead_est_double_with && !row.lead_est_id">
    <hr class="my-2">
    Эстатет: <span class="font-weight-bold">Ошибка</span>
  </ng-container>
</ng-template>

<ng-template #object_captionHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="object_caption" [cssClass]="'mb-0'" [placeholder]="'Объект'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #object_captionTmpl let-row="row" let-ind="row_index">
  <ng-container *ngIf="row.object_type === 'flat'">
    <div [ngClass]="{'activeBox': goBack.activeHash === 'object_caption-' + ind}">
      <a href="/housing2/flat/{{row.object_id}}"
         (click)="goBack.newLink('/housing2/flat/' + row.object_id, 'object_caption-' + ind, $event)">
        {{row.object_caption}}
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="row.object_type === 'housing'">
    <div [ngClass]="{'activeBox': goBack.activeHash === 'object_caption-' + ind}">
      <a href="/housing/house/{{row.object_id}}"
         (click)="goBack.newLink('/housing/house/' + row.object_id, 'object_caption-' + ind, $event)">
        {{row.object_caption}}
      </a>
    </div>
  </ng-container>
</ng-template>

<ng-template #next_contact_dateHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input-datepicker formControlName="next_contact_date" [cssClass]="'mb-0'" [placeholder]="'След. контакт (созд./изм.)'">
    </app-material-input-datepicker>
  </form>
</ng-template>
<ng-template #next_contact_dateTmpl let-row="row">
  <p class="bold mb-1" *ngIf="row.next_contact_date">
    {{datediff(row.next_contact_date)}}<br>
    ({{row.next_contact_date}} {{row.next_contact_time}})
  </p>
  {{row.create_time}} / {{row.stage_change_time}}
</ng-template>

<ng-template #physical_manager_fioHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_manager_fio" [cssClass]="'mb-0'" [placeholder]="'Ответственный'">
    </app-material-input>
  </form>
</ng-template>
<ng-template #physical_manager_fioTmpl let-row="row" let-ind="row_index">
  <div [ngClass]="{'activeBox': goBack.activeHash === 'physical_manager_fio-' + ind}">
    <a href="/managers/edit/{{row.physical_manager_id}}"
       (click)="goBack.newLink('/managers/edit/' + row.physical_manager_id, 'physical_manager_fio-' + ind, $event)">
      {{row.physical_manager_fio}}
    </a>
  </div>
</ng-template>

<ng-template #developer_manager_fioHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="developer_manager_fio" [cssClass]="'mb-0'" [placeholder]="'Менеджера застройщика'">
    </app-material-input>
  </form>
</ng-template>
<ng-template #developer_manager_fioTmpl let-row="row" let-ind="row_index">
  {{row.developer_manager_fio}}  <p>{{row.developer_manager_phone}}</p>
</ng-template>

<ng-template #stage_captionHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="stage_caption" [cssClass]="'mb-0'" [placeholder]="'Этап'">
    </app-material-input>
  </form>
</ng-template>
<ng-template #stage_captionTmpl let-row="row">
  <app-stages-dots [data]="row.stage_information"></app-stages-dots>
</ng-template>

<ng-template #sale_lead_transferHead>
  <form [formGroup]="formGroup" novalidate>
      <app-material-select formControlName="sale_lead_transfer" [cssClass]="'mb-0'">
        <option [value]="''">--Не выбрано--</option>
        <option [value]="'false'">Самостоятельная работа</option>
        <option [value]="'true'">Расширенная работа</option>
      </app-material-select>
  </form>
</ng-template>
<ng-template #sale_lead_transferTmpl let-row="row">
  {{getSaleLeadTransferCaption(row.sale_lead_transfer)}}
</ng-template>
