 <ul class="navigation__tabs">
  <li class="navigation__tab">
    <a class="navigation__tab__link" routerLink="all" routerLinkActive="navigation__tab__link_active">
      Все ({{data.all_count}})<span class="navigation__tab__link_active__slide"></span>
    </a>
  </li>
  <li class="navigation__tab" *ngFor="let element of data.stages">
    <a class="navigation__tab__link" routerLink="{{element.tab}}" routerLinkActive="navigation__tab__link_active">
      {{element.caption}} ({{element.count}}) <span class="navigation__tab__link_active__slide"></span>
    </a>
  </li>
</ul>
<router-outlet></router-outlet>
