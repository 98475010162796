import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class CalendarMapService {
  public onChangeRealty: EventEmitter<any> = new EventEmitter();
  public onChangeRealtyMap: EventEmitter<any> = new EventEmitter();
  public onChangeSubagent: EventEmitter<any> = new EventEmitter();
  public onMarkerMap: EventEmitter<any> = new EventEmitter();

  constructor() { }

  changeRealty(value) {
    this.onChangeRealty.emit(value);
  }

  changeRealtyMap(value) {
    this.onChangeRealtyMap.emit(value);
  }

  changeSubagent(value) {
    this.onChangeSubagent.emit(value);
  }

  markerMap(value) {
    this.onMarkerMap.emit(value);
  }

}
