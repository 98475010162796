import {AfterViewInit, Directive, ElementRef, Renderer2, RendererStyleFlags2} from '@angular/core';

@Directive({
  selector: '[td_compare]',
})
export class TdCompareDirective implements AfterViewInit {

  private value = 0;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

  ngAfterViewInit() {
    const element = this.elementRef.nativeElement;
    this.value = parseInt(element.getAttribute('data-compare'), 10);
    if (element.previousElementSibling && element.previousElementSibling.hasAttribute('td_compare')) {
      const compare = parseInt(element.previousElementSibling.getAttribute('data-compare'), 10);
      let iconClass = 'fa-arrow-up';
      let iconColor = 'c-dark';
      if (compare > this.value) {
        iconClass = 'fa-arrow-down';
        iconColor = 'c-red';
      }
      if (compare !== this.value) {
        const i = this.renderer.createElement('i');
        this.renderer.addClass(i, 'fa');
        this.renderer.addClass(i, 'td-graph__icon');
        this.renderer.addClass(i, iconClass);
        this.renderer.addClass(i, iconColor);

        this.renderer.addClass(element, 'td-graph');
        this.renderer.appendChild(element, i);
      }
    }
  }
}
