import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '../../../services/http.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { environment } from '../../../../environments/environment';
import { filter } from 'rxjs/operators';

declare const moment;

@Component({
  selector: 'app-calendar-statisctic-digits',
  templateUrl: './calendar-statisctic-digits.component.html',
  styleUrls: ['./calendar-statisctic-digits.component.scss']
})
export class CalendarStatiscticDigitsComponent implements OnDestroy {

  private subscription: Subscription;

  private event_complexes;
  public date_from;
  public date_to;
  private current_type;

  public spoilered = false;
  public statistic;
  public apiServer = environment.apiServer;

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, private router: Router) {
    this.subscription = this.router.events.pipe(filter(x => x instanceof NavigationEnd))
      .subscribe(_ => {
        let {from, to, type} = this.activatedRoute.snapshot.params;

        from = from || moment().startOf('month').format('DD.MM.YYYY');
        to = to || moment().endOf('month').format('DD.MM.YYYY');
        type = type || 'any';

        this.date_from = from;
        this.date_to = to;
        this.current_type = type;
        this.event_complexes = this.activatedRoute.snapshot.queryParams['event_complexes'];
        this.loadStatistic();
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public loadStatistic() {
    const options = {'date_from': this.date_from, 'date_end': this.date_to};

    if (this.current_type !== 'any') {
      options['type'] = +this.current_type;
    }

    if (this.event_complexes !== '') {
      options['complex_caption'] = this.event_complexes;
    }

    this.http.post('History.events_statistics', options).subscribe(x => this.statistic = x);
  }

}
