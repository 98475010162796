import {Component, OnDestroy, OnInit, Optional} from '@angular/core';
import {HelpWindowService} from '../../services/help-window.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-help-window',
  templateUrl: './help-window.component.html',
  styleUrls: [
    './help-window.component.scss'
  ]
})
export class HelpWindowComponent implements OnInit, OnDestroy {

  public displayed = false;
  public subscription: Subscription;

  public is_global = true;

  protected unique_id;

  constructor(@Optional() public hs: HelpWindowService) { }

  ngOnInit() {
    if (this.unique_id && this.hs && this.is_global) {
      this.subscription = this.hs.emitter
        .subscribe(x => this.onNewEvent(x));

      this.displayed = this.hs.wouldDisplay(this.unique_id);
      this.hs.setCurrent(this.unique_id);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.hs) {
      this.hs.setCurrent(null);
    }
  }

  onNewEvent(type) {
    if (type === this.unique_id) {
      this.displayed = true;
    }
  }

  helpComplete() {
    this.displayed = false;
    this.hs.markAsViewed(this.unique_id);
  }

  forAllPages() {
    this.displayed = false;
    this.hs.markAsViewed('for_all_pages');
  }

  helpClose() {
    this.displayed = false;
  }
}
