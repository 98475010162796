<form [formGroup]="formGroup">
  <div class="form__group mt-4">
    <app-material-textarea
      formControlName="list_emails"
      [placeholder]="'test@test.ru,test2@test.ru'"
      [rows]="20" [disabled]="true"
    >
      Список текущих Email исключений:
    </app-material-textarea>
  </div>
  <div class="form__group mt-4">
    <app-material-textarea
      formControlName="new_emails"
      [placeholder]="'test@test.ru, test2@test.ru'"
      [rows]="5"
    >
      Добавить новые Email исключения (перечислить новые через ,):
    </app-material-textarea>
  </div>
  <div class="form__group mt-4">
    <button (click)="exceptedEmails()"
            [disabled]="!newReady"
            class="button button_nowrap button_main mb-3"
            type="button">
      Добавить новые Email исключения
    </button><br/>
    <a target="_blank"
       *ngIf="!newReady"
       href="{{server}}restful/get_subagents_xlsx?without_no_notify=1"
       class="white_a button button_nowrap button_main"
    >
      <i class="fa fa-download mr-1"></i>
      Скачать файл с учетом исключений
    </a>
    <button
       *ngIf="newReady"
       class="button button_nowrap button_main"
       type="button"
       disabled="disabled"
    >
      <i class="fa fa-download mr-1"></i>
      Скачать файл с учетом исключений
    </button>
  </div>
</form>
