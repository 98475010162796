import { Component, OnDestroy, OnInit } from '@angular/core';
import {LockerService} from '../../services/locker.service';
import { HttpClient } from '../../services/http.service';
import { AuthenticateService } from '../../services/authenticate.service';
import { Router } from '@angular/router';
import { NavigationLoaderService } from '../navigation-loader/navigation-loader.service';
import { interval, Subscription } from 'rxjs';


@Component({
  selector: 'app-server-down-component',
  templateUrl: './server-down.component.html'
})
export class ServerDownComponent implements OnInit, OnDestroy {

  public display = false;
  public interval = false;
  public subscribtion: Subscription;

  constructor(public auth: AuthenticateService, public http: HttpClient, public router: Router) {
    this.display = LockerService.server_downed;
    if (LockerService.server_downed) {
      this.initInterval();
    }
    LockerService.downed_emitter.subscribe((state) => {
      this.display = state;
      if (state) {
        this.initInterval();
      }
    });
  }

  ngOnInit() {
  }

  initInterval() {
    if (!this.interval) {
      this.interval = true;
      NavigationLoaderService.hide();
      const myObservable = interval(2000);
      this.subscribtion = myObservable.subscribe(response => this.checkAccess());
    }
  }

  checkAccess() {
    this.http.post('System.check').subscribe((response) => {
      if (response === true) {
        this.interval = false;
        this.subscribtion.unsubscribe();
        this.router.navigate([this.router.url]);
        LockerService.server_up();
        location.reload();
      }
    });
  }

  ngOnDestroy() {
    if (this.subscribtion) {
      this.subscribtion.unsubscribe();
    }
  }

}
