import { AfterContentInit, Component, ComponentRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OverlayWindowService } from '../../../_crm/single-window/shared/overlay-window.service';
import { CalendarStatisticPopupComponent } from './calendar-statistic-popup.component';
import { CalendarStatisticTableComponent } from './calendar-statistic-table.component';
import {GoBackButtonService} from '../../../services/go-back-button.service';

declare const moment;

@Component({
  templateUrl: './calendar-statistic.component.html',
  styleUrls: ['./calendar-statistic.component.scss']
})
export class CalendarStatisticComponent implements OnInit, AfterContentInit, OnDestroy {

  @ViewChild('eventCaption', {static: true}) private eventCaption;
  @ViewChild('eventStatus', {static: true}) private eventStatus;
  @ViewChild('eventComplexes', {static: true}) private eventComplexes;
  @ViewChild('eventApproved', {static: true}) private eventApproved;
  @ViewChild('eventDeclined', {static: true}) private eventDeclined;
  @ViewChild('eventPlaces', {static: true}) private eventPlaces;
  @ViewChild('eventParticipants', {static: true}) private eventParticipants;
  @ViewChild('eventType', {static: true}) private eventType;

  @ViewChild('commonTable', {static: true}) public commonTable: CalendarStatisticTableComponent;

  public columns;
  public url: any;

  constructor(private overlayWindowService: OverlayWindowService, public goBack: GoBackButtonService) {
  }

  ngOnInit() {
    this.goBack.header = '<h1>Статистика</h1>';
    this.columns = [
      {'name': 'event_date', 'caption': 'Дата', 'sortable': true},
      {'name': 'event_caption', 'caption': 'Название мероприятия', 'sortable': true, 'template': this.eventCaption},
      {'name': 'event_complexes', 'caption': 'Комплекс', 'sortable': true, 'template': this.eventComplexes},
      {'name': 'event_status', 'caption': 'Статус мероприятия', 'sortable': true, 'template': this.eventStatus},
      {'name': 'event_type_caption', 'caption': 'Тип мероприятия', 'sortable': true, 'template': this.eventType},
      {'name': 'event_places_count', 'caption': 'Кол-во мест', 'sortable': true, 'template': this.eventPlaces},
      {'name': 'subscriptions_count', 'caption': 'Участников', 'sortable': true, 'template': this.eventParticipants},
      {'name': 'approve_subscriptions_count', 'caption': 'Пришли', 'sortable': true, 'template': this.eventApproved},
      {'name': 'cnt_not_approve_users', 'caption': 'Не пришли', 'sortable': true, 'template': this.eventDeclined},
    ];
  }

  ngAfterContentInit() {
    this.goBack.goScroll();
  }

  ngOnDestroy() {
  }

  displayApproved(row, editable) {
    this.openPopup(row['event_id'], row['subscription_users_list'], editable, row['event_caption'],
      true);
  }

  displayDeclined(row, editable) {
    this.openPopup(row['event_id'], row['subscription_users_list'], editable, row['event_caption'],
      false);
  }

  openPopup(event_id, rows, editable, caption, is_approved) {
    this.overlayWindowService.openComponentInPopup(CalendarStatisticPopupComponent)
      .then((componentRef: ComponentRef<CalendarStatisticPopupComponent>) => {
        componentRef.instance.event_id = event_id;
        componentRef.instance.rows = rows;
        componentRef.instance.editable = editable;
        componentRef.instance.caption = caption;
        componentRef.instance.is_approved = is_approved;
      });
  }

}
