<div class="form__group">
  <div class="form__label" [class.form__label_require]="require">
    <ng-content></ng-content>
  </div>

  <div class="form__input" [class.form__input_focus]="focus">
    <input [type]="type" class="input" [value]="current_value" [disabled]="disabled"
           (focus)="focus = true" [placeholder]="placeholder"
           (blur)="focus = false"
           [appMaterialInputAutocomplete]="api_action" (autocompleteChange)="current_value = $event"
           (keyup)="onChanges($event.target)">
  </div>
</div>

