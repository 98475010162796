<div class="row">
  <div class="col-md-8">
    <p>Сдвиг по вертикали</p>
    <app-position-selector
      [startValue]="margin_left"
      (finalValue)="editLeftValue($event)"
    ></app-position-selector>
    <p>Сдвиг по горизонтали</p>
    <app-position-selector
      [startValue]="margin_top"
      (finalValue)="editTopValue($event)"
    ></app-position-selector>
    <a *ngIf="margin_left != 0 || margin_top != 0" class="button button_sm mt-4" (click)="copyStyles()">Скопировать стили</a>
    <div class="mt-4">
      <input class="input ml-0 p-0" type="text" placeholder="Вставить стили:"
             [ngModel]="styles" (ngModelChange)="insertStyles($event)">
    </div>
  </div>
  <div class="col-md-4">
    <div class="holiday">
      <div class="holiday__block">
        <a class="col-auto holiday__link text-nowrap">
          <img
            src="{{upload_url}}"
            alt=""
            [style]="{'left': margin_left + 'px', 'top': margin_top_style + 'px'}"
          />График работы: <span>{{dates_string}}</span>
        </a>
      </div>
    </div>
  </div>
</div>

