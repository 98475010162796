import {Component, Host, OnInit} from '@angular/core';
import {CreateEntityAltComponent} from '../create-entity.component';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {DictionariesService} from '../../../../services/dictionaries.service';

@Component({
  selector: 'app-alt-entity-advanced',
  templateUrl: './create-entity-advanced.component.html',
  styleUrls: [
    './create-entity-advanced.component.scss'
  ]
})
export class CreateEntityAltAdvancedComponent implements OnInit {

  public form: UntypedFormGroup;

  public nalogForms;

  constructor(@Host() private hostComponent: CreateEntityAltComponent,
              private fb: UntypedFormBuilder, private dict: DictionariesService) { }

  ngOnInit() {
    this.form = this.hostComponent.form;

    this.dict.get('Organisation.get_type_nalog').then(x => this.nalogForms = x);

    this.hostComponent.form.addControl('advanced', this.fb.group({
      'organisation_bank_bik': [''],
      'organisation_bank_scheta': [''],
      'organisation_inn': [''],
      'organisation_kor_schet': [''],
      'organisation_kpp': [''],
      'organisation_ogrn': [''],
      'organisation_okpo': [''],
      'organisation_okved': [''],
      'organisation_rachetniy_schet': [''],
      'organisation_register_date': [''],
      'organisation_tax_id': [''],
      'organisation_who_register': [''],
    }));
  }

}
