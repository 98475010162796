<div class="mobile-sort c-black">
  <div class="d-flex w-100 align-items-baseline">
    <i class="icon icon_Small_Close ml-3 c-pointer ml-auto" (click)="closeTrigger()"></i>
  </div>
  <form [formGroup]="formGroup">
    <p class="caption">Сортировка по</p>
    <app-material-input-radio formControlName="sort_caption"
                              [data]="{'novelty': 'Новизне', 'price': 'Цене', 'deadline': 'Сроку сдачи', 'subway': 'До метро'}">
    </app-material-input-radio>
    <p class="caption sorting_direction">Направление сортировки</p>
    <app-material-input-radio formControlName="sort"
                              [data]="{'asc': 'По возрастанию', 'desc': 'По убыванию'}">
    </app-material-input-radio>
  </form>

</div>
