import {Component, EventEmitter, forwardRef, Input, OnDestroy, Optional, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import { MaterialInputComponent } from './material-input.component';
import {CalendarInviteBindService} from '../../../shared/calendar/invites/calendar-invite-bind.service';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-material-input-mask',
  templateUrl: './material-input-mask.component.html',
  styleUrls: [
    './material-input-mask.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialInputMaskComponent),
      multi: true
    }
  ]
})
export class MaterialInputMaskComponent extends MaterialInputComponent implements OnDestroy {

  @Input() public mask = '';
  @Input() public clearIfNotMatch = true;

  @Output() public keyUp: EventEmitter<any> = new EventEmitter();
  // Внимание, форма здесь нужна, потому что ngx-mask не работает без formControlName
  public control;
  public formG;
  public customSubscription: Subscription = new Subscription();

  public get current_value(): any {
    return this.control.value;
  }

  public set current_value(value: any) {
    this._current_value = value;
    this.control.patchValue(this._current_value);
    this.propagateChange(this._current_value);
  }

  constructor(@Optional() bind: CalendarInviteBindService) {
    super(bind);

    this.control = new UntypedFormControl('');
    this.formG = new UntypedFormGroup({inp: this.control});
    this.customSubscription.add(this.control.valueChanges.subscribe(value => {
      this._current_value = value;
      this.propagateChange(this._current_value);
    }));
  }

  onPaste(event) {
    if (this.mask === '+7 (000) 000-00-00') {
      const text = (event.clipboardData.getData('text') || '')
        .replace(/[^\d]/g, '');
      if (text.length > 10) {
        this.formG.get('inp').patchValue(('' + text).substr(-10));
      } else {
        this.formG.get('inp').patchValue(text);
      }
      event.preventDefault();
    }
  }

  onChanges(target) {
    this.keyUp.emit({'value': target.value});
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.customSubscription.unsubscribe();
  }

  setDisabledState(isDisabled: boolean) {
    if (!this.control) { return; }
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }

    super.setDisabledState(isDisabled);
  }

}
