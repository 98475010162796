<div class="row mt-4 mb-2" [formGroup]="form">
  <div class="col-12 col-sm-3 col-md-2">
    Всего: <b>{{count}}</b>
    <app-material-input-checkbox formControlName="is_archive">
      В архиве
    </app-material-input-checkbox>
  </div>
</div>
<div class="material-datatable-wrapper">
  <div class="table-responsive">
    <table class="table">
      <tr>
        <th *ngFor="let el of head" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
          <div [formGroup]="form">
            <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
              <div class="col table__input">
                <ng-container *ngIf="el.input">
                  <app-material-input
                    [placeholder] = "el.caption"
                    [formControlName] = "el.name"
                    [cssClass]="'mb-0'">
                  </app-material-input>
                </ng-container>
                <ng-container *ngIf="el.select">
                  <app-material-select
                    [formControlName]="el.name"
                    [cssClass]="'mb-0'">
                    <option *ngFor="let opt of el.select" [value]="opt.id">{{opt.caption}}</option>
                  </app-material-select>
                </ng-container>
                <ng-container *ngIf="!el.input && !el.select">
                  {{el.caption}}
                </ng-container>
              </div>
              <div class="col-auto" *ngIf="el.sort">
                <a href="javascript:void(0)" (click)="sortChange(el.name)">
                  <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                      'icon_new-sort-down': 'asc' == sort[el.name],
                                      'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                </a>
              </div>
            </div>
          </div>
        </th>
      </tr>
      <!-- // -->
      <tr *ngFor="let row of rows; let row_index = index">
        <td>{{row['create_date']}}</td>
        <td>
          <a *ngIf="row['resale_realty_id'] && row['allow_update']" [routerLink]="['/resale/flat/modify', row['resale_realty_id']]">{{row['resale_realty_caption']}}</a>
          <div *ngIf="row['resale_realty_id'] && !row['allow_update']">{{row['resale_realty_caption']}}</div>
        </td>
        <td>
          {{row['cause_caption']}}
        </td>
        <td>
          {{row['text']}}
        </td>
        <ng-container *ngIf="is_crm_part">
          <td>
            <a *ngIf="row['author_organisation_id']"
               [routerLink]="['/physical/edit', row['author_organisation_id'], 'common']">{{row['author_organisation']}}
            </a>
          </td>
          <td>
            <a *ngIf="row['owner_id']"
               [routerLink]="['/physical/edit', row['owner_id'], 'common']">{{row['owner_fio']}}
            </a>
          </td>
          <td>
            <a *ngIf="row['owner_organisation_id']"
               [routerLink]="['/physical/edit', row['owner_organisation_id'], 'common']">{{row['owner_organisation']}}
            </a>
          </td>
          <td>
            <a *ngIf="row['author_id']"
               [routerLink]="['/physical/edit', row['author_id'], 'common']">{{row['author_fio']}}
            </a>
          </td>
          <td>
            <div *ngIf="!row['is_archive']" class="button button_icon mt-2" (click)="updateComplaint(row['id'], true)">
              <i class="fa fa-trash"></i>
            </div>
            <div *ngIf="row['is_archive']" class="button button_icon mt-2" (click)="updateComplaint(row['id'], false)">
              <i class="fa fa-history"></i>
            </div>
          </td>
        </ng-container>
      </tr>
    </table>
  </div>
</div>

<div class="alt-pagination">
  <app-paginator
    [queryParams]="filter_columns"
    [itemsPerPage]="50"
    [itemCount]="count"
    [currentPage]="current_page"
    [baseUrl]="'/transfer-log'">
  </app-paginator>
</div>
