import {Component, ComponentRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SingleWindowChildComponent} from '../../interfaces/single-window-child.component';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {HistoryService} from '../../single-window/shared/history.service';
import {SingleWindowService} from '../../single-window/shared/single-window.service';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AppealsElementService} from './appeals-element.service';
import {NotificationService} from '../../../services/notification.service';
import {ScrollableHostComponent} from '../../../shared/housing/shared/scrollable-host/host/host.component';
import {FlatInfoStandaloneComponent} from '../../../shared/housing/shared/flat-info-standalone';

@Component({
  templateUrl: './appeals-element.component.html',
  styleUrls: [
    './appeals-element.component.scss'
  ],
  providers: [
    AppealsElementService,
  ]
})
export class AppealsElementComponent extends SingleWindowChildComponent
  implements OnInit, OnDestroy {

  private _data: any;

  private appeal_id: number;
  private subscription: Subscription;

  public form: UntypedFormGroup;
  @ViewChild('scrollableHost', {static: false}) public scrollableHost: ScrollableHostComponent;
  public current_flat: any = null;

  public set data(value) {
    this._data = value;

    this.historyService.setHeader(this.uuid, `Обращение # ${this._data['appeal_id']}`);
    this.singleWindowService.setProxyHeader(`Обращение # ${this._data['appeal_id']}`);
  }

  public get data() {
    return this._data;
  }

  constructor(public activatedRoute: ActivatedRoute, public historyService: HistoryService,
              public router: Router, private notify: NotificationService, public singleWindowService: SingleWindowService,
              private fb: UntypedFormBuilder, private s: AppealsElementService) {
    super(activatedRoute, router, singleWindowService);

    this.subscription = this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(_ => this.reinitialize());
  }

  ngOnInit() {
    const files = Object.assign(this._data['appeal_files'], []).map(x => {
      return {
        'id': x['file_id'],
        'filename': x['file_name'],
        'filepath': x['file_url'],
      };
    });

    this.form = this.fb.group({
      'files': [files],
    });
  }

  reinitialize() {
    this.data = this.activatedRoute.snapshot.data['data'];
    this.appeal_id = +this.activatedRoute.snapshot.params['id'];
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  saveFiles() {
    const exists_files = this._data['appeal_files'].map(x => x['file_id']);
    const files = this.form.value['files'].filter(x => exists_files.indexOf(x['id']) === -1);

    if (files.length) {
      this.s.attachFile(this.appeal_id, files);
    }

    NotificationService.swalSuccess('Готово', 'Файлы успешно прикреплены');
  }

  display_new_flat(value: any) {
    this.current_flat = value;
    this.scrollableHost.flushChilds();
    if (value === null) {
      return;
    }
    const options = {
      'flat_id': value,
    };
    this.scrollableHost.appendComponent(FlatInfoStandaloneComponent, options)
      .then((component: ComponentRef<FlatInfoStandaloneComponent>) => {
        component.instance.onSave.subscribe(x => {
          this.current_flat = null;
          this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
        });
      });
  }
}
