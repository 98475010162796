import { NgModule } from '@angular/core';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { MaterialInputModule } from '../../modules/@material-input';
import { ReactiveFormsModule } from '@angular/forms';
import { NotificationsComponent } from './notifications/notifications.component';
import { CoreDirectivesModule } from './core-directives.module';
import { HistoryComponent } from './history/history.component';
import { NavigationLoaderModule } from '../../shared/navigation-loader/navigation-loader.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialInputModule,
    ReactiveFormsModule,
    CoreDirectivesModule,
    NavigationLoaderModule,
  ],
  declarations: [
    SidebarComponent,
    NavbarComponent,
    NotificationsComponent,
    HistoryComponent,
  ],
  exports: [
    SidebarComponent,
    NavbarComponent,
    NotificationsComponent,
    HistoryComponent,
  ],
})
export class CoreModule {
}
