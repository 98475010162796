<div class="tab-content-wrapper mt-4">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <p>В данном разделе Вы можете привязать сотрудника к существующей организации и выбрать его должность.</p>
    <div *ngIf="isSubagent">
      <hr class="clearfix mb-2r mt-2r">
      <app-alert-warning>
        <div><i class="fa-fw fa fa-warning"></i> <b>Внимание!</b> Это субагент!</div>
        <div class="mt-2">Крайне не рекомендуется менять связи с организацией/ями, у субагента должна быть единственная
          организация, которая была создана при регистрации субагента
        </div>
      </app-alert-warning>
    </div>
    <button [routerLink]="['/transfer', 'physical', physicalId]"
            type="button" class="button button_main mt-3 ml-3">
      Перенести сотрудника в другую организацию
    </button>
    <hr class="clearfix mb-2r mt-2r">
    <div formArrayName="organizations">
      <div class="row no-gutters gutters-4 align-items-center" *ngFor="let relation of organizations.controls; let id = index"
           [formGroupName]="id">
        <ng-container  *ngIf="!relation.value.edit">
          <div class="col-5">
            <a [routerLink]="['/entity/edit/', relation.value.organisation_id]">
              <i class="fa fa-user"></i> {{relation.value.organisation_caption}}
            </a>
          </div>
          <div class="col-5">
            {{relation.value.organisation_post_caption}}
          </div>
          <div class="col-2">
            <a class="button button_icon button_icon_sub mr-1" (click)="relationEdit(relation)">
              <i class="fa fa-edit"></i></a>
            <a class="button button_icon button_icon_sub mr-1"
               (click)="relationDelete(relation)">
              <i class="fa fa-trash-o"></i></a>
          </div>
        </ng-container>
        <ng-container  *ngIf="relation.value.edit">
          <div class="col-6">
          <app-material-select2-autocomplete formControlName="organisation_id" [require]="true"
                                           [placeholder]="'Название организации'"
                                           [caption]="'Организация'"
                                           [api_action]="'Organisation.search'">
            <option [value]="relation.value.organisation_id">{{relation.value.organisation_caption}}</option>
          </app-material-select2-autocomplete>
          </div>
          <div class="col-6">
          <app-material-select2 formControlName="organisation_post_id" [caption]="'Должность'" [require]="true">
            <option [value]="post.post_id" *ngFor="let post of posts">
              {{post.post_caption}}
            </option>
          </app-material-select2>
          </div>
          <div class="col-12 mt-2">
            <div class="row justify-content-center">
              <button class="button button_sub" (click)="relationEdit(relation)">Отменить</button>
              <button class="button ml-3" (click)="relationChange(relation)">Отредактировать</button>
            </div>
          </div>
        </ng-container>
        <div class="col-12">
          <hr class="clearfix">
        </div>
      </div>
    </div>
    <div class="row no-gutters gutters-4 align-items-center mt-3">
      <ng-container  *ngIf="!relationCreateNew">
        <div class="col-12">
          <button class="button button_main" (click)="relationCreateNewToggle()">Добавить связь</button>
        </div>
      </ng-container>

      <ng-container  *ngIf="relationCreateNew">
        <div class="col-6">
          <app-material-select2-autocomplete formControlName="organisation_id" [require]="true"
                                           [placeholder]="'Название организации'"
                                           [caption]="'Организация'"
                                           [api_action]="'Organisation.search'">
            <option value=""></option>
          </app-material-select2-autocomplete>
        </div>
        <div class="col-6">
          <app-material-select2 formControlName="organisation_post_id" [caption]="'Должность'" [require]="true">
            <option [value]="null">Выберите должность</option>
            <option [value]="post.post_id" *ngFor="let post of posts">
              {{post.post_caption}}
            </option>
          </app-material-select2>
        </div>
        <div class="col-12 mt-2">
          <div class="row justify-content-center">
            <button class="button button_sub" (click)="relationCreateNewToggle()">Отменить</button>
            <button class="button ml-3" (click)="relationCreateSend()">Добавить</button>
          </div>
        </div>
      </ng-container>
    </div>
  </form>
</div>

