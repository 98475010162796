<div
  class="event-info__layout"
  [class.event-info__layout_open]="layout_opened">

  <a
    class="event-info__close"
    href="javascript:void(0)"
    (click)="closeEvent()">
    ×
  </a>

  <div class="event-info__content">
    <div class="event-info__content__wrapper">
      <ng-container *ngIf="loading">
        <app-preloader></app-preloader>
      </ng-container>
      <ng-container *ngIf="!loading && constrains">
        <div class="form_crm event-info__content__wrapper__constrains_table">

          <ng-container *ngIf="constrains['local'].length > 0">

            <h3>Локальные ограничения</h3>
            <app-simple-table>

              <app-simple-table-th>Автор</app-simple-table-th>
              <app-simple-table-th>Дата создания</app-simple-table-th>
              <app-simple-table-th>Застройщик / ЖК</app-simple-table-th>
              <app-simple-table-th>Поиск</app-simple-table-th>
              <app-simple-table-th>Iframe</app-simple-table-th>
              <app-simple-table-th>XML</app-simple-table-th>
                <app-simple-table-tr *ngFor="let row of constrains['local']"
                                     [template]="template">
                  <ng-template #template>
                    <td>{{row['author']}}</td>
                    <td>{{row['create_date']}}</td>
                    <td>{{row['realty_caption']}}</td>
                    <td>
                      <ng-container
                        *ngIf="!row['lock']; then okayTmpl; else notOkayTmpl">
                      </ng-container>
                    </td>
                    <td>
                      <ng-container
                        *ngIf="!row['iframe']; then okayTmpl; else notOkayTmpl">
                      </ng-container>
                    </td>
                    <td>
                      <ng-container
                        *ngIf="!row['xml']; then okayTmpl; else notOkayTmpl">
                      </ng-container>
                    </td>
                  </ng-template>
              </app-simple-table-tr>
            </app-simple-table>
          </ng-container>

          <ng-container *ngIf="constrains['global'].length > 0">

            <h3>Персональные ограничения</h3>
            <a href="javascript: void(0)" class="button button_main mt-2 mb-3"
               (click)="deleteContraints()">Удалить ограничения</a>
            <app-simple-table>

              <app-simple-table-th>Автор</app-simple-table-th>
              <app-simple-table-th>Дата создания</app-simple-table-th>
              <app-simple-table-th>Застройщик / ЖК</app-simple-table-th>
              <app-simple-table-th>Поиск</app-simple-table-th>
              <app-simple-table-th>Iframe</app-simple-table-th>
              <app-simple-table-th>XML</app-simple-table-th>
              <app-simple-table-tr *ngFor="let row of constrains['global']"
                                   [template]="template">
                <ng-template #template>
                  <td>{{row['author']}}</td>
                  <td>{{row['create_date']}}</td>
                  <td>{{row['realty_caption']}}</td>
                  <td>
                    <ng-container
                      *ngIf="!row['lock']; then okayTmpl; else notOkayTmpl">
                    </ng-container>
                  </td>
                  <td>
                    <ng-container
                      *ngIf="!row['iframe']; then okayTmpl; else notOkayTmpl">
                    </ng-container>
                  </td>
                  <td>
                    <ng-container
                      *ngIf="!row['xml']; then okayTmpl; else notOkayTmpl">
                    </ng-container>
                  </td>
                </ng-template>
              </app-simple-table-tr>
            </app-simple-table>
          </ng-container>

        </div>
      </ng-container>
    </div>
  </div>

</div>

<ng-template #okayTmpl>
  <i class="fa fa-2x fa-fw fa-check text-success"></i>
</ng-template>
<ng-template #notOkayTmpl>
  <i class="fa fa-2x fa-fw fa-lock text-danger"></i>
</ng-template>
