import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { HttpClient } from '../../../services/http.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {BreadcrumbsService} from '../../../shared/breadcrumbs/services';

@Component({
  selector: 'app,yamap-physical-edit-documents',
  templateUrl: './physical-edit-documents.component.html',
  styleUrls: ['./physical-edit-documents.component.scss']
})
export class PhysicalEditDocumentsComponent implements OnInit {

  public data: any = null;
  public formGroup: UntypedFormGroup;
  public physicalId = null;

  constructor(public activatedRoute: ActivatedRoute, public fb: UntypedFormBuilder,
              private http: HttpClient, public notify: NotificationService, private b: BreadcrumbsService) {
  }

  ngOnInit() {
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/physical/list', caption: 'Физические лица'},
    ]);
    this.data = this.activatedRoute.snapshot.data['data'];
    this.physicalId = this.activatedRoute.snapshot.parent.params['id'];
    this.formGroup = this.fb.group({
      'files': [[], Validators.required],
    });

    // Фото
    if (this.data.length) {
      const fileArr = [];
      for (const file of this.data) {
        fileArr.push({'id': file.file_id, 'filename': file.name, 'filepath': file.file});
      }
      this.formGroup.patchValue({
        'files': fileArr,
      });
    }
  }

  saveFiles() {
    const names = [];
    const files = [];
    const types = [];
    for (const file of this.formGroup.value.files) {
      names.push(file.filename);
      files.push(file.filepath);
      types.push(1);
    }
    this.http.post('Physical.attach_files',
      {'physical_id': this.physicalId, 'names': names, 'files': files, 'types': types, 'comments': []}
    ).subscribe(
      (response) => this.onSuccess(response),
      (error) => this.onError(error)
    );
  }

  onSuccess(response) {
    this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
  }

  onError(error) {
    this.notify.notifyError('Ошибка', 'Ошибка:' + error, 5000);
  }

}
