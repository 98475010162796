import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {GoBackButtonService} from '../../../../services/go-back-button.service';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-attraction-lead-info',
  templateUrl: './attraction-lead-info.component.html',
  styleUrls: ['./attraction-lead-info.component.scss']
})
export class AttractionLeadInfoComponent implements OnInit, OnDestroy {

  public data: any;
  private routerSubscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private goBack: GoBackButtonService) {}

  ngOnInit() {
    this.goBack.header = '<h1>Лиды по привлечению</h1>';
    this.data = this.activatedRoute.snapshot.data['data'];
    this.routerSubscription = this.router.events.subscribe((event) => {
      this.goBack.header = '<h1>Лиды по привлечению</h1>';
    });
  }
  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }


}
