import {NgModule} from '@angular/core';
import { ClickOutsideDirective, SidebarToggleDirective } from './core.directives';

@NgModule({
  declarations: [
    SidebarToggleDirective,
    ClickOutsideDirective,
  ],
  exports: [
    SidebarToggleDirective,
    ClickOutsideDirective,
  ]
})
export class CoreDirectivesModule { }
