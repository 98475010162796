import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MaterialInputModule} from '../../modules/@material-input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InstatekaComponent} from './users/instateka.component';
import {InstatekaResolve} from './users/instateka.resolve';
import {PaginatorModule} from '../../shared/housing-modules/modules';
import {InstatekaWrapperComponent} from './wrapper/wrapper.component';
import {InstatekaRequestsComponent} from './requests/requests.component';
import {InstatekaRequestsResolve, InstatekaRequestsStatisticsResolve} from './requests/requests.resolve';
import {InstatekaErrorComponent} from './errors/errors.component';
import {InstatekaErrorResolve} from './errors/errors.resolve';
import {ModalModule} from '../../modules/@modal';
import {PopoverModule} from '../../modules/@popover/popober.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialInputModule,
    FormsModule,
    PaginatorModule,
    ReactiveFormsModule,
    ModalModule,
    PopoverModule,
  ],
  declarations: [
    InstatekaComponent,
    InstatekaWrapperComponent,
    InstatekaRequestsComponent,
    InstatekaErrorComponent,
  ],
  providers: [
    InstatekaResolve,
    InstatekaRequestsResolve,
    InstatekaRequestsStatisticsResolve,
    InstatekaErrorResolve,
  ]

})
export class InstatekaModule {

}
