import {Component, ComponentRef, OnInit, ViewChild} from '@angular/core';
import {DataTableComponent} from '../../../modules/@datatable/components';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {HttpClient} from '../../../services/http.service';
import {OverlayWindowService} from '../../../_crm/single-window/shared/overlay-window.service';
import {AllEventsParticipantsComponent} from './all-events-participants.component';

@Component({
  selector: 'app-all-events-table',
  templateUrl: './all-events-table.component.html',
  styleUrls: ['./all-events-table.component.scss']
})
export class AllEventsTableComponent extends DataTableComponent implements OnInit {

  public form: UntypedFormGroup;

  @ViewChild('participants') private participants;
  @ViewChild('participantsApproved') private participantsApproved;

  public api_action = 'Calendar.dt_all_events';
  public columns: Array<any> = [ ];

  constructor(public http: HttpClient, private fb: UntypedFormBuilder, private overlayWindowService: OverlayWindowService) {
    super(http);
  }

  ngOnInit() {
    this.columns = [
      {'caption': 'Дата', 'name': 'event_date', 'sortable': true},
      {'caption': 'Название мероприятия', 'name': 'event_caption', 'sortable': true},
      {'caption': 'Тип мероприятия', 'name': 'event_type_caption', 'sortable': true},
      {'caption': 'Участников', 'name': 'subscriptions_count', 'sortable': true, 'template': this.participants},
      {'caption': 'Подтверждено', 'name': 'approve_subscriptions_count', 'sortable': true, 'template': this.participantsApproved},
    ];

    this.form = this.fb.group({
      'event_date_from': [''],
      'event_date_to': ['']
    });

    super.ngOnInit();
  }

  prepareQuery() {
    const options = super.prepareQuery();

    if (this.form) {
      options['filter']['event_date_from'] = this.form.value['event_date_from'] || null;
      options['filter']['event_date_to'] = this.form.value['event_date_to'] || null;
    }

    return options;
  }

  submitForm() {
    this.loadList();
  }

  openEvent(event_id) {
    this.overlayWindowService.openComponentInPopup(AllEventsParticipantsComponent)
      .then((componentRef: ComponentRef<AllEventsParticipantsComponent>) => {
        componentRef.instance.event_id = event_id;
      });
  }

}
