import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '../../../services/http.service';
import { NotificationService } from '../../../services/notification.service';
import {BreadcrumbsService} from '../../../shared/breadcrumbs/services';

@Component({
  selector: 'app,yamap-physical-edit-organization',
  templateUrl: './physical-edit-organization.component.html',
  styleUrls: ['./physical-edit-organization.component.scss']
})
export class PhysicalEditOrganizationComponent implements OnInit {

  public data: any = null;
  public posts: any = null;
  public formGroup: UntypedFormGroup;
  public physicalId = null;
  public isSubagent = false;
  public relationCreateNew = false;

  constructor(public activeRoute: ActivatedRoute, public fb: UntypedFormBuilder, public http: HttpClient,
              public notify: NotificationService, private b: BreadcrumbsService) {
  }

  ngOnInit() {
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/physical/list', caption: 'Физические лица'},
    ]);
    this.data = this.activeRoute.snapshot.data['data'];
    this.posts = this.activeRoute.snapshot.data['posts'];
    this.physicalId = this.activeRoute.snapshot.parent.params['id'];
    this.formGroup = this.fb.group({
      'organisation_id': ['', Validators.required],
      'organisation_post_id': ['', Validators.required],
      'organizations': this.fb.array(this.data.map((org) => {
        if (org['organisation_is_subagent']) {
          this.isSubagent = true;
        }
        return this.fb.group({
          'organisation_caption': org['organisation_caption'],
          'organisation_id': org['organisation_id'],
          'organisation_id_old': org['organisation_id'],
          'organisation_is_subagent': org['organisation_is_subagent'],
          'organisation_post_caption': org['organisation_post_caption'],
          'organisation_post_id': '' + org['organisation_post_id'],
          'edit': false
        });
      }))
    });
  }

  public get organizations(): UntypedFormArray {
    return this.formGroup.get('organizations') as UntypedFormArray;
  }

  relationCreateNewToggle() {
    this.relationCreateNew = !this.relationCreateNew;
  }

  relationEdit(relation) {
    relation.get('edit').setValue(!relation.value.edit);
  }

  relationChange(relation) {
    this.http.post('Physical.update_physical_organisation',
      {'physical_id': +this.physicalId,
        'old_organisation_id': +relation.value.organisation_id_old,
        'organisation_id': +relation.value.organisation_id,
        'post_id': +relation.value.organisation_post_id}
    ).subscribe(
      (response) => {
        this.onSuccess(response);
        relation.patchValue({
          'organisation_id': relation.value.organisation_id,
          'organisation_id_old': relation.value.organisation_id,
          'organisation_post_id': '' + relation.value.organisation_post_id,
          'organisation_caption': response['organisation_caption'],
          'organisation_post_caption': response['organisation_post_caption'],
          'edit': false
        });
      },
      (error) => this.onError(error)
    );
    relation.get('edit').setValue(!relation.value.edit);
  }

  relationCreateSend() {
    if (!this.formGroup.value.organisation_id || !this.formGroup.value.organisation_post_id) {
      this.onError('Заполните обязательные поля');
      return false;
    }
    this.http.post('Physical.add_physical_organisation',
      {'physical_id': +this.physicalId,
        'organisation_id': +this.formGroup.value.organisation_id,
        'post_id': +this.formGroup.value.organisation_post_id}
    ).subscribe(
      (response) => {
        this.onSuccess(response);
        this.organizations.push(
          this.fb.group({
            'organisation_id': this.formGroup.value.organisation_id,
            'organisation_id_old': this.formGroup.value.organisation_id,
            'organisation_post_id': '' + this.formGroup.value.organisation_post_id,
            'organisation_caption': response['organisation_caption'],
            'organisation_post_caption': response['organisation_post_caption'],
            'edit': false
          })
        );
        this.formGroup.patchValue({
          'organisation_id': null,
          'organisation_post_id': null
        });
        this.relationCreateNewToggle();
      },
      (error) => this.onError(error)
    );
  }

  relationDelete(relation) {
    NotificationService.swalConfirm('Вы уверены?', 'Выбранная связь будет безвозвратно удалена!')
      .then(() => {
        this.http.post('Physical.del_physical_organisation',
          {'physical_id': +this.physicalId, 'organisation_id': +relation.value.organisation_id}
        ).subscribe(
          (response) => {
            this.onSuccess(response);
            this.organizations.removeAt(this.organizations.controls.indexOf(relation));
          },
          (error) => this.onError(error)
        );
      }).catch(() => false);
  }

  onSuccess(response) {
    this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
  }

  onError(error) { }

}
