import { Component, EventEmitter, forwardRef, Input, OnChanges, Output } from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { MaterialInputComponent } from './material-input.component';

@Component({
  selector: 'app-material-input-search',
  templateUrl: './material-input-search.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialInputSearchComponent),
      multi: true
    }
  ]
})
export class MaterialInputSearchComponent extends MaterialInputComponent {
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();

  clickOnSubmit() {
    this.onSubmit.emit(this.current_value);
  }
}
