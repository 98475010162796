import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {HttpClient} from '../../../services/http.service';

@Injectable()
export class AppealsElementResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, ...args): Promise<any> | any {
    return this.http.post('Appeal.get', {
      'appeal_id': +route.params['id'],
    });
  }

}
