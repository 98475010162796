import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {DictionariesService} from '../../../services/dictionaries.service';
import {Observable} from 'rxjs';
import {HttpClient} from '../../../services/http.service';

@Injectable()
export class CalendarCreateResolve {

  constructor(private dictionariesService: DictionariesService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return this.dictionariesService.get('Calendar.types_list');
  }

}

@Injectable()
export class CalendarCreateResolveInvites {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.http.post('Calendar.invite_templates_list');
  }

}
