import {AddressComponentComponent} from './address-component.component';
import {CommonModule} from '@angular/common';
import {FieldsModule} from '../../modules/@fields/fields.module';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialInputModule} from '../../modules/@material-input';
import {FieldSubwayModule} from '../housing-modules/components/field-subway';
import {FieldHighwayModule} from '../housing-modules/components/field-highway';
import {OsmModule} from '../osm';

@NgModule({
  imports: [
    CommonModule,
    OsmModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
    FieldsModule,
    FieldSubwayModule,
    FieldHighwayModule,
  ],
  declarations: [
    AddressComponentComponent,
  ],
  exports: [
    AddressComponentComponent,
  ],
})
export class AddressComponentModule { }
