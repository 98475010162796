import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient} from '../../../services/http.service';
import {Observable} from 'rxjs';

@Injectable()
export class CalendarTeachingResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.http.post('Calendar.teaching_statistic');
  }

}

@Injectable()
export class CalendarTeachingResolveStatistic {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.http.post('History.organisations_events_statistics');
  }

}
