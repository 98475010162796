import {ComponentFactory, ComponentRef, Injectable, Type} from '@angular/core';
import {ScrollableHostComponent} from './host.component';

@Injectable()
export class ScrollableHostService {

  private host: ScrollableHostComponent;

  public registerHost(host: ScrollableHostComponent) {
    /*
     * Register new host
     */
    this.host = host;
  }

  public appendComponent<T>(componentType: Type<T>, options = {}): Promise<ComponentRef<T>> {
    /*
     * Append component to host
     */
    return this.host.appendComponent(componentType, options);
  }

}
