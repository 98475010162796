import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthenticateService} from '../../../services/authenticate.service';
import {User} from '../../../models/user';
import { Router } from '@angular/router';
import { HttpClient } from '../../../services/http.service';
import { environment } from '../../../../environments/environment';
import {Subscription} from 'rxjs';
import {LockerService} from '../../../services/locker.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  public user: User;
  public check_production: boolean;
  public mouseOvered: boolean;

  private subscription: Subscription;

  constructor(public auth: AuthenticateService, private router: Router,
              private http: HttpClient, private renderer: Renderer2) {
    /*
     * Получение данных текущего пользователя - добавлена подписка
     */
    this.subscription = this.auth.emit_user
      .subscribe((user) => this.user = user);
  }

  ngOnInit() {
    this.check_production = environment.test_version;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  logout() {
    this.serverLogout();
    this.router.navigateByUrl('/authenticate');
    this.auth.logout();
  }
  serverLogout() {
    this.http.post('Session.signout')
      .subscribe(
        (response) => true,
        (error) => false
      );
  }
  sidebarMode() {
    const class_name = 'sidebar-mini',
      has_class = document.body.classList.contains(class_name);
    if (has_class) {
      this.renderer.removeClass(document.body, class_name);
    } else {
      this.renderer.addClass(document.body, class_name);
    }
  }
  onLock() {
    this.serverLogout();
    LockerService.lock();
  }
  public isAccess(item: string) {
    return this.user.access[item];
  }
}
