import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '../../../services/http.service';
import { DictionariesService } from '../../../services/dictionaries.service';
import {BreadcrumbsService} from '../../../shared/breadcrumbs/services';

@Component({
  selector: 'app,yamap-physical-edit-additionally',
  templateUrl: './physical-edit-additionally.component.html',
  styleUrls: ['./physical-edit-additionally.component.scss']
})
export class PhysicalEditAdditionallyComponent implements OnInit {

  public data: any = null;
  public formGroup: UntypedFormGroup;
  public physicalId = null;
  public nationalityList = [];

  constructor(public activeRoute: ActivatedRoute, public fb: UntypedFormBuilder, public http: HttpClient,
              public notify: NotificationService, private dict_helper: DictionariesService, private b: BreadcrumbsService) {
  }

  ngOnInit() {
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/physical/list', caption: 'Физические лица'},
    ]);
    this.data = this.activeRoute.snapshot.data['data'];
    this.nationalityList = this.activeRoute.snapshot.data['nationality'];
    this.physicalId = this.activeRoute.snapshot.parent.params['id'];
    this.formGroup = this.fb.group({
      'physical_mesto_rozhd': [this.data['physical_mesto_rozhd']],
      'physical_mj_reg': [this.data['physical_mj_reg']],
      'physical_mj_fakt': [this.data['physical_mj_fakt']],
      'physical_korr_addr': [this.data['physical_korr_addr']],
      'physical_addr_index': [this.data['physical_addr_index']],
      'physical_inn': [this.data['physical_inn']],
      'physical_num_strah_svid': [this.data['physical_num_strah_svid']],
      'physical_nationality_id': [this.data['physical_nationality_id'] ? this.data['physical_nationality_id'].toString() : ''],
      'physical_pasp_num': [this.data['physical_pasp_num']],
      'physical_pasp_seriya': [this.data['physical_pasp_seriya']],
      'physical_pasp_date': [this.data['physical_pasp_date']],
      'physical_pasp_vidan': [this.data['physical_pasp_vidan']],
      'physical_pasp_kod_podrazdel': [this.data['physical_pasp_kod_podrazdel']],
      'physical_osnovan_podp': [this.data['physical_osnovan_podp']],
      'physical_client_notice': [this.data['physical_client_notice']],
    });
  }

  saveForm() {
    if (!this.formGroup.valid) {
      this.onError('Заполните обязательные поля');
      return false;
    }
    const post_data = {
      'physical_mesto_rozhd': this.formGroup.value.physical_mesto_rozhd,
      'physical_mj_reg': this.formGroup.value.physical_mj_reg,
      'physical_mj_fakt': this.formGroup.value.physical_mj_fakt,
      'physical_korr_addr': this.formGroup.value.physical_korr_addr,
      'physical_addr_index': this.formGroup.value.physical_addr_index,
      'physical_inn': this.formGroup.value.physical_inn,
      'physical_num_strah_svid': this.formGroup.value.physical_num_strah_svid,
      'physical_nationality_id': this.formGroup.value.physical_nationality_id,
      'physical_pasp_num': this.formGroup.value.physical_pasp_num,
      'physical_pasp_seriya': this.formGroup.value.physical_pasp_seriya,
      'physical_pasp_date': this.formGroup.value.physical_pasp_date,
      'physical_pasp_vidan': this.formGroup.value.physical_pasp_vidan,
      'physical_pasp_kod_podrazdel': this.formGroup.value.physical_pasp_kod_podrazdel,
      'physical_osnovan_podp': this.formGroup.value.physical_osnovan_podp,
      'physical_client_notice': this.formGroup.value.physical_client_notice,
    };
    this.http.post('Physical.save_physical_additionally',
      {'physical_id': +this.physicalId, 'data': post_data}
    ).subscribe(
      (response) => this.onSuccess(response),
      (error) => this.onError(error)
    );
  }

  onSuccess(response) {
    this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
  }

  onError(error) {
    this.notify.notifyError('Ошибка', error, 5000);
  }

}
