import {NgModule} from '@angular/core';
import {CalendarStatisticComponent} from './calendar-statistic.component';
import {CalendarStatiscticDigitsComponent} from './calendar-statisctic-digits.component';
import {CalendarStatisticPopupComponent} from './calendar-statistic-popup.component';
import {CalendarStatisticTableComponent} from './calendar-statistic-table.component';
import {CommonModule} from '@angular/common';
import {DataTableModule} from '../../../modules/@datatable/datatables.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialInputModule} from '../../../modules/@material-input/material-input.module';
import {SimpleTableModule} from '../../../modules/@simple-table/simple-table.module';
import {CalendarStatisticResole} from './calendar-statistic.resole';
import {RouterModule} from '@angular/router';
import {CalendarStatisticPopupPipe} from './calendar-statistic-popup.pipe';
import { CalendarStatisticService } from './calendar-statistic.service';
import { CalendarStatisticUsersComponent } from './calendar-statistic-users.component';

@NgModule({
    imports: [
        CommonModule,
        DataTableModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialInputModule,
        SimpleTableModule,
        RouterModule,
    ],
    declarations: [
        CalendarStatisticComponent,
        CalendarStatiscticDigitsComponent,
        CalendarStatisticPopupComponent,
        CalendarStatisticTableComponent,
        CalendarStatisticPopupPipe,
        CalendarStatisticUsersComponent
    ],
    exports: [
        CalendarStatisticComponent,
    ],
    providers: [
        CalendarStatisticResole,
        CalendarStatisticService,
    ]
})
export class CalendarStatisticModule { }
