import {Component, EventEmitter, OnInit, Optional, Output} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../services/notification.service';
import {JsonClientService} from '../../../shared/json-client';
import {GoBackButtonService} from '../../../services/go-back-button.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-managers-create',
  templateUrl: './managers-create.component.html',
  styleUrls: ['./managers-create.component.scss']
})
export class ManagersCreateComponent implements OnInit {

  public form: UntypedFormGroup;

  @Output('managerAdded') managerAdded: EventEmitter<any> = new EventEmitter();

  constructor(private route: ActivatedRoute, private http: JsonClientService, private fb: UntypedFormBuilder, private notify: NotificationService, @Optional() private goBack: GoBackButtonService) {
  }

  ngOnInit() {

    if (this.goBack && this.route.routeConfig.component.name === this.constructor.name) {
      this.goBack.header = '<h1>Создание менеджера</h1>';
    }

    this.form = this.fb.group({
      'fio': ['', Validators.required],
      'phone': ['', Validators.required],
      'email': [''],
    });
  }

  onSubmit() {
    const data = {
      'data': {
        'fio': this.form.value.fio,
        'phone': this.form.value.phone,
        'email': this.form.value.email,
      }
    };

    this.http.post('Developer.manager_create', data).then(
      response => {
        const fio = this.form.value.fio;
        this.notify.notifySuccess('Менеджер успешно создан', fio, 5000);
        this.form.reset();
        this.managerAdded.next(response);
      },
      error => { }
    );
  }
}
