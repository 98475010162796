import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {JsonClientService} from '../../../shared/json-client';

@Injectable()
export class InstatekaErrorResolve implements Resolve<any> {


  constructor(private jsonRpc: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    const options = {
      '_filter': {
        'resolved_task': route.parent.queryParams['resolved_task'] === 'true',
      },
      'limit': 50,
      'offset': (+(route.queryParams['page'] || 1) - 1) * 50,
    };

    return this.jsonRpc.post('Mortgage.get_errors_instateka', options);
  }
}
