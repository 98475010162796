<form class="form form_crm" [formGroup]="formGroup" novalidate>
  <div class="row no-gutters gutters-4 align-items-start" formArrayName="complexes">
    <div class="col-12 caption mb-3 mt-2">Использовать общие настройки импорта</div>
    <div class="col-12 mb-3 mt-2 text-sm" *ngIf="!complexes.controls.length">В данный момент комплексов нет</div>
    <div class="col-6 mb-3" *ngFor="let complex of complexes.controls; let id = index" [formGroupName]="id">
      <app-material-input-checkbox formControlName="is_checked" [require]="true">
        {{complex.value.caption}}
        <a class="button button_icon icon icon_Previews" title="ЖК в редакторе"
           [routerLink]="['/housing2/complex', complex.value.realty_id]"
           ></a><!-- (click)="linkImport($event, complex.value.realty_id)" -->
      </app-material-input-checkbox>
      <div class="row" *ngIf="complex.value.housings.length">
        <div class="col-md-2" *ngFor="let housing of complex.value.housings;">
          <a [routerLink]="['/housing2/house', housing['realty_id']]">{{housing['caption']}}</a>
        </div>
      </div>
    </div>
  </div>
  <div formArrayName="settings" class="end_block_padding">
    <div class="row no-gutters gutters-4 align-items-start"
         *ngFor="let setting of settings.controls; let id = index" [formGroupName]="id">
      <div class="col-12 caption mb-3 mt-3">{{setting.value.caption}}</div>
      <div class="col-6 mb-2">
        <app-material-input-radio-button formControlName="settings_type_prices"
                                         [data]="types" [labelClass]="''">Обновлять цены
        </app-material-input-radio-button>
      </div><div class="col-6 mb-2">
        <app-material-input-radio-button formControlName="settings_type_statuses"
                                         [data]="types" [labelClass]="''">Обновлять статусы квартир
        </app-material-input-radio-button>
      </div>
      <div class="col-6 mb-2">
        <app-material-input-radio-button formControlName="settings_type_squares"
                                         [data]="types" [labelClass]="''">Обновлять площади
        </app-material-input-radio-button>
      </div>
      <div class="col-6 mb-2">
        <app-material-input-radio-button formControlName="settings_type_plans"
                                         [data]="types" [labelClass]="''">Обновлять планировки
        </app-material-input-radio-button>
      </div>
      <div class="col-6 mb-2">
        <app-material-input-radio-button formControlName="settings_type_structure"
                                         [data]="types" [labelClass]="''">Обновлять структурные данные
        </app-material-input-radio-button>
      </div>
      <div class="col-6 mb-2">
        <app-material-input-radio-button formControlName="settings_type_captions"
                                         [data]="types" [labelClass]="''">Обновлять названия
        </app-material-input-radio-button>
      </div>
    </div>
  </div>
  <div class="row no-gutters gutters-4 align-items-start mt-4 mb-4">
    <div class="col-6">
      <button (click)="saveForm()" type="button" [disabled]="loading"
              class="button fixed_buttons_block_main" [ngClass]="{'button_disabled': loading}">
        Сохранить
      </button>
    </div>
  </div>
</form>
