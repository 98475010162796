<div class="mt-4 mb-2 row">
  <div class="col-12 col-sm-3 col-md-2">
    Всего: <b>{{count}}</b>
  </div>
  <div class="col-12 col-sm-9 col-md-10" *ngIf="checkedLength > 0">
    <span
      class="mr-4">Выбрано: <b>{{checkedLength}}</b>
    </span>
    <a href="javascript:void(0)" (click)="onClearChecked()"
       class="mr-4">снять выбор</a>
    <a href="javascript:void(0)" (click)="onRightViewClick('purch_term')"
       class="mr-4">перезаписать условия покупки</a>
  </div>
  <div class="col-12 mt-2 d-md-none no-wrap fix-ch-box">
    Выбрать все на странице: <input
    class="checkbox ml-3"
    type="checkbox"
    [value]="true"
    [(ngModel)]="isAllChecked"
    [ngModelOptions]="{standalone: true}"
  />
  </div>
</div>
<div class="material-datatable-wrapper">
  <div class="table-responsive">
    <table class="table table-striped datatable table-rwd">
      <tr>
        <th>
          <input
            class="checkbox"
            type="checkbox"
            [value]="true"
            [(ngModel)]="isAllChecked"
            [ngModelOptions]="{standalone: true}"
          />
        </th>
        <th *ngFor="let el of head" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
          <div [formGroup]="form">
            <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
              <div class="col table__input">
                <ng-container *ngIf="el.input">
                  <app-material-input
                    [placeholder] = "el.caption"
                    [formControlName] = "el.name"
                    [cssClass]="'mb-0'">
                  </app-material-input>
                </ng-container>
                <ng-container *ngIf="!el.input && el.name !== 'search_fields'">
                  {{el.caption}}
                </ng-container>
                <ng-container *ngIf="el.name === 'search_fields'">
                  <app-material-select2
                    [noCopy]="false"
                    [placeholder]="'На витрине с проблемами'"
                    [multiple]="true"
                    formControlName="search_fields"
                    class="small-padding-fix"
                  >
                    <option [value]="'only_published'">На витрине</option>
                    <option [value]="'not_fill'">Есть незаполненные</option>
                  </app-material-select2>
                </ng-container>
              </div>
              <div class="col-auto" *ngIf="el.sort">
                <a href="javascript:void(0)" (click)="sortChange(el.name)">
                  <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                      'icon_new-sort-down': 'asc' == sort[el.name],
                                      'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                </a>
              </div>
            </div>
          </div>
        </th>
      </tr>
      <!-- // -->
      <tr *ngFor="let row of rows; let row_index = index">
        <td>
          <input class="checkbox" type="checkbox" value="{{row['complex_id']}}"
                 [checked]="isChecked(row)" (click)="onChecking($event, row)">
        </td>
        <td>
          {{row['developer_caption']}}
        </td>
        <td>
          <a class="d-block mb-2" *ngIf="row['complex_id']" href="/housing2/complex/{{row['complex_id']}}"
             (click)="goBack.newLink('/housing2/complex/' + row['complex_id'], 'complex_caption' + row_index, $event)">
            {{row['complex_caption']}}
          </a>
          <ng-container *ngIf="!row['complex_id']">
            {{row['complex_caption']}}
          </ng-container>
        </td>
        <td>
          <ng-container *ngIf="row['not_up_akcii_exists'] === true">Не заполнены акции<br/></ng-container>
          <ng-container *ngIf="row['not_up_banki_exists'] === true">Не заполнены банки и ипотека<br/></ng-container>
          <ng-container *ngIf="row['not_up_rassroch_exists'] === true">Не заполнена рассрочка<br/></ng-container>
          <ng-container *ngIf="row['not_up_vzaimozach_exists'] === true">Не заполнен взаимозачет<br/></ng-container>
        </td>
      </tr>
    </table>
  </div>
</div>
<div class="alt-pagination">
  <app-paginator
    [queryParams]="filter_columns"
    [itemsPerPage]="50"
    [itemCount]="count"
    [currentPage]="current_page"
    [baseUrl]="'/mass/purch_temp'">
  </app-paginator>
</div>


<app-right-window
  [data]="rightView['regl']"
  (onClosedRight)="rightView['regl'] = false"
>
  <app-right-view-regl
    [complexes_ids]="checkedComplexesIds"
    (afterSubmit)="onRightSubmitted('regl')">
  </app-right-view-regl>
</app-right-window>


<app-right-window
  [data]="rightView['act']"
  (onClosedRight)="rightView['act'] = false"
>
  <app-right-view-act
    [complexes_ids]="checkedComplexesIds"
    (afterSubmit)="onRightSubmitted('act')">
  </app-right-view-act>
</app-right-window>


<app-right-window
  [data]="rightView['purch_term']"
  (onClosedRight)="rightView['purch_term'] = false"
  [cssClass]="'big'"
>
  <app-right-view-term
    [complexes_ids]="checkedComplexesIds"
    (afterSubmit)="onRightSubmitted('purch_term')">
  </app-right-view-term>
</app-right-window>
