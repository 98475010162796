import { animate, state, style, transition, trigger } from '@angular/animations';

export const SLIDE_LEFT_ANIMATION =
  trigger(
      'slideLeft',
      [
        transition(
        ':enter', [
          style({transform: 'translate(-100%, 0)', opacity: 1}),
          animate('300ms', style({transform: 'translate(0, 0)', 'opacity': 1}))
        ]
      ),
      transition(
        ':leave', [
          style({transform: 'translate(0, 0)', 'opacity': 1}),
          animate('300ms', style({transform: 'translate(-100%, 0)', 'opacity': 1}))
        ]
      )]
    );
