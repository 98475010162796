import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RightWindowComponent} from './right-window.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    RightWindowComponent,
  ],
  exports: [
    RightWindowComponent,
  ],
})
export class RightWindowModule { }
