import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient} from '../../../services/http.service';

@Injectable()
export class HousingCatalogDocumentsResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.http.post('Files.get_list', {
      'owner': ['realty', +route.parent.params['id']],
      'type_id': [13, 14]
    });
  }
}
