<div class="tab-content-wrapper mt-4">
  <form class="form form_crm mb-4" [formGroup]="formGroup" novalidate>
    <app-material-input-dropzone formControlName="files"
                                 [hasComments]="false">
    </app-material-input-dropzone>
    <div class="text-center">
      <button (click)="saveFiles()" [disabled]="!formGroup.value.files.length"
              type="button" class="button button_main">
        Сохранить загруженные документы
      </button>
    </div>
  </form>
</div>
