import {Component, OnInit, Optional} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '../../../services/http.service';
import {NotificationService} from '../../../services/notification.service';
import { TemplateService } from '../../../services/template.service';
import {GoBackButtonService} from '../../../services/go-back-button.service';
import {AuthenticateService} from '../../../services/authenticate.service';


@Component({
  templateUrl: './settings-index.component.html',
  styleUrls: ['./settings-index.component.scss']
})
export class SettingsIndexComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute, private http: HttpClient, @Optional() private goBack: GoBackButtonService,
              private notify: NotificationService, public template: TemplateService, public auth: AuthenticateService) { }

  ngOnInit() {
    this.goBack.header = '<h1>Настройки</h1>';
  }

}
