import {NgModule} from '@angular/core';
import {OnlineComponent} from './online.component';
import {OnlineResolve} from './online.resolve';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialInputModule} from '../../modules/@material-input/material-input.module';
import {OnlineCompareViewComponent} from './compare-view/online-compare-view.component';
import {OnlineCompareViewWrapperComponent} from './compare-view/online-compare-view-wrapper.component';
import {RouterModule} from '@angular/router';
import {DataTableModule} from '../../modules/@datatable/datatables.module';
import {OnlineCompareViewStaffComponent} from './compare-view/online-compare-view-staff.component';
import {SimpleTableModule} from '../../modules/@simple-table/simple-table.module';
import {OnlineCompareViewTableComponent} from './compare-view/online-compare-view-table.component';

@NgModule({
    imports: [
        DataTableModule,
        RouterModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialInputModule,
        SimpleTableModule,
    ],
    declarations: [
        OnlineComponent,
        OnlineCompareViewComponent,
        OnlineCompareViewStaffComponent,
        OnlineCompareViewWrapperComponent,
        OnlineCompareViewTableComponent,
    ],
    exports: [
        OnlineComponent,
        OnlineCompareViewComponent,
        OnlineCompareViewWrapperComponent,
    ],
    providers: [
        OnlineResolve,
    ]
})
export class OnlineModule { }
