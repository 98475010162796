import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MaterialInputModule} from '../../modules/@material-input';
import {EmailExceptionComponent} from './email-exсeption.component';
import {FieldsModule} from '../../modules/@fields/fields.module';
import {EmailExceptionResolve} from './email-exception.resolve';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
    FieldsModule

  ],
  declarations: [
    EmailExceptionComponent,
  ],
  providers: [
    EmailExceptionResolve,
  ]
})
export class EmailExceptionModule {
}
