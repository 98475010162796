import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {Subject} from 'rxjs';

@Component({
    /* tslint:disable-next-line:component-selector-prefix component-selector-name */
    selector: 'app-datatable-pagination',
    templateUrl: './datatable-pagination.component.html',
    styleUrls: ['./datatable-pagination.component.scss']
})
export class DataTablePaginationComponent {
    public pages_count = 1;
    public rage = null;

    @Input() public items_per_page = 10;
    @Input() public items_count = 0;
    @Input() public current_page = 1;

    @Output() public change_page: EventEmitter<number> = new EventEmitter<number>();

    private _update_pagination: Subject<Array<number>> = new Subject<Array<number>>();
    set update_pagination(value: Subject<Array<number>>) { }
    get update_pagination(): Subject<Array<number>> { return this._update_pagination; }

    constructor() {
        this.update_pagination.subscribe(([items, per_page]: [number, number]) => {
            this.items_count = items;
            this.items_per_page = per_page;
            this.getPagesCount();
            this.rage = this.createRange();
        });
    }

    getPagesCount() {
        this.pages_count = Math.ceil(this.items_count / this.items_per_page);
    }

    firstPage() {
        if (this.current_page > 1) {
            this.changePage(1);
        }
    }

    previousPage() {
        if (this.current_page > 1) {
            this.changePage(this.current_page - 1);
        }
    }

    nextPage() {
        if (this.current_page < this.pages_count) {
            this.changePage(this.current_page + 1);
        }
    }

    lastPage() {
        if (this.current_page < this.pages_count) {
            this.changePage(this.pages_count);
        }
    }

    changePage(page: number) {
        this.change_page.emit(page);
        this.current_page = page;
    }

    createRange() {
      const items: number[] = [];
      for (let i = 1; i <= this.pages_count; i++) {
        if (this.pages_count > 7) {
          if (this.current_page < 4) {
            if (i === 6) {
              items.push(0);
              continue;
            } else if ((i !== this.pages_count) &&
              (i > 5)) {
              continue;
            }
          } else if (this.current_page > this.pages_count - 4) {
            if (i === this.pages_count - 5) {
              items.push(0);
              continue;
            } else if ((i !== 1) &&
              (i < this.pages_count - 5)) {
              continue;
            }
          } else {
            if (i === 2) {
              items.push(0);
              continue;
            } else if (this.pages_count - 1 === i) {
              items.push(0);
              continue;
            } else if ((i !== 1 && i !== this.pages_count) &&
              ((i > (+this.current_page + 1)) || (i < (+this.current_page - 1)))) {
              continue;
            }
          }
        }
        items.push(i);
      }
      return items;
    }
}
