import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../services/notification.service';
import { OverlayWindowService } from '../../single-window/shared/overlay-window.service';
import { HttpClient } from '../../../services/http.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-news-edit',
  templateUrl: './news-edit.component.html',
  styleUrls: ['./news-edit.component.scss']
})
export class NewsEditComponent implements OnInit {

  @Input() public news_date = new DatePipe('en-US').transform(Date.now(), 'dd.MM.yyyy');
  @Input() public news_description = '';
  @Input() public news_id;
  @Input() public news_title = '';
  @Input() public is_archive = '';
  @Input() public always_show = '';
  @Input() public news_url = '';
  @Input() public public_status = 'crm';
  @Output() public onEdit: EventEmitter<any> = new EventEmitter<any>();

  public form: UntypedFormGroup;

  constructor(private http: HttpClient, private overlayWindowService: OverlayWindowService,
              private fb: UntypedFormBuilder, private notify: NotificationService) {
  }

  ngOnInit() {
    const date = new DatePipe('en-US').transform(Date.now(), 'dd.MM.yyyy');
    this.form = this.fb.group({
      'news_title': [this.news_title, Validators.required],
      'news_description': [this.news_description, Validators.required],
      'news_link': [this.news_url, Validators.required],
      'news_date': [this.news_date, Validators.required],
      'public_status': [this.public_status, Validators.required],
      'always_show': [this.always_show, Validators.required],
      'is_archive': [this.is_archive, Validators.required],
    });
  }

  onSubmit() {
    const data = {
      'news_id': this.news_id,
      'data': {
        'news_title': this.form.value.news_title,
        'news_description': this.form.value.news_description,
        'news_url': this.form.value.news_link,
        'news_date': this.form.value.news_date,
        'always_show': this.form.value.always_show,
        'is_archive': this.form.value.is_archive,
        'public_status': this.form.value.public_status,
      }
    };
    this.http.post('News.edit', data).subscribe(
      response => {
        const title = this.form.value.news_title;
        this.notify.notifySuccess('Новость успешно отредактирована', title, 5000);
        this.onEdit.emit({'value': title});
        this.overlayWindowService.closeHost();
      },
      error => this.notify.notifyError('Ошибка', error, 5000)
    );
  }

}
