import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../../services/notification.service';
import {ReglTableService} from '../regl-table/regl-table.service';


@Component({
  selector: 'app-right-view-act',
  templateUrl: './right-view-act.component.html',
  styleUrls: [
    './right-view-act.component.scss'
  ]
})
export class RightViewActComponent implements OnInit {

  @Input() public complexes_ids = [];
  @Output() public afterSubmit: EventEmitter<any> = new EventEmitter();

  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
              private s: ReglTableService,
              private notify: NotificationService) { }

  ngOnInit() {
    this.form = this.fb.group({
      'files': [[], Validators.required],
    });
  }

  fileSave() {
    const files = this.form.value['files'].map(x => {
      return {
        'base_file_id': x['id'],
        'filename': x['filename'],
        'file': x['filepath'],
        'comment': x['comment'],
      };
    });

    this.s.saveFiles(this.complexes_ids, files, 39)
      .then(() => {
        this.afterSubmit.emit();
        this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
      });
  }

}
