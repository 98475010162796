<div class="row mb-4 mt-2">
  <div class="col-md-4 mt-2">
    <div class="caption">Данные</div>
  </div>
  <div class="col-md-8">
    <button class="button pull-right mt-2" (click)="onProcsRefresh()" title="Перезаписать данные по процентам и долям
вознаграждений всех квартир всех проектов
этого импорта на значения во вкладке Реализация">
      <ng-container *ngIf="now_in_update_procs !== true;else prices_updating">
        Перезаписать %
      </ng-container>
    </button>
    <button class="button pull-right mt-2 mr-2" (click)="onPriceRefresh()" title="Перезаписать все цены на импортные">
      <ng-container *ngIf="now_in_update_prices !== true;else prices_updating">
        Перезаписать цены
      </ng-container>
    </button>
    <button class="button pull-right mt-2 mr-2" (click)="onPlansRefresh()" title="Сбросить обработанные импортом
планировки, чтобы они заново обработались
при следующем запуске импорта. При следующем
запуске данного импорта, планировочные
решения будут загружаться только на помещения
с отсутствующими соответствующими планировками.">
      <ng-container *ngIf="now_in_update_plans !== true;else prices_updating">
        Сбросить планировки
      </ng-container>
    </button>
  </div>
</div>
<ng-template #prices_updating>
  <i class="fa fa-spinner fa-spin"></i> пожалуйста, подождите...
</ng-template>

<form class="form form_crm" [formGroup]="formGroup" novalidate>
  <div class="row no-gutters gutters-2 mb-2">
    <div class="col-3">
      <app-material-select formControlName="status" [caption]="'Статус'">
        <option [value]="''">Все</option>
        <option [value]="item.id" *ngFor="let item of statuses">
          {{item.caption}}
        </option>
      </app-material-select>
    </div>
    <div class="col-3">
      <app-material-select formControlName="category" [caption]="'Типы событий'">
        <option [value]="''">Все</option>
        <option [value]="item.id" *ngFor="let item of modification">
          {{item.caption}}
        </option>
      </app-material-select>
    </div>
    <div class="col-6">
      <app-material-select formControlName="object" [caption]="'ЖК/корпус'">
        <option [value]="''">Все</option>
        <option [value]="item.id" *ngFor="let item of objects">
          {{item.caption}}
        </option>
      </app-material-select>
    </div>
  </div>
</form>
<div class="material-datatable-wrapper">
  <div class="table-responsive">
    <table class="table table-striped datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>
<div class="row no-gutters mt-4 mb-3">
  <div class="col-12">
    <button class="mb-3 button" (click)="onAcceptChecked()">Принять выбранное</button>
    <button class="mb-3 button ml-2" (click)="onDenyChecked()">Отклонить выбранное</button>
    <button class="mb-3 button pull-right" (click)="onDenyAll()">
      <ng-container *ngIf="now_in_update_modifications !== true;else prices_updating">
        Отклонить все
      </ng-container>
    </button>
    <button class="mb-3 button pull-right mr-2" (click)="onAcceptAll()">
      <ng-container *ngIf="now_in_update_modifications !== true;else prices_updating">
        Принять все
      </ng-container>
    </button>
<!--    <button (click)="saveForm()" type="button" class="button button_main pull-right">-->
<!--      Готово-->
<!--    </button>-->
  </div>
</div>

<ng-template #checkTmpl let-row="row">
  <input class="checkbox ml-1" type="checkbox" value="{{row.id}}" *ngIf="row.is_waiting"
         [checked]="getCheck(row.id)" (click)="onCheck(row.id)">
</ng-template>

<ng-template #checkHeadTmpl let-row="row">
  <input class="checkbox" type="checkbox" value="all" [checked]="check_all" (click)="onCheckAll()">
</ng-template>

<ng-template #modificationTmpl let-row="row">
  {{row.modification_caption}}
  <ng-container *ngIf="row.new_plan">
    <a href="javascript: void(0)" (click)="onView(row.id)" *ngIf="!getView(row.id)">Показать</a>
    <div *ngIf="getView(row.id)" class="images-preview images-preview_small mt-1 ml-1 mb-0 pull-right"
         (click)="displayImage(row.new_plan)" [style.background-image]="'url('+row.new_plan+')'"></div>
  </ng-container>
</ng-template>
