<div>
  <button (click)="analyze('basic')" type="button" [disabled]="loading"
          class="button mr-3 mb-2" [ngClass]="{'button_disabled': loading}">
    Быстрый анализ
  </button>
  <button (click)="analyze('full')" type="button" [disabled]="loading"
          class="button mb-2" [ngClass]="{'button_disabled': loading}">
    Глубокий анализ
  </button>
</div>
<div class="col-12 mt-5" *ngIf="analyzeResult">
  <div *ngIf="analyzeResult.status_code">
    Код ответа: <b>{{analyzeResult.status_code}}</b>
  </div>
  <div *ngIf="analyzeResult.headers">
    Заголовки: <pre>{{analyzeResult.headers}}</pre>
  </div>
  <div *ngIf="analyzeResult.analyze_content">
    Анализ:
    <div class="my-3" *ngIf="analyzeFilter">
      <app-alert-warning>
        <div><i class="fa-fw fa fa-warning"></i> <b>Внимание!</b> Установлен фильтр!</div>
        <div class="mt-2" *ngFor="let el of analyzeFilter">
          {{el[0]}}: {{el[1]}}
        </div>
      </app-alert-warning>
    </div>
    <!--          <pre>{{analyzeResult.analyze_content}}</pre>-->
    <div *ngFor="let lvl1 of analyzeResult.analyze_content">
      {{lvl1.selector}}
      <ul>
        <li *ngFor="let lvl2 of lvl1.content">
          `{{lvl2.value}}`:
<!--          <ng-container *ngIf="lvl2.canFilter">-->
<!--            <a href="javascript:void(0)"-->
<!--               (click)="analyze('full', [lvl1.selector, lvl2.value])"-->
<!--            >{{lvl2.result}}<span class="ml-4">[фильтр]</span></a>-->
<!--          </ng-container>-->
<!--          <ng-container *ngIf="!lvl2.canFilter">-->
            {{lvl2.result}}
<!--          </ng-container>-->
        </li>
      </ul>
      <!--            -->
      <!--            <div *ngFor="let v2 of v1; index as key2">-->
      <!--              {{key2}} {{v2}-->
      <!--            </div>-->
    </div>
  </div>
</div>
