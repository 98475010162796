import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsComponent } from './settings.component';
import { SharedModule } from '../../shared/shared.module';
import { MaterialInputModule } from '../../modules/@material-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {RouterModule} from '@angular/router';
import {HelpWindowModule} from '../../shared/help-window';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MaterialInputModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HelpWindowModule,
  ],
  declarations: [SettingsComponent]
})
export class SettingsModule { }
