import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MaterialInputModule} from '../../../../modules/@material-input';
import {TabsModule} from '../../../../modules/@tabs/tabs.module';

import {FlatInfoStandaloneComponent} from './flat-info-standalone.component';
import {FlatAdvancedComponent} from './flat-advanced/flat-advanced.component';
import {FlatCommonComponent} from './flat-common/flat-common.component';
import {FlatPlanningComponent} from './flat-planning/flat-planning.component';
import {FlatProprietorsComponent} from './flat-proprietors/flat-proprietors.component';
import {FlatFloorPlanningComponent} from './flat-floor-planning/flat-floor-planning.component';
import {FlatConditionsComponent} from './flat-conditions/flat-conditions.component';
import {SharedModule} from '../../../shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
    TabsModule,
    SharedModule,
  ],
  declarations: [
    FlatInfoStandaloneComponent,
    FlatAdvancedComponent,
    FlatCommonComponent,
    FlatPlanningComponent,
    FlatProprietorsComponent,
    FlatFloorPlanningComponent,
    FlatConditionsComponent,
  ],
  exports: [
    FlatInfoStandaloneComponent,
  ]
})
export class FlatInfoStandaloneModule { }
