<div class="tab-content-wrapper mt-4">
  <ng-container *ngIf="about.organisation_type_id && about.organisation_type_id.toString() === '4'">
    <form class="form form_crm mb-4" [formGroup]="formGroupReglament" novalidate>
      <app-material-input-dropzone [extensions]="['pdf', 'PDF']" [dropzoneCaption]="'Перетащите регламент (в формате PDF) сюда или нажмите <span class=\'dz-dash\'>тут</span>'"  [max_count]="1" formControlName="files"
                                   [hasComments]="false">
      </app-material-input-dropzone>
      <div class="text-center">
        <button (click)="saveReglament()" [disabled]="!formGroupReglament.value.files.length"
                type="button" class="button button_main">
          Сохранить регламент
        </button>
      </div>
    </form>
  </ng-container>
  <form class="form form_crm mb-4" [formGroup]="formGroup" novalidate>
    <app-material-input-dropzone formControlName="files"
                                 [hasComments]="false">
    </app-material-input-dropzone>
    <div class="text-center">
      <button (click)="saveFiles()" [disabled]="!formGroup.value.files.length"
              type="button" class="button button_main">
        Сохранить загруженные документы
      </button>
    </div>
  </form>
</div>
