import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  @Input('baseUrl') public baseUrl;
  @Input('queryParams') public queryParams: {[p: string]: any} = {};

  @Input('itemsPerPage') public itemsPerPage = 20;

  private _currentPage = 1;
  private _itemCount = 0;

  public pages: Array<any>;
  public pages_count = 0;

  get currentPage(): number {
    return this._currentPage;
  }

  @Input('currentPage') set currentPage(value: number) {
    this._currentPage = value;
    this.reloadPages();
  }

  get itemCount() {
    return this._itemCount;
  }


  @Input('itemCount') set itemCount(value) {
    this._itemCount = value;
    this.reloadPages();
  }

  ngOnInit() {
    // this.reloadPages();
  }

  private reloadPages() {
    this.pages_count = Math.ceil(this.itemCount / this.itemsPerPage);
    this.pages = [];

    for (let i = 1; i <= this.pages_count; i++) {
      if (this.pages_count > 7) {
        if (this._currentPage < 4) {
          if (i === 6) {
            this.pages.push({
              'caption': '...',
              'is_url': false,
              'is_active': false,
            });
            continue;
          } else if ((i !== this.pages_count) && (i > 5)) {
            continue;
          }
        } else if (this._currentPage > this.pages_count - 4) {
          if (i === this.pages_count - 5) {
            this.pages.push({
              'caption': '...',
              'is_url': false,
              'is_active': false,
            });
            continue;
          } else if ((i !== 1) &&
            (i < this.pages_count - 5)) {
            continue;
          }
        } else {
          if (i === 2) {
            this.pages.push({
              'caption': '...',
              'is_url': false,
              'is_active': false,
            });
            continue;
          } else if (this.pages_count - 1 === i) {
            this.pages.push({
              'caption': '...',
              'is_url': false,
              'is_active': false,
            });
            continue;
          } else if ((i !== 1 && i !== this.pages_count) &&
            ((i > (+this._currentPage + 1)) || (i < (+this._currentPage - 1)))) {
            continue;
          }
        }
      }
      this.pages.push({
        'caption': i,
        'is_url': true,
        'is_active': this.currentPage === i,
      });
    }
  }

}
