
<div class="material-datatable-wrapper small_table">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)">
  </app-datatable-headline>
  <div class="table-responsive">
    <table class="table table-striped datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>


<ng-template #idHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="complex_id" [cssClass]="'mb-0'" [placeholder]="'№'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #nameHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="complex_caption" [cssClass]="'mb-0'" [placeholder]="'Название комплекса'">
    </app-material-input>
  </form>
</ng-template>


<ng-template #nameTmpl let-row="row" let-ind="row_index">
  <ng-container *ngIf="row.complex_id&&row.complex_caption">
    <div [ngClass]="{'activeBox': goBack.activeHash === 'complex_caption' + ind}">
      <a href="/housing2/complex/{{row.complex_id}}"
         (click)="goBack.newLink('/housing2/complex/' + row.complex_id, 'complex_caption' + ind, $event)">
        <i class="fa fa-user"></i> {{row.complex_caption}}
      </a>
    </div>
  </ng-container>
</ng-template>


<ng-template #namekorpHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="housing_caption" [cssClass]="'mb-0'" [placeholder]="'Название корпуса'">
    </app-material-input>
  </form>
</ng-template>


<ng-template #namekorpTmpl let-row="row" let-ind="row_index">
  <ng-container *ngIf="row.housing_id&&row.housing_caption">
    <div [ngClass]="{'activeBox': goBack.activeHash === 'housing_caption' + ind}">
      <a href="/housing2/house/{{row.housing_id}}"
         (click)="goBack.newLink('/housing2/house/' + row.housing_id, 'housing_caption' + ind, $event)">
        <i class="fa fa-user"></i> {{row.housing_caption}}
      </a>
    </div>
  </ng-container>
</ng-template>


<ng-template #addressHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="housing_address" [cssClass]="'mb-0'" [placeholder]="'Адрес'">
    </app-material-input>
  </form>
</ng-template>


<ng-template #developerHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="developer_caption" [cssClass]="'mb-0'" [placeholder]="'Застройщик'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #developerTmpl let-row="row">
  <ng-container *ngIf="row.developer_caption&&row.developer_id">
    <a [routerLink]="['/entity/edit/',row.developer_id]">
      <i class="fa fa-user"></i> {{row.developer_caption}}
    </a>
  </ng-container>
</ng-template>


<ng-template #contactsHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="contacts" [cssClass]="'mb-0'" [placeholder]="'Контакты'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #managerTmpl let-row="row">
  <ng-container *ngIf="row.eat_bosses.length">
    <div class="table-responsive">
      <table class="table datatable table-rwd bordered">
        <thead>
        <tr>
          <th>
            ФИО
          </th>
          <th>
            Телефон
          </th>
          <th>
            Email
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let element of row.eat_bosses">
          <td>{{element.fio}}</td>
          <td>{{element.phone}}</td>
          <td>{{element.email}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</ng-template>


<ng-template #managerObjTmpl let-row="row">
  <ng-container *ngIf="row.eat_managers.length">
    <div class="table-responsive">
      <table class="table datatable table-rwd bordered">
        <thead>
        <tr>
          <th>
            ФИО
          </th>
          <th>
            Телефон
          </th>
          <th>
            Email
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let element of row.eat_managers">
          <td>{{element.fio}}</td>
          <td>{{element.phone}}</td>
          <td>{{element.email}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</ng-template>
