import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {JsonClientService} from '../../../../shared/json-client';

@Injectable()
export class ReservLeadInfoResolve {

  constructor(private jsonRpc: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    return this.jsonRpc.post('Bp.get_count_leads', {'category': 'sale'});
  }

}
