import {Component, Input, OnInit} from '@angular/core';
import {AuthenticateService} from '../../../services/authenticate.service';
import {HttpClient} from '../../../services/http.service';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() public type = '';
  @Input() public caption: string = null;
  @Input() public icon = true;
  @Input() public close = true;
  @Input() public marginBottom = false;
  @Input() public alertLogo = false;
  public hasView = true;

  constructor(private http: HttpClient, public auth: AuthenticateService) { }

  ngOnInit() {
  }

  onClose() {
    if (this.alertLogo) {
      this.http.post('User.close_view_alert_logo', {'value': false}).subscribe(value => {
        this.auth.user.setProperty('view_alert_logo', false);
        this.auth.save_data();
        this.hasView = false;
      });
    } else {
      this.hasView = false;
    }
  }

}
