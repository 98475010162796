import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GoBackButtonService} from '../../../services/go-back-button.service';
import {JsonClientService} from '../../../shared/json-client';
import {NotificationService} from '../../../services/notification.service';
import {AlternativeDatatablesComponent} from '../../../shared/alternative-datatables/alternative-datatables.component';
import {AlternativeDatatablesInterface} from '../../../shared/alternative-datatables/alternative-datatables.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TemplateService} from '../../../services/template.service';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';


@Component({
  templateUrl: './instateka.component.html',
  styleUrls: ['./instateka.component.css']
})
export class InstatekaComponent extends AlternativeDatatablesComponent
  implements AlternativeDatatablesInterface, OnInit, OnDestroy, AfterViewInit {

  public load = false;
  public head;

  public filter_columns = {};
  public form: UntypedFormGroup;
  public filter: string;

  public housing_id = null;
  private parasites = true;

  public filterSubject: Subject<void> = new Subject<void>();
  private filterSubscription: Subscription;
  private formSubscription: Subscription;

  public current_page = 1;

  public count;
  public rows;

  public sort: { [key: string]: string } = {};

  public current_flat: any = null;

  public set data(value) {
    this.count = value['count'];
    this.rows = value['rows'];
  }

  private cause_list = [];

  @ViewChild('input') private input;

  constructor(public http: JsonClientService,
              private notify: NotificationService,
              public goBack: GoBackButtonService,
              private router: Router,
              private fb: UntypedFormBuilder,
              public activatedRoute: ActivatedRoute,
              private template: TemplateService) {
    super(router, activatedRoute, fb, http);

    this.filterSubscription = this.filterSubject
      .pipe(debounceTime(500))
      .subscribe(_ => this.onFilterChanges());
  }

  bSet() {
    this.goBack.header = '<h2>Инстатека доступы</h2>';
    this.template.setHeader('Инстатека доступы');
  }

  ngOnInit(): void {
    this.bSet();
    this.form = this.fb.group({});

    this.head = [
      {'name': 'fio', 'value': '', 'caption': 'ФИО', 'input': true, 'sort': false},
      {'name': 'subagent_caption', 'value': '', 'caption': 'Название организации', 'sort': false, 'input': true},
      {
        'name': 'instateka_account',
        'value': '',
        'caption': 'Личный кабинет в Инстатеки',
        'sort': false,
        'input': false,
        'select': [
          {'caption': 'Да', 'id': true},
          {'caption': 'Нет', 'id': false},
          {'caption': 'Не выбрано', 'id': null}
        ],
        },
    ];

    this.head = this.head.map(x => {
      x.value = this.activatedRoute.snapshot.queryParams[x.name] || x.value;
      return x;
    });
    if (this.activatedRoute.snapshot.queryParams['sort_name']) {
      this.sort[this.activatedRoute.snapshot.queryParams['sort_name']] = this.activatedRoute.snapshot.queryParams['sort_key'];
    }

    this.formSubscription = this.form.valueChanges
      .subscribe(_ => this.filterSubject.next());

    for (const el of this.head) {
      this.form.addControl(el.name, new UntypedFormControl(el.value));
    }

    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.onInitCall();
  }

  onFileChange($event: Event) {
    const file_input = this.input.nativeElement;

    if (!file_input.files || !file_input.files[0]) {
      return;
    }
    const file_to_upload = file_input.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const file_result = (event.srcElement || event.target)['result'].split(',').pop();
      const options = {
        'csv_file': file_result,
      };
      this.load = true;
      this.http.post('Mortgage.import_csv_instateka', options).then(
        response => {
          this.load = false;
          this.notify.notifySuccess('Поздравляем', 'Успешное выполнение импорта', 3000);
          this.input.nativeElement.value = '';
        },
        _ => {
          this.notify.notifyError('Внимание', 'Ошибка загрузки CSV файла', 3000);
          this.load = false;
          this.input.nativeElement.value = '';
        },
      );
    };
    reader.readAsDataURL(file_to_upload);
  }

ngOnDestroy() {
    super.ngOnDestroy();

    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }

    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  onFilterChanged(): void {
    this.data = this.activatedRoute.snapshot.data['data'];
    this.current_page = +this.activatedRoute.snapshot.queryParams['page'] || 1;
  }

  onFilterChanges(text = null) {

    if (text !== null) {
      this.filter = text;
    }

    this.filter_columns = {};
    for (const el of this.head) {
      if (!this.form.get(el.name).value.length) {
        continue;
      }
      this.filter_columns[el.name] = this.form.get(el.name).value;
    }
    if (this.filter !== '') {
      this.filter_columns['global_search_string'] = this.filter;
    }
    if (this.sort) {
      this.filter_columns['sort_name'] = Object.keys(this.sort)[0];
      this.filter_columns['sort_key'] = this.sort[this.filter_columns['sort_name']];
    }
    if (this.parasites) {
      return this.parasites = !this.parasites;
    }
    this.bSet();
    this.router.navigate(['instateka', 'users'], {
      queryParams: this.getQueryParams(),
    });
  }

  getQueryParams() {
    const query_params = {};

    for (const el of this.head) {
      let _val = this.form.get(el.name).value;
      if (!_val.length) {
        continue;
      }
      if (_val === 'true' || _val === 'false') {
        _val = _val === 'true';
      }
      query_params[el.name] = _val;
    }
    if (this.sort) {
      query_params['sort_name'] = Object.keys(this.sort)[0];
      query_params['sort_key'] = this.sort[query_params['sort_name']];
    }
    if (this.filter !== '') {
      query_params['global_search_string'] = this.filter;
    }
    return query_params;
  }

  prepareSort(column_name: string) {
    for (const key in this.sort) {
      if (column_name !== key) {
        delete this.sort[key];
      }
    }
  }

  sortChange(column_name: string) {
    this.prepareSort(column_name);
    this.sort[column_name] = !this.sort[column_name] || ('desc' === this.sort[column_name]) ? 'asc' : 'desc';
    this.onFilterChanges();
  }

  ngAfterViewInit() {
    this.goBack.goScroll();
  }
}
