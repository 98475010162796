import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../services/notification.service';
import { OverlayWindowService } from '../../single-window/shared/overlay-window.service';
import { HttpClient } from '../../../services/http.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-start-sales-create',
  templateUrl: './start-sales-create.component.html',
  styleUrls: ['./start-sales-create.component.scss']
})
export class StartSalesCreateComponent implements OnInit {

  @Output('onCreate') public onCreate: EventEmitter<any> = new EventEmitter<any>();
  public files = [];

  public form: UntypedFormGroup;

  constructor(private http: HttpClient, private overlayWindowService: OverlayWindowService,
              private fb: UntypedFormBuilder, private notify: NotificationService) {
  }

  ngOnInit() {
    const date = new DatePipe('en-US').transform(Date.now(), 'dd.MM.yyyy');
    this.form = this.fb.group({
      'complex_id': ['', Validators.required],
      'description': ['', Validators.required],
      'date_start': [date, Validators.required],
      'date_end': [date],
      'end_when_publish': [false],
      'files': [[]],
    });
  }


  onSubmit() {
    if (this.form.value.end_when_publish) {
      this.form.value.date_end = null;
    }
    this.form.value['files'].map(x => {
      this.files.push(x['id']);
    });
    const data = {
      'data': {
        'realty_id': this.form.value.complex_id,
        'description': this.form.value.description,
        'date_start': this.form.value.date_start,
        'date_end': this.form.value.date_end,
        'end_when_publish': this.form.value.end_when_publish,
        'files': this.files,
      }
    };

    this.http.post('StartOfSales.create', data).subscribe(
      response => {
        this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
        this.onCreate.emit({'value': 'Старт продаж'});
        this.overlayWindowService.closeHost();
      },
      error => this.notify.notifyError('Ошибка', error, 5000)
    );
  }
}
