import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {JsonClientService} from '../../shared/json-client';


@Injectable()
export class HousingControlResolve {


  constructor(private jsonRpc: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {

    const options = {
      'filter': {
        'global_search_string': route.parent.queryParams['global_search_string'] || null,
      },
    };

    return this.jsonRpc.post('Housing.get_control_last_added_corpus', options);
  }

}
