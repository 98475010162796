import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {JsonClientService} from '../../../shared/json-client';
@Injectable()
export class TariffMapLogResolve {

  private filter_columns = [
    'author_fio',
    'flat_caption',
    'flat_status',
    'old_voznagr_agenta',
    'old_voznagr_agenta_from_ddu',
    'old_dolya_subagenta_db',
    'old_dolya_subagenta_agd',
    'change_time',
  ];

  constructor(private jsonRpc: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {

    const prepared_filter = {};
    const prepared_filter_keys = this.filter_columns.filter(x => !!route.parent.queryParams[x]);
    const sort = {};

    prepared_filter_keys.forEach(x => prepared_filter[x] = route.parent.queryParams[x]);

    if (!!route.queryParams['sort_name']) {
      sort[route.queryParams['sort_name']] = route.queryParams['sort_key'] || 'asc';
    }

    const options = {
      'housing_id': route.params['housing_id'],
      'limit': 50,
      'offset': (+(route.queryParams['page'] || 1) - 1) * 50,
      'filter': {
        'fields': prepared_filter,
      },
      'sort': sort
    };
    return this.jsonRpc.post('Realty.tariff_log', options);
  }

}
