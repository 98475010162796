<div class="error-404">
    <!-- Container-fluid starts -->
    <div class="m-x-auto">
        <!-- Row start -->
        <div class="row no-gutters">
            <div class="text-uppercase col-xs-12">
                <h1>404 - Страница не найдена</h1>
                <a [routerLink]="['/']" class="button mt-3">Вернуться на главную</a>
            </div>
        </div>
        <!-- Row end -->
    </div>
    <!-- Container-fluid ends -->
</div>
