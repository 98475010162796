import {NgModule} from '@angular/core';
import {SettingsConstraintsModule} from './constraints/settings-constraints.module';

@NgModule({
  imports: [
    SettingsConstraintsModule,
  ],
  exports: [
    SettingsConstraintsModule,
  ]
})
export class SettingsCrmModule { }
