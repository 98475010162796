import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MaterialInputModule} from '../../../modules/@material-input';
import {SharedModule} from '../../../shared/shared.module';
import {DataTableModule} from '../../../modules/@datatable/datatables.module';
import {ReservLeadComponent} from './reserv-lead.component';
import {ReservLeadPageCreateComponent} from './reserv-lead-page-create/reserv-lead-page-create.component';
import {ReservLeadPageEditComponent} from './reserv-lead-page-edit/reserv-lead-page-edit.component';
import {ReservLeadPageSubstepEditComponent} from './reserv-lead-page-edit/reserv-lead-page-substep-edit/reserv-lead-page-substep-edit.component';
import {ReservLeadPageEditResolve} from './reserv-lead-page-edit/reserv-lead-page-edit.resolve';
import {RightWindowModule} from '../../../shared/right-window/right-window.module';
import {ManagersModule} from '../../managers/managers.module';
import {ReservLeadPageEditViewsComponent} from './reserv-lead-page-edit/reserv-lead-page-edit-views/reserv-lead-page-edit-views.component';
import {ReservLeadInfoComponent} from './reserv-lead-info/reserv-lead-info.component';
import {ReservLeadInfoTodayComponent} from './reserv-lead-info/reserv-lead-info-today/reserv-lead-info-today.component';
import {ReservLeadInfoAllComponent} from './reserv-lead-info/reserv-lead-info-all/reserv-lead-info-all.component';
import {ReservLeadInfoResolve} from './reserv-lead-info/reserv-lead-info.resolve';
import {ReservLeadInfoAllResolve} from './reserv-lead-info/reserv-lead-info-all/reserv-lead-info-all.resolve';
import {ReservLeadInfoTodayResolve} from './reserv-lead-info/reserv-lead-info-today/reserv-lead-info-today.resolve';
import {StagesDotsModule} from '../../../modules/@stages-dots/stages-dots.module';
import {PriceRankPipe} from '../../../pipes/prices.pipe';
import {PopoverModule} from '../../../modules/@popover/popober.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DataTableModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
    SharedModule,
    RightWindowModule,
    ManagersModule,
    RouterModule,
    StagesDotsModule,
    PopoverModule,
  ],
  declarations: [
    ReservLeadComponent,
    ReservLeadPageCreateComponent,
    ReservLeadPageEditComponent,
    ReservLeadPageSubstepEditComponent,
    ReservLeadPageEditViewsComponent,
    ReservLeadInfoComponent,
    ReservLeadInfoTodayComponent,
    ReservLeadInfoAllComponent,
  ],
  providers: [
    ReservLeadPageEditResolve,
    ReservLeadInfoResolve,
    ReservLeadInfoAllResolve,
    ReservLeadInfoTodayResolve,
    DatePipe,
    PriceRankPipe,
  ]
})
export class ReservLeadModule { }
