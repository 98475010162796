import { Component, forwardRef, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MaterialInputAutocompleteComponent } from './material-input-autocomplete.component';

@Component({
  selector: 'app-material-input-autocomplete-custom',
  templateUrl: './material-input-autocomplete-custom.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialInputAutocompleteCustomComponent),
      multi: true
    }
  ]
})
export class MaterialInputAutocompleteCustomComponent extends MaterialInputAutocompleteComponent {

  @Input() public nameValue = 'value';
  @Input() public nameLabel = 'description';
  @Output() public autocopleteChange: EventEmitter<any> = new EventEmitter<any>();

  onAutocopleteChange(event: any) {
    this.autocopleteChange.emit(event['value']);
  }

  @HostListener('focusout', ['$event'])
  public onBlur(event) {
    return false;
  }

}
