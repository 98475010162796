import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {AlternativeDatatablesInterface} from '../../../shared/alternative-datatables/alternative-datatables.interface';
import {AlternativeDatatablesComponent} from '../../../shared/alternative-datatables/alternative-datatables.component';
import {JsonClientService} from '../../../shared/json-client';
import {GoBackButtonService} from '../../../services/go-back-button.service';

@Component({
  templateUrl: './control-crm-not-imported-flats.component.html',
  styleUrls: [
    './control-crm-not-imported-flats.component.scss'
  ]
})
export class ControlCrmNotImportedFlatsComponent
  extends AlternativeDatatablesComponent
  implements AlternativeDatatablesInterface, OnInit, OnDestroy, AfterViewInit {

  public readonly head = [
    {'name': 'realty_id', 'value': '', 'caption': '#', 'width': '6rem', 'sort': true},
    {'name': 'realty_complex_caption',  'value': '', 'caption': 'Комплекс', 'width': '', 'sort': true},
    {'name': 'realty_housing_caption', 'value': '', 'caption': 'Корпус', 'width': '', 'sort': true},
    {'name': 'realty_caption', 'value': '', 'caption': 'Квартира', 'width': '', 'sort': true},
  ];

  public filter_columns = {};
  public form: UntypedFormGroup;
  public filter: string;

  private parasites = true;

  public filterSubject: Subject<void> = new Subject<void>();
  private filterSubscription: Subscription;
  private formSubscription: Subscription;

  public current_page = 1;

  public count;
  public rows;

  public sort: {[key: string]: string} = {};

  public current_flat: any = null;

  public set data(value) {
    this.count = value['count'];
    this.rows = value['rows'];
  }

  constructor(private router: Router, private fb: UntypedFormBuilder, public activatedRoute: ActivatedRoute,
              public goBack: GoBackButtonService,
              public http: JsonClientService) {
    super(router, activatedRoute, fb, http);

    this.filterSubscription = this.filterSubject
      .pipe(debounceTime(500))
      .subscribe(_ => this.onFilterChanges());

    this.head = this.head.map(x => {
      x.value = this.activatedRoute.snapshot.queryParams[x.name] || x.value;
      return x;
    });
    if (this.activatedRoute.snapshot.queryParams['sort_name']) {
      this.sort[this.activatedRoute.snapshot.queryParams['sort_name']] = this.activatedRoute.snapshot.queryParams['sort_key'];
    }
  }

  ngOnInit() {
    this.form = this.fb.group({});

    this.formSubscription = this.form.valueChanges
      .subscribe(_ => this.filterSubject.next());

    for (const el of this.head) {
      this.form.addControl(el.name, new UntypedFormControl(el.value));
    }

    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.onInitCall();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }

    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  onFilterChanged(): void {
    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.data = this.activatedRoute.snapshot.data['data'];
    this.current_page = +this.activatedRoute.snapshot.queryParams['page'] || 1;
  }

  onFilterChanges(text = null) {

    if (text !== null) {
      this.filter = text;
    }

    this.filter_columns = {};
    for (const el of this.head) {
      if (!this.form.get(el.name).value.length) {
        continue;
      }

      this.filter_columns[el.name] = this.form.get(el.name).value;
    }
    if (this.filter !== '') {
      this.filter_columns['global_search_string'] = this.filter;
    }
    if (this.sort) {
      this.filter_columns['sort_name'] = Object.keys(this.sort)[0];
      this.filter_columns['sort_key'] = this.sort[this.filter_columns['sort_name']];
    }
    if (this.parasites) {
      return this.parasites = !this.parasites;
    }

    this.router.navigate(['control', 'not-imported'], {
      queryParams: this.getQueryParams(),
    });
  }

  getQueryParams() {
    const query_params = {};

    for (const el of this.head) {
      if (!this.form.get(el.name).value.length) {
        continue;
      }
      query_params[el.name] = this.form.get(el.name).value;
    }
    if (this.sort) {
      query_params['sort_name'] = Object.keys(this.sort)[0];
      query_params['sort_key'] = this.sort[query_params['sort_name']];
    }
    if (this.filter !== '') {
      query_params['global_search_string'] = this.filter;
    }
    return query_params;
  }

  prepareSort(column_name: string) {
    for (const key in this.sort) {
      if (column_name !== key) {
        delete this.sort[key];
      }
    }
  }

  sortChange(column_name: string) {
    this.prepareSort(column_name);
    this.sort[column_name] = !this.sort[column_name] || ('desc' === this.sort[column_name]) ? 'asc' : 'desc';
    this.onFilterChanges();
  }
  ngAfterViewInit() {
    this.goBack.goScroll();
  }
}
