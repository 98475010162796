<div
  class="event-info__layout"
  [class.event-info__layout_open]="layout_opened">

  <a
    class="event-info__close"
    href="javascript:void(0)"
    (click)="closeEvent()">
    ×
  </a>
  <div class="event-info__content">
    <div class="event-info__content__wrapper">
      <ng-container>
        <div class="form_crm event-info__content__wrapper__constrains_table">
            <h3>{{window_caption}}<p class="bold">{{subagent_caption}}</p></h3>
            <p>{{window_text}}</p>
            <div class="form__group mb-2r">
              <div class="form__input">
                 <textarea [(ngModel)]="subagent_text[subagent_type]"
                 class="textarea md-valid" placeholder="Введите примечание"></textarea>
              </div>
            </div>
            <button (click)="editData(subagent_type)" class="button button mr-2">Да</button>
            <button (click)="closeEvent()" class="button button_main">Нет</button>
        </div>
      </ng-container>
    </div>
  </div>

</div>
