import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {PaginatorComponent} from "./paginator.component";
import {RouterModule} from "@angular/router";
import {PaginatorDirective} from "./paginator.directive";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
    PaginatorComponent,
    PaginatorDirective,
  ],
  exports: [
    PaginatorComponent,
  ]
})
export class PaginatorModule { }
