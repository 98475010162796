import {AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {GoogleChartsService} from '../shared/google-charts.service';
import * as uuid from 'uuid';

@Component({
  selector: 'app-g-charts-donut',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements AfterViewInit {

  private readonly colors_pallet = {
    'light': ['#e2ac4a', '#6abde5', '#a6d672', '#d784d4', '#f9839d'],
    'medium': ['#e38b4b', '#5c9ad5', '#7dbd5f', '#b068ba', '#ef6483'],
    'dark': ['#df6e42', '#586ea8', '#59974a', '#8c57a9', '#e24b6a'],

    'light_revert': ['#a6d672', '#d784d4', '#f9839d', '#e2ac4a', '#6abde5'],
    'medium_revert': ['#7dbd5f', '#b068ba', '#ef6483', '#e38b4b', '#5c9ad5'],
    'dark_revert': ['#59974a', '#8c57a9', '#e24b6a', '#df6e42', '#586ea8'],
  };

  public chart_id = 'g-chart-' + uuid.v4();
  private google = null;

  @Input('cssClass') public cssClass;
  @Input('data') public data: Array<string | number>;

  @Input('caption') public caption = '';
  @Input('legend') public legend = false;
  @Input('color') public color = 'medium';

  @Output('onSelect') public onSelect: EventEmitter<any> = new EventEmitter();

  constructor(private loader: GoogleChartsService) { }

  ngAfterViewInit() {
    this.loader.load().then(google => {
      this.google = google;
      this.displayCharts();
    });
  }

  private displayCharts() {
    this.google.charts.load('current', {packages: ['corechart']});
    this.google.charts.setOnLoadCallback(() => {
      const data = this.google.visualization.arrayToDataTable(this.data);

      const options = {
        title: this.caption,
        legend: this.legend,
        pieHole: 0.4,
        colors: this.colors_pallet[this.color]
      };

      const chart = new this.google.visualization.PieChart(document.getElementById(this.chart_id));
      chart.draw(data, options);

      this.google.visualization.events.addListener(chart, 'select', _ => {
        const selection = chart.getSelection();

        if (selection) {
          this.onSelect.emit(selection.shift());
        }
      });
    });
  }

  @HostListener('window:resize')
  private onResize() {
    this.displayCharts();
  }

}
