import {Component, EventEmitter, Host, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {HttpClient} from '../../../../services/http.service';
import {ScrollableHostComponent} from '../scrollable-host/host/host.component';
import {NotificationService} from '../../../../services/notification.service';
import {PartRatePipe} from '../../../../pipes/part-rate.pipe';

@Component({
  selector: 'app-flat-info-standalone',
  templateUrl: './flat-info-standalone.component.html',
  styleUrls: [
    './flat-info-standalone.component.scss'
  ]
})
export class FlatInfoStandaloneComponent implements OnInit {

  public _parent: any;
  public title = 'Информация о квартире';

  public form: UntypedFormGroup;

  public type_otd: Array<any> = [];
  public chain_list: Array<any> = [];

  @Input('edit_mode') public edit_mode = true;

  @Input('flat_id') public flat_id;
  @Input('floor_id') public floor_id;

  @Output('onSave') public onSave: EventEmitter<any> = new EventEmitter<any>();
  @Output('onCreate') public onCreate: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Host() private host: ScrollableHostComponent, private fb: UntypedFormBuilder,
              private http: HttpClient, private notify: NotificationService,
              private partRate: PartRatePipe) { }

  ngOnInit () {
    this.form = this.fb.group({});
  }

  setTitle(title) {
    this.title += ' - ' + title;
    this.host.reloadTitle();
  }

  save() {
    const data = Object.assign({}, this.form.value);

    data['proprietors'] = {
      'type': this.form.value['proprietors']['type'],
      'owners': this.form.value['proprietors'][this.form.value['proprietors']['type']],
    };
    if (this.form.get('conditions')) {
      data['conditions'] = Object.assign({}, this.form.get('conditions').value);
    }


    let method;
    let options;

    if (!this.flat_id) {
      method = 'Flat.create_flat';
      options = {
        'parent_id': this.floor_id,
        'data': data,
      };
    } else {
      method = 'Flat.update_flat';
      options = {
        'realty_id': this.flat_id,
        'data': data,
      };
    }

    if (options['data']['proprietors']['type'] === 'no') {
      options['data']['proprietors']['type'] = '';
      options['data']['proprietors']['owners'] = [];
    }

    let wrongPercent = true;
    if (options['data']['conditions']) {

      if (
        parseFloat(options['data']['conditions']['flat_lead_transfer_sub_percent_db']) > parseFloat(options['data']['conditions']['flat_dolya_subagenta_db']) ||
        parseFloat(options['data']['conditions']['flat_lead_transfer_sub_percent_agd']) > parseFloat(options['data']['conditions']['flat_dolya_subagenta_agd'])
      ) {
        wrongPercent = false;
        this.notify.notifyError('Ошибка', 'Неверный процент субагента', 3000);
      }

      if (wrongPercent) {
        options['data']['conditions']['flat_dolya_subagenta_db'] = this.partRate.transform(options['data']['conditions']['flat_voznagr_agenta'], options['data']['conditions']['flat_dolya_subagenta_db']);
        options['data']['conditions']['flat_dolya_subagenta_agd'] = this.partRate.transform(options['data']['conditions']['flat_voznagr_agenta_from_ddu'], options['data']['conditions']['flat_dolya_subagenta_agd']);

        if (options['data']['conditions']['flat_dolya_subagenta_db'] > 1 || options['data']['conditions']['flat_dolya_subagenta_agd'] > 1) {
          wrongPercent = false;
          this.notify.notifyError('Ошибка', 'Неверный процент субагента', 3000);
        }
      }
    }
    if (wrongPercent) {
      this.http.post(method, options)
        .subscribe(x => {
          if (this.flat_id) {
            this.onSave.emit(x);
          } else {
            this.onCreate.emit(x);
          }
        });
    }

  }

}
