import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

import {debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {NotificationService} from '../../services/notification.service';
import {JsonClientService} from '../json-client';


@Component({
  selector: 'app-address-component',
  templateUrl: './address-component.component.html',
  styleUrls: [
    './address-component.component.scss',
  ],
})
export class AddressComponentComponent implements OnInit, OnDestroy {

  public form: UntypedFormGroup;

  @Input() public drawable = false;
  @Input() public without_districts = false;

  @Input('form') public set _form (value: UntypedFormGroup) {
    this.form = value;
    if (this.form.value['address'].lat && this.form.value['address'].lat !== null) {
      this.latitude = this.form.value['address'].lat;
    }
    if (this.form.value['address'].lon && this.form.value['address'].lon !== null) {
      this.longitude = this.form.value['address'].lon;
    }
  }

  @Input() public subways: any = [];
  @Input() public highways: any = [];

  private subscription: Subscription;

  public latitude = 55.753091;
  public longitude = 37.622248;

  public districts: any;
  public resetMapVal = true;

  constructor(private notify: NotificationService, private j: JsonClientService) { }

  ngOnInit() {
    this.j.post('Dict.get_address_districts_full').then(
      response => {
        this.districts = response;
      },
      error => { }
    );
    this.addressSubscription();
  }

  addressSubscription(subs = true) {
    if (subs) {
      this.subscription = this.form.controls['address'].valueChanges.pipe(debounceTime(500)).subscribe(value => {
        this.j.post('Dadata.get_address', {'value': value['label'] ? value['label'] : '', 'limit': null, 'return_one_in_raw': true, 'return_dict': true}).then(address => {
          if (address['lat'] && address['lat'] !== null && address['lon'] && address['lon'] !== null) {
            this.onPlacemarkDrag([address['lat'], address['lon']]);
          }
        });
      });
    } else {
      this.subscription.unsubscribe();
    }
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onPlacemarkDrag([latitude, longitude]) {
    const address = Object.assign(this.form.value['address'], {
      'lat': latitude,
      'lon': longitude,
    });
    this.addressSubscription(false);
    this.form.get('address').patchValue(
      address
    );
    this.latitude = address['lat'];
    this.longitude = address['lon'];
    this.addressSubscription();
  }

  public resetMap() {
    this.resetMapVal = false;
    setTimeout(_ => {
      this.resetMapVal = true;
    }, 50);

  }

  public polygonCreate(polygon) {
    const value = Object.assign(this.form.get('address').value, {
      'advance_address_json': polygon,
    });
    this.addressSubscription(false);
    this.form.get('address').patchValue(value);
    this.addressSubscription();
  }
}
