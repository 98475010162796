import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appSidebarToggle]'
})
export class SidebarToggleDirective {

  private bodyClass = 'sidebar-mini';
  private minWidth = 992;
  private minHeight = 850;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.checkSidebar();
  }

  @HostListener('window:resize', ['$event.target'])
  private onResize(targetElement) {
    this.checkSidebar();
  }

  public checkSidebar() {
    if (window.innerWidth <= this.minWidth || window.innerHeight <= this.minHeight) {
      this.renderer.addClass(document.body, this.bodyClass);
    } else {
      const has_class = document.body.classList.contains(this.bodyClass);
      if (has_class) {
        this.renderer.removeClass(document.body, this.bodyClass);
      }
    }
  }
}


@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
  @Output('appClickOutside') appClickOutside: EventEmitter<any> = new EventEmitter();

  private localEvent = null;

  constructor() {
  }

  @HostListener('click', ['$event'])
  trackEvent(event) {
    this.localEvent = event;
  }

  @HostListener('document:click', ['$event'])
  compareEvent(event) {
    if (event !== this.localEvent) {
      this.appClickOutside.emit(event);
    }
    this.localEvent = null;
  }
}
