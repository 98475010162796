import {Component, EventEmitter, Host, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {OsmService} from '../osm.service';
import {OsmMapComponent} from '../osm-map/osm-map.component';
import {takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';

@Component({
  selector: 'osm-drawer',
  templateUrl: './osm-drawer.component.html',
  styleUrls: [
    './osm-drawer.component.scss'
  ]
})
export class OsmDrawerComponent implements OnInit, OnDestroy {

  private L;
  private ngDestroy = new Subject();

  public ready: Promise<void>;
  public ready_resolve: (...args) => void;
  public mapSubscribe: Subscription;

  @Input() public layer;
  @Input() public controls;
  @Input() public polygons = null;

  @Output() public onCreate = new EventEmitter<any>();

  constructor(private o: OsmService,
              @Host() private host_map: OsmMapComponent) { }

  ngOnInit(): void {
    this.ready = new Promise<void>(resolve => this.ready_resolve = resolve);

    this.mapSubscribe = this.o.$getMap
      .pipe(takeUntil(this.ngDestroy))
      .subscribe((L) => {
        if (!L) { return; }

        this.L = L;

        this.host_map.ready.then(_ => this.whenMapReady());
      });
  }

  initDrawOptions() {
    this.layer = new this.L.FeatureGroup();
    const drawPluginOptions = {
      position: 'topright',
      draw: {
        polygon: {
          allowIntersection: false,
          drawError: {
            color: '#e1e100',
            message: '<strong>Oh snap!<strong> you can\'t draw that!'
          },
          shapeOptions: {
            color: '#97009c'
          }
        },
        polyline: false,
        circle: false,
        rectangle: false,
        marker: false,
      },
      edit: {
        featureGroup: this.layer,
        remove: true
      }
    };

    this.controls = new this.L.Control.Draw(drawPluginOptions);
  }

  whenMapReady() {
    this.initDrawOptions();
    this.o.registerDraw(this.layer, this, this.host_map);

    if (this.polygons) {
      this.polygons.map(x => {
        const polygon = new this.L.Polygon(x);
        this.layer.addLayer(polygon);
        this.o.registerPolygonDraw(polygon);
      });
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy.next(null);
    this.ngDestroy.complete();
    if (this.mapSubscribe) {
      this.mapSubscribe.unsubscribe();
    }
  }

}
