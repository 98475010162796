<div class="tab-content-wrapper mt-4">
  <form class="form form_crm" [formGroup]="formGroup" novalidate *ngIf="isUser">
    <div class="row no-gutters gutters-4">
      <div class="col-sm-12 col-lg-6">
        <app-material-select formControlName="user_active" [require]="false" [caption]="'Доступ к системе'">
          <option [value]="'true'">Да</option>
          <option [value]="'false'">Нет</option>
        </app-material-select>
        <app-material-select formControlName="user_is_subagent" [require]="false" [caption]="'Субагент'">
          <option [value]="'true'">Да</option>
          <option [value]="'false'">Нет</option>
        </app-material-select>
      </div>
      <div class="clearfix"></div>
      <div class="col-sm-12 col-lg-6">
        <app-material-input formControlName="user_login" [autofill]="false" [placeholder]="'Логин'">Логин
        </app-material-input>
        <app-material-select formControlName="user_role_id" [require]="false" [caption]="'Роль'">
          <option [value]="role.role_id" *ngFor="let role of roles">
            {{role.role_caption}}
          </option>
        </app-material-select>
      </div>
      <div class="col-6">
        <app-material-input formControlName="pass_new" [autofill]="false" [type]="'text'" [placeholder]="'Не менее 8 символов'">
          Новый пароль
        </app-material-input>
      </div>
      <div class="col-6">
        <app-material-input formControlName="pass_new_repeat" [autofill]="false" [type]="'text'" [placeholder]="'Повторите пароль'">
          Повторите пароль
        </app-material-input>
      </div>
      <div class="col-12 mt-2">
        <button (click)="saveForm()" type="button" class="button button_main">Сохранить</button>
      </div>
    </div>
  </form>

  <form class="form form_crm" [formGroup]="formGroup" novalidate *ngIf="!isUser">
    <div class="mb-2">
      <app-alert-default>
        У данного физ. лица нет доступа в систему. <br>Вы можете его создать:
      </app-alert-default>
    </div>
    <div class="row no-gutters gutters-4">
      <div class="col-sm-12 col-lg-6">
        <app-material-select formControlName="user_active" [require]="true" [caption]="'Доступ к системе'">
          <option [value]="''">-- Не выбрано --</option>
          <option [value]="'true'">Да</option>
          <option [value]="'false'">Нет</option>
        </app-material-select>
        <app-material-select formControlName="user_is_subagent" [require]="true" [caption]="'Субагент'">
          <option [value]="''">-- Не выбрано --</option>
          <option [value]="'true'">Да</option>
          <option [value]="'false'">Нет</option>
        </app-material-select>
      </div>
      <div class="clearfix"></div>
      <div class="col-sm-12 col-lg-6">
        <app-material-input formControlName="user_login" [autofill]="false" [require]="true" [placeholder]="'Логин'">Логин
        </app-material-input>
        <app-material-select formControlName="user_role_id" [require]="true" [caption]="'Роль'">
          <option [value]="''">-- Не выбрано --</option>
          <option [value]="role.role_id" *ngFor="let role of roles">
            {{role.role_caption}}
          </option>
        </app-material-select>
      </div>
      <div class="col-6">
        <app-material-input formControlName="pass_new" [autofill]="false" [require]="true" [type]="'password'"
                            [placeholder]="'Не менее 8 символов'">
          Новый пароль
        </app-material-input>
      </div>
      <div class="col-6">
        <app-material-input formControlName="pass_new_repeat" [autofill]="false" [require]="true" [type]="'password'"
                            [placeholder]="'Повторите пароль'">
          Повторите пароль
        </app-material-input>
      </div>
      <div class="col-12 mt-2">
        <button (click)="addForm()" type="button" class="button button_main">Добавить</button>
      </div>
    </div>
  </form>
</div>
