import { Component, ViewChild, OnInit, OnDestroy, TemplateRef, Input } from '@angular/core';
import { HttpClient } from '../../services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataTableParamComponent } from '../../modules/@datatable/components/datatable-param.component';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-queue',
  templateUrl: './queue.component.html'
})
export class QueueListComponent extends DataTableParamComponent implements OnInit, OnDestroy {

  @ViewChild('priceTmpl', {static: true}) private price_tmpl: TemplateRef<any>;
  @ViewChild('priceAgdTmpl', {static: true}) private price_agd_tmpl: TemplateRef<any>;
  @ViewChild('priceSubTmpl', {static: true}) private price_sub_tmpl: TemplateRef<any>;

  public api_action = 'Data_tables.get_queue_crm';
  public columns: Array<{ [key: string]: any }>;
  private _activity: boolean = null;
  public formGroup: UntypedFormGroup;
  public current_operation_id: number;
  public tab_active = 1;
  public createSubscription: Subscription;

  public modal_caption = null;
  public modal_content = null;
  public modal_footer = null;

  constructor(public http: HttpClient, public route: ActivatedRoute,
              public fb: UntypedFormBuilder, router: Router) {
    super(http, route, router);
    this.formGroup = this.fb.group({
      'activity': ['null'],
    });
    this.formGroup.valueChanges.pipe(debounceTime(500)).subscribe((data) => {
      this.router_param['page'] = 1;
      this.current_page = 1;
      this.loadList();
    });
  }

  public set activity(value: boolean) {
    this._activity = value;
    this.loadList();
  }

  public get activity(): boolean {
    return this._activity;
  }


  prepareQuery() {
    const query = super.prepareQuery();
    query['filter']['fields'] = {'operation_is_active': this.activity};
    return query;
  }

  ngOnInit() {
    this.columns = [
      {'name': 'operation_serial_i', 'caption': '№', 'sortable': true},
      {'name': 'organisation_caption', 'caption': 'АН', 'sortable': true},
      {'name': 'operation_create_date', 'caption': 'Дата операции', 'sortable': true},
      {'name': 'housing_complex_caption', 'caption': 'Название ЖК', 'sortable': true},
      {'name': 'object_caption', 'caption': 'Объект', 'sortable': true},
      {'name': 'realty_flat_price', 'caption': 'Стоимость', 'sortable': true, 'template': this.price_tmpl},
      {'name': 'realty_flat_object_agv', 'caption': 'Аг.В', 'sortable': true, 'template': this.price_agd_tmpl},
      {'name': 'realty_flat_object_sub', 'caption': 'Суб.', 'sortable': true, 'template': this.price_sub_tmpl},
      {'name': 'pay_type', 'caption': 'Тип оплаты', 'sortable': true},
      {'name': 'is_active', 'caption': 'Статус', 'sortable': true},
      {'name': 'physical_manager_fio', 'caption': 'Ответственный', 'sortable': true},
      {'name': 'physical_subagent_fio', 'caption': 'Субагент', 'sortable': true},
      {'name': 'physical_subagent_phone', 'caption': 'Тел. субагента', 'sortable': true},
      {'name': 'appeal_client_fio', 'caption': 'Клиент', 'sortable': true},
      {'name': 'appeal_client_fio', 'caption': 'Тел. клиента', 'sortable': true},
      {'name': 'operation_view_status', 'caption': ''},
    ];
    super.ngOnInit();
  }


    ngOnDestroy() {
      if (this.createSubscription) {
        this.createSubscription.unsubscribe();
      }
    }

}
