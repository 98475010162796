import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsListComponent } from './list/news-list.component';
import { NewsCreateComponent } from './news-create/news-create.component';
import { DataTableModule } from '../../modules/@datatable/datatables.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialInputModule } from '../../modules/@material-input/material-input.module';
import {SharedModule} from '../../shared/shared.module';
import {NewsEditComponent} from './news-edit/news-edit.component';
import {NewsWrapperComponent} from './wrapper/wrapper.component';
import {NewsActionComponent} from './actions/action.component';
import {RouterModule} from '@angular/router';
import {AngularDraggableModule} from 'angular2-draggable';
import {ActionCreateComponent} from './action-create/action-create.component';
import {ActionEditComponent} from './action-edit/action-edit.component';
import {ActionArchiveComponent} from './action-archive/action-archive.component';
import {AccordionModule} from '../../modules/@accordion/accordion.module';

@NgModule({
    imports: [
        CommonModule,
        DataTableModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialInputModule,
        SharedModule,
        RouterModule,
        AngularDraggableModule,
        AccordionModule,
    ],
    declarations: [
        NewsListComponent,
        NewsCreateComponent,
        NewsEditComponent,
        NewsWrapperComponent,
        NewsActionComponent,
        ActionCreateComponent,
        ActionEditComponent,
        ActionArchiveComponent,
    ],
    exports: [
      NewsListComponent,
      NewsActionComponent
    ]
})
export class NewsModule {
}
