import {Injectable} from '@angular/core';
import {JsonClientService} from '../../../json-client';

@Injectable()
export class FieldHighwayService {

  constructor(private j: JsonClientService) { }

  loadHelpers() {
    return Promise.all([
      this.j.post('Dict.get_highway'),
    ]);
  }

}
