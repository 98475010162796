import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {DeactivateService} from './deactivate.service';

@Injectable()
export class DeactivateGuard {

  constructor(private DS: DeactivateService) { }

  canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, ...args): Promise<boolean> | boolean {
    return this.DS.testChange(component.was_changed);
  }

}
