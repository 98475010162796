<form class="form form_crm" [formGroup]="form" novalidate *ngIf="nalogForms && form">
  <div class="row" formGroupName="advanced">
    <div class="col-6">
      <app-material-input formControlName="organisation_inn" [placeholder]="'Инн'">
        Инн
      </app-material-input>
      <app-material-input formControlName="organisation_ogrn" [placeholder]="'Огрн'">
        Огрн
      </app-material-input>
      <app-material-input formControlName="organisation_who_register" [placeholder]="'Кем зарегистрировано'">
        Кем зарегистрировано
      </app-material-input>
      <app-material-input-datepicker formControlName="organisation_register_date" [placeholder]="'Дата регистрации'">
        Дата регистрации
      </app-material-input-datepicker>
      <app-material-input formControlName="organisation_kpp" [placeholder]="'КПП'">
        КПП
      </app-material-input>
      <app-material-select formControlName="organisation_tax_id" [require]="false" [caption]="'Форма налогообложения'">
        <option [value]="''">-- Не выбрано --</option>
        <option *ngFor="let nalogForm of nalogForms" [value]="nalogForm.organisation_tax_forms_id">
          {{nalogForm.organisation_tax_forms_caption}}
        </option>
      </app-material-select>
    </div>
    <div class="col-6">
      <app-material-input [placeholder]="'ОКПО'" formControlName="organisation_okpo">
        ОКПО
      </app-material-input>
      <app-material-input formControlName="organisation_okved" [placeholder]="'ОКВЭД'">
        ОКВЭД
      </app-material-input>
      <app-material-input formControlName="organisation_bank_bik" [placeholder]="'Бик'">
        Бик
      </app-material-input>
      <app-material-input formControlName="organisation_bank_scheta" [placeholder]="'Банк счет'">
        Банк счет
      </app-material-input>
      <app-material-input formControlName="organisation_rachetniy_schet" [placeholder]="'Расчетный счет'">
        Расчетный счет
      </app-material-input>
      <app-material-input formControlName="organisation_kor_schet" [placeholder]="'Корреспондентский счет'">
        Корреспондентский счет
      </app-material-input>
    </div>
  </div>
</form>
