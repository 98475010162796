import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhysicalListComponent } from './physical-list.component';
import { RouterModule } from '@angular/router';
import { DataTableModule } from '../../modules/@datatable/datatables.module';
import { TabsModule } from '../../modules/@tabs/tabs.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialInputModule } from '../../modules/@material-input/material-input.module';
import { PhysicalEditComponent } from './physical-edit/physical-edit.component';
import { PhysicalEditCommonComponent } from './physical-edit/physical-edit-common.component';
import { PhysicalEditAdditionallyComponent } from './physical-edit/physical-edit-additionally.component';
import { PhysicalEditOrganizationComponent } from './physical-edit/physical-edit-organization.component';
import { PhysicalEditUserComponent } from './physical-edit/physical-edit-user.component';
import { PhysicalEditPhotoComponent } from './physical-edit/physical-edit-photo.component';
import { PhysicalEditDocumentsComponent } from './physical-edit/physical-edit-documents.component';
import { SharedModule } from '../../shared/shared.module';
import { PhysicalCreateComponent } from './physical-create/physical-create.component';
import { PhysicalAboutResolve, PhysicalAdditionallyResolve, PhysicalCommonResolve, PhysicalDocumentsResolve,
  PhysicalOrganizationResolve, PhysicalPhotoResolve, PhysicalUserResolve } from './shared/physical.resolve';
import { PhysicalEditCertificateComponent } from './physical-edit/physical-edit-certificate.component';
import { PreloaderModule } from '../../modules/@preloader/preloader.module';
import { SimpleTableModule } from '../../modules/@simple-table/simple-table.module';
import {PhysicalSharedModule} from './physical-shared.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DataTableModule,
        TabsModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialInputModule,
        SharedModule,
        PreloaderModule,
        SimpleTableModule,
        PhysicalSharedModule,
    ],
    declarations: [
        PhysicalListComponent,
        PhysicalEditComponent,
        PhysicalEditCommonComponent,
        PhysicalEditAdditionallyComponent,
        PhysicalEditOrganizationComponent,
        PhysicalEditUserComponent,
        PhysicalEditPhotoComponent,
        PhysicalEditDocumentsComponent,
        PhysicalEditCertificateComponent,
    ],
    exports: [
        PhysicalListComponent,
        PhysicalEditComponent,
        PhysicalEditCommonComponent,
        PhysicalEditAdditionallyComponent,
        PhysicalEditUserComponent,
        PhysicalEditPhotoComponent,
        PhysicalEditDocumentsComponent,
        PhysicalEditCertificateComponent,
    ],
    providers: [
        PhysicalAboutResolve,
        PhysicalCommonResolve,
        PhysicalAdditionallyResolve,
        PhysicalOrganizationResolve,
        PhysicalUserResolve,
        PhysicalPhotoResolve,
        PhysicalDocumentsResolve,
    ]
})
export class PhysicalModule { }
