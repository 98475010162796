<h1>Участники старта продаж в {{complex_caption}}</h1>
<a [href]="urlExcel + this.start_of_sales_id" class="button button_sm mt-2" target="_blank"> Скачать список участников</a>
<div class="material-datatable-wrapper mt-4">
  <div class="table-responsive">
    <table class="table table-striped datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>
<ng-template #subagentTmpl let-row="row">
  <a
    href="javascript: void(0)"
    (click)="routerFunc('/physical/edit/', row.subagent_user_id)">
    {{row.subagent_user_fio}}
  </a>
</ng-template>
<ng-template #clientTmpl let-row="row">
  <a
    href="javascript: void(0)"
    (click)="routerFunc('/reservation/lead-info/', row.lead_id)">
    {{row.client_fio}}
  </a>
</ng-template>
<ng-template #orgTmpl let-row="row">
  <a
    href="javascript: void(0)"
    (click)="routerFunc('/entity/edit/', row.subagent_organisation_id)">
    {{row.subagent_organisation_caption}}
  </a>
</ng-template>
