import {Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, OnInit, Type} from '@angular/core';
import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import {ScrollableHostService} from './host.service';
import {ScrollableChildComponent} from '../child/child.component';

@Component({
  selector: 'app-scrollable-host',
  templateUrl: './host.component.html',
  styleUrls: [
    './host.component.scss'
  ],
  animations: [
    trigger('flyInOut', [
      state('*', style({
        marginLeft: '{{left_indent}}',
      }), {params: {left_indent: 0}}),
    ])
  ]
})
export class ScrollableHostComponent implements OnInit {

  private readonly childWidth = 736;

  public title = 'Заголовок окна';

  public factoryCollection: Array<[any, any]> = [];
  private childs: Map<ComponentFactory<any>, any> = new Map<ComponentFactory<any>, any>();

  public get left_indent(): string {
    /*
     * Set left indent
     */
    return '-' + (this.factoryCollection.length - 1) * this.childWidth + 'px';
  }

  private get childs_list(): Array<any> {
    /*
     * Return childs array
     */
    return Array.from(this.childs.values());
  }

  constructor(private factoryResolver: ComponentFactoryResolver, private hostService: ScrollableHostService) { }

  ngOnInit() {
    this.hostService.registerHost(this);
  }

  public appendFactory<T>(factory: ComponentFactory<T>, options): Promise<ComponentRef<T>> {
    /*
     * Appending factory
     */
    let resolver;
    const promise = new Promise<ComponentRef<T>>(resolve => resolver = resolve);

    this.childs.set(factory, resolver);
    this.factoryCollection.push([factory, options]);

    return promise;
  }

  public appendComponent<T>(componentType: Type<T>, options = {}): Promise<ComponentRef<T>> {
    /*
     * Convert componentType to factory and return it
     */
    const factory: ComponentFactory<T> = this.factoryResolver.resolveComponentFactory(componentType);
    return this.appendFactory<T>(factory, options);
  }

  public registerChild(child: ScrollableChildComponent) {
    /*
     * Register child component
     */
    const resolve = this.childs.get(child.factory);

    resolve(child.componentRef);

    this.childs.set(child.factory, child);
    this.reloadTitle();
  }

  public flushChilds() {
    /*
     * Flush all childrens component
     */
    for (let i = 0; i < this.childs_list.length; i++) {
      const component: ScrollableChildComponent = this.childs_list[i];

      this.childs.delete(component.factory);
      this.factoryCollection.splice(this.factoryCollection.findIndex(x => x[0] === component.factory), 1);

      component.componentRef.destroy();
    }

    this.reloadTitle();
  }

  public unregisterChild() {
    /*
     * Unregister child
     */
    const component: ScrollableChildComponent = this.childs_list[this.childs_list.length - 1];

    this.childs.delete(component.factory);
    this.factoryCollection.splice(this.factoryCollection.findIndex(x => x[0] === component.factory), 1);

    component.componentRef.destroy();
    this.reloadTitle();
  }

  public reloadTitle() {
    /*
     * Reload host window's title
     */
    const component: ScrollableChildComponent = this.childs_list[this.childs_list.length - 1];

    if (component && component.componentRef.instance['title']) {
      this.title = component.componentRef.instance['title'];
    }
  }

}
