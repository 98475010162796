import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MaterialInputModule} from '../@material-input';

import {FieldDateComponent} from './field-date/field-date.component';
import {FieldInputComponent} from './field-input/field-input.component';
import {FieldSelectComponent} from './field-select/field-select.component';
import {FieldTextareaComponent} from './field-textarea/field-textarea.component';
import {FieldCheckboxComponent} from './field-checkbox/field-checkbox.component';
import {FieldSelect2AutocompleteComponent} from './field-select2-autocomplete/field-select2-autocomplete.component';
import {FieldRadioComponent} from "./field-radio/field-radio.component";

@NgModule({
  imports: [
    CommonModule,
    MaterialInputModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    FieldInputComponent,
    FieldSelectComponent,
    FieldDateComponent,
    FieldTextareaComponent,
    FieldSelect2AutocompleteComponent,
    FieldCheckboxComponent,
    FieldRadioComponent,
  ],
  exports: [
    FieldInputComponent,
    FieldSelectComponent,
    FieldDateComponent,
    FieldTextareaComponent,
    FieldSelect2AutocompleteComponent,
    FieldCheckboxComponent,
    FieldRadioComponent,
  ]
})
export class FieldsModule { }
