
<br>
<div class="row no-gutters justify-content-center import">
  <div class="col-12">
    <ul>
      <li>Импорт не принимает файлы с пропущенными первыми строками</li>
      <li>Импорт принимает файлы только xlsx формата</li>
      <li>В некоторых случаях импорт не сможет определить и сопоставить квартиру и собственно обновить ее</li>
      <li>После завершения импорта вы получите подробный отчет об ошибках в импорте</li>
    </ul>

      <div class="row no-gutters gutters-2">
        <div class="col-sm-6 col-xs-12">
          <ng-container *ngIf="dict.length">
            <form class="form form_crm" [formGroup]="formGroup" novalidate>
              <p class="mb-2">Выберите источник импорта:</p>
              <div class="row no-gutters gutters-2">
                <div class="col-8">
                  <app-material-select formControlName="resourse" >
                    <option [value]="''" selected>-- Не выбрано --</option>
                    <option [value]="el.resource" *ngFor="let el of dict">{{el.caption}}</option>
                  </app-material-select>
                </div>
                <div class="col-auto text-left mt-2" *ngIf="questInfo.length > 0">
                  <popover-content #popoverInfo
                                   [title]="'Информация по полям'"
                                   [placement]="'left'"
                                   [closeOnClickOutside]="false"
                                   [closeOnMouseOutside]="true">
                    <p class="mb-0" *ngFor="let el of questInfo">
                      {{el}}
                    </p>
                  </popover-content>
                  <div [popover]="popoverInfo">
                    <i class="fa fa-2x fa-fw fa-question-circle c-pointer"></i>
                  </div>
                </div>
              </div>
              <p class="mb-2">Загрузите файл:</p>
              <button class="button" [disabled]="!formGroup.valid"  (click)="input.click()">Загрузить файл импорта</button>
              <div [hidden]="true" class="hidden">
                <input #input type="file" (change)="onFileChange($event)">
              </div>
            </form>
          </ng-container>
        </div>
        <div class="col-xs-12"></div>
        <div class="col-sm-6 col-xs-12">
          <form class="form form_crm" [formGroup]="formSource" novalidate>
            <p class="mb-2 mt-2r">Добавить новый источник импорта:</p>
            <div class="row no-gutters gutters-2">
              <div class="col-sm-6 col-xs-12">
                <app-material-input formControlName="id"
                                    [placeholder]="'ID'">
                  ID</app-material-input>
              </div>
              <div class="col-sm-6 col-xs-12">
                <app-material-input formControlName="caption"
                                    [placeholder]="'Название'">
                  Название</app-material-input>
              </div>
            </div>
            <div class="row no-gutters mt-2">
              <div class="col-6">
                <button class="button" [disabled]="!formSource.valid"  (click)="sourceAdd()">Сохранить</button>
              </div>
            </div>
          </form>
        </div>
      </div>

  </div>
</div>
<br>
<div class="row no-gutters justify-content-center" *ngIf="errorTable">
  <h2 class="c-red">Результаты импорта</h2>
  <div class="col-12" style="max-height: 400px;overflow:auto;">
    <table class="table table-hover compare-view">
      <tr *ngFor="let error of errorTable; let i = index"><td>{{error}}</td></tr>
    </table>
  </div>
</div>

<div class="mb-4" *ngIf="import_history">
  <p><strong>Ошибки последнего импорта</strong></p>
  <table class="table">
    <tr>
      <th>Дата загрузки</th>
      <th>Автор</th>
      <th>Файл</th>
    </tr>
    <tr *ngFor="let el of import_history">
      <td>{{el['date_upload']}}</td>
      <td>{{el['phys_fio']}}</td>
      <td>
        <a target="_blank" [href]="el['url']">{{el['filename']}}</a>
      </td>
    </tr>
  </table>
</div>

<ng-container *ngIf="formGroup.value.resourse">
  <div class="mb-4">
    <form class="form form_crm" [formGroup]="formGroupAuto" novalidate>

      <div class="row no-gutters">
        <div class="col-6">

          <app-material-select2-autocomplete formControlName="realty_id" [require]="true"
                                             [placeholder]="'Введите название комплекса'"
                                             [caption]="'Комплекс для добавления'"
                                             [api_action]="'Import.search_complex_from_import'"
                                             (label)="formGroupAuto.get('caption').patchValue($event)">
            <option [value]="formGroupAuto.value.realty_id">{{formGroupAuto.value.caption}}</option>
          </app-material-select2-autocomplete>

        </div>
      </div>
      <div class="row no-gutters mt-2">
        <div class="col-6">
          <button class="button" [disabled]="!formGroupAuto.valid"  (click)="complexAdd()">Сохранить</button>
        </div>
      </div>

    </form>
  </div>
  <div class="mb-4">
    <p><strong>Комплексы</strong></p>
    <table class="table">
      <tr>
        <th>Название</th>
        <th>Комментарий</th>
        <th>Статус</th>
        <th>Удаление</th>
      </tr>
      <tr *ngFor="let el of complex_list">
        <td>
          <a [routerLink]="['/housing2/complex/', el['realty_id']]" >{{el['caption']}}</a>
        </td>
        <td>
          <div class="form__group">
            <div class="form__input">
      <textarea [(ngModel)]="el['comment']"
                class="textarea md-valid textarea-small" placeholder="Комментарий"></textarea>
            </div>
          </div>
          <button (click)="saveComment(el['realty_id'], el['comment'])" class="button-small button">Сохранить</button>
        </td>
        <td>
          <label class="form__checkbox form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="el['is_active']" (change)="complexActivate(el['realty_id'], el['is_active'])">
            <span class="form__checkbox__wrapper">
                <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>
            <span class="form__checkbox__content"></span>
          </label>
        </td>
        <td>
          <a class="button button_icon button_icon_sub mr-1"
             (click)="complexDelete(el['realty_id'])">
            <i class="fa fa-trash-o"></i></a>
        </td>
      </tr>
    </table>
  </div>
</ng-container>
<app-preloader *ngIf="load"></app-preloader>
