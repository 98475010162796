
<ul class="navigation__tabs">
  <li class="navigation__tab">
    <a class="navigation__tab__link" routerLink="/instateka/requests" routerLinkActive="navigation__tab__link_active">
      Заявки <span class="navigation__tab__link_active__slide"></span>
    </a>
  </li>
  <li class="navigation__tab">
    <a class="navigation__tab__link" routerLink="/instateka/users" routerLinkActive="navigation__tab__link_active">
      Доступы <span class="navigation__tab__link_active__slide"></span>
    </a>
  </li>
  <li class="navigation__tab">
    <a class="navigation__tab__link" routerLink="/instateka/errors" routerLinkActive="navigation__tab__link_active">
      Ошибки <span class="navigation__tab__link_active__slide"></span>
    </a>
  </li>
</ul>
<router-outlet></router-outlet>
