import {
  AfterViewInit, Component, ElementRef, forwardRef, Inject, Input, OnDestroy, OnInit, Optional,
  TemplateRef
} from '@angular/core';
import {SimpleTableComponent} from './simple-table.component';
import {GoBackButtonService} from '../../services/go-back-button.service';

@Component({
  selector: 'app-simple-table-tr',
  templateUrl: './simple-table-row.component.html',
  styleUrls: ['./simple-table-row.component.scss']
})
export class SimpleTableRowComponent implements AfterViewInit, OnDestroy {

  @Input('template') public template: TemplateRef<any>;

  constructor(@Inject(forwardRef(() => SimpleTableComponent)) private parent: SimpleTableComponent,
              public elementRef: ElementRef, @Optional() public goBack: GoBackButtonService) { }

  ngAfterViewInit() {
    this.parent.registerRow(this);
    if (this.goBack) {
      this.goBack.goScroll();
    }
  }

  ngOnDestroy() {
    this.parent.unregisterRow(this);
  }

}
