import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HttpClient } from '../../../services/http.service';
import { Subscription } from 'rxjs';
import { OverlayWindowService } from '../../../_crm/single-window/shared/overlay-window.service';
import { DataTableParamComponent } from '../../../modules/@datatable/components/datatable-param.component';
import { BackrefWindowService } from '../../../_crm/single-window/shared/backref-window.service';
import { debounceTime } from 'rxjs/operators';
import {GoBackButtonService} from '../../../services/go-back-button.service';

@Component({
  selector: 'app-calendar-user-list',
  templateUrl: './calendar-user-list.component.html',
  styleUrls: ['./calendar-user-list.component.scss']
})
export class CalendarUserListComponent extends DataTableParamComponent implements OnInit, OnDestroy {

  public api_action = 'Calendar.dt_all_users';
  public columns: Array<{ [key: string]: any }>;
  public formGroup: UntypedFormGroup;
  public createSubscription: Subscription;
  public statistics: any;
  public statistics_view = false;

  @ViewChild('userTmpl', {static: true}) private userTmpl: TemplateRef<any>;
  @ViewChild('is_approveTmpl', {static: true}) private is_approveTmpl: TemplateRef<any>;
  @ViewChild('org2phyCaptiondTmpl', {static: true}) private org2phyCaptiondTmpl: TemplateRef<any>;
  @ViewChild('physicalFio', {static: true}) private physicalFiodHead: TemplateRef<any>;
  @ViewChild('org2phyCaption', {static: true}) private org2phyCaptiondHead: TemplateRef<any>;
  @ViewChild('is_approve', {static: true}) private is_approveHead: TemplateRef<any>;
  @ViewChild('physicalPhone', {static: true}) private physicalPhonedHead: TemplateRef<any>;
  @ViewChild('physical_access', {static: true}) private physical_access: TemplateRef<any>;
  @ViewChild('physicalCreate', {static: true}) private physicalCreate: TemplateRef<any>;
  @ViewChild('userIsActive', {static: true}) private userIsActive: TemplateRef<any>;

  @ViewChild('is_active', {static: true}) private is_active: TemplateRef<any>;
  @ViewChild('physical_role', {static: true}) private physical_role: TemplateRef<any>;

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router, private goBack: GoBackButtonService,
              public fb: UntypedFormBuilder, private overlayWindowService: OverlayWindowService,
              public backrefWindowService: BackrefWindowService) {
    super(http, route, router);
    this.formGroup = this.fb.group({
      'physical_fio': [''],
      'physical_create': [''],
      'org_socrachcaption': [''],
      'is_approve': [''],
      'physical_phone': [''],
      'physical_access': [''],
      'is_active': [''],
      'physical_role': [''],
    });
    this.updateForm(this.formGroup);
    this.formGroup.valueChanges.pipe(debounceTime(500))
      .subscribe((data) => {
      this.changeForm(this.formGroup);
    });

    this.backrefWindowService.routerInit();
  }

  ngOnInit() {
    this.goBack.header = '<h1>Список пользователей системы</h1>';
    this.columns = [
      {'caption': '№', 'name': 'num', 'sortable': true},
      {'caption': 'ФИО', 'name': 'physical_fio', 'sortable': true,
        'templateHead': this.physicalFiodHead, 'template': this.userTmpl},
      {'caption': 'Организация', 'name': 'org_socrachcaption', 'sortable': true,
        'template': this.org2phyCaptiondTmpl, 'templateHead': this.org2phyCaptiondHead},
      {'caption': 'Телефон', 'name': 'physical_phone', 'sortable': true,
        'templateHead': this.physicalPhonedHead},
      {'caption': 'Доступ', 'name': 'physical_access', 'sortable': true, 'templateHead': this.physical_access},
      {'caption': 'Дата регистрации', 'name': 'physical_create', 'sortable': true,
        'templateHead': this.physicalCreate},
      {'caption': 'Обучен', 'name': 'is_approve', 'sortable': true,
        'template': this.is_approveTmpl, 'templateHead': this.is_approveHead},
      {'caption': 'Активен', 'name': 'is_active', 'sortable': false,
        'template': this.userIsActive, 'templateHead': this.is_active},
      {'caption': 'Роль', 'name': 'physical_role', 'sortable': true,
        'templateHead': this.physical_role},
      {'caption': 'Сертификат от', 'name': 'cert_date', 'sortable': true},
    ];
    super.ngOnInit();
    this.getStatistics();
  }

  ngOnDestroy() {
    if (this.createSubscription) {
      this.createSubscription.unsubscribe();
    }
    this.backrefWindowService.routerDestroy();
  }

  prepareQuery() {
    const result = super.prepareQuery();
    result['filter']['fields'] = {};

    result['filter']['fields'] = {
      'physical_fio': this.formGroup.value.physical_fio,
      'physical_create': this.formGroup.value.physical_create,
      'org_socrachcaption': this.formGroup.value.org_socrachcaption,
      'is_approve': this.formGroup.value.is_approve,
      'user_login': this.formGroup.value.user_login,
      'physical_phone': this.formGroup.value.physical_phone,
      'physical_access': this.formGroup.value.physical_access,
      'physical_role': this.formGroup.value.physical_role,
      'is_active': this.formGroup.value.is_active,
    };

    return result;
  }

  onExcel() {
    const data = this.prepareQuery();
    this.http.post('Calendar.get_upload_link', data).subscribe(res => {
      if (res) {
        // window.open(res, '_blank');
        // window.location.href = res;
        const a = document.createElement('a');
        a.href = res;
        a.setAttribute('target', '_blank');
        a.click();
        a.remove();
      }
    });
  }

  showStatistics() {
    this.statistics_view = !this.statistics_view;
  }

  click_is_approve(val) {
    if (this.formGroup.value.is_approve === val) {
      this.formGroup.get('is_approve').patchValue('');
    } else {
      this.formGroup.get('is_approve').patchValue(val);
    }
  }

  getStatistics() {
    this.http.post('Calendar.statistic_users').subscribe(res => {
      this.statistics = res;
    });
  }

}
