<div class="row">
  <div class="col-12">
    <h2>Инструкция по работе с таблицами массового занесения</h2>
    <div class="row">
      <div class="col">
        <h5 class="mb-3 mt-3">Таблица &laquo;Регламенты и акты&raquo;:</h5>
        <p class="mt-3">Таблица &laquo;Регламенты и акты&raquo; содержит информацию о состоянии заполненности регламентов и актов просмотра на
          жилом комплексе. Интерфейс имеет фильтры по Застройщику, названию ЖК и фильтр с параметрами.
          Также в интерфейсе реализована возможность массовой загрузки актов просмотра и регламентов на
          выбранных жилых комплексах. Выбрать жилые комплексы необходимо с помощью &laquo;галки&raquo; в левой части таблицы. </p>
        <p><b>Таблица имеет 3 поля для фильтрации:</b></p>
        <ol class="pl-3">
          <li class="mb-3">Поле фильтрации по названию застройщика<br>
          </li>
          <li class="mb-3">Поле фильтрации по названию жилого комплекса.
            Для перехода в карточку ЖК нажмите на название<br></li>
          <li class="mb-3">Поле с возможностью отфильтровать данные по параметрам:<br>
            <ul class="dashed pl-2">
              <li>На витрине (Отображает только те ЖК, которые выводятся на витрине)
              </li>
              <li>Нет регламента (Отображает только те ЖК, где нет регламента)
              </li>
              <li>Нет акта просмотра (Отображает только те ЖК, где нет актов просмотра)
              </li>
            </ul>
          </li>
        </ol>
        <p><i>По умолчанию выводятся те ЖК, которые активны и не имеют регламентов или актов.</i></p>
        <h5 class="mb-3 mt-3">Таблица &laquo;Условия покупки&raquo;:</h5>
        <p class="mt-3">Таблица &laquo;Условия покупки&raquo; содержит информацию список ЖК.
          Интерфейс имеет фильтры по Застройщику, названию ЖК и фильтр с параметрами.
          Также в интерфейсе реализована возможность массовой загрузки условий покупки в катаблог корпусов внутри выбранных ЖК.
          Выбрать жилые комплексы необходимо с помощью &laquo;галки&raquo; в левой части таблицы. </p>
        <p><b>Таблица имеет 3 поля для фильтрации:</b></p>
        <ol class="pl-3">
          <li class="mb-3">Поле фильтрации по названию застройщика<br>
          </li>
          <li class="mb-3">Поле фильтрации по названию жилого комплекса.
            Для перехода в карточку ЖК нажмите на название<br></li>
          <li class="mb-3">Поле с возможностью отфильтровать данные по параметрам:<br>
            <ul class="dashed pl-2">
              <li>На витрине (Отображает только те ЖК, которые выводятся на витрине)</li>
              <li>Акции (Отображает только те ЖК, где хотя бы на 1 корпусе не заполнены акции)</li>
              <li>Банки и ипотека (Отображает только те ЖК, где хотя бы на 1 корпусе не заполнены банки и ипотека)</li>
              <li>Рассрочка (Отображает только те ЖК, где хотя бы на 1 корпусе не заполнена рассрочка)</li>
              <li>Взаимозачет (Отображает только те ЖК, где хотя бы на 1 корпусе не заполнен взаимозачет)</li>
            </ul>
          </li>
        </ol>
        <p><i>По умолчанию выводятся те ЖК, которые активны.</i></p>
        <h5 class="mb-3 mt-3">Пример использования:</h5>
        <p><b>Задача</b><br>
        Необходимо перезаписать регламенты и акты на нескольких ЖК определенного застройщика</p>
        <ul class="pl-3">
          <li class="mb-3 mt-3"><b>Шаг 1</b><br>
            <p>Введите в поле фильтров нужного застройщика и выберите ЖК (Для выбора всех элементов
              на текущей странице используйте галочку сверху таблицы, также можно добавить к выбору
              элементы следующей страницы, нажав галочку там)</p>
          </li>
          <div class="instruction-image mr-3 mb-3"
               (click)="displayImage(images[0])"
               [style.background-image]="'url('+images[0]['src']+')'"></div>
          <li class="mb-3 mt-3"><b>Шаг 2</b><br>
            <p>Сверху над фильтрами кликнуть левой кнопкой мыши на &laquo;перезаписать регламенты&raquo;</p>
          </li>
          <div class="instruction-image mr-3 mb-3"
               (click)="displayImage(images[2])"
               [style.background-image]="'url('+images[2]['src']+')'"></div>
          <li class="mb-3 mt-3"><b>Шаг 3</b><br>
            <p>Выбрать файлы необходимые для загрузки и нажать кнопку &laquo;Сохранить&raquo;.
              Для регламента при загрузки файлов доступны параметры  &laquo;Описание&raquo; и &laquo;Показывать в Квартире и ТК&raquo;
              Для загрузки актов, необходимо вернуться на Шаг2 и нажать кнопку &laquo;перезаписать акты просмотра&raquo;,
              в параметрах доступно поле &laquo;Описание&raquo;.
              </p>
          </li>
          <div class="row ml-0">
            <div class="instruction-image mr-3 mb-3"
                 (click)="displayImage(images[3])"
                 [style.background-image]="'url('+images[3]['src']+')'"></div>
            <div class="instruction-image mr-3 mb-3"
                 (click)="displayImage(images[4])"
                 [style.background-image]="'url('+images[4]['src']+')'"></div>
            <div class="instruction-image mr-3 mb-3"
                 (click)="displayImage(images[5])"
                 [style.background-image]="'url('+images[5]['src']+')'"></div>
            <div class="instruction-image mr-3 mb-3"
                 (click)="displayImage(images[6])"
                 [style.background-image]="'url('+images[6]['src']+')'"></div>
          </div>
        </ul>
      </div>
    </div>
  </div>
</div>
