import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { NotificationService } from '../../../services/notification.service';
import { HttpClient } from '../../../services/http.service';

import { filter } from 'rxjs/operators';
import {BreadcrumbsService} from '../../../shared/breadcrumbs/services';
import {GoBackButtonService} from '../../../services/go-back-button.service';

@Component({
  selector: 'app,yamap-physical-edit',
  templateUrl: './physical-edit.component.html',
  styleUrls: ['./physical-edit.component.scss']
})
export class PhysicalEditComponent implements OnInit, OnDestroy {

  private _data: any = null;
  private _subscription: Subscription = null;
  public currentUrl = null;
  public physicalId = null;
  private routerSubscription: Subscription;

  public set data(value: any) {
    this._data = value;
  }

  public get data(): any {
    return this._data;
  }

  constructor(public activatedRoute: ActivatedRoute,
              public router: Router, public fb: UntypedFormBuilder, public goBack: GoBackButtonService,
              private http: HttpClient, public notify: NotificationService, private b: BreadcrumbsService) {

    this._subscription = this.router.events.pipe(filter(x => x instanceof NavigationEnd))
      .subscribe(x => {
        this.data = this.activatedRoute.snapshot.data['about'];
        this.currentUrl = this.activatedRoute.firstChild.routeConfig.path;
      });
  }

  ngOnInit() {
    this.data = this.activatedRoute.snapshot.data['about'];
    this.physicalId = this.activatedRoute.snapshot.params['id'];
    this.goBack.header = '<h3>Карточка физического лица "' + this._data['physical_fio'] + '"</h3>';
    this.routerSubscription = this.router.events.subscribe((val) => {
      this.goBack.header = '<h3>Карточка физического лица "' + this._data['physical_fio'] + '"</h3>';
    });
  }


  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

}
