import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';

import {UntypedFormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-mobile-sort-vitrine',
  templateUrl: 'mobile-sort-vitrine.component.html',
  styleUrls: ['mobile-sort-vitrine.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileSortVitrineComponent implements OnInit, OnDestroy {

  @Input('formGroup') public formGroup: UntypedFormGroup;
  public close: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (!this.cd['destroyed']) {
      this.cd.detach();
    }
  }

  closeTrigger() {
    this.close.next(true);
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }
}
