import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '../../../services/http.service';

@Component({
  templateUrl: './calendar-teaching-popup.component.html',
  styleUrls: ['./calendar-teaching-popup.component.scss']
})
export class CalendarTeachingPopupComponent implements OnInit {

  public data;
  public search_string = '';

  @Input('teached') public teached;

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.http.post('History.organisations_study', {'approve': this.teached})
      .subscribe(x => this.data = x);
  }

}
