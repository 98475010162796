<div class="form__group" [ngClass]="cssClass">
  <div class="form__label" [class.form__label_require]="require && !disabled" [class.form__label_error]="error">
    <ng-content></ng-content>
  </div>
  <div class="form__input" [class.form__input_disabled]="disabled" [class.form__input_focus]="focus"
       [class.form__input_error]="error">
    <div class="form__input_icon icon icon_Small_Calendar c-pointer" *ngIf="!disabled" (click)="input.focus()"></div>
    <input appInitializeDatepicker (onDateSelect)="onChangeDate(input)" [type]="'text'" [disabled]="disabled"
       class="input input_icon" [value]="current_value" (keyup)="onChanges($event.target)"
       (focus)="focus = true"
       (blur)="focus = false"
       [inputMask]="mask" [placeholder]="placeholder"
       [ngClass]="{'md-valid': current_value && (current_value.length || current_value > 0)}" #input>
  </div>
</div>
