import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, Renderer2, SimpleChange } from '@angular/core';

@Directive({
  selector: '[appDropdown]'
})
export class DropdownDirective implements OnChanges {

  @Input('appDropdown') public appDropdown = 'active';
  @Input('dopClass') public dopClass: string = null;
  @Input('globalHide') public globalHide = false;
  @Input('targetElement') public targetElement: any;
  @Input('changeDropdown') public changeDropdown = false;
  @Output('onChangeDropdown') public onChangeDropdown: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    if (changes['changeDropdown']) {
      if (changes['changeDropdown'].currentValue === true) {
        this.open();
      } else if (changes['changeDropdown'].currentValue === false) {
        this.close();
      }
    }
  }

  @HostListener('click', ['$event'])
  private onClick(event) {
    event.preventDefault();

    const has_class = this.targetElement.classList.contains(this.appDropdown);
    if (!has_class) {
      this.open();
    }
    else {
      this.close();
    }
  }

  @HostListener('document:click', ['$event.target'])
  private outside(targetElement) {
    if (!this.globalHide) {
      return;
    }

    const sweet2 = document.getElementsByClassName('swal2-container');
    const select2 = document.getElementsByClassName('select2-container--open');
    const clickedInside = this.targetElement.contains(targetElement) ||
      this.targetElement.parentNode.contains(targetElement);

    if (!clickedInside && sweet2.length === 0 && select2.length === 0) {
      this.close();
    }
  }

  close() {
    this.renderer.removeClass(this.targetElement, this.appDropdown);
    if (this.dopClass) {
      this.renderer.removeClass(this.elementRef.nativeElement, this.dopClass);
    }
    this.changeDropdown = false;
    this.onChangeDropdown.emit(false);
  }

  open() {
    this.renderer.addClass(this.targetElement, this.appDropdown);
    if (this.dopClass) {
      this.renderer.addClass(this.elementRef.nativeElement, this.dopClass);
    }
    this.changeDropdown = true;
    this.onChangeDropdown.emit(true);
  }

}


@Directive({
  selector: '[appSidebarToggle]'
})
export class SidebarToggleDirective {

  private bodyClass = 'sidebar-collapse';

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    if (window.innerWidth <= 767) {
      this.renderer.removeClass(document.body, 'sidebar-open');
      this.renderer.removeClass(document.body, 'sidebar-collapse');
    } else if (window.innerWidth <= 1024) {
      this.renderer.removeClass(document.body, 'sidebar-open');
      this.renderer.addClass(document.body, 'sidebar-collapse');
    }
  }

  @HostListener('click', ['$event'])
  private onClick(event) {
    event.preventDefault();

    const additionalClass = window.innerWidth < 767 ? 'sidebar-open' : this.bodyClass,
      body = document.getElementById('app-body'),
      has_class = body.classList.contains(additionalClass);

    if (!has_class) {
      this.renderer.addClass(body, additionalClass);
    }
    else {
      this.renderer.removeClass(body, additionalClass);
    }
  }

  @HostListener('window:resize', ['$event.target'])
  private onResize(targetElement) {
    const width = targetElement.innerWidth;
    const body = document.body;
    if (width <= 767) {
      this.renderer.removeClass(body, 'sidebar-open');
      this.renderer.removeClass(body, 'sidebar-collapse');
    } else if (width <= 1024) {
      this.renderer.removeClass(body, 'sidebar-open');
      this.renderer.addClass(body, 'sidebar-collapse');
    } else {
      this.renderer.removeClass(body, 'sidebar-collapse');
      this.renderer.addClass(body, 'sidebar-open');
    }
  }

}

@Directive({
  selector: '[appSidebarLink]'
})
export class SidebarCloseMobileDirective {

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('click', ['$event'])
  private onClick(event) {
    event.preventDefault();

    if (window.innerWidth < 768) {
      this.renderer.removeClass(document.body, 'sidebar-open');
    }
  }

}


@Directive({
  selector: '[appSidebarDropdown]'
})
export class AppSidebarDropdownDirective {

  @Input('appSidebarDropdown') public appSidebarDropdown = 'active';

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('click', ['$event'])
  private onClick(event) {
    event.preventDefault();

    const parent = this.elementRef.nativeElement.parentNode,
      has_class = parent.classList.contains(this.appSidebarDropdown),
      treeview_list = document.getElementsByClassName('treeview');

    for (let i = 0, element; i < treeview_list.length; i++) {
      this.renderer.removeClass(treeview_list[i], this.appSidebarDropdown);
    }

    if (!this.elementRef.nativeElement.parentNode.classList.contains('treeview')) {
      return;
    }

    if (!has_class) {
      this.renderer.addClass(this.elementRef.nativeElement.parentNode, this.appSidebarDropdown);
    }
    else {
      this.renderer.removeClass(this.elementRef.nativeElement.parentNode, this.appSidebarDropdown);
    }
  }

}
