<p class="mt-4 mb-2">
  Всего: <b>{{count}}</b>
</p>
<div class="material-datatable-wrapper">
  <div class="table-responsive">
    <table class="table">
      <tr>
        <th *ngFor="let el of head" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
          <div [formGroup]="form">
            <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
              <div class="col table__input">
                <ng-container *ngIf="el.input">
                  <app-material-input
                    [placeholder] = "el.caption"
                    [formControlName] = "el.name"
                    [cssClass]="'mb-0'">
                  </app-material-input>
                </ng-container>
                <ng-container *ngIf="!el.input && el.name !== 'search_fields'">
                  {{el.caption}}
                </ng-container>
                <ng-container *ngIf="el.name === 'search_fields'">
                  <app-material-select2 [noCopy]="false" [placeholder]="'Все объекты'" [multiple]="true" formControlName="search_fields">
                    <option [value]="'cian'">Не выбрана Cian новостройка</option>
                    <option [value]="'cian_housing'">Не выбран Cian корпус</option>
                    <option [value]="'yandex'">Не выбрана Yandex новостройка</option>
                    <option [value]="'yandex_housing'">Не выбран Yandex корпус</option>
                    <option [value]="'avito'">Не выбрана Avito новостройка</option>
                    <option [value]="'avito_housing'">Не выбран Avito корпус</option>
                  </app-material-select2>
                </ng-container>
              </div>
              <div class="col-auto" *ngIf="el.sort">
                <a href="javascript:void(0)" (click)="sortChange(el.name)">
                  <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                      'icon_new-sort-down': 'asc' == sort[el.name],
                                      'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                </a>
              </div>
            </div>
          </div>
        </th>
      </tr>
      <!-- // -->
      <tr *ngFor="let row of rows; let row_index = index">
        <td>
          <a class="d-block mb-2" *ngIf="row['complex_id']" href="/housing2/complex/{{row['complex_id']}}"
             (click)="goBack.newLink('/housing2/complex/' + row['complex_id'], 'complex_caption' + row_index, $event)">
            {{row['complex_caption']}}
          </a>
        </td>
        <td>
          <ng-container *ngIf="row['ids'].length">
            <ng-container *ngFor="let el of row['ids']; let row_index = index">
              <a class="d-block mb-2" *ngIf="el[0]" href="/housing2/house/{{el[0]}}"
                 (click)="goBack.newLink('/housing2/house/' + el[0], 'house_caption' + row_index, $event)">
                {{el[1]}}
              </a>
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </table>
  </div>
</div>
<div class="alt-pagination">
  <app-paginator
    [queryParams]="filter_columns"
    [itemsPerPage]="50"
    [itemCount]="count"
    [currentPage]="current_page"
    [baseUrl]="'/cian-yandex'">
  </app-paginator>
</div>
