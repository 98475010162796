import { Component, ComponentRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DataTableParamComponent } from '../../modules/@datatable/components/datatable-param.component';
import { UntypedFormBuilder  } from '@angular/forms';
import { Subscription } from 'rxjs';
import { OverlayWindowService } from '../single-window/shared/overlay-window.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '../../services/http.service';
import { NotificationService } from '../../services/notification.service';
import {GoBackButtonService} from '../../services/go-back-button.service';
import {HolidayCreateComponent} from './holiday-create/holiday-create.component';
import {HolidayEditComponent} from './holiday-edit/holiday-edit.component';
import {FancyboxService} from '../../shared/fancybox.service';


@Component({
  selector: 'app-list-holiday',
  templateUrl: './holiday-list.component.html',
  styleUrls: ['./holiday-list.component.scss']
})
export class HolidayListComponent extends DataTableParamComponent implements OnInit, OnDestroy {

  public api_action = 'Holiday.get_holidays';
  public columns: Array<{ [key: string]: any }>;
  public createSubscription: Subscription;
  public editSubscription: Subscription;
  private _is_archive = false;
  private file = '';

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router,
              public fb: UntypedFormBuilder, private overlayWindowService: OverlayWindowService, private goBack: GoBackButtonService,
              public notify: NotificationService, private lightbox: FancyboxService) {
    super(http, route, router);
  }

  @ViewChild('buttonTmpl', {static: true}) private buttonTmpl: TemplateRef<any>;
  @ViewChild('fileTmpl', {static: true}) private fileTmpl: TemplateRef<any>;
  @ViewChild('fileIconTmpl', {static: true}) private fileIconTmpl: TemplateRef<any>;
  @ViewChild('nameTmpl', {static: true}) private nameTmpl: TemplateRef<any>;
  @ViewChild('archiveTmpl', {static: true}) private archiveTmpl: TemplateRef<any>;
  @ViewChild('viewTmpl', {static: true}) private viewTmpl: TemplateRef<any>;

  ngOnInit() {
    this.goBack.header = '<h1>График работы в праздничные дни</h1>';
    this.columns = [
      {'caption': 'Баннер', 'name': 'file_url', 'sortable': false, 'template': this.fileTmpl, 'width': '40px'},
      {'caption': 'Иконка', 'name': 'file_icon_url', 'sortable': false, 'template': this.fileIconTmpl, 'width': '40px'},
      {'caption': 'Дата создания', 'name': 'create_time', 'sortable': false},
      {'caption': 'Период отображения', 'sortable': false, template: this.viewTmpl},
      {'caption': 'Даты в хедере', 'name': 'dates_string', 'sortable': false},
      {'caption': 'Автор', 'name': 'author_name', 'sortable': false},
      {'caption': '', 'sortable': false, 'template': this.archiveTmpl},
      {'caption': '', 'template': this.buttonTmpl}
    ];
    super.ngOnInit();
  }

  ngOnDestroy() {
  }

  createHoliday() {
    this.overlayWindowService.openComponentInPopup(HolidayCreateComponent)
      .then((factory: ComponentRef<HolidayCreateComponent>) => {
        this.createSubscription = factory.instance.onCreate.subscribe(_ => this.loadList());
      });
  }

  public get is_archive() {
    return this._is_archive;
  }

  public set is_archive(value: any) {
    this._is_archive = !(value === 'false' || value === false);
    this.loadList();
  }

  prepareQuery() {
    const query = super.prepareQuery();
    query['filter']['is_archive'] = this._is_archive;
    return query;
  }

  deleteNews(id) {
    NotificationService.swalConfirm('Вы уверены?', 'Выбранный график работы будет перемещен в архив')
      .then(() => {
        this.http.post('Holiday.delete',
          {'id': +id}
        ).subscribe(
          (response) => {
            this.notify.notifySuccess('График работы успешно удален', '', 2000);
            this.loadList();
          },
          (error) => this.notify.notifyError('Ошибка', error, 5000)
        );
      }).catch(() => false);
  }

  editNews(holiday_item) {
    this.overlayWindowService.openComponentInPopup(HolidayEditComponent, holiday_item)
      .then((factory: ComponentRef<HolidayEditComponent>) => {
        this.editSubscription = factory.instance.onEdit.subscribe(_ => this.loadList());
      });
  }

  displayImage(file_url) {
    this.lightbox.open([{'src': file_url, 'thumb': file_url, 'caption': ''}], 0);
  }

}
