import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableModule } from '../../modules/@datatable/datatables.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialInputModule } from '../../modules/@material-input';
import {StartSalesComponent} from './start-sales.component';
import {PaginatorModule} from '../../shared/housing-modules/modules';
import {StartSalesCreateComponent} from './start-sales-create/start-sales-create.component';
import {StartSalesEditComponent} from './start-sales-edit/start-sales-edit.component';
import {AlertModule} from '../../modules/@alert';
import {ModalModule} from '../../modules/@modal';
import {StartSalesMembersComponent} from './start-sales-members/start-sales-members.component';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    DataTableModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
    PaginatorModule,
    AlertModule,
    ModalModule,
    RouterModule,
  ],
  declarations: [StartSalesComponent,
    StartSalesCreateComponent,
    StartSalesEditComponent,
    StartSalesMembersComponent],
  exports: [StartSalesComponent,
    StartSalesEditComponent,
    StartSalesMembersComponent],
})
export class StartSalesModule {
}
