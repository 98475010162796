import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialInputModule} from '../../modules/@material-input';
import {DataTableModule} from '../../modules/@datatable/datatables.module';
import {AppealsListComponent} from './list/appeals-list.component';
import {AppealsRoutingModule} from './appeals-routing.module';
import {AppealsElementComponent} from './element/appeals-element.component';
import {AppealsElementResolve} from './element/appeals-element.resolve';

import {getBackrefAppeals} from './shared/backrefs.factory';
import {FlatInfoStandaloneModule} from '../../shared/housing/shared/flat-info-standalone';
import {ScrollableHostModule} from '../../shared/housing/shared/scrollable-host/scrollable-host.module';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // ~
    AppealsRoutingModule,
    // ~
    SharedModule,
    MaterialInputModule,
    DataTableModule,
  ],
  declarations: [
    AppealsListComponent,
    AppealsElementComponent,
  ],
  providers: [
    AppealsElementResolve,
    {provide: 'backref_appeals', useValue: getBackrefAppeals}
  ]
})
export class AppealsModule { }
