import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {HttpClient} from "../../services/http.service";
import {TransferServices} from "./transfer.services";
import {NotificationService} from "../../services/notification.service";
import {GoBackButtonService} from "../../services/go-back-button.service";

@Component({
  selector: 'transfer',
  templateUrl: './transfer-entity.component.html',
  styleUrls: ['./transfer-entity.component.css']
})
export class TransferEntityComponent implements OnInit {

  public form: UntypedFormGroup;
  public physicals: any = null;
  public physicals_user_select: any = null;

  constructor(private activeRoute: ActivatedRoute,
              private http: HttpClient,
              private s: TransferServices,
              private router: Router,
              private fb: UntypedFormBuilder,
              private notify: NotificationService,
              public goBack: GoBackButtonService) {

  }

  ngOnInit() {

    this.goBack.header= '<h3>Перенос сотрудника между субагентами</h3>';
    this.physicals = this.activeRoute.snapshot.data['physicals'];
    this.form = this.fb.group({
      'new_subagent_id': [null, Validators.required],
      'new_subagent_caption': [null, Validators.required],
      'user_id': [null, Validators.required],
      'user_fio': [null, Validators.required],
      'user_transfer_id': [null, Validators.required],
      'user_transfer_fio': [null, Validators.required],
    });

  }

  transferUser() {

    this.s.transferUserSubagent(+this.form.value.user_id,
      +this.form.value.user_transfer_id,
      +this.form.value.new_subagent_id).then(
      (response) => {
        this.notify.notifySuccess('OK', 'Пользователь успешно перенесён', 2000);
        this.router.navigate(['entity', 'edit',
          this.activeRoute.snapshot.params['id'], 'physical'])
      },
      (error) => { }
    );
  }

  selectUser($event: any) {
    this.physicals_user_select = this.physicals.filter(x => x.organisation_owner_id === +$event).pop();
  }
}
