import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '../../../../services/http.service';
import { NotificationService } from '../../../../services/notification.service';
import { debounceTime } from 'rxjs/operators';
import {PartRatePipe} from '../../../../pipes/part-rate.pipe';
import {Subscription} from 'rxjs';

@Component({
  templateUrl: './import-edit-commerce.component.html',
  styleUrls: ['./import-edit-commerce.component.scss']
})
export class ImportEditCommerceComponent implements OnInit, OnDestroy {

  public feed_id: any;
  public data: any;
  public report: any;
  public formGroup: UntypedFormGroup;
  public loading = false;
  public complexes_id = [];
  public settings_id = [];
  private capabilitySubscription: Subscription;
  constructor(public http: HttpClient, public fb: UntypedFormBuilder,
              public notify: NotificationService, public router: Router, public activatedRoute: ActivatedRoute,
              private partRate: PartRatePipe) {

    this.feed_id = activatedRoute.snapshot.parent.params['id'];
    this.data = activatedRoute.snapshot.data['data'];
    this.complexes_id = this.data['complexes'].map((item) => item['id']);
    this.settings_id = this.data['settings'].map((item) => item['id']);
    const disable = !this.data['default']['is_active'];
    this.formGroup = this.fb.group({
      'default': this.fb.group({
        'is_active': [this.data['default']['is_active']],
        'voznagr_agenta': [{value: this.data['default']['voznagr_agenta'], disabled: disable}],
        'voznagr_agenta_from_ddu': [{value: this.data['default']['voznagr_agenta_from_ddu'], disabled: disable}],
        'dolya_subagenta_db': [{value: (this.data['default']['dolya_subagenta_db'] * this.data['default']['voznagr_agenta']).toFixed(5), disabled: disable}],
        'dolya_subagenta_agd': [{value: (this.data['default']['dolya_subagenta_agd'] * this.data['default']['voznagr_agenta_from_ddu']).toFixed(5), disabled: disable}],
        'srok_realisation': [{value: this.data['default']['srok_realisation'], disabled: disable}],
        'unique_term_days': [{value: this.data['default']['unique_term_days'], disabled: disable}],
        'lead_transfer_capability': [{value: this.data['default']['lead_transfer_capability'], disabled: disable}],
        'lead_transfer_sub_percent_db': [{value: this.data['default']['lead_transfer_sub_percent_db'], disabled: disable}],
        'lead_transfer_sub_percent_agd': [{value: this.data['default']['lead_transfer_sub_percent_agd'], disabled: disable}],
      }),
      'complexes': this.fb.array(this.data['complexes'].map((item) => {
        return this.fb.group({
          'id': item['id'],
          'realty_id': item['realty_id'],
          'caption': item['caption'],
          'is_checked': item['is_checked'],
        });
      })),
      'settings': this.fb.array(this.data['settings'].map((item) => {
        const disable = !item['is_active'];
        return this.fb.group({
          'id': item['id'],
          'caption': item['caption'],
          'is_active': item['is_active'],
          'voznagr_agenta': [{value: item['voznagr_agenta'], disabled: disable}],
          'voznagr_agenta_from_ddu': [{value: item['voznagr_agenta_from_ddu'], disabled: disable}],
          'dolya_subagenta_db': [{value: (item['dolya_subagenta_db'] * item['voznagr_agenta']).toFixed(5), disabled: disable}],
          'dolya_subagenta_agd': [{value: (item['dolya_subagenta_agd'] * item['voznagr_agenta_from_ddu']).toFixed(5), disabled: disable}],
          'srok_realisation': [{value: item['srok_realisation'], disabled: disable}],
          'unique_term_days': [{value: item['unique_term_days'], disabled: disable}],
          'lead_transfer_capability': [{value: item['lead_transfer_capability'], disabled: disable}],
          'lead_transfer_sub_percent_db': [{value: item['lead_transfer_sub_percent_db'], disabled: disable}],
          'lead_transfer_sub_percent_agd': [{value: item['lead_transfer_sub_percent_agd'], disabled: disable}],
        });
      }))
    });

    this.defaults.get('is_active').valueChanges.pipe(debounceTime(100))
      .subscribe((data) => {
        this.setDisabled(this.defaults, data);
      });
    this.settings.controls.forEach(item => {
      item.get('is_active').valueChanges.subscribe((data) => {
        this.setDisabled(item, data);
      });
    });
    this.complexes.valueChanges.pipe(debounceTime(100))
      .subscribe((data) => {
        data.forEach((item) => {
          const index = this.settings_id.indexOf(item.id);
          if (item.is_checked && index !== -1) {
            this.settings_id.splice(index, 1);
            this.settings.removeAt(index);
          }
          if (!item.is_checked && index === -1) {
            this.settings_id.push(item.id);
            this.appendSettings(item);
          }
        });
      });
  }

  leadValidators(value) {
    if (!value) {
      this.formGroup.get('default').get('lead_transfer_sub_percent_db').patchValue(null);
      this.formGroup.get('default').get('lead_transfer_sub_percent_agd').patchValue(null);
    }
  }

  ngOnInit() {
    this.leadValidators(this.formGroup.get('default').get('lead_transfer_capability').value);
    this.capabilitySubscription = this.formGroup.get('default').get('lead_transfer_capability').valueChanges
      .subscribe(value => {
        this.leadValidators(value);
      });
  }

  setDisabled(form, is_active) {
    if (is_active) {
      form.get('voznagr_agenta').enable();
      form.get('voznagr_agenta_from_ddu').enable();
      form.get('dolya_subagenta_db').enable();
      form.get('dolya_subagenta_agd').enable();
      form.get('srok_realisation').enable();
      form.get('unique_term_days').enable();
      form.get('lead_transfer_capability').enable();
      form.get('lead_transfer_sub_percent_db').enable();
      form.get('lead_transfer_sub_percent_agd').enable();
    } else {
      form.get('voznagr_agenta').disable();
      form.get('voznagr_agenta_from_ddu').disable();
      form.get('dolya_subagenta_db').disable();
      form.get('dolya_subagenta_agd').disable();
      form.get('srok_realisation').disable();
      form.get('unique_term_days').disable();
      form.get('lead_transfer_capability').disable();
      form.get('lead_transfer_sub_percent_db').disable();
      form.get('lead_transfer_sub_percent_agd').disable();
    }
  }

  public get complexes(): UntypedFormArray {
    return this.formGroup.get('complexes') as UntypedFormArray;
  }

  public get settings(): UntypedFormArray {
    return this.formGroup.get('settings') as UntypedFormArray;
  }

  public get defaults(): UntypedFormGroup {
    return this.formGroup.get('default') as UntypedFormGroup;
  }

  appendSettings(data) {
    const disable = !this.defaults.value.is_active;
    const form = this.fb.group({
      'id': data.id,
      'caption': data.caption,
      'is_active': this.defaults.value.is_active,
      'voznagr_agenta': [{value: this.defaults.value.voznagr_agenta || null, disabled: disable}],
      'voznagr_agenta_from_ddu': [{value: this.defaults.value.voznagr_agenta_from_ddu || null, disabled: disable}],
      'dolya_subagenta_db': [{value: this.defaults.value.dolya_subagenta_db || null, disabled: disable}],
      'dolya_subagenta_agd': [{value: this.defaults.value.dolya_subagenta_agd || null, disabled: disable}],
      'srok_realisation': [{value: this.defaults.value.srok_realisation || null, disabled: disable}],
      'unique_term_days': [{value: this.defaults.value['unique_term_days'] || null, disabled: disable}],
      'lead_transfer_capability': [{value: this.defaults.value['lead_transfer_capability'] || null, disabled: disable}],
      'lead_transfer_sub_percent_db': [{value: this.defaults.value['lead_transfer_sub_percent_db'] || null, disabled: disable}],
      'lead_transfer_sub_percent_agd': [{value: this.defaults.value['lead_transfer_sub_percent_agd'] || null, disabled: disable}],
    });
    this.settings.push(form);
    form.get('is_active').valueChanges.subscribe((data) => {
      this.setDisabled(form, data);
    });
  }

  saveForm() {
    if (!this.formGroup.valid) {
      this.onError('Заполните обязательные поля');
      return false;
    }
    this.loading = true;

    const settingObj = [];
    for (const el of  this.formGroup.get('settings').value) {
      settingObj.push(Object.assign({}, el));
    }

    const data = {
      'setting_id': this.feed_id,
      'data': {
        'default': Object.assign({}, this.formGroup.get('default').value),
        'complexes': Object.assign({}, this.formGroup.get('complexes').value),
        'settings': settingObj,
      }
    };


    let wrongPercent = true;
    if (
      parseFloat(data['lead_transfer_sub_percent_db']) > (parseFloat(data['dolya_subagenta_db']) ? parseFloat(data['dolya_subagenta_db']) : 0) ||
      parseFloat(data['lead_transfer_sub_percent_agd']) > (parseFloat(data['dolya_subagenta_agd']) ? parseFloat(data['dolya_subagenta_agd']) : 0)
    ) {
      wrongPercent = false;
    }

    data['data']['default']['dolya_subagenta_db'] = this.partRate.transform(data['data']['default']['voznagr_agenta'], data['data']['default']['dolya_subagenta_db']);
    data['data']['default']['dolya_subagenta_agd'] = this.partRate.transform(data['data']['default']['voznagr_agenta_from_ddu'], data['data']['default']['dolya_subagenta_agd']);

    if (wrongPercent) {
      for (let i = 0; i < this.settings.controls.length; i++) {

        if (
          parseFloat(data['data']['settings'][i]['lead_transfer_sub_percent_db']) > (parseFloat(data['data']['settings'][i]['dolya_subagenta_db']) ? parseFloat(data['data']['settings'][i]['dolya_subagenta_db']) : 0) ||
          parseFloat(data['data']['settings'][i]['lead_transfer_sub_percent_agd']) > (parseFloat(data['data']['settings'][i]['dolya_subagenta_agd']) ? parseFloat(data['data']['settings'][i]['dolya_subagenta_agd']) : 0)
        ) {
          wrongPercent = false;
          break;
        }

        data['data']['settings'][i]['dolya_subagenta_db'] = this.partRate.transform(data['data']['settings'][i]['voznagr_agenta'], data['data']['settings'][i]['dolya_subagenta_db']);
        data['data']['settings'][i]['dolya_subagenta_agd'] = this.partRate.transform(data['data']['settings'][i]['voznagr_agenta_from_ddu'], data['data']['settings'][i]['dolya_subagenta_agd']);

        if (data['data']['settings'][i]['dolya_subagenta_db'] > 1 || data['data']['settings'][i]['dolya_subagenta_agd'] > 1) {
          wrongPercent = false;
          break;
        }
      }
    }

    if (data['data']['default']['dolya_subagenta_db'] > 1 || data['data']['default']['dolya_subagenta_agd'] > 1 || !wrongPercent) {
      this.notify.notifyError('Ошибка', 'Неверный процент субагента', 3000);
      this.loading = false;
    } else {
      this.http.post('Import.realization_commerce_update', data).subscribe(
        (response) => {
          this.onSuccess(response);
          this.loading = false;
        },
        (error) => {
          this.onError(error);
          this.loading = false;
        }
      );
    }


  }
  ngOnDestroy() {
    if (this.capabilitySubscription) {
      this.capabilitySubscription.unsubscribe();
    }
  }
  onSuccess(response?) {
    this.notify.notifySuccess('Поздравляем', 'Настройки успешно сохранена', 3000);
  }

  onError(error) {
    this.notify.notifyError('Ошибка', error, 5000);
  }
}
