<div class="material-datatable-wrapper">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)"
    [templateRight]="buttonCreate">
  </app-datatable-headline>
  <div class="table-responsive">
    <table class="table table-striped datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>

<ng-template #buttonCreate>
  <div class="text-right">
    <a class="button" routerLink="/import/create">Новый фид...</a>
  </div>
</ng-template>

<ng-template #buttonTmpl let-row="row">
  <a class="button button_icon" [routerLink]="['/import/edit', row.id]">
    <i class="icon icon_Small_Edit"></i>
  </a>
</ng-template>

<ng-template #captionTmpl let-row="row">
  <ng-container *ngIf="row.is_correct === 0">
    <i class="mr-1 text-danger fa fa-fw fa-exclamation-triangle"></i>
  </ng-container>
  <span [class.text-danger]="row.is_correct === 0">{{row.caption}}</span>
</ng-template>
