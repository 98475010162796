import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SimpleTableComponent} from './simple-table.component';
import {SimpleTableHeaderComponent} from './simple-table-header.component';
import {SimpleTableRowComponent} from './simple-table-row.component';
import {SimpleTablePaginationComponent} from './simple-table-pagination.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    SimpleTableComponent,
    SimpleTableHeaderComponent,
    SimpleTableRowComponent,
    SimpleTablePaginationComponent,
  ],
  exports: [
    SimpleTableComponent,
    SimpleTableHeaderComponent,
    SimpleTableRowComponent,
  ]
})
export class SimpleTableModule { }
