<p class="mb-2">Загрузите csv файл с доступами от Инстатеки: </p>

<button *ngIf="!load" class="button" (click)="input.click()">Загрузить файл импорта</button>
<button *ngIf="load" class="button button_disabled" type="button">
  <i class="fa fa-spinner fa-spin mr-1"></i>
          Подождите, идёт обработка файла
</button>

<div [hidden]="true" class="hidden">
  <input #input type="file" (change)="onFileChange($event)">
</div>

<ng-container *ngIf="!load">
<div class="row mt-4 mb-2" [formGroup]="form">
  <div class="col-6">
    Всего: <b>{{count}}</b>
  </div>
</div>
<div class="material-datatable-wrapper">
  <div class="table-responsive">
    <table class="table">
      <tr>
        <th *ngFor="let el of head" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
          <div [formGroup]="form">
            <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
              <div class="col table__input">
                <ng-container *ngIf="el.input">
                  <app-material-input
                    [placeholder] = "el.caption"
                    [formControlName] = "el.name"
                    [cssClass]="'mb-0'">
                  </app-material-input>
                </ng-container>
                <ng-container *ngIf="el.select">
                  <app-material-select
                    [formControlName]="el.name"
                    [cssClass]="'mb-0'">
                    <option *ngFor="let opt of el.select" [value]="opt.id">{{opt.caption}}</option>
                  </app-material-select>
                </ng-container>
                <ng-container *ngIf="!el.input && !el.select">
                  {{el.caption}}
                </ng-container>
              </div>
              <div class="col-auto" *ngIf="el.sort">
                <a href="javascript:void(0)" (click)="sortChange(el.name)">
                  <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                      'icon_new-sort-down': 'asc' == sort[el.name],
                                      'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                </a>
              </div>
            </div>
          </div>
        </th>
      </tr>
      <!-- // -->
      <tr *ngFor="let row of rows; let row_index = index">
        <td>
          <a [routerLink]="['/physical/edit/', row['physical_id'], 'common']">{{row['fio']}}</a>
        </td>
        <td>
          <a [routerLink]="['/entity/edit/', row['organisation_id'], 'common']">{{row['subagent_caption']}}</a>
        </td>
        <td>
          {{row['instateka_account']}}
        </td>
      </tr>
    </table>
  </div>
</div>

<div class="alt-pagination">
  <app-paginator
    [queryParams]="filter_columns"
    [itemsPerPage]="50"
    [itemCount]="count"
    [currentPage]="current_page"
    [baseUrl]="'/instateka/users'">
  </app-paginator>
</div>
</ng-container>
