import {Component, EventEmitter, Input, Output} from '@angular/core';
import {JsonClientService} from '../json-client';

@Component({
  selector: 'app-right-window',
  templateUrl: './right-window.component.html',
  styleUrls: ['./right-window.component.scss']
})

export class RightWindowComponent {

  public layout_opened = false;

  @Input() public cssClass = '';

  constructor(public http: JsonClientService) {}

  @Input() private set data(value) {
    if (value) {
      this.loadData(value);
    } else {
      this.layout_opened = false;
    }
  }

  @Output() public onClosedRight: EventEmitter<void> = new EventEmitter<void>();

  private loadData(value) {
    this.layout_opened = true;
  }

  closeEvent() {
    this.onClosedRight.next();
    this.layout_opened = false;
  }
}
