import {Component, OnInit} from '@angular/core';
import {GoBackButtonService} from '../../../services/go-back-button.service';
import {BreadcrumbsService} from '../../../shared/breadcrumbs/services';
import {FancyboxService} from '../../../shared/fancybox.service';


@Component({
  templateUrl: './regl-help.component.html',
  styleUrls: [
    './regl-help.component.scss'
  ]
})
export class MassHelpComponent implements OnInit {
  public images = [
    {'src': '/assets_crm/images/regl-help/developer_searce.jpg',
      'thumb': '/assets_crm/images/regl-help/developer_searce.jpg', 'caption': ''},
    {'src': '/assets_crm/images/regl-help/developer_searce_2.png',
      'thumb': '/assets_crm/images/regl-help/developer_searce_2.png', 'caption': ''},
    {'src': '/assets_crm/images/regl-help/realty_select.jpg',
      'thumb': '/assets_crm/images/regl-help/realty_select.jpg', 'caption': ''},
    {'src': '/assets_crm/images/regl-help/add_reglament.jpg',
      'thumb': '/assets_crm/images/regl-help/add_reglament.jpg', 'caption': ''},
    {'src': '/assets_crm/images/regl-help/add_acts.png',
      'thumb': '/assets_crm/images/regl-help/add_acts.png', 'caption': ''},
    {'src': '/assets_crm/images/regl-help/add_regl_param.png',
      'thumb': '/assets_crm/images/regl-help/add_regl_param.png', 'caption': ''},
    {'src': '/assets_crm/images/regl-help/add_acts_param.png',
      'thumb': '/assets_crm/images/regl-help/add_acts_param.png', 'caption': ''},
  ];

  constructor(private goBack: GoBackButtonService, private b: BreadcrumbsService, private lightbox: FancyboxService) { }

  ngOnInit() {
    this.goBack.header = '<h1>Регламенты и акты </h1>';
    this.b.set([
      {url: '/', caption: 'Главная'},
      {caption: 'Массовое занесение'},
    ]);
  }
  displayImage(image) {
    const index = this.images.findIndex(x => x['src'] === image['src']);
    this.lightbox.open(this.images, index);
  }

}
