import {Component, ViewChild, OnInit, OnDestroy, TemplateRef, Input, AfterViewInit} from '@angular/core';
import { HttpClient } from '../../services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataTableParamComponent } from '../../modules/@datatable/components/datatable-param.component';
import { debounceTime } from 'rxjs/operators';
import {BackrefWindowService} from '../single-window/shared/backref-window.service';
import {GoBackButtonService} from '../../services/go-back-button.service';


@Component({
  selector: 'app-unique',
  templateUrl: './unique.component.html',
  styleUrls: [
    './reserv-global.component.scss',
  ]
})
export class UniqueListComponent extends DataTableParamComponent implements OnInit, OnDestroy, AfterViewInit {

  public api_action = 'Data_tables.get_unique_check';
  public columns: Array<{ [key: string]: any }>;
  public formGroup: UntypedFormGroup;
  public subscription: Subscription;
  public data: any;
  private firstLoad = true;

  @ViewChild('subagent_physical_fioTmpl', {static: true}) public subagent_physical_fioTmpl: TemplateRef<any>;
  @ViewChild('subagent_physical_fioHead', {static: true}) public subagent_physical_fioHead: TemplateRef<any>;
  @ViewChild('subagent_physical_phoneHead', {static: true}) public subagent_physical_phoneHead: TemplateRef<any>;
  @ViewChild('lead_physical_fioTmpl', {static: true}) public lead_physical_fioTmpl: TemplateRef<any>;
  @ViewChild('lead_physical_fioHead', {static: true}) public lead_physical_fioHead: TemplateRef<any>;
  @ViewChild('lead_physical_phoneHead', {static: true}) public lead_physical_phoneHead: TemplateRef<any>;
  @ViewChild('object_captionTmpl', {static: true}) public object_captionTmpl: TemplateRef<any>;
  @ViewChild('object_captionHead', {static: true}) public object_captionHead: TemplateRef<any>;
  @ViewChild('manager_fioTmpl', {static: true}) public manager_fioTmpl: TemplateRef<any>;
  @ViewChild('manager_fioHead', {static: true}) public manager_fioHead: TemplateRef<any>;

  @ViewChild('subagent_organisation_captionTmpl', {static: true}) public subagent_organisation_captionTmpl: TemplateRef<any>;
  @ViewChild('subagent_organisation_captionHead', {static: true}) public subagent_organisation_captionHead: TemplateRef<any>;
  @ViewChild('stage_captionTmpl', {static: true}) private stage_captionTmpl: TemplateRef<any>;
  @ViewChild('stage_captionHead', {static: true}) private stage_captionHead: TemplateRef<any>;


  constructor(public http: HttpClient, public route: ActivatedRoute,
              private backrefWindowService: BackrefWindowService,
              public fb: UntypedFormBuilder, router: Router, public goBack: GoBackButtonService) {
    super(http, route, router);
    this.formGroup = this.fb.group({
      'date_start': [''],
      'date_end': [''],
      'date_change_start': [''],
      'date_change_end': [''],
      'date_created': [''],
      'date_unique_until': [''],
      'stage_caption': [''],
      'subagent_organisation_caption': [''],
      'subagent_organisation_id': [''],
      'subagent_physical_id': [''],
      'subagent_physical_fio': [''],
      'subagent_physical_phone': [''],
      'lead_id': [''],
      'lead_physical_fio': [''],
      'lead_physical_phone': [''],
      'object_caption': [''],
      'housing_id': [''],
      'flat_id': [''],
      'manager_id': [''],
      'manager_fio': [''],
      'manager_phone': [''],
      'unique_status': [''],
      'tab': [''],
    });
    this.subscription = this.formGroup.valueChanges.pipe(debounceTime(500))
      .subscribe((data) => {
        this.router_param['page'] = 1;
        this.current_page = 1;
        if (this.firstLoad) {
          this.firstLoad = false;
        } else {
          this.changeForm(this.formGroup);
        }
      });
    this.updateForm(this.formGroup);
    this.backrefWindowService.routerInit();
  }

  ngOnInit() {
    this.goBack.header = '<h1>Уникальность</h1>';
    this.data = this.route.snapshot.data['data'];
    this.columns = [
      {'name': 'date_created', 'caption': 'Дата проверки', 'sortable': true, 'width': '120'},
      {'name': 'date_unique_until', 'caption': ' Уникален до', 'sortable': true, 'width': '120'},
      {'name': 'subagent_organisation_caption', 'caption': 'Организация', 'sortable': true, 'width': '200',
        'template': this.subagent_organisation_captionTmpl, 'templateHead': this.subagent_organisation_captionHead},
      {'name': 'subagent_physical_fio', 'caption': 'Субагент', 'sortable': true, 'width': '120',
        'template': this.subagent_physical_fioTmpl, 'templateHead': this.subagent_physical_fioHead},
      {'name': 'subagent_physical_phone', 'caption': 'Тел. субагента', 'sortable': true,
        'templateHead': this.subagent_physical_phoneHead},
      {'name': 'lead_physical_fio', 'caption': 'Клиент', 'sortable': true,
        'template': this.lead_physical_fioTmpl, 'templateHead': this.lead_physical_fioHead},
      {'name': 'lead_physical_phone', 'caption': 'Тел. клиента', 'sortable': true, 'templateHead': this.lead_physical_phoneHead},
      {'name': 'object_caption', 'caption': 'Объект', 'sortable': true,
        'template': this.object_captionTmpl, 'templateHead': this.object_captionHead},
      {'name': 'manager_fio', 'caption': 'Ответственный', 'sortable': true,
        'template': this.manager_fioTmpl, 'templateHead': this.manager_fioHead},
      {'name': 'unique_status', 'caption': 'Уникальность', 'sortable': true},
      {'caption': 'Этап', 'name': 'stage_caption', 'sortable': true, 'template': this.stage_captionTmpl,  'templateHead': this.stage_captionHead},
    ];
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  ngAfterViewInit() {
    this.goBack.goScroll();
  }
  prepareQuery() {
    const result = super.prepareQuery();
    result['filter']['fields'] = {};
    result['filter']['fields'] = {
      'date_start': this.formGroup.value.date_start,
      'date_end': this.formGroup.value.date_end,
      'date_created': this.formGroup.value.date_created,
      'date_unique_until': this.formGroup.value.date_unique_until,
      'date_change_start': this.formGroup.value.date_change_start,
      'date_change_end': this.formGroup.value.date_change_end,
      'stage_caption': this.formGroup.value.stage_caption,
      'subagent_organisation_caption': this.formGroup.value.subagent_organisation_caption,
      'subagent_physical_fio': this.formGroup.value.subagent_physical_fio,
      'subagent_physical_phone': this.formGroup.value.subagent_physical_phone,
      'lead_physical_fio': this.formGroup.value.lead_physical_fio,
      'lead_physical_phone': this.formGroup.value.lead_physical_phone,
      'object_caption': this.formGroup.value.object_caption,
      'manager_id': this.formGroup.value.manager_id,
      'manager_fio': this.formGroup.value.manager_fio,
      'manager_phone': this.formGroup.value.manager_phone,
      'unique_status': this.formGroup.value.unique_status,
      'tab': this.formGroup.value.tab,
    };
    return result;
  }

  filter_state(string) {
    if (this.formGroup.value.tab === string) {
      string = '';
    }
    this.formGroup.patchValue({
      'tab': string,
    });
  }
}
