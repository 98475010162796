import {AfterContentInit, Component, ContentChildren, Input, OnDestroy, QueryList} from '@angular/core';
import {AccordionElementComponent} from './accordion-element.component';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion-wrapper-component.html',
  styleUrls: ['./accordion-wrapper-component.scss']
})
export class AccordionWrapperComponent implements AfterContentInit, OnDestroy {

  @Input() private singleActive: boolean;
  @Input() private openFirst = true;
  @Input() private openAll = false;

  @ContentChildren(AccordionElementComponent) private elements: QueryList<AccordionElementComponent>;

  private subscriptions: Subscription = new Subscription();

  ngAfterContentInit() {
    const active_length = this.elements.filter(x => x.is_active).length;
    if (!active_length && (this.openFirst || this.openAll)) {
      setTimeout(() => {
        if (this.openAll) {
          this.elements.forEach(element => element.is_active = true);
        } else if (this.openFirst) {
          this.elements.first.is_active = true;
        }
      }, 10);
    }

    this.elements.forEach((element) => {
      this.subscriptions.add(element.activate.subscribe(event => this.onAccordionChange(element)));
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onAccordionChange(element: AccordionElementComponent) {
    if (this.singleActive) {
      this.elements.filter(x => x !== element).forEach(x => x.set_inactive());
    }
  }

}
