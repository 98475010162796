import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { HttpClient } from '../../../services/http.service';
import { Subscription } from 'rxjs';
import { MaterialSelect2Service } from './material-select2.service';
import {BehaviorSubject} from 'rxjs';

declare const jQuery: any;

export interface IObj {
  value: string;
  description: string;
  complex_not_in_overall_unique_is: boolean | null;
  developer_caption: string | null;
  developer_complexes_in_unique: any[] | null;
  developer_id: number | null;
  developer_overall_unique_is: boolean | null;
}


@Directive({
  selector: '[appMaterialSelectAutocomplete]',
})
export class MaterialSelect2AutocompleteDirective implements AfterViewInit, OnDestroy {

  private subscription: Subscription;

  @Input() public appMaterialSelectAutocomplete = {};
  @Input() public appMaterialSelectAutocompleteSendSelected = false;
  @Input() public apiUrl = '';
  @Input() public api_options = {};
  @Input() public focusOnInit = false;
  @Input() public preSelectedValues = {};
  @Input() public selectedValues: BehaviorSubject<{ [key: string]: IObj; }>;
  @Output() public select2_change: EventEmitter<any> = new EventEmitter();

  constructor(private elementRef: ElementRef, private http: HttpClient,
              public select2Service: MaterialSelect2Service) {
    this.select2Service.onClear.subscribe(_ => {
      jQuery(this.elementRef.nativeElement).val('').trigger('change');
    });
  }

  ngAfterViewInit() {
    this.appMaterialSelectAutocomplete = Object.assign(this.appMaterialSelectAutocomplete, {
      minimumInputLength: 2,
      ajax: {
        delay: 350,
        transport: (params, success, failure) => {
          if (params.data.term) {
            this.subscription = this.http.post(this.apiUrl, Object.assign(this.api_options, {
              'value': params.data.term,
              'limit': 10
            }, this.appMaterialSelectAutocompleteSendSelected ? {
              selected: Object.keys(this.selectedValues.getValue()),
            } : {})).subscribe(
              (response) => {
                const data = response.map(obj => ({
                    'id': obj.value,
                    'text': obj.description,
                    'obj': obj
                  })
                );
                success(data);
              },
              (error) => failure(false)
            );
          }
        },
        processResults: function (data) {
          return {
            results: data
          };
        },
      },
      language: {
        errorLoading: function () {
          return 'Результат не может быть загружен.';
        },
        inputTooLong: function (args) {
          const overChars = args.input.length - args.maximum;
          let message = 'Пожалуйста, удалите ' + overChars + ' символ';
          if (overChars >= 2 && overChars <= 4) {
            message += 'а';
          } else if (overChars >= 5) {
            message += 'ов';
          }
          return message;
        },
        inputTooShort: function (args) {
          const remainingChars = args.minimum - args.input.length;
          const message = 'Пожалуйста, введите ' + remainingChars + ' или более символов';
          return message;
        },
        loadingMore: function () {
          return 'Загружаем ещё ресурсы…';
        },
        maximumSelected: function (args) {
          let message = 'Вы можете выбрать ' + args.maximum + ' элемент';
          if (args.maximum >= 2 && args.maximum <= 4) {
            message += 'а';
          } else if (args.maximum >= 5) {
            message += 'ов';
          }
          return message;
        },
        noResults: function () {
          return 'Ничего не найдено';
        },
        searching: function () {
          return 'Поиск…';
        }
      },
      // templateResult: function (data, container) {
      //   console.log(data);
      //   console.log(container);
      //   // if (data.element) {
      //   //   $(container).addClass($(data.element).attr("class"));
      //   // }
      //   return data.text;
      // },
      templateSelection: function (data, container) {
        if (data.element && data.element.classList.contains('exists-option')) {
           container[0].classList.add('exists-option');
        }
        return data.text;
      },
    });
    setTimeout(() => {
      this.select2Change();
    }, 1);
  }

  select2Change() {
    const $selElem = jQuery(this.elementRef.nativeElement).select2(this.appMaterialSelectAutocomplete)
        .on('change', () => {
            const newSelectedValues = {},
              selectedValues = this.selectedValues.getValue();
            let text = null, obj = null;
            jQuery(this.elementRef.nativeElement).find(':selected').each((_, element) => {
              const data = jQuery(element).data('data');
              newSelectedValues[data.id] = data.obj || selectedValues[data.id] || this.preSelectedValues[data.id] || null;
              if (!selectedValues[data.id]) {
                text = data.text;
                obj = newSelectedValues[data.id];
              }
            });
            this.selectedValues.next(newSelectedValues);
            this.select2_change.emit({
              'val': jQuery(this.elementRef.nativeElement).val(),
              'text': text,
              'obj': obj,
            });
          }
        ).on('mouseup', () => {
          return false;
        });
    (function ($select2Obj) {
      $select2Obj
        .parent()
        .find('.select2-selection')
        .on('keydown', '.select2-search--inline', function (evt) {
          const backspaceKey = 8;
          if (evt.which === backspaceKey) {
            const currentSelection = $select2Obj.select2('data').map(function (s) {
              return s.id;
            });
            $select2Obj.val(currentSelection).trigger('change');
            $select2Obj.select2('close');
          }
        });
    })($selElem);
    if (this.focusOnInit) {
      $selElem.select2('open');
    }
  }

  ngOnDestroy() {
    try {
      jQuery(this.elementRef.nativeElement).select2('destroy');
    } catch (e) {
      return;
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
