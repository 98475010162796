import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MaterialInputModule} from '../../modules/@material-input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PaginatorModule} from '../housing-modules/modules';
import {ComplaintsDatatableComponent} from './complaints-datatable.component';
import {
  CauseDatatableResolve, ComplaintsCrmPartFalse, ComplaintsCrmPartTrue,
  ComplaintsDatatableResolve, ComplaintsDatatableSubagentResolve,
} from './complaints-datatable.resolve';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialInputModule,
    FormsModule,
    PaginatorModule,
    ReactiveFormsModule,
  ],
  declarations: [
    ComplaintsDatatableComponent
  ],
  providers: [
    ComplaintsDatatableResolve,
    ComplaintsDatatableSubagentResolve,
    CauseDatatableResolve,
    ComplaintsCrmPartTrue,
    ComplaintsCrmPartFalse
  ]

})
export class ComplaintsDatatableModule {

}
