<div id="data-table-selection-footer" *ngIf="pagesCount > 1" class="bootgrid-footer container-fluid">
  <div class="row">
    <div class="col-md-12">
      <ul class="pagination">
        <li *ngFor="let page of pages"  class="paginate_button page-item" [ngClass]="{active: page == currentPage}">
          <a class="page-link button" href="javascript:void(0)" *ngIf="page !== '...'"
             (click)="setCurrentPage(page)">{{page + 1}}</a>
          <a  href="javascript:void(0)" class="page-link button disabled" *ngIf="page === '...'">
            <i class="fa fa-ellipsis-h"></i></a>
        </li>
      </ul>
    </div>
  </div>
</div>
