<aside class="notifications" [ngClass]="{'notifications_opened': opened}" (appClickOutside)="clickOutside()" [@slideLeft] *ngIf="opened">
  <ul class="notifications__list">
    <li class="notifications__element" *ngFor="let el of notificationsQueue.notifications">
      <span class="notifications__element__caption">{{el.subject}}</span>
      <span class="notifications__element__text">{{el.body}}</span>
      <a href="javascript:void(0)" (click)="removeNotification(el)"
         class="notifications__element__close icon icon_theme_crm icon_delete_sign"></a>
    </li>
  </ul>
</aside>
