<form class="form form_crm" [formGroup]="formGroup" novalidate>
  <div class="row no-gutters gutters-4 align-items-center" formGroupName="default">
    <div class="col-12 caption mb-2">Общие настройки для реализации коммерческой</div>
    <div class="col-4 pt-2">
      <app-material-input-checkbox formControlName="is_active">
        Принимать на реализацию
      </app-material-input-checkbox>
    </div>
    <div class="col-4">
      <app-material-input formControlName="voznagr_agenta" [placeholder]="'Вознаграждение агента (ДБ)'">
        Вознаграждение агента (ДБ)
      </app-material-input>
    </div>
    <div class="col-4">
      <app-material-input formControlName="dolya_subagenta_db" [placeholder]="'Процент субагента (ДБ)'">
        Процент субагента ДБ (Доля <span class="bold">{{ formGroup.get('default').get('voznagr_agenta').value | partRate : formGroup.get('default').get('dolya_subagenta_db').value : 'front'}}</span>)
      </app-material-input>
    </div>
    <div class="col-4">
      <app-material-input-datepicker formControlName="srok_realisation" [placeholder]="'Срок реализации'">
        Срок реализации
      </app-material-input-datepicker>
    </div>
    <div class="col-4">
      <app-material-input formControlName="voznagr_agenta_from_ddu" [placeholder]="'Вознаграждение агента (ДДУ)'">
        Вознаграждение агента (ДДУ)
      </app-material-input>
    </div>
    <div class="col-4">
      <app-material-input formControlName="dolya_subagenta_agd" [placeholder]="'Процент субагента (АГД)'">
        Процент субагента АГД (Доля <span class="bold">{{ formGroup.get('default').get('voznagr_agenta_from_ddu').value | partRate : formGroup.get('default').get('dolya_subagenta_agd').value : 'front'}}</span>)
      </app-material-input>
    </div>
    <div class="col-sm-12">
      <app-material-input formControlName="unique_term_days" [placeholder]="'Срок уникальности клиента'">
        Срок уникальности клиента
      </app-material-input>
    </div>
    <div class="col-sm-12">
      <app-material-input-checkbox formControlName="lead_transfer_capability">
        Возможность передачи лида субагентом
      </app-material-input-checkbox>
    </div>
    <div class="col-sm-12">
      <div [hidden]="!formGroup.get('default').get('lead_transfer_capability').value">
        <app-material-input formControlName="lead_transfer_sub_percent_db" [placeholder]="'Процент субагента ДБ при передаче лида'">
          Процент субагента ДБ при передаче лида
        </app-material-input>
        <app-material-input formControlName="lead_transfer_sub_percent_agd" [placeholder]="'Процент субагента АГД при передаче лида'">
          Процент субагента АГД при передаче лида
        </app-material-input>
      </div>
    </div>
  </div>
  <hr>

  <div class="row no-gutters gutters-4 align-items-center" formArrayName="complexes">
    <div class="col-12 caption mb-3">Использовать общие настройки для реализации коммерческой</div>
    <div class="col-12 mb-2 mt-2 text-sm" *ngIf="!complexes.controls.length">В данный момент комплексов нет</div>
    <div class="col-6" *ngFor="let complex of complexes.controls; let id = index" [formGroupName]="id">
      <app-material-input-checkbox formControlName="is_checked" [require]="true">
        {{complex.value.caption}}
      </app-material-input-checkbox>
    </div>
  </div>
  <div formArrayName="settings" class="end_block_padding">
    <div class="row no-gutters gutters-4 align-items-center">
      <div class="col-12" *ngFor="let setting of settings.controls; let id = index" [formGroupName]="id">
        <div class="row no-gutters gutters-2 panel bg-white mb-3 p-2 pt-3 pb-3 mr-0 ml-0">
          <div class="col-12 caption-sm mb-2 mt-0">{{setting.value.caption}}</div>
          <div class="col-4 pt-2">
            <app-material-input-checkbox formControlName="is_active">
              Принимать на реализацию
            </app-material-input-checkbox>
          </div>
          <div class="col-4">
            <app-material-input formControlName="voznagr_agenta" [placeholder]="'Вознаграждение агента (ДБ)'">
              Вознаграждение агента (ДБ)
            </app-material-input>
          </div>
          <div class="col-4">
            <app-material-input formControlName="dolya_subagenta_db" [placeholder]="'Процент субагента (ДБ)'">
              Процент субагента ДБ (Доля <span class="bold">{{ settings.controls[id].value['voznagr_agenta'] | partRate : settings.controls[id].value['dolya_subagenta_db'] : 'front'}}</span>)
            </app-material-input>
          </div>
          <div class="col-4">
            <app-material-input-datepicker formControlName="srok_realisation" [placeholder]="'Срок реализации'">
              Срок реализации
            </app-material-input-datepicker>
          </div>
          <div class="col-4">
            <app-material-input formControlName="voznagr_agenta_from_ddu" [placeholder]="'Вознаграждение агента (ДДУ)'">
              Вознаграждение агента (ДДУ)
            </app-material-input>
          </div>
          <div class="col-4">
            <app-material-input formControlName="dolya_subagenta_agd" [placeholder]="'Процент субагента (АГД)'">
              Процент субагента АГД (Доля <span class="bold">{{ settings.controls[id].value['voznagr_agenta_from_ddu'] | partRate : settings.controls[id].value['dolya_subagenta_agd'] : 'front'}}</span>)
            </app-material-input>
          </div>
          <div class="col-sm-12">
            <app-material-input formControlName="unique_term_days" [placeholder]="'Срок уникальности клиента'">
              Срок уникальности клиента
            </app-material-input>
          </div>
          <div class="col-sm-12">
            <app-material-input-checkbox formControlName="lead_transfer_capability">
              Возможность передачи лида субагентом
            </app-material-input-checkbox>
          </div>
          <div class="col-sm-12">
            <div [hidden]="!settings.controls[id].value['lead_transfer_capability']">
              <app-material-input formControlName="lead_transfer_sub_percent_db" [placeholder]="'Процент субагента ДБ при передаче лида'">
                Процент субагента ДБ при передаче лида
              </app-material-input>
              <app-material-input formControlName="lead_transfer_sub_percent_agd" [placeholder]="'Процент субагента АГД при передаче лида'">
                Процент субагента АГД при передаче лида
              </app-material-input>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="row no-gutters gutters-4 align-items-center mt-4 mb-4">
    <div class="col-6">
      <button (click)="saveForm()" type="button" [disabled]="loading"
              class="button fixed_buttons_block_main" [ngClass]="{'button_disabled': loading}">
        Сохранить
      </button>
    </div>
  </div>
</form>
