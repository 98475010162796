import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {JsonClientService} from '../../../shared/json-client';
import {Observable} from 'rxjs';

@Injectable()
export class InstatekaRequestsResolve {
  constructor(public jsonRpc: JsonClientService) {
  }

  private filter_columns =
    [
      'inst_id',
      'date_from',
      'date_to',
      'sa_caption',
      'author_fio',
      'fio',
      'value',
      'last_update',
      'status_name'
    ];

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {

    const prepared_filter = {};
    const prepared_filter_keys = this.filter_columns.filter(x => !!route.parent.queryParams[x]);
    const sort = {};

    prepared_filter_keys.forEach(x => prepared_filter[x] = route.parent.queryParams[x]);

    if (!!route.queryParams['sort_name']) {
      sort[route.queryParams['sort_name']] = route.queryParams['sort_key'] || 'asc';
    }
    const options = {
      'limit': 50,
      'offset': (+(route.queryParams['page'] || 1) - 1) * 50,
      '_filter': {
        'global_search_string': route.queryParams['global_search_string'] || null,
        'fields': prepared_filter,
      },
      'sort': sort
    };

    return this.jsonRpc.post('Data_tables.get_clients_of_mortgage_crm', options);
  }
}


@Injectable()
export class InstatekaRequestsStatisticsResolve {
  constructor(public jsonRpc: JsonClientService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    return this.jsonRpc.post('Mortgage.get_statistics_instateka');
  }
}
