import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '../../../services/http.service';
import { CalendarMapService } from './calendar-map.service';
import { Subscription } from 'rxjs';
import {OsmPlacemarkComponent} from '../../osm/osm-placemark/osm-placemark.component';
import {GoBackButtonService} from '../../../services/go-back-button.service';

@Component({
  selector: 'app-calendar-map',
  templateUrl: './calendar-map.component.html',
  styleUrls: ['./calendar-map.component.scss']
})
export class CalendarMapComponent implements OnInit, OnDestroy, AfterViewInit {

  public data = null;
  public realty_list = null;
  public loading = true;
  public minHeight = null;
  private subscription: Subscription;
  private mapSubscription: Subscription;

  @ViewChildren(OsmPlacemarkComponent) mapMarkerComponents: QueryList<OsmPlacemarkComponent>;

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, private goBack: GoBackButtonService,
              private cdr: ChangeDetectorRef, private calendarMapService: CalendarMapService) {
    this.subscription = this.http.post('Map.subagents_and_housings').subscribe(
      data => {
        this.data = data;
        this.realty_list = this.data['complexes'];
      },
      error => false
    );
    this.mapSubscription = this.calendarMapService.onMarkerMap.subscribe((value) => {
      this.mapMarkerComponents.forEach(item => {
        if (item.options['caption'] === value) {
          item.balloonOpen();
        }
      });
    }, (error) => false);
  }

  ngOnInit() {
    this.goBack.header = '<h1>Карта субагентов и комплексов</h1>';
  }

  ngAfterViewInit() {
    this.onHeight();
    this.cdr.detectChanges();
  }

  onHeight() {
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const offcet = document.getElementById('map-auto-height').offsetTop;
    this.minHeight = h - offcet;
  }

  clickMap() {

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.mapSubscription) {
    this.mapSubscription.unsubscribe();
    }
  }

  @HostListener('window:resize')
  private onResize() {
    this.onHeight();
  }

}
