import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';

import {UntypedFormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-mobile-sort',
  templateUrl: './mobile-sort.component.html',
  styleUrls: [
    './mobile-sort.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileSortComponent implements OnInit, OnDestroy {

  @Input('formGroup') public formGroup: UntypedFormGroup;
  @Input('caption') public caption: string;
  @Input('template') public template: string;
  @Input('sort_exist') public sort_exist = false;
  public close: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (!this.cd['destroyed']) {
      this.cd.detach();
    }
  }

  closeTrigger() {
    this.close.next(true);
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }
}
