import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient} from '../../../services/http.service';

@Injectable()
export class EntityAboutResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Organisation.get_organisation_about', {'organisation_id': +route.params['id']});
  }
}

@Injectable()
export class EntityCommonResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Organisation.get_organisation_common', {'organisation_id': +route.parent.params['id']});
  }
}

@Injectable()
export class EntityAdditionallyResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Organisation.get_organisation_additionally', {'organisation_id': +route.parent.params['id']});
  }
}

@Injectable()
export class EntityPhysicalResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Organisation.get_organisation_physical', {'organisation_id': +route.parent.params['id']});
  }
}

@Injectable()
export class EntityDocumentsResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Files.get_list_to_type', {'owner': ['organisation', +route.parent.params['id'], 1]});
  }
}

@Injectable()
export class EntityReglamentResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Files.get_list_to_type', {'owner': ['organisation', +route.parent.params['id'], 37]});
  }
}

@Injectable()
export class PhyToEntityResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Organisation.get_phy_to_organisation', {'organisation_id': +route.parent.params['id']});
  }
}
