import {Component, OnInit, Optional} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '../../../services/http.service';
import {environment} from '../../../../environments/environment';
import {UsageVariant} from '../../../models/usage_variants';
import {NotificationService} from '../../../services/notification.service';
import { TemplateService } from '../../../services/template.service';
import {GoBackButtonService} from '../../../services/go-back-button.service';

class Constraint {

  private _allowed_all;
  private _allowed_iframe;
  private _allowed_xml;

  public get allowed_all() {
    return this._allowed_all;
  }

  public set allowed_all(value) {
    this._allowed_all = value;

    if (!value) {
      if (this.allowed_rules['allowed_iframe_editable']) {
        this.allowed_iframe = false;
      }
      if (this.allowed_rules['allowed_xml_editable']) {
        this.allowed_xml = false;
      }
    }
  }

  public get allowed_iframe() {
    return this._allowed_iframe;
  }

  public set allowed_iframe(value) {
    this._allowed_iframe = value;

    if (value && !this.allowed_all) {
      this.allowed_all = true;
    }
  }

  public get allowed_xml() {
    return this._allowed_xml;
  }

  public set allowed_xml(value) {
    this._allowed_xml = value;

    if (value && !this.allowed_all) {
      this.allowed_all = true;
    }
  }

  constructor(private realty_id, private realty_caption, private manufacturer, private subway_caption, private comment,
              private allowed_rules, allowed_all, allowed_iframe, allowed_xml) {
    this.allowed_iframe = allowed_iframe;
    this.allowed_xml = allowed_xml;

    this.allowed_all = allowed_all;
  }

  public to_dict() {
    return {
      'realty_id': this.realty_id,
      'realty_caption': this.realty_caption,
      'manufacturer': this.manufacturer,
      'subway_caption': this.subway_caption,
      'comment': this.comment,
      'allowed_all': this.allowed_all,
      'allowed_iframe': this.allowed_iframe,
      'allowed_xml': this.allowed_xml,
      'allowed_all_editable': this.allowed_rules['allowed_all_editable'],
      'allowed_iframe_editable': this.allowed_rules['allowed_iframe_editable'],
      'allowed_xml_editable': this.allowed_rules['allowed_xml_editable'],
    };
  }
}

@Component({
  templateUrl: './settings-constraints-element.component.html',
  styleUrls: ['./settings-constraints-element.component.scss']
})
export class SettingsConstraintsElementComponent implements OnInit {

  private subagent_id;

  private list = [
    '',
    'allowed_all',
    '',
    'allowed_iframe',
    'allowed_xml'
  ];


  public resolved_subagent_id;
  public crmUsage = environment.usage === UsageVariant.CRM;

  public global_search;
  public allow_all = false;
  public allow_frame_only = false;
  public allow_xml_only = false;
  public header_info: Array<{[p: string]: any}>;

  private _data: Array<Constraint> = [];

  public subagent_name;

  public get data(): Array<Constraint> {
    return this._data;
  }

  public set data(value: Array<Constraint>) {
    this._data = value;
  }

  constructor(private activatedRoute: ActivatedRoute, private http: HttpClient, @Optional() private goBack: GoBackButtonService,
              private notify: NotificationService, public template: TemplateService) { }

  ngOnInit() {
    this.subagent_name = this.activatedRoute.snapshot.data['data']['common_data']['subagent_caption'];
    this.resolved_subagent_id = this.activatedRoute.snapshot.data['data']['subagent_id'];

    this.header_info = this.activatedRoute.snapshot.data['data']['common_data']['header_info'];
    this.data = this.activatedRoute.snapshot.data['data']['common_data']['realty'].map(element => {
      return new Constraint(
        element['realty_id'],
        element['realty_caption'],
        element['manufacturer'],
        element['subway_caption'],
        element['comment'],
        {
          'allowed_all_editable': element['allowed_all_editable'],
          'allowed_iframe_editable': element['allowed_iframe_editable'],
          'allowed_xml_editable': element['allowed_xml_editable'],
        },
        element['allowed_all'],
        element['allowed_iframe'],
        element['allowed_xml'],
      );
    });

    if (this.crmUsage) {
      this.subagent_id = this.activatedRoute.snapshot.params['subagent_id'];
      this.goBack.header = '<h1>Редактирование ограничений ' + (this.resolved_subagent_id ? this.subagent_name : '') + '</h1>';
    } else {
      this.template.setHeader('Редактирование ограничений');
      this.subagent_id = null;
    }
  }

  saveData() {
    let method = 'RealtyList.create_black_list';

    if (environment.usage === UsageVariant.CRM && !this.subagent_id) {
      method = 'RealtyList.create_global_black_list';
    }

    const options = {'subagent_id': null, 'constraints': this.data.map(x => x.to_dict())};

    if (this.subagent_id) {
      options['subagent_id'] = +this.subagent_id;
    }

    this.http.post(method, options)
      .subscribe(_ => this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000));
  }

  onConstraintChange(number, ind) {
    for (const [key, value] of Object.entries(this.header_info)) {
      if (key !== '2' && key !== '0') {
        value['count'] = this.data.filter(x => {
          return x[this.list[key]] === true;
        }).length;
      }
      if (key === '2') {
        value['count'] = this.data.filter(x => {
          if (x[this.list[3]] === true || x[this.list[4]] === true) {
            return true;
          }
        }).length;
      }
    }
  }

}
