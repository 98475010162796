import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-simple-table-pagination',
  templateUrl: './simple-table-pagination.component.html',
  styleUrls: ['./simple-table-pagination.component.scss']
})
export class SimpleTablePaginationComponent {

  private readonly maxPagesToShow = 5;

  public currentPage = 0;

  @Input('totalRows') public totalRows: number;
  @Input('itemsPerPags') public itemsPerPags = 15;

  @Output('currentPageEmitter') public currentPageEmitter: EventEmitter<number> = new EventEmitter();

  public get pagesCount(): number {
    return Math.ceil(this.totalRows / this.itemsPerPags);
  }

  public setCurrentPage(page) {
    this.currentPage = page;
    this.currentPageEmitter.emit(page);
  }

  public get pages() {
    const pagesArray: Array<any> = [];

    if (this.pagesCount <= 1) {
      return [];
    }

    if (this.pagesCount < this.maxPagesToShow) {
      return Array.apply(null, Array(this.pagesCount)).map((x, i) => i);
    }

    const numAdjacents = parseInt(((this.maxPagesToShow - 3) / 2).toString(), 10);
    let slidingStart: number, slidingEnd: number;

    if (this.currentPage + numAdjacents > this.pagesCount) {
        slidingStart = this.pagesCount - this.maxPagesToShow + 2;
    }
    else {
        slidingStart = this.currentPage - numAdjacents;
    }

    if (slidingStart < 1) {
      slidingStart = 1;
    }

    slidingEnd = slidingStart + this.maxPagesToShow - 2;
    if (slidingEnd >= this.pagesCount) {
      slidingEnd = this.pagesCount - 1;
    }

    pagesArray.push(0);
    if (slidingStart > 2) {
        pagesArray.push('...');
    }

    for (let i = slidingStart; i <= slidingEnd; i++) {
        pagesArray.push(i);
    }

    if (slidingEnd < this.pagesCount - 1) {
        pagesArray.push('...');
    }

    pagesArray.push(this.pagesCount);

    return pagesArray;
  }

}
