import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {AlternativeDatatablesInterface} from '../../../../../shared/alternative-datatables/alternative-datatables.interface';
import {AlternativeDatatablesComponent} from '../../../../../shared/alternative-datatables/alternative-datatables.component';
import {JsonClientService} from '../../../../../shared/json-client';
import {GoBackButtonService} from '../../../../../services/go-back-button.service';
import {BreadcrumbsService} from '../../../../../shared/breadcrumbs/services';

@Component({
  templateUrl: './get-activity-table.component.html',
  styleUrls: [
    './get-activity-table.component.scss'
  ]
})
export class GetActivityTableComponent
  extends AlternativeDatatablesComponent
  implements AlternativeDatatablesInterface, OnInit, OnDestroy, AfterViewInit {

  public readonly head = [
    {'name': 'stage_change_time', 'value': '', 'caption': 'Дата изменения', 'sort': true},
    {'name': 'organisation_caption', 'value': '', 'caption': 'Организация', 'width': '', 'sort': true},
    {'name': 'subagent_user_fio', 'value': '', 'caption': 'Субагент', 'width': '', 'sort': true},
    {'name': 'subagent_phone', 'value': '', 'caption': 'Тел. субагента', 'width': '', 'sort': true},
    {'name': 'fio', 'value': '', 'caption': 'Лид', 'width': '', 'sort': true},
    {'name': 'lead_phone', 'value': '', 'caption': 'Тел. клиента', 'width': '', 'sort': true},
    {'name': 'object_caption', 'value': '', 'caption': 'Объект', 'width': '', 'sort': true},
    {'name': 'physical_manager_fio', 'value': '', 'caption': 'Ответственный', 'width': '', 'sort': true},
    {'name': 'stage_caption', 'value': '', 'caption': 'Этап', 'width': '', 'sort': true},
    {'name': 'bp_source', 'value': '', 'caption': 'Источник', 'width': '', 'sort': false},
  ];
  public filter_columns = {};
  public form: UntypedFormGroup;
  public filter: string;

  private parasites = true;

  public filterSubject: Subject<void> = new Subject<void>();
  private filterSubscription: Subscription;
  private formSubscription: Subscription;

  public current_page = 1;

  public count;
  public rows;
  public thisDate = new Date();

  public sort: {[key: string]: string} = {};

  private default_query = {};

  public set data(value) {
    this.count = value['count'];
    this.rows = value['rows'];
  }

  constructor(private router: Router, private fb: UntypedFormBuilder, public activatedRoute: ActivatedRoute,
              public goBack: GoBackButtonService, private b: BreadcrumbsService,
              public http: JsonClientService) {
    super(router, activatedRoute, fb, http);

    this.filterSubscription = this.filterSubject
      .pipe(debounceTime(500))
      .subscribe(_ => this.onFilterChanges());

    this.head = this.head.map(x => {
      x.value = this.activatedRoute.snapshot.queryParams[x.name] || x.value;
      return x;
    });
    if (this.activatedRoute.snapshot.queryParams['sort_name']) {
      this.sort[this.activatedRoute.snapshot.queryParams['sort_name']] = this.activatedRoute.snapshot.queryParams['sort_key'];
    }
  }

  ngOnInit() {

    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/analytic/index', caption: 'Аналитика'},
    ]);
    this.goBack.header = '<h1>KPI отдела привлечения по закрытым сделкам</h1>';

    this.default_query = {
      'date_start': this.activatedRoute.snapshot.queryParams['date_start'],
      'date_end': this.activatedRoute.snapshot.queryParams['date_end'],
    };
    if (this.activatedRoute.snapshot.queryParams['method'] === 'get_summary_statistic') {
      this.default_query['method'] = this.activatedRoute.snapshot.queryParams['method'];
      this.default_query['month'] = this.activatedRoute.snapshot.queryParams['month'];
      this.default_query['locked'] = this.activatedRoute.snapshot.queryParams['locked'];
      if (this.activatedRoute.snapshot.queryParams['locked'] === 'cnt_org_register') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Первичная регистрация пользователя субагента (Пользователь нажал на кнопку регистрации на landing page)</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['locked'] === 'cnt_org_register_fail') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Отклонение первичной регистрация пользователя субагента</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['locked'] === 'cnt_org_register_not_approve') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Заполнение мастера субагентом (Пользователь подтвердил что он - руководитель и выбрал организацию)</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['locked'] === 'cnt_org_master_fail') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Отклонение заполнения мастера субагентом</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['locked'] === 'cnt_org_register_approve') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Акцепт оферты субагентом (Пользователь подтвердил оферту и ожидает подтверждение менеджером, чтобы начать работу с системой)</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['locked'] === 'cnt_org_register_approve_fail') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Отклонение акцепта оферты субагентом</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['locked'] === 'cnt_org_approve') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Подтверждение субагента менеджером (Подтверждение субагента произошло, субагент наделен полными правами)</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['locked'] === 'cnt_org_approve_fail') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Отклонение подтверждённых субагентских организаций менеджером</h1>';
      }
    } else if (this.activatedRoute.snapshot.queryParams['method'] === 'get_sub_activity') {
      this.default_query['method'] = this.activatedRoute.snapshot.queryParams['method'];
      this.default_query['sub_date_from'] = this.activatedRoute.snapshot.queryParams['sub_date_from'];
      this.default_query['result_type'] = this.activatedRoute.snapshot.queryParams['result_type'];
      this.default_query['fact_type'] = this.activatedRoute.snapshot.queryParams['fact_type'];
      this.default_query['gap'] = this.activatedRoute.snapshot.queryParams['gap'];
      this.default_query['only_subagent_id'] = this.activatedRoute.snapshot.queryParams['only_subagent_id'];
      const header = '<h1>' + this.default_query['gap'] + (this.rows[0] ? (' ' + this.rows[0]['organisation_caption']) : '');
      if (this.activatedRoute.snapshot.queryParams['fact_type'] === 'create_lead') {
        this.goBack.header =  header + ' - Активность по созданию лидов</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['fact_type'] === 'db') {
        this.goBack.header =  header + ' - Активность по ДБ</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['fact_type'] === 'finish') {
        this.goBack.header =  header + ' - Активность по завершению БП</h1>';
      }
    } else if (this.activatedRoute.snapshot.queryParams['method'] === 'get_complex_activity') {
      this.default_query['method'] = this.activatedRoute.snapshot.queryParams['method'];
      this.default_query['result_type'] = this.activatedRoute.snapshot.queryParams['result_type'];
      this.default_query['fact_type'] = this.activatedRoute.snapshot.queryParams['fact_type'];
      this.default_query['gap'] = this.activatedRoute.snapshot.queryParams['gap'];
      this.default_query['complex_id'] = this.activatedRoute.snapshot.queryParams['complex_id'];
      const header = '<h1>' + this.default_query['gap'] + (this.rows[0] ? (' ' + this.rows[0]['complex_caption']) : '');
      if (this.activatedRoute.snapshot.queryParams['fact_type'] === 'create_lead') {
        this.goBack.header =  header + ' - Активность по созданию лидов</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['fact_type'] === 'db') {
        this.goBack.header =  header + ' - Активность по ДБ</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['fact_type'] === 'finish') {
        this.goBack.header =  header + ' - Активность по завершению БП</h1>';
      }
    } else if (this.activatedRoute.snapshot.queryParams['method'] === 'get_reserved_summary' ||
      this.activatedRoute.snapshot.queryParams['method'] === 'get_unique_leads_on_period' ||
      this.activatedRoute.snapshot.queryParams['method'] === 'get_stage_statistic_lead_on_moment') {
      this.default_query['method'] = this.activatedRoute.snapshot.queryParams['method'];
      this.default_query['month'] = this.activatedRoute.snapshot.queryParams['month'];
      this.default_query['stage'] = this.activatedRoute.snapshot.queryParams['stage'];
      if (this.activatedRoute.snapshot.queryParams['method'] === 'get_unique_leads_on_period') {
        this.default_query['flag'] = this.activatedRoute.snapshot.queryParams['flag'];
      }
      if (!this.default_query['month']) {
        this.default_query['month'] = 'За весь период';
      }
      if (this.activatedRoute.snapshot.queryParams['method'] === 'get_stage_statistic_lead_on_moment') {
        this.default_query['month'] = 'Текущее состояние';
      }
      if (this.activatedRoute.snapshot.queryParams['stage'] === 'sale_call') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Вх./Исх. Звонок</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'sale_unique_check') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Проверка Уникальности</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'sale_preview') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Назначение просмотра</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'sale_view') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Подтверждение просмотра</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'sale_view_processing') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Проведение просмотра</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'sale_pre_reserve') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Ожидание резерва</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'sale_reserve') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Ожидание ДБ / резерва</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'pre_sale_stages_fail') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Кол-во расторжений - пресейл</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'sale_db') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - ДБ, Получение док-тов</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'sale_ddu') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - ДДУ Подписание</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'sale_ddu_payment') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - ДДУ Оплата</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'sale_developer_payment') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - ПД-З Оплата</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'sale_developer_docs') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - ПД-З Получена</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'sale_subagent_docs') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - ПД-С Получение док-тов</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'sale_subagent_payment') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - ПД-С Оплата</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'sale_archive') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Архив</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'sale_stages_fail') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Кол-во расторжений сделка</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'total_leads') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Кол-во обращений</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'pre_sale_leads') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Пресейл в работе</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'pre_sale_leads_fail') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Дисквалификация</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'sale_qualification') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Квалификация</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'sale_leads') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Сделок в работе</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'sale_leads_fail') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Дисквалификация</h1>';
      } else if (this.activatedRoute.snapshot.queryParams['stage'] === 'total_complete') {
        this.goBack.header = '<h1>' + this.default_query['month'] + ' - Архив (СДЕЛКИ ЗАКРЫТЫ)</h1>';
      }
    }



      this.filter_columns = Object.assign({}, this.default_query);

    this.form = this.fb.group({});

    this.formSubscription = this.form.valueChanges
      .subscribe(_ => this.filterSubject.next());

    for (const el of this.head) {
      this.form.addControl(el.name, new UntypedFormControl(el.value));
    }

    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';

    this.onInitCall();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }

    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  onFilterChanged(): void {
    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.data = this.activatedRoute.snapshot.data['data'];
    this.current_page = +this.activatedRoute.snapshot.queryParams['page'] || 1;
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/analytic/index', caption: 'Аналитика'},
    ]);
  }

  onFilterChanges(text = null) {

    if (text !== null) {
      this.filter = text;
    }

    this.filter_columns = Object.assign({}, this.default_query);
    for (const el of this.head) {
      if (!this.form.get(el.name).value.length) {
        continue;
      }

      this.filter_columns[el.name] = this.form.get(el.name).value;
    }
    if (this.filter !== '') {
      this.filter_columns['global_search_string'] = this.filter;
    }
    if (this.sort) {
      this.filter_columns['sort_name'] = Object.keys(this.sort)[0];
      this.filter_columns['sort_key'] = this.sort[this.filter_columns['sort_name']];
    }
    if (this.parasites) {
      return this.parasites = !this.parasites;
    }

    this.router.navigate(['analytic', 'inner-lead', 'table'], {
      queryParams: this.getQueryParams(),
    });
  }

  getQueryParams() {
    const query_params = Object.assign({}, this.default_query);
    for (const el of this.head) {
      if (!this.form.get(el.name).value.length) {
        continue;
      }
      query_params[el.name] = this.form.get(el.name).value;
    }
    if (this.sort) {
      query_params['sort_name'] = Object.keys(this.sort)[0];
      query_params['sort_key'] = this.sort[query_params['sort_name']];
    }
    if (this.filter !== '') {
      query_params['global_search_string'] = this.filter;
    }
    return query_params;
  }

  prepareSort(column_name: string) {
    for (const key in this.sort) {
      if (column_name !== key) {
        delete this.sort[key];
      }
    }
  }

  sortChange(column_name: string) {
    this.prepareSort(column_name);
    this.sort[column_name] = !this.sort[column_name] || ('desc' === this.sort[column_name]) ? 'asc' : 'desc';
    this.onFilterChanges();
  }
  ngAfterViewInit() {
    this.goBack.goScroll();
  }
  datediff(newdate) {
    const oneDay = 1000 * 60 * 60 * 24;
    const [day, month, year] = newdate.split('.');
    const dayTill = Math.round((new Date(year, month - 1, day).getTime() - this.thisDate.getTime()) / oneDay) + 1;
    if (dayTill === 0) {
      return 'Контакт сегодня';
    } else if (dayTill > 0) {
      return 'Дней до контакта (' + dayTill + ')';
    } else if (dayTill < 0) {
      return 'Контакт просрочен';
    }
  }
}
