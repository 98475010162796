import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlComponent } from './control.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTableModule } from '../../modules/@datatable/datatables.module';
import { SharedModule } from '../../shared/shared.module';
import { MaterialInputModule } from '../../modules/@material-input';
import { ControlLayoutsComponent } from './control-layouts/control-layouts.component';
import { ControlConditionsComponent } from './control-conditions/control-conditions.component';
import { ControlImportComponent } from './control-import/control-import.component';
import { ControlXmlonComponent } from './control-xmlon/control-xmlon.component';
import { ControlXmldelComponent } from './control-xmldel/control-xmldel.component';
import {ControlCrmNotImportedFlatsComponent} from './control-crm-not-imported-flats/control-crm-not-imported-flats.component';
import {ControlCrmNotImportedFlatsResolve} from './control-crm-not-imported-flats/control-crm-not-imported-flats.resolve';
import {PaginatorModule} from '../../shared/housing-modules/modules';
import {ControlCrmNotRelatedFlatsComponent} from './control-crm-not-related-flats/control-crm-not-related-flats.component';
import {ControlCrmNotRelatedFlatsResolve} from './control-crm-not-related-flats/control-crm-not-related-flats.resolve';
import {ControlIndexComponent} from './control-index/control-index.component';
import {ControlIndexCardComponent} from './control-index/card/control-index-card.component';

@NgModule({
  imports: [
    CommonModule,
    DataTableModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
    RouterModule,
    SharedModule,
    PaginatorModule,
  ],
  declarations: [
    ControlComponent,
    ControlLayoutsComponent,
    ControlConditionsComponent,
    ControlImportComponent,
    ControlXmlonComponent,
    ControlXmldelComponent,
    ControlCrmNotImportedFlatsComponent,
    ControlCrmNotRelatedFlatsComponent,
    ControlIndexComponent,
    ControlIndexCardComponent,
  ],
  providers: [
    ControlCrmNotImportedFlatsResolve,
    ControlCrmNotRelatedFlatsResolve,
  ],
    exports: [
        ControlComponent,
        ControlLayoutsComponent,
        ControlConditionsComponent,
        ControlImportComponent,
        ControlXmlonComponent,
        ControlXmldelComponent,
        ControlIndexComponent,
        ControlIndexCardComponent,
    ]
})
export class ControlModule {
}
