import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MaterialInputComponent} from './material-input.component';

@Component({
  selector: 'app-material-textarea',
  templateUrl: './material-textarea.component.html',
  styleUrls: ['./material-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialTextareaComponent),
      multi: true
    }
  ]
})
export class MaterialTextareaComponent extends MaterialInputComponent {
  @Input() public rows = 5;

}
