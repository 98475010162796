<div *ngIf="data === null">
  <app-preloader></app-preloader>
</div>
<div *ngIf="data !== null && !data.length">
  Сертификаты у данного пользователя отсутствуют
</div>
<div *ngIf="data !== null && data.length">
  <app-simple-table>
    <app-simple-table-th>№ сертификата</app-simple-table-th>
    <app-simple-table-th>Дата сертификата</app-simple-table-th>
    <app-simple-table-th>Событие</app-simple-table-th>
    <app-simple-table-th>Дата события</app-simple-table-th>
    <app-simple-table-th>Тип события</app-simple-table-th>
    <app-simple-table-th>Присутствовал</app-simple-table-th>
    <app-simple-table-th>Компания</app-simple-table-th>
    <app-simple-table-th>Сертификат</app-simple-table-th>
    <!-- // -->
    <app-simple-table-tr *ngFor="let row of data; index as i">
      <td>{{row['cert_num']}}</td>
      <td>{{row['cert_date']}}</td>
      <td>{{row['event_caption']}}</td>
      <td>{{row['event_date']}}</td>
      <td>{{row['event_type']}}</td>
      <td>{{row['is_visit']}}</td>
      <td>{{row['company_caption']}}</td>
      <td><a *ngIf="row['url_certificate']" [href]="row['url_certificate']" target="_blank">Скачать</a></td>
    </app-simple-table-tr>
  </app-simple-table>
</div>
