<section class="main-wrapper login" *ngIf="display" id="locker-wrapper">
  <a href="javascript: void(0)" routerLink="/" class="logo logo_spn"></a>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <h2 class="text-center">Сервер обновляется</h2>
        <h3 class="text-center">Пожалуйста, подождите <i class="fa fa-spinner fa-spin"></i></h3>
      </div>
    </div>
  </div>
</section>
