import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DataTableComponent } from './components/datatable.component';
import { DataTableHeadComponent } from './components/datatable-head.component';
import { DataTableHeadlineComponent } from './components/datatable-headline.component';
import { DataTableBodyComponent } from './components/datatable-body.component';
import { DataTablePaginationComponent } from './components/datatable-pagination.component';
import { MaterialInputModule } from '../@material-input/material-input.module';
import { DataTableParamComponent } from './components/datatable-param.component';
import {MobileSortModule} from '../../shared/directives/mobile-sort/mobile-sort.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialInputModule,
    ReactiveFormsModule,
    MobileSortModule,
  ],
  declarations: [
    DataTableComponent,
    DataTableHeadlineComponent,
    DataTableHeadComponent,
    DataTableBodyComponent,
    DataTablePaginationComponent,
    DataTableParamComponent
  ],
  exports: [
    DataTableComponent,
    DataTableHeadlineComponent,
    DataTableHeadComponent,
    DataTableBodyComponent,
    DataTablePaginationComponent,
    DataTableParamComponent
  ]
})
export class DataTableModule { }
