import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {DataTableModule} from '../../modules/@datatable/datatables.module';
import {TabsModule} from '../../modules/@tabs/tabs.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialInputModule} from '../../modules/@material-input/material-input.module';
import {SharedModule} from '../../shared/shared.module';
import {PreloaderModule} from '../../modules/@preloader/preloader.module';
import {SimpleTableModule} from '../../modules/@simple-table/simple-table.module';
import {PhysicalCreateComponent} from './physical-create/physical-create.component';
import {PhysicalCreateFieldsComponent} from './shared/physical-create-fields/physical-create-fields.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DataTableModule,
    TabsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
    SharedModule,
    PreloaderModule,
    SimpleTableModule
  ],
  declarations: [
    PhysicalCreateComponent,
    PhysicalCreateFieldsComponent,
  ],
  exports: [
    PhysicalCreateComponent,
    PhysicalCreateFieldsComponent,
  ],
})
export class PhysicalSharedModule { }
