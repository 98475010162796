Всего: <b>{{count}}</b>
<div class="mb-2">
  <div class="row no-gutters align-items-center m-0">
    <div class="col-md-6 col-lg-6 col-xl-4">
      <div class="form__group">
        <div class="form__input">
          <input class="input" type="text" placeholder="Поиск"
                 [ngModel]="filter" (ngModelChange)="onFilterChanges($event)">
          <div class="form__input_icon icon icon_Small_Search c-pointer ng-star-inserted"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="material-datatable-wrapper table-color">
  <div class="table-responsive">
    <table class="table">
      <tr>
        <th *ngFor="let el of head" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
          <div [formGroup]="form">
            <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
              <div class="col table__input">
                <ng-container *ngIf="el.name === 'next_contact_date' || el.name === 'stage'">
                  <app-material-input-datepicker formControlName="next_contact_date" [cssClass]="'mb-0'" [placeholder]="'След. контакт (созд./изм.)'">
                  </app-material-input-datepicker>
                </ng-container>
                <ng-container *ngIf="el.name !== 'next_contact_date' && el.name !== 'stage'">
                  <app-material-input
                    [placeholder] = "el.caption"
                    [formControlName] = "el.name"
                    [cssClass]="'mb-0'">
                  </app-material-input>
                </ng-container>
              </div>
              <ng-container *ngIf="el.name !== 'stage'">
                <div class="col-auto" *ngIf="el.sort">
                  <a href="javascript:void(0)" (click)="sortChange(el.name)">
                    <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                      'icon_new-sort-down': 'asc' == sort[el.name],
                                      'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                  </a>
                </div>
              </ng-container>

            </div>
          </div>
        </th>
      </tr>
      <!-- // -->
      <tr *ngFor="let row of rows; let ind = index" [attr.data-color]="row.tab?row.tab:''">
        <td>
          {{row.stage_change_time}}
        </td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'organisation_caption-' + ind}">
            <a href="/entity/edit/{{row.organisation_id}}"
               (click)="goBack.newLink('/entity/edit/' + row.organisation_id, 'organisation_caption-' + ind, $event)">
              {{row.organisation_caption}}
            </a>
          </div>
        </td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'subagent_user_fio-' + ind}">
            <a href="/physical/edit/{{row.subagent_user_id}}"
               (click)="goBack.newLink('/physical/edit/' + row.subagent_user_id, 'subagent_user_fio-' + ind, $event)">
              {{row.subagent_user_fio}}
            </a>
          </div>
        </td>
        <td>{{row['subagent_phone']}}</td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'fio-' + ind}">
            <a href="/reservation/lead-info/{{row.id}}"
               (click)="goBack.newLink('/reservation/lead-info/' + row.id, 'fio-' + ind, $event)">
              {{row.fio}}
            </a>
          </div>
        </td>
        <td>{{row['lead_phone']}}</td>
        <td>
          <ng-container *ngIf="row.object_type === 'flat'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'object_caption-' + ind}">
              <a href="/housing2/flat/{{row.object_id}}"
                 (click)="goBack.newLink('/housing2/flat/' + row.object_id, 'object_caption-' + ind, $event)">
                {{row.object_caption}}
              </a>
            </div>
          </ng-container>
          <ng-container *ngIf="row.object_type === 'housing'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'object_caption-' + ind}">
              <a href="/housing2/house/{{row.object_id}}"
                 (click)="goBack.newLink('/housing2/house/' + row.object_id, 'object_caption-' + ind, $event)">
                {{row.object_caption}}
              </a>
            </div>
          </ng-container>
        </td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'physical_manager_fio-' + ind}">
            <a href="/physical/edit/{{row.physical_manager_id}}"
               (click)="goBack.newLink('/physical/edit/' + row.physical_manager_id, 'physical_manager_fio-' + ind, $event)">
              {{row.physical_manager_fio}}
            </a>
          </div>
        </td>
        <td><app-stages-dots [data]="row.stage_information" [info]="row.stage_information.stage_changes_list"></app-stages-dots></td>
    </table>
  </div>
</div>

<div class="alt-pagination">
  <app-paginator
    [queryParams]="filter_columns"
    [itemsPerPage]="20"
    [itemCount]="count"
    [currentPage]="current_page"
    [baseUrl]="'/analytic/report-kpi/table'">
  </app-paginator>
</div>
