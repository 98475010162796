<h1 class="mb-3">Редактирование новости</h1>
<form class="form form_crm" [formGroup]="form" *ngIf="form" novalidate (submit)="onSubmit()">
  <div class="row no-gutters gutters-4">
    <div class="col-12">
      <app-material-input formControlName="news_title"
                          [placeholder]="'Заголовок'"
                          [require]="true">
        Заголовок</app-material-input>
    </div>
    <div class="col-12">
      <app-wysiwyg-editor [placeholder]="'Краткое описание'" formControlName="news_description"></app-wysiwyg-editor>
    </div>
    <div class="col-12">
      <app-material-input formControlName="news_link"
                          [placeholder]="'Ссылка'"
                          [require]="true">
        Ссылка</app-material-input>
    </div>
    <div class="col-md-3 pt-2">
      <app-material-input-checkbox formControlName="always_show" [cssClass]="'mb-0'" [border]="true">
        Всегда всплывает
      </app-material-input-checkbox>
    </div>
    <div class="col-md-3">
      <app-material-select [require]="true" [caption]="'Публикация на:'" formControlName="public_status">
        <option [value]="'crm'">СА</option>
        <option [value]="'site'">САЙТ</option>
        <option [value]="'site_crm'">САЙТ+СА</option>
      </app-material-select>
    </div>
    <div class="col-md-3">
      <app-material-input-datepicker formControlName="news_date"
                          [placeholder]="'Дата публикации'"
                          [require]="true">
        Дата публикации</app-material-input-datepicker>
    </div>
    <div class="col-md-3 pt-2">
      <app-material-input-checkbox formControlName="is_archive" [cssClass]="'mb-0'" [border]="true">
        В архиве
      </app-material-input-checkbox>
    </div>
  </div>
  <div class="row no-gutters gutters-2 mt-3 mb-5 mb-md-0">
    <div class="col-md-10">
      <button type="submit" class="button button_sm" [disabled]="!form.valid">Сохранить и закрыть</button>
    </div>
  </div>
</form>
