import {Component, ComponentRef, OnInit, ViewChild} from '@angular/core';
import {OverlayWindowService} from '../../../_crm/single-window/shared/overlay-window.service';
import {CalendarInviteComponent} from './calendar-invite-component';
import {NotificationService} from '../../../services/notification.service';
import {DataTableComponent} from '../../../modules/@datatable/components';
import {GoBackButtonService} from '../../../services/go-back-button.service';

@Component({
  templateUrl: './calendar-invites.component.html',
  styleUrls: ['./calendar-invites.component.scss']
})
export class CalendarInvitesComponent implements OnInit {

  @ViewChild('rowBtn', {static: true}) private rowBtn;
  @ViewChild('table', {static: true}) private table: DataTableComponent;

  public columns: Array<{[p: string]: string | number}>;

  constructor(private overlayWindowService: OverlayWindowService, private notify: NotificationService,
              private goBack: GoBackButtonService) { }

  ngOnInit() {
    this.goBack.header = '<h1>Шаблоны приглашений</h1>';
    this.columns = [
      {'caption': '№ п/п', 'name': 'calendarinvitetemplate_id'},
      {'caption': 'Название', 'name': 'calendarinvitetemplate_caption'},
      {'caption': '', 'name': 'calendarinvitetemplate_id', 'template': this.rowBtn},
    ];
  }

  open(template_id) {
    this.overlayWindowService.openComponentInPopup(CalendarInviteComponent)
      .then((componentRef: ComponentRef<CalendarInviteComponent>) => {
        componentRef.instance.invite_id = template_id;
        const subscription = componentRef.instance.saveEmitter.subscribe(_ => {
          subscription.unsubscribe();
          this.notify.notifySuccess('Готово', 'Изменения успешно сохранены', 3000);
          this.overlayWindowService.closeHost();
          this.table.loadList();
        });
      });
  }

}
