<div class="mb-2">
  <form [formGroup]="formGroup" novalidate (submit)="clickOnSubmit(formGroup.value.search)">
    <div class="row no-gutters align-items-center justify-content-between m-0 flex-nowrap">
      <div *ngIf="enable_global_search_string"
           class=""
           [class.col-12]="!templateLeft && !templateRight || templateLeft && templateRight"
           [class.col-5]="!templateLeft && templateRight || templateLeft || templateRight"
           [class.col-lg-6]="templateLeft || templateRight"
           [class.flex-shrink-1]="templateLeft || templateRight"
      >
        <!--<p class="form-group"><strong>Найдено результатов: {{showed_lines}}</strong></p>-->
        <div class="form-group mb-0">
          <app-material-input-search formControlName="search" (onSubmit)="clickOnSubmit($event)"
                                     [placeholder]="'Поиск'" [withMb0]="true"></app-material-input-search>
        </div>
      </div>
      <ng-container *ngIf="templateRight || templateLeft">
        <div class="col-7 col-lg-6 flex-grow-1"
             [class.col-12]="templateLeft && templateRight"
             [class.col-6]="!templateLeft && templateRight || templateLeft && !templateRight"
        >
          <div class="d-flex flex-row-reverse flex-nowrap justify-content-between align-items-center">
            <div *ngIf="templateRight">
              <ng-template [ngTemplateOutlet]="templateRight"></ng-template>
            </div>
            <div *ngIf="templateLeft">
              <div class="d-flex flex-nowrap align-items-center">
                <ng-template [ngTemplateOutlet]="templateLeft"></ng-template>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!--<div class="col-md-2"></div>-->
      <!--<div class="col-md-6">-->
        <!--<div class="form-group">-->
          <!--<p class="form-group">&nbsp;</p>-->
          <!--<app-material-select formControlName="items_per_page" (onSelected)="onPerPageChange()">-->
            <!--<option *ngFor="let variant of items_per_page_vars" [value]="variant">-->
              <!--Элементов на странице: {{variant}}</option>-->
          <!--</app-material-select>-->
        <!--</div>-->
      <!--</div>-->
    </div>
  </form>
</div>

