<div class="table-responsive">
  <form [formGroup]='formGroup' >
    <h1>Подтверждение просмотра</h1>
    <table class="table table_vertical table-small-font table-bordered table-striped f-13">
      <tr>
        <th>Дата просмотра:</th>
        <td>

            <app-material-input-datepicker [require]="true" formControlName="view_date">
            </app-material-input-datepicker>

        </td>
      </tr>
      <tr>
        <th>Время просмотра:</th>
        <td>
            <app-material-input-mask [require]="true" formControlName="view_time"
                                     [placeholder]="'__:__'" [mask]="'00:00'"></app-material-input-mask>
        </td>
      </tr>
      <tr>
        <th>Комментарий:</th>
        <td>
            <div class="form__input">
              <textarea class="textarea md-valid" [placeholder]="'Комментарий'" formControlName='comment'></textarea>
            </div>
        </td>
      </tr>
    </table>

    <button class="button mr-2"  [disabled]="!formGroup.valid" (click)="save(true)">Подтвердить</button>
    <button class="button"  [disabled]="!formGroup.valid" (click)="save(false)">Отклонить</button>
  </form>
</div>
