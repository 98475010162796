import {Component, EventEmitter, Input, Output} from '@angular/core';
import {HttpClient} from '../../../services/http.service';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'app-attraction-status-change',
  templateUrl: './attraction-status-change.component.html',
  styleUrls: ['./attraction-status-change.component.scss']
})

export class AttractionStatusChangeComponent {

  public _subagent_id = null;

  public subagent_text = [];
  public layout_opened = false;
  constructor(public http: HttpClient, public notify: NotificationService) {}

  @Input() private set subagent_id(value) {
    if (value) {
      this.loadData(value);
    }
  }

  private get subagent_id() {
    return this._subagent_id;
  }

  @Input('subagent_caption') public subagent_caption: string;
  @Input('subagent_type') public subagent_type: string;
  @Input('window_caption') public window_caption: string;
  @Input('window_text') public window_text: string;

  @Output() public onClosed: EventEmitter<void> = new EventEmitter<void>();

  private loadData(value) {

    this._subagent_id = value;
    this.layout_opened = true;

  }

  editData(status) {

    const method = 'Attraction.update_subagent_status';

    const options = {
      'subagent_id': this._subagent_id,
      'status_id': status,
      'comment': this.subagent_text[status] || ''
    };

    this.http.post(method, options).subscribe(
      response => {
        this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
        this.closeEvent();
      },
      error => { }
    );

  }

  closeEvent() {
    this.layout_opened = false;
    this.subagent_text = [];
    this._subagent_id = null;
    this.onClosed.emit();
  }
}
