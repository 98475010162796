import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialInputModule } from '../../modules/@material-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DataTableModule } from '../../modules/@datatable/datatables.module';
import { SharedModule } from '../../shared/shared.module';
import {AbleproDirectivesModule} from '../../_front/core/ablepro-directives.module';
import {PaginatorModule} from '../../shared/housing-modules/modules';
import {ReglTableComponent} from './regl-table/regl-table.component';
import {ReglTableResolve} from './regl-table/regl-table.resolve';
import {RightWindowModule} from '../../shared/right-window/right-window.module';
import {RightViewReglComponent} from './right-view-regl/right-view-regl.component';
import {RightViewActComponent} from './right-view-act/right-view-act.component';
import {ReglTableService} from './regl-table/regl-table.service';
import {MassHelpComponent} from './regl-help/regl-help.component';
import {MassWrapTabsComponent} from './wrap-tabs/wrap-tabs.component';
import {PurchTermTableComponent} from './purch-term-table/purch-term-table.component';
import {PurchTermTableResolve} from './purch-term-table/purch-term-table.resolve';
import {PurchTermTableService} from './purch-term-table/purch-term-table.service';
import {TabsModule} from '../../modules/@tabs/tabs.module';
import {RightViewTermComponent} from './right-view-term/right-view-term.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DataTableModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
    SharedModule,
    AbleproDirectivesModule,
    PaginatorModule,
    RightWindowModule,
    TabsModule,
  ],
  declarations: [
    ReglTableComponent,
    RightViewReglComponent,
    RightViewActComponent,
    MassHelpComponent,
    MassWrapTabsComponent,
    PurchTermTableComponent,
    RightViewTermComponent,
  ],
  exports: [
    ReglTableComponent,
    MassHelpComponent,
    MassWrapTabsComponent,
    PurchTermTableComponent,
  ],
  providers: [
    ReglTableResolve,
    ReglTableService,
    PurchTermTableResolve,
    PurchTermTableService,
  ]
})
export class MassFillingModule { }
