import {Component, Input} from '@angular/core';

@Component({
  templateUrl: './calendar-teaching-users.component.html',
  styleUrls: ['./calendar-teaching-users.component.scss']
})
export class CalendarTeachingUsersComponent {

  @Input('list') public list: {[p: string]: any};
  @Input('type') public type: string;

}
