import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FieldsModule} from '../../../../modules/@fields/fields.module';
import {FieldSubwayComponent} from './field-subway.component';
import {FieldSubwayPipe} from './field-subway.pipe';
import {MaterialInputModule} from '../../../../modules/@material-input';

@NgModule({
  imports: [
    CommonModule,
    FieldsModule,
    MaterialInputModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    FieldSubwayComponent,
    FieldSubwayPipe,
  ],
  exports: [
    FieldSubwayComponent,
    FieldSubwayPipe,
  ]
})
export class FieldSubwayModule { }
