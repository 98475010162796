import { Component, forwardRef, Input } from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MaterialInputComponent} from './material-input.component';

@Component({
  selector: 'app-material-input-datepicker',
  templateUrl: './material-input-datepicker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialInputDatePickerComponent),
      multi: true
    }
  ]
})
export class MaterialInputDatePickerComponent extends MaterialInputComponent {
  @Input('mask') public mask = '00.00.0000';

  onChangeDate(target: any) {
    this.current_value = target.value;
  }
}
