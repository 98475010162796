import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialInputModule} from '../../modules/@material-input';
import {EntityCreateComponent} from './entity-create/entity-create.component';
import {EntityCreateFieldsComponent} from './shared/entity-create-fields/entity-create-fields.component';
import {EntityAdditionallyFieldsComponent} from './shared/entity-additionally-fields/entity-additionally-fields.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
  ],
  declarations: [
    EntityCreateComponent,
    EntityCreateFieldsComponent,
    EntityAdditionallyFieldsComponent,
  ],
  exports: [
    EntityCreateComponent,
    EntityCreateFieldsComponent,
    EntityAdditionallyFieldsComponent,
  ]
})
export class EntitySharedModule { }
