
<div class="material-datatable-wrapper small_table">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)">
  </app-datatable-headline>
  <div class="table-responsive">
    <table class="table table-striped datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>


<ng-template #IdHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="subagent_boss_id" [cssClass]="'mb-0'" [placeholder]="'№'">
    </app-material-input>
  </form>
</ng-template>


<ng-template #timeHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="status_modified_time" [cssClass]="'mb-0'" [placeholder]="'Дата изменения'">
    </app-material-input>
  </form>
</ng-template>
<ng-template #regHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="create_date" [cssClass]="'mb-0'" [placeholder]="'Регистрация'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #orgHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="subagent_caption" [cssClass]="'mb-0'" [placeholder]="'Организация'">
    </app-material-input>
  </form>
</ng-template>


<ng-template #orgTmpl let-row="row">
  <ng-container *ngIf="row.subagent_caption&&row.subagent_caption">
    <a [routerLink]="['/entity/edit/',row.subagent_id]">
      <i class="fa fa-user"></i> {{row.subagent_caption}}
    </a>
  </ng-container>
</ng-template>

<ng-template #managerHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="subagent_boss_fio" [cssClass]="'mb-0'" [placeholder]="'Руководитель'">
    </app-material-input>
  </form>
</ng-template>


<ng-template #managerTmpl let-row="row">
  <ng-container *ngIf="row.subagent_boss_fio&&row.subagent_boss_id">
    <a [routerLink]="['/physical/edit/',row.subagent_boss_id]">
      <i class="fa fa-user"></i> {{row.subagent_boss_fio}}
    </a>
  </ng-container>
</ng-template>


<ng-template #phoneHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="subagent_boss_phone" [cssClass]="'mb-0'" [placeholder]="'Телефон'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #emailHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="subagent_boss_email" [cssClass]="'mb-0'" [placeholder]="'Email'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #entityHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="subagent_address" [cssClass]="'mb-0'" [placeholder]="'Юр. адреса'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #typeHead>
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="notice" [cssClass]="'mb-0'" [placeholder]="'Комментарий'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #typeTmpl let-row="row">
  <div class="form__group">
    <div class="form__input">
      <textarea [(ngModel)]="row['notice']"
                class="textarea md-valid textarea-small" placeholder="Комментарий"></textarea>
    </div>
  </div>
  <button (click)="saveData(row)" class="button-small button">Сохранить</button>
</ng-template>

<ng-template #commentTmpl let-row="row">
  <p>{{row['status_caption']}}</p>
  <p>

    <ng-container *ngIf="row.wizard_step">
      <b>Шаг мастера: {{row['wizard_step']}}</b><br>
    </ng-container>
    <ng-container *ngIf="row.wizard_step_caption">
      ({{row['wizard_step_caption']}})
    </ng-container>
  </p>

  <button *ngFor="let elements of row.available_statuses" (click)="loadData(row['subagent_id'], elements.status_id, row['subagent_caption'], elements.caption, elements.description)"
          class="button-small button mb-2 mt-2 d-block">{{elements.caption}}</button>

</ng-template>

<app-attraction-status-change
  [subagent_id]="subagent_id"
  [subagent_caption]="subagent_caption"
  [subagent_type]="subagent_type"
  [window_caption]="window_caption"
  [window_text]="window_text"
  (onClosed)="WhenDataClosed()">
</app-attraction-status-change>
