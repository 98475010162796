import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'organisation_filter'
})
@Injectable()
export class CalendarTeachingPopupPipe implements PipeTransform {

  transform(value: any, search_string): any {
    const sort = (a, b) => {
      if (a.caption < b.caption) {
        return -1;
      }

      if (a.caption > b.caption) {
        return 1;
      }

      return 0;
    };

    if (search_string && search_string.length) {
      return value.sort(sort).filter(x => !x['caption'].toLowerCase().indexOf(search_string.toLowerCase()));
    }

    return value.sort(sort);
  }

}
