
<div class="container pb-4">
  <div class="card-deck text-center"
       *ngIf="!auth.isMenu('projects_flag')"
  >
    <app-control-index-card class="card mb-4 box-shadow"
                            [title]="'Физ лица'"
                            [description]="'Таблица со всеми физическими лицами системы'"
                            [url]="'/physical/list'"
    ></app-control-index-card>
    <app-control-index-card class="card mb-4 box-shadow"
                            [title]="'Юр лица'"
                            [description]="'Таблица со всеми юридическими лицами системы'"
                            [url]="'/entity/list'"
    ></app-control-index-card>
  </div>
  <div class="card-deck text-center"
       *ngIf="auth.isMenu('projects_flag')"
  >
    <app-control-index-card class="card mb-4 box-shadow"
                            [title]="'Планировки'"
                            [description]="'Помещения с незаполненными планировками'"
                            [url]="'../layouts'"
    ></app-control-index-card>
    <app-control-index-card class="card mb-4 box-shadow"
                            [title]="'Условия реализации'"
                            [description]="'Помещения с незаполненными условиями реализации'"
                            [url]="'../conditions'"
    ></app-control-index-card>
    <app-control-index-card class="card mb-4 box-shadow"
                            [title]="'Не связанные объекты'"
                            [description]="'Ожидающие связи объекты в фидах импорта без обязательных полей'"
                            [url]="'../not-imported'"
    ></app-control-index-card>
  </div>
  <div class="card-deck text-center">
    <app-control-index-card class="card mb-4 box-shadow"
                            *ngIf="auth.isAdmin()"
                            [title]="'XML импорт'"
                            [description]="'Проблемных импортов за последнюю неделю'"
                            [url]="'/import'"
                            [count]="counts['incorrect_import']"
                            [loading]="load"
    ></app-control-index-card>
    <app-control-index-card class="card mb-4 box-shadow"
                            *ngIf="auth.isMenu('instateka_is')"
                            [title]="'Ошибки Инстатека'"
                            [description]="'Таблица с ошибками методов сервиса Инстатека'"
                            [url]="'/instateka/errors'"
                            [count]="counts['not_resolve_error']"
                            [loading]="load"
    ></app-control-index-card>
    <app-control-index-card class="card mb-4 box-shadow"
                            *ngIf="auth.isFullAccess('projects_flag')"
                            [title]="'Регламенты и Акты'"
                            [description]="'ЖК с незаполненными регламентами или актами просмотра'"
                            [url]="'/mass/regl_table'"
                            [count]="counts['not_regl_or_act_complexes']"
                            [loading]="load_long"
    ></app-control-index-card>
    <app-control-index-card class="card mb-4 box-shadow"
                            *ngIf="!auth.isAdmin() && auth.isMenu('email_exception_is')"
                            [title]="'Email исключения'"
                            [description]="'Почты-исключения для блок-списка рассылки'"
                            [url]="'/email-exception'"
    ></app-control-index-card>
  </div>
  <div class="card-deck text-center">
    <app-control-index-card class="card mb-4 box-shadow"
                            *ngIf="auth.isAdmin()"
                            [title]="'Эст-а-тет ЖК'"
                            [description]="'Не установленные ассоциации ЖК систем Эст-а-тет и СПН24
                            (для корректной автоматической обработки квалификаций)'"
                            [url]="'/eat-complex-captions'"
                            [count]="counts['eat_not_assoc_complexes']"
                            [loading]="load"
    ></app-control-index-card>
    <app-control-index-card class="card mb-4 box-shadow"
                            *ngIf="auth.isAdmin()"
                            [title]="'Жалобы на объекты'"
                            [description]="'Необработанные жалобы на объекты вторичного рынка'"
                            [url]="'/complaints-datatable'"
                            [count]="counts['resale_complaints']"
                            [loading]="load"
    ></app-control-index-card>
  </div>


</div>
