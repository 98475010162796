import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient} from '../../../services/http.service';

@Injectable()
export class DataHousingAboutResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Housing.get_housing_information', {'housing_id': +route.params['id']});
  }

}
