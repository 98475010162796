import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '../../../services/http.service';
import { NotificationService } from '../../../services/notification.service';
import { Subscription } from 'rxjs';
import { MaterialSelect2Service } from '../../../modules/@material-input/component/material-select2.service';
import { debounceTime } from 'rxjs/operators';
import {BreadcrumbsService} from '../../../shared/breadcrumbs/services';


@Component({
  selector: 'app,yamap-physical-edit-common',
  templateUrl: './physical-edit-common.component.html',
  styleUrls: ['./physical-edit-common.component.scss']
})
export class PhysicalEditCommonComponent implements OnInit, OnDestroy {

  public data: any = null;
  public phone_main: any = null;
  public phones: Array<any> = [];
  public formGroup: UntypedFormGroup;
  public physicalId = null;
  private paramSubscribe: Subscription;
  private dataSubscribe: Subscription;
  public loading = true;

  constructor(public activeRoute: ActivatedRoute, public fb: UntypedFormBuilder, public http: HttpClient,
              public notify: NotificationService, private b: BreadcrumbsService,
              public select2Service: MaterialSelect2Service) {
  }

  ngOnInit() {
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/physical/list', caption: 'Физические лица'},
    ]);
    this.data = this.activeRoute.snapshot.data['data'];

    this.formGroup = this.fb.group({
      'physical_author': [{value: this.data['physical_author'], disabled: true}],
      'physical_author_id': [this.data['physical_author_id']],
      'physical_create_date': [{value: this.data['physical_create_date'], disabled: true}],
      'physical_data_rozhd': [this.data['physical_data_rozhd']],
      'physical_email': [this.data['physical_email'] ? this.data['physical_email'] : '' , Validators.email],
      'physical_fam': [this.data['physical_fam'], Validators.required],
      'physical_gender': [this.data['physical_gender'] ? this.data['physical_gender'] : '', Validators.required],
      'physical_name': [this.data['physical_name'], Validators.required],
      'physical_otch': [this.data['physical_otch'], Validators.required],
      'physical_phones': [this.data['physical_phones']],
      'phone_new': [''],
    });

    this.paramSubscribe = this.activeRoute.parent.paramMap.subscribe((params) => {
      this.physicalId = this.activeRoute.parent.snapshot.params['id'];
    });

    this.dataSubscribe = this.activeRoute.data.subscribe((data) => {
      if (this.loading) {
        this.loading = !this.loading;
      } else {
        this.data = data['data'];
        this.pathForm();
      }
      this.phones = this.data['physical_phones'];
      this.phones.forEach((phone) => {
        if (phone['phone_is_main']) {
          this.phone_main = phone['phone_id'];
        }
      });
    });
  }

  pathForm() {
    this.formGroup.patchValue({
      'physical_author': this.data['physical_author'],
      'physical_author_id': this.data['physical_author_id'],
      'physical_create_date': this.data['physical_create_date'],
      'physical_data_rozhd': this.data['physical_data_rozhd'],
      'physical_email': this.data['physical_email'],
      'physical_fam': this.data['physical_fam'],
      'physical_gender': this.data['physical_gender'],
      'physical_name': this.data['physical_name'],
      'physical_otch': this.data['physical_otch'],
      'physical_phones': this.data['physical_phones'],
      'phone_new': '',
    });
    this.select2Service.reload();
  }

  saveForm() {
    if (!this.formGroup.valid) {
      this.onError('Заполните обязательные поля');
      return false;
    }
    const post_data = {
      'physical_fam': this.formGroup.value.physical_fam,
      'physical_name': this.formGroup.value.physical_name,
      'physical_otch': this.formGroup.value.physical_otch,
      'physical_data_rozhd': this.formGroup.value.physical_data_rozhd,
      'physical_gender': this.formGroup.value.physical_gender,
      'physical_email': this.formGroup.value.physical_email,
    };
    this.http.post('Physical.save_physical_common',
      {'physical_id': +this.physicalId, 'data': post_data}
    ).subscribe(
      (response) => this.onSuccess(response),
      (error) => this.onError(error)
    );
  }

  phoneDelete(phone: any) {
    this.http.post('Phones.del_phone',
      {'phone_id': phone['phone_id']}
    ).subscribe(
      (response) => {
        this.phones.splice(this.phones.indexOf(phone), 1);
      },
      _ => {}
    );
  }

  phoneMain(phone_id: any) {
    this.http.post('Phones.save_is_main',
      {'phone_id': phone_id}
    ).subscribe(
      (response) => {
        this.phone_main = phone_id;
      },
      _ => {}
    );
  }

  phoneCreate() {
    if (this.formGroup.value.phone_new.length < 18) {
      this.onError('Неверный номер телефона');
      return false;
    }
    this.http.post('Phones.add_phone_phy',
      {'physical_id': +this.physicalId, 'phone': this.formGroup.value.phone_new}
    ).subscribe(
      (response) => {
        this.onSuccess(response);
        this.phones.push({
          'phone': this.formGroup.value.phone_new,
          'phone_id': response,
          'phone_is_main': null,
        });
        this.formGroup.get('phone_new').setValue('');
      },
      (error) => { }
    );
  }

  onSuccess(response) {
    this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
  }

  onError(error) {
    this.notify.notifyError('Ошибка', error, 5000);
  }

  ngOnDestroy() {
    if (this.paramSubscribe) {
      this.paramSubscribe.unsubscribe();
    }
    if (this.dataSubscribe) {
      this.dataSubscribe.unsubscribe();
    }
  }

}
