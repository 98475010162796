<h3 *ngIf="a.snapshot.params['fact_type'] === 'create_lead'">По созданию лидов</h3>
<h3 *ngIf="a.snapshot.params['fact_type'] === 'db'">По ДБ</h3>
<h3 *ngIf="a.snapshot.params['fact_type'] === 'finish'">По завершению БП</h3>

<div class="row align-items-center form_crm">
  <ng-container *ngIf="data['boss_info']">
    <div class="col-md-4">
      <app-analytic-filter
        [base_url]="base_url"
        [lock_type]="url_segment">
      </app-analytic-filter>
    </div>
    <div class="col-md-8">
      <div class="panel my-3">
        <div class="row">
          <div class="col-auto">
            <ng-container *ngIf="data['boss_info']['boss_fio']">
              <p class="mb-2"><span class="font-weight-bold">ФИО руководителя :</span> {{data['boss_info']['boss_fio']}}</p>
            </ng-container>
            <ng-container *ngIf="data['boss_info']['boss_phone']">
              <p class="mb-2"><span class="font-weight-bold">Телефон руководителя :</span> {{data['boss_info']['boss_phone']}}</p>
            </ng-container>
            <ng-container *ngIf="data['boss_info']['boss_email']">
              <p class="mb-2"><span class="font-weight-bold">Email руководителя :</span> {{data['boss_info']['boss_email']}}</p>
            </ng-container>
            <ng-container *ngIf="data['boss_info']['date_activation']">
              <p class="mb-0"><span class="font-weight-bold">Дата активации :</span> {{data['boss_info']['date_activation']}}</p>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!data['boss_info']">
    <div class="col-md-12">
      <app-analytic-filter
        [base_url]="base_url"
        [lock_type]="url_segment">
      </app-analytic-filter>
    </div>
  </ng-container>

</div>
<ng-container *ngIf="url_segment === 'report-get-complex-activity'">
  <div>
    <a href="{{server}}restful/get_complex_activity?events={{a.snapshot.params['events'] ? a.snapshot.params['events'] : ''}}&vitrina={{a.snapshot.params['vitrina'] ? a.snapshot.params['vitrina'] : ''}}&complex_type={{a.snapshot.params['complex_type'] ? a.snapshot.params['complex_type'] : ''}}&date_start={{date_options['date_start']}}&date_end={{date_options['date_end']}}&result_type={{a.snapshot.params['result_type']}}&fact_type={{a.snapshot.params['fact_type']}}" target="_blank" class="button float-right">Скачать отчёт</a>
  </div>
</ng-container>
<ng-container *ngIf="url_segment !== 'report-get-complex-activity'">
  <div>
    <a href="{{server}}restful/get_sub_inactive?date_start={{date_options['date_start']}}&date_end={{date_options['date_end']}}&sub_date_from={{a.snapshot.params['sub_date_from'] ? a.snapshot.params['sub_date_from'] : ''}}&result_type={{a.snapshot.params['result_type']}}&fact_type={{a.snapshot.params['fact_type']}}" target="_blank" class="button float-right">Отчёт неактивные СА</a>
    <a href="{{server}}restful/get_sub_activity?date_start={{date_options['date_start']}}&date_end={{date_options['date_end']}}&sub_date_from={{a.snapshot.params['sub_date_from'] ? a.snapshot.params['sub_date_from'] : ''}}&result_type={{a.snapshot.params['result_type']}}&fact_type={{a.snapshot.params['fact_type']}}" target="_blank" class="button float-right mr-2">Отчёт активные СА</a>
  </div>
</ng-container>


<div class="legend">
  <div class="mr-3 d-inline-flex">
    <span class="legend__dot green"></span> Есть лиды
  </div>
  <ng-container *ngIf="url_segment === 'report-get-complex-activity'">
    <div class="mr-3 d-inline-flex">
      <span class="legend__dot dark_green"></span> Есть лиды от пользователей с обучения
    </div>
    <div class="mr-3 d-inline-flex">
      <span class="legend__dot yellow"></span> Есть события
    </div>
  </ng-container>
</div>



<ng-container *ngIf="url_segment === 'report-get-complex-activity'">

  <div class="mt-3">



    <app-easy-dropdown [cssClass]="'w100 max-height-none'" [activeDropdown]="activeDropdown"
                       [minusPadding]="true"
                       [titleText]="'Всего ЖК'">
      <ng-template #header>
        <b>{{this.summStatisticList['allComplex']}}</b>
      </ng-template>
      <div class="row mt-3">
        <div class="col-auto my-1 no-wrap">
          кол-во заявок: <b>{{this.summStatisticList['total_data']}}</b>
        </div>
        <div class="col-auto my-1 no-wrap">
          кол-во мероприятий: <b>{{this.summStatisticList['total_event_data']}}</b>
        </div>
        <div class="col-auto my-1 no-wrap">
          кол-во заявок после обучения: <b>{{this.summStatisticList['total_data_by_events']}}</b>
        </div>
      </div>
      <ng-container *ngIf="this.summStatisticList['sorted']">
        <div class="row">
          <div class="col-12 my-1">
            <span class="c-red">Из них</span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngFor="let obj of this.summStatisticList['sorted']">
        <div class="row">
          <div class="col-md-auto col-12 my-1 no-wrap">
            <b>{{this.obj['caption']}}: </b>
          </div>
          <div class="col-auto my-1 no-wrap">
            ЖК: <b>{{this.obj['allComplex']}}</b>
          </div>
          <div class="col-auto my-1 no-wrap">
            кол-во заявок: <b>{{this.obj['total_data']}}</b>
          </div>
          <div class="col-auto my-1 no-wrap">
            кол-во мероприятий: <b>{{this.obj['total_event_data']}}</b>
          </div>
          <div class="col-auto my-1 no-wrap">
            кол-во заявок после обучения: <b>{{this.obj['total_data_by_events']}}</b>
          </div>
        </div>
      </ng-container>
    </app-easy-dropdown>
  </div>
  <div>
    <app-easy-dropdown [cssClass]="'w100 max-height-none'" [activeDropdown]="activeDropdown"
                       [minusPadding]="true"
                       [titleText]="'Пояснение значений таблицы'">
      <div class="row align-items-center">
        <div class="col-md-auto mb-3">
          <img class="img-fluid" src="/assets_crm/images/help.png" alt="">
        </div>
        <div class="col-md-auto mb-3">
          <p class="mb-2"><b>1</b> - Кол-во мероприятий на этом ЖК за <b>всё время</b> / <b>за месяц</b></p>
          <p class="mb-2"><b>2</b> - Кол-во лидов всего на этом ЖК за <b>всё время</b> / <b>за месяц</b></p>
          <p class="mb-2"><b>3</b> - Кол-во лидов непосредственно с обучения на этом ЖК за <b>всё время</b> / <b>за месяц</b></p>
          <p class="mb-2"><b>4</b> - Кол-во <b>лидов за месяц на этом ЖК</b> / <b>из них с обучения</b></p>
          <p class="mb-2"><b>5</b> - Событий за месяц</p>
          <p class="mb-2"><b>6</b> - Стрелка <b>вверх</b> обозначает <b>положительную</b> динамику лидов за данный месяц</p>
          <p class="mb-2"><b>7</b> - Стрелка <b>вниз</b> обозначает <b>отрицательную</b> динамику лидов за данный месяц</p>
          <p class="mb-2"><b>8</b> - Знак <b>«–»</b> обозначает отсутствие событий и лидов за данный месяц</p>
          <p class="mb-2"><b>9</b> - График динамики лидов по ЖК относительно кол-ва мероприятий обучения</p>
        </div>
      </div>
    </app-easy-dropdown>
  </div>
</ng-container>




<div class="mb-5 max-overflow">
  <div class="pb-2 position-relative">
    <ng-container *ngIf="preloader">
      <div class="loader-wrapper loader-wrapper__absolute">
        <app-preloader></app-preloader>
        <p class="text-center texthelp">Идёт загрузка</p>
      </div>
    </ng-container>
    <table class="table" [ngClass]="{'loader-opacity': preloader}">
      <thead>
      <tr>
        <th>Название</th>
        <ng-container *ngIf="url_segment === 'report-get-complex-activity'">
          <ng-container *ngFor="let el of data['keys']; index as i;">
            <th class="text-center">
              <a class="d-flex align-items-center" [routerLink]="filter_url"
                 [queryParams]="{'sort_field': i,
                               'sort_direction': next_sort_direction}">
                <span class="text-left" [innerHtml]="el"></span>
                <i class="fa fa-sort ml-auto"></i>
              </a>
            </th>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="url_segment === 'report-get-sub-user-activity' || url_segment === 'report-get-sub-activity'">
          <th class="text-center">
            Последний комментарий
          </th>
          <ng-container *ngFor="let el of data['keys']; index as i;">
            <th class="text-center">
              <a class="d-flex align-items-center" [routerLink]="filter_url"
                 [queryParams]="{'sort_field': i,
                               'sort_direction': next_sort_direction}">
                <span class="text-left" [innerHtml]="el"></span>
                <i class="fa fa-sort ml-auto"></i>
              </a>
            </th>
          </ng-container>
        </ng-container>
      </tr>
      </thead>
      <tbody>

      <ng-container *ngIf="org">
        <ng-container *ngFor="let el of org['groups']">
          <ng-container *ngIf="el['sources'].length">
            <tr *ngFor="let source of el['sources']">
              <td class="font-weight-bold">
                <div [ngClass]="{'activeBox analytic': goBack.activeHash === 'org-' + source['id']}">
                  <a href="/entity/edit/{{source['id']}}/common"
                     (click)="goBack.newLink('/entity/edit/' + source['id'] + '/common', 'org-' + source['id'], $event)">
                    {{source['caption']}}
                  </a>
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <a (click)="addComent(source['id'])" class="d-flex align-items-center c-pointer"><i class="form__input_icon icon icon_Small_Plus mr-1"></i>коммент.</a>
                  <ng-container *ngIf="source['have_comments']">
                    <div class="d-flex align-items-center ml-1 c-pointer" (click)="commentListLoad(source['id'], source['caption'])">
                      <i class="round color-round mr-1" [ngClass]="source['last_comment']['color_active_status']"></i> история
                    </div>
                  </ng-container>
                </div>
              </td>
              <td *ngFor="let num of source['data']; let count=index;" [ngClass]="{'td-green': num}">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'lead-org-' + count + '-' + source['id']}">
                  <div class="d-flex align-items-center">
                    <ng-container *ngIf="num">
                      <a href="/analytic/inner-lead/table?method=get_sub_activity&date_start={{date_options['date_start']}}&date_end={{date_options['date_end']}}&sub_date_from={{a.snapshot.params['sub_date_from'] ? a.snapshot.params['sub_date_from'] : ''}}&result_type={{a.snapshot.params['result_type']}}&fact_type={{a.snapshot.params['fact_type']}}&gap={{data['raw_keys'][count]}}&only_subagent_id={{source['id']}}"
                         (click)="goBack.newLink('/analytic/inner-lead/table?method=get_sub_activity'
                           + '&date_start=' + date_options['date_start']
                           + '&date_end=' + date_options['date_end']
                           + '&sub_date_from=' + (a.snapshot.params['sub_date_from'] ? a.snapshot.params['sub_date_from'] : '')
                           + '&result_type=' + a.snapshot.params['result_type']
                           + '&fact_type=' + a.snapshot.params['fact_type']
                           + '&gap=' + data['raw_keys'][count]
                           + '&only_subagent_id=' + source['id'],
                           'lead-org-' + count + '-' + source['id'], $event)">
                        {{num}}
                      </a>
                    </ng-container>
                    <ng-container *ngIf="!num">
                      {{num}}
                    </ng-container>
                    <ng-container *ngIf="source['data_comments'][count].length">
                      <div class="d-inline-flex color-holder" (click)="rightWindowCommentList(source['caption'], source['data_comments'][count])">
                        <ng-container *ngFor="let item of source['data_comments'][count] | slice:0:3; let i=index">
                          <i class="round color-round small-round" [ngClass]="item.color_active_status"></i>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </td>

            </tr>
            <tr class="head-big">
              <td [attr.colspan]="org['keys'].length + 2">
                Сотрудники
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngFor="let el of data['groups']">
        <ng-container *ngIf="el['sources'].length">
          <tr class="head-sm">
            <td [attr.colspan]="data['keys'].length + 3">
              {{el.caption}}
            </td>
          </tr>
          <ng-container *ngIf="url_segment !== 'report-get-complex-activity'">
            <tr *ngFor="let source of el['sources']">
              <ng-container *ngIf="url_segment !== 'report-get-sub-activity'">
                <td>
                  <div [ngClass]="{'activeBox analytic': goBack.activeHash === 'subagent-' + source['id']}">
                    <i class="form__input_icon icon icon_Small_Edit c-pointer mr-2" (click)="rightWindowOpen(source['caption'], source['id'], source['contact_block'])"></i>
                    <a href="/physical/edit/{{source['id']}}/common"
                       (click)="goBack.newLink('/physical/edit/' + source['id'] + '/common', 'subagent-' + source['id'], $event)">
                      {{source['caption']}}
                    </a>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <a (click)="addComent(a.snapshot.params['subagent_id'], source['id'])" class="d-flex align-items-center c-pointer"><i class="form__input_icon icon icon_Small_Plus mr-1"></i>коммент.</a>
                    <ng-container *ngIf="source['have_comments']">
                      <div class="d-flex align-items-center ml-1 c-pointer" (click)="commentListLoad(a.snapshot.params['subagent_id'], source['caption'], source['id'])">
                        <i class="round color-round mr-1" [ngClass]="source['last_comment']['color_active_status']"></i> история
                      </div>
                    </ng-container>
                  </div>
                </td>
                <td *ngFor="let num of source['data']; let count=index;" [ngClass]="{'td-green': num}">
                  <div [ngClass]="{'activeBox': goBack.activeHash === 'lead-org-' + count + '-' + source['id']}">
                    <div class="d-flex align-items-center">
                      <ng-container *ngIf="num">
                        <a href="/analytic/inner-lead/table?method=get_sub_activity&date_start={{date_options['date_start']}}&date_end={{date_options['date_end']}}&sub_date_from={{a.snapshot.params['sub_date_from'] ? a.snapshot.params['sub_date_from'] : ''}}&result_type={{a.snapshot.params['result_type']}}&fact_type={{a.snapshot.params['fact_type']}}&gap={{data['raw_keys'][count]}}&employee_id={{source['id']}}&only_subagent_id="
                           (click)="goBack.newLink('/analytic/inner-lead/table?method=get_sub_activity'
                           + '&date_start=' + date_options['date_start']
                           + '&date_end=' + date_options['date_end']
                           + '&sub_date_from=' + (a.snapshot.params['sub_date_from'] ? a.snapshot.params['sub_date_from'] : '')
                           + '&result_type=' + a.snapshot.params['result_type']
                           + '&fact_type=' + a.snapshot.params['fact_type']
                           + '&gap=' + data['raw_keys'][count]
                           + '&employee_id=' + source['id']
                           + '&only_subagent_id=',
                           'lead-org-' + count + '-' + source['id'], $event)">
                          {{num}}
                        </a>
                      </ng-container>
                      <ng-container *ngIf="!num">
                        {{num}}
                      </ng-container>
                      <ng-container *ngIf="source['data_comments'][count].length">
                        <div class="d-inline-flex color-holder" (click)="rightWindowCommentList(source['caption'], source['data_comments'][count])">
                          <ng-container *ngFor="let item of source['data_comments'][count] | slice:0:3; let i=index">
                            <i class="round color-round small-round" [ngClass]="item.color_active_status"></i>
                          </ng-container>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-container *ngIf="url_segment === 'report-get-sub-activity'">
                <td>
                  <div [ngClass]="{'activeBox analytic': goBack.activeHash === 'subagent-' + source['id']}">
                    <i class="form__input_icon icon icon_Small_Edit c-pointer mr-2" (click)="rightWindowOpen(source['caption'], source['id'], source['contact_block'], source['id'])"></i>
                    <a href="/analytic/report-get-sub-user-activity/{{source['id']}}/{{a.snapshot.params['fact_type']}}/{{a.snapshot.params['result_type']}}/{{a.snapshot.params['sub_date_from']}}/{{date_options['date_start']}}/{{date_options['date_end']}}/"
                       (click)="goBack.newLink('/analytic/report-get-sub-user-activity/' + source['id'] + '/'
                     + a.snapshot.params['fact_type'] + '/' + a.snapshot.params['result_type']
                     + '/' + (date_options['date_start'] ? date_options['date_start'] : '')
                     + '/' + (date_options['date_end'] ? date_options['date_end'] : '')
                     + '/' + (a.snapshot.params['sub_date_from'] ? a.snapshot.params['sub_date_from'] : ''), 'subagent-' + source['id'], $event)">
                      {{source['caption']}}
                    </a>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <a (click)="addComent(source['id'])" class="d-flex align-items-center c-pointer"><i class="form__input_icon icon icon_Small_Plus mr-1"></i>коммент.</a>
                    <ng-container *ngIf="source['have_comments']">
                      <div class="d-flex align-items-center ml-1 c-pointer" (click)="commentListLoad(source['id'], source['caption'])">
                        <i class="round color-round mr-1" [ngClass]="source['last_comment']['color_active_status']"></i> история
                      </div>
                    </ng-container>
                  </div>
                </td>
                <td *ngFor="let num of source['data']; let count=index;" [ngClass]="{'td-green': num}">
                    <div [ngClass]="{'activeBox': goBack.activeHash === 'lead-org-' + count + '-' + source['id']}">
                      <div class="d-flex align-items-center">
                        <ng-container *ngIf="num">
                          <a href="/analytic/inner-lead/table?method=get_sub_activity&date_start={{date_options['date_start']}}&date_end={{date_options['date_end']}}&sub_date_from={{a.snapshot.params['sub_date_from'] ? a.snapshot.params['sub_date_from'] : ''}}&result_type={{a.snapshot.params['result_type']}}&fact_type={{a.snapshot.params['fact_type']}}&gap={{data['raw_keys'][count]}}&only_subagent_id={{source['id']}}"
                             (click)="goBack.newLink('/analytic/inner-lead/table?method=get_sub_activity'
                           + '&date_start=' + date_options['date_start']
                           + '&date_end=' + date_options['date_end']
                           + '&sub_date_from=' + (a.snapshot.params['sub_date_from'] ? a.snapshot.params['sub_date_from'] : '')
                           + '&result_type=' + a.snapshot.params['result_type']
                           + '&fact_type=' + a.snapshot.params['fact_type']
                           + '&gap=' + data['raw_keys'][count]
                           + '&only_subagent_id=' + source['id'],
                           'lead-org-' + count + '-' + source['id'], $event)">
                            {{num}}
                          </a>
                        </ng-container>
                        <ng-container *ngIf="!num">
                          {{num}}
                        </ng-container>
                        <ng-container *ngIf="source['data_comments'][count].length">
                          <div class="d-inline-flex color-holder" (click)="rightWindowCommentList(source['caption'], source['data_comments'][count])">
                            <ng-container *ngFor="let item of source['data_comments'][count] | slice:0:3; let i=index">
                              <i class="round color-round small-round" [ngClass]="item.color_active_status"></i>
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                </td>
              </ng-container>
            </tr>
          </ng-container>
          <ng-container *ngIf="url_segment === 'report-get-complex-activity'">
            <ng-container *ngFor="let source of el['sources']; let ind=index">
              <tr>
                <td class="border-bottom-0">
                  <div class="d-flex">
                    <div [ngClass]="{'activeBox analytic': goBack.activeHash === 'complex-' + source['id']}">
                      <a href="/housing2/complex/{{source['id']}}"
                         (click)="goBack.newLink('/housing2/complex/' + source['id'], 'complex-' + source['id'], $event)">
                        {{source['caption']}}
                      </a>
                    </div>
                    <ng-container *ngIf="source['total_data_on_period']">
                      <div class="ml-4" [ngClass]="{'activeBox analytic': goBack.activeHash === 'graphic-' + source['id']}">
                        <a href="/analytic/report-get-activity-complex-id/{{a.snapshot.params['fact_type']}}/{{a.snapshot.params['result_type']}}/{{date_options['date_start']}}/{{date_options['date_end']}}/{{source['id']}}/"
                           (click)="goBack.newLink('/analytic/report-get-activity-complex-id/'
                         + a.snapshot.params['fact_type'] + '/'
                         + a.snapshot.params['result_type'] + '/'
                         + date_options['date_start'] + '/'
                         + date_options['date_end'] + '/'
                         + source['id'],
                         'graphic-' + source['id'], $event)">
                          График
                        </a>
                      </div>
                    </ng-container>
                    <div class="ml-auto">События :</div>
                  </div>

                </td>
                <td *ngFor="let num of source['event_data']; let ind = index" [ngClass]="{'td-yellow': num}">
                  <ng-container *ngIf="num">
                    <div [ngClass]="{'activeBox analytic': goBack.activeHash === 'event-' + ind + '-' + source['id']}">
                      <a href="/calendar/statistic/{{date_options['date_start']}}/{{date_options['date_end']}}/any?event_complexes={{source['caption']}}"
                         (click)="goBack.newLink('/calendar/statistic/'
                         + date_options['date_start'] + '/'
                         + date_options['date_end'] + '/any?event_complexes='
                         + source['caption'],
                         'event-' + ind + '-' + source['id'], $event)">
                        {{num}}
                      </a>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!num">
                    -
                  </ng-container>
                </td>
              </tr>
              <tr>
                <td class="text-right border-top-0">
                  <div class="d-flex w-100">
                    <div class="mr-2">
                      Событий:
                      <b>{{source['total_event_data']}} / {{source['total_event_data_on_period']}}</b>
                    </div>
                    <div class="mr-2">
                      Лидов:
                      <b>{{source['total_data']}} / {{source['total_data_on_period']}}</b>
                    </div>
                    <div class="mr-2">
                      с обучения:
                      <b>{{source['total_data_by_events']}} / {{source['total_data_by_events_on_period']}}</b>
                    </div>
                    <div class="text-right ml-auto">
                      Лиды :
                    </div>
                  </div>
                </td>
                <td *ngFor="let num of source['data']; let ind = index" [ngClass]="{'td-dark-green': source['data_by_events'][ind], 'td-green': num}" td_compare [attr.data-compare] = 'num'>
                  <ng-container *ngIf="num">
                    <div [ngClass]="{'activeBox analytic': goBack.activeHash === 'lead-org-' + ind + '-' + source['id']}">
                      <a href="/analytic/inner-lead/table?method=get_complex_activity&date_start={{date_options['date_start']}}&date_end={{date_options['date_end']}}&result_type={{a.snapshot.params['result_type']}}&fact_type={{a.snapshot.params['fact_type']}}&gap={{data['raw_keys'][ind]}}&complex_id={{source['id']}}"
                         (click)="goBack.newLink('/analytic/inner-lead/table?method=get_complex_activity'
                             + '&date_start=' + date_options['date_start']
                             + '&date_end=' + date_options['date_end']
                             + '&result_type=' + a.snapshot.params['result_type']
                             + '&fact_type=' + a.snapshot.params['fact_type']
                             + '&gap=' + data['raw_keys'][ind]
                             + '&complex_id=' + source['id'],
                             'lead-org-' + ind + '-' + source['id'], $event)">
                        <span class="hover-span" title="Всего лидов">{{num}}</span>
                        <ng-container *ngIf="source['data_by_events'][ind]">
                          / <span class="hover-span" title="Лидов от пользователей, которые присутствовали на обучении"> {{source['data_by_events'][ind]}}</span>
                        </ng-container>
                      </a>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!num">
                    -
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      </tbody>
    </table>
  </div>
</div>


<app-right-window
  [data]="rightWindow"
  (onClosedRight)="rightWindowClose()">
  <ng-container *ngIf="rightWindowData['caption']">
    <h1>{{rightWindowData['caption']}}</h1>
  </ng-container>

  <ng-container *ngIf="rightWindowData['data']">
    <div class="panel my-3">
      <ng-container *ngIf="rightWindowData['data']['boss_fio']">
        <p><span class="font-weight-bold">ФИО руководителя :</span> {{rightWindowData['data']['boss_fio']}}</p>
      </ng-container>
      <ng-container *ngIf="rightWindowData['data']['boss_phone']">
        <p><span class="font-weight-bold">Телефон руководителя :</span> {{rightWindowData['data']['boss_phone']}}</p>
      </ng-container>
      <ng-container *ngIf="rightWindowData['data']['boss_email']">
        <p><span class="font-weight-bold">Email руководителя :</span> {{rightWindowData['data']['boss_email']}}</p>
      </ng-container>
      <ng-container *ngIf="rightWindowData['data']['date_activation']">
        <p><span class="font-weight-bold">Дата активации :</span> {{rightWindowData['data']['date_activation']}}</p>
      </ng-container>
      <ng-container *ngIf="rightWindowData['data']['date_create']">
        <p><span class="font-weight-bold">Дата создания :</span> {{rightWindowData['data']['date_create']}}</p>
      </ng-container>
      <ng-container *ngIf="rightWindowData['data']['email']">
        <p><span class="font-weight-bold">Email сотрудника :</span> {{rightWindowData['data']['email']}}</p>
      </ng-container>
      <ng-container *ngIf="rightWindowData['data']['fio']">
        <p><span class="font-weight-bold">ФИО сотрудника :</span> {{rightWindowData['data']['fio']}}</p>
      </ng-container>
      <ng-container *ngIf="rightWindowData['data']['phone']">
        <p><span class="font-weight-bold">Телефон сотрудника :</span> {{rightWindowData['data']['phone']}}</p>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="rightWindowData['employee']">
    <a href="/entity/edit/{{rightWindowData['id']}}/common"
        (click)="goBack.newLink('/entity/edit/' + rightWindowData['id'] + '/common', 'subagent-' + rightWindowData['id'], $event)"
        class="d-inline-block underline">
      Перейти в карточку организации
    </a>
    <div style="overflow: auto" class="mb-5">
      <div class="pb-2">
        <p class="mb-1">Активность сотрудников за последние <span class="font-weight-bold ">2 месяца</span> выбранного периода</p>
        <p>Для просмотра активности сотрудников за весь период перейдите
          <a href="/analytic/report-get-sub-user-activity/{{rightWindowData['id']}}/{{a.snapshot.params['fact_type']}}/{{a.snapshot.params['result_type']}}/{{a.snapshot.params['sub_date_from']}}/{{date_options['date_start']}}/{{date_options['date_end']}}/"
             (click)="goBack.newLink('/analytic/report-get-sub-user-activity/' + rightWindowData['id'] + '/'
                     + a.snapshot.params['fact_type'] + '/' + a.snapshot.params['result_type']
                     + '/' + (date_options['date_start'] ? date_options['date_start'] : '')
                     + '/' + (date_options['date_end'] ? date_options['date_end'] : '')
                     + '/' + (a.snapshot.params['sub_date_from'] ? a.snapshot.params['sub_date_from'] : ''), 'subagent-' + rightWindowData['id'], $event)">
            по ссылке
          </a>
        </p>
        <table class="table">
          <thead>
          <tr>
            <th>Название</th>
            <ng-container *ngFor="let el of rightWindowData['employee']['keys']; index as i;">
              <th class="text-center">
                <span class="text-left" [innerHtml]="el"></span>
              </th>
            </ng-container>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let el of rightWindowData['employee']['groups']">
            <ng-container *ngIf="el['sources'].length">
              <tr class="head-sm">
                <td [attr.colspan]="rightWindowData['employee']['keys'].length + 1">
                  {{el.caption}}
                </td>
              </tr>
              <tr *ngFor="let source of el['sources']">
                <td>{{source['caption']}}</td>
                <td *ngFor="let num of source['data']" [ngClass]="{'td-green': num}">
                  {{num}}
                </td>
              </tr>
            </ng-container>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="rightWindowComment['comment']">
    <h2 class="mt-2">Создание комментария</h2>
    <form [formGroup]="formGroup" novalidate>
      <div class="row">
        <div class="col-12">
          <app-material-input-radio-button [require]="true" formControlName="only_this_report"
                                           [data]="{true: 'Только для этого отчёта', false: 'Для всех отчетов'}">
            Переключатель комментария
          </app-material-input-radio-button>
        </div>
        <div class="col-12">
          <app-material-input-radio-button [cssClass]="'listcolors'" [require]="true" formControlName="color_active_status"
                                           [data]="{'green': 'Договорённость о возобновлении сотрудничества', 'yellow': 'Переговоры по возобновлении сотрудничества', 'red': 'Отказ от сотрудничества'}">
            Переключатель комментария
          </app-material-input-radio-button>
        </div>
        <div class="col-12">
          <app-material-input-datepicker [require]="true" formControlName="time_of_comment">
            Дата комментария</app-material-input-datepicker>
        </div>
        <div class="col-12">
          <app-material-textarea [rows]="6" [require]="true" [placeholder]="'Введите комментарий'"
                                 formControlName="comment">
            Комментарий
          </app-material-textarea>
        </div>
        <div class="col-12">
          <hr>
        </div>
        <div class="col-12 col-md-6 my-3">
          <app-material-textarea [rows]="4" [placeholder]="'Работа с системой СПН'"
                                 formControlName="work_with_system_spn">
          </app-material-textarea>
          <app-material-select formControlName="work_with_system_status" [caption]="'Статус'">
            <option [value]="''">-- Не выбрано --</option>
            <option [value]="'хорошо'">Хорошо</option>
            <option [value]="'удовлетворительно'">Удовлетворительно</option>
            <option [value]="'неудовлетворительно'">Неудовлетворительно</option>
          </app-material-select>
        </div>
        <div class="col-12 col-md-6 my-3">
          <app-material-textarea [rows]="4" [placeholder]="'Сервисность сотрудников СПН'"
                                 formControlName="service_staff">
          </app-material-textarea>
          <app-material-select formControlName="service_staff_status" [caption]="'Статус'">
            <option [value]="''">-- Не выбрано --</option>
            <option [value]="'хорошо'">Хорошо</option>
            <option [value]="'удовлетворительно'">Удовлетворительно</option>
            <option [value]="'неудовлетворительно'">Неудовлетворительно</option>
          </app-material-select>
        </div>
        <div class="col-12 col-md-6 my-3">
          <app-material-textarea [rows]="4" [placeholder]="'Сервисность застройщика при просмотре (работа менеджеров в ОП)'"
                                 formControlName="developer_service">
          </app-material-textarea>
          <app-material-select formControlName="developer_service_status" [caption]="'Статус'">
            <option [value]="''">-- Не выбрано --</option>
            <option [value]="'хорошо'">Хорошо</option>
            <option [value]="'удовлетворительно'">Удовлетворительно</option>
            <option [value]="'неудовлетворительно'">Неудовлетворительно</option>
          </app-material-select>
        </div>
        <div class="col-12 col-md-6 my-3">
          <app-material-textarea [rows]="4" [placeholder]="'Выплаты'"
                                 formControlName="payments">
          </app-material-textarea>
          <app-material-select formControlName="payments_status" [caption]="'Статус'">
            <option [value]="''">-- Не выбрано --</option>
            <option [value]="'хорошо'">Хорошо</option>
            <option [value]="'удовлетворительно'">Удовлетворительно</option>
            <option [value]="'неудовлетворительно'">Неудовлетворительно</option>
          </app-material-select>
        </div>
        <div class="col-12">
          <button type="button" button (click)="saveForm()" class="button" [disabled]="!formGroup.valid">Сохранить комментарий</button>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="rightWindowListData['data']">
    <h2 class="mt-2">{{rightWindowListData['caption']}}</h2>
    <div class="panel" *ngFor="let data of rightWindowListData['data']">
      <div class="d-flex justify-content-center">
        <div class="color-round" [ngClass]="data['color_active_status']"></div>
        <p><span class="font-weight-bold">{{data['time_of_comment']}}</span></p>
        <i class="icon icon_Small_Close ml-auto c-pointer" (click)="commentDelete(data['id'])"></i>
      </div>
      <h4>{{data['author_fio']}}</h4>
      <div class="mt-3">{{data['comment']}}</div>

      <div class="mt-3 comm-block" *ngIf="data['work_with_system_status'] || data['work_with_system_spn']">
        <div class="d-flex align-items-center">
          <div class="color-round" [ngClass]="{'red': data['work_with_system_status'] === 'неудовлетворительно', 'yellow': data['work_with_system_status'] === 'удовлетворительно', 'green': data['work_with_system_status'] === 'хорошо'}"></div>
          <p class="font-weight-bold mb-0">Работа с системой СПН</p>
        </div>
        {{data['work_with_system_spn']}}
      </div>
      <div class="mt-3 comm-block" *ngIf="data['service_staff_status'] || data['service_staff']">
        <div class="d-flex align-items-center">
          <div class="color-round" [ngClass]="{'red': data['service_staff_status'] === 'неудовлетворительно', 'yellow': data['service_staff_status'] === 'удовлетворительно', 'green': data['service_staff_status'] === 'хорошо'}"></div>
          <p class="font-weight-bold mb-0">Сервисность сотрудников СПН</p>
        </div>
        {{data['service_staff']}}
      </div>
      <div class="mt-3 comm-block" *ngIf="data['developer_service_status'] || data['developer_service']">
        <div class="d-flex align-items-center">
          <div class="color-round" [ngClass]="{'red': data['developer_service_status'] === 'неудовлетворительно', 'yellow': data['developer_service_status'] === 'удовлетворительно', 'green': data['developer_service_status'] === 'хорошо'}"></div>
          <p class="font-weight-bold mb-0">Сервисность застройщика при просмотре (работа менеджеров в ОП)</p>
        </div>
        {{data['developer_service']}}
      </div>
      <div class="mt-3 comm-block" *ngIf="data['payments_status'] || data['payments']">
        <div class="d-flex align-items-center">
          <div class="color-round" [ngClass]="{'red': data['payments_status'] === 'неудовлетворительно', 'yellow': data['payments_status'] === 'удовлетворительно', 'green': data['payments_status'] === 'хорошо'}"></div>
          <p class="font-weight-bold mb-0">Выплаты</p>
        </div>
        {{data['payments']}}
      </div>
    </div>
  </ng-container>

</app-right-window>
