<h1 *ngIf="teached">Список организаций, прошедших обучение</h1>
<h1 *ngIf="!teached">Список организаций, не прошедших обучение</h1>
<br>
<div class="form__group">
  <div class="form__input">
    <input class="input" type="text" placeholder="Поиск" [(ngModel)]="search_string">
  </div>
</div>
<app-simple-table *ngIf="data">
  <app-simple-table-th>Название организации</app-simple-table-th>
  <app-simple-table-th>Руководитель</app-simple-table-th>
  <app-simple-table-th>Телефон</app-simple-table-th>
  <app-simple-table-th>E-mail</app-simple-table-th>
  <!-- // -->
  <app-simple-table-tr *ngFor="let row of data | organisation_filter: search_string">
    <td>{{row['caption']}}</td>
    <td>{{row['boss_fio']}}</td>
    <td>{{row['boss_phone']}}</td>
    <td>{{row['boss_email']}}</td>
  </app-simple-table-tr>
</app-simple-table>
