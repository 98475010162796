import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {LockerService} from '../../services/locker.service';
import { NotificationService } from '../../services/notification.service';
import { HttpClient } from '../../services/http.service';
import { AuthenticateService } from '../../services/authenticate.service';
import { Router } from '@angular/router';
import { NavigationLoaderService } from '../navigation-loader/navigation-loader.service';

@Component({
  selector: 'app-locker-component',
  templateUrl: './locker.component.html'
})
export class LockerComponent implements OnInit {

  public form: UntypedFormGroup;
  public display = false;

  constructor(private fb: UntypedFormBuilder, private http: HttpClient, public auth: AuthenticateService,
              private router: Router) {
    this.display = LockerService.locked;
    this.auth.emit_autocomplete.subscribe((autocomplite) => {
      if (this.auth.is_autocomplete && this.form) {
        this.form.patchValue({
          'password': (this.auth.autocomplete) ? this.auth.autocomplete['password'] || '' : ''
        });
      }
    });
    LockerService.state_emitter.subscribe((state) => this.display = state);
  }

  ngOnInit() {
    this.form = this.fb.group({
      'password': ['', Validators.required]
    });
    if (this.auth.is_autocomplete) {
      this.form.patchValue({
        'password': (this.auth.autocomplete) ? this.auth.autocomplete['password'] || '' : ''
      });
    }
    NavigationLoaderService.hide();
  }

  onSubmit() {
    this.http.post('Session.prolongation', {'password': this.form.value.password})
      .subscribe(
        (response) => this.onLoginSuccess(response),
        (error) => this.onLoginError(error)
      );
    this.form.patchValue({
      'password': ''
    });
  }

  onLoginSuccess(response) {
    AuthenticateService.session_id = response.session_id;

    this.auth.user.setProperty('session_id', response.session_id);
    this.auth.save_data();

    LockerService.unlock();
    NavigationLoaderService.hide();
  }

  onLoginError(error) {
    this.form.patchValue({
      'password': ''
    });
    NotificationService.swalError('Неверный пароль',
      'Проверьте раскладку клавиатуры и посмотрите, не включён ли режим «Caps Lock»');
  }

  onChangeUser() {
    this.auth.logout();
    this.router.navigateByUrl('/authenticate');
    setTimeout(function () {
      NavigationLoaderService.hide();
      LockerService.unlock();
    }, 300);
  }

}
