<div class="sticky-top zi-fix">
  <div class="bg-body pt-1">
    <div class="constraints-filter">
      <div class="row no-gutters gutters-2">
        <div class="col-md-3">
          <div class="form__group">
            <div class="form__input">
              <input class="input" type="text" [(ngModel)]="global_search" placeholder="Застройщик / Название ЖК">
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label class="form__checkbox form__checkbox_icon_done_mark form__checkbox_border">
            <input type="checkbox" [(ngModel)]="allow_all">
            <span class="form__checkbox__wrapper">
              <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
          </span>
            <span class="form__checkbox__content">Общее разрешение</span>
          </label>
        </div>
        <div class="col-md-3">
          <label class="form__checkbox form__checkbox_icon_done_mark form__checkbox_border">
            <input type="checkbox" [(ngModel)]="allow_frame_only">
            <span class="form__checkbox__wrapper">
              <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
          </span>
            <span class="form__checkbox__content">Разрешены IFrame</span>
          </label>
        </div>
        <div class="col-md-3">
          <label class="form__checkbox form__checkbox_icon_done_mark form__checkbox_border">
            <input type="checkbox" [(ngModel)]="allow_xml_only">
            <span class="form__checkbox__wrapper">
              <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
          </span>
            <span class="form__checkbox__content">Разрешены XML</span>
          </label>
        </div>
      </div>
    </div>
    <ng-container *ngIf="header_info">
    <div class="mt-2">
      <div class="float-right sticky-save-btn">
        <button class="button button_main" (click)="saveData()" title="Сохранить изменения"><i class="fa fa-save"></i></button>
      </div>
      <p class="d-inline-block mr-2r mb-0 mt-1" *ngFor="let el of header_info">
        {{el.caption}}: <b>{{el.count}}</b>
      </p>
    </div>
  </ng-container>
  </div>
  <div class="bg-body-bottom"></div>
</div>


<div class="form_crm">
  <app-simple-table>
    <app-simple-table-th>Застройщик</app-simple-table-th>
    <app-simple-table-th>ЖК</app-simple-table-th>
    <app-simple-table-th>Метро</app-simple-table-th>
    <app-simple-table-th>Комментарий</app-simple-table-th>
    <app-simple-table-th>Общ разреш</app-simple-table-th>
    <app-simple-table-th>Разреш iframe</app-simple-table-th>
    <app-simple-table-th>Разреш xml</app-simple-table-th>
    <!-- // -->
    <app-simple-table-tr *ngFor="let row of data | realty_constraint_filter: global_search : allow_all : allow_frame_only : allow_xml_only; let ind=index"
      [template]="template">
      <ng-template #template>
        <td>{{row['manufacturer']}}</td>
        <td>{{row['realty_caption']}}</td>
        <td>{{row['subway_caption']}}</td>
        <td>
          <div class="form__group">
            <div class="form__input">
              <textarea [(ngModel)]="row['comment']" [disabled]="!crmUsage"
                        class="textarea md-valid" placeholder="Комментарий"></textarea>
            </div>
          </div>
        </td>
        <td>
          <label class="form__checkbox form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="row['allowed_all']" (change)="onConstraintChange(1, ind)"
                   [disabled]="!row['allowed_rules']['allowed_all_editable']">
            <span class="form__checkbox__wrapper">
                <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>
            <span class="form__checkbox__content"></span>
          </label>
        </td>
        <td>
          <label class="form__checkbox form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="row['allowed_iframe']" (change)="onConstraintChange(2, ind)"
                   [disabled]="!row['allowed_rules']['allowed_iframe_editable']">
            <span class="form__checkbox__wrapper">
                <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>
            <span class="form__checkbox__content"></span>
          </label>
        </td>
        <td>
          <label class="form__checkbox form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="row['allowed_xml']" (change)="onConstraintChange(3, ind)"
                   [disabled]="!row['allowed_rules']['allowed_xml_editable']">
            <span class="form__checkbox__wrapper">
                <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>
            <span class="form__checkbox__content"></span>
          </label>
        </td>
      </ng-template>
    </app-simple-table-tr>
  </app-simple-table>
  <button class="button button_main mb-5 mt-3" (click)="saveData()">Сохранить изменения</button>
</div>
