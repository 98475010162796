import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {OverlayWindowService} from '../../../../single-window/shared/overlay-window.service';
import {NotificationService} from '../../../../../services/notification.service';
import {JsonClientService} from '../../../../../shared/json-client';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-reserv-lead-page-substep-edit',
  templateUrl: './reserv-lead-page-substep-edit.component.html',
  styleUrls: ['./reserv-lead-page-substep-edit.component.scss']
})
export class ReservLeadPageSubstepEditComponent implements OnDestroy {

  public _data: any;
  public formsActive = false;
  public rightWindow: boolean;

  public rollbackSubscription: Subscription;
  public dict = {
    'phone_call': 'Звонок',
    'personal_meeting': 'Встреча',
    'email': 'Email',
    'other': 'Другое',
  };
  public fldNames = {
    'source_id': 'Источник лида',
    'middleName': 'Отчество',
    'lastName': 'Фамилия',
    'firstName': 'Имя',
    'annotation': 'Комментарий',
    'commission': 'Вознаграждение',
    'commission_developer': '% вознаграждения от застройщика',
    'commission_proc': '% вознаграждения',
    'commission_spn': 'КВ СПН',
    'commission_subagent': 'Субагентские расходы',
    'db_date': 'Дата ДБ',
    'db_num': '№ ДБ',
    'ddu_date': 'Дата договора продажи',
    'ddu_num': '№ договора продажи',
    'ddu_payment_date': 'Дата оплаты договора продажи',
    'flat_deal_num': 'Квартира',
    'price': 'Стоимость общая',
    'square': 'Площадь',
    'sale_flat_id': 'Выбранная квартира в поле «Объект»',
    'sale_housing_id': 'Выбранный корпус в поле «Объект»',
    'sale_subagent_user_id': 'Пользователь субагента',
    'sale_subagent_id': 'Субагент',
    'notice': 'Комментарий',
    // 'email': '',
    'phone': 'Телефон',
    'worker_id': 'Ответственный от spn24',
    'sale_lead_transfer': 'Расширенное бронирование',
  };


  @Input('data')
  set data(val) {
    this._data = val;
    if (this.formsActive) {
      const obj = {
        'next_contact_date': this._data.next_contact_date,
        'next_contact_time': this._data.next_contact_time,
        'view_date': this._data.forms_fields.view_date ? this._data.forms_fields.view_date : '',
        'view_time': this._data.forms_fields.view_time ? this._data.forms_fields.view_time : '',
        'unique_until': this._data.unique_until ? this._data.unique_until : '',
        'data_okonchaniya': this._data.forms_fields.reserve_end_time,
        'next_contact_status_state': 'phone_call',
        'rollback_stage_id': this._data.stage_rollback_stages.length ? this._data.stage_rollback_stages[0].id : '',
      };
      this.form_all.patchValue(obj);
    } else {
      this.formInit();
    }

  }

  @Output() public closeEdit: EventEmitter<any> = new EventEmitter<any>();
  public form_all: UntypedFormGroup;

  public type = '';

  constructor(private http: JsonClientService, private overlayWindowService: OverlayWindowService,
              private fb: UntypedFormBuilder, private notify: NotificationService) { }

  formInit() {
    this.form_all = this.fb.group({
      'next_contact_date': [this._data.next_contact_date, Validators.required],
      'next_contact_time': [this._data.next_contact_time, [Validators.pattern(/^(0?\d:[0-5]\d|1\d:[0-5]\d|2[0-3]:[0-5]\d|)$/)]],
      'next_contact_comment': ['', Validators.required],
      'comment_for_subagent': [''],
      'next_contact_status_state': ['phone_call'],
      'view_date': [this._data.forms_fields.view_date ? this._data.forms_fields.view_date : '', Validators.required],
      'unique_until': [this._data.unique_until ? this._data.unique_until : '', Validators.required],
      'data_okonchaniya': [this._data.forms_fields.reserve_end_time, Validators.required],
      'pay_type': ['', Validators.required],
      'view_time': [this._data.forms_fields.view_time ? this._data.forms_fields.view_time : '', [Validators.required, Validators.pattern(/^(0?\d:[0-5]\d|1\d:[0-5]\d|2[0-3]:[0-5]\d|)$/)]],
      'rollback_stage_id': [this._data.stage_rollback_stages.length ? this._data.stage_rollback_stages[0].id : ''],
    });

    this.formsActive = true;
  }

  doJump() {
    NotificationService.swalConfirm('Вы уверены?', 'Лид перепрыгнет все этапы pre sale и перейдет на этап Ожидание ДБ. ' +
      'Это необходимо делать только в случае, когда в CRM Estatet уже произошла квалификация сделки, а в SPN24 - нет!')
      .then(() => {
        const dataObj = {
          'lead_id': this._data.id,
          'btn_type': 'jump',
          'stage_id': this._data.stage_id,
          'params': {},
        };

        this.http.post('Bp.lead_stage_change', dataObj).then(
          response => {
            this.notify.notifySuccess('Этап успешно изменён', '', 5000);
            this.closeEdit.emit(response);
          },
          error => { }
        );
      }).catch(() => null);
  }

  stepEditLead(type) {
    this.type = type;
    this.viewDisable();

    if (this._data.stage_next_add_form === 'unique') {
      this.uniqueEnable();
    } else {
      this.uniqueDisable();
    }
    this.form_all.get('pay_type').disable();
    this.form_all.get('data_okonchaniya').disable();
    if (this.type === 'success') {
      if (this._data.stage_next_add_form === 'view' || this._data.stage_next_add_form === 'preview') {
        this.viewEnable();
      }
      if (this._data.stage_next_add_form === 'reserve') {
        this.form_all.get('pay_type').enable();
      }
      if (!this._data.stage_next_exists) {
        this.contDisable();
      } else {
        this.contEnable();
      }
      if (this._data.stage_next_add_form_unique_until) {
        this.uniqueEnable();
      }
      this.form_all.get('rollback_stage_id').disable();
    } else if (this.type === 'prolongation') {
      if (this._data.stage_next_add_form === 'view' || this._data.stage_next_add_form === 'view_processing') {
        this.viewEnable();
      }
      if (this._data.stage_next_add_form === 'reserve_prolongation') {
        this.form_all.get('data_okonchaniya').enable();
        this.form_all.get('pay_type').enable();
      }
      this.contEnable();
      if (this._data.stage_next_add_form_unique_until) {
        this.uniqueEnable();
      }
      this.form_all.get('rollback_stage_id').disable();
    } else if (this.type === 'fail') {
      this.contDisable();
      this.form_all.get('rollback_stage_id').disable();
    } else if (this.type === 'roll_back') {
      this.contEnable();
      this.form_all.get('rollback_stage_id').enable();
    }

    this.rightWindow = true;
  }

  contEnable() {
    this.form_all.get('next_contact_date').enable();
    this.form_all.get('next_contact_time').enable();
    this.form_all.get('next_contact_status_state').enable();
  }
  contDisable() {
    this.form_all.get('next_contact_date').disable();
    this.form_all.get('next_contact_time').disable();
    this.form_all.get('next_contact_status_state').disable();
  }
  viewEnable() {
    this.form_all.get('view_date').enable();
    this.form_all.get('view_time').enable();
  }
  viewDisable() {
    this.form_all.get('view_date').disable();
    this.form_all.get('view_time').disable();
  }
  uniqueEnable() {
    this.form_all.get('unique_until').enable();
  }
  uniqueDisable() {
    this.form_all.get('unique_until').disable();
  }

  onSubmit() {

    const dataObj = {
      'lead_id': this._data.id,
      'btn_type': this.type,
      'stage_id': this._data.stage_id,
      'params': this.form_all.value,
    };

    this.http.post('Bp.lead_stage_change', dataObj).then(
      response => {
        this.notify.notifySuccess('Этап успешно изменён', '', 5000);
        this.closeEdit.emit(response);
        this.form_all.reset();
        this.onClosedRight();
      },
      error => { }
    );
  }
  onClosedRight() {
    this.type = '';
    this.rightWindow = false;
  }

  ngOnDestroy() {
    if (this.rollbackSubscription) {
      this.rollbackSubscription.unsubscribe();
    }
  }
}
