import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {DictionariesService} from '../../services/dictionaries.service';

@Injectable()
export class DictionaryBuildStageResolver {

  constructor(private dict_helper: DictionariesService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.dict_helper.get('Dict.get_buld_stages');
  }

}
