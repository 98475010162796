import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, FormControl, UntypedFormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {AlternativeDatatablesInterface} from '../../../shared/alternative-datatables/alternative-datatables.interface';
import {AlternativeDatatablesComponent} from '../../../shared/alternative-datatables/alternative-datatables.component';
import {JsonClientService} from '../../../shared/json-client';
import {GoBackButtonService} from '../../../services/go-back-button.service';
import {BreadcrumbsService} from '../../../shared/breadcrumbs/services';


@Component({
  templateUrl: './regl-table.component.html',
  styleUrls: [
    './regl-table.component.scss'
  ]
})
export class ReglTableComponent
  extends AlternativeDatatablesComponent
  implements AlternativeDatatablesInterface, OnInit, OnDestroy, AfterViewInit {

  public readonly head = [
    {'name': 'developer_caption', 'value': '', 'caption': 'Застройщик', 'sort': true, 'input': true},
    {'name': 'complex_caption', 'value': '', 'caption': 'Название ЖК', 'sort': true, 'input': true},
    {'name': 'search_fields', 'value': ['only_published', 'not_fill'],
      'caption': 'Заполнение', 'sort': false, 'input': false, 'width': '400px'},
  ];

  public filter_columns = {};
  public form: UntypedFormGroup;
  public filter: string;

  public housing_id = null;
  private parasites = true;

  public rightView = {
    regl: false,
    act: false,
  };

  public filterSubject: Subject<void> = new Subject<void>();
  private filterSubscription: Subscription;
  private formSubscription: Subscription;

  public current_page = 1;

  public count;
  public rows;
  public checkedLength = 0;
  public checkedComplexes = {};
  private _isAllChecked = false;
  public get isAllChecked() {
    return this._isAllChecked;
  }
  public get checkedComplexesIds() {
    return Object.keys(this.checkedComplexes).map(x => parseInt(x, 10));
  }
  public set isAllChecked(value) {
    this._isAllChecked = value;
    if (value) {
      this.rows.forEach(value2 => {
        if (!this.checkedComplexes['' + value2['complex_id']]) {
          this.checkedComplexes['' + value2['complex_id']] = value2['complex_caption'];
        }
      });
    } else {
      this.rows.forEach(value2 => {
        if (this.checkedComplexes['' + value2['complex_id']]) {
          delete this.checkedComplexes['' + value2['complex_id']];
        }
      });
    }
    this.checkedLength = Object.keys(this.checkedComplexes).length;
  }

  public sort: {[key: string]: string} = {};

  public current_flat: any = null;

  public set data(value) {
    this.count = value['count'];
    this.rows = value['rows'];
    this.checkAllChecked();
  }

  constructor(private router: Router, private fb: UntypedFormBuilder, public activatedRoute: ActivatedRoute,
              public goBack: GoBackButtonService, private b: BreadcrumbsService,
              public http: JsonClientService) {
    super(router, activatedRoute, fb, http);

    this.filterSubscription = this.filterSubject
      .pipe(debounceTime(500))
      .subscribe(_ => this.onFilterChanges());

    this.head = this.head.map(x => {
      x.value = this.activatedRoute.snapshot.queryParams[x.name] || x.value;
      return x;
    });
    if (this.activatedRoute.snapshot.queryParams['sort_name']) {
      this.sort[this.activatedRoute.snapshot.queryParams['sort_name']] = this.activatedRoute.snapshot.queryParams['sort_key'];
    }
    this.housing_id = this.activatedRoute.snapshot.params['housing_id'];
  }

  ngOnInit() {
    this.goBack.header = '<h1>Регламенты и акты</h1>';
    this.b.set([
      {url: '/', caption: 'Главная'},
      {caption: 'Массовое занесение '},
    ]);
    this.form = this.fb.group({});

    this.formSubscription = this.form.valueChanges
      .subscribe(_ => this.filterSubject.next());

    for (const el of this.head) {
      this.form.addControl(el.name, new FormControl(el.value));
    }

    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.onInitCall();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }

    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  onFilterChanged(): void {
    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.data = this.activatedRoute.snapshot.data['data'];
    this.current_page = +this.activatedRoute.snapshot.queryParams['page'] || 1;
  }

  onFilterChanges(force = null) {
    const forceParams = {};
    if (force !== null) {
      forceParams['t'] = Math.floor(Math.random() * 1000);
    }

    this.filter_columns = {};
    for (const el of this.head) {
      if (!this.form.get(el.name).value.length) {
        continue;
      }

      this.filter_columns[el.name] = this.form.get(el.name).value;
    }
    if (this.filter !== '') {
      this.filter_columns['global_search_string'] = this.filter;
    }
    if (this.sort) {
      this.filter_columns['sort_name'] = Object.keys(this.sort)[0];
      this.filter_columns['sort_key'] = this.sort[this.filter_columns['sort_name']];
    }
    if (this.parasites) {
      return this.parasites = !this.parasites;
    }
    this.goBack.header = '<h1>Регламенты и акты</h1>';
    this.b.set([
      {url: '/', caption: 'Главная'},
      {caption: 'Массовое занесение'},
    ]);
    this.router.navigate(['mass/regl_table'], {
      queryParams: Object.assign(forceParams, this.getQueryParams()),
    });
  }

  getQueryParams() {
    const query_params = {};

    for (const el of this.head) {
      if (!this.form.get(el.name).value.length) {
        continue;
      }
      query_params[el.name] = this.form.get(el.name).value;
    }
    if (!query_params['search_fields']) {
      query_params['search_fields'] = ['stub'];
    }
    if (this.sort) {
      query_params['sort_name'] = Object.keys(this.sort)[0];
      query_params['sort_key'] = this.sort[query_params['sort_name']];
    }
    if (this.filter !== '') {
      query_params['global_search_string'] = this.filter;
    }
    return query_params;
  }

  prepareSort(column_name: string) {
    for (const key in this.sort) {
      if (column_name !== key) {
        delete this.sort[key];
      }
    }
  }

  sortChange(column_name: string) {
    this.prepareSort(column_name);
    this.sort[column_name] = !this.sort[column_name] || ('desc' === this.sort[column_name]) ? 'asc' : 'desc';
    this.onFilterChanges();
  }

  ngAfterViewInit() {
    this.goBack.goScroll();
  }

  onChecking(e, obj) {
    if (e.target.checked) {
      this.checkedComplexes['' + obj['complex_id']] = obj['complex_caption'];
      this.checkAllChecked();
    } else {
      if (this._isAllChecked) {
        this._isAllChecked = false;
      }
      delete this.checkedComplexes['' + obj['complex_id']];
    }
    this.checkedLength = Object.keys(this.checkedComplexes).length;
  }

  isChecked(obj) {
    return !!this.checkedComplexes['' + obj['complex_id']];
  }

  onClearChecked() {
    this.checkedComplexes = {};
    this.checkedLength = 0;
    if (this._isAllChecked) {
      this._isAllChecked = false;
    }
  }

  checkAllChecked() {
    let isAllChecked = true;
    this.rows.forEach(value2 => {
      if (!this.checkedComplexes['' + value2['complex_id']]) {
        isAllChecked = false;
      }
    });
    this._isAllChecked = isAllChecked;
  }

  onRightViewClick(type) {
    Object.keys(this.rightView).forEach(value => this.rightView[value] = false);
    this.rightView[type] = true;
  }

  onRightSubmitted(type) {
    this.rightView[type] = false;
    this.onFilterChanges(true);
  }

}
