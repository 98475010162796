<ng-container *ngIf="_data">
  <div class="row no-gutters gutters-4">

    <div class="col-sm-12">
      <div class="blackblock">
        <div class="row">
          <div class="col-sm-9">
            <p class="blackblock__text">Этап: <span title="{{_data['stage_description']}}">{{_data['stage_caption']}}</span>
              <ng-container *ngIf="_data['current_contact_date']">
                <span class="dot"></span> {{_data['current_contact_date']}}
              </ng-container>
              <ng-container *ngIf="_data['current_contact_status_state']">
                <span class="dot"></span> {{dict[_data['current_contact_status_state']]}}
              </ng-container>
              <ng-container *ngIf="_data['current_contact_comment']">
                <span class="dot"></span> <span class="comment" title="{{_data['current_contact_comment']}}">{{_data['current_contact_comment']}}</span>
              </ng-container>
            </p>
          </div>
          <div class="col-sm-3">
            <p class="blackblock__text text-right">{{_data['stage_change_text']}}</p>
          </div>
        </div>

        <div class="stage_status">
          <div class="row">

            <div class="col-sm-9 col-md-8">
              <ng-container *ngIf="_data.stage_is_active">
                <button class="button mb-2 mr-2 stage_status__button success" (click)="stepEditLead('success')" [ngClass]="{'active': type ==='success'}" title="{{_data.stage_text_success}}">{{_data.stage_text_success}}</button>
                <button class="button mb-2 mr-2 stage_status__button prolongation" (click)="stepEditLead('prolongation')" [ngClass]="{'active': type ==='prolongation'}" title="{{_data.stage_text_prolongation}}">{{_data.stage_text_prolongation}}</button>
                <button class="button mb-2 mr-2 stage_status__button fail" (click)="stepEditLead('fail')" [ngClass]="{'active': type ==='fail'}" title="{{_data.stage_text_fail}}">{{_data.stage_text_fail}}</button>
              </ng-container>
              <ng-container *ngIf="_data.stage_can_rollback">
                <button class="button mb-2 mr-2 stage_status__button roll_back" (click)="stepEditLead('roll_back')" [ngClass]="{'active': type ==='roll_back'}" title="Откат к предыдущим шагам">Откат к предыдущим шагам</button>
              </ng-container>
              <ng-container *ngIf="_data.stage_can_jump">
                <button class="button mb-2 mr-2 stage_status__button jump" (click)="doJump()" [ngClass]="{'active': type ==='jump'}" title="Перепрыгнуть PRE-SALE и перейти к этапу Ожидание ДБ"><i class="fa fa-angle-right"></i> предДБ</button>
              </ng-container>
            </div>

            <div class="col-3 col-md-4 text-sm-right">
              <button class="button mb-2 mr-2 mr-md-0" (click)="stepEditLead('history-fields')" [ngClass]="{'active': type ==='history-fields'}" title="История изменения полей лида">Логи полей</button>
              <button class="button mb-2 ml-md-2" (click)="stepEditLead('history')" [ngClass]="{'active': type ==='history'}" title="История переключения этапов работы с лидом">История БП</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <app-right-window
    [data]="rightWindow"
    (onClosedRight)="onClosedRight()">


    <form [formGroup]="form_all" *ngIf="rightWindow && type !== 'history' && type !== 'history-fields'" novalidate (submit)="onSubmit()" class="typeForm" [ngClass]="type">
      <div class="row no-gutters gutters-4">
        <div class="col-12">
          <ng-container *ngIf="_data['stage_text_' + type]">
            <h2 class="mt-2r">{{_data['stage_text_' + type]}}</h2>
          </ng-container>
          <ng-container *ngIf="type === 'roll_back'">
            <h2 class="mt-2r">Откат к предыдущему шагу</h2>
          </ng-container>
          <ng-container *ngIf="type === 'success'">
            <ng-container *ngIf="!_data.stage_next_exists">
              <h5>Последний Этап</h5>
            </ng-container>
            <ng-container *ngIf="_data.stage_next_exists">
              <h5>Следующий Этап: <span class="bold">{{_data.stage_next_caption}}</span></h5>
            </ng-container>
          </ng-container>
        </div>
        <ng-container *ngIf="(_data.stage_next_add_form === 'unique' || (this._data.stage_next_add_form_unique_until && (type === 'prolongation' || type === 'success')))">
          <div class="mt-2 col-12"></div>
          <div class="col-12">
            <app-material-input-datepicker formControlName="unique_until" [require]="true"
                                           [placeholder]="'Уникален до'">
              Уникален до</app-material-input-datepicker>
          </div>
        </ng-container>

        <ng-container *ngIf="type === 'roll_back'">
          <div class="col-12">
            <app-material-select2 formControlName="rollback_stage_id" [caption]="'Прошлые этапы'" [require]="true">
              <option [value]="past_stage.id" *ngFor="let past_stage of _data.stage_rollback_stages">
                {{past_stage.caption}}
              </option>
            </app-material-select2>
          </div>
        </ng-container>

        <ng-container *ngIf="type !== 'fail'">
          <ng-container *ngIf="_data.stage_next_exists || type === 'prolongation' || type === 'roll_back'">
            <div class="col-6">
              <app-material-input-datepicker formControlName="next_contact_date" [require]="true"
                                             [placeholder]="'Дата следующего контакта'">
                Дата следующего контакта</app-material-input-datepicker>
            </div>
            <div class="col-6">
              <app-material-input-mask formControlName="next_contact_time"
                                       [placeholder]="'__:__'" [mask]="'00:00'">
                Время следующего контакта</app-material-input-mask>
            </div>
            <div class="col-12">
              <app-material-select2 [caption]="'Тип связи'" formControlName="next_contact_status_state">
                <option [value]="'phone_call'">Звонок</option>
                <option [value]="'personal_meeting'">Встреча</option>
                <option [value]="'email'">Email</option>
                <option [value]="'other'">Другое</option>
              </app-material-select2>
            </div>
          </ng-container>
        </ng-container>

        <div class="col-12">
          <app-material-textarea [rows]="4"
                                 [placeholder]="'Наш комментарий для контакта'"
                                 [require]="true"
                                 formControlName="next_contact_comment">
            Наш комментарий для контакта
          </app-material-textarea>
        </div>

        <div class="col-12">
          <app-material-textarea [rows]="4"
                                 [placeholder]="'Комментарий для субагента'"
                                 formControlName="comment_for_subagent">
            Комментарий для субагента
          </app-material-textarea>
        </div>

        <ng-container *ngIf="type !== 'fail'">
          <ng-container *ngIf="_data.stage_next_exists || type === 'prolongation' || type === 'roll_back'">
            <ng-container *ngIf="(type === 'success' && (_data.stage_next_add_form === 'view' || _data.stage_next_add_form === 'preview') || (type === 'prolongation' && (_data.stage_next_add_form === 'view' || _data.stage_next_add_form === 'view_processing')))">
              <div class="mt-2 col-12"></div>
              <div class="col-6">
                <app-material-input-datepicker formControlName="view_date" [require]="true"
                                               [placeholder]="'Дата просмотра'">
                  Дата просмотра</app-material-input-datepicker>
              </div>
              <div class="col-6">
                <app-material-input-mask formControlName="view_time" [require]="true"
                                         [placeholder]="'__:__'" [mask]="'00:00'">
                  Время просмотра</app-material-input-mask>
              </div>
            </ng-container>

            <ng-container *ngIf="((type === 'success') && (_data.stage_next_add_form === 'reserve')) || ((type === 'prolongation') && (_data.stage_next_add_form === 'reserve_prolongation'))">
              <div class="mt-2 col-12"></div>
              <div class="col-12">
                <app-material-select2 formControlName="pay_type"
                                      [caption]="'ТИП ОПЛАТЫ (ОБЯЗАТЕЛЬНО)'" [require]="true">
                  <option [value]="" selected></option>
                  <option [value]="4">100% оплата</option>
                  <option [value]="5">Рассрочка</option>
                  <option [value]="6">Ипотека</option>
                </app-material-select2>
              </div>
              <ng-container *ngIf="(type === 'prolongation') && (_data.stage_next_add_form === 'reserve_prolongation')">
                <div class="col-12">
                  <app-material-input-datepicker formControlName="data_okonchaniya" [require]="true"
                                                 [placeholder]="'Дата окончания'">
                    Дата просмотра</app-material-input-datepicker>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>

      </div>
      <div class="row no-gutters gutters-2 mt-3">
        <div class="col-md-10">
          <button type="submit" class="button" [disabled]="!form_all.valid">Сохранить</button>
        </div>
      </div>
    </form>


    <ng-container *ngIf="type === 'history'">
      <div class="row no-gutters gutters-4">
        <div class="col-12">
          <h2 class="mt-2r">История работы</h2>
        </div>
        <div class="col-12">
          <ng-container *ngIf="_data.stage_changes_list.length">
            <div class="row no-gutters gutters-4">
              <div class="col-sm-12 mt-3 mb-2">
                <table class="table history">
                  <tr>
                    <th>Дата</th>
                    <th>Событие</th>
                  </tr>
                  <tr *ngFor="let operation of _data['stage_changes_list']; let i = index">
                    <td>
                      {{operation['date_time']}}
                    </td>
                    <td>
                      <span class="history__colorblock" [ngClass]="operation['status']"></span>
                      <ng-container *ngIf="operation['estatet_is']">
                        <span class="mr-2"><span class="bold">Эстатет</span>
                        <span [popover]="i">
                          <i class="fa fa-2x fa-fw fa-question-circle c-pointer"></i>
                        </span>
                          | </span>
                        <popover-content #i
                                         [title]="operation['estatet_state']"
                                         [placement]="'right'"
                                         [closeOnClickOutside]="true"
                                         [closeOnMouseOutside]="false">
                          <div class="history__params">{{ operation['estatet_params']}}</div>
                        </popover-content>
                      </ng-container>
                      <ng-container *ngIf="operation['author_fio']">
                        <a [routerLink]="['/physical/edit/', operation['author_id']]">
                          {{operation['author_fio']}}
                        </a>
                      </ng-container>
                      <span class="history__gray">{{operation['status_change_description']}}</span>
                      <ng-container *ngIf="operation['old_stage_caption'] && operation.show_second_piece">
                        <span> {{operation['old_stage_caption']}}</span>
                        <span class="history__gray"> на </span>
                      </ng-container>
                      <span> {{operation['new_stage_id']}}</span>
                      <ng-container *ngIf="operation['next_contact_date']">
                        <span class="history__gray"> до </span>
                        {{operation['next_contact_date']}} {{operation['next_contact_time']}}
                      </ng-container>
                      <ng-container *ngIf="operation['next_contact_comment']">
                        <span class="history__gray"> с комментарием </span> <span [innerHTML]="operation['next_contact_comment']"></span>
                      </ng-container>
                      <ng-container *ngIf="operation['comment_for_subagent']">
                        <p> <span class="history__gray">Комментарий для субагента </span> <span [innerHTML]="operation['comment_for_subagent']"></span></p>
                      </ng-container>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>


    <ng-container *ngIf="type === 'history-fields'">
      <div class="row no-gutters gutters-4">
        <div class="col-12">
          <h2 class="mt-2r">История изменения полей</h2>
        </div>
        <div class="col-12">
          <div class="row no-gutters gutters-4">
            <div class="col-sm-12 mt-3 mb-2">
              <ng-container *ngIf="!_data.history_change_log.length">
                Нет данных для отображения
              </ng-container>
              <ng-container *ngIf="_data.history_change_log.length">
                <table class="table history">
                  <tr>
                    <th>Дата</th>
                    <th>Автор</th>
                    <th>Изменения</th>
                  </tr>
                  <tr *ngFor="let log of _data['history_change_log']; let i = index">

                    <td>
                      {{log['create_time']}}
                    </td>
                    <td>
                      {{log['author_fio']}}
                    </td>
                    <td>
                      <div *ngFor="let fld of log['new_data'] | keyvalue">
                        <b>{{fldNames[fld.key] || fld.key}}</b>
                        с <i>{{log['old_data'][fld.key] || 'Пусто'}}</i>
                        на <i>{{fld.value || 'Пусто'}}</i>
                      </div>
                    </td>
                  </tr>
                </table>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

  </app-right-window>
</ng-container>
