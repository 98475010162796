import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SimpleTableHeaderComponent} from './simple-table-header.component';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {SimpleTableRowComponent} from './simple-table-row.component';

class TableElement {
  constructor(public html: SafeHtml, public component: SimpleTableHeaderComponent | SimpleTableRowComponent) { }
}

@Component({
  selector: 'app-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss']
})
export class SimpleTableComponent {

  public headers: Array<TableElement> = [];
  public rows: Array<TableElement> = [];

  public totalRows: number;

  @Output('currentPageEmitter') public currentPageEmitter: EventEmitter<number> = new EventEmitter();

  constructor(private domSanitizer: DomSanitizer) { }

  public registerHeader(header: SimpleTableHeaderComponent) {
    this.headers.push(new TableElement(this.domSanitizer.bypassSecurityTrustHtml(header.elementRef.nativeElement.innerHTML),
                                       header));
  }

  public registerRow(row: SimpleTableRowComponent) {
    this.rows.push(new TableElement(this.domSanitizer.bypassSecurityTrustHtml(row.elementRef.nativeElement.innerHTML),
                                    row));
  }

  public unregisterHeader(header: SimpleTableHeaderComponent) {
    const index = this.headers.findIndex(x => x.component === header);

    if (-1 === index) {
      return;
    }

    this.headers.splice(index, 1);
  }

  public unregisterRow(row: SimpleTableRowComponent) {
    const index = this.rows.findIndex(x => x.component === row);

    if (-1 === index) {
      return;
    }

    this.rows.splice(index, 1);
  }

  public setTotalRows(value: number) {
    this.totalRows = value;
  }

  public onPageSet(page) {
    this.currentPageEmitter.emit(page);
  }

}
