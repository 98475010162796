<div class="alert alert-warning mb-4">
  <div><i class="fa-fw fa fa-warning"></i> <b>Внимание!</b></div>
  <div class="mt-2">Данные ассоциации необходимы для корректной обработки квалификаций от Эст-а-тет, - лидам, на
    которых корпус будет различаться, автоматически будет переприсваиваться объект для корректного отражения
    проведенных сделок в аналитике.
  </div>
</div>

<ng-container *ngIf="!load">
  <div class="row mt-4 mb-2" [formGroup]="form">
    <div class="col-6">
      Всего: <b>{{count}}</b>
    </div>
  </div>
  <div class="material-datatable-wrapper">
    <div class="table-responsive">
      <table class="table">
        <tr>
          <th *ngFor="let el of head" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
            <div [formGroup]="form">
              <div class="row no-gutters align-items-center flex-nowrap"
                   [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
                <div class="col table__input">
                  <ng-container *ngIf="el.input">
                    <app-material-input
                      [placeholder]="el.caption"
                      [formControlName]="el.name"
                      [cssClass]="'mb-0'">
                    </app-material-input>
                  </ng-container>
                  <ng-container *ngIf="el.select">
                    <app-material-select
                      [formControlName]="el.name"
                      [cssClass]="'mb-0'">
                      <option *ngFor="let opt of el.select" [value]="opt.id">{{opt.caption}}</option>
                    </app-material-select>
                  </ng-container>
                  <ng-container *ngIf="!el.input && !el.select">
                    {{el.caption}}
                  </ng-container>
                </div>
                <div class="col-auto" *ngIf="el.sort">
                  <a href="javascript:void(0)" (click)="sortChange(el.name)">
                    <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                      'icon_new-sort-down': 'asc' == sort[el.name],
                                      'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                  </a>
                </div>
              </div>
            </div>
          </th>
        </tr>
        <!-- // -->
        <ng-container *ngFor="let row of rows; let row_index = index">
          <tr>
            <td>
              <span title="Время появления 1й квалификации от Эст-а-тет по данному ЖК в базе">
                {{row['date_created']}}
              </span>
              <span *ngIf="row['associated_time']"
                    title="Время установки ассоциации по данному ЖК объекту СПН24 автором">
                / {{row['associated_time']}}
              </span>
            </td>
            <td>
              {{row['eat_complex_caption']}}
            </td>
            <td>
                <a href="javascript:void(0)" (click)="onAssocSetClick(row)">
                  <ng-container *ngIf="!row['object_caption'] && !row['select_complex_show']">
                    <i class="icon icon_md icon_line icon_Small_Chevron-Down"></i> задать ассоциацию
                  </ng-container>
                  <ng-container *ngIf="row['object_caption']">
                    <i class="icon icon_md icon_line icon_Small_Chevron-Down"></i> {{row['object_caption']}}
                  </ng-container>
                </a>
            </td>
            <td>
              {{row['author_fio'] || '—'}}
            </td>
            <td>
              {{row['assoc_exists']}}
            </td>
          </tr>
          <tr *ngIf="row['select_complex_show'] === true">
            <td colspan="5" [formGroup]="flatForm">
              <app-material-select2-autocomplete
                formControlName="flat_id"
                [placeholder]="'Квартира ...'"
                [caption]="'Выберите ЖК и квартиру, которая будет автоматически подставляться в лиды сделок по ' + row['eat_complex_caption']"
                [api_options]="{'only_flat': true}"
                [api_action]="'Dict.bp_sale_realty_ac'"
                [focusOnInit]="true"
                (label)="onFlatSelected(row, $event)"
                [cssClass]="'mt-3 mb-4'"
              >
                <option>{{row['object_caption']}}</option>
              </app-material-select2-autocomplete>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>

  <div class="alt-pagination">
    <app-paginator
      [queryParams]="filter_columns"
      [itemsPerPage]="50"
      [itemCount]="count"
      [currentPage]="current_page"
      [baseUrl]="'/eat-complex-captions'">
    </app-paginator>
  </div>
</ng-container>
