import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {JsonClientService} from '../../../../../shared/json-client';
import {NotificationService} from '../../../../../services/notification.service';

@Component({
  selector: 'app-reserv-lead-page-edit-views',
  templateUrl: './reserv-lead-page-edit-views.component.html',
  styleUrls: ['./reserv-lead-page-edit-views.component.scss']
})
export class ReservLeadPageEditViewsComponent implements OnInit, OnDestroy {

  public formGroup: UntypedFormGroup;

  constructor(private http: JsonClientService, private fb: UntypedFormBuilder,
              private notify: NotificationService) { }

  @Input() public data: any = null;
  @Output('viewChanged') viewChanged: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.formGroup = this.fb.group({
      'view_date': [this.data.view_date, Validators.required],
      'view_time': [this.data.view_time, [Validators.required, Validators.pattern(/^(0?\d:[0-5]\d|1\d:[0-5]\d|2[0-3]:[0-5]\d|)$/)]],
      'comment': [''],
    });
  }


  ngOnDestroy() {

  }

  save(is_manager_confirmed) {

    const obj = {
      'operation_id': this.data.operation_id,
      'data': {
        'view_date': this.formGroup.value.view_date,
        'view_time': this.formGroup.value.view_time,
        'comment': this.formGroup.value.comment,
        'is_manager_confirmed': is_manager_confirmed,
      }
    }

    this.http.post('Operation.view_confirmation', obj).then(
      (response) => {
        this.viewChanged.next(response);
      },
      error => { }
    );
  }

}
