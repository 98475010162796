import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '../../../../services/http.service';
import { CalendarMapService } from '../calendar-map.service';

@Component({
  selector: 'app-calendar-map-tables',
  templateUrl: './calendar-map-tables.component.html',
  styleUrls: ['./calendar-map-tables.component.scss']
})
export class CalendarMapTablesComponent implements OnInit {
  @Input() realty_list = [];
  @Input() event_list = [];
  public view = 'subagent';
  public realty_id = null;
  public subagent_id = null;
  public subagent_caption = null;

  constructor(private http: HttpClient, public calendarMapService: CalendarMapService) {
  }

  ngOnInit() {
    this.http.post('Map.get_actual_event').subscribe(
      data => {
        this.event_list = data;
      },
      error => false
    );
  }

  onChange(type, value?, caption?) {
    this.view = type;
    switch (type) {
      case 'physical':
        this.subagent_id = value;
        this.subagent_caption = caption;
        break;
      case 'subagent':
        this.subagent_id = null;
        break;
    }
  }

  onSubagent(value) {
    this.subagent_id = value;
  }
}
