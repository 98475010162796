<div class="tab-content-wrapper mt-4">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <div class="row no-gutters gutters-4">
      <div class="col-6">
        <app-material-input formControlName="physical_fam" [placeholder]="'Фамилия'" [require]="true">Фамилия
        </app-material-input>
        <app-material-input formControlName="physical_name" [placeholder]="'Имя'" [require]="true">Имя
        </app-material-input>
        <app-material-input formControlName="physical_otch" [placeholder]="'Отчество'" [require]="true">Отчество
        </app-material-input>
        <app-material-input-datepicker formControlName="physical_data_rozhd" [require]="false">
          Дата рождения
        </app-material-input-datepicker>
        <app-material-input formControlName="physical_email" [placeholder]="'Email'" [require]="false" [trim]="true">
          Email
        </app-material-input>
        <app-material-select formControlName="physical_gender" [require]="true" [caption]="'Пол'">
          <option [value]="''">-- Не выбрано --</option>
          <option [value]="'m'">Мужчина</option>
          <option [value]="'f'">Женщина</option>
        </app-material-select>

        <div class="mt-4">
          <button (click)="saveForm()"
                  type="button" class="button button_main">
            Сохранить
          </button>
        </div>
      </div>
      <div class="col-6">
        <app-material-input [placeholder]="'Дата создания'" formControlName="physical_create_date">
          Дата создания
        </app-material-input>
        <app-material-input formControlName="physical_author" [placeholder]="'Кто создал физ.лицо'">
          Кто создал физ.лицо
        </app-material-input>
        <p class="caption-sm mt-4">Телефоны</p>
        <div class="form__group" *ngFor="let phone of phones">
          <div class="row no-gutters align-items-center">
            <label class="form__checkbox form__checkbox_icon_done_mark">
              <input type="checkbox" (change)="phoneMain(phone['phone_id'])"
                     [disabled]="phone_main === phone['phone_id']"
                     [checked]="phone_main === phone['phone_id']">
              <span class="form__checkbox__wrapper">
                      <span class="form__checkbox__wrapper__toggler icon"></span>
                    </span>
              <span class="form__checkbox__content form__checkbox__content_phone">{{phone['phone']}}</span>
            </label>
            <a class="ml-3 button button_icon button_icon_sub" (click)="phoneDelete(phone)">
              <i class="fa fa-trash-o c-white"></i>
            </a>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-auto">
            <app-material-input-mask [mask]="'+7 (000) 000-00-00'" [placeholder]="'+7 (___) ___-__-__'"
                                     formControlName="phone_new">
            </app-material-input-mask>
          </div>
          <div class="col-auto ml-2 mt-1">
            <button class="button button_sub" (click)="phoneCreate()">Добавить</button>
          </div>
        </div>

      </div>
    </div>
  </form>
</div>
