import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'exclude',
})
export class FieldHighwayPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const [excluded, current_value] = args;

    if (value === undefined) {
      return [];
    }

    return value.filter(x => excluded
      .indexOf(+x['dict_subway_id']) === -1 || +current_value === +x['dict_subway_id']);
  }

}
