<div class="childs-wrapper__header">
  <a (click)="unregisterChild()"
    *ngIf="factoryCollection.length > 1"
    href="javascript:void(0)"
    class="icon icon_back">
  </a>
  <h5 class="modal-title">{{title}}</h5>
</div>
<div class="childs-wrapper__overflow">
  <div class="childs-wrapper" [@flyInOut]="{value: factoryCollection.length, params: {left_indent: left_indent}}">
    <div *ngFor="let data of factoryCollection" class="childs-wrapper__element">
      <app-scrollable-child [data]="data"></app-scrollable-child>
    </div>
  </div>
</div>
