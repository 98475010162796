import { Component, OnDestroy, Renderer2 } from '@angular/core';
import { AuthenticateService } from '../../../services/authenticate.service';
import { Router } from '@angular/router';
import { HistoryService } from '../../single-window/shared/history.service';
import { Subscription } from 'rxjs';
import { LockerService } from '../../../services/locker.service';
import { HttpClient } from '../../../services/http.service';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnDestroy {

  public history = null;
  public locked: boolean;
  public notificationsQueue = null;

  public comment_active = false;
  public history_active = false;

  private subscription: Subscription;
  private subscription2: Subscription;
  private subscriptionQueue: Subscription = null;
  private subscriptionQueue2: Subscription = null;

  constructor(private auth: AuthenticateService, private router: Router, private historyService: HistoryService,
              private notification: NotificationService,
              private http: HttpClient, private renderer: Renderer2) {
    this.subscription = this.historyService.global_history.subscribe(x => this.history = x);
    this.subscription2 = this.historyService.status_emitter.subscribe(x => {
      this.history_active = x;
    });
    this.locked = LockerService.locked;
    LockerService.state_emitter.subscribe((locked) => this.locked = locked);

    /*
     * Prepare notification queue
     */
    this.notificationsQueue = this.notification.queue;
    this.subscriptionQueue = this.notification.queue_emitter.subscribe(x => this.notificationsQueue = x);
    this.subscriptionQueue2 = this.notification.status_emitter.subscribe(x => {
      this.comment_active = x;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }

    if (this.subscriptionQueue !== null) {
      this.subscriptionQueue.unsubscribe();
    }
    if (this.subscriptionQueue2 !== null) {
      this.subscriptionQueue2.unsubscribe();
    }
  }

  toggleNotifications() {
    if (this.notificationsQueue.length) {
      this.comment_active = this.notification.toggleStatus();
    }
  }

  toggleHistory() {
    this.history_active = this.historyService.toggleOpened();
  }

  logout() {
    this.serverLogout();
    this.router.navigateByUrl('/authenticate');
    this.auth.logout();
  }

  fullScreen() {
    if (!document['fullscreenElement'] && !document['webkitFullscreenElement']) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement['webkitRequestFullscreen']) {
        document.documentElement['webkitRequestFullscreen']();
      }
    } else {
      if (document['webkitCancelFullScreen']) {
        document['webkitCancelFullScreen']();
      }
    }
  }

  onLock() {
    this.serverLogout();
    LockerService.lock();
  }

  serverLogout() {
    this.http.post('Session.signout')
      .subscribe(
        (response) => true,
        (error) => false
      );
  }

  sidebarMode() {
    const class_name = 'sidebar-mini',
      has_class = document.body.classList.contains(class_name);
    if (has_class) {
      this.renderer.removeClass(document.body, class_name);
    } else {
      this.renderer.addClass(document.body, class_name);
    }
  }

}
