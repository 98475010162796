<h1 *ngIf="type === 'teached'">Обученные пользователи</h1>
<h1 *ngIf="type === 'staff'">Все пользователи</h1>
<h1 *ngIf="type === 'refusal'">Необученные пользователи</h1>

<app-simple-table>
  <app-simple-table-th>№ п/п</app-simple-table-th>
  <app-simple-table-th [name]="'physical_fio'">ФИО</app-simple-table-th>
  <app-simple-table-th [name]="'physical_phone'">Телефон</app-simple-table-th>
  <app-simple-table-th [name]="'physical_email'">Телефон</app-simple-table-th>
  <!--//-->
  <app-simple-table-tr *ngFor="let row of list; index as i">
    <td>{{i+1}}</td>
    <td>{{row['physical_fio']}}</td>
    <td>{{row['physical_phone']}}</td>
    <td>{{row['physical_email']}}</td>
  </app-simple-table-tr>
</app-simple-table>
