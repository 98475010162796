<h1>Все мероприятия</h1>
<br>
<div class="row teaching">
  <div class="col-lg-3 col-md-3 col-sm-6">
    <p class="teaching__digits">{{statistic['events_all']}} / {{statistic['events_last_month']}}</p>
    <p class="teaching__legend">Мероприятий проведено</p>
    <p class="teaching__legend_mute">всего / последний месяц</p>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-6">
    <p class="teaching__digits">{{statistic['subscriptions_all']}} / {{statistic['subscriptions_last_month']}}</p>
    <p class="teaching__legend">Участников</p>
    <p class="teaching__legend_mute">всего / последний месяц</p>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-6">
    <p class="teaching__digits">{{statistic['users_perc']}}%</p>
    <p class="teaching__legend">Охват пользователей</p>
    <p class="teaching__legend_mute">участвовал хотя бы в одном</p>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-6">
    <p class="teaching__digits">{{statistic['users_perc']}}%</p>
    <p class="teaching__legend">Охват орагнизаций</p>
    <p class="teaching__legend_mute">участвовал хотя бы в одном</p>
  </div>
</div>
<div class="teaching-table-wrapper">
  <app-all-events-table></app-all-events-table>
</div>
