
<div class="material-datatable-wrapper">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)"
    [templateLeft]="additInputs"
    [templateRight]="buttonCreate">
  </app-datatable-headline>
  <div class="table-responsive">
    <table class="table table-striped datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>


<ng-template #buttonCreate>
  <button type="button" class="button button_sm" (click)="createNews()">
    Создать<span class="d-none d-md-inline"> новость</span>
  </button>
</ng-template>

<ng-template #additInputs>
  <app-material-input-checkbox [(ngModel)]="is_archive" [cssClass]="'mb-0'" [border]="true" [withMb0]="true">
    В архиве
  </app-material-input-checkbox>
</ng-template>

<ng-template #urlTmpl let-row="row">
  <a class="link-break" href="{{row.news_url}}" target="_blank">{{row.news_url}}</a>
</ng-template>

<ng-template #pubTmpl let-row="row">
  <span *ngIf="!row.is_archive && row.public_status == 'crm'">СА</span>
  <span *ngIf="!row.is_archive && row.public_status == 'site'">САЙТ</span>
  <span *ngIf="!row.is_archive && row.public_status == 'site_crm'">САЙТ+СА</span>
  <span *ngIf="row.is_archive">Архив</span>
  <span class="badge badge-warning" *ngIf="row.always_show">Всегда всплывает</span>
</ng-template>

<ng-template #descriptionTmpl let-row="row">
  <div [textPreview]="300">
    <div [innerHtml]="row.news_description"></div>
  </div>
</ng-template>

<ng-template #nameTmpl let-row="row">
  {{row.news_title}}
  <ng-container *ngIf="row.is_archive">
    <p class="font-weight-bold">Архив</p>
  </ng-container>
</ng-template>

<ng-template #buttonTmpl let-row="row">
  <div class="button button_icon my-2" (click)="editNews(row)">
    <i class="fa fa-pencil"></i>
  </div>
  <ng-container *ngIf="!row.is_archive">
    <div class="button button_icon" (click)="deleteNews(row.news_id)">
      <i class="fa fa-trash"></i>
    </div>
  </ng-container>
</ng-template>
