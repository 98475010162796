<div class="housing-sections__block pb-4" *ngIf="form && initialized">
  <form class="form form_crm" [formGroup]="form" novalidate>
    <div class="row no-gutters gutters-4" formGroupName="proprietors">
      <div class="col-3">
        <app-material-input-radio formControlName="type" [data]="types">
          Тип собственности</app-material-input-radio>
      </div>
      <div class="col-9">
        <div formArrayName="sob" [ngClass]="{'d-none': form.value['proprietors']['type'] != 'sob'}">
          <table class="table table_middle">
            <tr>
              <th style="width: 50px;">№</th>
              <th>Собственник</th>
            </tr>
            <tr *ngFor="let item of sob.controls; let id = index" [formGroupName]="id">
              <td>1</td>
              <td>
                <app-material-select2-autocomplete formControlName="value"
                                                   [api_action]="api_action"
                                                   [placeholder]="'Имя собственника или название организации'">
                  <option [value]="''">Имя собственника или название организации</option>
                  <option [value]="item.value.value">{{item.value.caption}}</option>
                </app-material-select2-autocomplete>
              </td>
            </tr>
          </table>
          <div class="no-gutters mt-5">
            <p>
              <a href="javascript:void(0)" (click)="physicalAdd()">Создать и добавить физ. лицо</a>
            </p>
            <p>
              <a href="javascript:void(0)" (click)="entityAdd()">Создать и добавить юр. лицо</a>
            </p>
          </div>
        </div>
        <div formArrayName="sovm" [ngClass]="{'d-none': form.value['proprietors']['type'] != 'sovm'}">
          <table class="table table_middle">
            <tr>
              <th style="width: 50px;">№</th>
              <th>Собственник</th>
              <th style="width: 50px;"></th>
            </tr>
            <tr *ngFor="let item of sovm.controls; let id = index" [formGroupName]="id">
              <td>{{id + 1}}</td>
              <td>
                <app-material-select2-autocomplete formControlName="value"
                                                   [api_action]="api_action"
                                                   [placeholder]="'Имя собственника или название организации'">
                  <option [value]="''">Имя собственника или название организации</option>
                  <option [value]="item.value.value">{{item.value.caption}}</option>
                </app-material-select2-autocomplete>
              </td>
              <td>
                <a class="button button_icon button_icon_sub mr-1" (click)="sovmDelete(item)">
                  <i class="fa fa-trash-o"></i>
                </a>
              </td>
            </tr>
          </table>
          <div class="no-gutters mt-3">
              <button class="button button_sub" (click)="sovmAdd()">Добавить</button>
          </div>
          <div class="mt-5">
            <p>
              <a href="javascript:void(0)" (click)="physicalAdd()">Создать и добавить физ. лицо</a>
            </p>
            <p>
              <a href="javascript:void(0)" (click)="entityAdd()">Создать и добавить юр. лицо</a>
            </p>
          </div>
        </div>
        <div formArrayName="dol" [ngClass]="{'d-none': form.value['proprietors']['type'] != 'dol'}">
          <table class="table table_middle">
            <tr>
              <th style="width: 50px;">№</th>
              <th>Собственник</th>
              <th style="width: 120px;">Доли</th>
              <th style="width: 50px;"></th>
            </tr>
            <tr *ngFor="let item of dol.controls; let id = index" [formGroupName]="id">
              <td>{{id + 1}}</td>
              <td>
                <app-material-select2-autocomplete formControlName="value"
                                                   [api_action]="api_action"
                                                   [placeholder]="'Имя собственника или название организации'">
                  <option [value]="''">Имя собственника или название организации</option>
                  <option [value]="item.value.value">{{item.value.caption}}</option>
                </app-material-select2-autocomplete>
              </td>
              <td>
                <div class="input__group__wrapper">
                  <div class="input__group">
                    <app-material-input formControlName="znamenatel"></app-material-input>
                    <app-material-input formControlName="chislitel"></app-material-input>
                  </div>
                </div>
              </td>
              <td>
                <a class="button button_icon button_icon_sub mr-1" (click)="dolDelete(item)">
                  <i class="fa fa-trash-o"></i>
                </a>
              </td>
            </tr>
          </table>
          <div class="no-gutters mt-3">
            <button class="button button_sub m-auto" (click)="dolAdd()">Добавить</button>
          </div>
          <div class="mt-5">
            <p>
              <a href="javascript:void(0)" (click)="physicalAdd()">Создать и добавить физ. лицо</a>
            </p>
            <p>
              <a href="javascript:void(0)" (click)="entityAdd()">Создать и добавить юр. лицо</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
