<form [formGroup]="form" *ngIf="nationalityList">
  <div formGroupName="advanced" class="row">
    <div class="col-md-12 col-lg-6">
      <app-material-input formControlName="physical_mesto_rozhd" [placeholder]="'Место рождения'">
        Место рождения
      </app-material-input>
      <app-material-input formControlName="physical_mj_reg" [placeholder]="'Место жительства по регистрации'">
        Место жительства по регистрации
      </app-material-input>
    </div>
    <div class="col-md-12 col-lg-6">
      <app-material-input formControlName="physical_mj_fakt" [placeholder]="'Фактическое место жительства'">
        Фактическое место жительства
      </app-material-input>
      <app-material-input formControlName="physical_korr_addr" [placeholder]="'Корреспондентский адрес'">
        Корреспондентский адрес
      </app-material-input>
    </div>
    <div class="col-6">
      <app-material-input-mask [mask]="'00 00'" [placeholder]="'__ __'" formControlName="physical_pasp_seriya">
        Серия паспорта
      </app-material-input-mask>
      <app-material-input formControlName="physical_pasp_num" [placeholder]="'Номер паспорта'">
        Номер паспорта
      </app-material-input>
      <app-material-input-datepicker formControlName="physical_pasp_date">
        Дата выдачи паспорта
      </app-material-input-datepicker>
      <app-material-input formControlName="physical_pasp_vidan" [placeholder]="'Кем выдан паспорт'">
        Кем выдан паспорт
      </app-material-input>
      <app-material-input-mask [mask]="'000-000'" [placeholder]="'___-___'" formControlName="physical_pasp_kod_podrazdel">
        Пасп код подразд
      </app-material-input-mask>
    </div>
    <div class="col-6">
      <app-material-select
        #nationalitySelect
        formControlName="physical_nationality_id"
        [caption]="'Гражданство'">
        <option [value]="''">-- Не выбрано --</option>
        <option *ngFor="let nationality of nationalityList" [value]="nationality.dict_nationality_id">
          {{nationality.dict_nationality_caption}}
        </option>
      </app-material-select>
      <app-material-input formControlName="physical_inn" [placeholder]="'ИНН'">ИНН</app-material-input>
      <app-material-input formControlName="physical_num_strah_svid" [placeholder]="'Номер страхового свидетельства'">
        Номер страхового свидетельства
      </app-material-input>
      <app-material-input formControlName="physical_osnovan_podp" [placeholder]="'Основание подписи'">
        Основание подписи
      </app-material-input>
    </div>
  </div>
</form>
