
<div class="material-datatable-wrapper">
  <div class="popup-window__buttons mb-3" *ngIf="editable == null">
    <button class="button button_sm button_main mr-2" type="button" (click)="toggleStatus(true)">Мероприятие прошло</button>
    <button class="button button_sm button_main mr-2" type="button" (click)="toggleStatus(false)">Мероприятие отменено</button>
    <button class="button button_sm button_main" type="button" (click)="input.click()">Загрузить участников</button>
    <div [hidden]="true" class="hidden">
      <input #input type="file" (change)="onFileChange($event)">
    </div>
  </div>
  <div *ngIf="unreg" class="col-6">
    <p>Незарегистрированные в системе участники:</p>
    <ul>
      <li *ngFor="let i of unreg" class="ml-1"><a [routerLink]="['/physical/edit/', i['id']]">{{i['fio']}} ({{i['count']}})</a></li>
    </ul>
  </div>
  <div class="table-responsive">
    <table class="table datatable" [ngClass]="cssClass">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>

<ng-template #presence let-row="row">
  <ng-container [ngSwitch]="getRowApproved(row)">
    <ng-container *ngSwitchCase="null">
      <a (click)="changeStatus(row, true)" href="javascript: void(0)">Присутствовал</a> /
      <a (click)="changeStatus(row, false)" href="javascript: void(0)">Не&nbsp;присутствовал</a>
    </ng-container>
    <ng-container *ngSwitchCase="false">
      Не&nbsp;присутствовал.
      <a (click)="changeStatus(row, null)" href="javascript: void(0)">Отменить</a>
    </ng-container>
    <ng-container *ngSwitchCase="true">
      Присутствовал.
      <a (click)="changeStatus(row, null)" href="javascript: void(0)">Отменить</a>
    </ng-container>
  </ng-container>
  <!--<label>-->
    <!--<i class="fa" [ngClass]="{-->
      <!--'mute': null === row['approved'],-->
      <!--'fa-square-o': !row['approved'],-->
      <!--'fa-check-square-o': row['approved']-->
      <!--}"></i>-->
    <!--<input type="checkbox" [hidden]="true" value="1" [(ngModel)]="row['approved']"-->
           <!--(change)="changeStatus(row)">-->
  <!--</label>-->
</ng-template>

<ng-template #physical_fio let-row="row">
  <a [routerLink]="['/physical/edit/', row['physical_id']]">{{row['physical_fio']}}</a>
</ng-template>

<ng-template #organisation_socrachcaption let-row="row">
  <a [routerLink]="['/entity/edit/', row['organisation_id']]">{{row['organisation_socrachcaption']}}</a>
</ng-template>


<ng-template #physical_fio_head let-row="row">
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_fio" [cssClass]="'mb-0'" [placeholder]="'ФИО'">
    </app-material-input>
  </form>
</ng-template>
<ng-template #organisation_socrachcaption_head let-row="row">
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="organisation_socrachcaption" [cssClass]="'mb-0'" [placeholder]="'Агентство'">
    </app-material-input>
  </form>
</ng-template>
<ng-template #physical_phone_head let-row="row">
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_phone" [cssClass]="'mb-0'" [placeholder]="'Телефон'">
    </app-material-input>
  </form>
</ng-template>
<ng-template #physical_email_head let-row="row">
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_email" [cssClass]="'mb-0'" [placeholder]="'Эл. почта'">
    </app-material-input>
  </form>
</ng-template>
<ng-template #presence_head let-row="row">
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="presence" [cssClass]="'mb-0'" [placeholder]="'Присутствовал(а)'">
    </app-material-input>
  </form>
</ng-template>
<ng-template #agenstvo_head let-row="row">
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="organisation_address" [cssClass]="'mb-0'" [placeholder]="'Адрес'">
    </app-material-input>
  </form>
</ng-template>
<ng-template #dostup_head let-row="row">
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="post" [cssClass]="'mb-0'" [placeholder]="'Доступ'">
    </app-material-input>
  </form>
</ng-template>
