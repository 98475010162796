import { Injectable } from '@angular/core';
import { DictionariesService } from '../../../services/dictionaries.service';

@Injectable()
export class ImportPeriodicResolve {

  constructor(private dict_helper: DictionariesService) {
  }

  resolve() {
    return this.dict_helper.get('Dict.get_import_periodic');
  }
}
