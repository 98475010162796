import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WizardComponent } from './component/wizard.component';
import { WizardStepComponent } from './component/wizard-step.component';
import { WizardGuard } from '../../guards/wizard.guard';

export * from './component/wizard.component';
export * from './component/wizard-step.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    WizardComponent,
    WizardStepComponent
  ],
  exports: [
    WizardComponent,
    WizardStepComponent
  ]
})
export class FormWizardModule {
  static forRoot(): ModuleWithProviders<FormWizardModule> {
    return {
      ngModule: FormWizardModule
    };
  }
}
