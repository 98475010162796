import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Optional, Output, QueryList } from '@angular/core';
import {PageSliderElementComponent} from './page-slider-element/page-slider-element.component';
import {EmbededService} from '../../services/embeded.service.';


@Component({
  selector: 'app-page-slider',
  templateUrl: './page-slider.component.html',
  styleUrls: ['./page-slider.component.scss']
})
export class PageSliderComponent implements AfterContentInit {

  @Input() public cssClass;
  @Output() public complete: EventEmitter<any> = new EventEmitter<any>();
  @Output() public close: EventEmitter<any> = new EventEmitter<any>();
  @Output() public allPages: EventEmitter<any> = new EventEmitter<any>();
  @ContentChildren(PageSliderElementComponent) public element: QueryList<PageSliderElementComponent>;

  public activeIndex = 0;
  public activeElement: PageSliderElementComponent;

  constructor(@Optional() public embeddedService: EmbededService) { }

  ngAfterContentInit() {
    const active_count = this.element.filter(x => x.is_active).length;

    if (this.element.length && !active_count) {
      this.activeFirst();
    }
  }

  activeFirst() {
    this.element.first.ready.then(() => this.activate(this.element.first));
  }

  setActive() {
    this.element.forEach((item, index) => {
      if (item.is_active === true) {
        this.activeElement = item;
        this.activeIndex = index;
      }
    });
  }

  activate(tab: PageSliderElementComponent) {
    this.element.forEach(x => x.is_active = false);
    tab.is_active = true;
    this.setActive();
  }

  onBack() {
    const element = this.element.find((item, index) => index === (this.activeIndex - 1));
    if (element) {
      this.activate(element);
    }
  }

  onNext() {
    const element = this.element.find((item, index) => index === (this.activeIndex + 1));
    if (element) {
      this.activate(element);
    }
  }

  onComplete() {
    this.complete.emit(true);
  }

  onClose() {
    this.close.emit(true);
  }

  forAllPages() {
    this.allPages.emit(true);
  }

}
