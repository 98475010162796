import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HolidayListComponent } from './holiday-list.component';
import { DataTableModule } from '../../modules/@datatable/datatables.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialInputModule } from '../../modules/@material-input';
import {HolidayCreateComponent} from './holiday-create/holiday-create.component';
import {HolidayEditComponent} from './holiday-edit/holiday-edit.component';
import {HolidayPreviewComponent} from './holiday-preview/holiday-preview.component';
import {PositionSelectorComponent} from '../../shared/position-selector/position-selector.component';
import {PositionSelectorModule} from '../../shared/position-selector/position-selector.module';

@NgModule({
  imports: [
    CommonModule,
    DataTableModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
    PositionSelectorModule,
  ],
    declarations: [HolidayListComponent,
        HolidayCreateComponent,
        HolidayEditComponent,
        HolidayPreviewComponent],
  exports: [HolidayListComponent]
})
export class HolidayModule {
}
