
<div class="compare__body">
  <app-online-view-table [columns]="columns"></app-online-view-table>
</div>

<ng-template #offlineStaff let-row="row">
  <a href="javascript:void(0)" (click)="displayStaff(row)" *ngIf="row['cnt_staff_offline']">
    {{row['cnt_staff_offline']}}
  </a>
  <span *ngIf="!row['cnt_staff_offline']">{{row['cnt_staff_offline']}}</span>
</ng-template>

<ng-template #onlineStaff let-row="row">
  <a href="javascript:void(0)" (click)="displayStaffOnline(row)" *ngIf="row['cnt_staff_online']">
    {{row['cnt_staff_online']}}
  </a>
  <span *ngIf="!row['cnt_staff_online']">{{row['cnt_staff_online']}}</span>
</ng-template>

<ng-template #allStaff let-row="row">
  <a href="javascript:void(0)" (click)="displayAllStaff(row)" *ngIf="row['cnt_staff']">
    {{row['cnt_staff']}}
  </a>
  <span *ngIf="!row['cnt_staff']">{{row['cnt_staff']}}</span>
</ng-template>
