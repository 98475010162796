import {Component, Input, Optional} from '@angular/core';
import {EmbededService} from '../../services/embeded.service.';

@Component({
  selector: 'app-stock-badge',
  templateUrl: './stock-badge.component.html',
  styleUrls: [
    './stock-badge.component.scss'
  ]
})
export class StockBadgeComponent {

  @Input() public color;
  @Input() public text;
  @Input() public link;
  @Input() public title;
  @Input() public isPrivateHighlight;

  public host = location.host;

  constructor(@Optional() public embeddedService: EmbededService) {}

}
