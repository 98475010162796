import {AfterContentInit, Component, ContentChildren, Input, QueryList} from '@angular/core';
import {TabsElementComponent} from './tabs-element.component';

@Component({
  selector: 'app-tab-wrapper',
  templateUrl: './tabs-wrapper.component.html',
  styleUrls: ['./tabs-wrapper.component.scss']
})
export class TabsWrapperComponent implements AfterContentInit {

  @Input() public cssClass;
  public headCssClass;
  public contentCssClass;

  @Input() public tabClass = null;
  @ContentChildren(TabsElementComponent) public tabs: QueryList<TabsElementComponent>;

  @Input('mode') private set mode(value: string) {
    if ('left' === value) {
      this.headCssClass = 'tabs-left';
      this.contentCssClass = ' tabs-left-content';
    }

    if ('right' === value) {
      this.headCssClass = 'tabs-right';
      this.contentCssClass = ' tabs-right-content';
    }

    if ('vertical' === value) {
      this.cssClass = 'navigation_vertical';
    }
  }

  ngAfterContentInit() {
    const active_count = this.tabs.filter(x => x.is_active).length;

    if (this.tabs.length && !active_count) {
      this.tabs.first.ready.then(() => this.tabs.first.is_active = true);
    }
  }

  activate(tab: TabsElementComponent) {
    this.tabs.forEach(x => x.is_active = false);
    tab.is_active = true;
  }

}
