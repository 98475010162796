<div class="material-datatable-wrapper">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)"
    [templateRight]="buttonCreate">
  </app-datatable-headline>
  <div class="table-responsive">
    <table class="table table-striped datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>

<ng-template #buttonCreate>
  <div class="text-right">
    <a class="button button_sm" href="javascript: void(0)" (click)="createPhysical()">
      Создать<span class="d-none d-md-inline"> физическое лицо</span>
    </a>
  </div>
</ng-template>

<ng-template #isUserTmpl let-row="row">
  {{(row.is_user) ? 'Да' : 'Нет'}}
</ng-template>

<ng-template #org2phyCaptiondTmpl let-row="row">
  <div [innerHtml]="row.org2phy_caption"></div>
</ng-template>

<ng-template #clientTmpl let-row="row" let-ind="row_index">
  <div [ngClass]="{'activeBox': goBack.activeHash === 'physical_edit-' + ind}">
    <a href="/physical/edit{{row.physical_id}}"
       (click)="goBack.newLink('/physical/edit/' + row.physical_id, 'physical_edit-' + ind, $event)">
      <i class="fa fa-user"></i>
    </a>
  </div>
</ng-template>

<ng-template #physicalIdHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_id" [cssClass]="'mb-0'" [placeholder]="'#'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #physicalAuthor let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_author" [cssClass]="'mb-0'" [placeholder]="'Автор'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #physicalFio let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_fio" [cssClass]="'mb-0'" [placeholder]="'ФИО'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #org2phyCaption let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="org2phy_caption" [cssClass]="'mb-0'" [placeholder]="'Организация'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #isUser let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-select formControlName="is_user" [cssClass]="'mb-0'">
      <option [value]="''">Все</option>
      <option [value]="'true'">Да</option>
      <option [value]="'false'">Нет</option>
    </app-material-select>
  </form>
</ng-template>

<ng-template #userLogin let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="user_login" [cssClass]="'mb-0'" [placeholder]="'Логин'">
    </app-material-input>
  </form>
</ng-template>


<ng-template #physicalPhone let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_phone" [cssClass]="'mb-0'" [placeholder]="'Телефон'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #physicalEmail let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="physical_email" [cssClass]="'mb-0'" [placeholder]="'Email'">
    </app-material-input>
  </form>
</ng-template>
