<div class="table-responsive">
  <table class="table table-striped datatable">
    <thead>
    <tr>
      <th *ngFor="let th of headers" [innerHtml]="th.html"></th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let row of rows">
      <tr *ngIf="row.component['template']">
        <ng-container [ngTemplateOutlet]="row.component['template']"></ng-container>
      </tr>
      <tr *ngIf="!row.component['template']" [innerHtml]="row.html"></tr>
    </ng-container>
    </tbody>
</table>
</div>

<app-simple-table-pagination [totalRows]="totalRows" (currentPageEmitter)="onPageSet($event)">
</app-simple-table-pagination>
