import { AfterContentInit, Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-slider-element',
  templateUrl: './page-slider-element.component.html',
  styleUrls: ['./page-slider-element.component.scss']
})
export class PageSliderElementComponent implements AfterContentInit {

  @Input('caption') public caption;

  public ready: Promise<void>;
  private readyResolve: () => void;
  private _is_active: boolean;

  public get is_active(): boolean {
    return this._is_active;
  }

  @Input('is_active')
  public set is_active(value: boolean) {
    this._is_active = value;
  }

  constructor() {
    this.ready = new Promise<void>((resolve: () => void) => this.readyResolve = resolve);
  }

  ngAfterContentInit() {
    this.readyResolve();
  }

}
