import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DataTableComponent} from '../../../modules/@datatable/components';

@Component({
  selector: 'app-all-events-participants-table',
  templateUrl: './all-events-participants-table.component.html',
  styleUrls: ['./all-events-participants-table.component.scss']
})
export class AllEventsParticipantsTableComponent extends DataTableComponent implements OnInit {

  @Input() public event_id;
  @ViewChild('presence') private presence;

  public api_action = 'Calendar.dt_event_subscriptions';

  ngOnInit() {
    this.columns = [
      {'caption': 'ФИО', 'name': 'physical_fio', 'sortable': true},
      {'caption': 'Организация', 'name': 'organisation_socrachcaption', 'sortable': true},
      {'caption': 'Телефон', 'name': 'physical_phone', 'sortable': true},
      {'caption': 'Эл. почта', 'name': 'physical_email', 'sortable': true},
      {'caption': 'Присутствовал(а)', 'name': 'physical_fio', 'sortable': true, 'template': this.presence},
    ];

    super.ngOnInit();
  }

  prepareQuery() {
    const options = super.prepareQuery();

    options['event_id'] = this.event_id;

    return options;
  }

  changeStatus(row) {
    const options = {
      'event_id': this.event_id,
      'physical_id': row['physical_id'],
      'organisation_id': row['organisation_id'],
      'approve_is': row['approved'],
    };
    this.http.post('Calendar.set_approve_subscription', options).subscribe();
  }

}
