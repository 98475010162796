import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tozero'
})
export class ToZeroPipe implements PipeTransform {

  transform(value: any) {
    return value ? value : '0';
  }
}
