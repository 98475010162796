<app-tab-wrapper>
  <app-tab-element [caption]="'Основное'">
    <app-alt-entity-common></app-alt-entity-common>
  </app-tab-element>
  <app-tab-element [caption]="'Дополнительно'">
    <app-alt-entity-advanced></app-alt-entity-advanced>
  </app-tab-element>
  <app-tab-element [caption]="'Сотрудники'">
    <app-alt-entity-relationships></app-alt-entity-relationships>
  </app-tab-element>
</app-tab-wrapper>
<br>
<button class="button" (click)="saveData()" [disabled]="!form.valid">Сохранить</button>
