import {Component, Host, Input, OnDestroy, OnInit} from '@angular/core';
import {FlatInfoStandaloneComponent} from '../flat-info-standalone.component';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DictionariesService} from '../../../../../services/dictionaries.service';
import {HttpClient} from '../../../../../services/http.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-flat-info-common',
  templateUrl: './flat-common.component.html',
  styleUrls: [
    './flat-common.component.scss'
  ]
})
export class FlatCommonComponent implements OnInit, OnDestroy {

  @Input('flat_id') public flat_id;
  @Input('floor_id') public floor_id;

  public form: UntypedFormGroup;

  public type_otd: Array<any> = [];
  public chain_list: Array<any> = [];

  private subscriptions: Array<Subscription> = [];

  public get rooms(): UntypedFormArray {
    return this.form.get('common').get('flat_rooms_description') as UntypedFormArray;
  }

  constructor(@Host() private host: FlatInfoStandaloneComponent,
              private fb: UntypedFormBuilder, private dict_helper: DictionariesService,
              private http: HttpClient) { }

  ngOnInit() {
    const promises = [
      this.dict_helper.get('Dict.get_realty_type_otd'),
      this.dict_helper.get('Housing.get_chain'),
    ];

    Promise.all(promises).then(result => {
      this.type_otd = result.shift();
      this.chain_list = result.shift();

      if (this.flat_id) {
        this.http.post('Flat.get_common', {'flat_id': +this.flat_id})
          .subscribe(data => {
            this.initializeFormCommon(data);
            const title = data.complex_caption + ' - ' + data.housing_caption;
            this.host.setTitle(title);
          });
      } else {
        this.initializeFormCommon();
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  addRoom() {
    this.rooms.push(this.fb.group({
      'caption': '',
      'area': '',
    }));
  }

  dropRoom(index) {
    this.rooms.removeAt(index);
  }

  private getRooms(rooms: Array<{[p: string]: string}>) {
    return rooms.map(x => this.fb.group({
      'caption': x['caption'],
      'area': x['area'],
    }));
  }

  private initializeFormCommon(data = {}) {
    this.form = this.host.form;

    this.form.addControl('common', this.fb.group({
      'housing_caption': [{'value': data['housing_caption'], 'disabled': true}],
      'section_caption': [{'value': data['section_caption'], 'disabled': true}],
      'floor_caption': [{'value': data['floor_caption'], 'disabled': true}],
      'flat_num': [data['flat_num'], Validators.required],
      'flat_num_pp': [data['flat_num_pp'], Validators.required],
      'flat_rooms': [data['flat_rooms'], Validators.required],
      'flat_price': [data['flat_price'], Validators.required],
      'flat_price_promo': [data['flat_price_promo'] || ''],
      'flat_plochad_obsh': [data['flat_plochad_obsh'], Validators.required],
      'flat_stoimost_kv_metra': [{'value': data['flat_stoimost_kv_metra'], 'disabled': true}],
      'flat_category': [data['flat_category']],
      'flat_dog_bron': [data['flat_dog_bron']],
      'flat_koef_balkonov': [data['flat_koef_balkonov'] || '0.3'],
      'flat_koef_lodjiy': [data['flat_koef_lodjiy'] || '0.5'],
      'flat_kol_vo_balkonov': [data['flat_kol_vo_balkonov']],
      'flat_kol_vo_lodjiy': [data['flat_kol_vo_lodjiy']],
      'flat_plochad_bez_neotaplivaemich': [data['flat_plochad_bez_neotaplivaemich']],
      'flat_plosch_balkonov': [data['flat_plosch_balkonov']],
      'flat_plosch_lodjiy': [data['flat_plosch_lodjiy']],
      'flat_ploshad_kuchni': [data['flat_ploshad_kuchni']],
      'flat_ploshad_shil': [data['flat_ploshad_shil']],
      'flat_pom_type': [data['flat_pom_type']],
      'flat_stoimost_kv_metra_ddu': [data['flat_stoimost_kv_metra_ddu']],
      'flat_subagent_agd': [data['flat_subagent_agd']],
      // 'realty_parent_chain_caption': [data['realty_parent_chain_caption']],

      'flat_rooms_description': this.fb.array(this.getRooms(data['flat_rooms_description'] || []))
    }));

    this.addSubscriptions();
  }

  private changeSquareTotal() {
    setTimeout(_ => {
      if (this.form.value['common']['flat_ploshad_shil'] && this.form.value['common']['flat_ploshad_kuchni']) {
        let flat_ploshad_shil = this.form.value['common']['flat_ploshad_shil'];
        let flat_ploshad_kuchni = this.form.value['common']['flat_ploshad_kuchni'];

        flat_ploshad_shil = flat_ploshad_shil ? flat_ploshad_shil.toString() : '';
        flat_ploshad_kuchni = flat_ploshad_kuchni ? flat_ploshad_kuchni.toString() : '';

        flat_ploshad_shil = parseFloat(flat_ploshad_shil.replace(',', '.'));
        flat_ploshad_kuchni = parseFloat(flat_ploshad_kuchni.replace(',', '.'));

        if (flat_ploshad_shil && flat_ploshad_kuchni && !this.form.value['common']['flat_plochad_obsh']) {
          this.form.get('common').patchValue({
            'flat_plochad_obsh': (flat_ploshad_shil + flat_ploshad_kuchni).toFixed(2),
          }, {onlySelf: true});
        }
      }
    }, 100);
  }

  private changeSquareNP() {
    setTimeout(_ => {
      let flat_plochad_obsh = this.form.value['common']['flat_plochad_obsh'];
      let flat_plosch_balkonov = this.form.value['common']['flat_plosch_balkonov'];
      let flat_plosch_lodjiy = this.form.value['common']['flat_plosch_lodjiy'];

      let flat_koef_balkonov = this.form.value['common']['flat_koef_balkonov'];
      let flat_koef_lodjiy = this.form.value['common']['flat_koef_lodjiy'];

      flat_koef_balkonov = flat_koef_balkonov ? flat_koef_balkonov.toString() : '';
      flat_koef_lodjiy = flat_koef_lodjiy ? flat_koef_lodjiy.toString() : '';

      flat_koef_balkonov = flat_koef_balkonov && parseFloat(flat_koef_balkonov.replace(',', '.')) || 0;
      flat_koef_lodjiy = flat_koef_lodjiy && parseFloat(flat_koef_lodjiy.replace(',', '.')) || 0;

      flat_plochad_obsh = flat_plochad_obsh && parseFloat(flat_plochad_obsh.replace(',', '.')) || 0;
      flat_plosch_balkonov = flat_plosch_balkonov && parseFloat(flat_plosch_balkonov.replace(',', '.')) || 0;
      flat_plosch_lodjiy = flat_plosch_lodjiy && parseFloat(flat_plosch_lodjiy.replace(',', '.')) || 0;

      if (flat_plochad_obsh && (flat_plosch_balkonov || flat_plosch_lodjiy)) {
        this.form.get('common').patchValue({
          'flat_plochad_bez_neotaplivaemich': (flat_plochad_obsh - flat_plosch_balkonov * flat_koef_balkonov
            - flat_plosch_lodjiy * flat_koef_lodjiy).toFixed(2),
        }, {onlySelf: true});
      }

    }, 100);
  }

  private refreshPrices(type = 'total') {
    setTimeout(_ => {
      let flat_price = this.form.value['common']['flat_price'];
      let flat_plochad_obsh = this.form.value['common']['flat_plochad_obsh'];
      let flat_stoimost_kv_metra = this.form.value['common']['flat_stoimost_kv_metra'];


      flat_price = flat_price && parseFloat(flat_price.toString().replace(',', '.')) || 0;
      flat_plochad_obsh = flat_plochad_obsh && parseFloat(flat_plochad_obsh.toString().replace(',', '.')) || 0;
      flat_stoimost_kv_metra = flat_stoimost_kv_metra && parseFloat(flat_stoimost_kv_metra.toString().replace(',', '.')) || 0;

      if (!flat_plochad_obsh) {
        return;
      }

      if (type === 'total') {
        this.form.get('common').get('flat_stoimost_kv_metra').setValue(
          (flat_price / flat_plochad_obsh).toFixed(2),
          {onlySelf: true}
        );
      }
    }, 100);
  }

  private addSubscriptions() {
    this.subscriptions.push(this.form.get('common').get('flat_ploshad_shil').valueChanges
      .subscribe(_ => this.changeSquareTotal()));

    this.subscriptions.push(this.form.get('common').get('flat_ploshad_kuchni').valueChanges
      .subscribe(_ => this.changeSquareTotal()));

    this.subscriptions.push(this.form.get('common').get('flat_plochad_obsh').valueChanges
      .subscribe(_ => this.changeSquareNP()));

    this.subscriptions.push(this.form.get('common').get('flat_plosch_balkonov').valueChanges
      .subscribe(_ => this.changeSquareNP()));

    this.subscriptions.push(this.form.get('common').get('flat_plosch_lodjiy').valueChanges
      .subscribe(_ => this.changeSquareNP()));

    this.subscriptions.push(this.form.get('common').get('flat_price').valueChanges
      .subscribe(_ => this.refreshPrices()));
  }

}
