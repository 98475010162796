import {AfterViewInit, Component, ComponentRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {HttpClient} from '../../../../../services/http.service';
import {DataTableParamComponent} from '../../../../../modules/@datatable/components/datatable-param.component';
import {OverlayWindowService} from '../../../../single-window/shared/overlay-window.service';
import {BackrefWindowService} from '../../../../single-window/shared/backref-window.service';
import {GoBackButtonService} from '../../../../../services/go-back-button.service';
import {environment} from '../../../../../../environments/environment';
import {AuthenticateService} from '../../../../../services/authenticate.service';

@Component({
  selector: 'app-attraction-lead-info-all',
  templateUrl: './attraction-lead-info-all.component.html',
  styleUrls: ['./attraction-lead-info-all.component.scss']
})
export class AttractionLeadInfoAllComponent extends DataTableParamComponent implements OnInit, OnDestroy, AfterViewInit {

  public api_action = 'Bp.dt_leads_calling_in';
  public columns: Array<{ [key: string]: any }>;
  public formGroup: UntypedFormGroup;
  public subscription: Subscription;
  public data: any;

  public buttonLink = '';
  public urlLead = environment.apiServer + 'restful/lead-info/';
  public subscribtionUser: Subscription;
  public user = null;

  private firstLoad = true;
  @ViewChild('fioHead', {static: true}) private fioHead: TemplateRef<any>;
  @ViewChild('fioTmpl', {static: true}) private fioTmpl: TemplateRef<any>;
  @ViewChild('emailHead', {static: true}) private emailHead: TemplateRef<any>;
  @ViewChild('phoneHead', {static: true}) private phoneHead: TemplateRef<any>;
  @ViewChild('phoneTmpl', {static: true}) private phoneTmpl: TemplateRef<any>;
  @ViewChild('create_timeTmpl', {static: true}) private create_timeTmpl: TemplateRef<any>;
  @ViewChild('access_idHead', {static: true}) private access_idHead: TemplateRef<any>;
  @ViewChild('access_idTmpl', {static: true}) private access_idTmpl: TemplateRef<any>;
  @ViewChild('next_contact_dateHead', {static: true}) private next_contact_dateHead: TemplateRef<any>;
  @ViewChild('next_contact_dateTmpl', {static: true}) private next_contact_dateTmpl: TemplateRef<any>;
  @ViewChild('noticeHead', {static: true}) private noticeHead: TemplateRef<any>;
  @ViewChild('stage_captionHead', {static: true}) private stage_captionHead: TemplateRef<any>;
  @ViewChild('stage_change_timeHead', {static: true}) private stage_change_timeHead: TemplateRef<any>;
  @ViewChild('status_descriptionTmpl', {static: true}) private status_descriptionTmpl: TemplateRef<any>;
  @ViewChild('organisation_captionHead', {static: true}) private organisation_captionHead: TemplateRef<any>;
  @ViewChild('organisation_captionTmpl', {static: true}) private organisation_captionTmpl: TemplateRef<any>;
  @ViewChild('ci_sourceHead', {static: true}) private ci_sourceHead: TemplateRef<any>;
  @ViewChild('ci_sourceTmpl', {static: true}) private ci_sourceTmpl: TemplateRef<any>;
  @ViewChild('subagent_area_workHead', {static: true}) private subagent_area_workHead: TemplateRef<any>;
  @ViewChild('subagent_area_workTmpl', {static: true}) private subagent_area_workTmpl: TemplateRef<any>;

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router,
              public fb: UntypedFormBuilder, private overlayWindowService: OverlayWindowService, private auth: AuthenticateService,
              private backrefWindowService: BackrefWindowService, public goBack: GoBackButtonService) {
    super(http, route, router);
    this.formGroup = this.fb.group({
      'fio': [''],
      'email': [''],
      'phone': [''],
      'create_time': [''],
      'next_contact_date': [''],
      'next_contact_time': [''],
      'date_start': [''],
      'date_end': [''],
      'last_days': [''],
      'access_id': [''],
      'tab': [''],
      'object_caption': [''],
      'subagent_user_fio': [''],
      'stage_caption': [''],
      'status_description': [''],
      'organisation_caption': [''],
      'ci_source': [''],
      'ci_subagent_area_worker': ['']
    });

    this.subscription = this.formGroup.valueChanges.pipe(debounceTime(500))
      .subscribe((data) => {
        this.router_param['page'] = 1;
        this.current_page = 1;
        if (this.firstLoad) {
          this.firstLoad = false;
        } else {
          this.changeForm(this.formGroup);
        }
      });
    this.updateForm(this.formGroup);
    this.backrefWindowService.routerInit();
  }

  ngOnInit() {
    this.goBack.header = '<h1>Лиды по привлечению</h1>';
    this.subscribtionUser = this.auth.emit_user.subscribe((user) => this.user = user);
    this.data = this.route.snapshot.data['data'];
    this.columns = [
      {'caption': 'Организация', 'name': 'organisation_caption', 'sortable': true,
        'templateHead': this.organisation_captionHead, 'template': this.organisation_captionTmpl},
      {'caption': 'Субагент', 'name': 'fio', 'sortable': true, 'width': '210px',
        'template': this.fioTmpl, 'templateHead': this.fioHead},
      {'caption': 'Телефон субагента', 'name': 'phone', 'sortable': true, 'templateHead': this.phoneHead,
        'template': this.phoneTmpl, 'width': '210px'},
      {'caption': 'Следующий контакт', 'name': 'next_contact_date', 'sortable': true,
        'template': this.next_contact_dateTmpl, 'templateHead': this.next_contact_dateHead},
      {'caption': 'Статус', 'name': 'access_id', 'sortable': true, 'width': '210px',
        'templateHead': this.access_idHead, 'template': this.access_idTmpl},
      {'caption': 'Этап', 'name': 'stage_caption', 'sortable': true, 'templateHead': this.stage_captionHead},
      {'caption': 'Дата создания', 'name': 'create_time', 'sortable': true, 'width': '280px',
        'template': this.create_timeTmpl},
      {'caption': 'Статус', 'name': 'status_description', 'sortable': false,
        'template': this.status_descriptionTmpl},
      {'caption': 'Источник', 'name': 'ci_source', 'sortable': true, 'width': '210px',
        'templateHead': this.ci_sourceHead, 'template': this.ci_sourceTmpl},
      {
        'caption': 'Направление работы', 'name': 'ci_subagent_area_worker', 'templateHead': this.subagent_area_workHead,
        'template': this.subagent_area_workTmpl
      }
    ];
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  prepareQuery() {
    const result = super.prepareQuery();
    result['filter']['fields'] = {};
    result['filter']['fields'] = {
      'fio': this.formGroup.value.fio,
      'email': this.formGroup.value.email,
      'phone': this.formGroup.value.phone,
      'create_time': this.formGroup.value.create_time,
      'date_start': this.formGroup.value.date_start,
      'date_end': this.formGroup.value.date_end,
      'tab': this.formGroup.value.tab,
      'access_id': this.formGroup.value.access_id,
      'organisation_caption': this.formGroup.value.organisation_caption,
      'next_contact_date': this.formGroup.value.next_contact_date,
      'notice': this.formGroup.value.notice,
      'stage_caption': this.formGroup.value.stage_caption,
      'ci_source': this.formGroup.value.ci_source,
      'global_tab': 'all',
      'ci_subagent_area_worker': this.formGroup.value.ci_subagent_area_worker
    };
    this.buttonLink = '';
    for (const [key, value] of Object.entries(result['filter']['fields'])) {
      if (value !== '' && value !== undefined && key !== 'global_tab') {
        this.buttonLink += key + '=' + value + '&';
      }
    }
    if (this.buttonLink !== '') {
      this.buttonLink = '/all?' + this.buttonLink.slice(0, this.buttonLink.length - 1);
    } else {
      this.buttonLink = '/all';
    }
    return result;
  }

  filter_state(string) {
    if (this.formGroup.value.tab === string) {
      string = '';
    }
    this.formGroup.patchValue({
      'tab': string,
    });
  }

  ngAfterViewInit() {
    this.goBack.goScroll();
  }
}
