import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import { filter } from 'rxjs/operators';
import {GoBackButtonService} from '../../services/go-back-button.service';

declare const moment;

@Component({
  templateUrl: './online.component.html',
  styleUrls: ['./online.component.scss']
})
export class OnlineComponent implements OnInit, OnDestroy {

  public data;
  public header_info;
  public form;
  public hours;

  public hours_work_day = [
    8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19
  ];

  public hours_full_day = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
  ];

  private subscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private fb: UntypedFormBuilder, private goBack: GoBackButtonService) {
    this.subscription = this.router.events.pipe(filter(x => x instanceof NavigationEnd))
      .subscribe(_ => {
        this.header_info = this.activatedRoute.snapshot.data['data'].header_info;
        this.data = this.activatedRoute.snapshot.data['data'].rows;
        const modification = this.activatedRoute.snapshot.params['modification'] || 'workday';

        this.hours = this.hours_work_day;

        if (modification === 'fullday') {
          this.hours = this.hours_full_day;
        }
      });
  }

  ngOnInit() {
    this.goBack.header = '<h1>Активность сотрудников</h1>';
    const date = this.activatedRoute.snapshot.params['date'] || moment().format('DD.MM.YYYY'),
      modification = this.activatedRoute.snapshot.params['modification'] || 'workday';

    this.hours = this.hours_work_day;

    if (modification === 'fullday') {
      this.hours = this.hours_full_day;
    }

    this.form = this.fb.group({
      'date': [date, Validators.required],
      'modification': [modification, Validators.required],
    });


  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onSearch() {
    this.router.navigateByUrl(`/online/${this.form.value['date']}/${this.form.value['modification']}`);
  }

}
