
<div class="mb-2">
  <div class="row no-gutters align-items-center m-0">
    <div class="col-md-6 col-lg-6 col-xl-4">
      <div class="form__group">
        <div class="form__input">
          <input class="input" type="text" placeholder="Поиск"
                 [ngModel]="filter" (ngModelChange)="onFilterChanges($event)">
          <div class="form__input_icon icon icon_Small_Search c-pointer ng-star-inserted"></div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6 col-xl-8 text-right">
      <a class="button" routerLink="/managers/create/">Создать менеджера</a>
    </div>

  </div>
</div>

<div class="material-datatable-wrapper">
  <div class="table-responsive">
    <table class="table">
      <tr>
        <th *ngFor="let el of head" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
          <div [formGroup]="form">
            <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
              <div class="col table__input">
                <app-material-input
                  [placeholder] = "el.caption"
                  [formControlName] = "el.name"
                  [cssClass]="'mb-0'">
                </app-material-input>
              </div>
              <div class="col-auto" *ngIf="el.sort">
                <a href="javascript:void(0)" (click)="sortChange(el.name)">
                  <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                      'icon_new-sort-down': 'asc' == sort[el.name],
                                      'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                </a>
              </div>
            </div>
          </div>
        </th>
        <th>
          Удалить
        </th>
      </tr>
      <!-- // -->
      <tr *ngFor="let row of rows; let i = index">
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'fio' + i}">
            <a href="/managers/edit/{{row.id}}"
               (click)="goBack.newLink('/managers/edit/' + row.id, 'fio' + i, $event)">
              {{row['fio']}}
            </a>
          </div>
          <ng-container *ngIf="row.is_boss">
            руководитель
          </ng-container>
        </td>
        <td>{{row['create_time']}}</td>
        <td>{{row['phone']}}</td>
        <td>{{row['email']}}</td>
        <td>{{row['developer_caption']}}</td>
        <td>
          <ng-container *ngIf="row['realty_as_manager'].length">
            <div  *ngFor="let el of row.realty_as_manager">
              <ng-container *ngIf="el.type === 'housing_complex'">
                <a routerLink="/housing/{{el.id}}">
                  {{el.caption}}
                </a>
              </ng-container>
              <ng-container *ngIf="el.type === 'housing'">
                <a routerLink="/housing/house/{{el.id}}">
                  {{el.caption}}
                </a>
              </ng-container>
              <ng-container *ngIf="el.type === 'flat'">
                <a routerLink="/housing2/flat/{{el.id}}">
                  {{el.caption}}
                </a>
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="row['realty_as_manager_boss'].length">
            <p class="mt-3 mb-0">Руководитель менеджера</p>
            <div  *ngFor="let el of row.realty_as_manager_boss">
              <ng-container *ngIf="el.type === 'housing_complex'">
                <a routerLink="/housing/{{el.id}}">
                  {{el.caption}}
                </a>
              </ng-container>
              <ng-container *ngIf="el.type === 'housing'">
                <a routerLink="/housing/house/{{el.id}}">
                  {{el.caption}}
                </a>
              </ng-container>
              <ng-container *ngIf="el.type === 'flat'">
                <a routerLink="/housing2/flat/{{el.id}}">
                  {{el.caption}}
                </a>
              </ng-container>
            </div>
          </ng-container>
        </td>
        <td>
          <div class="button button_icon" (click)="deleteManager(row.id)">
            <i class="fa fa-trash"></i>
          </div>
        </td>

    </table>
  </div>
</div>

<div class="alt-pagination">
  <app-paginator
    [queryParams]="filter_columns"
    [itemsPerPage]="50"
    [itemCount]="count"
    [currentPage]="current_page"
    [baseUrl]="'/managers/'">
  </app-paginator>
</div>
