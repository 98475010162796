import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {JsonClientService} from '../../../shared/json-client';

@Injectable()
export class EntityEditAddressResolve {

  constructor(private jsonRpc: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    return this.jsonRpc.post('Address.get_address', {'id': +route.parent.params['id'], 'obj_type': 'organisation'});
  }

}
