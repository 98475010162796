import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '../../../services/http.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SingleWindowService } from '../../../_crm/single-window/shared/single-window.service';
import { CalendarStatisticService } from './calendar-statistic.service';
import { DataTableParamComponent } from '../../../modules/@datatable/components/datatable-param.component';
import { BackrefWindowService } from '../../../_crm/single-window/shared/backref-window.service';
import { filter } from 'rxjs/operators';
import {GoBackButtonService} from '../../../services/go-back-button.service';

declare const moment;

@Component({
  selector: 'app-calendar-statistic-table',
  templateUrl: './calendar-statistic-table.component.html',
  styleUrls: ['./calendar-statistic-table.component.scss']
})
export class CalendarStatisticTableComponent extends DataTableParamComponent implements OnInit, OnDestroy {

  private subscription: Subscription;

  public form: UntypedFormGroup;
  public types: Array<{ [p: string]: string | number }>;

  public event_complexes;
  public date_from;
  public date_to;
  public current_type;
  public url;
  private firstLoad = false;

  public api_action = 'Calendar.dt_all_events';

  constructor(public http: HttpClient, private fb: UntypedFormBuilder, public router: Router,
              public activatedRoute: ActivatedRoute,
              public singleWindowService: SingleWindowService,
              public calendarStatisticService: CalendarStatisticService,
              public backrefWindowService: BackrefWindowService,
              private goBack: GoBackButtonService) {
    super(http, activatedRoute, router);

    this.types = this.activatedRoute.snapshot.data['types'];

    this.subscription = this.router.events.pipe(filter(x => x instanceof NavigationEnd))
      .subscribe((_: NavigationEnd) => {
          this.goBack.header = '<h1>Статистика</h1>';
          let {from, to, type} = this.activatedRoute.snapshot.params;

          from = from || moment().startOf('month').format('DD.MM.YYYY');
          to = to || moment().endOf('month').format('DD.MM.YYYY');
          type = type || 'any';

          this.date_from = from;
          this.date_to = to;
          this.current_type = type;
          this.event_complexes = this.activatedRoute.snapshot.queryParams['event_complexes'];

          this.calendarStatisticService.backref = _.url;
          if (!this.firstLoad) {
            this.firstLoad = true;
          } else {
            this.loadList();
          }
      });
    this.backrefWindowService.routerInit();
  }

  ngOnInit() {
    this.event_complexes = this.activatedRoute.snapshot.queryParams['event_complexes'];
    this.form = this.fb.group({
      'event_complexes': [this.event_complexes],
      'event_date_from': [this.date_from, Validators.required],
      'event_date_to': [this.date_to, Validators.required],
      'event_type': [this.current_type, Validators.required],
    });

    super.ngOnInit();
  }

  public prepareQuery() {
    const query = super.prepareQuery();

    query['filter']['event_date_from'] = this.date_from;
    query['filter']['event_date_to'] = this.date_to;
    query['filter']['complex_caption'] = this.event_complexes;

    if (this.current_type && this.current_type !== 'any') {
      query['filter']['event_type'] = this.current_type;
    }

    return query;
  }

  submitForm() {
    const navigation = ['/calendar/statistic',
      this.form.value['event_date_from'],
      this.form.value['event_date_to'],
      this.form.value['event_type']];

    this.router.navigate(navigation, { queryParams: { 'event_complexes': this.form.value['event_complexes']} });
  }

  public loadList() {
    super.loadList();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.backrefWindowService.routerDestroy();
  }

}
