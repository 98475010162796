import { NgModule } from '@angular/core';
import { SingleWindowComponent } from './single-window.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SingleWindowHistoryComponent } from './single-window-history/single-window-history.component';
import { HistoryService } from './shared/history.service';
import { SingleWindowService } from './shared/single-window.service';
import { SingleWindowLayoutComponent } from './single-window-layout/single-window-layout.component';
import { OverlayWindowService } from './shared/overlay-window.service';
import { BackrefWindowService } from './shared/backref-window.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  providers: [
    HistoryService,
    SingleWindowService,
    OverlayWindowService,
    BackrefWindowService
  ],
  declarations: [
    SingleWindowComponent,
    SingleWindowHistoryComponent,
    SingleWindowLayoutComponent,
  ],
  exports: [
    SingleWindowComponent,
    SingleWindowLayoutComponent,
  ]
})
export class SingleWindowModule {
}
