import {Component, Input} from '@angular/core';


@Component({
  selector: 'app-control-index-card',
  templateUrl: './control-index-card.component.html',
  styleUrls: ['./control-index-card.component.scss']
})
export class ControlIndexCardComponent {
  @Input() public title;
  @Input() public description;
  @Input() public url;
  @Input() public count = null;
  @Input() public loading = false;

  constructor() {
  }

}
