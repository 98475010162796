import {Component, forwardRef, Input, OnChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { KeysPipe } from '../../../pipes/keys.pipe';

@Component({
  selector: 'app-material-input-radio-button',
  templateUrl: './material-input-radio-button.component.html',
  styleUrls: [
    './material-input-radio-button.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialInputRadioButtonComponent),
      multi: true
    }
  ]
})
export class MaterialInputRadioButtonComponent {
  @Input('require') public require = false;
  @Input('cssClass') public cssClass = '';
  @Input('labelClass') public labelClass = 'mb-2';
  @Input('icon') public icon = 'icon_done_mark';
  @Input('disabled') public disabled = false;
  @Input('data') public data = {};
  @Input('error') public error = false;

  private _current_value;

  public get current_value(): boolean {
    return this._current_value;
  }

  public set current_value(value: boolean) {
    this._current_value = value;
    this.propagateChange(this._current_value);
  }

  constructor(public keys: KeysPipe) {
  }

  propagateChange = (_: any) => {};

  onChange(value: any) {
    this.current_value = value;
  }

  writeValue(value: any) {
    this.current_value = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  onChanges(value: any) {
    if (!this.disabled) {
      this.current_value = value;
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

}
