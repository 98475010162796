<h1 class="mb-3">Создание акции </h1>
<form class="form form_crm" [formGroup]="form" *ngIf="form" novalidate (submit)="onSubmit()">
  <div class="row no-gutters gutters-4">
    <div class="col-12">
      <app-material-input formControlName="title"
                          [placeholder]="'Заголовок'"
                          [require]="true">
        Заголовок</app-material-input>
    </div>
    <div class="col-12">
      <app-wysiwyg-editor [placeholder]="'Краткое описание'" [require]="true" formControlName="description"></app-wysiwyg-editor>
    </div>
    <div class="col-md-6">
      <app-material-input-checkbox formControlName="is_url" [cssClass]="'mb-0'" [border]="true" [require]="true">
        Ссылка на баннере
      </app-material-input-checkbox>
    </div>
    <div class="col-12">
      <app-material-input formControlName="url"
                          [placeholder]="'Ссылка'">
        Ссылка</app-material-input>
    </div>
  </div>
  <div class="row no-gutters gutters-4">
    <div class="col-md-6 mt-2">
      <p>Баннер акции - десктоп</p>
      <app-material-input-dropzone-sortable
        formControlName="file_bn">
      </app-material-input-dropzone-sortable>
    </div>
    <div class="col-md-6 mt-2">
       <p>Баннер акции - моб.</p>
      <app-material-input-dropzone-sortable
        formControlName="file_icon">
      </app-material-input-dropzone-sortable>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mt-3">
      <app-material-input-dropzone-sortable
        formControlName="files" [hasComments]="true">
      </app-material-input-dropzone-sortable>
    </div>
  </div>
  <div class="row no-gutters gutters-2 mt-3 mb-5 mb-md-0">
    <div class="col-md-10">
      <button type="submit" class="button button_sm" [disabled]="!form.valid">Сохранить и закрыть</button>
    </div>
  </div>
</form>
