import { Component, forwardRef, HostListener, Input, OnDestroy, Optional } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CalendarInviteBindService } from '../../../shared/calendar/invites/calendar-invite-bind.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-material-input',
  templateUrl: './material-input.component.html',
  styleUrls: ['./material-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialInputComponent),
      multi: true
    }
  ]
})
export class MaterialInputComponent implements ControlValueAccessor, OnDestroy {

  @Input() public type = 'text';
  @Input() public withMb0 = false;
  @Input() public require = false;
  @Input() public autofill = true;
  @Input() public error = false;
  @Input() public cssClass = '';
  @Input() public placeholder = '';
  @Input() public disabled = false;
  @Input() public remove = true;
  @Input() public appendHtml = '';
  @Input() public trim = false;
  @Input() public descriptionTitle = '';
  @Input() public titleDescription = '';
  @Input() public maxLength: number | null = null;
  @Input() public label_overflow = 'hidden';

  protected  _current_value;
  private _delay_activated = false;
  private _focus = false;
  public hovered = false;

  public get focus(): boolean {
    return this._focus;
  }

  public set focus(value: boolean) {
    this._focus = value;

    if (value) {
      this._delay_activated = true;
    } else {
      setTimeout(_ => this._delay_activated = false, 100);
    }
  }

  private subscription: Subscription;

  public get current_value(): any {
    return this._current_value;
  }

  public set current_value(value: any) {
    if (this.trim) {
      this._current_value = value.trim();
    } else {
      this._current_value = value;
    }
    this.propagateChange(this._current_value);
  }

  propagateChange = (_: any) => {  };

  constructor(@Optional() bind: CalendarInviteBindService) {
    /*
     * Yep, it's not a best practices. Mad blacksmith mode ON =)))
     */
    if (bind) {
      this.subscription = bind.bindEmitter.subscribe(x => {
        if (this._delay_activated) {
          this.current_value += `{{${x}}}`;
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onChange(value: any) {
    this.current_value = value;
  }

  writeValue(value: any) {
    this.current_value = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
  }

  onChanges(target) {
    this.current_value = target.value;
  }

  onRemove(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.current_value) {
      this.current_value = '';
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  @HostListener('focusout', ['$event'])
  public onBlur(event) {
    if (this.current_value !== event.target.value) {
      this.current_value = event.target.value;
    }
  }

}
