import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {JsonClientService} from '../../../../shared/json-client';

@Injectable()
export class BookingInfoContentResolve {

  public route;
  constructor(private jsonRpc: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot, ...args): Observable<any> | Promise<any> | any {
    this.route = route;
    const tab = this.route.params.tab;

    const obj = {
      'tab': tab,
    }
    return this.jsonRpc.post('Deals.get_count_tab', obj);
  }

}
@Injectable()
export class BookingInfoContentDataResolve {

  public readonly filter_columns = [
    'date_start',
    'date_end',
    'date_change_start',
    'date_change_end',
    'tab',
    'next_contact_date',
    'housing_complex_caption',
    'object_caption',
    'physical_subagent_fio',
    'subagent_caption',
    'physical_subagent_phone',
    'appeal_client_fio',
    'lead_phone',
    'physical_manager_fio',
    'developer_caption',
    'stage_caption',
  ];

  constructor(private jsonRpc: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {

    const prepared_filter = {};
    const prepared_filter_keys = this.filter_columns.filter(x => !!route.parent.queryParams[x]);
    const sort = {};

    prepared_filter_keys.forEach(x => prepared_filter[x] = route.parent.queryParams[x]);

    if (!!route.queryParams['sort_name']) {
      sort[route.queryParams['sort_name']] = route.queryParams['sort_key'] || 'asc';
    }
     Object.assign(prepared_filter, {
       'global_tab': route.params['tab'],
     });
    const options = {
      'limit': 50,
      'offset': (+(route.queryParams['page'] || 1) - 1) * 50,
      'filter': {
        'global_search_string': route.queryParams['global_search_string'] || null,
        'fields': prepared_filter,
      },
      'sort': sort
    };
    return this.jsonRpc.post('Data_tables.get_deals_crm', options);
  }

}
