import {Component, Host, OnInit} from '@angular/core';
import {CreateEntityAltComponent} from '../create-entity.component';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DictionariesService} from '../../../../services/dictionaries.service';

@Component({
  selector: 'app-alt-entity-common',
  templateUrl: './create-entity-common.component.html',
  styleUrls: [
    './create-entity-common.component.scss'
  ]
})
export class CreateEntityAltCommonComponent implements OnInit {

  public form: UntypedFormGroup;

  public types;
  public forms;
  public managers;

  public get phones(): UntypedFormArray {
    return this.form.get('common').get('organisation_phones') as UntypedFormArray;
  }

  constructor(@Host() private hostComponent: CreateEntityAltComponent,
              private fb: UntypedFormBuilder, private dict: DictionariesService) { }

  ngOnInit() {
    this.form = this.hostComponent.form;

    Promise.all(
      [
        this.dict.get('Organisation.get_type'),
        this.dict.get('Organisation.get_forms'),
        this.dict.get('Dict.get_spn_managers'),
      ])
      .then((args) => {
        for (const field of ['types', 'forms', 'managers']) {
          this[field] = args.shift();
        }
      });

    this.hostComponent.form.addControl('common', this.fb.group({
      'organisation_form_id': [''],
      'organisation_type_id': [''],
      'organisation_caption': ['', Validators.required],
      'organisation_socrachcaption': [''],
      'organisation_rekl_naz_org': [''],
      'organisation_address': ['', Validators.required],
      'organisation_fact_addr': [''],
      'organisation_post_address': [''],
      'organisation_email': [''],
      'organisation_rekl_email': [''],
      'organisation_rekl_tel': [''],
      'organisation_website': [''],
      'organisation_gendir_fio': [''],
      'organisation_dolj_ryk': [''],
      'organisation_osn_dolj': [''],
      'organisation_glbuh_fio': [''],
      'organisation_notes': [''],
      'organisation_owner_id': [''],
      'organisation_manager_id': [''],
      'organisation_overall_unique_is': [false],
      'organisation_phones': this.fb.array([]),
    }));
  }

  appendPhone() {
    this.phones.push(this.fb.group({
      'phone': ['', Validators.required],
      'is_main': [false],
    }));
  }

  deletePhone(index) {
    this.phones.removeAt(index);
  }

  toggleMainPhone(index) {
    /*
     * Main phone handler
     */
    this.phones.controls.forEach(c => c.patchValue({
      'is_main': false,
    }));

    this.phones.controls[index].patchValue({
      'is_main': !this.phones.controls[index].value['is_main'],
    });
  }

}
