import {Component, Optional} from '@angular/core';
import {HelpWindowInterface} from '../../help-window.interface';
import {HelpWindowComponent} from '../../common/help-window.component';
import {HelpWindowService} from '../../../services/help-window.service';

@Component({
  selector: 'app-help-calendar-list',
  templateUrl: './help-calendar-list.component.html',
  styleUrls: [
    './help-calendar-list.component.scss',
    // '../../stylesheets/common-help.scss',
  ]
})
export class HelpCalendarListComponent extends HelpWindowComponent implements HelpWindowInterface {

  unique_id = 'calendar_list';

  constructor(@Optional() public hc: HelpWindowService) {
    super(hc);
  }

}
