import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CreatePhysicalAltComponent} from './create-physical/create-physical.component';
import {CreateEntityAltComponent} from './create-entity/create-entity.component';
import {CreatePhysicalAltCommonComponent} from './create-physical/common/create-physical-common.component';
import {TabsModule} from '../../modules/@tabs/tabs.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CreatePhysicalAltAdvancedComponent} from './create-physical/advanced/create-physical-advanced.component';
import {MaterialInputModule} from '../../modules/@material-input';
import {CreatePhysicalAltOrganisationsComponent} from './create-physical/organisations/create-physical-organisations.component';
import {CreateEntityAltCommonComponent} from './create-entity/common/create-entity-common.component';
import {CreateEntityAltAdvancedComponent} from './create-entity/advanced/create-entity-advanced.component';
import {CreateEntityAltRelationshipsComponent} from './create-entity/relationships/create-entity-relationships.component';

@NgModule({
    imports: [
        CommonModule,
        TabsModule,
        MaterialInputModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [
        CreatePhysicalAltComponent,
        CreateEntityAltComponent,
        CreatePhysicalAltCommonComponent,
        CreatePhysicalAltAdvancedComponent,
        CreatePhysicalAltOrganisationsComponent,
        CreateEntityAltCommonComponent,
        CreateEntityAltAdvancedComponent,
        CreateEntityAltRelationshipsComponent,
    ],
    exports: [
        CreatePhysicalAltComponent,
        CreateEntityAltComponent,
    ]
})
export class AlternativeModule { }
