<ul class="navigation__tabs">
  <li class="navigation__tab">
    <a class="navigation__tab__link" routerLink="/news/list" routerLinkActive="navigation__tab__link_active">
      Новости <span class="navigation__tab__link_active__slide"></span>
    </a>
  </li>
  <li class="navigation__tab">
    <a class="navigation__tab__link" routerLink="/news/actions" routerLinkActive="navigation__tab__link_active">
      Акции <span class="navigation__tab__link_active__slide"></span>
    </a>
  </li>
</ul>
<router-outlet></router-outlet>
