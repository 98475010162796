import {Component, OnDestroy, OnInit} from '@angular/core';
import {GoBackButtonService} from '../../services/go-back-button.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {AuthenticateService} from '../../services/authenticate.service';


@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.scss']
})
export class ControlComponent implements OnInit, OnDestroy {

  private routerSubscription: Subscription;

  constructor(private router: Router, private goBack: GoBackButtonService, public auth: AuthenticateService) { }

  ngOnInit() {
    this.goBack.header = '<h1>Таблицы контроля</h1>';
    this.routerSubscription = this.router.events.subscribe((val) => {
      this.goBack.header = '<h1>Таблицы контроля</h1>';
    });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
