import { EventEmitter, Injectable } from '@angular/core';


@Injectable()
export class ImportEditService {
  public onModificationsErase: EventEmitter<any> = new EventEmitter();
  public onLogFileLoad: EventEmitter<string> = new EventEmitter();

  constructor() { }

  eraseModifications() {
    this.onModificationsErase.emit();
  }

  logFileLoad(logId: string) {
    this.onLogFileLoad.emit(logId);
  }

}
