import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {BreadcrumbsService} from '../../services';
import {UrlList} from '../../types';


@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: [
    './breadcrumbs.component.scss'
  ]
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  public chain: UrlList;

  constructor(private b: BreadcrumbsService) { }

  ngOnInit() {
    this.b.elements$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(chain => this.chain = chain);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
