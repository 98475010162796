<p class="mt-4 mb-2">
  Всего: <b>{{count}}</b>
</p>
<div class="material-datatable-wrapper">
  <div class="table-responsive">
    <table class="table">
      <tr>
        <th *ngFor="let el of head" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
          <div [formGroup]="form">
            <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
              <div class="col table__input">
                <ng-container *ngIf="el.input">
                  <app-material-input
                    [placeholder] = "el.caption"
                    [formControlName] = "el.name"
                    [cssClass]="'mb-0'">
                  </app-material-input>
                </ng-container>
                <ng-container *ngIf="!el.input">
                  {{el.caption}}
                </ng-container>
              </div>
              <div class="col-auto" *ngIf="el.sort">
                <a href="javascript:void(0)" (click)="sortChange(el.name)">
                  <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                      'icon_new-sort-down': 'asc' == sort[el.name],
                                      'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                </a>
              </div>
            </div>
          </div>
        </th>
      </tr>
      <!-- // -->
      <tr *ngFor="let row of rows; let row_index = index">
        <td>{{row['author_fio']}}</td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'flat_caption' + row_index}">
            <a href="/housing2/flat/{{row.realty_id}}"
               (click)="goBack.newLink('/housing2/flat/' + row.flat_id, 'flat_caption' + row_index, $event)">
              {{row.flat_caption}}
            </a>
          </div>
        </td>
        <td>{{row['flat_status']}}</td>
        <td>{{row['old_voznagr_agenta_from_ddu'] | tozero | toFixed2}} - {{row['new_voznagr_agenta_from_ddu'] | tozero | toFixed2}}</td>
        <td>{{row['old_dolya_subagenta_agd'] * row['old_voznagr_agenta_from_ddu'] | tozero | toFixed2}} - {{row['new_dolya_subagenta_agd'] * row['new_voznagr_agenta_from_ddu'] | tozero | toFixed2}}</td>
        <td>{{row['old_lead_transfer_sub_percent_agd'] | tozero | toFixed2}} - {{row['new_lead_transfer_sub_percent_agd'] | tozero | toFixed2}}</td>
        <td>{{row['change_time']}}</td>
      </tr>
    </table>
  </div>
</div>

<div class="alt-pagination">
  <app-paginator
    [queryParams]="filter_columns"
    [itemsPerPage]="50"
    [itemCount]="count"
    [currentPage]="current_page"
    [baseUrl]="'/tariff-map/logs/' + housing_id">
  </app-paginator>
</div>
