import {AfterContentInit, Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {JsonClientService} from '../../../../shared/json-client';

declare const jQuery: any;

@Directive({
  selector: '[appMaterialInputGetAddressDirective]'
})
export class MaterialInputGetAddressDirective implements AfterContentInit {

  @Input('appMaterialInputGetAddressDirective') private appMaterialInputGetAddressDirective = '';
  @Input('nameValue') private nameValue = '';
  @Input('nameLabel') private nameLabel = '';
  @Output() public autocompleteChange: EventEmitter<string> = new EventEmitter();

  constructor(private elementRef: ElementRef, private http: JsonClientService) { }

  ngAfterContentInit() {
    jQuery(this.elementRef.nativeElement).autocomplete({
      appendTo: this.elementRef.nativeElement.parentNode,
      select: (ux, obj) => {
        this.autocompleteChange.emit(obj.item);
      },
      source: (request, response) => {
        const data = {'value': jQuery(this.elementRef.nativeElement).val(), 'limit': 10};
        this.http.post(this.appMaterialInputGetAddressDirective, data)
          .then((r: Array<any>) => {
            response(r
              .filter(x => {
                return !!x['XCC'] && !!x['XGGG'] && !!x['XGGG2'];
              })
              .map(x => {
                return {
                  'label': x['label'],
                  'value': x['label'],
                  'DDD': x['DDD'],
                  'XA': x['XA'],
                  'XCC': x['XCC'],
                  'XGGG': x['XGGG'],
                  'XGGG2': x['XGGG2'],
                  'XPPP': x['XPPP'],
                  'XRRR': x['XRRR'],
                  'XUUUU': x['XUUUU'],
                  'XUUUU2': x['XUUUU2'],
                  'XUUUU3': x['XUUUU3'],
                  'city': x['city'],
                  'city_area': x['city_area'],
                  'country': x['country'],
                  'city_type': x['city_type'],
                  'settlement_type': x['settlement_type'],
                  'ya_address': x['ya_address'],
                  'district': x['district'],
                  'region': x['region'],
                  'lat': x['lat'],
                  'lon': x['lon'],
                  'fias_id': x['fias_id'],
                  'kladr_id': x['kladr_id'],
                };
              }));
          });
      }
    });
  }
}
