import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {JsonClientService} from '../../../shared/json-client';

@Injectable()
export class IndexResolve {

  constructor(private j: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot, ...args): Promise<any> | any {
    return this.j.post('Analytics.get_report_list');
  }

}
