<div class="material-datatable-wrapper">
  <div class="table-responsive">
    <table class="table datatable" [ngClass]="cssClass">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>

<ng-template #presence let-row="row">
  <label>
    <i class="fa" [ngClass]="{
      'mute': null === row['approved'],
      'fa-square-o': !row['approved'],
      'fa-check-square-o': row['approved']
      }"></i>
    <input type="checkbox" [hidden]="true" value="1" [(ngModel)]="row['approved']"
           (change)="changeStatus(row)">
  </label>
</ng-template>
