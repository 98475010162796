import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient} from '../../../services/http.service';
import {Observable} from 'rxjs';

@Injectable()
export class SettingsConstraintsElementResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    let subagent_id = null;

    if (route.params['subagent_id']) {
      subagent_id = +route.params['subagent_id'];
    }

    return new Promise((resolve) => {
      this.http.post('RealtyList.get', {'subagent_id': subagent_id})
        .subscribe(x => resolve({
          'common_data': x,
          'subagent_id': subagent_id,
        }));
    });
  }

}

@Injectable()
export class SettingsConstraintsConsolidatedResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return new Promise((resolve) => {
      this.http.post('RealtyList.get_for_global', {'subagent_id': null})
        .subscribe(x => resolve({
          'common_data': x,
          'subagent_id': null,
        }));
    });
  }

}
