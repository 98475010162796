import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GoBackButtonService} from '../../services/go-back-button.service';
import {JsonClientService} from '../../shared/json-client';
import {NotificationService} from '../../services/notification.service';
import {AlternativeDatatablesComponent} from '../../shared/alternative-datatables/alternative-datatables.component';
import {AlternativeDatatablesInterface} from '../../shared/alternative-datatables/alternative-datatables.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TemplateService} from '../../services/template.service';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  templateUrl: './eat-complex-assoc.component.html',
  styleUrls: ['./eat-complex-assoc.component.scss']
})
export class EatComplexAssocComponent extends AlternativeDatatablesComponent
  implements AlternativeDatatablesInterface, OnInit, OnDestroy, AfterViewInit {

  public load = false;
  public head;

  public filter_columns = {};
  public form: UntypedFormGroup;
  public flatForm: UntypedFormGroup;
  public filter: string;

  public housing_id = null;
  private parasites = true;

  public filterSubject: Subject<void> = new Subject<void>();
  private filterSubscription: Subscription;
  private formSubscription: Subscription = new Subscription();

  public current_page = 1;

  public count;
  public rows;

  public sort: { [key: string]: string } = {};

  public current_flat: any = null;

  public set data(value) {
    this.count = value['count'];
    this.rows = value['rows'];
  }

  private cause_list = [];

  @ViewChild('input') private input;

  constructor(public http: JsonClientService,
              private notify: NotificationService,
              public goBack: GoBackButtonService,
              private router: Router,
              private fb: UntypedFormBuilder,
              public activatedRoute: ActivatedRoute,
              private template: TemplateService) {
    super(router, activatedRoute, fb, http);

    this.filterSubscription = this.filterSubject
      .pipe(debounceTime(500))
      .subscribe(_ => this.onFilterChanges());
  }

  bSet() {
    this.goBack.header = '<h2>Эст-а-тет ЖК ассоциации</h2>';
    this.template.setHeader('Эст-а-тет ЖК ассоциации');
  }

  ngOnInit(): void {
    this.form = this.fb.group({});
    this.flatForm = this.fb.group({
      flat_id: [''],
    });

    this.head = [
      {'name': 'date', 'value': '', 'caption': 'Дата', 'input': false, 'sort': false},
      {'name': 'eat_complex_caption', 'value': '', 'caption': 'ЖК в Эст-а-тет квалификации', 'input': true, 'sort': false},
      {'name': 'spn_object', 'value': '', 'caption': 'Объект СПН24', 'input': true, 'sort': false},
      {'name': 'fio', 'value': '', 'caption': 'Автор', 'input': true, 'sort': false},
      {
        'name': 'assoc_exists',
        'value': '',
        'caption': 'Ассоциация',
        'sort': false,
        'input': false,
        'select': [
          {'caption': 'Все', 'id': 'all'},
          {'caption': 'Без ассоциации', 'id': ''},
          {'caption': 'С ассоциацией', 'id': 'yes'},
        ],
      },
    ];

    this.head = this.head.map(x => {
      x.value = this.activatedRoute.snapshot.queryParams[x.name] || x.value;
      return x;
    });
    if (this.activatedRoute.snapshot.queryParams['sort_name']) {
      this.sort[this.activatedRoute.snapshot.queryParams['sort_name']] = this.activatedRoute.snapshot.queryParams['sort_key'];
    }

    this.formSubscription.add(this.form.valueChanges
      .subscribe(_ => this.filterSubject.next()));
    // this.formSubscription.add(this.flatForm.valueChanges
    //   .subscribe(a => { }));

    for (const el of this.head) {
      this.form.addControl(el.name, new UntypedFormControl(el.value));
    }

    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.onInitCall();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }

    this.formSubscription.unsubscribe();
  }

  onFilterChanged(): void {
    this.data = this.activatedRoute.snapshot.data['data'];
    this.current_page = +this.activatedRoute.snapshot.queryParams['page'] || 1;
  }

  onFilterChanges(text = null) {

    if (text !== null) {
      this.filter = text;
    }

    this.filter_columns = {};
    for (const el of this.head) {
      if (!this.form.get(el.name).value.length) {
        continue;
      }
      this.filter_columns[el.name] = this.form.get(el.name).value;
    }
    if (this.filter !== '') {
      this.filter_columns['global_search_string'] = this.filter;
    }
    if (this.sort) {
      this.filter_columns['sort_name'] = Object.keys(this.sort)[0];
      this.filter_columns['sort_key'] = this.sort[this.filter_columns['sort_name']];
    }
    if (this.parasites) {
      return this.parasites = !this.parasites;
    }
    this.router.navigate(['eat-complex-captions'], {
      queryParams: this.getQueryParams(),
    });
  }

  getQueryParams() {
    const query_params = {};

    for (const el of this.head) {
      let _val = this.form.get(el.name).value;
      if (!_val.length) {
        continue;
      }
      if (_val === 'true' || _val === 'false') {
        _val = _val === 'true';
      }
      query_params[el.name] = _val;
    }
    if (this.sort) {
      query_params['sort_name'] = Object.keys(this.sort)[0];
      query_params['sort_key'] = this.sort[query_params['sort_name']];
    }
    if (this.filter !== '') {
      query_params['global_search_string'] = this.filter;
    }
    return query_params;
  }

  prepareSort(column_name: string) {
    for (const key in this.sort) {
      if (column_name !== key) {
        delete this.sort[key];
      }
    }
  }

  sortChange(column_name: string) {
    this.prepareSort(column_name);
    this.sort[column_name] = !this.sort[column_name] || ('desc' === this.sort[column_name]) ? 'asc' : 'desc';
    this.onFilterChanges();
  }

  ngAfterViewInit() {
    this.bSet();
    this.goBack.goScroll();
  }

  onAssocSetClick(row) {
    this.rows.forEach(el => el.select_complex_show = false);
    row['select_complex_show'] = !row['select_complex_show'];
  }

  onFlatSelected(row, spnCaption) {
    this.flatForm.get('flat_id').disable();
    this.http.post('Estatet.set_eat_complex_assoc', {
      'eat_complex_caption': row['eat_complex_caption'],
      'flat_id': +this.flatForm.value.flat_id,
    }).then(_ => {
        row['object_caption'] = spnCaption;
        row['select_complex_show'] = false;
        row['assoc_exists'] = 'С ассоциацией';
        this.flatForm.get('flat_id').enable();
      }, _ => this.flatForm.get('flat_id').enable()
    );
  }

}
