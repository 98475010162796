import {Component, Host, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FlatInfoStandaloneComponent} from '../flat-info-standalone.component';
import {DictionariesService} from '../../../../../services/dictionaries.service';
import {HttpClient} from '../../../../../services/http.service';

@Component({
  selector: 'app-flat-info-advanced',
  templateUrl: './flat-advanced.component.html',
  styleUrls: [
    './flat-advanced.component.scss'
  ]
})
export class FlatAdvancedComponent implements OnInit {

  public type_otd: Array<any> = [];
  public form: UntypedFormGroup;

  @Input('flat_id') public flat_id;
  @Input('floor_id') public floor_id;

  constructor(@Host() private host: FlatInfoStandaloneComponent,
              private fb: UntypedFormBuilder, private dict_helper: DictionariesService,
              private http: HttpClient) { }

  ngOnInit() {
    const promises = [
      this.dict_helper.get('Dict.get_realty_type_otd'),
      this.dict_helper.get('Housing.get_chain'),
    ];

    Promise.all(promises).then(result => {
      this.type_otd = result.shift();

      if (this.flat_id) {
        this.http.post('Flat.get_common', {'flat_id': +this.flat_id})
          .subscribe(data => {
            this.initializeFormCommon(data);
          });
      } else {
        this.initializeFormCommon();
      }
    });
  }

  private initializeFormCommon(data = {}) {
    this.form = this.host.form;
    this.form.addControl('advanced', this.fb.group({
      'flat_description_finish': [(data['flat_description_finish']) ? data['flat_description_finish'] : ''],
      'bool_br_conditions': [data['bool_br_conditions'] || false],
      'bool_oform_price': [data['bool_oform_price'] || ''],
      'bool_pay_conditions': [data['bool_pay_conditions'] || ''],
      'bool_tip_otd_id': [data['bool_tip_otd_id'] || ''],
      'bool_description_finish': [data['bool_description_finish'] || ''],
      'housing_subagent_br_conditions': [{value: data['housing_subagent_br_conditions'] || '', disabled: true}],
      'housing_subagent_oform_price': [{value: data['housing_subagent_oform_price'] || '', disabled: true}],
      'housing_subagent_pay_conditions': [{value: data['housing_subagent_pay_conditions'] || '', disabled: true}],
      'housing_tip_otd_id': [{
        value: (data['housing_tip_otd_id']) ? '' + data['housing_tip_otd_id'] : '',
        disabled: true
      }],
      'housing_description_finish': [{value: data['housing_description_finish'] || '', disabled: true}],
      'flat_tip_otd_id': [data['flat_tip_otd_id'] ? '' + data['flat_tip_otd_id'] : null],
      'flat_subagent_pay_conditions': [(data['flat_subagent_pay_conditions']) ?
        data['flat_subagent_pay_conditions'] : ''],
      'flat_subagent_br_conditions': [(data['flat_subagent_br_conditions']) ?
        data['flat_subagent_br_conditions'] : ''],
      'flat_subagent_oform_price': [(data['flat_subagent_oform_price']) ?
        data['flat_subagent_oform_price'] : ''],
      'flat_is_apartment': [data['flat_is_apartment'] ? data['flat_is_apartment'] : false],
      'flat_is_duplex': [data['flat_is_duplex'] ? data['flat_is_duplex'] : false],
      'flat_is_mansarda': [data['flat_is_mansarda'] ? data['flat_is_mansarda'] : false],
      'flat_is_studio': [data['flat_is_studio'] ? data['flat_is_studio'] : false],
      'flat_add_export': [data['flat_add_export'] ? data['flat_add_export'] : false],
      // ~
      'flat_separate_wcs_count': [data['flat_separate_wcs_count'] || ''],
      'flat_combined_wcs_count': [data['flat_combined_wcs_count'] || ''],
    }));
  }
}
