import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {HttpClient} from '../../../../../services/http.service';

@Component({
  selector: 'app-flat-info-floor-planning',
  templateUrl: './flat-floor-planning.component.html',
  styleUrls: [
    './flat-floor-planning.component.scss'
  ]
})
export class FlatFloorPlanningComponent implements OnInit {

  @Input() public form: UntypedFormGroup;

  @Input('flat_id') public flat_id;
  @Input('floor_id') public floor_id;

  public initialized = false;

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.http.post('Files.get_list_to_type', {'owner': ['realty', +this.flat_id, 20]})
      .subscribe(x => {

        const fileArr = [];
        for (const file of x) {
          fileArr.push({'id': file.file_id, 'filename': file.name, 'filepath': file.file});
        }

        this.form.addControl('floors_planning', new UntypedFormControl(fileArr));
        this.initialized = true;
      });
  }

}
