import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {AlternativeDatatablesInterface} from '../../../shared/alternative-datatables/alternative-datatables.interface';
import {AlternativeDatatablesComponent} from '../../../shared/alternative-datatables/alternative-datatables.component';
import {JsonClientService} from '../../../shared/json-client';
import {GoBackButtonService} from '../../../services/go-back-button.service';
import {BreadcrumbsService} from '../../../shared/breadcrumbs/services';
import {NotificationService} from '../../../services/notification.service';


@Component({
  templateUrl: './errors.component.html',
  styleUrls: [
    './errors.component.scss'
  ]
})
export class InstatekaErrorComponent
  extends AlternativeDatatablesComponent
  implements AlternativeDatatablesInterface, OnInit, OnDestroy, AfterViewInit {

  public readonly head = [
    {'name': 'user_id', 'value': '', 'caption': 'id субагента', 'sort': false, 'input': false},
    {'name': 'create_date', 'value': '', 'caption': 'Дата создания', 'sort': false, 'input': false},
    {'name': 'code', 'value': '', 'caption': 'Код ошибки', 'sort': false, 'input': false},
    {'name': 'status', 'value': '', 'caption': 'Статус', 'sort': false, 'input': false},
    {'name': 'method', 'value': '', 'caption': 'Метод', 'sort': false, 'input': false},
    {'name': 'request', 'value': '', 'caption': 'Запрос', 'sort': false, 'input': false},
    {'name': 'response', 'value': '', 'caption': 'Ответ', 'sort': false, 'input': false},
  ];

  public modal_show = false;
  public modal_content = '';

  public form: UntypedFormGroup;
  public checkedIds = [];

  public housing_id = null;
  private parasites = true;

  private innSubscription: Subscription = new Subscription();

  public count;
  public rows;
  public checkedLength = 0;
  public checkedErrors = {};
  private _isAllChecked = false;
  public _is_resolve = false;
  private resolved_task = false;
  public get isAllChecked() {
    return this._isAllChecked;
  }
  public get checkedErrorsIds() {
    return Object.keys(this.checkedErrors).map(x => parseInt(x, 10));
  }
  public set isAllChecked(value) {
    this._isAllChecked = value;
    if (value) {
      this.rows.forEach(value2 => {
        if (!this.checkedErrors['' + value2['error_id']]) {
          this.checkedErrors['' + value2['error_id']] = value2['error_id'];
        }
      });
    } else {
      this.rows.forEach(value2 => {
        if (this.checkedErrors['' + value2['error_id']]) {
          delete this.checkedErrors['' + value2['error_id']];
        }
      });
    }
    this.checkedLength = Object.keys(this.checkedErrors).length;
  }

  public current_page = 1;

  public set data(value) {
    this.count = value['count'];
    this.rows = value['rows'];
    this.checkAllChecked();
  }

  constructor(private router: Router, private fb: UntypedFormBuilder, public activatedRoute: ActivatedRoute,
              public goBack: GoBackButtonService, private b: BreadcrumbsService,
              public http: JsonClientService, private notify: NotificationService) {
    super(router, activatedRoute, fb, http);

    this.head = this.head.map(x => {
      x.value = this.activatedRoute.snapshot.queryParams[x.name] || x.value;
      return x;
    });
    this.housing_id = this.activatedRoute.snapshot.params['housing_id'];
  }

  ngOnInit() {
    this.goBack.header = '<h1>Ошибки сервиса Инстатека</h1>';
    this.b.set([
      {url: '/', caption: 'Главная'},
      {caption: 'Ошибки сервиса Инстатека'},
    ]);
    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.form = this.fb.group({
      'search': [this.filter]
    });
    this.innSubscription = this.form.get('search').valueChanges
      .pipe(debounceTime(500))
      .subscribe(value => this.onFilterChanges(value));
    this.onInitCall();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.innSubscription.unsubscribe();
  }

  public get is_archive() {
    return this._is_resolve;
  }

  public set is_resolve(value: any) {
    this._is_resolve = !(value === 'false' || value === false);
    this.resolved_task = this._is_resolve;
    this.onClearChecked();
    this.onFilterChanges();
  }

  onFilterChanged(): void {
    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.resolved_task = this.activatedRoute.snapshot.queryParams['resolved_task'];
    this.data = this.activatedRoute.snapshot.data['data'];
    this.current_page = +this.activatedRoute.snapshot.queryParams['page'] || 1;
  }

  onFilterChanges(text = null) {

    if (this.parasites) {
      return this.parasites = !this.parasites;
    }
    this.goBack.header = '<h1>Ошибки сервиса Инстатека</h1>';
    this.b.set([
      {url: '/', caption: 'Главная'},
      {caption: 'Ошибки сервиса Инстатека'},
    ]);
    this.router.navigate(['instateka', 'errors'], {
      queryParams: this.getQueryParams(),
    });
  }

  getQueryParams() {
    const query_params = {};
    /*for (const el of this.head) {
      let _val = this.form.get(el.name).value;
      if (!_val.length) {
        continue;
      }
      if (_val === 'true' || _val === 'false') {
        _val = _val === 'true';
      }
      query_params[el.name] = _val;
    }*/
    query_params['resolved_task'] = this.resolved_task;
    return query_params;
  }

  ngAfterViewInit() {
    this.goBack.goScroll();
  }

  viewPayConditions(id: any, event) {
    event.stopPropagation();
    this.modal_show = true;
    this.modal_content = id;
  }

  modalClose() {
    this.modal_show = false;
  }

  checkAllChecked() {
    let isAllChecked = true;
    this.rows.forEach(value2 => {
      if (!this.checkedErrors['' + value2['error_id']]) {
        isAllChecked = false;
      }
    });
    this._isAllChecked = isAllChecked;
  }

  isChecked(obj) {
    return !!this.checkedErrors['' + obj['error_id']];
  }
  onClearChecked() {
    this.checkedErrors = {};
    this.checkedLength = 0;
    if (this._isAllChecked) {
      this._isAllChecked = false;
    }
  }

  onChecking(e, obj) {
    if (e.target.checked) {
      this.checkedErrors['' + obj['error_id']] = obj['error_id'];
      this.checkAllChecked();
    } else {
      if (this._isAllChecked) {
        this._isAllChecked = false;
      }
      delete this.checkedErrors['' + obj['error_id']];
    }
    this.checkedLength = Object.keys(this.checkedErrors).length;
  }

  onResolveError(value = true) {
    this.checkedIds = Object.keys(this.checkedErrors).map(x => parseInt(x, 10));
    const request = {'errors': this.checkedIds, 'value': value};
    NotificationService.swalConfirm('Вы уверены?', 'Выбранные данные отметятся как решенные')
      .then(_ => {
        this.rows = this.rows.filter((elem) => {
          return !this.checkedIds.includes(elem.error_id);
        });
        this.http.post('Mortgage.resolved_error', request).then(x => {
          this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
        });
        this.count = this.count - this.checkedLength;
        this.onClearChecked();
      })
      .catch(_ => false);
  }

  onUnresolvedError(value = false) {
    this.checkedIds = Object.keys(this.checkedErrors).map(x => parseInt(x, 10));
    const request = {'errors': this.checkedIds, 'value': value};
    NotificationService.swalConfirm('Вы уверены?', 'Выбранные данные отметятся как нерешенные')
      .then(_ => {
        this.rows = this.rows.filter((elem) => {
          return !this.checkedIds.includes(elem.error_id);
        });
        this.http.post('Mortgage.resolved_error', request).then(x => {
          this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
        });
        this.count = this.count - this.checkedLength;
        this.onClearChecked();
      })
      .catch(_ => false);
  }
}
