<div>
  <h2>Перезапись актов просмотра</h2>
  <div>
    <form [formGroup]="form" novalidate (submit)="fileSave()">

      <app-material-input-dropzone-sortable
        formControlName="files" [hasComments]="true">
      </app-material-input-dropzone-sortable>

      <div class="mt-3 text-center">
        <button
          type="submit"
          class="button"
          [disabled]="!form.valid">
            Сохранить
        </button>
      </div>
    </form>
  </div>
</div>
