import {Component, Host, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {CreatePhysicalAltComponent} from '../create-physical.component';
import {HttpClient} from '../../../../services/http.service';

@Component({
  selector: 'app-alt-physical-advanced',
  templateUrl: './create-physical-advanced.component.html',
  styleUrls: [
    './create-physical-advanced.component.scss'
  ]
})
export class CreatePhysicalAltAdvancedComponent implements OnInit {

  public form: UntypedFormGroup;
  public nationalityList = [];

  @ViewChild('nationalitySelect', {static: false}) public nationalitySelect;

  constructor(@Host() private hostComponent: CreatePhysicalAltComponent,
              private fb: UntypedFormBuilder, private http: HttpClient) { }

  ngOnInit() {
    this.form = this.hostComponent.form;

    this.loadNationality().then(x => this.nationalityList = x);

    this.hostComponent.form.addControl('advanced', this.fb.group({
      'physical_mesto_rozhd': [''],
      'physical_mj_reg': [''],
      'physical_mj_fakt': [''],
      'physical_korr_addr': [''],
      'physical_addr_index': [''],
      'physical_inn': [''],
      'physical_num_strah_svid': [''],
      'physical_nationality_id': [''],
      'physical_pasp_num': [''],
      'physical_pasp_seriya': [''],
      'physical_pasp_date': [''],
      'physical_pasp_vidan': [''],
      'physical_pasp_kod_podrazdel': [''],
      'physical_osnovan_podp': [''],
    }));
  }

  private loadNationality(): Promise<any> {
    return new Promise<any>(resolve => {
      this.http.post('Dict.get_nationality')
        .subscribe(x => resolve(x));
    });
  }
}
