export class UploadFile {

  private _filepath: string;
  private _exten: string;

  public set filepath(value: string) {
    this._filepath = value;
    this._exten = value.split('.').pop();
  }

  public get filepath(): string {
    return this._filepath;
  }

  constructor(public id: string, public filename: string, filepath: string,
              public comment: string = '', public in_flat_visible: string = '',
              public file_id: string = null, public file_assoc_id: string = null) {
    this.filepath = filepath;
  }

  public get preview(): string {
    if (['jpg', 'jpeg', 'png', 'bmp', 'tiff', 'gif'].indexOf(this._exten.toLowerCase()) !== -1) {
      return this._filepath;
    }

    return null;
  }

  toDict() {
    return {
      'id': this.id,
      'file_id': this.file_id,
      'file_assoc_id': this.file_assoc_id,
      'filename': this.filename,
      'filepath': this.filepath,
      'comment': this.comment,
      'in_flat_visible': this.in_flat_visible,
    };
  }
}
