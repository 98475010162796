import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalHelpComponent } from './modal-help.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ModalHelpComponent,
  ],
  exports: [
    ModalHelpComponent,
  ]
})
export class ModalHelpModule { }
