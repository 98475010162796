import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';

import {FieldHighwayService} from './field-highway.service';

@Component({
  selector: 'field-housing-highway',
  templateUrl: './field-highway.component.html',
  styleUrls: [
    './field-highway.component.scss',
  ],
  providers: [
    FieldHighwayService,
  ]
})
export class FieldHighwayComponent implements OnInit, OnDestroy {

  @Input() public form: UntypedFormGroup;
  @Input() public field_name: string;
  @Input() public values: Array<any> = [];

  public highway_list: Array<any>;
  private subscriptions: Array<Subscription> = [];
  private allreadyMain = false;

  public get highways(): UntypedFormArray {
    return this.form.get(this.field_name) as UntypedFormArray;
  }

  public get chosen_elements() {
    return this.highways.controls.map(x => +x.get('id').value);
  }

  constructor(private s: FieldHighwayService, private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.form.addControl(this.field_name, new UntypedFormArray(this.getHighways()));
    this.s.loadHelpers().then(args => [this.highway_list] = args);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  dropHighway(i) {
    const need_main = this.highways.controls[i].get('is_main').value === true;
    this.highways.removeAt(i);
    if (need_main && this.highways.controls.length) {
      this.highways.controls[0].patchValue({
        is_main: true
      });
    }
  }

  addHighway() {
    const options = {};
    if (!this.highways.length) {
      options['is_main'] = true;
    }
    const [control, subscription] = this.getControl(options);

    this.highways.push(control);
    this.subscriptions.push(subscription);
  }

  private getHighways() {
    if (!this.values.length) {
      return [];
    }

    const controls = [];

    for (const el of this.values) {
      el['id'] = el['id'].toString();
      if (el['is_main'] && this.allreadyMain) {
        el['is_main'] = false;
      } else if (el['is_main']) {
        this.allreadyMain = true;
      }
      const [_c, _s] = this.getControl(el);
      controls.push(_c);
      this.subscriptions.push(_s);
    }

    return controls;
  }

  private getControl(options = {}): [UntypedFormGroup, Subscription] {
    const control = this.fb.group({
      'id': [options['id'] || '', Validators.required],
      'is_main': [options['is_main'] || false],
    });

    const subscription = control.get('is_main').valueChanges
      .subscribe(value => {
        if (!value || !this.form) {
          return;
        }

        this.highways.controls
          .filter(x => x !== control)
          .forEach(_control => _control.patchValue({
            'is_main': false,
          }, {onlySelf: true, emitEvent: false}));
      });

    return [control, subscription];
  }

}
