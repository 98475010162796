import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fio_filter'
})

@Injectable()
export class CalendarStatisticPopupPipe implements PipeTransform {

  private static sort(a, b) {
    if (a.fio < b.fio) {
      return -1;
    }

    if (a.fio > b.fio) {
      return 1;
    }

    return 0;
  }

  transform(value: any, search_string: string, editable: boolean, is_approved: boolean): any {

    if (!editable) {
      /*
       *
       */
      if (is_approved) {
        return value.sort(CalendarStatisticPopupPipe.sort).filter(x => x['approve']);
      }
      else {
        return value.sort(CalendarStatisticPopupPipe.sort).filter(x => !x['approve']);
      }
    }

    if (search_string && search_string.length) {
      return value.sort(CalendarStatisticPopupPipe.sort)
        .filter(x => !x['fio'].toLowerCase()
        .indexOf(search_string.toLowerCase()));
    }

    return value.sort(CalendarStatisticPopupPipe.sort);
  }

}
