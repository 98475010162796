import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toFixed2'
})
export class ToFixed2Pipe implements PipeTransform {

  transform(value: any) {
    if (typeof value === 'number') {
      return parseFloat(value.toFixed(2));
    } else {
      return value;
    }
  }
}
