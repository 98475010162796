import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {JsonClientService} from '../../../../shared/json-client';
import {AlternativeDatatablesComponent} from '../../../../shared/alternative-datatables/alternative-datatables.component';
import {AlternativeDatatablesInterface} from '../../../../shared/alternative-datatables/alternative-datatables.interface';
import {GoBackButtonService} from '../../../../services/go-back-button.service';
import {environment} from '../../../../../environments/environment';
import {AuthenticateService} from '../../../../services/authenticate.service';


@Component({
  selector: 'app-booking-info-content',
  templateUrl: './booking-info-content.component.html',
  styleUrls: [
    './booking-info-content.component.scss',
    '../../reserv-global.component.scss',
  ]
})
export class BookingInfoContentComponent
  extends AlternativeDatatablesComponent
  implements AlternativeDatatablesInterface, OnInit, OnDestroy, AfterViewInit {

  public readonly head = [
    {'name': 'next_contact_date', 'value': '', 'caption': 'След. контакт (созд./изм.)', 'sort': true},
    {'name': 'housing_complex_caption', 'value': '', 'caption': 'Название ЖК', 'width': '', 'sort': true},
    {'name': 'object_caption', 'value': '', 'caption': 'Квартира', 'width': '', 'sort': true},
    {'name': 'physical_subagent_fio', 'value': '', 'caption': 'Субагент', 'width': '', 'sort': true},
    {'name': 'subagent_caption', 'value': '', 'caption': 'Организация', 'width': '', 'sort': true},
    {'name': 'physical_subagent_phone', 'value': '', 'caption': 'Тел. субагента', 'width': '', 'sort': true},
    {'name': 'appeal_client_fio', 'value': '', 'caption': 'Клиент', 'width': '', 'sort': true},
    {'name': 'lead_phone', 'value': '', 'caption': 'Тел. клиента', 'width': '', 'sort': true},
    {'name': 'physical_manager_fio', 'value': '', 'caption': 'Ответственный', 'width': '', 'sort': true},
    {'name': 'stage_caption', 'value': '', 'caption': 'Этап', 'width': '', 'sort': true},
    {'name': 'date_start', 'value': '', 'caption': 'none', 'width': '', 'sort': true},
    {'name': 'date_end', 'value': '', 'caption': 'none', 'width': '', 'sort': true},
    {'name': 'date_change_start', 'value': '', 'caption': 'none', 'width': '', 'sort': true},
    {'name': 'date_change_end', 'value': '', 'caption': 'none', 'width': '', 'sort': true},
    {'name': 'developer_caption', 'value': '', 'caption': 'none', 'width': '', 'sort': true},
    {'name': 'developer_id', 'value': '', 'caption': 'none', 'width': '', 'sort': true},
    {'name': 'tab', 'value': '', 'caption': 'none', 'width': '', 'sort': true},
  ];
  public readonly headDisplay = [
    {'name': 'next_contact_date', 'value': '', 'caption': 'След. контакт (созд./изм.)', 'width': '250px', 'sort': true},
    {'name': 'subagent_caption', 'value': '', 'caption': 'Организация', 'width': '', 'sort': true},
    {'name': 'physical_subagent_fio', 'value': '', 'caption': 'Субагент', 'width': '', 'sort': true},
    {'name': 'physical_subagent_phone', 'value': '', 'caption': 'Тел. субагента', 'width': '', 'sort': true},
    {'name': 'appeal_client_fio', 'value': '', 'caption': 'Клиент', 'width': '', 'sort': true},
    {'name': 'lead_phone', 'value': '', 'caption': 'Тел. клиента', 'width': '', 'sort': true},
    {'name': 'object_caption', 'value': '', 'caption': 'Объект', 'width': '', 'sort': true},
    {'name': 'physical_manager_fio', 'value': '', 'caption': 'Ответственный', 'width': '', 'sort': true},
    {'name': 'developer_caption', 'value': '', 'caption': 'Застройщик', 'width': '', 'sort': true},
    {'name': 'stage_caption', 'value': '', 'caption': 'Этап', 'width': '', 'sort': true},
  ];

  public filter_columns = {};
  public form: UntypedFormGroup;
  public filter: string;

  public filterdata: any;

  private parasites = true;
  public tab = 'all';

  public filterSubject: Subject<void> = new Subject<void>();
  private filterSubscription: Subscription;
  private formSubscription: Subscription;
  private subscriptionRouter: Subscription;

  public current_page = 1;
  public refresh = false;

  public count;
  public rows;
  public thisDate = new Date();

  public sort: {[key: string]: string} = {};


  public buttonLink = '';
  public urlLead = environment.apiServer + 'restful/booking-info/';
  public subscribtionUser: Subscription;
  public user = null;

  public set data(value) {
    this.count = value['count'];
    this.rows = value['rows'];
    this.tab = this.activatedRoute.snapshot.params['tab'];
  }

  constructor(private router: Router, private fb: UntypedFormBuilder, public activatedRoute: ActivatedRoute,
              private auth: AuthenticateService, public http: JsonClientService, public goBack: GoBackButtonService) {
    super(router, activatedRoute, fb, http);

    this.filterSubscription = this.filterSubject
      .pipe(debounceTime(500))
      .subscribe(_ => this.onFilterChanges());

    this.head = this.head.map(x => {
      x.value = this.activatedRoute.snapshot.queryParams[x.name] || x.value;
      return x;
    });
    if (this.activatedRoute.snapshot.queryParams['sort_name']) {
      this.sort[this.activatedRoute.snapshot.queryParams['sort_name']] = this.activatedRoute.snapshot.queryParams['sort_key'];
    }
  }

  ngOnInit() {
    this.form = this.fb.group({});
    this.subscribtionUser = this.auth.emit_user.subscribe((user) => this.user = user);
    this.subscriptionRouter = this.activatedRoute.params.subscribe((params) => {
      this.form.reset( '');
    });

    this.formSubscription = this.form.valueChanges
      .subscribe(_ => this.filterSubject.next());

    for (const el of this.head) {
      this.form.addControl(el.name, new UntypedFormControl(el.value));
    }
    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.onInitCall();
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscriptionRouter) {
      this.subscriptionRouter.unsubscribe();
    }
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    if (this.subscribtionUser) {
      this.subscribtionUser.unsubscribe();
    }
  }
  ngAfterViewInit() {
    this.goBack.goScroll();
  }
  onFilterChanged(): void {
    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.data = this.activatedRoute.snapshot.data['data'];
    this.filterdata = this.activatedRoute.snapshot.data['filterdata'];
    this.current_page = +this.activatedRoute.snapshot.queryParams['page'] || 1;
  }

  onFilterChanges(text = null) {
    if (text !== null) {
      this.filter = text;
    }

    this.filter_columns = {};
    for (const el of this.head) {
      if (!this.form.get(el.name).value) {
        continue;
      }

      this.filter_columns[el.name] = this.form.get(el.name).value;
    }

    if (this.filter !== '') {
      this.filter_columns['global_search_string'] = this.filter;
    }
    if (this.sort) {
      this.filter_columns['sort_name'] = Object.keys(this.sort)[0];
      this.filter_columns['sort_key'] = this.sort[this.filter_columns['sort_name']];
    }
    this.filterButton();
    if (this.parasites) {
      return this.parasites = !this.parasites;
    }

    this.router.navigate(['reservation', 'booking-info', this.tab], {
      queryParams: this.getQueryParams(),
    });
  }

  getQueryParams() {
    const query_params = {};

    for (const el of this.head) {
      if (!this.form.get(el.name).value) {
        continue;
      }
      query_params[el.name] = this.form.get(el.name).value;
    }
    if (this.sort) {
      query_params['sort_name'] = Object.keys(this.sort)[0];
      query_params['sort_key'] = this.sort[query_params['sort_name']];
    }
    if (this.filter !== '') {
      query_params['global_search_string'] = this.filter;
    }
    if (this.refresh) {
      query_params['refresh'] = new Date().valueOf();
      this.refresh = false;
    }
    return query_params;
  }

  prepareSort(column_name: string) {
    for (const key in this.sort) {
      if (column_name !== key) {
        delete this.sort[key];
      }
    }
  }

  sortChange(column_name: string) {
    this.prepareSort(column_name);
    this.sort[column_name] = !this.sort[column_name] || ('desc' === this.sort[column_name]) ? 'asc' : 'desc';
    this.onFilterChanges();
  }

  filter_state(string) {
    if (this.form.value.tab === string) {
      string = '';
    }
    this.form.patchValue({
      'tab': string,
    });
  }
  datediff(newdate) {
    const oneDay = 1000 * 60 * 60 * 24;
    const [day, month, year] = newdate.split('.');
    const dayTill = Math.round((new Date(year, month - 1, day).getTime() - this.thisDate.getTime()) / oneDay) + 1;
    if (dayTill === 0) {
      return 'Контакт сегодня';
    } else if (dayTill > 0) {
      return 'Дней до контакта (' + dayTill + ')';
    } else if (dayTill < 0) {
      return 'Контакт просрочен';
    }
  }
  filterButton() {
    this.buttonLink = '';
    for (const [key, value] of Object.entries(this.filter_columns)) {
      if (value !== '' && key !== 'sort_name' && key !== 'sort_key' && value !== undefined && key !== 'global_tab') {
        this.buttonLink += key + '=' + value + '&';
      }
    }
    if (this.buttonLink !== '') {
      this.buttonLink = '/' + this.tab + '?' + this.buttonLink.slice(0, this.buttonLink.length - 1);
    } else {
      this.buttonLink = '/' + this.tab;
    }
  }
}
