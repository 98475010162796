<div class="calendar-widget" [ngClass]="{'calendar-widget_fixed': fixed}">
  <div class="calendar-widget__caption">
    <!--<div>-->
      <!--<a [routerLink]="['/calendar/list', prevUrl['year'], prevUrl['month']]">-->
        <!--<i class="fa fa-chevron-left"></i></a>-->
    <!--</div>-->
    <div class="calendar-widget__caption__heading">
      <div class="calendar-widget__common">
        <!--<div class="calendar-widget__week-days">-->
          <!--<div class="calendar-widget__week-day" *ngFor="let day of days_of_the_week">-->
            <!--<span>{{day}}</span>-->
          <!--</div>-->
        <!--</div>-->
        <div class="calendar-widget__day" *ngFor="let day of calendar"
             [ngClass]="{'calendar-widget__day_holiday': day.is_holiday,
                          'calendar-widget__day_subscribe': day.is_current}">
          <div *ngIf="!day.event_id" class="row no-gutters">
            <div class="col-auto calendar-widget__day-wrap">
              {{day.num}}<span class="calendar-widget__day-span">{{day.month}}</span>
            </div>
          </div>
          <div (click)="choose.emit(day.event_id)" *ngIf="day.event_id" class="row no-gutters c-pointer flex-nowrap">
            <div class="col-auto calendar-widget__day-wrap">
              {{day.num}}<span class="calendar-widget__day-span">{{day.month}}</span>
            </div>
            <div class="col-auto calendar-widget__day-event" *ngIf="day.event_types">
              <div *ngFor="let event of day.event_types" class="calendar-widget__day-circle"
                   [ngClass]="{'calendar-widget__day-circle_green': event == 3,
                              'calendar-widget__day-circle_black': event == 5 || event == 7}">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<div>-->
      <!--<a [routerLink]="['/calendar/list', nextUrl['year'], nextUrl['month']]">-->
        <!--<i class="fa fa-chevron-right"></i></a>-->
    <!--</div>-->
  </div>
</div>
