import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import {Subscription} from 'rxjs';
import {NavigationLoaderService} from './navigation-loader.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-navigation-loader',
  templateUrl: './navigation-loader.component.html'
})
export class NavigationLoaderComponent implements OnInit, OnDestroy {

  public displayed = false;

  private routerSubscription: Subscription;
  private serviceSubscription: Subscription;

  constructor(private router: Router) {
    this.routerSubscription = this.router.events.pipe(
      filter(e => (e instanceof NavigationStart) ||
        (e instanceof NavigationEnd) ||
        (e instanceof NavigationError) ||
        (e instanceof NavigationCancel))
    ).subscribe(event =>  {
      NavigationLoaderService.state_emitter.emit(event instanceof NavigationStart);
    });
  }

  ngOnInit() {
    this.serviceSubscription = NavigationLoaderService.state_emitter
      .subscribe(value => {
        this.displayed = value;
      });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
  }

}
