
<div class="table-responsive">
  <table class="table table-striped datatable">
    <thead>
    <tr>
      <th class="w-200">Роль</th>
      <th>Физ Лица</th>
      <th>Юр Лица</th>
      <th>Проекты</th>
      <th>Привле&shy;чение, доступ</th>
      <th>Брониро&shy;вание, доступ</th>
      <th>Обучение</th>
      <th>Анализ, доступ</th>
      <th>Новости, доступ</th>
      <th>График работы, доступ</th>
      <th>Email исключения, доступ</th>
      <th>Инста&shy;тека, доступ</th>
      <th>логи ЭСТ-А-ТЕТ карточки, доступ</th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let role of roles" data-role-id="{{role.id}}">
        <td [title]="role.users_text">{{role.caption}} ({{role.users_count}})</td>
        <td>
          <label class="form__checkbox form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="role['access']['phys_edit_is']"
                   (change)="onRoleChange('phys_edit_is', role, $event)">
            <span class="form__checkbox__wrapper">
                <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>
            <span class="form__checkbox__content">Правка</span>
          </label>
        </td>
        <td>
          <label class="form__checkbox form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="role['access']['ent_edit_is']"
                   (change)="onRoleChange('ent_edit_is', role, $event)">
            <span class="form__checkbox__wrapper">
                <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>
            <span class="form__checkbox__content">Правка</span>
          </label>
        </td>
        <td>
          <label class="form__checkbox form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="role['access']['projects_flag_readonly_is']"
                   (change)="onRoleChange('projects_flag_readonly_is', role, $event)">
            <span class="form__checkbox__wrapper">
                <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>
            <span class="form__checkbox__content">Чтение</span>
          </label>
          <label class="form__checkbox form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="role['access']['projects_flag_full_is']"
                   (change)="onRoleChange('projects_flag_full_is', role, $event)">
            <span class="form__checkbox__wrapper">
                <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>
            <span class="form__checkbox__content">Правка</span>
          </label>
        </td>
        <td>
          <label class="form__checkbox form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="role['access']['attraction_is']"
                   (change)="onRoleChange('attraction_is', role, $event)">
            <span class="form__checkbox__wrapper">
                <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>
            <span class="form__checkbox__content"></span>
          </label>
        </td>
        <td>
          <label class="form__checkbox form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="role['access']['reservation_is']"
                   (change)="onRoleChange('reservation_is', role, $event)">
            <span class="form__checkbox__wrapper">
                <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>
            <span class="form__checkbox__content"></span>
          </label>
        </td>
        <td>
          <label class="form__checkbox form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="role['access']['calendar_flag_readonly_is']"
                   (change)="onRoleChange('calendar_flag_readonly_is', role, $event)">
            <span class="form__checkbox__wrapper">
                <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>
            <span class="form__checkbox__content">Чтение</span>
          </label>
          <label class="form__checkbox form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="role['access']['calendar_flag_full_is']"
                   (change)="onRoleChange('calendar_flag_full_is', role, $event)">
            <span class="form__checkbox__wrapper">
                <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>
            <span class="form__checkbox__content">Правка</span>
          </label>
        </td>
        <td>
          <label class="form__checkbox form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="role['access']['analyze_is']"
                   (change)="onRoleChange('analyze_is', role, $event)">
            <span class="form__checkbox__wrapper">
                <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>
            <span class="form__checkbox__content"></span>
          </label>
        </td>
        <td>
          <label class="form__checkbox form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="role['access']['news_is']"
                   (change)="onRoleChange('news_is', role, $event)">
            <span class="form__checkbox__wrapper">
                <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>
            <span class="form__checkbox__content"></span>
          </label>
        </td>
        <td>
          <label class="form__checkbox form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="role['access']['holiday_is']"
                   (change)="onRoleChange('holiday_is', role, $event)">
            <span class="form__checkbox__wrapper">
                <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>
            <span class="form__checkbox__content"></span>
          </label>
        </td>
        <td>
          <label class="form__checkbox form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="role['access']['email_exception_is']"
                   (change)="onRoleChange('email_exception_is', role, $event)">
            <span class="form__checkbox__wrapper">
                <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>
            <span class="form__checkbox__content"></span>
          </label>
        </td>
        <td>
          <label class="form__checkbox form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="role['access']['instateka_is']"
                   (change)="onRoleChange('instateka_is', role, $event)">
            <span class="form__checkbox__wrapper">
                <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>
            <span class="form__checkbox__content"></span>
          </label>
        </td>
        <td>
          <label class="form__checkbox form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="role['access']['est_card_log_is']"
                   (change)="onRoleChange('est_card_log_is', role, $event)">
            <span class="form__checkbox__wrapper">
                <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>
            <span class="form__checkbox__content"></span>
          </label>
        </td>
      </tr>
    </tbody>
  </table>
</div>

