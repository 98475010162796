
<button class="button mt-2r mb-2r" (click)="onSave()">Сохранить сортировку</button>
<p>
  Сортируйте ЖК на витрине и закрепляйте их для вывода в приоритете, в случае необходимости.
  <app-stock-badge
    class="mr-2"
    [text]="'Внутренняя акция'"
    [title]="''"
    [color]="'rgb(68, 219, 239)'"
    [isPrivateHighlight]="true"
  ></app-stock-badge>
  <app-stock-badge
    class="mr-2"
    [text]="'Публичная акция'"
    [title]="''"
    [color]="'rgb(68, 219, 239)'"
    [isPrivateHighlight]="false"
  ></app-stock-badge>
</p>
<div class="wrapper" [ngStyle]="{'min-height' : complexes.length * el_height + 'px'}">
  <div *ngFor="let el of complexes, let i=index;" class="wrapper__row_drag" (endOffset)="onMoveEnd($event)" [zIndexMoving]="9999" (movingOffset)="onMoving($event, i)"
       [ngDraggable] = "!stopEdit"
       [preventDefaultEvent] = "true"
       [position] = position[i]
       [handle]="sorthandle"
       [trackPosition] = "false">
    <div class="d-flex list_row">
      <div class="d-flex align-items-center">
        <div class="number">{{i+1}}</div>
        <div (click)="moveUp(i)" class="fa fa-arrow-up arrrow-icon"></div>
        <div (click)="moveDown(i)" class="fa fa-arrow-down arrrow-icon"></div>
        <div #sorthandle class="menu__item-icon icon icon_menu"></div>
        <ng-container *ngIf="i===0 || complexes[i-1].pinned || el['pinned']">
          <label class="form__checkbox form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="el['pinned']"  [attr.disabled]="complexes[i+1].pinned ? true : null">
            <span class="form__checkbox__wrapper">
                  <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
              </span>
            <span class="form__checkbox__content"></span>
          </label>
        </ng-container>
      </div>
      <div class="d-flex flex-grow-1 ml-3">
        <div class="d-flex flex-grow-1 align-items-center">
          <div>
            {{el.caption}}
            <a class="button button_icon icon icon_Previews ml-2 mr-3" title="ЖК в редакторе"
               [href]="'/housing2/complex/' + el.id" target="_blank"
            ></a>
          </div>
          <ng-container *ngIf="el.stocks_info.length">
            <div class="actions__wrap">
              <div class="actions ml-3">
                <app-stock-badge
                  *ngFor="let badge of el.stocks_info"
                  class="mr-2"
                  [text]="badge.badge_caption"
                  [title]="badge.stock_caption"
                  [color]="badge.color"
                  [link]="'/housing2/complex/' + el.id + '/stocks/edit/' + badge.stock_id"
                  [isPrivateHighlight]="badge.stock_is_private"
                ></app-stock-badge>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="empty_div">
</div>
<div class="fixed-bottom-exc">
  <button class="button mt-2r button_sticky" (click)="onSave()">Сохранить сортировку</button>
</div>
