import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '../../../services/http.service';
import {BreadcrumbsService} from '../../../shared/breadcrumbs/services';

@Component({
  selector: 'app-physical-edit-certificate',
  templateUrl: './physical-edit-certificate.component.html',
  styleUrls: ['./physical-edit-certificate.component.scss']
})
export class PhysicalEditCertificateComponent implements OnInit {
  public data = null;
  public physical_id = null;

  constructor(public activatedRoute: ActivatedRoute, public http: HttpClient, private b: BreadcrumbsService) {
    this.physical_id = this.activatedRoute.snapshot.parent.params['id'];
    this.http.post('Calendar.get_certificate_training_user', {'phys_id': this.physical_id})
      .subscribe(data => this.data = data);
  }

  ngOnInit() {
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/physical/list', caption: 'Физические лица'},
    ]);
  }

}
