import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {JsonClientService} from '../../shared/json-client';

@Injectable()
export class EstatetResolve {

  public readonly filter_columns = [
    'date_start',
    'date_end',
    'eat_requests',
    'next_contact_date',
    'organisation_caption',
    'subagent_user_fio',
    'subagent_phone',
    'fio',
    'lead_phone',
    'object_caption',
    'sales_manager_fio',
    'time_to_answer',
    'stage_caption',
    'lead_status',
  ];

  constructor(private jsonRpc: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {

    const prepared_filter = {};
    const prepared_filter_keys = this.filter_columns.filter(x => !!route.parent.queryParams[x]);
    const sort = {};

    prepared_filter_keys.forEach(x => prepared_filter[x] = route.parent.queryParams[x]);

    if (!prepared_filter['lead_status']) {
      prepared_filter['lead_status'] = 'new||success||prolongation||roll_back';
    }

    if (!!route.queryParams['sort_name']) {
      sort[route.queryParams['sort_name']] = route.queryParams['sort_key'] || 'asc';
    }

    const preapeared_object = {
      'global_search_string': route.queryParams['global_search_string'] || null,
      'fields': prepared_filter,
    };

    const options = {
      'limit': 20,
      'offset': (+(route.queryParams['page'] || 1) - 1) * 20,
      'filter': preapeared_object,
      'sort': sort,
    };

    let resolve: (...args) => any;
    const p = new Promise(r => resolve = r);

    this.jsonRpc.post('Data_tables.get_eat_logs', options).then(r => {
      r = Object.assign(r, preapeared_object);
      resolve(r);
    });
    return p;
  }

}
