import {Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {UploadFile} from '../../../models/files';
import {NotificationService} from '../../../services/notification.service';
import {HttpClient} from '../../../services/http.service';
import {defer, fromEvent, merge, first, mergeMap, switchMap, takeUntil, tap} from 'rxjs';

@Component({
  selector: 'app-material-input-dropzone',
  templateUrl: './material-input-dropzone.component.html',
  styleUrls: ['./material-input-dropzone.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialInputDropzoneComponent),
      multi: true
    }
  ]
})
export class MaterialInputDropzoneComponent implements OnInit {

  public _current_value: Array<UploadFile>;

  @Input() public dropzoneCaption = '';
  @Input() public hasComments = false;
  @Input() public nameComment = false;
  @Input() public disable = false;
  @Input() public max_count = null;
  @Input() public extensions = [];
  @Input() public min_height = 250;

  @Output() public file_upload: EventEmitter<any> = new EventEmitter<any>();
  @Output() public comment_change: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('fileInput') private fileInput;
  @ViewChild('dropZone', { static: true }) dropZone: ElementRef<HTMLDivElement>;

  dragEnter$ = defer(() => fromEvent(this.dropZone.nativeElement, 'dragenter')).pipe(
    tap((event: any) => event.target.style.background = 'rgb(220, 255, 226)')
  );

  dragExit$ = defer(() => fromEvent(this.dropZone.nativeElement, 'dragleave')).pipe(
    tap((event: any) => event.target.style.background = '')
  );

  dragEnd$ = defer(() => fromEvent(this.dropZone.nativeElement, 'dragend')).pipe(
    tap((event: any) => event.target.style.background = '')
  );
  dragOver$ = defer(() => fromEvent(this.dropZone.nativeElement, 'dragover')).pipe(
    tap((event: any) => { event.preventDefault(); })
  );
  drop$ = defer(() => fromEvent(this.dropZone.nativeElement, 'drop')).pipe(
    tap((event: any) => {
      event.preventDefault();
      event.target.style.background = '';
    }));

  dragAndDrop$ = this.dragEnter$.pipe(
    mergeMap(() => this.dragOver$),
    switchMap(() => merge(this.dragExit$.pipe(first()),  this.drop$.pipe(takeUntil(this.dragEnd$)))));

  constructor(public http: HttpClient, public renderer: Renderer2) { }

  public get current_value(): Array<UploadFile> {
    return this._current_value;
  }

  public set current_value(value: Array<UploadFile>) {
    this._current_value = value;
    this.propagateChange(this._current_value.map(e => e.toDict()));
  }

  propagateChange = (_: any) => {};

  ngOnInit() {
    const self = this,
      drop_disable = function(e) {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
      },
      custom_drop = function(e) {
        e.stopPropagation();
        e.preventDefault();
        self.uploadFiles(e.dataTransfer.files);
      };

    if (!this.disable) {
      this.dropZone.nativeElement.removeEventListener('dragover', drop_disable, false);
      this.dropZone.nativeElement.addEventListener('dragover', drop_disable, false);
      this.dropZone.nativeElement.removeEventListener('drop', custom_drop, false);
      this.dropZone.nativeElement.addEventListener('drop', custom_drop, false);
      this.dragAndDrop$.subscribe();
    }
  }

  uploadFiles(files) {

    if (this.max_count !== null && (this.current_value.length >= this.max_count || files.length > this.max_count)) {
      NotificationService.swalError('Ошибка', 'Загружено слишком много файлов');
      return;
    }
    if (this.extensions.length) {
      for (let i = 0, file; i < files.length; i++) {
        if (this.extensions.indexOf(files[i]['name'].split('.').pop()) === -1) {
          NotificationService.swalError('Ошибка', 'Неверный формат файла');
          return;
        }
      }
    }
    for (let i = 0, file; i < files.length; i++) {
      const reader: FileReader = new FileReader();
      reader.onprogress = (event) => { };

      reader.onload = (event) => {
        const fileResult = (event.srcElement || event.target)['result'].split(',').pop();
        this.http.post('Files.upload', {'filename': files[i]['name'], 'b64data': fileResult})
          .subscribe(
            (response) => {
              let comment = '';
              if (this.nameComment) {
                comment = response.filename.split('.').slice(0, -1).join('.');
              }
              const new_file = new UploadFile(response.file_id, response.filename, response.uploaded, comment);
              this._current_value.push(new_file);
              this.file_upload.emit(new_file);
              this.propagateChange(this._current_value.map(e => e.toDict()));
            },
            (error) => NotificationService.swalError('Ошибка', 'Ошибка загрузки файла')
          );
      };

      reader.readAsDataURL(files[i]);
    }
  }

  onFileRemove(filepath: string) {
    NotificationService.swalConfirm('Вы уверены?', 'Выбранный файл будет безвозвратно удален!')
      .then(() => {
        for (let i = 0; i < this.current_value.length; i++) {
          if (this.current_value[i].filepath === filepath) {
            if (this.current_value[i].id) {
              this.http.post('Files.remove', {'file_id': this.current_value[i].id}).subscribe();
            } else {
              this.http.post('Files.remove_by_name', {'file_name': this.current_value[i].filepath}).subscribe();
            }

            this.current_value.splice(i, 1);
            break;
          }
        }
        this.propagateChange(this._current_value.map(e => e.toDict()));
      }).catch(() => false);
  }

  onFileChange() {
    this.uploadFiles(this.fileInput.nativeElement.files);
  }

  onClick() {
    this.fileInput.nativeElement.click();
  }

  onCommentChange(value) {
    const fileFilter = this.current_value.filter((el) => el.filepath === value[0]);

    if (fileFilter.length) {
      const file = fileFilter.pop();
      file.comment = value[1];
      this.comment_change.emit(file);
      this.propagateChange(this._current_value.map(e => e.toDict()));
    }

  }

  onChange(value: Array<UploadFile>) {
    this.current_value = value;
  }

  writeValue(value: Array<{[key: string]: string}>) {
    this.current_value = value.map(e => new UploadFile(e.id, e.filename, e.filepath, e.comment || ''));
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

}
