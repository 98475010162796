import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {JsonClientService} from '../../../../shared/json-client';

declare const moment;

@Injectable()
export class GetComplexActivityResolve {

  constructor(private j: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot, ...args): Promise<any> | any {
    const options = {
      'date_start': route.params['start'] || moment()
        .startOf('year').format('DD.MM.YYYY'),
      'date_end': route.params['stop'] || moment()
        .endOf('month').format('DD.MM.YYYY'),
      'fact_type': route.params['fact_type'] || '',
      'result_type': route.params['result_type'] || '',
      'complex_type': route.params['complex_type'] || '',
      'events': route.params['events'] || 'all',
      'vitrina': route.params['vitrina'] || 'all',
    };
    return this.j.post('Analytics.get_complex_activity', options);
  }

}
