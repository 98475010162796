import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';

import { HttpClient } from '../../../../services/http.service';
import { OverlayWindowService } from '../../../single-window/shared/overlay-window.service';
import { NotificationService } from '../../../../services/notification.service';
import {JsonClientService} from '../../../../shared/json-client/index';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-physical-create-fields',
  templateUrl: './physical-create-fields.component.html',
  styleUrls: ['./physical-create-fields.component.scss']
})
export class PhysicalCreateFieldsComponent implements OnInit {

  @Input('form') public  form: UntypedFormGroup;
  @Output('formChange') public formChange: EventEmitter<any> = new EventEmitter<any>();
  @Output('dataChange') public dataChange: EventEmitter<any> = new EventEmitter<any>();


  constructor(private http: HttpClient, private overlayWindowService: OverlayWindowService,
              private fb: UntypedFormBuilder, private j: JsonClientService) {
  }

  ngOnInit() {
    this.form.addControl('physical_fam', this.fb.control('', Validators.required));
    this.form.addControl('physical_name', this.fb.control('', Validators.required));
    this.form.addControl('physical_otch', this.fb.control('', Validators.required));
    this.form.addControl('physical_email', this.fb.control(''));
    this.form.addControl('physical_phone', this.fb.control('', Validators.required));
    this.formChange.next(this.form);
    this.form.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      const data = {
        'physical_fam': value.physical_fam,
        'physical_name': value.physical_name,
        'physical_otch': value.physical_otch,
        'physical_email': value.physical_email,
        'physical_phone': value.physical_phone,
      };
      this.formChange.next(this.form);
      this.dataChange.next(data);
    });
  }
}
