import {Component, EventEmitter, Host, Input, OnDestroy, OnInit, Optional, Output} from '@angular/core';

import {OsmService} from '../osm.service';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {OsmClusterComponent} from '../osm-cluster/osm-cluster.component';
import {OsmMapComponent} from '../osm-map/osm-map.component';


@Component({
  selector: 'osm-placemark',
  templateUrl: './osm-placemark.component.html',
  styleUrls: [
    './osm-placemark.component.scss'
  ]
})
export class OsmPlacemarkComponent implements OnInit, OnDestroy {

  private readonly icons = {
    'house': {
      // source iconUrl: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMnB4IiBoZWlnaHQ9IjQ2cHgiIHZpZXdCb3g9IjAgMCAzMiA0NiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5Ba3ogLyBNYXAgLyBTaW5nbGUgUE9JPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxkZWZzPjwvZGVmcz4gICAgPGcgaWQ9IkRlc2lnbiIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0iSG9tZS0vLU1hcC1WaWV3IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjI3LjAwMDAwMCwgLTc0My4wMDAwMDApIiBmaWxsPSIjREUxRDI1IiBmaWxsLXJ1bGU9Im5vbnplcm8iPiAgICAgICAgICAgIDxnIGlkPSJBa3otLy1NYXAtLy1TaW5nbGUtUE9JIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2MjcuMDAwMDAwLCA3NDMuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNiwwIEM3LjE3OTY4OCwwIDAsNy4xNzk2ODggMCwxNiBDMCwyOC4wNzgxMjUgMTQuNjI4OTA2LDQ0Ljk0NTMxMyAxNS4yNSw0NS42NTYyNSBDMTUuNDQxNDA2LDQ1Ljg3NSAxNS43MTA5MzgsNDYgMTYsNDYgQzE2LjI4OTA2Myw0NiAxNi41NTg1OTQsNDUuODcxMDk0IDE2Ljc1LDQ1LjY1NjI1IEMxNy4zNzEwOTQsNDQuOTQ1MzEzIDMyLDI4LjE1MjM0NCAzMiwxNiBDMzIsNy4xNzk2ODggMjQuODIwMzEzLDAgMTYsMCBaIE0xNiw4IEwyNCwxNCBMMjIsMTQgTDIyLDIyIEwxMCwyMiBMMTAsMTQgTDgsMTQgTDE2LDggWiI+PC9wYXRoPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==',
      // small white iconUrl: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4wLjIsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i0KHQu9C+0LlfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzMiA0NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzIgNDY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNERTFEMjU7c3Ryb2tlOiNGRkZGRkY7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fQ0KPC9zdHlsZT4NCjx0aXRsZT5Ba3ogLyBNYXAgLyBTaW5nbGUgUE9JPC90aXRsZT4NCjxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPg0KPGcgaWQ9IkRlc2lnbiI+DQoJPGcgaWQ9IkhvbWUtX3gyRl8tTWFwLVZpZXciIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02MjcuMDAwMDAwLCAtNzQzLjAwMDAwMCkiPg0KCQk8ZyBpZD0iQWt6LV94MkZfLU1hcC1feDJGXy1TaW5nbGUtUE9JIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2MjcuMDAwMDAwLCA3NDMuMDAwMDAwKSI+DQoJCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTYsMEM3LjIsMCwwLDcuMiwwLDE2YzAsMTIuMSwxNC42LDI4LjksMTUuMywyOS43YzAuMiwwLjIsMC41LDAuMywwLjgsMC4zYzAuMywwLDAuNi0wLjEsMC44LTAuMw0KCQkJCUMxNy40LDQ0LjksMzIsMjguMiwzMiwxNkMzMiw3LjIsMjQuOCwwLDE2LDB6IE0xNiw4bDgsNmgtMnY4SDEwdi04SDhMMTYsOHoiLz4NCgkJPC9nPg0KCTwvZz4NCjwvZz4NCjwvc3ZnPg0K',
      // very white iconUrl: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4wLjIsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i0KHQu9C+0LlfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzMiA0NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzIgNDY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNERTFEMjU7c3Ryb2tlOiNGRkZGRkY7c3Ryb2tlLXdpZHRoOjM7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fQ0KPC9zdHlsZT4NCjx0aXRsZT5Ba3ogLyBNYXAgLyBTaW5nbGUgUE9JPC90aXRsZT4NCjxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPg0KPGcgaWQ9IkRlc2lnbiI+DQoJPGcgaWQ9IkhvbWUtX3gyRl8tTWFwLVZpZXciIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02MjcuMDAwMDAwLCAtNzQzLjAwMDAwMCkiPg0KCQk8ZyBpZD0iQWt6LV94MkZfLU1hcC1feDJGXy1TaW5nbGUtUE9JIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2MjcuMDAwMDAwLCA3NDMuMDAwMDAwKSI+DQoJCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTYsMEM3LjIsMCwwLDcuMiwwLDE2YzAsMTIuMSwxNC42LDI4LjksMTUuMywyOS43YzAuMiwwLjIsMC41LDAuMywwLjgsMC4zYzAuMywwLDAuNi0wLjEsMC44LTAuMw0KCQkJCUMxNy40LDQ0LjksMzIsMjguMiwzMiwxNkMzMiw3LjIsMjQuOCwwLDE2LDB6IE0xNiw4bDgsNmgtMnY4SDEwdi04SDhMMTYsOHoiLz4NCgkJPC9nPg0KCTwvZz4NCjwvZz4NCjwvc3ZnPg0K',
      iconUrl: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4wLjIsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i0KHQu9C+0LlfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzMiA0NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzIgNDY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNERTFEMjU7c3Ryb2tlOiNGRkZGRkY7c3Ryb2tlLXdpZHRoOjI7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fQ0KPC9zdHlsZT4NCjx0aXRsZT5Ba3ogLyBNYXAgLyBTaW5nbGUgUE9JPC90aXRsZT4NCjxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPg0KPGcgaWQ9IkRlc2lnbiI+DQoJPGcgaWQ9IkhvbWUtX3gyRl8tTWFwLVZpZXciIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02MjcuMDAwMDAwLCAtNzQzLjAwMDAwMCkiPg0KCQk8ZyBpZD0iQWt6LV94MkZfLU1hcC1feDJGXy1TaW5nbGUtUE9JIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2MjcuMDAwMDAwLCA3NDMuMDAwMDAwKSI+DQoJCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTYsMEM3LjIsMCwwLDcuMiwwLDE2YzAsMTIuMSwxNC42LDI4LjksMTUuMywyOS43YzAuMiwwLjIsMC41LDAuMywwLjgsMC4zYzAuMywwLDAuNi0wLjEsMC44LTAuMw0KCQkJCUMxNy40LDQ0LjksMzIsMjguMiwzMiwxNkMzMiw3LjIsMjQuOCwwLDE2LDB6IE0xNiw4bDgsNmgtMnY4SDEwdi04SDhMMTYsOHoiLz4NCgkJPC9nPg0KCTwvZz4NCjwvZz4NCjwvc3ZnPg0K',
      iconSize: [32, 46],
      iconAnchor: [15, 46],
    },
    'blue': {
      iconUrl: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCAyMyAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAgMTEuNDc4M0MwIDUuMTUwNjUgNS4xNTA2NSAwIDExLjQ3ODMgMEMxNy44MDU5IDAgMjIuOTU2NSA1LjE1MDY1IDIyLjk1NjUgMTEuNDc4M0MyMi45NTY1IDIwLjE5NjIgMTIuNDYxOSAzMi4yNDM0IDEyLjAxNjMgMzIuNzUzNEMxMS44NzkgMzIuOTA3NSAxMS42ODU2IDMzIDExLjQ3ODMgMzNDMTEuMjcwOSAzMyAxMS4wNzc1IDMyLjkxMDMgMTAuOTQwMiAzMi43NTM0QzEwLjQ5NDcgMzIuMjQzNCAwIDIwLjE0MyAwIDExLjQ3ODNaTTExLjUgMTZDMTMuOTg1MyAxNiAxNiAxMy45ODUzIDE2IDExLjVDMTYgOS4wMTQ3MiAxMy45ODUzIDcgMTEuNSA3QzkuMDE0NzIgNyA3IDkuMDE0NzIgNyAxMS41QzcgMTMuOTg1MyA5LjAxNDcyIDE2IDExLjUgMTZaIiBmaWxsPSIjMEY4M0VEIi8+PC9zdmc+',
      iconSize: [23, 33],
      iconAnchor: [11, 33],
    },
    'eye': {
      iconUrl: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDg5IiBoZWlnaHQ9IjQ4OSIgdmlld0JveD0iMCAwIDQ4OSA0ODkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+PHBhdGggZD0iTTI0NC40MjUgOTguNzI1QzE1MS4wMjUgOTguNzI1IDY2LjMyNSAxNDkuODI1IDMuODI1IDIzMi44MjVDLTEuMjc1IDIzOS42MjUgLTEuMjc1IDI0OS4xMjUgMy44MjUgMjU1LjkyNUM2Ni4zMjUgMzM5LjAyNSAxNTEuMDI1IDM5MC4xMjUgMjQ0LjQyNSAzOTAuMTI1QzMzNy44MjUgMzkwLjEyNSA0MjIuNTI1IDMzOS4wMjUgNDg1LjAyNSAyNTYuMDI1QzQ5MC4xMjUgMjQ5LjIyNSA0OTAuMTI1IDIzOS43MjUgNDg1LjAyNSAyMzIuOTI1QzQyMi41MjUgMTQ5LjgyNSAzMzcuODI1IDk4LjcyNSAyNDQuNDI1IDk4LjcyNVpNMjUxLjEyNSAzNDcuMDI1QzE4OS4xMjUgMzUwLjkyNSAxMzcuOTI1IDI5OS44MjUgMTQxLjgyNSAyMzcuNzI1QzE0NS4wMjUgMTg2LjUyNSAxODYuNTI1IDE0NS4wMjUgMjM3LjcyNSAxNDEuODI1QzI5OS43MjUgMTM3LjkyNSAzNTAuOTI1IDE4OS4wMjUgMzQ3LjAyNSAyNTEuMTI1QzM0My43MjUgMzAyLjIyNSAzMDIuMjI1IDM0My43MjUgMjUxLjEyNSAzNDcuMDI1Wk0yNDguMDI1IDI5OS42MjVDMjE0LjYyNSAzMDEuNzI1IDE4Ny4wMjUgMjc0LjIyNSAxODkuMjI1IDI0MC44MjVDMTkwLjkyNSAyMTMuMjI1IDIxMy4zMjUgMTkwLjkyNSAyNDAuOTI1IDE4OS4xMjVDMjc0LjMyNSAxODcuMDI1IDMwMS45MjUgMjE0LjUyNSAyOTkuNzI1IDI0Ny45MjVDMjk3LjkyNSAyNzUuNjI1IDI3NS41MjUgMjk3LjkyNSAyNDguMDI1IDI5OS42MjVaIiBmaWxsPSJibGFjayIvPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjUxLjEyNSAzNDcuMDI1QzE4OS4xMjUgMzUwLjkyNSAxMzcuOTI1IDI5OS44MjUgMTQxLjgyNSAyMzcuNzI1QzE0NS4wMjUgMTg2LjUyNSAxODYuNTI1IDE0NS4wMjUgMjM3LjcyNSAxNDEuODI1QzI5OS43MjUgMTM3LjkyNSAzNTAuOTI1IDE4OS4wMjUgMzQ3LjAyNSAyNTEuMTI1QzM0My43MjUgMzAyLjIyNSAzMDIuMjI1IDM0My43MjUgMjUxLjEyNSAzNDcuMDI1Wk0xODkuMjI1IDI0MC44MjVDMTg3LjAyNSAyNzQuMjI1IDIxNC42MjUgMzAxLjcyNSAyNDguMDI1IDI5OS42MjVDMjc1LjUyNSAyOTcuOTI1IDI5Ny45MjUgMjc1LjYyNSAyOTkuNzI1IDI0Ny45MjVDMzAxLjkyNSAyMTQuNTI1IDI3NC4zMjUgMTg3LjAyNSAyNDAuOTI1IDE4OS4xMjVDMjEzLjMyNSAxOTAuOTI1IDE5MC45MjUgMjEzLjIyNSAxODkuMjI1IDI0MC44MjVaIiBmaWxsPSJ3aGl0ZSIvPjwvZz48ZGVmcz48Y2xpcFBhdGggaWQ9ImNsaXAwIj48cmVjdCB3aWR0aD0iNDg4Ljg1IiBoZWlnaHQ9IjQ4OC44NSIgZmlsbD0id2hpdGUiLz48L2NsaXBQYXRoPjwvZGVmcz48L3N2Zz4=',
      iconSize: [28, 18],
      iconAnchor: [14, 9],
    }
  };


  private L;

  private latitude;
  private longitude;
  private _lowOpacity = false;
  private lowOpacityStyles = {
    opacity: 0.7,
    icon: {
      // version 1
      // iconUrl: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4wLjIsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i0KHQu9C+0LlfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzMiA0NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzIgNDY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNERTFEMjU7fQ0KPC9zdHlsZT4NCjx0aXRsZT5Ba3ogLyBNYXAgLyBTaW5nbGUgUE9JPC90aXRsZT4NCjxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPg0KPGc+DQoJPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIyMi41LDE0IDIyLDE0IDIyLDIyIDEwLDIyIDEwLDE0IDkuNSwxNCA5LjUsMjIuNCAyMi41LDIyLjQgCSIvPg0KCTxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMjQsMTQgMjUsMTQgMTYsOCA3LDE0IDgsMTQgMTYsOC41IAkiLz4NCgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTYsMEM3LjIsMCwwLDcuMiwwLDE2YzAsMTIuMSwxNC42LDI4LjksMTUuMywyOS43QzE0LjYsNDQuOSwxLjQsMjguMSwxLjQsMTZDMS40LDcuMiw4LjcsMS4xLDE2LDEuMQ0KCQljNy42LDAsMTQuNiw2LjEsMTQuNiwxNC45YzAsMTIuMi0xMy4yLDI4LjktMTMuOCwyOS43QzE3LjQsNDQuOSwzMiwyOC4yLDMyLDE2QzMyLDcuMiwyNC44LDAsMTYsMHoiLz4NCjwvZz4NCjwvc3ZnPg0K',
      // version with round
      // iconUrl: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjUwIiBoZWlnaHQ9IjUwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogPCEtLSBDcmVhdGVkIHdpdGggU1ZHLWVkaXQgLSBodHRwczovL2dpdGh1Yi5jb20vU1ZHLUVkaXQvc3ZnZWRpdC0tPgogPGcgY2xhc3M9ImxheWVyIj4KICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+CiAgPGVsbGlwc2UgY3g9IjI1LjAwMDAwMSIgY3k9IjI1LjAwMDAwMSIgZmlsbD0iI2ZmZmZmZiIgaWQ9InN2Z18yIiByeD0iMTQuNTAwMDAxIiByeT0iMTQuNSIgc3Ryb2tlPSIjZmYwMDAwIiBzdHJva2UtZGFzaGFycmF5PSJudWxsIiBzdHJva2UtbGluZWNhcD0ibnVsbCIgc3Ryb2tlLWxpbmVqb2luPSJudWxsIiBzdHJva2Utd2lkdGg9IjE1Ii8+CiA8L2c+Cjwvc3ZnPg==',
      iconUrl: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8IS0tIENyZWF0ZWQgd2l0aCBTVkctZWRpdCAtIGh0dHBzOi8vZ2l0aHViLmNvbS9TVkctRWRpdC9zdmdlZGl0LS0+CgogPGc+CiAgPHRpdGxlPmJhY2tncm91bmQ8L3RpdGxlPgogIDxyZWN0IGZpbGw9Im5vbmUiIGlkPSJjYW52YXNfYmFja2dyb3VuZCIgaGVpZ2h0PSI2MDIiIHdpZHRoPSI4MDIiIHk9Ii0xIiB4PSItMSIvPgogPC9nPgogPGcgY2xhc3M9ImxheWVyIj4KICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+CiAgPGVsbGlwc2Ugc3Ryb2tlLXdpZHRoPSIxNSIgc3Ryb2tlLWxpbmVqb2luPSJudWxsIiBzdHJva2UtbGluZWNhcD0ibnVsbCIgc3Ryb2tlPSIjZGUxZDI1IiByeT0iMTQuNSIgcng9IjE0LjUiIGlkPSJzdmdfMiIgZmlsbD0iI2ZmZmZmZiIgY3k9IjI1IiBjeD0iMjUiLz4KIDwvZz4KPC9zdmc+',
      iconSize: [16, 16],
      iconAnchor: [8, 8],
      // version 2
      // iconUrl: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4wLjIsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i0KHQu9C+0LlfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzMiA0NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzIgNDY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNERTFEMjU7fQ0KPC9zdHlsZT4NCjx0aXRsZT5Ba3ogLyBNYXAgLyBTaW5nbGUgUE9JPC90aXRsZT4NCjxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPg0KPGc+DQoJPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIyMy4yLDE0IDIyLDE0IDIyLDIyIDEwLDIyIDEwLDE0IDguOCwxNCA4LjgsMjMuMSAyMy4yLDIzLjEgCSIvPg0KCTxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMjQsMTQgMjUuNywxNCAxNiw3LjMgNi4zLDE0IDgsMTQgMTYsOC41IAkiLz4NCgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTYsMEM3LjIsMCwwLDcuMiwwLDE2YzAsMTIuMSwxNC42LDI4LjksMTUuMywyOS43QzE0LjYsNDQuOSwyLjEsMjguMSwyLjEsMTZDMi4xLDcuMiw4LjcsMS44LDE2LDEuOA0KCQljNy42LDAsMTMuOSw1LjMsMTMuOSwxNC4yYzAsMTIuMi0xMi41LDI4LjktMTMuMSwyOS43QzE3LjQsNDQuOSwzMiwyOC4yLDMyLDE2QzMyLDcuMiwyNC44LDAsMTYsMHoiLz4NCjwvZz4NCjwvc3ZnPg0K',
      // iconSize: [24, 34],
      // iconAnchor: [11, 34],
    },
  };

  public placemark;
  public mapSubscribe: Subscription;
  private ngDestroy = new Subject();

  @Input() private clickOnlyWithoutCluster = false;
  @Input() private draggable = false;
  @Input() private set lowOpacity(lowOpacity) {
    if (lowOpacity === this._lowOpacity) {
      return;
    }
    this._lowOpacity = lowOpacity;
    if (this.placemark) {
      this.placemark.setOpacity(lowOpacity ? this.lowOpacityStyles.opacity : 1);
      this.placemark.setIcon(this.L.icon(lowOpacity ? this.lowOpacityStyles.icon : this.icons[this.icon]));
    }
  }
  @Input() private balloonContent = null;
  @Input() private icon = 'house';

  @Input() private set coordinates(value) {
    if (!value.length || value.length !== 2) {
      throw new Error('Invalid coordinates');
    }

    this.latitude = Math.round(value[0] * 1000000) / 1000000;
    this.longitude = Math.round(value[1] * 1000000) / 1000000;
    this.setPosition();
  }

  private get coordinates() {
    return [this.latitude, this.longitude];
  }

  @Input() public options = {};
  @Input() public lastElement = false;

  @Output() public click: EventEmitter<any> = new EventEmitter();
  @Output() public drag: EventEmitter<any> = new EventEmitter();

  constructor(private o: OsmService,
              @Optional() @Host() private host_map: OsmMapComponent,
              @Optional() @Host() private host_cluster: OsmClusterComponent) { }

  ngOnInit(): void {
    this.mapSubscribe = this.o.$getMap
      .pipe(takeUntil(this.ngDestroy))
      .subscribe((L) => {
        if (!L) { return; }

        this.L = L;

        if (this.host_map) {
          this.host_map.ready.then(_ => this.whenMapReady());
        } else {
          this.host_cluster.ready.then(_ => this.whenMapReady());
        }
      });
  }

  ngOnDestroy(): void {
    this.o.dropPlacemark(this, this.host_cluster, this.host_map);
    this.placemark.off('click');
    this.placemark.off('dragend');

    this.ngDestroy.next(null);
    this.ngDestroy.complete();
    if (this.mapSubscribe) {
      this.mapSubscribe.unsubscribe();
    }
  }

  whenMapReady() {
    const options = {'draggable': this.draggable};
    if (this._lowOpacity) {
      options['icon'] = this.L.icon(this.lowOpacityStyles.icon);
      options['opacity'] = this.lowOpacityStyles.opacity;
    } else {
      options['icon'] = this.L.icon(this.icons[this.icon]);
    }

    this.placemark = this.L.marker(this.coordinates, options);

    if (this.balloonContent) {
      this.placemark.bindPopup(this.balloonContent).openPopup();
    }

    this.placemark.on('dragend', e => {
      const position = e.target['_latlng'];
      this.drag.emit([position['lat'], position['lng']]);
    });

    this.placemark.on('click', _ => {
      if (!(this.host_cluster && this.clickOnlyWithoutCluster)) {
        this.click.emit(this);
      }
    });

    this.o.registerPlacemark(this.placemark, this, this.host_cluster, this.host_map, this.lastElement);
    if  (this.options['caption']) {
      this.placemark.bindTooltip(this.options['caption']);
    }
  }

  balloonOpen() {
    if (this.balloonContent) {
      this.placemark.openPopup();
    }
  }

  private setPosition() {
    if (this.placemark) {
      this.placemark.setLatLng(this.L.latLng(this.latitude, this.longitude));
    }
  }

}
