import {Injectable} from '@angular/core';
import {HttpClient} from '../../../services/http.service';


@Injectable()
export class PurchTermTableService {

  constructor(private http: HttpClient) { }

  public saveTerm(complex_ids, values) {
    let resolver = (...args) => null;
    let rejecter = (...args) => null;

    const promise = new Promise<any>((resolve, reject) => {
      resolver = resolve;
      rejecter = reject;
    });

    const request = {'complex_ids': complex_ids, 'values': values};

    this.http.post('Catalog.mass_filling_term_purch', request)
      .subscribe(
        response => resolver(response),
        error => rejecter(error),
      );

    return promise;
  }

}
