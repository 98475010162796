import {Component, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {HttpClient} from '../../services/http.service';
import {AuthenticateService} from '../../services/authenticate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import {environment} from '../../../environments/environment';
import {UsageVariant} from '../../models/usage_variants';
import wizardUrls from '../../shared/wizard-urls';
import { ConfigService } from '../../services/configuration.service';
import {WebinarService} from '../../_front/webinar/shared/webinar.service';
import {ClientModeService} from '../../_front/client-mode/client-mode.service';

@Component({
  templateUrl: './authenticate-login.component.html'
})
export class AuthenticateLoginComponent implements OnInit {

  public form: UntypedFormGroup;
  public loading = false;
  public buttonLocked = false;

  constructor(private fb: UntypedFormBuilder, private http: HttpClient, private auth: AuthenticateService,
              private router: Router, public config: ConfigService, public route: ActivatedRoute,
              @Optional() private ClientMode: ClientModeService,
              @Optional() private wService: WebinarService) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      'login': ['', Validators.required],
      'password': ['', Validators.required],
    });
    if (this.auth.is_autocomplete) {
      this.form.patchValue({
        'login': (this.auth.autocomplete) ? this.auth.autocomplete['login'] || '' : '',
        'password': (this.auth.autocomplete) ? this.auth.autocomplete['password'] || '' : ''
      });
    }
  }

  onLoginSuccess(response) {
    this.loading = true;
    this.config.reset();
    this.auth.authenticate(response.login, response.session_id, response.email, response);

    if ('wwwtoken' === response['token_type']) {
      if (1 === response['wizard_step']) {
        this.router.navigateByUrl(wizardUrls['step_1']);
      } else if (2 === response['wizard_step']) {
        this.router.navigateByUrl(wizardUrls['step_2']);
      } else if (3 === response['wizard_step']) {
        this.router.navigateByUrl(wizardUrls['step_3']);
      } else if (4 === response['wizard_step']) {
        this.router.navigateByUrl(wizardUrls['step_4']);
      } else if (5 === response['wizard_step']) {
        this.router.navigateByUrl(wizardUrls['step_5']);
      } else if (6 === response['wizard_step']) {
        this.router.navigateByUrl(wizardUrls['step_6']);
      } else if (7 === response['wizard_step']) {
        this.router.navigateByUrl(wizardUrls['step_7']);
      }
      if (this.wService) {
        this.wService.checkStatus();
      }
    }

    this.config.setShowNews(true);

    const returnUrl = this.route.snapshot.queryParams['returnUrl'];
    if (returnUrl) {
      this.router.navigateByUrl(returnUrl);
    } else if (this.config.data['search_type']) {
      this.router.navigateByUrl('objects/search/' + this.config.data['search_type']);
      return false;
    } else {
      this.router.navigateByUrl('');
    }
  }

  onLoginError(error) {
    this.form.patchValue({
      'password': ''
    });
  }

  onSubmit() {
    this.buttonLocked = true;
    const post_data =  {
      'username': this.form.value.login,
      'password': this.form.value.password,
      'is_subagent': environment.usage === UsageVariant.Frontend,
    };

    this.http.post('Session.signin', post_data)
      .subscribe(
        (response) => {
          if (this.ClientMode) {
            this.ClientMode.client = null;
          }
          this.buttonLocked = false;
          this.auth.saveAutocomplete(post_data.username, post_data.password);
          this.onLoginSuccess(response);
        },
        (error) => {
          NotificationService.swalError('Ошибка', error.error.error.data.clear_message);
          this.buttonLocked = false;
          this.onLoginError(error);
        }
      );
  }

}
