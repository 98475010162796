import {Component, ComponentRef, OnInit, ViewChild} from '@angular/core';
import {OverlayWindowService} from '../../single-window/shared/overlay-window.service';
import {OnlineCompareViewStaffComponent} from './online-compare-view-staff.component';
import {GoBackButtonService} from '../../../services/go-back-button.service';


@Component({
  templateUrl: './online-compare-view.component.html',
  styleUrls: ['./online-compare-view.component.scss']
})
export class OnlineCompareViewComponent implements OnInit {

  public columns;

  @ViewChild('onlineStaff', {static: true}) private onlineStaff;
  @ViewChild('offlineStaff', {static: true}) private offlineStaff;
  @ViewChild('allStaff', {static: true}) private allStaff;

  constructor(private overlayWindowService: OverlayWindowService, private goBack: GoBackButtonService) { }

  ngOnInit() {
    this.goBack.header = '<h1>Сводный вид</h1>';
    this.columns = [
      {'name': 'organisation_socrachcaption', 'caption': 'Название организации'},
      {'name': 'all_days_online', 'caption': 'Дней онлайн'},
      {'name': 'cnt_staff', 'caption': 'Кол-во сотрудников', 'template': this.allStaff},
      {'name': 'cnt_staff_online', 'caption': 'Сотрудников заходило', 'template': this.onlineStaff},
      {'name': 'cnt_staff_offline', 'caption': 'Сотрудников не заходило', 'template': this.offlineStaff},
    ];
  }

  openPopup(row, is_offline) {
    this.overlayWindowService.openComponentInPopup(OnlineCompareViewStaffComponent)
      .then((componentRef: ComponentRef<OnlineCompareViewStaffComponent>) => {
        componentRef.instance.staff = row;
        componentRef.instance.is_offline = is_offline;
      });
  }

  displayStaff(row) {
    this.openPopup(row['staff_offline_list'], true);
  }

  displayStaffOnline(row) {
    this.openPopup(row['staff_online_list'], false);
  }

  displayAllStaff(row) {
    let staff = row['staff_online_list'];
    staff = staff.concat(row['staff_offline_list']);

    this.openPopup(staff, null);
  }

}
