/*
 * Author: Pavel Ivanov
 * Company: IT-Lab
 */

import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class CalendarListService {

  public clickEventEmitter: EventEmitter<string> = new EventEmitter();

}
