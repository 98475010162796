import {Component, EventEmitter, Input, Output} from '@angular/core';
import {HttpClient} from '../../../services/http.service';
import {CommonModule} from '@angular/common';


@Component({
  standalone: true,
  selector: 'app-import-analyze',
  templateUrl: './import-analyze.component.html',
  styleUrl: './import-analyze.component.scss',
  imports: [
    CommonModule
  ]
})
export class ImportAnalyzeComponent {
  @Input() feedUrl;
  @Output() onLoading = new EventEmitter<boolean>();
  @Output() onError = new EventEmitter<string>();

  public analyzeResult;
  public analyzeFilter = null;
  public error;
  public _loading = false;
  public set loading(v: boolean) {
    this._loading = v;
    this.onLoading.emit(v);
  }
  public get loading() {
    return this._loading;
  }

  constructor(public http: HttpClient) { }

  analyze(type, params = null) {
    if (!this.feedUrl) {
      this.error = 'Заполните поле url';
      this.onError.emit(this.error);
      return;
    }

    this.loading = true;
    this.error = undefined;
    this.analyzeResult = undefined;
    if (params === null) {
      this.analyzeFilter = null;
    } else if (this.analyzeFilter === null) {
      this.analyzeFilter = [params];
    } else {
      this.analyzeFilter.push(params);
    }
    // this.formGroup.disable();
    const data = {
      'url': this.feedUrl,
      'type_str': type,
      'params': this.analyzeFilter,
    };

    this.http.post('Import.check_url', data).subscribe(
      (response) => {
        this.loading = false;
        // this.formGroup.enable();
        this.analyzeResult = response;
        // console.log(response);
      },
      (error) => {
        this.loading = false;
        // this.formGroup.enable();
      }
    );
  }
}
