<div class="page-slider" [ngClass]="cssClass">
  <div class="page-slider__content">
    <ng-content></ng-content>
  </div>
  <div class="page-slider__buttons">
    <button type="button" class="close modal__close c-pointer page-slider__close" (click)="onClose()">
      <i class="icon icon_Small_Close"></i>
    </button>
    <button
      *ngIf="!embeddedService"
      class="button page-slider__button button-small dont_show"
      (click)="forAllPages()"
    >Больше не показывать</button>
    <button *ngIf="activeIndex != 0" class="button page-slider__button" (click)="onBack()">< Назад</button>
    <button *ngIf="activeIndex < (element.length-1)" class="button button_main ml-2 page-slider__button" (click)="onNext()">Далее ></button>
    <button *ngIf="activeIndex == (element.length-1)" class="button button_main ml-2 page-slider__button" (click)="onComplete()">Готово</button>
  </div>
</div>
