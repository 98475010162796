import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient } from '../../../services/http.service';

@Injectable()
export class ImportFormatsResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.http.post('Dict.get_import_specifications');
  }
}
