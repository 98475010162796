import { AfterContentInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { HttpClient } from '../../../services/http.service';
import { ActivatedRoute, NavigationEnd, ResolveEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OverlayWindowService } from '../../single-window/shared/overlay-window.service';
import { DataTableParamComponent } from '../../../modules/@datatable/components/datatable-param.component';
import { SingleWindowService } from '../../single-window/shared/single-window.service';
import { SingleWindowComponent } from '../../single-window/single-window.component';
import { StatisticPluginService } from '../shared/statistic-plugin.service';
import { BackrefWindowService } from '../../single-window/shared/backref-window.service';
import { filter } from 'rxjs/operators';
import {GoBackButtonService} from '../../../services/go-back-button.service';

@Component({
  selector: 'app-statistic-plugin',
  templateUrl: './statistic-plugin.component.html',
  styleUrls: ['./statistic-plugin.component.scss']
})
export class StatisticPluginComponent extends DataTableParamComponent implements OnInit, AfterContentInit, OnDestroy {

  @ViewChild('subagent_id', {static: true}) private subagent_id;
  @ViewChild('organisation_caption', {static: true}) private organisation_caption;
  @ViewChild('frame_is_active', {static: true}) private frame_is_active;
  @ViewChild('frame_site', {static: true}) private frame_site;
  @ViewChild('cnt_complexes', {static: true}) private cnt_complexes;
  @ViewChild('frame_cnt_requests', {static: true}) private frame_cnt_requests;
  @ViewChild('frame_cnt_appeals', {static: true}) private frame_cnt_appeals;
  @ViewChild('frame_appeals_list', {static: true}) private frame_appeals_list;
  @ViewChild('frame_remote_url', {static: true}) private frame_remote_url;
  @ViewChild('button', {static: true}) private button;

  public columns;
  private subscription: Subscription;
  public api_action = 'IFrame.dt_all_plugins';
  public url: any;

  constructor(public http: HttpClient, private fb: UntypedFormBuilder, public router: Router, private goBack: GoBackButtonService,
              public activatedRoute: ActivatedRoute, private overlayWindowService: OverlayWindowService,
              public singleWindowService: SingleWindowService, public backrefWindowService: BackrefWindowService,
              public statisticPluginService: StatisticPluginService) {
    super(http, activatedRoute, router);
    this.subscription = this.router.events.pipe(filter(x => x instanceof NavigationEnd))
      .subscribe((x: NavigationEnd) => {
        this.statisticPluginService.backref = x.url;
      });
    this.backrefWindowService.routerInit();
  }

  ngOnInit() {
    this.goBack.header = '<h1>Статистика по плагинам</h1>';
    this.columns = [
      {'name': 'organisation_caption', 'caption': 'Организация', 'sortable': true, 'template': this.organisation_caption},
      {'name': 'frame_site', 'caption': 'Сайт', 'sortable': true, 'template': this.frame_site},
      {'name': 'frame_is_active', 'caption': 'Активен', 'sortable': true, 'template': this.frame_is_active},
      {'name': 'frame_date_activate', 'caption': 'Дата активации', 'sortable': true},
      // {'name': 'frame_www_uuid', 'caption': 'Ключ', 'sortable': true},
      {'name': 'cnt_complexes', 'caption': 'Кол-во комплексов', 'sortable': true, 'template': this.cnt_complexes},
      {'name': 'frame_cnt_requests', 'caption': 'Кол-во запросов', 'sortable': true, 'template': this.frame_cnt_requests},
      {'name': 'frame_cnt_appeals', 'caption': 'Кол-во заявок', 'sortable': true, 'template': this.frame_cnt_appeals},
      {'name': 'frame_remote_url', 'caption': 'Расположение iframe', 'sortable': true, 'template': this.frame_remote_url},
      {'name': '', 'template': this.button},
    ];

    super.ngOnInit();
  }

  public prepareQuery() {
    const query = super.prepareQuery();
    // query['filter']['event_date_from'] = this.date_from;

    return query;
  }

  public loadList() {
    super.loadList();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.backrefWindowService.routerDestroy();
  }

  ngAfterContentInit() {

  }

  displayApproved(row, editable) {
    this.openPopup(row['event_id'], row['subscription_users_list'], editable, row['event_caption'], true);
  }

  displayDeclined(row, editable) {
    this.openPopup(row['event_id'], row['subscription_users_list'], editable, row['event_caption'], false);
  }

  openPopup(event_id, rows, editable, caption, is_approved) {
    // this.overlayWindowService.openComponentInPopup(CalendarStatisticPopupComponent)
    //   .then((componentRef: ComponentRef<CalendarStatisticPopupComponent>) => {
    //     componentRef.instance.event_id = event_id;
    //     componentRef.instance.rows = rows;
    //     componentRef.instance.editable = editable;
    //     componentRef.instance.caption = caption;
    //     componentRef.instance.is_approved = is_approved;
    //   });
  }

}
