import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FieldsModule} from '../../../../modules/@fields/fields.module';
import {FieldHighwayComponent} from './field-highway.component';
import {FieldHighwayPipe} from './field-highway.pipe';
import {MaterialInputModule} from '../../../../modules/@material-input';

@NgModule({
  imports: [
    CommonModule,
    FieldsModule,
    MaterialInputModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    FieldHighwayComponent,
    FieldHighwayPipe,
  ],
  exports: [
    FieldHighwayComponent,
    FieldHighwayPipe,
  ]
})
export class FieldHighwayModule { }
