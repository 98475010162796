import {Component, Host, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PriceRankPipe} from '../../../../../pipes/prices.pipe';
import {NotificationService} from '../../../../../services/notification.service';
import {HttpClient} from '../../../../../services/http.service';
import {DictionariesService} from '../../../../../services/dictionaries.service';
import {FlatInfoStandaloneComponent} from '../flat-info-standalone.component';
import {debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-flat-conditions',
  templateUrl: './flat-conditions.component.html',
  styleUrls: ['./flat-conditions.component.scss']
})
export class FlatConditionsComponent implements OnInit, OnDestroy {

  public data: any = null;
  public flat_statuses: Array<any> = [];
  public form: UntypedFormGroup;
  private subscription: Subscription;
  private capabilitySubscription: Subscription;
  @Input('flat_id') public flat_id;

  constructor(@Host() private host: FlatInfoStandaloneComponent,
              public fb: UntypedFormBuilder, public http: HttpClient,
              public notify: NotificationService, public priceRank: PriceRankPipe, private dict_helper: DictionariesService) {
  }

  ngOnInit() {
    const promises = [
      this.dict_helper.get('Dict.get_flat_statuses'),
    ];
    Promise.all(promises).then(result => {
      this.flat_statuses = result.shift();
    });

    if (this.flat_id) {
      this.getData();
    }
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.capabilitySubscription) {
      this.capabilitySubscription.unsubscribe();
    }
  }

  getData() {
    this.http.post('Flat.get', {'realty_id': +this.flat_id, 'tab': 'conditions'})
      .subscribe(data => {
        this.data = data;
        this.pathForm();
      });
  }

  pathForm() {
    this.form = this.host.form;

    this.form.addControl('conditions', this.fb.group({
      'flat_voznagr_agenta': this.data['flat_voznagr_agenta'],
      'flat_voznagr_agenta_from_ddu': this.data['flat_voznagr_agenta_from_ddu'],
      'flat_dolya_subagenta_db': (this.data['flat_dolya_subagenta_db'] * this.data['flat_voznagr_agenta']).toFixed(5),
      'flat_dolya_subagenta_agd': (this.data['flat_dolya_subagenta_agd'] * this.data['flat_voznagr_agenta_from_ddu']).toFixed(5),
      'flat_srok_realisation': this.data['flat_srok_realisation'],
      'flat_status_id': '' + this.data['flat_status_id'],
      'flat_unique_term_days': this.data['flat_unique_term_days'] ? this.data['flat_unique_term_days'] + '' : '',
      'flat_lead_transfer_capability': this.data['flat_lead_transfer_capability'],
      'flat_lead_transfer_sub_percent_db': [this.data['flat_lead_transfer_sub_percent_db'], Validators.required],
      'flat_lead_transfer_sub_percent_agd': [this.data['flat_lead_transfer_sub_percent_agd'], Validators.required],
    }));

    this.leadValidators(this.form.get('conditions').get('flat_lead_transfer_capability').value);
    this.capabilitySubscription = this.form.get('conditions').get('flat_lead_transfer_capability').valueChanges
      .subscribe(value => {
        this.leadValidators(value);
      });
  }
  leadValidators(value) {
    if (!value) {
      this.form.get('conditions').get('flat_lead_transfer_sub_percent_db').clearValidators();
      this.form.get('conditions').get('flat_lead_transfer_sub_percent_agd').clearValidators();
      this.form.get('conditions').get('flat_lead_transfer_sub_percent_db').patchValue(null);
      this.form.get('conditions').get('flat_lead_transfer_sub_percent_agd').patchValue(null);
    } else {
      this.form.get('conditions').get('flat_lead_transfer_sub_percent_db').setValidators(Validators.required);
      this.form.get('conditions').get('flat_lead_transfer_sub_percent_agd').setValidators(Validators.required);
      this.form.get('conditions').get('flat_lead_transfer_sub_percent_db').updateValueAndValidity();
      this.form.get('conditions').get('flat_lead_transfer_sub_percent_agd').updateValueAndValidity();
    }
  }
}
