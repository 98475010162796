import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {JsonClientService} from '../../shared/json-client';
import {Observable} from 'rxjs';

@Injectable()
export class EatComplexAssocResolve {
  constructor(public jsonRpc: JsonClientService) {
  }

  private filter_columns =
    [
      'eat_complex_caption',
      'spn_object',
      'fio',
      'assoc_exists'
    ];

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {

    const prepared_filter = {};
    const prepared_filter_keys = this.filter_columns.filter(x => !!route.parent.queryParams[x]);
    const sort = {};

    prepared_filter_keys.forEach(x => prepared_filter[x] = route.parent.queryParams[x]);

    if (!!route.queryParams['sort_name']) {
      sort[route.queryParams['sort_name']] = route.queryParams['sort_key'] || 'asc';
    }
    Object.assign(prepared_filter, {
      'global_tab': route.params['tab'],
    });
    const options = {
      'limit': 50,
      'offset': (+(route.queryParams['page'] || 1) - 1) * 50,
      '_filter': {
        'global_search_string': route.queryParams['global_search_string'] || null,
        'fields': prepared_filter,
      },
      'sort': sort
    };

    return this.jsonRpc.post('Estatet.get_eat_complex_captions', options);
  }
}
