import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {JsonClientService} from '../../shared/json-client';

@Injectable()
export class ContactsUnregResolve implements Resolve<any> {

  private filter_columns = [
    'physicals_fio',
    'phones_phone',
    'physicals_email',
    'event_caption',
    'status',
  ];
  constructor(private jsonRpc: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {

    const prepared_filter = {
    };
    const prepared_filter_keys = this.filter_columns.filter(x => !!route.parent.queryParams[x]);
    const sort = {};

    prepared_filter_keys.forEach(x => {
      let _x = route.parent.queryParams[x];
      if (_x === 'true' || _x === 'false') {
        _x = _x === 'true';
      }
      prepared_filter[x] = _x;
    });

    if (!!route.queryParams['sort_name']) {
      sort[route.queryParams['sort_name']] = route.queryParams['sort_key'] || 'asc';
    } else {
      sort['date_time'] = 'desc';
    }

    const options = {
      'limit': 50,
      'offset': (+(route.queryParams['page'] || 1) - 1) * 50,
      '_filter': {
        'fields': prepared_filter,
      },
      'sort': sort
    };
    return this.jsonRpc.post('Calendar.get_all_unreg_physical', options);
  }
}
