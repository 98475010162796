<div class="badge__border">
  <div
    class="badge__wrapper"
    [class.border-red]="isPrivateHighlight === true"
    [class.border-green]="isPrivateHighlight === false"
  >
    <div class="badge__text" [style.backgroundColor]="color">
      <ng-container *ngIf="link && !embeddedService">
        <a title="{{title || text}}" href="{{link}}" target="_blank">{{text}}</a>
      </ng-container>
      <ng-container *ngIf="!link || embeddedService">
        <span title="{{title || text}}">{{text}}</span>
      </ng-container>
    </div>
    <div class="badge__icon">
      <i class="fa fa-star" [style.color]="color"></i>
    </div>
  </div>
</div>
