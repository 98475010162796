<div class="row teaching" *ngIf="statistic">
  <div class="col-lg-4 col-md-6 col-sm-12 statistic_cols">
    <p class="teaching__digits">{{statistic['events']['cnt_events_all']}} / {{statistic['events']['cnt_events_done']}} / {{statistic['events']['cnt_events_plan']}} / {{statistic['events']['cnt_events_cancel']}}</p>
    <p class="teaching__legend">Мероприятия</p>
    <p class="teaching__legend_mute">всего / проведено / планируется / отменено</p>
    <p class="teaching__legend_mute">100% / {{statistic['events']['cnt_events_done_perc']}}% / {{statistic['events']['cnt_events_plan_perc']}}% / {{statistic['events']['cnt_events_cancel_perc']}}%</p>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12 statistic_cols">
    <p class="teaching__digits">{{statistic['events_organisations']['cnt_organisation_subagents']}} / {{statistic['events_organisations']['cnt_organisation_plan']}} / {{statistic['events_organisations']['cnt_organisation_visit']}} / {{statistic['events_organisations']['cnt_organisation_not_visit']}}</p>
    <p class="teaching__legend">Организации</p>
    <p class="teaching__legend_mute">все / записано / присутствовали / не присутствовали</p>
    <p class="teaching__legend_mute">100% / {{statistic['events_organisations']['cnt_organisation_plan_perc']}}% / {{statistic['events_organisations']['cnt_organisation_visit_perc']}}% / {{statistic['events_organisations']['cnt_organisation_not_visit_perc']}}%</p>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12 statistic_cols">
    <p class="teaching__digits">{{statistic['events_users']['places_count_all'] ? statistic['events_users']['places_count_all'] : 0}} / {{statistic['events_users']['cnt_users_subscribe']}} / {{statistic['events_users']['cnt_users_visit']}} / {{statistic['events_users']['cnt_users_cancel']}}</p>
    <p class="teaching__legend">Неуникальные пользователи</p>
    <p class="teaching__legend_mute">кол-во мест / записано / пришло / не пришло</p>
    <p class="teaching__legend_mute">100% / {{statistic['events_users']['cnt_users_subs_perc']}}% / {{statistic['events_users']['cnt_users_visit_perc']}}% / {{statistic['events_users']['cnt_users_cancel_perc']}}%</p>
  </div>
</div>

<div *ngIf="statistic" class="spoiler">
  <div class="row justify-content-between">
    <div class="col-auto">
      <a href="javascript:void(0)" (click)="spoilered = !spoilered">Подробная статистика</a>
    </div>
    <div class="col-auto">
      <a [href]="apiServer + 'restful/calendar_statistic' + '?date_from=' + this.date_from + '&date_to=' + this.date_to"
         target="_blank">Скачать статистику в Excel</a>
    </div>
  </div>
  <div class="spoiler__content mt-3" [hidden]="!spoilered">
    <app-simple-table>
      <app-simple-table-th>Тип мероприятия</app-simple-table-th>
      <app-simple-table-th>Всего мероприятий</app-simple-table-th>
      <app-simple-table-th>Проведено мероприятий</app-simple-table-th>
      <app-simple-table-th>Планируется мероприятий</app-simple-table-th>
      <app-simple-table-th>Отменено мероприятий</app-simple-table-th>
      <!-- // -->
      <app-simple-table-tr *ngFor="let row of statistic['events_types']">
        <td>{{row['caption_type']}}</td>
        <td>{{row['cnt_type_event']}}</td>
        <td>{{row['cnt_done_type_event']}}</td>
        <td>{{row['cnt_plan_type_event']}}</td>
        <td>{{row['cnt_cancel_type_event']}}</td>
      </app-simple-table-tr>
    </app-simple-table>
  </div>
</div>
