<div class="single-window">
  <div class="single-window__wrapper">
    <div class="single-window__nav">
      <div class="single-window__nav__column_1">
        <div class="single-window__head">
          <a class="single-window__head__favorites icon c-pointer"
             [ngClass]="{'icon_star-simple': !favorite, 'icon_star-active': favorite}"
             (click)="markFavorite()"></a>
          <div class="single-window__head__title">{{header}}</div>
        </div>
      </div>
      <div class="single-window__nav__column_2">
        <ul class="single-window__nav__icons">
          <li class="single-window__nav__icon__prev-next">
            <a class="icon icon_chevron_left_round"></a>
            <span class="icon icon_chevron_divider"></span>
            <a class="icon icon_chevron_right_round"></a>
          </li>
          <li class="single-window__nav__icon__close ">
            <a class="icon icon_delete_sign icon_theme_crm c-pointer" *ngIf="backref" (click)="onClose()"></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="single-window__content">
      <div class="single-window__content__wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div class="single-window__history">
    <app-single-window-history></app-single-window-history>
  </div>
</div>
