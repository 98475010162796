import { Component, OnDestroy } from '@angular/core';
import { AnonymousUser, User } from './models/user';
import { AuthenticateService } from './services/authenticate.service';
import { Subscription } from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import { LockerService } from './services/locker.service';
import {HttpClient} from './services/http.service';
import {HistoryService} from './_crm/single-window/shared/history.service';
import {GoBackButtonService} from './services/go-back-button.service';

@Component({
  selector: 'app-root',
  templateUrl: './app-crm.component.html'
})
export class AppCrmComponent implements OnDestroy {

  public user: User = new AnonymousUser();
  public locked: boolean;
  public subscription: Subscription = null;
  public goBackSubscription: Subscription = null;
  public routerSubscription: Subscription = null;
  public newLink = {
    'path': null,
    'url': null,
    'title': null,
    'coords': null,
  };

  constructor(private auth: AuthenticateService, public router: Router, private http: HttpClient,
              private historyService: HistoryService, public goBack: GoBackButtonService) {

    this.subscription = this.auth.emit_user.subscribe((user: User) => this.user = user);

    this.locked = LockerService.locked;
    LockerService.state_emitter.subscribe((locked) => this.locked = locked);
/*
    this.http.post('History.get_history', {'limit': History.max_history_length})
      .subscribe((history_list: Array<{[p: string]: string | number | boolean}>) => {
        for (let i = 0; i < history_list.length; i++) {
          const history = new HistoryElement({'uuid': null, 'url': history_list[i]['history_url']});

          history.favorites = !!history_list[i]['history_favorite'];
          history.caption = history_list[i]['history_caption'].toString();
          history.hash = history_list[i]['history_hash'].toString();
          history.url = history_list[i]['history_url'].toString();

          this.historyService.append_alt(history);
        }
      });
*/

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.indexOf('authenticate') !== -1 || this.router.url.indexOf('crm') !== -1) {
          this.goBack.header = '';
          this.newLink = {
            'path': null,
            'url': null,
            'title': null,
            'coords': null,
          };
        }
      }
    });

    this.goBackSubscription = this.goBack.urlObj
      .subscribe(obj => {
        if (obj) {
          this.newLink = obj;
        } else {
          this.newLink = {
            'path': null,
            'url': null,
            'title': null,
            'coords': null,
          };
        }
      });
  }

  private goBackUrl() {
    this.goBack.onMoved();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

}
