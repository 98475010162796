import { Component, OnInit, OnDestroy } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GoBackButtonService} from '../../services/go-back-button.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';

import {environment} from '../../../environments/environment';
import {HttpClient} from '../../services/http.service';
import {NotificationService} from '../../services/notification.service';


@Component({
  templateUrl: './email-exсeption.component.html',
  styleUrls: ['./email-exсeption.component.scss']
})
export class EmailExceptionComponent implements OnInit, OnDestroy {

  public server = environment.apiServer;
  public formGroup: UntypedFormGroup;
  public newReady = false;
  private subscription: Subscription = new Subscription();
  emails: string[];

  constructor(private goBack: GoBackButtonService,
              private fb: UntypedFormBuilder,
              private activatedRoute: ActivatedRoute,
              private http: HttpClient,
              private notify: NotificationService) { }

  ngOnInit() {
    this.goBack.header = '<h1>Выгрузка активных исключений для Email рассылки</h1>';

    this.formGroup = this.fb.group({
      'list_emails': [(this.activatedRoute.snapshot.data['data'] || []).join(', ')],
      'new_emails': [''],
    });
    this.subscription.add(this.formGroup.get('new_emails').valueChanges.subscribe(value => {
      this.newReady = value !== '';
    }));
    // this.http.post('Subagent.get_email_excepted')
    //   .subscribe(response => this.formGroup.setValue({list_emails: response.join(', ')}));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  exceptedEmails() {
    // if (typeof(this.formGroup.value.emails) === 'string') {
    //   this.formGroup.value.emails = this.formGroup.value.emails.split(',');
    //   console.log(this.formGroup.get('emails'));
    // }
    if (!this.newReady) {
      return;
    }
    this.http.post('Subagent.email_excepted', this.formGroup.value)
      .subscribe(rows => {
        this.formGroup.get('new_emails').patchValue('');
        this.notify.notifySuccess('Готово', 'Количество добавленных исключений: ' + rows, 3000);
      });
  }
}
