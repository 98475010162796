import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {MaterialInputComponent} from './material-input.component';

@Component({
  selector: 'app-material-input-autocomplete',
  templateUrl: './material-input-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialInputAutocompleteComponent),
      multi: true
    }
  ]
})
export class MaterialInputAutocompleteComponent extends MaterialInputComponent {

  @Input() public api_action = '';

}
