<div class="mt-4 mb-2 row">
  <div class="col-12 col-sm-3 col-md-2">
    Всего: <b>{{count}}</b>
  </div>
</div>
<app-modal *ngIf="modal_show" [caption]="'JSON текст'" (onClosed)="modalClose()">
  <pre>{{modal_content|json}}</pre>
</app-modal>
<div class="material-datatable-wrapper">
  <div class="table-responsive">
    <table class="table">
      <tr>
        <th *ngFor="let el of head" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
          <div [formGroup]="form">
            <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
              <div class="col table__input">
                <ng-container *ngIf="el.input">
                  <app-material-input
                    [placeholder] = "el.caption"
                    [formControlName] = "el.name"
                    [cssClass]="'mb-0'">
                  </app-material-input>
                </ng-container>
                <ng-container *ngIf="el.select">
                  <app-material-select
                    [formControlName]="el.name"
                    [cssClass]="'mb-0'">
                    <option *ngFor="let opt of el.select" [value]="opt.id">{{opt.caption}}</option>
                  </app-material-select>
                </ng-container>
                <ng-container *ngIf="!el.input && !el.select">
                  {{el.caption}}
                </ng-container>
              </div>
              <div class="col-auto" *ngIf="el.sort">
                <a href="javascript:void(0)" (click)="sortChange(el.name)">
                  <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                      'icon_new-sort-down': 'asc' == sort[el.name],
                                      'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                </a>
              </div>
            </div>
          </div>
        </th>
      </tr>
      <tr *ngFor="let row of rows; let row_index = index">
        <td>
          <p>{{row['create_time']}}</p>
        </td>
        <td>
          <a href="/reservation/lead-info/{{row.lead_id}}"
             (click)="goBack.newLink('/reservation/lead-info/' + row.lead_id, 'appeal_client_fio-' + row.lead_id, $event)">
            <ng-container *ngIf="row.sale_developer_id">
              {{row.physical_caption}} &mdash; {{row.sale_developer_caption}}
            </ng-container>
            <ng-container *ngIf="!row.sale_developer_id">
              {{row.physical_caption}} &mdash; {{row.lead_realty_caption}}
            </ng-container>
          </a>
        </td>
        <td>
          <p>{{row['method']}}</p>
        </td>
        <td>
          <a class="" href="javascript: void(0)"
             *ngIf="row['params']"
             (click)="viewLogParam(row['params'], $event)">
            Подробнее
          </a>
        </td>
        <td>
          <p>{{row['remote_addr']}}</p>
        </td>
        <td>
          <p>{{row['user_platform']}}</p>
        </td>
        <td>
          <p>{{row['user_browser']}}</p>
        </td>
        <td>
          <a class="" href="javascript: void(0)"
             *ngIf="row['user_detail']"
             (click)="viewLogParam(row['user_detail'], $event)">
            Подробнее
          </a>
        </td>
      </tr>
    </table>
  </div>
</div>
<div class="alt-pagination">
  <app-paginator
    [itemsPerPage]="50"
    [itemCount]="count"
    [currentPage]="current_page"
    [baseUrl]="'/est-card-log'">
  </app-paginator>
</div>
