import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationLoaderService } from './navigation-loader.service';
import { NavigationLoaderComponent } from './navigation-loader.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [NavigationLoaderComponent],
  exports: [NavigationLoaderComponent],
  providers: [NavigationLoaderService]
})
export class NavigationLoaderModule { }
