<div class="stages-block">
  <ng-container
    *ngIf="miniView === true && !only_history"
  >
    <div class="color-highlight mb-2" [ngClass]="{
      'done' : data['status'] !== 'fail',
      'fail' : data['status'] === 'fail'
      }"
    >
      <div class="color-highlight-round"></div><div [innerHtml]="{
      'fail': 'Отказ',
      'finish': 'Архив'
      }[data['status']] || 'В&nbsp;работе'"></div>
    </div>

  </ng-container>
  <div class="stages-block__dots"
       *ngIf="miniView !== true"
  >
    <div class="stages-block__dots__element" *ngFor="let el of dotArray; let i=index" [ngClass]="{'active': i < data.bp_stages_current}"></div>
  </div>
  <div class="text-nowrap">
    <ng-container *ngIf="info">
      <a href="javascript: void(0)" (click)="rightWindow = true">Подробнее</a>
    </ng-container>
    <ng-container
      *ngIf="miniView !== true && !only_history"
    >
      {{data.bp_stages_current}} / {{data.bp_stages_count}}<br>{{data.stage_caption}}<br><span
      class="c-red">{{data.status_description_short}}</span>
    </ng-container>
    <ng-container *ngIf="data.comment_for_subagent">
      <popover-content #popovercomment [title]="'Комментарий для субагента'"
                       [placement]="'left'" [closeOnClickOutside]="true"
                       [closeOnMouseOutside]="false">
        <div [innerHtml]="data.comment_for_subagent"></div>
      </popover-content>
      <div [popover]="popovercomment" class="d-inline-block ml-1">
        <i class="fa fa-2x fa-fw fa-question-circle c-pointer"></i>
      </div>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="info">
<app-right-window
  [data]="rightWindow"
  [cssClass]="cssClass"
  (onClosedRight)="onClosedRight()">
    <div class="row no-gutters gutters-4">
      <div class="col-12">
        <h2 class="mt-2r">История работы</h2>
      </div>
      <div class="col-12 mt-3" *ngIf="manager_fio">
        Ваш менеджер: <b>{{manager_fio}} {{manager_phone}}</b>
      </div>
      <div class="col-12">
        <ng-container *ngIf="info.length">
          <div class="row no-gutters gutters-4">
            <div class="col-sm-12 mt-3 mb-2">
              <table class="table history table-rwd">
                <tr>
                  <th>Дата</th>
                  <th>Событие</th>
                </tr>
                <tr *ngFor="let operation of info; let i = index">
                  <td>
                    {{operation['date_time']}}
                  </td>
                  <td>
                    <span class="history__colorblock" [ngClass]="operation['status']"></span>
                    <span class="history__gray">{{operation['status_change_description']}}</span>
                    <ng-container *ngIf="operation['old_stage_caption'] && operation.show_second_piece">
                      <span> {{operation['old_stage_caption']}}</span>
                      <span class="history__gray"> на </span>
                    </ng-container>
                    <span> {{operation['new_stage_id']}}</span>
                    <ng-container *ngIf="operation['next_contact_date']">
                      <span class="history__gray"> до </span>
                      {{operation['next_contact_date']}} {{operation['next_contact_time']}}
                    </ng-container>
                    <ng-container *ngIf="operation['comment_for_subagent']">
                      <p> <span class="history__gray">Комментарий </span> <span [innerHTML]="operation['comment_for_subagent']"></span></p>
                    </ng-container>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

</app-right-window>
</ng-container>
