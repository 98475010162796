import {ComponentFactory, ComponentRef, Injectable, Type} from '@angular/core';
import {IOverlayHost} from '../interfacers/overlay-host';

@Injectable()
export class OverlayWindowService {

  public overlayHost: IOverlayHost;

  public registerHost(host: IOverlayHost) {
    this.overlayHost = host;
  }

  public openComponentInPopup<T>(componentType: Type<T>, options?: {[key: string]: any}): Promise<ComponentRef<T>> {
    return this.overlayHost.openComponentInPopup(componentType, options);
  }

  public openFactoryInPopup<T>(componentFactory: ComponentFactory<T>, options?: {[key: string]: any}): Promise<ComponentRef<T>> {
    return this.overlayHost.openFactoryInPopup(componentFactory, options);
  }

  public closeHost() {
    this.overlayHost.closeWindow();
  }

  public closeEmitter() {
    return this.overlayHost.closeEmitter;
  }

}
