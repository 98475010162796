<h1>Сертификаты</h1>

<div class="statistic-plagin__wrapper">
  <div class="material-datatable-wrapper">
    <app-datatable-headline
      [global_search_string]="global_search_string"
      [items_per_page_vars]="items_per_page_vars"
      (items_per_page_change)="itemPerPageChange($event)"
      (global_search_change)="onGlobalSearch($event)"
      [templateRight]="templateRight">
    </app-datatable-headline>
    <div class="table-responsive">
      <table class="table datatable" [ngClass]="cssClass">
        <thead app-data-table-head
               [sort]="sort"
               [columns]="columns"
               (sort_emmit)="onSortChange($event)">
        </thead>
        <tbody app-data-table-body
               (rowClick)="rowClick.emit($event)"
               [loading]="true"
               [columns]="columns">
        </tbody>
      </table>
    </div>
    <app-datatable-pagination
      [current_page]="current_page"
      (change_page)="changePage($event)">
    </app-datatable-pagination>
  </div>
</div>

<ng-template #organisation_caption let-row="row">
  <a [routerLink]="['/entity/edit/', row['org_id']]">
    {{row['org_socrachcaption']}}
  </a>
</ng-template>

<ng-template #author_caption let-row="row">
  <a [routerLink]="['/physical/edit/', row['author_id']]">
    {{row['author_fio']}}
  </a>
</ng-template>

<ng-template #physical_caption let-row="row">
  <a [routerLink]="['/physical/edit/', row['physical_id']]">
    {{row['physical_fio']}}
  </a>
</ng-template>

<ng-template #button let-row="row">
  <a href="{{row.url_certificate}}" target="_blank">Сертификат</a>
</ng-template>
