import { Component } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { NotifyQueue } from '../../models/notificaction';
import { FADE_ANIMATION } from '../../animations/fade.animation';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification-alert',
  templateUrl: './notification-alert.component.html',
  animations: [FADE_ANIMATION]
})
export class NotificationAlertComponent {

  private subscription: Subscription;
  public alert: NotifyQueue = new NotifyQueue();

  constructor(private noficationService: NotificationService, private router: Router) {
    this.subscription = this.noficationService.alert_emitter.subscribe(alert => {
      this.alert = alert;
    });
  }

  onLink(link) {
    this.router.navigateByUrl(link);
  }

}
