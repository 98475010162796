import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {Subscription} from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {HttpClient} from '../../../services/http.service';
import {DataTableParamComponent} from '../../../modules/@datatable/components/datatable-param.component';
import {OverlayWindowService} from '../../single-window/shared/overlay-window.service';
import {BackrefWindowService} from '../../single-window/shared/backref-window.service';
import {DatePipe} from '@angular/common';
import { environment } from '../../../../environments/environment';
import {AuthenticateService} from '../../../services/authenticate.service';
import {GoBackButtonService} from '../../../services/go-back-button.service';

@Component({
  selector: 'app-reserv-lead',
  templateUrl: './reserv-lead.component.html',
  styleUrls: ['./reserv-lead.component.scss']
})
export class ReservLeadComponent extends DataTableParamComponent implements OnInit, AfterViewInit, OnDestroy {

  public api_action = 'Bp.dt_leads_anal_sale';
  public columns: Array<{ [key: string]: any }>;
  public formGroup: UntypedFormGroup;
  public subscription: Subscription;
  public subscribtionUser: Subscription;
  public activeDropdown = true;
  public user = null;

  public all_data: any = null;
  public urlLead = environment.urlLead;
  public stages_data = [];
  public stages_size = 0;
  public thisDate = new Date();
  private topScroll = 0;

  private firstLoad = true;
  @ViewChild('fioHead', {static: true}) private fioHead: TemplateRef<any>;
  @ViewChild('fioTmpl', {static: true}) private fioTmpl: TemplateRef<any>;
  @ViewChild('emailHead', {static: true}) private emailHead: TemplateRef<any>;
  @ViewChild('subagent_phoneHead', {static: true}) private subagent_phoneHead: TemplateRef<any>;
  @ViewChild('lead_phoneHead', {static: true}) private lead_phoneHead: TemplateRef<any>;
  @ViewChild('object_captionHead', {static: true}) private object_captionHead: TemplateRef<any>;
  @ViewChild('object_captionTmpl', {static: true}) private object_captionTmpl: TemplateRef<any>;
  @ViewChild('subagent_user_fioHead', {static: true}) private subagent_user_fioHead: TemplateRef<any>;
  @ViewChild('subagent_user_fioTmpl', {static: true}) private subagent_user_fioTmpl: TemplateRef<any>;
  @ViewChild('next_contact_dateHead', {static: true}) private next_contact_dateHead: TemplateRef<any>;
  @ViewChild('next_contact_dateTmpl', {static: true}) private next_contact_dateTmpl: TemplateRef<any>;
  @ViewChild('noticeHead', {static: true}) private noticeHead: TemplateRef<any>;
  @ViewChild('stage_change_timeHead', {static: true}) private stage_change_timeHead: TemplateRef<any>;
  @ViewChild('organisation_captionHead', {static: true}) private organisation_captionHead: TemplateRef<any>;
  @ViewChild('organisation_captionTmpl', {static: true}) private organisation_captionTmpl: TemplateRef<any>;
  @ViewChild('stage_captionTmpl', {static: true}) private stage_captionTmpl: TemplateRef<any>;

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router,
              public fb: UntypedFormBuilder, private overlayWindowService: OverlayWindowService,
              private backrefWindowService: BackrefWindowService, private pipe: DatePipe,
              public auth: AuthenticateService, public goBack: GoBackButtonService) {
    super(http, route, router);

    const nowDate = new Date();

    this.formGroup = this.fb.group({
      'fio': [''],
      'email': [''],
      'lead_phone': [''],
      'subagent_phone': [''],
      'create_time': [''],
      'next_contact_date': [''],
      'next_contact_time': [''],
      'date_end': [this.pipe.transform(nowDate, 'dd.MM.yyyy')],
      'date_start': [this.pipe.transform(nowDate.setMonth(nowDate.getMonth() - 1), 'dd.MM.yyyy')],
      'date_change_start': [''],
      'date_change_end': [''],
      'last_days': [''],
      'tab': [''],
      'locked_func': [''],
      'object_caption': [''],
      'subagent_user_fio': [''],
      'stage_caption': [''],
      'status_description': [''],
      'organisation_caption': [''],
    });

    this.subscription = this.formGroup.valueChanges.pipe(debounceTime(500))
      .subscribe((data) => {
        this.router_param['page'] = 1;
        this.current_page = 1;
        if (this.firstLoad) {
          this.firstLoad = false;
        } else {
          this.changeForm(this.formGroup);
          setTimeout(() => {
            window.scroll(0, this.topScroll);
          }, 0);
        }
        this.updateAnalitics();
      });

    const exclude = [
      'date_end',
      'date_start',
      'date_change_start',
      'date_change_end',
    ];

    this.updateForm(this.formGroup, exclude);
    this.backrefWindowService.routerInit();
  }

  ngOnInit() {
    this.goBack.header = '<h1>Бронирование</h1>';
    this.subscribtionUser = this.auth.emit_user.subscribe((user) => this.user = user);
    this.columns = [
      {'caption': 'Следующий контакт', 'name': 'next_contact_date', 'sortable': true, width: '20rem',
        'template': this.next_contact_dateTmpl, 'templateHead': this.next_contact_dateHead},
      {'caption': 'Организация', 'name': 'organisation_caption', 'sortable': true,
        'templateHead': this.organisation_captionHead, 'template': this.organisation_captionTmpl},
      {'caption': 'Субагент', 'name': 'subagent_user_fio', 'sortable': true,
        'template': this.subagent_user_fioTmpl, 'templateHead': this.subagent_user_fioHead},
      {'caption': 'Телефон субагента', 'name': 'subagent_phone', 'sortable': true, 'templateHead': this.subagent_phoneHead},
      {'caption': 'Клиент', 'name': 'fio', 'sortable': true, 'width': '210px',
        'template': this.fioTmpl, 'templateHead': this.fioHead},
      {'caption': 'Телефон клиента', 'name': 'lead_phone', 'sortable': true, 'templateHead': this.lead_phoneHead},
      {'caption': 'Объекты', 'name': 'object_caption', 'sortable': true,
        'template': this.object_captionTmpl, 'templateHead': this.object_captionHead},
      {'caption': 'Этап', 'name': 'stage_caption', 'sortable': false, 'template': this.stage_captionTmpl},
    ];
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscribtionUser) {
      this.subscribtionUser.unsubscribe();
    }
  }
  ngAfterViewInit() {
    this.goBack.goScroll();
  }

  prepareQuery() {
    const result = super.prepareQuery();
    result['filter']['fields'] = {};
    result['filter']['fields'] = {
      'fio': this.formGroup.value.fio,
      'email': this.formGroup.value.email,
      'lead_phone': this.formGroup.value.lead_phone,
      'subagent_phone': this.formGroup.value.subagent_phone,
      'create_time': this.formGroup.value.create_time,
      'subagent_user_fio': this.formGroup.value.subagent_user_fio,
      'date_start': this.formGroup.value.date_start,
      'date_end': this.formGroup.value.date_end,
      'date_change_start': this.formGroup.value.date_change_start,
      'date_change_end': this.formGroup.value.date_change_end,
      'tab': this.formGroup.value.tab,
      'locked_func': this.formGroup.value.locked_func,
      'organisation_caption': this.formGroup.value.organisation_caption,
      'next_contact_date': this.formGroup.value.next_contact_date,
      'notice': this.formGroup.value.notice,
      'stage_caption': this.formGroup.value.stage_caption,
    };

    return result;
  }

  updateAnalitics() {
    const params = {
      'date_start': this.formGroup.value.date_start,
      'date_finish': this.formGroup.value.date_end,
      'date_change_start': this.formGroup.value.date_change_start,
      'date_change_end': this.formGroup.value.date_change_end,
    };
    this.http.post('Bp.stat_leads_sale', params).subscribe(
      (response) => {
        this.all_data = response;
        this.stages_data = response.locked_funcs;
        this.stages_data = this.stages_data.map(element => {
          const all = element.count_in_work + element.count_success + element.count_fail;
          return {
            'caption': element.caption,
            'description': element.description,
            'count_all': all,
            'locked_func': element.locked_func,
            'count_in_work': element.count_in_work,
            'count_success': element.count_success,
            'count_fail': element.count_fail,
          };
        });

        this.stages_size = Math.max.apply(Math, this.stages_data.map(function(o) { return o.count_all; }));
      }
    );
  }

  stageFilter(string, tab) {
    if (this.formGroup.value.locked_func === string && this.formGroup.value.tab === tab) {
      this.formGroup.patchValue({
        'locked_func': '',
        'tab': '',
      });
    } else {
      this.formGroup.patchValue({
        'locked_func': string,
        'tab': tab,
      });
    }
    this.topScroll = window.pageYOffset;
  }
  datediff(newdate) {
    const oneDay = 1000 * 60 * 60 * 24;
    const [day, month, year] = newdate.split('.');
    const dayTill = Math.round((new Date(year, month - 1, day).getTime() - this.thisDate.getTime()) / oneDay) + 1;
    if (dayTill === 0) {
      return 'Контакт сегодня';
    } else if (dayTill > 0) {
      return 'Дней до контакта (' + dayTill + ')';
    } else if (dayTill < 0) {
      return 'Контакт просрочен';
    }
  }
}
