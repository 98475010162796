<div class="form__group" [class.mb-0]="withMb0">
  <div class="form__label" [class.form__label_require]="require && !disabled" [class.form__label_error]="error">
    <ng-content></ng-content>
  </div>
  <div class="form__input" [class.form__input_disabled]="disabled" [class.form__input_focus]="focus"
       [class.form__input_error]="error">
    <input [type]="type" class="input" [value]="current_value" [disabled]="disabled"
           (focus)="focus = true"
           (blur)="focus = false"
           (keyup)="onChanges($event.target)"
           [ngClass]="{'md-valid': current_value && (current_value.length || current_value > 0)}"
           [placeholder]="placeholder">
    <div class="form__input_icon icon icon_Small_Search c-pointer" *ngIf="!disabled" (click)="clickOnSubmit()"></div>
  </div>
</div>
