<div class="overflow-a">
  <ul class="breadcrumbs__list" [hidden]="!(chain && chain.length)">
    <li *ngFor="let el of chain; index as i" class="breadcrumbs__element">
      <ng-container *ngIf="el['url']">
        <a [routerLink]="el['url']">
          <ng-container *ngIf="i">
            <i class="fa fa-chevron-right"></i>
          </ng-container>
          <span [ngClass]="el['opts']['class']">{{el['caption']}}</span>
        </a>
      </ng-container>
      <ng-container *ngIf="!el['url']">
        <ng-container *ngIf="i">
          <i class="fa fa-chevron-right"></i>
        </ng-container>
        <span [ngClass]="el['opts']['class']">{{el['caption']}}</span>
      </ng-container>
    </li>
  </ul>
</div>
