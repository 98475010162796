<h1 class="mb-3">Редактирование графика работы в праздничные дни</h1>
<form class="form form_crm" [formGroup]="form" *ngIf="form" novalidate (submit)="onSubmit()">
  <div class="row no-gutters gutters-4">
    <div class="col-12">
      <div class="row">
        <div class="col-md-2">
          <app-material-input-datepicker formControlName="time_start"
                                         [placeholder]="'Дата начала'"
                                         [require]="true">
            Дата начала отображения</app-material-input-datepicker>
        </div>
        <div class="col-md-2">
          <app-material-input-datepicker formControlName="time_stop"
                                         [placeholder]="'Дата окончания'"
                                         [require]="true">
            Дата окончания отображения</app-material-input-datepicker>
        </div>
        <div class="col-md-2">
          <app-material-input-mask formControlName="dates_string"
                                   [placeholder]="'Период отображения'"
                                   [require]="true"
                                   [mask]="'00.00.00 - 00.00.00'">
            Даты в хедере</app-material-input-mask>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-md-2 mt-4">
          <app-material-input-dropimage
            formControlName="image"
            [cssClass]="'m-h-none embed-responsive embed-responsive-1by1 visible'"
            [cssClassZone]="'zone-photo'"
            [uploadOptions]="{'exclude_compressing': true}"
          >
            Перетащите сюда фотографию или иллюстрацию
          </app-material-input-dropimage>
        </div>
        <div class="col-md-2 mt-4">
          <app-material-input-dropimage
            formControlName="image_icon"
            [cssClass]="'m-h-none embed-responsive embed-responsive-1by1 visible'"
            [cssClassZone]="'zone-photo'"
          >
            Перетащите сюда иконку. Размер иконки должен быть 32 x 32. Формат png/jpg/svg.
          </app-material-input-dropimage>
        </div>
        <div class="col-md-2 mt-4" *ngIf="form.value.image_icon">
          <app-preview-holiday
            [dates_string] = new_dates(form.value.dates_string)
            [image_icon] = form.value.image_icon
            [margin_left] = form.value.margin_left
            [margin_top] = form.value.margin_top
            (marginLeft) = replaceMarginLeft($event)
            (marginTop) = replaceMarginTop($event)
          ></app-preview-holiday>
        </div>
      </div>
    </div>
    <div class="col-md-2 mt-4">
      <app-material-input-checkbox formControlName="is_archive" [cssClass]="'mb-0'" [border]="true">
        В архиве
      </app-material-input-checkbox>
    </div>
  </div>
  <div class="row no-gutters gutters-2 mt-3 mb-5 mb-md-0">
    <div class="col-md-10">
      <button type="submit" class="button button_sm" [disabled]="!form.valid">Сохранить и закрыть</button>
    </div>
  </div>
</form>
