import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DictionariesService } from '../../services/dictionaries.service';

@Injectable()
export class DictionaryOrganisationFormsResolver {

  constructor(private dict_helper: DictionariesService) { }

  resolve(router: ActivatedRouteSnapshot) {
    return this.dict_helper.get('Organisation.get_forms');
  }

}
