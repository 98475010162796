
<ul class="navigation__tabs"
    *ngIf="auth.isMenu('projects_flag')"
>
  <li class="navigation__tab">
    <a class="navigation__tab__link" routerLink="index" routerLinkActive="navigation__tab__link_active">
      Главная <span class="navigation__tab__link_active__slide"></span>
    </a>
  </li>
  <li class="navigation__tab">
    <a class="navigation__tab__link" routerLink="layouts" routerLinkActive="navigation__tab__link_active">
      Планировки <span class="navigation__tab__link_active__slide"></span>
    </a>
  </li>
  <li class="navigation__tab">
    <a class="navigation__tab__link" routerLink="conditions" routerLinkActive="navigation__tab__link_active">
      Условия реализации <span class="navigation__tab__link_active__slide"></span>
    </a>
  </li>
  <li class="navigation__tab">
    <a class="navigation__tab__link" routerLink="import" routerLinkActive="navigation__tab__link_active">
      Импорт без связей <span class="navigation__tab__link_active__slide"></span>
    </a>
  </li>
  <li class="navigation__tab">
    <a class="navigation__tab__link" routerLink="xml-del" routerLinkActive="navigation__tab__link_active">
      Удалены из XML <span class="navigation__tab__link_active__slide"></span>
    </a>
  </li>
  <li class="navigation__tab">
    <a class="navigation__tab__link" routerLink="xml-on" routerLinkActive="navigation__tab__link_active">
      Возвращены в XML <span class="navigation__tab__link_active__slide"></span>
    </a>
  </li>
  <li class="navigation__tab">
    <a class="navigation__tab__link" routerLink="not-related" routerLinkActive="navigation__tab__link_active">
      Квартиры не в импорте <span class="navigation__tab__link_active__slide"></span>
    </a>
  </li>
  <li class="navigation__tab">
    <a class="navigation__tab__link" routerLink="not-imported" routerLinkActive="navigation__tab__link_active">
      Не связанные объекты в импорте <span class="navigation__tab__link_active__slide"></span>
    </a>
  </li>
</ul>
<router-outlet></router-outlet>
