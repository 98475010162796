import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute, Event, NavigationEnd, Router} from '@angular/router';
import {Observable, Subject, Subscription} from 'rxjs';
import {LocalStorageService} from './storages/local_storage.service';

@Injectable()
export class GoBackButtonService implements OnDestroy {

  private historyArray = [];

  constructor(private route: ActivatedRoute, private router: Router, private storage: LocalStorageService) {
    this.routeEvent();

    const store = this.storage.loadData('goback');
    if (store) {
      this.historyArray = store;
    }
  }

  private _urlObj: Subject<any> = new Subject<any>();

  public readonly urlObj: Observable<any> = this._urlObj.asObservable();


  public header = '';

  private activePath: string;
  public activeHash: string;
  public activeX: number;
  public activeY: number;

  private subscription: Subscription;
  private goingBackward: boolean;

  private urlArrayStart = [
    '/authenticate',
    '/crm',
    '/physical/list',
    '/entity/list',
    '/housing',
    '/housing',
    '/housing2',
    '/housing2/list',
    '/housing-order',
    '/control',
    '/import',
    '/import_xlsx',
    '/tariff-map',
    '/attraction/lead-info',
    '/attraction/status-created',
    '/attraction/status-passed',
    '/attraction/subagents',
    '/reservation/unique',
    '/reservation/views',
    '/reservation/reserve',
    '/calendar/list',
    '/calendar/invites',
    '/calendar/teaching',
    '/calendar/statistic',
    '/calendar/map',
    '/calendar/certificate',
    '/calendar/user/list',
    '/statistic/plugin',
    '/statistic/plugin',
    '/online',
    '/online/compare',
    '/reservation/lead',
    '/subagents-actions',
    '/analytic/index',
    '/reference',
    '/reference',
    '/managers',
    '/news/list',
    '/settings',
    '/settings/constraints-consolidate',
    '/settings/constraints',
    '/processes/reserv',
    '/processes/attraction',
    '/holiday/list',
    '/reservation/lead-info',
    '/reservation/lead-info/today',
    '/control/index',
  ];

  private urlArrayBlock = [
    '/control/conditions', '/control/import', '/control/xml-del', '/control/xml-on', '/control/not-related', '/control/not-imported',
    '/housing/compare', '/housing/structural/complexes', '/housing/structural/korpuses', '/housing/structural/flats', '/calendar/create',
    '/processes/reserv/create', '/attraction/lead-info/create', '/reservation/lead-info/create'
  ];

  private urlArrayBlockRegular = [
    '\/physical\/edit\/.', '\/entity\/edit\/.', '\/housing\/house\/[0-9]+\/edit\/.', '\/housing\/house\/[0-9]+\/catalog\/.',
    '\/housing\/house\/[0-9]+\/catalog\/.', '\/housing\/house\/[0-9]+\/details.', '\/housing\/house\/[0-9]+\/details.',
    '\/physical\/edit\/.', '\/housing2\/house\/[0-9]+\/catalog\/.',
    '\/housing2\/house\/.\/edit\/presets\/.', '\/housing\/[0-9]+\/edit\/.', '\/import\/edit\/.',
    '\/housing2\/add',
    '\/housing2\/complex\/[0-9]+\/add',
    '\/analytic\/report-common.',
    '\/analytic\/report\/.',
    '\/analytic\/report\/.',
    '\/analytic\/report-get-sub-activity\/.',
    '\/analytic\/report-get-sub-user-activity\/.',
    '\/analytic\/report-get-complex-activity\/.',
    '\/analytic\/report-get-activity-complex-id\/.',
  ];



  public routeEvent() {
    this.subscription = this.router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationEnd && !this.goingBackward  && event.url.indexOf('authenticate') === -1) {
        this.activePath = event.url.split(/\?|\#/)[0];
        const lastElement = this.historyArray.length > 0 ? this.historyArray[this.historyArray.length - 1].path : '';

        if (this.activePath === lastElement) {
          this.historyArray.splice(-1, 1);
        } else {
          this.header = '';
        }

        const arraySize = 50;
        if (this.urlArrayStart.indexOf(this.activePath) !== -1) {
          this.historyArray = [];
        }
        if (this.historyArray.length > 1) {
          if (this.urlArrayBlock.indexOf(this.activePath) !== -1) {
            this.historyArray.splice(-1, 1);
          }
          for (let i = 0; i < this.urlArrayBlockRegular.length; i++) {
            if (this.activePath.match(this.urlArrayBlockRegular[i]) && this.historyArray[this.historyArray.length - 1].path.match(this.urlArrayBlockRegular[i])) {
              this.historyArray.splice(-1, 1);
              break;
            }
          }
        }

        if (this.historyArray.length > arraySize) {
          this.historyArray.shift();
        }

        this.historyArray.push({
          'path': this.activePath ,
          'url': event.url.split('#')[0],
          'title': '',
          'coords': null,
        });
        this.objReturn();

      } else if (event instanceof NavigationEnd && this.goingBackward  && event.url.indexOf('authenticate') === -1) {
        this.header = '';
        this.objReturn();
      }
      if (event instanceof NavigationEnd) {
        const split = event.url.split('#');
        this.activeHash = split[1];
        if (split[3]) {
          this.activeX = parseInt(split[2], 10);
          this.activeY = parseInt(split[3], 10);
        } else {
          this.activeX = 0;
          this.activeY = 0;
        }
      }
    });
  }


  public goScroll() {
    if (this.activeY || this.activeX) {
      setTimeout(_ => {
        try {
          window.scrollTo({
            top: this.activeY ? this.activeY : 0,
            left: this.activeX ? this.activeX : 0,
            behavior: 'smooth'
          });
        } catch (err) {
          window.scroll(this.activeY ? this.activeY : 0, this.activeX ? this.activeX : 0);
        }
      }, 1500);
    }
  }

  public onMoved() {
    this.goingBackward = true;
    this.historyArray.splice(-1, 1);
    this.router.navigateByUrl(this.historyArray[this.historyArray.length - 1].url);
  }

  public newLink(url, hash, event = null) {
    if (event) {
      event.preventDefault();
    }
    const coords = {
      'X': window.pageXOffset,
      'Y': window.pageYOffset,
    };
    this.historyArray[this.historyArray.length - 1].url = this.historyArray[this.historyArray.length - 1].url.split('#')[0] + '#' + hash + '#' + coords.X + '#' + coords.Y;
    this.historyArray[this.historyArray.length - 1].coords = coords;

    this.router.navigateByUrl(url);
  }

  private objReturn() {
    let obj = {};

    if (this.historyArray.length > 1) {
      obj = this.historyArray[this.historyArray.length - 2];
    } else {
      obj = null;
    }
    this._urlObj.next(obj);
    this.goingBackward = false;
    this.storage.saveData('goback', this.historyArray);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
