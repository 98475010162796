
<div class="calendar-map" id="map-auto-height">
  <div class="row no-gutters">
    <div class="col-7 o-hidden">
      <ng-container *ngIf="minHeight !== null">
        <div class="calendar-map__loader" *ngIf="data == null">
          <i class="fa fa-spin fa-spinner"></i> загрузка данных
        </div>
        <ng-container *ngIf="data && data['complexes']">
          <osm-map
            [center]="[55.76, 37.64]"
            [zoom]="11"
            [style]="{'height': minHeight + 'px'}">
              <osm-placemark
                *ngFor="let c of data['complexes']"
                [coordinates]="[c['housing_lat'], c['housing_lon']]"
                [balloonContent]="content"
                [options]="{'caption': c['housing_caption']}">
                  <td class="" #content>
                    <div *ngIf="c.housing_caption" class="">
                      <span class="font-weight-bold">{{c.housing_caption}}</span>
                    </div>
                    <div *ngIf="c.developer" class="">
                      <span class="text-muted">Застройщик:</span> {{c.developer}}
                    </div>
                    <div *ngIf="c.housing_address" class="">
                      <span class="text-muted">Адрес:</span> {{c.housing_address}}
                    </div>
                    <div *ngIf="c.subway">
                      <span class="text-muted">Метро:</span> {{c.subway}}
                    </div>
                    <div *ngIf="c.housing_class_caption" class="">
                      <span class="text-muted">Класс:</span> {{c.housing_class_caption}}
                    </div>
                    <div *ngIf="c.housing_date" class="">
                      <span class="text-muted">Дата сдачи:</span>{{c.housing_date}}
                    </div>

                    <div class="mt-2">
                      <table style="width: 100%">
                        <ng-container *ngIf="c['1_room_area']">
                          <tr>
                            <td>1-комнатные</td>
                            <td>от {{c['1_room_area']}} м<sup><small>2</small></sup></td>
                            <td>
                              <span [outerHTML]="c['1_room_price'] | priceRank"></span>
                            </td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="c['2_room_area']">
                          <tr>
                            <td>2-комнатные</td>
                            <td>от {{c['2_room_area']}} м<sup><small>2</small></sup></td>
                            <td>
                              <span [outerHTML]="c['2_room_price'] | priceRank"></span>
                            </td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="c['3_room_area']">
                          <tr>
                            <td>3-комнатные</td>
                            <td>от {{c['3_room_area']}} м<sup><small>2</small></sup></td>
                            <td>
                              <span [outerHTML]="c['3_room_price'] | priceRank"></span>
                            </td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="c['4_room_area']">
                          <tr>
                            <td>4-комнатные</td>
                            <td>от {{c['4_room_area']}} м<sup><small>2</small></sup></td>
                            <td>
                              <span [outerHTML]="c['4_room_price'] | priceRank"></span>
                            </td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="c['studios_area']">
                          <tr>
                            <td>Студии</td>
                            <td>от {{c['studios_area']}} м<sup><small>2</small></sup></td>
                            <td class="col-auto text-right">
                              <span [outerHTML]="c['studios_price'] | priceRank"></span>
                            </td>
                          </tr>
                        </ng-container>
                      </table>
                    </div>
              </osm-placemark>
              <!-- // -->
              <osm-placemark
                *ngFor="let c of data['subagents']"
                [coordinates]="[c['lat'], c['lon']]"
                [icon]="'blue'"
                [balloonContent]="content"
                [options]="{'caption': c['organisation_caption']}">
                  <div class="" #content>
                    <div class="mb-2 font-weight-bold">{{c['organisation_caption']}}</div>
                    <div *ngIf="c.organisation_fact_address">
                      <span class="text-muted">Адрес:</span> {{c.organisation_fact_address}}
                    </div>
                    <div *ngIf="c.organisation_phone">
                      <span class="text-muted">Телефон:</span> {{c.organisation_phone}}
                    </div>
                    <div *ngIf="c.organisation_email">
                      <span class="text-muted">Email:</span> {{c.organisation_email}}
                    </div>
                  </div>
              </osm-placemark>
          </osm-map>
        </ng-container>
        </ng-container>
    </div>
    <div class="col-5 calendar-map__table" [ngStyle]="{'height': minHeight + 'px'}">
      <app-calendar-map-tables [realty_list]="realty_list"></app-calendar-map-tables>
    </div>
  </div>

</div>
