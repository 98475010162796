import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {JsonClientService} from '../../../../shared/json-client';

declare const moment;

@Injectable()
export class GetActivityTableResolve {

  public readonly filter_columns = [
    'stage_change_time',
    'organisation_caption',
    'subagent_user_fio',
    'subagent_phone',
    'fio',
    'lead_phone',
    'physical_manager_fio',
    'stage_caption',
    'bp_source',
  ];
  constructor(private j: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {

    const prepared_filter = {};
    const prepared_filter_keys = this.filter_columns.filter(x => !!route.parent.queryParams[x]);
    const sort = {};

    prepared_filter_keys.forEach(x => prepared_filter[x] = route.parent.queryParams[x]);

    if (!!route.queryParams['sort_name']) {
      sort[route.queryParams['sort_name']] = route.queryParams['sort_key'] || 'asc';
    }

    const options = {
      'limit': 20,
      'offset': (+(route.queryParams['page'] || 1) - 1) * 20,
      'filter': {
        'global_search_string': route.queryParams['global_search_string'] || null,
        'fields': prepared_filter,
      },
      'sort': sort,
      'date_start': route.queryParams['date_start'] || moment()
        .startOf('year').format('DD.MM.YYYY'),
      'date_end': route.queryParams['date_end'] || moment()
        .endOf('month').format('DD.MM.YYYY'),
    };

    if (route.queryParams['method'] === 'get_summary_statistic') {
      options['month'] = route.queryParams['month'];
      options['locked'] = route.queryParams['locked'];
    } else if (route.queryParams['method'] === 'get_sub_activity') {
      options['fact_type'] = route.queryParams['fact_type'];
      options['result_type'] = route.queryParams['result_type'];
      options['gap'] = route.queryParams['gap'];
      options['sub_date_from'] = route.queryParams['sub_date_from'];
      options['only_subagent_id'] = route.queryParams['only_subagent_id'] ? route.queryParams['only_subagent_id'] : '';
      if (route.queryParams['employee_id']) {
        options['employee_id'] = route.queryParams['employee_id'];
      }
    } else if (route.queryParams['method'] === 'get_complex_activity') {
      options['fact_type'] = route.queryParams['fact_type'];
      options['result_type'] = route.queryParams['result_type'];
      options['gap'] = route.queryParams['gap'];
      options['complex_id'] = route.queryParams['complex_id'];
    } else if (route.queryParams['method'] === 'get_reserved_summary') {
      options['month'] = route.queryParams['month'];
      options['stage'] = route.queryParams['stage'];
    } else if (route.queryParams['method'] === 'get_unique_leads_on_period') {
      options['month'] = route.queryParams['month'];
      options['stage'] = route.queryParams['stage'];
      options['flag'] = route.queryParams['flag'];
    } else if (route.queryParams['method'] === 'get_stage_statistic_lead_on_moment') {
      delete options['date_start'];
      delete options['date_end'];
      options['stage'] = route.queryParams['stage'];
    }

    if (!route.queryParams['method']) {
      // исключение для закончившейся сессии
      return;
    } else {
      return this.j.post('Data_tables.' + route.queryParams['method'], options);
    }
  }

}
