import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient} from '../../../services/http.service';

@Injectable()
export class HousingCatalogResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.http.post('Catalog.get_tab_dict', {
      'housing_id': +route.parent.params['id'],
      'tab': route.routeConfig.path,
    });
  }
}
