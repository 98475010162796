<div id="data-table-selection-footer" *ngIf="pages_count>1 && rage" class="bootgrid-footer container-fluid">
  <div class="row">
    <div class="col-md-12">
      <ul class="pagination">
        <li class="paginate_button page-item first" [ngClass]="{disabled: 1==current_page}">
          <a class="page-link button" (click)="firstPage()">
            <i class="fa fa-angle-double-left"></i></a>
        </li>
        <li class="paginate_button page-item prev" [ngClass]="{disabled: 1==current_page}">
          <a data-page="prev" class="page-link button" (click)="previousPage()">
            <i class="fa fa-angle-left"></i></a>
        </li>
        <li *ngFor="let page of rage; let i = index" class="paginate_button page-item "
            [ngClass]="{active: page==current_page}">
          <a *ngIf="page !== 0" class="page-link button" href="javascript:void(0)" (click)="changePage(page)">
            {{page}} </a>
          <a *ngIf="page === 0" class="page-link button" href="javascript:void(0)" (click)="changePage(rage[i-1] + 1)"><i class="fa fa-ellipsis-h"></i></a>
        </li>
        <li class="paginate_button page-item  next" [ngClass]="{disabled: pages_count<=current_page}">
          <a data-page="next" class="page-link button" (click)="nextPage()">
            <i class="fa fa-angle-right"></i></a>
        </li>
        <li class="paginate_button page-item last" [ngClass]="{disabled: pages_count<=current_page}">
          <a data-page="last" class="page-link button" (click)="lastPage()">
            <i class="fa fa-angle-double-right"></i></a>
        </li>
      </ul>
    </div>
  </div>
</div>
