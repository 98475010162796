import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {JsonClientService} from '../../../shared/json-client';
import {NotificationService} from '../../../services/notification.service';
import {BreadcrumbsService} from '../../../shared/breadcrumbs/services';
import {AddressComponentComponent} from '../../../shared/address-component/address-component.component';

@Component({
  templateUrl: './entity-edit-address.component.html',
  styleUrls: ['./entity-edit-address.component.scss']
})
export class EntityEditAddressComponent implements OnInit {

  public form: UntypedFormGroup;
  public data: any = null;

  @ViewChild(AddressComponentComponent, {static: true}) private address: AddressComponentComponent;

  constructor(private fb: UntypedFormBuilder, private activatedRoute: ActivatedRoute,
              private http: JsonClientService, private notify: NotificationService, private b: BreadcrumbsService) {
    this.data = this.activatedRoute.snapshot.data['data'];
    this.form = this.fb.group({
      'address': [this.data.address, Validators.required],
      'city_admin_area_id': [this.data.city_admin_area.id ? this.data.city_admin_area.id.toString() : ''],
    });
  }

  ngOnInit() {
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/entity/list', caption: 'Юридические лица'},
    ]);
    this.address.resetMap();
  }
  saveForm() {

    let objSubways = [];
    let objHighways = [];

    if (this.form.value['subways']) {
      objSubways = this.form.value['subways'].filter((value) => {
        return value.id !== '';
      });
    }
    if (this.form.value['highways']) {
      objHighways = this.form.value['highways'].filter((value) => {
        return value.id !== '';
      });
    }

    const obj = {
      'id': parseInt(this.activatedRoute.snapshot.parent.params['id'], 10),
      'obj_type': 'organisation',
      'data': this.form.value['address'],
      'city_admin_area_id': this.form.value['city_admin_area_id'],
      'subways': objSubways,
      'highways': objHighways,
    }
    this.http.post('Address.save_address', obj).then(
      (response) => {
        this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
      },
      (error) => { }
    );
  }

}
