<div
  class="event-info__layout"
  [ngClass]="cssClass"
  [class.event-info__layout_open]="layout_opened">

  <a
    class="event-info__close"
    href="javascript:void(0)"
    (click)="closeEvent()">
    ×
  </a>
  <div class="event-info__content">
    <div class="event-info__content__wrapper">
      <ng-content></ng-content>
    </div>
  </div>
</div>
