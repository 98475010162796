import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ModalModule } from '../../modules/@modal/modal.module';
import { NotificationService } from '../../services/notification.service';

import { BookingListComponent } from './booking.component';
import { QueueListComponent } from './queue.component';
import { ReserveListComponent } from './reserve.component';
import { ViewsListComponent } from './views.component';

import { DataTableModule } from '../../modules/@datatable/datatables.module';
import { ReserveChangeDate } from './reserve-info.component';
import { MaterialInputModule } from '../../modules/@material-input/material-input.module';
import {ViewsElementComponent} from './views-element/views-element.component';
import {TabsModule} from '../../modules/@tabs/tabs.module';
import {SharedModule} from '../../shared/shared.module';
import {ReservLeadModule} from './reserv-lead/reserv-lead.module';
import {ViewListResolve} from './main-resolvers/views.resolve';
import {ReserveListResolve} from './main-resolvers/reserve.resolve';
import {BookingResolve} from './main-resolvers/booking.resolve';
import {BookingInfoComponent} from './booking-info/booking-info.component';
import {BookingInfoResolve} from './booking-info/booking-info.resolve';
import {BookingInfoContentComponent} from './booking-info/booking-info-content/booking-info-content.component';
import {BookingInfoContentDataResolve, BookingInfoContentResolve} from './booking-info/booking-info-content/booking-info-content.resolve';
import {PaginatorModule} from '../../shared/housing-modules/modules/paginator/paginator.module';
import {UniqueListComponent} from './unique.component';
import {UniqueListResolve} from './main-resolvers/unique.resolve';
import {StagesDotsModule} from '../../modules/@stages-dots/stages-dots.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DataTableModule,
    ModalModule,
    MaterialInputModule,
    TabsModule,
    SharedModule,
    ReservLeadModule,
    PaginatorModule,
    StagesDotsModule,
  ],
  providers: [
    NotificationService,
    ViewListResolve,
    ReserveListResolve,
    BookingResolve,
    BookingInfoResolve,
    BookingInfoContentResolve,
    BookingInfoContentDataResolve,
    UniqueListResolve,
  ],
  declarations: [
    ReserveListComponent,
    ReserveChangeDate,
    BookingListComponent,
    QueueListComponent,
    ViewsListComponent,
    ViewsElementComponent,
    BookingInfoComponent,
    BookingInfoContentComponent,
    UniqueListComponent,
  ],
  exports: [
    ReserveListComponent,
    BookingListComponent,
    QueueListComponent,
    ViewsListComponent,
    ViewsElementComponent,
  ],
})
export class ReserveModule { }
