import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import {debounceTime, filter} from 'rxjs/operators';
import {HttpClient} from '../../../../../services/http.service';
import {DataTableParamComponent} from '../../../../../modules/@datatable/components/datatable-param.component';
import {OverlayWindowService} from '../../../../single-window/shared/overlay-window.service';
import {BackrefWindowService} from '../../../../single-window/shared/backref-window.service';
import {GoBackButtonService} from '../../../../../services/go-back-button.service';
import {environment} from '../../../../../../environments/environment';
import {AuthenticateService} from '../../../../../services/authenticate.service';


@Component({
  selector: 'app-reserv-lead-info-all',
  templateUrl: './reserv-lead-info-all.component.html',
  styleUrls: ['./reserv-lead-info-all.component.scss']
})
export class ReservLeadInfoAllComponent extends DataTableParamComponent implements OnInit, AfterViewInit, OnDestroy {

  public api_action = 'Bp.dt_leads_sale';
  public columns: Array<{ [key: string]: any }>;
  public formGroup: UntypedFormGroup;
  public subscription: Subscription;
  public routerSubscription: Subscription;
  public data: any;
  public thisDate = new Date();

  private firstLoad = true;
  public buttonLink = '/all';
  public urlLead = environment.apiServer + 'restful/all_leads/';
  public subscribtionUser: Subscription;
  public user = null;
  @ViewChild('fioHead', {static: true}) private fioHead: TemplateRef<any>;
  @ViewChild('fioTmpl', {static: true}) private fioTmpl: TemplateRef<any>;
  @ViewChild('emailHead', {static: true}) private emailHead: TemplateRef<any>;
  @ViewChild('create_timeTmpl', {static: true}) private create_timeTmpl: TemplateRef<any>;
  @ViewChild('object_captionHead', {static: true}) private object_captionHead: TemplateRef<any>;
  @ViewChild('object_captionTmpl', {static: true}) private object_captionTmpl: TemplateRef<any>;
  @ViewChild('subagent_user_fioHead', {static: true}) private subagent_user_fioHead: TemplateRef<any>;
  @ViewChild('subagent_user_fioTmpl', {static: true}) private subagent_user_fioTmpl: TemplateRef<any>;
  @ViewChild('subagent_phoneHead', {static: true}) private subagent_phoneHead: TemplateRef<any>;
  @ViewChild('lead_phoneHead', {static: true}) private lead_phoneHead: TemplateRef<any>;
  @ViewChild('next_contact_dateHead', {static: true}) private next_contact_dateHead: TemplateRef<any>;
  @ViewChild('next_contact_dateTmpl', {static: true}) private next_contact_dateTmpl: TemplateRef<any>;
  @ViewChild('noticeHead', {static: true}) private noticeHead: TemplateRef<any>;
  @ViewChild('stage_change_timeHead', {static: true}) private stage_change_timeHead: TemplateRef<any>;
  @ViewChild('stage_captionTmpl', {static: true}) private stage_captionTmpl: TemplateRef<any>;
  @ViewChild('stage_captionHead', {static: true}) private stage_captionHead: TemplateRef<any>;
  @ViewChild('organisation_captionHead', {static: true}) private organisation_captionHead: TemplateRef<any>;
  @ViewChild('organisation_captionTmpl', {static: true}) private organisation_captionTmpl: TemplateRef<any>;
  @ViewChild('physical_manager_fioHead', {static: true}) public physical_manager_fioHead: TemplateRef<any>;
  @ViewChild('physical_manager_fioTmpl', {static: true}) public physical_manager_fioTmpl: TemplateRef<any>;
  @ViewChild('developer_manager_fioHead', {static: true}) public developer_manager_fioHead: TemplateRef<any>;
  @ViewChild('developer_manager_fioTmpl', {static: true}) public developer_manager_fioTmpl: TemplateRef<any>;
  @ViewChild('sale_lead_transferHead', {static: true}) public sale_lead_transferHead: TemplateRef<any>;
  @ViewChild('sale_lead_transferTmpl', {static: true}) public sale_lead_transferTmpl: TemplateRef<any>;

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router,
              public fb: UntypedFormBuilder, private overlayWindowService: OverlayWindowService, private auth: AuthenticateService,
              private backrefWindowService: BackrefWindowService, public goBack: GoBackButtonService) {
    super(http, route, router);
    this.formGroup = this.fb.group({
      'fio': [''],
      'email': [''],
      'lead_phone': [''],
      'subagent_phone': [''],
      'create_time': [''],
      'next_contact_date': [''],
      'next_contact_time': [''],
      'date_start': [''],
      'date_end': [''],
      'date_change_start': [''],
      'date_change_end': [''],
      'last_days': [''],
      'tab': [''],
      'object_caption': [''],
      'subagent_user_fio': [''],
      'stage_caption': [''],
      'status_description': [''],
      'organisation_caption': [''],
      'physical_manager_fio': [''],
      'developer_manager_fio': [''],
      'developer_manager_phone': [''],
      'sale_lead_transfer': ['']

    });

    this.subscription = this.formGroup.valueChanges.pipe(debounceTime(500))
      .subscribe((data) => {
        this.router_param['page'] = 1;
        this.current_page = 1;
        if (this.firstLoad) {
          this.firstLoad = false;
        } else {
          this.changeForm(this.formGroup);
        }
        this.goBack.header = '<h1>Все обращения</h1>';
      });
    this.updateForm(this.formGroup);
    this.backrefWindowService.routerInit();
    this.routerSubscription = this.router.events.pipe(filter(x => x instanceof NavigationEnd))
      .subscribe(x => {
        this.goBack.header = '<h1>Все обращения</h1>';
      });
  }

  ngOnInit() {
    this.goBack.header = '<h1>Все обращения</h1>';
    this.data = this.route.snapshot.data['data'];
    this.subscribtionUser = this.auth.emit_user.subscribe((user) => this.user = user);
    this.columns = [
      {'caption': 'След. дата (созд./изм.)', 'name': 'next_contact_date', 'sortable': true, 'width': '250px',
        'template': this.next_contact_dateTmpl, 'templateHead': this.next_contact_dateHead},
      {'caption': 'Организация', 'name': 'organisation_caption', 'sortable': true,
        'templateHead': this.organisation_captionHead, 'template': this.organisation_captionTmpl},
      {'caption': 'Субагент', 'name': 'subagent_user_fio', 'sortable': true,
        'template': this.subagent_user_fioTmpl, 'templateHead': this.subagent_user_fioHead},
      {'caption': 'Телефон субагента', 'name': 'subagent_phone', 'sortable': true,
        'templateHead': this.subagent_phoneHead, 'width': '150px'
      },
      {'caption': 'Клиент', 'name': 'fio', 'sortable': true, 'width': '150px',
        'template': this.fioTmpl, 'templateHead': this.fioHead},
      {'caption': 'Телефон клиента', 'name': 'lead_phone', 'sortable': true,
        'templateHead': this.lead_phoneHead, 'width': '150px'},
      {'caption': 'Объекты', 'name': 'object_caption', 'sortable': true,
        'template': this.object_captionTmpl, 'templateHead': this.object_captionHead},
      {'name': 'physical_manager_fio', 'caption': 'Ответственный', 'sortable': true,
        'template': this.physical_manager_fioTmpl, 'templateHead': this.physical_manager_fioHead, 'width': '150px'},
      {'name': 'developer_manager_fio', 'caption': 'Менеджера застройщика', 'sortable': true,
        'template': this.developer_manager_fioTmpl, 'templateHead': this.developer_manager_fioHead},
      {'caption': 'Этап', 'name': 'stage_caption', 'sortable': true, 'template': this.stage_captionTmpl,
        'templateHead': this.stage_captionHead},
      {'caption': 'Вид лида', 'name': 'sale_lead_transfer', template: this.sale_lead_transferTmpl,
        'templateHead': this.sale_lead_transferHead}
    ];
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.subscribtionUser) {
      this.subscribtionUser.unsubscribe();
    }
  }
  ngAfterViewInit() {
    this.goBack.goScroll();
  }

  prepareQuery() {
    const result = super.prepareQuery();
    result['filter']['fields'] = {};
    result['filter']['fields'] = {
      'fio': this.formGroup.value.fio,
      'email': this.formGroup.value.email,
      'lead_phone': this.formGroup.value.lead_phone,
      'subagent_phone': this.formGroup.value.subagent_phone,
      'create_time': this.formGroup.value.create_time,
      'subagent_user_fio': this.formGroup.value.subagent_user_fio,
      'date_start': this.formGroup.value.date_start,
      'date_end': this.formGroup.value.date_end,
      'date_change_start': this.formGroup.value.date_change_start,
      'date_change_end': this.formGroup.value.date_change_end,
      'tab': this.formGroup.value.tab,
      'object_caption': this.formGroup.value.object_caption,
      'physical_manager_fio': this.formGroup.value.physical_manager_fio,
      'organisation_caption': this.formGroup.value.organisation_caption,
      'next_contact_date': this.formGroup.value.next_contact_date,
      'notice': this.formGroup.value.notice,
      'stage_caption': this.formGroup.value.stage_caption,
      'developer_manager_fio': this.formGroup.value.developer_manager_fio,
      'developer_manager_phone': this.formGroup.value.developer_manager_fio,
      'global_tab': 'all',
      'sale_lead_transfer': this.formGroup.value.sale_lead_transfer

    };
    // this.buttonLink = '';
    // for (const [key, value] of Object.entries(result['filter']['fields'])) {
    //   if (value !== '' && value !== undefined && key !== 'global_tab') {
    //     this.buttonLink += key + '=' + value + '&';
    //   }
    // }
    // if (this.buttonLink !== '') {
    //   this.buttonLink = '?' + this.buttonLink.slice(0, this.buttonLink.length - 1);
    // }
    return result;
  }

  filter_state(string) {
    if (this.formGroup.value.tab === string) {
      string = '';
    }
    this.formGroup.patchValue({
      'tab': string,
    });
  }
  datediff(newdate) {
    const oneDay = 1000 * 60 * 60 * 24;
    const [day, month, year] = newdate.split('.');
    const dayTill = Math.round((new Date(year, month - 1, day).getTime() - this.thisDate.getTime()) / oneDay) + 1;
    if (dayTill === 0) {
      return 'Контакт сегодня';
    } else if (dayTill > 0) {
      return 'Дней до контакта (' + dayTill + ')';
    } else if (dayTill < 0) {
      return 'Контакт просрочен';
    }
  }

  getSaleLeadTransferCaption(sale_lead_transfer) {
    const mapCaption = {
      null: 'Не указано',
      false: 'Самостоятельная работа',
      true: 'Расширенная работа'
    };

    return mapCaption[sale_lead_transfer];
  }
}
