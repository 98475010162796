<h1 *ngIf="caption">Список участников &laquo;{{caption}}&raquo;</h1>
<br>
<div class="popup-window">
  <div class="popup-window__buttons" *ngIf="editable">
    <button class="button" type="button" [disabled]="!closeable" (click)="toggleStatus(true)">Мероприятие прошло</button>
    <button class="button" type="button" [disabled]="!closeable" (click)="toggleStatus(false)">Мероприятие отменено</button>
  </div>
  <div class="popup-window__filter" *ngIf="editable">
    <div class="form_crm">
      <div class="form__group">
        <div class="form__input">
          <input class="input" type="text" placeholder="Поиск" [(ngModel)]="search_string">
        </div>
      </div>
    </div>
  </div>
  <div class="popup-window__body">
    <app-simple-table>
      <app-simple-table-th>ФИО</app-simple-table-th>
      <app-simple-table-th>E-mail</app-simple-table-th>
      <app-simple-table-th>Телефон</app-simple-table-th>
      <app-simple-table-th *ngIf="editable">Участвовал</app-simple-table-th>
      <!-- // -->
      <app-simple-table-tr
        *ngFor="let row of rows | fio_filter: search_string : editable : is_approved"
        [template]="template">
        <ng-template #template>
          <td>{{row['fio']}}</td>
          <td>{{row['email']}}</td>
          <td>{{row['phone']}}</td>
          <td *ngIf="editable">
            <label>
              <i class="fa" [ngClass]="{
                'mute': null === row['approve'],
                'fa-square-o': !row['approve'],
                'fa-check-square-o': row['approve']
                }"></i>
              <input type="checkbox" [hidden]="true" value="1" [(ngModel)]="row['approve']" (click)="toggleApproved(row)">
            </label>
          </td>
        </ng-template>
      </app-simple-table-tr>
    </app-simple-table>
  </div>
</div>
