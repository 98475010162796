import {Component, ComponentRef, Host, OnInit} from '@angular/core';
import {CreateEntityAltComponent} from '../create-entity.component';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {CreatePhysicalAltComponent} from '../../create-physical/create-physical.component';
import {DictionariesService} from '../../../../services/dictionaries.service';

@Component({
  selector: 'app-alt-entity-relationships',
  templateUrl: './create-entity-relationships.component.html',
  styleUrls: [
    './create-entity-relationships.component.scss'
  ]
})
export class CreateEntityAltRelationshipsComponent implements OnInit {

  public form: UntypedFormGroup;

  public posts;

  public get relationships(): UntypedFormArray {
    return this.form.get('physicals').get('relationships') as UntypedFormArray;
  }

  constructor(@Host() private hostComponent: CreateEntityAltComponent,
              private fb: UntypedFormBuilder, private dict: DictionariesService) { }

  ngOnInit() {
    this.form = this.hostComponent.form;

    this.dict.get('Dict.get_post').then(x => this.posts = x);

    this.hostComponent.form.addControl('physicals', this.fb.group({
      'relationships': this.fb.array([]),
    }));
  }

  addRelationship() {
    this.relationships.push(this.fb.group({
      'physical_id': [''],
      'post_id': ['']
    }));
  }

  createPhysical() {
    return this.hostComponent._parent.host.appendComponent(CreatePhysicalAltComponent, {'displayHeader': false});
  }

  appendPhysical(select) {
    this.createPhysical()
      .then((component: ComponentRef<CreatePhysicalAltComponent>) => {
        component.instance.onCreate.subscribe((response) => {
          select.appendOption(response['physical_id'], response['physical_fio']);
          this.hostComponent._parent.host.unregisterChild();
        });
      });
  }

}
