import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MaterialSelect2Service } from './material-select2.service';
import { Subscription } from 'rxjs';
import {UsageVariant} from '../../../models/usage_variants';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-material-select2',
  templateUrl: './material-select2.component.html',
  styleUrls: ['./material-select2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialSelect2Component),
      multi: true
    }
  ]
})
export class MaterialSelect2Component implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {

  @Input() public multiple;
  @Input() public noCopy = true;
  @Input() public options = [];
  @Input() public caption = '';
  @Input() public require = false;
  @Input() public cssClass = '';
  @Input() public placeholder = '';
  @ViewChild('select', {static: true}) private select: ElementRef;
  public usageCrm;
  public reloadSubscribtion: Subscription;
  public disabled = false;
  public valid = false;
  private refresh = false;
  private _value: any;
  @Input() public set reload(value: any) {
    if (value) {
      this.select2Service.reload();
    }
  }
  public set current_value(value: any) {

    if (value === 'refresh') {

      this.refresh = true;
      setTimeout(() => {
        this.refresh = false;
      }, 1);
    } else {
      this._value = value;
      this.propagateChange(this._value);
      this.onSelected();
    }
  }

  public get current_value(): any {
    return this._value;
  }

  propagateChange = (_: any) => {};
  propagateTouched = () => { };

  constructor(public select2Service: MaterialSelect2Service) {

  }

  ngOnInit() {
    this.usageCrm = environment.usage === UsageVariant.CRM;
    this.reloadSubscribtion = this.select2Service.onReload.subscribe((response) => {
      this.onSelected();
    });
  }

  ngAfterViewInit() {
    this.onSelected();
  }

  onSelected() {
    for (let i = 0; i < this.select.nativeElement.options.length; i++) {
      if (this.multiple) {
        this.select.nativeElement.options[i].selected =
          -1 !== this.current_value.indexOf(this.select.nativeElement.options[i].value);
      } else {
        this.select.nativeElement.options[i].selected = this.current_value === this.select.nativeElement.options[i].value;
      }
    }
  }

  writeValue(value: any) {
    this.current_value = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
      this.propagateTouched = fn;
  }

  onSelectChange(value: any) {
    this.current_value = value;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  copyToClipboard() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    if (typeof this.current_value === 'string') {
      selBox.value = this.current_value;
      if (selBox.value.substring(selBox.value.length - 1) !== ',') {
        selBox.value = selBox.value + ',';
      }
    } else {
      selBox.value = this.current_value.join(',') + ',';
    }
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  ngOnDestroy() {
    if (this.reloadSubscribtion) {
      this.reloadSubscribtion.unsubscribe();
    }
  }

}
