<div class="row no-gutters align-items-center flex-nowrap mb-2">
  <div class="col-auto">
    <a class="header__backref backref__btn mt-1 mr-2 float-left" href="javascript: void(0)"
                     (click)="onBack()"><i class="icon icon_back"></i></a>
  </div>
  <div class="col">
    <div class="caption-sm">{{subagent_caption}}</div>
  </div>
</div>
<div class="material-datatable-wrapper">
  <form class="form" [formGroup]="formGroup" >
    <div class="row no-gutters align-items-center">
      <div class="col">
        <app-material-select2 formControlName="event_id" [placeholder]="'-- Выберите событие --'">
          <option [value]="''">-- Выберите событие --</option>
          <option *ngFor="let event of event_list" [value]="event.event_id">
            {{event.caption}}
          </option>
        </app-material-select2>
      </div>
      <div class="col-auto">
        <button class="close close_sm ml-2 mb-1" tabindex="-1" type="button" (click)="clearSelect2()">
          <i class="icon icon_Small_Close"></i>
        </button>
      </div>
    </div>
  </form>
  <div class="table-responsive" [ngClass]="{'table-responsive': responsive}">
    <table class="table datatable" [ngClass]="cssClass">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)"
  ></app-datatable-pagination>
</div>


<ng-template #buttonTmpl let-row="row">
  <a href="javascript:void(0)"
     (click)="getSubagent(row.organisation_id, row.organisation_socrachcaption)"
     class="button button_icon button_icon_sub mr-1"><i class="icon icon_Small_Crowd"></i>
  </a>
</ng-template>

<ng-template #checkTmpl let-row="row">
  <input class="checkbox ml-1" type="checkbox" value="{{row.physic_id}}" *ngIf="formGroup.value.event_id"
         [checked]="getCheck(row)" (click)="onCheck($event, row)">
</ng-template>

<ng-template #checkHeadTmpl let-row="row">
  <a href="javascript: void(0)"  *ngIf="formGroup.value.event_id"
     class="calendar-physical__check-all" (click)="onCheckAll()">Всех</a>
</ng-template>
