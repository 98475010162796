import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {JsonClientService} from '../../../shared/json-client';


@Injectable()
export class ReportKpiTableResolve {

  public readonly filter_columns = [
    'stage_change_time',
    'organisation_caption',
    'subagent_user_fio',
    'subagent_phone',
    'fio',
    'lead_phone',
    'object_caption',
    'physical_manager_fio',
    'stage_caption',
  ];
  constructor(private jsonRpc: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {

    const prepared_filter = {};
    const prepared_filter_keys = this.filter_columns.filter(x => !!route.parent.queryParams[x]);
    const sort = {};

    prepared_filter_keys.forEach(x => prepared_filter[x] = route.parent.queryParams[x]);

    if (!!route.queryParams['sort_name']) {
      sort[route.queryParams['sort_name']] = route.queryParams['sort_key'] || 'asc';
    }
    const options = {
      'limit': 20,
      'offset': (+(route.queryParams['page'] || 1) - 1) * 20,
      'filter': {
        'global_search_string': route.queryParams['global_search_string'] || null,
        'fields': prepared_filter,
      },
      'sort': sort,
      'month': route.queryParams['month'],
      'date_start': route.queryParams['date_start'],
      'date_end': route.queryParams['date_end'],
      'user_id': parseInt(route.queryParams['user_id'], 10),
    };
    return this.jsonRpc.post('Data_tables.get_attraction_kpi', options);
  }

}
