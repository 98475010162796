import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
  selector: 'app-position-selector',
  templateUrl: './position-selector.component.html',
  styleUrls: ['./position-selector.component.scss'],
})
export class PositionSelectorComponent implements OnInit {

  @Input() public startValue = 0;
  @Output() public finalValue: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }
  changeRange(value) {
    this.finalValue.emit({'value': value});
  }

}
