<div class="material-datatable-wrapper">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)">
  </app-datatable-headline>
  <div class="table-responsive">
    <table class="table table-striped datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>
<div class="row no-gutters mt-4">
  <div class="col-6">
    <button (click)="saveForm()" type="button" class="button fixed_buttons_block_main">
      Готово
    </button>
  </div>
</div>

<ng-template #processTmpl let-row="row">
  <ng-container *ngIf="row.statistic">
    <span class="" title="Всего квартир">{{row.statistic.process.flat}}</span> /
    <span class="c-red" title="Отклонено">{{row.statistic.reject.flat}}</span><br/>
    <span class="" title="Всего коммерческой">{{row.statistic.process.commerce}}</span> /
    <span class="c-red" title="Пропущено коммерческой">{{row.statistic.ignored.commerce}}</span>
  </ng-container>
</ng-template>

<ng-template #rejectTmpl let-row="row">
  <ng-container *ngIf="row.statistic">
    {{row.statistic.reject.flat}}
  </ng-container>
</ng-template>

<ng-template #modifyTmpl let-row="row">
  <ng-container *ngIf="row.statistic">
    <span class="c-green" title="Добавлено квартир">{{row.statistic.add.flat}}</span> /
    <span class="c-yellow2" title="Изменений квартир">{{row.statistic.modify.flat}}</span> /
    <span class="c-red" title="Удалено квартир">{{row.statistic.delete.flat}}</span> /
    <span class="c-blue" title="Восстановлено квартир">{{row.statistic.restore.flat}}</span><br/>
    <span class="c-green" title="Добавлено коммерческой">{{row.statistic.add.commerce}}</span> /
    <span class="c-red" title="Удалено коммерческой">{{row.statistic.delete.commerce}}</span>
  </ng-container>
</ng-template>

<ng-template #deleteTmpl let-row="row">
  <ng-container *ngIf="row.statistic">
    {{row.statistic.delete.flat}}
  </ng-container>
</ng-template>

<ng-template #addTmpl let-row="row">
  <ng-container *ngIf="row.statistic">
    {{row.statistic.add.flat}}
  </ng-container>
</ng-template>

<ng-template #statusTmpl let-row="row">
  <ng-container [ngSwitch]="row.is_ok">
    <ng-container *ngSwitchCase="null">&mdash;</ng-container>
    <ng-container *ngSwitchCase="true">Успешно</ng-container>
    <ng-container *ngSwitchCase="false"><span class="text-danger">Ошибка</span></ng-container>
  </ng-container>
</ng-template>

<ng-template #excelTmpl let-row="row">
  <div class="text-right">
    <ng-container *ngIf="row.backup_filename">
      <a href="javascript:void(0)"
         (click)="onLogFileLoadClick(row.id, row.time)"
         class="button button_icon mr-2"
         title="Вернуться к этой версии фида"
      ><i class="fa fa-refresh"></i></a>
    </ng-container>
    <ng-container *ngIf="row.url">
      <a [href]="row.url" target="_blank" class="button button_icon" title="Скачать"><i class="icon icon_download"></i></a>
    </ng-container>
  </div>
</ng-template>

<ng-template #errorTmpl let-row="row">
  <ng-container *ngIf="row.statistic">
    <span class="c-yellow2" title="Заметка">{{row.statistic.logs.notice}}</span> /
    <span class="c-pink" title="Предупреждения">{{row.statistic.logs.warning}}</span> /
    <span class="c-red" title="Ошибка">{{row.statistic.logs.danger}}</span>
  </ng-container>
  <ng-container *ngIf="row.trace">
    <textarea rows="5" style="width: 650px;" readonly>{{row.trace}}</textarea>
  </ng-container>
</ng-template>
