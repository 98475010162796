import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BreadcrumbsService} from '../../../../shared/breadcrumbs/services';
import {GoBackButtonService} from '../../../../services/go-back-button.service';
import {Subscription} from 'rxjs';
import {ReportComponent} from '../report/report.component';
import {AuthenticateService} from '../../../../services/authenticate.service';
import {UntypedFormBuilder, FormGroup} from '@angular/forms';
import {JsonClientService} from '../../../../shared/json-client';

declare const moment;

@Component({
  templateUrl: './get-activity-complex-id.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: [
    './get-activity-complex-id.component.scss'
  ]
})
export class GetActivityComplexIdComponent extends ReportComponent implements OnInit, OnDestroy, AfterViewInit {



  public data: any;
  public url_segment = 'report-get-activity-complex-id';

  public lineChartData = [];
  public lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
      labels: {
        usePointStyle: true,
      },
      fullWidth: true
    }
  };
  public lineChartLegend = true;

  constructor(public r: Router,
              public a: ActivatedRoute,
              public b: BreadcrumbsService,
              public goBack: GoBackButtonService,
              private auth: AuthenticateService,
              private fb: UntypedFormBuilder,
              private j: JsonClientService,
              private cd: ChangeDetectorRef) {
    super(r, a, b);
  }

  public get base_url() {
    return '/analytic/' + this.url_segment + '/';
  }

  ngOnInit() {
    super.ngOnInit();

    this.captureData();
  }

  whenUrlChange() {
    super.whenUrlChange();
    this.captureData();
  }
  captureData() {
    this.data = this.a.snapshot.data['data']
    this.goBack.header = '<h2>' + this.data.sources[0].caption + '</h2>';
    const keys = this.data.keys.map(x => {
      return x.replace('<br>', ' ');
    })
    this.lineChartData = [
      {
        options: this.lineChartOptions,
        labels: keys,
        class: 'col-md-12',
        type: 'bar',
        caption: 'Кол-во заявок за промежуток',
        value: [
          { data: this.data.sources[0].data, label: 'Лиды' },
          { data: this.data.sources[0].data_by_events, label: 'Лиды с обучения' },
        ]
      },
      {
        options: this.lineChartOptions,
        labels: keys,
        class: 'col-md-12 mt-minus',
        type: 'bar',
        caption: 'Кол-во мероприятий за промежуток',
        colors: [
          {
            backgroundColor: 'rgb(255, 204, 0, 0.4)',
            borderColor: 'rgb(255, 204, 0)',
            pointBackgroundColor: 'rgb(255, 204, 0)',
          },
        ],
        value: [
          { data: this.data.sources[0].event_data, label: 'Мероприятия'},
        ]
      },
    ];
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  ngOnDestroy() {
    if (!this.cd['destroyed']) {
      this.cd.detach();
    }
  }


  ngAfterViewInit() {
    this.goBack.goScroll();
  }
}
