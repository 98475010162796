import { NgModule } from '@angular/core';
import { HousingComplexComponent } from './housing-complex/housing-complex.component';
import { HousingListComponent } from './housing-list.component';
import { RouterModule } from '@angular/router';
import { DataTableModule } from '../../modules/@datatable/datatables.module';
import { CommonModule } from '@angular/common';
import { TabsModule } from '../../modules/@tabs/tabs.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialInputModule } from '../../modules/@material-input';
import { HousingPlacementComponent } from './housing/housing-placement.component';
import { HousingCommonComponent } from './housing/housing-common.component';
import { HousingSectionsComponent } from './housing/housing-sections.component';
import { HousingComplexCommonComponent } from './housing-complex/housing-complex-common.component';
import { HousingComplexPresentationComponent } from './housing-complex/housing-complex-presentation.component';
import { HousingComplexPhotoComponent } from './housing-complex/housing-complex-photo.component';
import { HousingComplexHousingsComponent } from './housing-complex/housing-complex-housings.component';
import { HousingComponent } from './housing/housing.component';
import { HousingImagesComponent } from './housing/housing-images.component';
import { HousingSectionsResolve } from './housing/housing-sections.resolve';
import { HousingSectionsCreateComponent } from './housing/housing-sections-create.component';
import { HousingComplexCatalogComponent } from './housing-complex-catalog/housing-complex-catalog.component';
import { HousingComplexCatalogCommonComponent } from './housing-complex-catalog/housing-complex-catalog-common.component';
import { HousingCreateComponent } from './housing-create/housing-create.component';
import { HousingImagesResolve } from './housing/housing-images.resolve';
import { FormWizardModule } from '../../modules/@wizard-form';
import { HousingKvartirogrammaComponent } from './housing/housing-kvartirogramma.component';
import { HousingCatalogModule } from './housing-catalog/housing-catalog.module';
import { HousingKvartirogrammaResolve } from './housing/housing-kvartirogramma.resolve';
import { HousingComplexCreateComponent } from './housing-complex-create/housing-complex-create.component';
import { HousingComplexAboutResolve, HousingComplexCommonResolve, HousingComplexHousingsResolve,
  HousingComplexPhotoResolve, HousingComplexPresentationResolve } from './shared/housing-complex.resolve';
import { HousingComplexCatalogResolve } from './shared/housing-complex-catalog.resolve';
import { DataHousingAboutResolve } from './shared/data-housing-about.resolve';
import { FlatAboutResolve, FlatCommonResolve, FlatConditionsResolve, FlatFloorsResolve, FlatImagesResolve,
  FlatLayoutResolve, FlatOperationsResolve, FlatProprietorsResolve } from './shared/flat.resolve';
import { HousingCommonResolve } from './housing/housing-common.resolve';
import { HousingStructurialListComponent } from './housing-structurial-list/housing-structurial-list.component';
import { StructurialHousingComplexComponent } from './housing-structurial-list/structurial-housing-complex/structurial-housing-complex.component';
import { StructurialHousingComponent } from './housing-structurial-list/structurial-housing/structurial-housing.component';
import { StructurialFlatComponent } from './housing-structurial-list/structurial-flat/structurial-flat.component';
import { PopoverModule } from '../../modules/@popover/popober.module';
import { PreloaderModule } from '../../modules/@preloader/preloader.module';
import { NewlyFlatComponent } from './flat/newly-flat.component';
import { FlatModule } from '../../shared/housing/shared/flat/flat.module';
import {OsmModule} from '../../shared/osm';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DataTableModule,
        TabsModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialInputModule,
        FormWizardModule,
        HousingCatalogModule,
        PopoverModule,
        PreloaderModule,
        FlatModule,
        OsmModule,
    ],
    declarations: [
        HousingListComponent,
        /* --- */
        NewlyFlatComponent,
        /* --- */
        HousingComponent,
        HousingPlacementComponent,
        HousingCommonComponent,
        HousingSectionsComponent,
        HousingImagesComponent,
        HousingKvartirogrammaComponent,
        /* --- */
        HousingComplexComponent,
        HousingComplexCommonComponent,
        HousingComplexPresentationComponent,
        HousingComplexPhotoComponent,
        HousingComplexHousingsComponent,
        HousingComplexCatalogComponent,
        HousingComplexCatalogCommonComponent,
        /* --- */
        HousingComplexCreateComponent,
        HousingCreateComponent,
        HousingSectionsCreateComponent,
        HousingStructurialListComponent,
        StructurialHousingComplexComponent,
        StructurialHousingComponent,
        StructurialFlatComponent,
    ],
    exports: [
        PopoverModule,
        NewlyFlatComponent,
        /* --- */
        HousingComponent,
        HousingComplexComponent,
        HousingPlacementComponent,
        HousingCommonComponent,
        HousingSectionsComponent,
        HousingImagesComponent,
        HousingKvartirogrammaComponent,
        /* --- */
        HousingCreateComponent,
        /* --- */
        HousingListComponent,
        HousingComplexCommonComponent,
        HousingComplexPresentationComponent,
        HousingComplexHousingsComponent,
        /* --- */
        HousingCatalogModule,
    ],
    providers: [
        DataHousingAboutResolve,
        HousingSectionsResolve,
        HousingImagesResolve,
        HousingKvartirogrammaResolve,
        HousingComplexAboutResolve,
        HousingComplexCatalogResolve,
        HousingComplexCommonResolve,
        HousingComplexPresentationResolve,
        HousingComplexPhotoResolve,
        HousingComplexHousingsResolve,
        HousingCommonResolve,
        FlatAboutResolve,
        FlatCommonResolve,
        FlatLayoutResolve,
        FlatFloorsResolve,
        FlatImagesResolve,
        FlatProprietorsResolve,
        FlatOperationsResolve,
        FlatConditionsResolve,
    ]
})
export class NewlyModule {
}
