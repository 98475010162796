import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataTableParamComponent} from '../../../modules/@datatable/components/datatable-param.component';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {HttpClient} from '../../../services/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime} from 'rxjs/operators';

@Component({
  templateUrl: './appeals-list.component.html',
  styleUrls: [
    './appeals-list.component.scss'
  ]
})
export class AppealsListComponent extends DataTableParamComponent implements OnInit, OnDestroy {

  api_action = 'Data_tables.get_crm_appeals';

  public columns: Array<{ [key: string]: any }>;
  public formGroup: UntypedFormGroup;
  public createSubscription: Subscription;
  private firstLoad = true;

  @ViewChild('headAppealId', {static: true}) private headAppealId: TemplateRef<any>;
  @ViewChild('headAppealDate', {static: true}) private headAppealDate: TemplateRef<any>;
  @ViewChild('headAppealFio', {static: true}) private headAppealFio: TemplateRef<any>;
  @ViewChild('headAppealPhone', {static: true}) private headAppealPhone: TemplateRef<any>;
  @ViewChild('headAppealManager', {static: true}) private headAppealManager: TemplateRef<any>;
  @ViewChild('headAppealAuthor', {static: true}) private headAppealAuthor: TemplateRef<any>;
  @ViewChild('headOperationType', {static: true}) private headOperationType: TemplateRef<any>;

  @ViewChild('tmplEstManager', {static: true}) private tmplEstManager: TemplateRef<any>;

  @ViewChild('tmplEditBtn', {static: true}) private tmplEditBtn: TemplateRef<any>;

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router,
              public fb: UntypedFormBuilder) {
    super(http, route, router);

    this.formGroup = this.fb.group({
      'appeal_id': [''],
      'appeal_date': [''],
      'appeal_fio': [''],
      'appeal_phone': [''],
      'appeal_manager': [''],
      'appeal_author': [''],
      'appeal_operation_type': [''],
    });

    this.formGroup.valueChanges.pipe(debounceTime(500)).subscribe((data) => {
      this.router_param['page'] = 1;
      this.current_page = 1;
      if (this.firstLoad) {
        this.firstLoad = false;
      } else {
        this.changeForm(this.formGroup);
      }
    });
    this.updateForm(this.formGroup);
  }

  ngOnInit() {
    this.columns = [
      {'caption': '№', 'name': 'appeal_id', 'sortable': true, 'templateHead': this.headAppealId},
      {'caption': 'Тип операции', 'name': 'appeal_operation_type', 'sortable': true, 'templateHead': this.headOperationType},
      {'caption': 'Дата обращения', 'name': 'appeal_date', 'sortable': true, 'templateHead': this.headAppealDate},
      {'caption': 'Кто обратился', 'name': 'appeal_fio', 'sortable': true, 'templateHead': this.headAppealFio},
      {'caption': 'Телефон', 'name': 'appeal_phone', 'sortable': true, 'templateHead': this.headAppealPhone},
      {'caption': 'Ответственный', 'name': 'appeal_manager', 'sortable': true, 'templateHead': this.headAppealManager},
      {'caption': 'Автор', 'name': 'appeal_author', 'sortable': true, 'templateHead': this.headAppealAuthor},
      {'caption': 'Менеджер Est', 'name': 'appeal_author', 'sortable': true, 'template': this.tmplEstManager},
      {'caption': '', 'name': 'appeal_id', 'template': this.tmplEditBtn},
    ];

    super.ngOnInit();
  }

  ngOnDestroy() {
    if (this.createSubscription) {
      this.createSubscription.unsubscribe();
    }
  }

  prepareQuery() {
    const result = super.prepareQuery();

    result['filter']['fields'] = {
      'appeal_id': this.formGroup.value.appeal_id,
      'appeal_date': this.formGroup.value.appeal_date,
      'appeal_fio': this.formGroup.value.appeal_fio,
      'appeal_phone': this.formGroup.value.appeal_phone,
      'appeal_manager': this.formGroup.value.appeal_manager,
      'appeal_author': this.formGroup.value.appeal_author,
    };

    return result;
  }

  createAppeal() {
    alert('111');
  }

}
