
<div class="mb-2">
  <app-easy-dropdown [cssClass]="'w100 max-height-none'" [activeDropdown]="false"
                     [minusPadding]="true"
                     [titleText]="'Аналитика действий:'">
    <ng-container *ngIf="header_info">
      <p class="mb-1" *ngFor="let el of header_info">
        {{el.caption}}: <b>{{el.count}}</b>
      </p>
    </ng-container>
  </app-easy-dropdown>
</div>
<div class="material-datatable-wrapper">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)">
  </app-datatable-headline>
  <div class="table-responsive">
    <table class="table table-striped datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>


<ng-template #IdHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="id" [cssClass]="'mb-0'" [placeholder]="'№'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #subagentHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="subagent" [cssClass]="'mb-0'" [placeholder]="'Субагент'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #actionHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="type" [cssClass]="'mb-0'" [placeholder]="'Действие'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #authorHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="author_fio" [cssClass]="'mb-0'" [placeholder]="'Автор'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #timeHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="create_time" [cssClass]="'mb-0'" [placeholder]="'Дата'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #subagentTmpl let-row="row" let-row_index="row_index">
  <ng-container *ngIf="row.subagent_id&&row.subagent">
    <a routerLink="'/entity/edit/{{row.subagent_id}}/'">
      <i class="fa fa-user"></i> {{row.subagent}}
    </a>
  </ng-container>
</ng-template>


<ng-template #authorTmpl let-row="row" let-row_index="row_index">
  <ng-container *ngIf="row.author_id&&row.author_fio">
    <a routerLink="'/entity/edit/{{row.author_id}}/'">
      <i class="fa fa-user"></i> {{row.author_fio}}
    </a>
  </ng-container>
</ng-template>
