import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import * as c from './components';
import {MobileSortDirective} from './mobile-sort.directive';
import {MaterialInputModule} from '../../../modules/@material-input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


@NgModule({
    imports: [
        CommonModule,
        MaterialInputModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [
        c.MobileSortComponent,
        c.MobileSortVitrineComponent,
        MobileSortDirective,
    ],
    exports: [
        c.MobileSortComponent,
        c.MobileSortVitrineComponent,
        MobileSortDirective,
    ]
})
export class MobileSortModule { }
