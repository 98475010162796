<div class="mobile-sort c-black">
  <div class="d-flex w-100 align-items-baseline">
    <p class="caption">{{caption}}</p>
    <i class="icon icon_Small_Close ml-3 c-pointer ml-auto" (click)="closeTrigger()"></i>
  </div>
  <form [formGroup]="formGroup" novalidate>
    <ng-container *ngIf="sort_exist">
      <app-material-input-radio formControlName="sort"
                                [data]="{'asc': 'Сортировать по возрастанию', 'desc': 'Сортировать по убыванию'}"
                                [suppressLabel]="true"
      ></app-material-input-radio>
    </ng-container>
    <ng-template
      *ngIf="template"
      [ngTemplateOutlet]="template"></ng-template>
  </form>

</div>
