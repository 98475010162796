<div class="MyRange">
  <input
    #renger
    class="range"
    type="range"
    min="-10"
    max="10"
    step="0.1"
    [value] = "startValue"
    (change)="changeRange(renger.value)"
    (input)="changeRange(renger.value)">
</div>
