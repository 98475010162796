<div class="tab-content-wrapper mt-4">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <div class="row no-gutters gutters-4 photo">
      <div class="col-6 photo__image">
        <app-material-input-dropimage formControlName="file"
                                      [cssClass]="'m-h-none embed-responsive embed-responsive-1by1 visible'"
                                      [cssClassZone]="'zone-photo'">
          <b>Фотография сотрудника</b>
          <br>Нажмите, чтобы выбрать файл,<br> или перетащите фото сюда
        </app-material-input-dropimage>
      </div>
      <div class="col-6 mt-3 photo__descr">
        <div class="">
          <i class="fa-fw fa fa-info-circle"></i><b>Информация</b>
          <br class="mt-2">Размер файла не должен превышать 100Мб.
          <br>Формат изображений jpg, jpeg, png, gif.
          <br>Пропорции изображения 1:1.
        </div>
        <div class="mt-4">
          <button (click)="saveForm()"
                  type="button" class="button button_main">
            Сохранить
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
