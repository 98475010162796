import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialInputModule } from '../../modules/@material-input/material-input.module';
import { DataTableModule } from '../../modules/@datatable/datatables.module';
import { StatisticPluginComponent } from './statistic-plugin/statistic-plugin.component';
import { StatisticPluginSiteComponent } from './statistic-plugin/statistic-plugin-site.component';
import { PluginResolve } from './shared/plugin.resolve';
import { SharedModule } from '../../shared/shared.module';
import { StatisticPluginService } from './shared/statistic-plugin.service';

@NgModule({
    imports: [
        CommonModule,
        DataTableModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialInputModule,
        RouterModule,
        SharedModule
    ],
    declarations: [StatisticPluginComponent, StatisticPluginSiteComponent],
    exports: [StatisticPluginComponent, StatisticPluginSiteComponent],
    providers: [PluginResolve, StatisticPluginService]
})
export class StatisticModule {
}
