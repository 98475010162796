<ng-container *ngIf="!loading">
  <a href="https://spn24.ru/" target="_blank" class="logo logo_spn"></a>
  <div class="row">
    <div class="col-md-12">
      <form class="md-float-material" [formGroup]="form" (submit)="onSubmit()" novalidate>
        <div class="col-md-12">
          <app-material-input formControlName="login">Имя пользователя</app-material-input>
          <app-material-input formControlName="password" [cssClass]="'mt-2'" [type]="'password'">Пароль
          </app-material-input>
        </div>
        <div class="col-md-12 mt-2">
          <button class="button button_main" type="submit" [disabled]="!form.valid || buttonLocked">Войти</button>
          <p class="pull-right mb-0 mr-2 lh-button text-right">
            <a class="" href="/restore.html"> Забыли пароль?</a>
          </p>
        </div>
      </form>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="loading">
  <app-preloader></app-preloader>
</ng-container>
