import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {UntypedFormBuilder} from '@angular/forms';
import { filter } from 'rxjs/operators';
import {AlternativeDatatablesInterface} from './alternative-datatables.interface';
import {JsonClientService} from '../json-client';

@Injectable()
export abstract class AlternativeDatatablesComponent implements OnDestroy, AlternativeDatatablesInterface {

  private subscription: Subscription;
  // this is flag for repair situation with no data in datatable after goBackButton click
  protected initDataLoad = null;


  public filter: string = null;


  constructor(router: Router, public activatedRoute: ActivatedRoute, fb: UntypedFormBuilder,
              public http: JsonClientService) {
    this.subscription = router.events
      .pipe(filter(x => x instanceof NavigationEnd))
      .subscribe(_ => {
        if (this.initDataLoad === null || this.initDataLoad === true) {
          if (this.initDataLoad === null) {
            this.initDataLoad = false;
          }
          this.filter = activatedRoute.snapshot.queryParams['filter'];
          this.onFilterChanged();
        }
      });

  }

  onInitCall() {
    if (this.initDataLoad === null) {
      this.initDataLoad = true;
      this.filter = this.activatedRoute.snapshot.queryParams['filter'];
      this.onFilterChanged();
    } else if (this.initDataLoad === false) {
      this.initDataLoad = true;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  onFilterChanged(): void {
    throw new Error('Method not implemented.');
  }

}
