import {Component, ComponentRef, EventEmitter, Host, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {HttpClient} from '../../../../../services/http.service';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {NotificationService} from '../../../../../services/notification.service';
import {PhysicalCreateComponent} from '../../../../../_crm/physical/physical-create/physical-create.component';
import {EntityCreateComponent} from '../../../../../_crm/entity/entity-create/entity-create.component';
import {CreatePhysicalAltComponent} from '../../../../alternative/create-physical/create-physical.component';
import {CreateEntityAltComponent} from '../../../../alternative/create-entity/create-entity.component';
import {FlatInfoStandaloneComponent} from '../flat-info-standalone.component';

@Component({
  selector: 'app-flat-info-proprietors',
  templateUrl: './flat-proprietors.component.html',
  styleUrls: ['./flat-proprietors.component.scss']
})
export class FlatProprietorsComponent implements OnInit, OnDestroy {

  public data: any = null;
  public form: UntypedFormGroup;

  public initialized = false;

  public flatId = null;
  public physicalSubscription: Subscription;
  public entittSubscription: Subscription;
  public api_action = 'Search.physicals_and_organisations';
  public types = {
    'no': 'Не определен',
    'sob': '1 собственник',
    'sovm': 'Совместная',
    'dol': 'Долевая',
  };

  public get sob(): UntypedFormArray {
    return this.form.get('proprietors').get('sob') as UntypedFormArray;
  }

  public get sovm(): UntypedFormArray {
    return this.form.get('proprietors').get('sovm') as UntypedFormArray;
  }

  public get dol(): UntypedFormArray {
    return this.form.get('proprietors').get('dol') as UntypedFormArray;
  }

  @Input('flat_id') public flat_id;
  @Input('parent') public parent;

  constructor(@Host() private host: FlatInfoStandaloneComponent,
              public activeRoute: ActivatedRoute, public fb: UntypedFormBuilder, public http: HttpClient,
              public notify: NotificationService) {
  }

  ngOnInit() {
    this.form = this.host.form;

    if (this.flat_id) {
      this.http.post('Realty.get_proprietors', {
          'realty_id': +this.flat_id,
        })
        .subscribe(x => {
          this.data = x;
          this.initializeData();
        });
    }
    else {
      this.data = {'proprietors': []};
      this.initializeData();
    }
  }

  initializeData() {
    let sobArray = this.fb.array([
      this.fb.group({
        'caption': '',
        'value': [''],
      })
    ]);

    let sovmArray = this.fb.array([
      this.fb.group({
        'caption': '',
        'value': [''],
      })
    ]);

    let dolArray = this.fb.array([
      this.fb.group({
        'caption': '',
        'value': [''],
        'znamenatel': '',
        'chislitel': '',
      })
    ]);

    if (this.data['proprietors'].length) {
      switch (this.data['type']) {
        case 'sob':
          sobArray = this.fb.array([
            this.fb.group({
              'caption': this.data['proprietors'][0]['caption'],
              'value': [this.data['proprietors'][0]['value']],
            })
          ]);
          break;
        case 'sovm':
          sovmArray = this.fb.array(this.data['proprietors'].map((item) => {
            return this.fb.group({
              'caption': item['caption'],
              'value': [item['value']],
            });
          }));
          break;
        case 'dol':
          dolArray = this.fb.array(this.data['proprietors'].map((item) => {
            return this.fb.group({
              'caption': item['caption'],
              'value': [item['value']],
              'znamenatel': item['znamenatel'],
              'chislitel': item['chislitel'],
            });
          }));
          break;
      }
    }

    this.form.addControl('proprietors', this.fb.group({
      'type': [(this.data['type']) ? this.data['type'] : 'no'],
      'sob': sobArray,
      'sovm': sovmArray,
      'dol': dolArray,
    }));

    this.initialized = true;
  }

  sovmAdd() {
    this.sovm.push(
      this.fb.group({
        'caption': '',
        'value': [''],
      })
    );
  }

  sovmDelete(item) {
    this.sovm.removeAt(this.sovm.controls.indexOf(item));
  }

  dolAdd() {
    this.dol.push(
      this.fb.group({
        'caption': '',
        'value': [''],
        'znamenatel': '',
        'chislitel': '',
      })
    );
  }

  dolDelete(item) {
    this.dol.removeAt(this.dol.controls.indexOf(item));
  }

  physicalAdd() {
    this.parent.host.appendComponent(CreatePhysicalAltComponent, {'displayHeader': false})
      .then((component: ComponentRef<CreatePhysicalAltComponent>) => {
        this.physicalSubscription = component.instance.onCreate.subscribe((response) => {
          this.newProprietor({
            'value': 'physical:' + response.physical_id,
            'description': response.physical_fio,
          });

          this.parent.host.unregisterChild();
        });
      });
  }

  entityAdd() {
    this.parent.host.appendComponent(CreateEntityAltComponent, {'displayHeader': false})
      .then((component: ComponentRef<CreateEntityAltComponent>) => {
        this.entittSubscription = component.instance.onCreate.subscribe((response) => {
          this.newProprietor({
            'value': 'organisation:' + response.organisation_id,
            'description': response.organisation_caption,
          });

          this.parent.host.unregisterChild();
        });
      });
  }

  newProprietor(response) {
    switch (this.form.value['proprietors']['type']) {
      case 'sob':
        this.sob.removeAt(0);
        this.sob.push(
          this.fb.group({
            'caption': response.description,
            'value': [response.value],
          })
        );
        break;
      case 'sovm':
        this.sovm.push(
          this.fb.group({
            'caption': response.description,
            'value': [response.value],
          })
        );
        break;
      case 'dol':
        this.dol.push(
          this.fb.group({
            'caption': response.description,
            'value': [response.value],
            'znamenatel': '',
            'chislitel': '',
          })
        );
        break;
    }
  }

  ngOnDestroy() {
    if (this.physicalSubscription) {
      this.physicalSubscription.unsubscribe();
    }
    if (this.entittSubscription) {
      this.entittSubscription.unsubscribe();
    }
  }

}
