<aside class="sidebar-wrapper">
  <section class="sidebar" appSidebarToggle>
      <div class="sidebar__bg sidebar__bg_crm"></div>
      <div class="menu__item-group" routerLinkActive="menu__item-group_active">
        <div class="menu__item-icon icon icon_menu" (click)="sidebarMode()"></div>
      </div>
      <div class="sidebar__menu sidebar_super_scroll">
        <div class="super_scroll_app perfect-scrollbar">
          <!-- [config]="{'wheelPropagation': true, 'suppressScrollX': true}"-->
        <nav class="menu menu_crm">
          <div class="menu__item-group" routerLinkActive="menu__item-group_active">
            <div class="menu__item-icon menu__item-icon_dashboard" routerLink="crm"></div>
            <div class="menu__item-list">
              <a routerLink="control/index" routerLinkActive="menu__item_active" class="menu__item">
                <span class="menu__item-text">Главная</span>
              </a>
            </div>
          </div>
          <div class="menu__item-group" routerLinkActive="menu__item-group_active">
            <div class="menu__item-icon menu__item-icon_users" routerLink="physical"></div>
            <div class="menu__item-list">
              <a routerLink="physical" routerLinkActive="menu__item_active" class="menu__item menu__item_collapse">
                <span class="menu__item-text">Контакты</span>
              </a>
              <a routerLink="physical" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Физические лица</span>
              </a>
              <a routerLink="entity" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Юридические лица</span>
              </a>
            </div>
          </div>
          <div class="menu__item-group"
               routerLinkActive="menu__item-group_active"
               *ngIf="auth.isMenu('projects_flag')"
          >
            <div class="menu__item-icon icon menu__item-icon_housing-edit" routerLink="housing2"></div>
            <div class="menu__item-list">
              <a routerLink="housing2" routerLinkActive="menu__item_active"
                 class="menu__item menu__item_collapse">
                <span class="menu__item-text">Отдел Проектов</span>
              </a>
              <a routerLink="housing2" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Проекты</span></a>
              <a routerLink="start-sales" class="menu__item" routerLinkActive="menu__item_active"
                 *ngIf="auth.isAdmin()"
              >
                <span class="menu__item-text">Старты продаж</span></a>
              <a routerLink="housing-order" class="menu__item" routerLinkActive="menu__item_active"
                 *ngIf="auth.isAdmin()"
              >
                <span class="menu__item-text">Сортировка ЖК</span></a>
              <a routerLink="control/layouts" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Контроль</span></a>
              <a routerLink="import" class="menu__item" routerLinkActive="menu__item_active"
                 *ngIf="auth.isAdmin()"
              >
                <span class="menu__item-text">Импорт XML</span></a>
              <a routerLink="import_xlsx" class="menu__item" routerLinkActive="menu__item_active"
                 *ngIf="auth.isAdmin()"
              >
                <span class="menu__item-text">Импорт XLSX</span></a>
              <a routerLink="realty-show-logs" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Логи публикации проектов</span></a>
              <a routerLink="tariff-map" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Тарифная карта</span></a>
              <a routerLink="cian-yandex" class="menu__item" routerLinkActive="menu__item_active"
                 *ngIf="auth.isFullAccess('projects_flag')"
              >
                <span class="menu__item-text">Циан / яндекс / авито </span></a>
              <a routerLink="mass" class="menu__item" routerLinkActive="menu__item_active"
                 *ngIf="auth.isFullAccess('projects_flag')"
              >
                <span class="menu__item-text">Массовое занесение </span></a>
              <a routerLink="eat-complex-captions" class="menu__item" routerLinkActive="menu__item_active"
                 *ngIf="auth.isAdmin()"
              >
                <span class="menu__item-text">Эст-а-тет ЖК ассоциации</span></a>
              <a routerLink="complaints-datatable" class="menu__item" routerLinkActive="menu__item_active"
                 *ngIf="auth.isAdmin()"
              >
                <span class="menu__item-text">Вторичка - жалобы</span></a>
            </div>
          </div>

          <div class="menu__item-group"
               routerLinkActive="menu__item-group_active"
               *ngIf="auth.isMenu('attraction_is')"
          >
            <div class="menu__item-icon menu__item-icon_leads" routerLink="attraction/not-registered"></div>
            <div class="menu__item-list">
              <a routerLink="attraction/lead-info" routerLinkActive="menu__item_active"
                 class="menu__item menu__item_collapse">
                <span class="menu__item-text">Отдел Привлечения</span>
              </a>
              <!--<a routerLink="attraction/not-registered" class="menu__item" routerLinkActive="menu__item_active">-->
                <!--<span class="menu__item-text">Лиды</span>-->
              <!--</a>-->
              <a routerLink="attraction/lead-info" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Лиды по привлечению</span>
              </a>
              <a routerLink="attraction/status-created" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Создан. <span class="menu__small-item-text">Ожидается заполнение мастера</span></span>
              </a>
              <a routerLink="attraction/status-passed" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Мастер пройден. <span class="menu__small-item-text">Ожидается подтверждение менеджера</span></span>
              </a>
              <a routerLink="attraction/subagents" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Субагенты</span>
              </a>
              <a routerLink="/organisation-search" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Поиск организаций</span>
              </a>
              <a routerLink="/transfer-log" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Переводы субагентов</span>
              </a>
              <a routerLink="/contacts-unreg" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Контакты из обучения</span>
              </a>
            </div>
          </div>

          <div class="menu__item-group"
               routerLinkActive="menu__item-group_active"
               *ngIf="auth.isMenu('reservation_is')"
          >
              <div class="menu__item-icon menu__item-icon_key-exchange" routerLink="reservation/lead-info"></div>
              <div class="menu__item-list">
                <a routerLink="reservation/lead-info" routerLinkActive="menu__item_active"
                   class="menu__item menu__item_collapse">
                  <span class="menu__item-text">Отдел Бронирования</span>
                </a>
                <a routerLink="/reservation/lead-info" routerLinkActive="menu__item_active" class="menu__item">
                  <span class="menu__item-text">Обращения</span>
                </a>
                <a routerLink="reservation/unique" routerLinkActive="menu__item_active" class="menu__item">
                  <span class="menu__item-text">Уникальность</span>
                </a>
                <a routerLink="reservation/views" routerLinkActive="menu__item_active" class="menu__item">
                  <span class="menu__item-text">Просмотры</span>
                </a>
                <!--<a routerLink="reservation/queue" routerLinkActive="menu__item_active" class="menu__item">
                  <span class="menu__item-text">Очереди</span>
                </a>-->
                <!--<a routerLink="reservation/reserve" routerLinkActive="menu__item_active" class="menu__item">-->
                  <!--<span class="menu__item-text">Резервы</span>-->
                <!--</a>-->
                <a routerLink="reservation/booking-info/all" routerLinkActive="menu__item_active" class="menu__item">
                  <span class="menu__item-text">Сделки</span>
                </a>
                <!--<a routerLink="/appeals/list" routerLinkActive="menu__item_active" class="menu__item">
                  <span class="menu__item-text">Обращения</span>
                </a>-->
            </div>
          </div>

          <div class="menu__item-group"
               routerLinkActive="menu__item-group_active"
               *ngIf="auth.isMenu('calendar_flag')"
          >
            <div class="menu__item-icon menu__item-icon_calendar" routerLink="calendar/list"></div>
            <div class="menu__item-list">
              <a routerLink="calendar/list" routerLinkActive="menu__item_active" class="menu__item menu__item_collapse">
                <span class="menu__item-text">Мероприятия</span>
              </a>
              <a routerLink="calendar/invites" class="menu__item" routerLinkActive="menu__item_active">
                    <span class="menu__item-text">Шаблоны</span></a>
              <a routerLink="calendar/teaching" class="menu__item" routerLinkActive="menu__item_active">
                    <span class="menu__item-text">Статистика</span></a>
              <!--
              <a routerLink="calendar/all-events" class="menu__item" routerLinkActive="menu__item_active">
                    <span class="menu__item-text">Мероприятия</span></a>
                    -->
              <a routerLink="calendar/statistic" class="menu__item" routerLinkActive="menu__item_active">
                    <span class="menu__item-text">Статистика план / факт</span></a>
              <a routerLink="calendar/map" class="menu__item" routerLinkActive="menu__item_active">
                    <span class="menu__item-text">Карта ЖК</span></a>
              <a routerLink="calendar/certificate" class="menu__item" routerLinkActive="menu__item_active">
                    <span class="menu__item-text">Сертификаты</span></a>
              <a routerLink="calendar/user/list" class="menu__item" routerLinkActive="menu__item_active">
                    <span class="menu__item-text">Пользователи</span></a>
            </div>
          </div>

          <div class="menu__item-group"
               routerLinkActive="menu__item-group_active"
               *ngIf="auth.isMenu('analyze_is')"
          >
            <div class="menu__item-icon icon menu__item-icon_time-refresh icon-fade" routerLink="analytic/index"></div>
            <div class="menu__item-list">
              <a routerLink="analytic/index" routerLinkActive="menu__item_active"
                 class="menu__item menu__item_collapse">
                <span class="menu__item-text">Анализ</span>
              </a>
              <a routerLink="analytic/index" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Аналитика</span>
              </a>
              <a routerLink="estatet" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Логи интеграции Estatet</span>
              </a>
              <a routerLink="statistic/plugin" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Плагин</span>
              </a>
              <a routerLink="online" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Онлайн - гант</span>
              </a>
              <a routerLink="online/compare" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Онлайн - сводный вид</span>
              </a>
              <a routerLink="reservation/lead" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Бронирование</span>
              </a>
              <a routerLink="subagents-actions" class="menu__item" routerLinkActive="menu__item_active">
                <span class="menu__item-text">Действия субагентов</span>
              </a>
              <a routerLink="est-card-log" class="menu__item" routerLinkActive="menu__item_active"
                 *ngIf="auth.isMenu('est_card_log_is')">
                <span class="menu__item-text">Логи ЭСТ-А-ТЕТ карточки</span>
              </a>

            </div>
          </div>

          <div class="menu__item-group"
               routerLinkActive="menu__item-group_active"
               *ngIf="auth.isMenu('news_is')"
          >
            <div class="menu__item-icon menu__item-icon_ad-module2" routerLink="news/list"></div>
            <div class="menu__item-list">
              <a routerLink="news/list" routerLinkActive="menu__item_active" class="menu__item">
                <span class="menu__item-text">Новости</span>
              </a>
            </div>
          </div>

          <div class="menu__item-group"
               routerLinkActive="menu__item-group_active"
               *ngIf="auth.isMenu('holiday_is')"
          >
            <div class="menu__item-icon menu__item-icon_new-hot-white" routerLink="holiday/list"></div>
            <div class="menu__item-list">
              <a routerLink="holiday/list" routerLinkActive="menu__item_active" class="menu__item">
                <span class="menu__item-text">График работы</span>
              </a>
            </div>
          </div>

          <div class="menu__item-group"
               routerLinkActive="menu__item-group_active"
               *ngIf="auth.isAdmin() || auth.isMenu('email_exception_is') || auth.isMenu('instateka_is')"
          >
            <div class="menu__item-icon icon icon_icons8-settings"
                 routerLink="settings"
            ></div>
            <div class="menu__item-list">
              <a routerLink="settings" routerLinkActive="menu__item_active"
                 class="menu__item menu__item_collapse"
              >
                <span class="menu__item-text">Настройки</span>
              </a>
              <a routerLink="settings/roles" class="menu__item" routerLinkActive="menu__item_active"
                 *ngIf="auth.isAdmin()"
              >
                <span class="menu__item-text">Роли</span></a>
              <a routerLink="email-exception" class="menu__item" routerLinkActive="menu__item_active"
                 *ngIf="auth.isMenu('email_exception_is')"
              >
                <span class="menu__item-text">Email исключения рассылки</span></a>
              <a routerLink="instateka" class="menu__item" routerLinkActive="menu__item_active"
                 *ngIf="auth.isMenu('instateka_is')"
              >
                <span class="menu__item-text">Инстатека</span></a>
              <a routerLink="settings/constraints-consolidate" class="menu__item" routerLinkActive="menu__item_active"
                 *ngIf="auth.isAdmin()"
              >
                <span class="menu__item-text">Ограничения объекты</span></a>
              <a routerLink="settings/constraints" class="menu__item" routerLinkActive="menu__item_active"
                 *ngIf="auth.isAdmin()"
              >
                <span class="menu__item-text">Ограничения субагенты</span></a>
              <a routerLink="processes/reserv" class="menu__item" routerLinkActive="menu__item_active"
                 *ngIf="auth.isAdmin()"
              >
                <span class="menu__item-text">Бизнес-процессы Бронирование</span></a>
              <a routerLink="processes/attraction" class="menu__item" routerLinkActive="menu__item_active"
                 *ngIf="auth.isAdmin()"
              >
                <span class="menu__item-text">Бизнес-процессы Привлечение</span></a>
              <a routerLink="reference" class="menu__item" routerLinkActive="menu__item_active"
                 *ngIf="auth.isAdmin()"
              >
                <span class="menu__item-text">Сотрудники на объектах</span>
              </a>
              <a routerLink="managers" class="menu__item" routerLinkActive="menu__item_active"
                 *ngIf="auth.isAdmin()"
              >
                <span class="menu__item-text">Менеджеры</span>
              </a>
            </div>
          </div>
        </nav>
        </div>
      </div>
      <div class="sidebar__exit" *ngIf="!user.access['is_department']">
        <nav class="menu">
          <div class="menu__item-flat-group">
            <a href="javascript: void(0)" class="menu__item-flat" (click)="onLock()">
              <span class="menu__item-text">
                Блок <i class="menu__item-text-icon menu__item-text-icon_line icon icon_icons8-keyhole_shield"></i>
              </span>
            </a>
            <a href="javascript: void(0)" class="menu__item-flat menu__item-flat_pressed" (click)="logout()">
              <span class="menu__item-text">
                Выход <i class="menu__item-text-icon menu__item-text-icon_line icon icon_icons8-logout_rounded_up"></i>
              </span>
            </a>
          </div>
        </nav>
      </div>
      <div class="sidebar__user">
        <div class="user">
          <div class="user__avatar">
            <img class="user__avatar-img" [src]="user.avatar" alt="" *ngIf="user.avatar">
          </div>
          <a class="user__name text-eclips">
            <ng-container *ngIf="user.name || user.fam">{{user.name}} {{user.fam}}</ng-container>
            <ng-container *ngIf="!user.name">Пользователь</ng-container>
          </a>
        </div>
      </div>
    </section>
</aside>

<ng-container *ngIf="check_production">
  <div class="yellow_warning" [class.hovered]="mouseOvered" (mouseover)="mouseOvered=true">
    Данная версия является тестовой.
  </div>
</ng-container>
