<div class="easydropdown" [ngClass]="{'active': _activeDropdown, 'minusPadding': minusPadding}">
  <div [ngClass]="cssClass" class="wrapper">
    <div class="button button_title" (click)="trigger()">
      <i class="icon icon_Small_Chevron-Down"></i> {{titleText}}
      <ng-container *ngIf="header">
        <ng-container *ngTemplateOutlet="header"></ng-container>
      </ng-container>
    </div>
    <div class="description_scrollbar perfect-scrollbar">
      <!-- [config]="{'wheelPropagation': true, 'suppressScrollX': true, 'scrollIndicators' : true}"-->
      <ng-content></ng-content>
    </div>
  </div>
</div>
