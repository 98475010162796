import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {JsonClientService} from '../../shared/json-client';

@Injectable()
export class RealtyShowLogsResolve {

  private filter_columns = [
    'complex',
    'flat',
    'visible_status',
    'realty_caption',
    'module_source',
    'author_fio',
  ];

  constructor(private jsonRpc: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {

    const prepared_filter = {
      'complex': false,
      'flat': false,
    };
    const prepared_filter_keys = this.filter_columns.filter(x => !!route.parent.queryParams[x]);
    const sort = {};

    prepared_filter_keys.forEach(x => {
      let _x = route.parent.queryParams[x];
      if (_x === 'true' || _x === 'false') {
        _x = _x === 'true';
      }
      prepared_filter[x] = _x;
    });

    if (!!route.queryParams['sort_name']) {
      sort[route.queryParams['sort_name']] = route.queryParams['sort_key'] || 'asc';
    } else {
      sort['date_time'] = 'desc';
    }

    const options = {
      'limit': 50,
      'offset': (+(route.queryParams['page'] || 1) - 1) * 50,
      '_filter': {
        'fields': prepared_filter,
      },
      'sort': sort
    };
    return this.jsonRpc.post('Data_tables.get_log_visible_showcase', options);
  }

}
