import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportXlsxComponent } from './import-xlsx.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { MaterialInputModule } from '../../modules/@material-input/material-input.module';
import { PreloaderModule } from '../../modules/@preloader/preloader.module';
import {ImportXlsxDictResolve, ImportXlsxResolve} from './import-xlsx.resolve';
import {RouterModule} from '@angular/router';
import {PopoverModule} from '../../modules/@popover/popober.module';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
    PreloaderModule,
    RouterModule,
    PopoverModule,
  ],
  declarations: [
    ImportXlsxComponent
  ],
  providers: [
    ImportXlsxResolve,
    ImportXlsxDictResolve,
  ],
})
export class ImportXlsxModule { }
