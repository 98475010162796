<div class="material-datatable-wrapper table-color">
  <app-datatable-headline
    [enable_global_search_string]="enable_global_search_string"
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)"
    [templateRight]="templateRight">
  </app-datatable-headline>
  <div class="table-responsive pb-5" [ngClass]="{'table-responsive': responsive}">
    <table class="table datatable" [ngClass]="cssClass">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             [multisortEvent]="multisortEvent"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>

