import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {OsmService} from './osm.service';

import {OsmMapComponent} from './osm-map/osm-map.component';
import {OsmPlacemarkComponent} from './osm-placemark/osm-placemark.component';
import {OsmClusterComponent} from './osm-cluster/osm-cluster.component';
import {OsmPolygonComponent} from './osm-polygon/osm-polygon.component';
import {OsmCircleComponent} from './osm-circle/osm-circle.component';
import {OsmDrawerComponent} from './osm-drawer/osm-drawer.component';
import {OsmSemicircleComponent} from './osm-semicircle/osm-semicircle.component';
import {OsmSemicircleCombinedComponent} from './osm-semicircle-combined/osm-semicircle-combined.component';
import {OsmMapCopyrightComponent} from './osm-map-copyright/osm-map-copyright.component';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    OsmMapComponent,
    OsmMapCopyrightComponent,
    OsmClusterComponent,
    OsmPlacemarkComponent,
    OsmPolygonComponent,
    OsmCircleComponent,
    OsmDrawerComponent,
    OsmSemicircleComponent,
    OsmSemicircleCombinedComponent,
  ],
  providers: [
    OsmService,
  ],
  exports: [
    OsmMapComponent,
    OsmMapCopyrightComponent,
    OsmClusterComponent,
    OsmPlacemarkComponent,
    OsmPolygonComponent,
    OsmCircleComponent,
    OsmDrawerComponent,
    OsmSemicircleComponent,
    OsmSemicircleCombinedComponent,
  ]
})
export class OsmModule { }
