<ng-container *ngIf="show">
  <div class="modal modal-help d-block" [@slideUp] [class.in]="show" tabindex="-1" role="dialog" [ngClass]="backdropClass">

    <div class="modal-dialog"
         [class.modal-full]="size==='full'"
         [class.modal-xl]="size==='xl'"
         [class.modal-lg]="size==='large'"
         [class.modal-sm]="size==='small'" role="document">
      <div class="modal-content modal-help__content" #content [ngClass]="cssClass">
        <div class="modal-help__question">
          <i class="modal-help__icon icon icon_new-help-graphics"></i>
        </div>
        <div class="modal-body modal-help__body">
          <ng-container>
            <ng-content></ng-content>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop in modal-stack" [@fade]></div>
</ng-container>
