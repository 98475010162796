<div class="form__group">
  <div class="form__label" [class.form__label_require]="required && !disabled" [class.form__label_error]="error">
    <ng-content></ng-content>
  </div>
  <div class="form__input" [class.form__input_error]="error"  [class.form__input_disabled]="disabled">
    <input class="input" [ngClass]="{'md-valid': current_value && (current_value.length || current_value > 0)}"
           [appMaterialInputFiasAddressEventDirective]="'Fias.get_address'" (autocompleteChange)="onChanges($event)" [placeholder]="placeholder"
           [value]="current_value && current_value['label'] && current_value['label'].toString().length  ? current_value['label'] : ''"
           [disabled]="disabled">
  </div>
</div>
