import {Component, ComponentRef, OnInit} from '@angular/core';
import {ConfigService} from '../../../services/configuration.service';
import {HttpClient} from '../../../services/http.service';
import {GoBackButtonService} from '../../../services/go-back-button.service';
import {OverlayWindowService} from '../../single-window/shared/overlay-window.service';
import {Subscription} from 'rxjs';
import {ActionCreateComponent} from '../action-create/action-create.component';
import {FancyboxService} from '../../../shared/fancybox.service';
import {NotificationService} from '../../../services/notification.service';
import {ActionEditComponent} from '../action-edit/action-edit.component';
import {Router} from '@angular/router';

@Component({
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})

export class NewsActionComponent implements OnInit {
  public position = [];
  public ordering = [];
  rowHeight = 107;
  public actions: Array<{}>;
  private _is_archive = false;
  public createSubscription: Subscription;
  public editSubscription: Subscription;
  constructor(private config: ConfigService,
              public http: HttpClient,
              private goBack: GoBackButtonService,
              private overlayWindowService: OverlayWindowService,
              private lightbox: FancyboxService,
              private notify: NotificationService,
              private router: Router) {
  }

  propagateChange = (_: any) => {};

  ngOnInit() {
    this.goBack.header = '<h1>Акции</h1>';
    this.loadAction();
  }

  loadAction() {
    this.http.post('Ordering.get_action_banners').subscribe(
      data => {
        this.actions = data;
        this.refreshPositions();
      },
      error => false
    );
  }

  getPosition(i) {
    return this.position[i];
  }
  onMoving(event, index) {
    const boxHeight = this.rowHeight;
    let replaceIndex = null,
      currentIndex = index,
      isChanged = false;

    this.position.forEach((el, i) => {
      if (event.y < el.y &&
        event.y + boxHeight >= el.y + boxHeight / 2) {
        replaceIndex = i;
      } else if (event.y >= el.y &&
        event.y <= el.y + boxHeight / 2 &&
        event.y + boxHeight >= el.y) {
        replaceIndex = i;
      }

      if (replaceIndex !== null) {
        let isPositive = true;

        const maxIndex = Math.abs(replaceIndex - currentIndex);
        if (replaceIndex - currentIndex < 0) {
          isPositive = false;
        }

        for (let offsetI = 1; offsetI <= maxIndex; offsetI++) {
          if (!isPositive) {
            replaceIndex = currentIndex - offsetI;
          } else {
            replaceIndex = currentIndex + offsetI;
          }

          const tempElem = this.ordering[currentIndex];
          this.ordering[currentIndex] = this.ordering[replaceIndex];
          this.ordering[replaceIndex] = tempElem;
          isChanged = true;

          currentIndex = replaceIndex;
        }
      }
    });

    if (isChanged) {
      const out = [];
      this.actions = this.ordering;
      this.propagateChange(this.actions.map(e => {}));
    }
  }
  refreshPositions() {
    this.ordering = [];
    this.position = [];
    let i = 0;
    this.actions.forEach((el) => {
      this.ordering.push(el);
      this.position.push({
        x: 0,
        y: i * this.rowHeight,
        elemId: el['id'],
      });
      i++;
    });
  }
  createNews() {
    this.overlayWindowService.openComponentInPopup(ActionCreateComponent)
      .then((factory: ComponentRef<ActionCreateComponent>) => {
        this.createSubscription = factory.instance.onCreate.subscribe(_ => this.loadAction());
      });
  }
  displayImage(file_url) {
    this.lightbox.open([{'src': file_url, 'thumb': file_url, 'caption': ''}], 0);
  }

  saveSorting() {
    NotificationService.swalConfirm('Вы уверены?', 'Баннеры акций на субагенстком ЛК будут выводится в заданном порядке. Продолжить?')
      .then(_ => {
        this.http.post('Ordering.set_action_banners', {'data': this.actions})
          .subscribe(
            (_) => this.notify.notifySuccess('Поздравляем', 'Сортировка успешно сохранена', 3000),
            (error) => this.notify.notifyError('Ошибка', 'Не удалось сохранить сортировку', 5000)
          );
      })
      .catch(_ => false);
  }

  editAction(news_item) {
    this.overlayWindowService.openComponentInPopup(ActionEditComponent, news_item)
      .then((factory: ComponentRef<ActionEditComponent>) => {
        this.editSubscription = factory.instance.onEdit.subscribe(_ => this.loadAction());
      });
  }

  archiveAction(id) {
    NotificationService.swalConfirm('Вы уверены?', 'Выбранная акция будет перемещена в архив')
      .then(() => {
        this.http.post('ActionBanners.archive',
          {'id': id}
        ).subscribe(
          (response) => {
            this.notify.notifySuccess('Акция успешно удалена', '', 2000);
            this.loadAction();
          },
          (error) => this.notify.notifyError('Ошибка', error, 5000)
        );
      }).catch(() => false);
  }

  public get is_archive() {
    return this._is_archive;
  }

  public set is_archive(value: any) {
    this._is_archive = !(value === 'false' || value === false);
    if (this._is_archive === false) {
      this.loadAction();
    }
  }
}
