import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { HttpClient } from '../../../services/http.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {BreadcrumbsService} from '../../../shared/breadcrumbs/services';

@Component({
  selector: 'app,yamap-entitie-edit-documents',
  templateUrl: './entity-edit-documents.component.html',
  styleUrls: ['./entity-edit-documents.component.scss']
})
export class EntityEditDocumentsComponent implements OnInit {

  public about: any = null;
  public data: any = null;
  public reglament: any = null;
  public formGroup: UntypedFormGroup;
  public formGroupReglament: UntypedFormGroup;
  public entityId = null;

  constructor(public activatedRoute: ActivatedRoute, public fb: UntypedFormBuilder,
              private http: HttpClient, public notify: NotificationService, private b: BreadcrumbsService) {
  }

  ngOnInit() {
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/entity/list', caption: 'Юридические лица'},
    ]);
    this.about = this.activatedRoute.parent.snapshot.data['about'];
    this.data = this.activatedRoute.snapshot.data['data'];
    this.reglament = this.activatedRoute.snapshot.data['reglament'];
    this.entityId = this.activatedRoute.snapshot.parent.params['id'];
    this.formGroup = this.fb.group({
      'files': [[], Validators.required],
    });
    this.formGroupReglament = this.fb.group({
      'files': [[], Validators.required],
    });

    // Фото
    if (this.data.length) {
      const fileArr = [];
      for (const file of this.data) {
        fileArr.push({'id': file.file_id, 'filename': file.name, 'filepath': file.file});
      }
      this.formGroup.patchValue({
        'files': fileArr,
      });
    }
    // reglament
    if (this.reglament.length) {
      const fileArrReg = [];
      for (const file of this.reglament) {
        fileArrReg.push({'id': file.file_id, 'filename': file.name, 'filepath': file.file});
      }
      this.formGroupReglament.patchValue({
        'files': fileArrReg,
      });
    }
  }

  saveFiles() {
    const names = [];
    const files = [];
    const types = [];
    for (const file of this.formGroup.value.files) {
      names.push(file.filename);
      files.push(file.filepath);
      types.push(1);
    }
    this.http.post('Organisation.attach_files',
      {'organisation_id': this.entityId, 'names': names, 'files': files, 'types': types, 'comments': []}
    ).subscribe(
      (response) => this.onSuccess(response),
      (error) => this.onError(error)
    );
  }

  saveReglament() {
    const names = [];
    const files = [];
    const types = [];
    for (const file of this.formGroupReglament.value.files) {
      names.push(file.filename);
      files.push(file.filepath);
      types.push(37);
    }
    this.http.post('Organisation.attach_files',
      {'organisation_id': this.entityId, 'names': names, 'files': files, 'types': types, 'comments': []}
    ).subscribe(
      (response) => this.onSuccess(response),
      (error) => this.onError(error)
    );
  }

  onSuccess(response) {
    this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
  }

  onError(error) { }

}
