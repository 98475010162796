<h1>{{caption}}</h1>
<div class="text-muted">Тексты на основе этих шаблонов отправляются всем учатникам, когда Вы отправляете им приглашения.
  Ипользуйте переменные
  <a href="javascript:void(0)" (click)="bindPlacemark('Имя')"><span ngNonBindable>{{Имя}}</span></a>,
  <a href="javascript:void(0)" (click)="bindPlacemark('Фамилия')"><span ngNonBindable>{{Фамилия}}</span></a>,
  <a href="javascript:void(0)" (click)="bindPlacemark('Название мероприятия')"><span ngNonBindable>{{Название мероприятия}}</span></a>,
  <!--<a href="javascript:void(0)" (click)="bindPlacemark('Ссылка отказа')"><span ngNonBindable>{{Ссылка отказа}}</span></a>,-->
  <a href="javascript:void(0)" (click)="bindPlacemark('Аннотация мероприятия')"><span ngNonBindable>{{Аннотация мероприятия}}</span></a>,
  <a href="javascript:void(0)" (click)="bindPlacemark('Расширенное описание')"><span ngNonBindable>{{Расширенное описание}}</span></a>,
  <a href="javascript:void(0)" (click)="bindPlacemark('Как добраться')"><span ngNonBindable>{{Как добраться}}</span></a>,
  <a href="javascript:void(0)" (click)="bindPlacemark('Схема проезда')"><span ngNonBindable>{{Схема проезда}}</span></a>,
  <a href="javascript:void(0)" (click)="bindPlacemark('Адрес')"><span ngNonBindable>{{Адрес}}</span></a>,
  <a href="javascript:void(0)" (click)="bindPlacemark('Дата')"><span ngNonBindable>{{Дата}}</span></a>,
  <a href="javascript:void(0)" (click)="bindPlacemark('Время')"><span ngNonBindable>{{Время}}</span></a> и
  <a href="javascript:void(0)" (click)="bindPlacemark('Ссылка')"><span ngNonBindable>{{Ссылка}}</span></a>,
  чтобы в сообщения автоматически вставлялись соотвествующие поля для каждого отдельного участника</div>
<form [formGroup]="form" *ngIf="form" class="form_crm invite-form" novalidate (submit)="onSubmit()">
  <app-material-input formControlName="caption" [require]="true">Полное наименование</app-material-input>
  <app-material-input formControlName="email_subj" [require]="true">Тема e-mail</app-material-input>
  <app-material-textarea formControlName="email_text" [require]="true">Текст e-mail</app-material-textarea>
  <hr>
  <app-material-textarea formControlName="sms_text" [require]="true">Текст SMS</app-material-textarea>
  <p [ngClass]="{'text-danger': target_length > max_sms_char_count}">{{target_length}} / {{max_sms_char_count}} символов</p>
  <button type="submit" class="button" [disabled]="!form.valid">Сохранить и закрыть</button>
</form>
