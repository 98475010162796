import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageSliderComponent} from './page-slider.component';
import {PageSliderElementComponent} from './page-slider-element/page-slider-element.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    PageSliderComponent,
    PageSliderElementComponent,
  ],
  exports: [
    PageSliderComponent,
    PageSliderElementComponent,
  ]
})
export class PageSliderModule { }
