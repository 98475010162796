import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '../../../services/http.service';
import {BreadcrumbsService} from '../../../shared/breadcrumbs/services';

@Component({
  selector: 'app,yamap-physical-edit-photo',
  templateUrl: './physical-edit-photo.component.html',
  styleUrls: ['./physical-edit-photo.component.scss']
})
export class PhysicalEditPhotoComponent implements OnInit {

  public data: any = null;
  public formGroup: UntypedFormGroup;
  public physicalId = null;

  constructor(public activeRoute: ActivatedRoute, public fb: UntypedFormBuilder, public http: HttpClient,
              public notify: NotificationService, private b: BreadcrumbsService) { }

  ngOnInit() {
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/physical/list', caption: 'Физические лица'},
    ]);
    this.data = this.activeRoute.snapshot.data['data'];
    this.physicalId = this.activeRoute.snapshot.parent.params['id'];
    this.formGroup = this.fb.group({
      'file': [null, Validators.required],
    });
    if (this.data.length) {
      let file = this.data[0];
      let file_arr = {
        'id': file.file_id,
        'filename': file.name,
        'comment': file.comment,
        'filepath': file.file
      };
      this.formGroup.patchValue({
        'file': file_arr,
      });
    }
  }

  saveForm() {
    if (!this.formGroup.valid) {
      this.onError('Загрузите фотографию');
      return false;
    }
    let new_file = this.formGroup.value.file;
    this.http.post('Files.attach_exists',
        {'attach_to': ['physical', +this.physicalId], 'names': [new_file.filename], 'files': [new_file.filepath], 'types': [17]}
      ).subscribe(
        (response) => {
          let file_arr = this.formGroup.value.file;
          file_arr['id'] = response.shift().file_id;
          this.formGroup.patchValue({
            'file': file_arr,
          });
          this.onSuccess(response);
        },
        (error) => false
      );
  }

  onSuccess(response) {
    this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
  }

  onError(error) {
    this.notify.notifyError('Ошибка', error, 5000);
  }

}
