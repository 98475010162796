
<div class="row">
  <div class="col-md-6">
    <app-g-charts-donut [data]="chart_organisations" (onSelect)="chartCompanySelection($event)">
    </app-g-charts-donut>
  </div>
  <div class="col-md-6">
    <app-g-charts-donut [data]="chart_users" (onSelect)="chartCompanySelection($event)" [color]="'medium_revert'">
    </app-g-charts-donut>
  </div>
</div>
<br>
<div class="row teaching">
  <div class="col-lg-3">
    <p class="teaching__digits">{{statistic['events_all']}} / {{statistic['events_last_month']}}</p>
    <p class="teaching__legend">Обучений проведено</p>
    <p class="teaching__legend_mute">всего / последний месяц</p>
  </div>
  <div class="col-lg-3">
    <p class="teaching__digits">{{statistic['users_teached_all']}} / {{statistic['users_teached_last_month']}}</p>
    <p class="teaching__legend">Пользователей обучено</p>
    <p class="teaching__legend_mute">всего / последний месяц</p>
  </div>
  <div class="col-lg-3">
    <p class="teaching__digits">{{statistic['users_teached_all_perc']}}% / {{statistic['users_teached_last_month_perc']}}%</p>
    <p class="teaching__legend">Пользователей обучено</p>
    <p class="teaching__legend_mute">всего / последний месяц</p>
  </div>
  <div class="col-lg-3">
    <p class="teaching__digits">{{statistic['users_teached_avg_by_organisation']}}%</p>
    <p class="teaching__legend">Обученных пользователей</p>
    <p class="teaching__legend_mute">по организации, в среднем</p>
  </div>
</div>
<div class="table-wrapper">
  <div class="table-wrapper__component">
    <h2>Новые организации, за последние 30 дней</h2>
    <p><a [href]="api_hostname + '/restful/calendar_last_30_days'" target="_blank" class="button">Экспортировать</a></p>
    <app-material-table [api_action]="'Calendar.calendar_last_30_days'" [columns]="columns1">
    </app-material-table>
  </div>
  <div class="table-wrapper__component">
    <h2>Организации с необученными пользователями</h2>
    <p><a [href]="api_hostname + '/restful/calendar_noteach_users'" target="_blank" class="button">Экспортировать</a></p>
    <app-material-table [api_action]="'Calendar.dt_noteach_users'" [columns]="columns2">
    </app-material-table>
  </div>
</div>

<ng-template #staffList let-row="row">
  <a href="javascript:void(0)" *ngIf="row['cnt_staff']" (click)="displayStaff(row)">{{row['cnt_staff']}}</a>
  <span *ngIf="!row['cnt_staff']">{{row['cnt_staff']}}</span>
</ng-template>

<ng-template #teachedList let-row="row">
  <a href="javascript:void(0)" *ngIf="row['cnt_approve']" (click)="displayTeached(row)">{{row['cnt_approve']}}</a>
  <span *ngIf="!row['cnt_approve']">{{row['cnt_approve']}}</span>
</ng-template>

<ng-template #refusalList let-row="row">
  <a href="javascript:void(0)" *ngIf="row['cnt_refusal']" (click)="displayRefusal(row)">{{row['cnt_refusal']}}</a>
  <span *ngIf="!row['cnt_refusal']">{{row['cnt_refusal']}}</span>
</ng-template>
