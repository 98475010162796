<div class="row">
  <div  class="col-6 mt-2">
    <app-material-input-checkbox [(ngModel)]="is_archive" [cssClass]="'mb-0'" [border]="true">
      В архиве
    </app-material-input-checkbox>
  </div>
  <div class="col-6 mt-2 d-flex flex-row-reverse" *ngIf="!is_archive">
    <button type="button" class="button button_sm" (click)="createNews()">
      Создать<span class="d-none d-md-inline"> акцию</span>
    </button>
  </div>
</div>
<div class="mb-4 min-height-auto" *ngIf="actions && actions.length && !is_archive">
  <div class="wrapper" [ngStyle]="{'min-height' : position.length * rowHeight + 'px'}">
    <div class="wrapper__row_drag"
         *ngFor="let action of actions; let i = index"
         ngDraggable
         [preventDefaultEvent]="true"
         [zIndexMoving]="'9999'"
         [trackPosition]="false"
         [position]="getPosition(i)"
         (movingOffset)="onMoving($event, i)"
         [handle]="sorthandle"
    >
      <div class="handle" #sorthandle>
        <i class="fa fa-th" aria-hidden="true"></i>
      </div>
      <div class="row flex-grow-1">
        <div class="col-auto d-flex align-items-center">
          <div  (click)="displayImage(action['icon'][0]['url'])" class = "icon_block"
                [style.background-image]="'url('+action['icon'][0]['url']+')'">
          </div>
        </div>
        <div class="col-auto">
          <div  (click)="displayImage(action['banner'][0]['url'])" class = "banner_block"
                [style.background-image]="'url('+action['banner'][0]['url']+')'">
          </div>
        </div>
        <div class="col-auto d-flex align-items-center">
          <span>
            {{action['title']}} <br> Создана: {{action['create_time']}} <br> Автор: {{action['author']}}
          </span>
        </div>
      </div>
      <div class="text-right">
        <div class="button button_icon" (click)="editAction(action)">
          <i class="fa fa-pencil"></i>
        </div>
      </div>
      <div class="ml-2 text-right" (click)="true">
        <div class="button button_icon" (click)="archiveAction(action['id'])">
          <i class="fa fa-trash"></i>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-6 mt-4 p-0" *ngIf="!is_archive">
  <button type="button" class="button button_sm" (click)="saveSorting()">
    Сохранить<span class="d-none d-md-inline"> сортировку</span>
  </button>
</div>
<div class="mt-2 p-0" *ngIf="is_archive">
  <app-action-archive></app-action-archive>
</div>
