import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HttpClient } from '../../services/http.service';
import { Subscription } from 'rxjs';
import { DataTableParamComponent } from '../../modules/@datatable/components/datatable-param.component';
import { BackrefWindowService } from '../single-window/shared/backref-window.service';
import { debounceTime } from 'rxjs/operators';
import {GoBackButtonService} from '../../services/go-back-button.service';

@Component({
  selector: 'app-subagents-actions',
  templateUrl: './subagents-actions.component.html',
  styleUrls: ['./subagents-actions.component.scss']
})
export class SubagentsActionsComponent extends DataTableParamComponent implements OnInit, OnDestroy {

  public api_action = 'Data_tables.subagents_actions';
  public columns: Array<{ [key: string]: any }>;
  public formGroup: UntypedFormGroup;
  public createSubscription: Subscription;
  public subscription: Subscription;
  private firstLoad = true;
  @ViewChild('subagentTmpl', {static: true}) private subagentTmpl: TemplateRef<any>;
  @ViewChild('authorTmpl', {static: true}) private authorTmpl: TemplateRef<any>;
  @ViewChild('IdHead', {static: true}) private IdHead: TemplateRef<any>;
  @ViewChild('subagentHead', {static: true}) private subagentHead: TemplateRef<any>;
  @ViewChild('actionHead', {static: true}) private actionHead: TemplateRef<any>;
  @ViewChild('authorHead', {static: true}) private authorHead: TemplateRef<any>;
  @ViewChild('timeHead', {static: true}) private timeHead: TemplateRef<any>;


  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router, private goBack: GoBackButtonService,
              public fb: UntypedFormBuilder, public backrefWindowService: BackrefWindowService) {
    super(http, route, router);
    this.formGroup = this.fb.group({
      'id': [''],
      'subagent': [''],
      'type': [''],
      'author_fio': [''],
      'create_time': [''],
    });
    this.subscription = this.formGroup.valueChanges.pipe(debounceTime(500))
      .subscribe((data) => {
        this.router_param['page'] = 1;
        this.current_page = 1;
        if (this.firstLoad) {
          this.firstLoad = false;
        } else {
          this.changeForm(this.formGroup);
        }
      });
    this.updateForm(this.formGroup);
    this.backrefWindowService.routerInit();
  }

  ngOnInit() {
    this.goBack.header = '<h1>Действия субагентов</h1>';
    this.columns = [
      {'caption': '№', 'name': 'id', 'sortable': true, 'width': '90px',
        'templateHead': this.IdHead},
      {'caption': 'Субагент', 'name': 'subagent', 'sortable': true,
        'template': this.subagentTmpl, 'templateHead': this.subagentHead},
      {'caption': 'Действие', 'name': 'type', 'sortable': true,
        'templateHead': this.actionHead},
      {'caption': 'Автор', 'name': 'author_fio', 'sortable': true,
        'template': this.authorTmpl, 'templateHead': this.authorHead},
      {'caption': 'Дата', 'name': 'create_time', 'sortable': true,
        'templateHead': this.timeHead},
    ];
    super.ngOnInit();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.createSubscription) {
      this.createSubscription.unsubscribe();
    }
    this.backrefWindowService.routerDestroy();
  }

  prepareQuery() {
    const result = super.prepareQuery();
    result['filter']['fields'] = {
      'id': this.formGroup.value.id,
      'subagent': this.formGroup.value.subagent,
      'type': this.formGroup.value.type,
      'author_fio': this.formGroup.value.author_fio,
      'create_time': this.formGroup.value.create_time,
    };

    return result;
  }

}
