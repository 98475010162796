import {NgModule} from '@angular/core';
import {CrmDashboardComponent} from './crm-dashboard.component';
import {RouterModule} from '@angular/router';
import {CrmDashboardResolve} from './crm-dashboard.resolve';

@NgModule({
  imports: [
    RouterModule,
  ],
  declarations: [
    CrmDashboardComponent,
  ],
  exports: [
    CrmDashboardComponent,
  ],
  providers: [
    CrmDashboardResolve
  ]
})
export class CrmDashboardModule {

}
