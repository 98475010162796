import { EventEmitter, Injectable } from '@angular/core';
import { interval ,  Subscription } from 'rxjs';
import { HttpClient } from '../../../services/http.service';

@Injectable()
export class WebinarService {

  public qst_obj_is_finished = null;
  public holidayBanner = null;
  private _status = false;
  private _online = false;
  private _caption = 'Вебинар';
  public statusEmit: EventEmitter<any> = new EventEmitter();
  public onlineEmit: EventEmitter<any> = new EventEmitter();
  public subscription: Subscription;
  public startOfSalesCount = 0;

  get status() {
    return this._status;
  }

  set status(value: boolean) {
    this._status = value;
    this.statusEmit.emit(value);
  }

  get online() {
    return this._online;
  }

  set online(value: boolean) {
    this._online = value;
    this.onlineEmit.emit(value);
  }

  get caption() {
    return this._caption;
  }

  set caption(value: string) {
    this._caption = value;
    this.onlineEmit.emit(value);
  }

  constructor(public http: HttpClient) {
  }

  init() {
    this.checkStatus();
    const myObservable = interval(60000);
    this.subscription = myObservable.subscribe(response => this.checkStatus());
  }

  public checkStatus() {
    this.http.post('Webinar.status').subscribe(data => {
      this.online = data['online'];
      this.status = data['status'];
      this.caption = data['caption'];
      this.qst_obj_is_finished = data['qst_obj_is_finished'];
      this.holidayBanner = data['holiday_banner'];
      this.startOfSalesCount = data['start_of_sales_count'];
    }, error => {
      this.online = false;
      this.status = false;
      this.caption = 'Вебинар';
    });
  }


  destroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
