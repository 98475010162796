<div class="row align-items-center form_crm">
  <div class="col-md-12">
    <app-analytic-filter
      [base_url]="base_url"
      [lock_type]="url_segment">
    </app-analytic-filter>
  </div>
</div>

<div class="d-block">
  <div class="row">
    <div class="col-12 chart-holder" *ngFor="let data of lineChartData" [ngClass]="data.class ? data.class : ''">
      <h4 class="mb-3">{{data.caption}}</h4>
      <canvas baseChart width="100%"
              [datasets]="data.value"
              [labels]="data.labels"
              [options]="data.options"
              [colors]="data.colors ? data.colors : null"
              [legend]="lineChartLegend"
              [chartType]="data.type"></canvas>
    </div>
  </div>
</div>
