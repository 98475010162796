import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { HttpClient } from '../../../services/http.service';
import { OverlayWindowService } from '../../single-window/shared/overlay-window.service';
import { NotificationService } from '../../../services/notification.service';
import {JsonClientService} from '../../../shared/json-client';
import {DictionariesService} from '../../../services/dictionaries.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app,yamap-entity-create',
  templateUrl: './entity-create.component.html',
  styleUrls: ['./entity-create.component.scss']
})
export class EntityCreateComponent implements OnInit, OnDestroy {

  public title = 'Создание юридического лица';

  @Input() public  forms: any;
  @Input() public  types: any;
  @Input() public displayHeader = true;
  @Output() public onCreate: EventEmitter<any> = new EventEmitter<any>();

  public form: UntypedFormGroup;
  public data: any;
  public entityRequisitesData: any;
  public nalogForms: any = null;
  public subscription: Subscription;

  constructor(private http: HttpClient, private overlayWindowService: OverlayWindowService,
              private fb: UntypedFormBuilder, private notify: NotificationService, private dict: DictionariesService,
              private j: JsonClientService) {
  }

  ngOnInit() {
    this.form = this.fb.group({});
    this.form.addControl('requisites', new UntypedFormGroup({}));
    this.dict.get('Organisation.get_type_nalog').then(x => this.nalogForms = x);
    this.formSubscription();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  formSubscription() {
    this.subscription = this.form.valueChanges.subscribe(val => {
      if (val.organisation_type_id === '4') {
        this.subscription.unsubscribe();
        this.form.setControl('requisites', new UntypedFormGroup({}));
        this.formSubscription();
      }
    });
  }

  onSubmit() {
    const data = {
      'data': ''
    };
    if (this.form.get('organisation_type_id').value === '4') {
      data.data = Object.assign({}, this.data);
    } else {
      data.data = Object.assign({}, this.data, this.entityRequisitesData);
    }
    this.http.post('Organisation.organisation_create', data).subscribe(
      response => {
        this.notify.notifySuccess('Юридическое лицо успешно создано', this.form.value.organisation_socrachcaption,
          5000, '/entity/edit/' + response);
        this.onCreate.emit({'value': response, 'description': this.form.value.organisation_caption});
        this.overlayWindowService.closeHost();
      },
      error => {
        if (error.error.error.name === 'InvalidUsage' || error.error.error.name === 'InvalidUsageWithoutSentry') {
          this.form.get('organisation_phone').setValue('');
          this.notify.notifyError('Ошибка', error.error.error.data.clear_message, 5000);
        } else {
          this.notify.notifyError('Ошибка', error, 5000);
        }
      }
    );
  }

}
