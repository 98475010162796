import {Component, Host, OnInit} from '@angular/core';
import {CreatePhysicalAltComponent} from '../create-physical.component';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-alt-physical-common',
  templateUrl: './create-physical-common.component.html',
  styleUrls: [
    './create-physical-common.component.scss'
  ]
})
export class CreatePhysicalAltCommonComponent implements OnInit {

  public form: UntypedFormGroup;

  public get phones(): UntypedFormArray {
    return this.form.get('common').get('physical_phones') as UntypedFormArray;
  }

  constructor(@Host() private hostComponent: CreatePhysicalAltComponent,
              private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.form = this.hostComponent.form;

    this.hostComponent.form.addControl('common', this.fb.group({
      'physical_data_rozhd': [''],
      'physical_email': [''],
      'physical_fam': ['', Validators.required],
      'physical_gender': [''],
      'physical_name': ['', Validators.required],
      'physical_otch': ['', Validators.required],
      'physical_phones': this.fb.array([]),
    }));
  }

  appendPhone() {
    this.phones.push(this.fb.group({
      'phone': ['', Validators.required],
      'is_main': [false],
    }));
  }

  deletePhone(index) {
    this.phones.removeAt(index);
  }

  toggleMainPhone(index) {
    /*
     * Main phone handler
     */
    this.phones.controls.forEach(c => c.patchValue({
      'is_main': false,
    }));

    this.phones.controls[index].patchValue({
      'is_main': !this.phones.controls[index].value['is_main'],
    });
  }

}
