import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {HttpClient} from "../../services/http.service";
import {TransferServices} from "./transfer.services";
import {NotificationService} from "../../services/notification.service";
import {GoBackButtonService} from "../../services/go-back-button.service";

@Component({
  selector: 'transfer',
  templateUrl: './transfer-physical.component.html',
  styleUrls: ['./transfer-physical.component.css']
})
export class TransferPhysicalComponent implements OnInit {

  public form: UntypedFormGroup;
  public physicals: any = null;
  public physical_id: any = null;

  constructor(private activeRoute: ActivatedRoute,
              private http: HttpClient,
              private s: TransferServices,
              private router: Router,
              private fb: UntypedFormBuilder,
              private notify: NotificationService,
              public goBack: GoBackButtonService) {

  }

  ngOnInit() {

    this.goBack.header= '<h3>Перенос сотрудника между субагентами</h3>';
    this.physicals = this.activeRoute.snapshot.data['physicals'];
    this.physical_id = +this.activeRoute.snapshot.params['id'];
    this.form = this.fb.group({
      'new_subagent_id': [null, Validators.required],
      'new_subagent_caption': [null, Validators.required],
      'user_transfer_id': [null, Validators.required],
      'user_transfer_fio': [null, Validators.required],
    });

  }

  transferUser() {

    this.s.transferUserSubagent(+this.physical_id,
      +this.form.value.user_transfer_id,
      +this.form.value.new_subagent_id).then(
      (response) => {
        this.notify.notifySuccess('OK', 'Пользователь успешно перенесён', 2000);
        this.router.navigate(['physical', 'edit',
          this.activeRoute.snapshot.params['id'], 'organization'])
      },
      (error) => { }
    );
  }
}
