import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient} from '../../../services/http.service';

@Injectable()
export class PhysicalAboutResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Physical.get_physical_about', {'physical_id': +route.params['id']});
  }
}

@Injectable()
export class PhysicalCommonResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Physical.get_physical_common', {'physical_id': +route.parent.params['id']});
  }
}

@Injectable()
export class PhysicalAdditionallyResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Physical.get_physical_additionally', {'physical_id': +route.parent.params['id']});
  }
}

@Injectable()
export class PhysicalOrganizationResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Physical.get_physical_organisation', {'physical_id': +route.parent.params['id']});
  }
}

@Injectable()
export class PhysicalUserResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Physical.get_physical_user', {'physical_id': +route.parent.params['id']});
  }
}

@Injectable()
export class PhysicalPhotoResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Files.get_list_to_type', {'owner': ['physical', +route.parent.params['id'], 17]});
  }
}

@Injectable()
export class PhysicalDocumentsResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Files.get_list_to_type', {'owner': ['physical', +route.parent.params['id'], 1]});
  }
}
