import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {HttpClient} from '../../services/http.service';

@Injectable()
export class OnlineResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const time = route.params['modification'] === 'fullday'
        ? {start: '00:00', stop: '23:59'}
        : {start: '08:00', stop: '20:00'},
      {start, stop} = time,
      options = {'date': route.params['date'] || '', 'time_start': start, 'time_end': stop};

    return this.http.post('History.view_online', options);
  }

}
