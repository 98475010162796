import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {JsonClientService} from '../../../../shared/json-client';

declare const moment;

@Injectable()
export class GetRealtySummaryTableResolve {

  public readonly filter_columns = [
    'realty_caption',
  ];
  constructor(private j: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {

    const prepared_filter = {};
    const prepared_filter_keys = this.filter_columns.filter(x => !!route.parent.queryParams[x]);
    const sort = {};

    prepared_filter_keys.forEach(x => prepared_filter[x] = route.parent.queryParams[x]);

    if (!!route.queryParams['sort_name']) {
      sort[route.queryParams['sort_name']] = route.queryParams['sort_key'] || 'asc';
    }

    const options = {
      'limit': 20,
      'offset': (+(route.queryParams['page'] || 1) - 1) * 20,
      'query_type': route.queryParams['query_type'],
      'date_end': route.queryParams['date_end'] || moment()
        .endOf('month').format('DD.MM.YYYY'),
    };

    return this.j.post('Data_tables.' + route.queryParams['method'], options);
  }

}
