<form class="form form_crm" [formGroup]="form" novalidate *ngIf="form && types && forms && managers">
  <div class="row" formGroupName="common">
    <div class="col-6">
      <app-material-select formControlName="organisation_type_id" [require]="false" [caption]="'Тип лица'">
        <option [value]="''">-- Не выбрано --</option>
        <option *ngFor="let type of types" [value]="type.organisation_types_id">
          {{type.organisation_types_caption}}
        </option>
      </app-material-select>
      <app-material-input formControlName="organisation_caption" [require]="true" [placeholder]="'Наименование организации'">
        Наименование организации
      </app-material-input>
      <app-material-input formControlName="organisation_socrachcaption" [placeholder]="'Сокращенное наименование организации'">
        Сокращенное наименование организации
      </app-material-input>
      <app-material-input formControlName="organisation_rekl_naz_org" [placeholder]="'Рекламное название организации'">
        Рекламное название организации
      </app-material-input>
      <app-material-select formControlName="organisation_form_id" [require]="false" [caption]="'Форма предприятия'">
        <option [value]="''">-- Не выбрано --</option>
        <option *ngFor="let form of forms" [value]="form.organisation_form_id">
          {{form.organisation_form_caption}}
        </option>
      </app-material-select>
      <app-material-input formControlName="organisation_address" [require]="true" [placeholder]="'Юридический адрес'">
        Юридический адрес
      </app-material-input>
      <app-material-input formControlName="organisation_fact_addr" [placeholder]="'Фактический адрес'">
        Фактический адрес
      </app-material-input>
      <app-material-input formControlName="organisation_post_address" [placeholder]="'Почтовый адрес'">
        Почтовый адрес
      </app-material-input>
      <app-material-input formControlName="organisation_gendir_fio" [placeholder]="'ФИО директора'">
        ФИО директора
      </app-material-input>
      <app-material-input formControlName="organisation_dolj_ryk" [placeholder]="'Должность руководителя'">
        Должность руководителя
      </app-material-input>
      <app-material-input formControlName="organisation_osn_dolj" [placeholder]="'Основание должности'">
        Основание должности
      </app-material-input>
      <app-material-input formControlName="organisation_glbuh_fio" [placeholder]="'ФИО гл. бухгалтера'">
        ФИО гл. бухгалтера
      </app-material-input>
    </div>
    <div class="col-6">
      <app-material-input-checkbox
        *ngIf="form.get('organisation_type_id').value === '4'"
        formControlName="organisation_overall_unique_is"
      >
        Общая проверка на уникальность всех ЖК данного застройщика
      </app-material-input-checkbox>
      <app-material-input formControlName="organisation_email" [placeholder]="'Email'">
        Email
      </app-material-input>
      <app-material-input formControlName="organisation_rekl_email" [placeholder]="'Email для рекламы'">
        Email для рекламы
      </app-material-input>
      <app-material-input-mask formControlName="organisation_rekl_tel" [mask]="'+7 (000) 000-00-00'"
                               [placeholder]="'Телефон для рекламы'">
        Телефон для рекламы
      </app-material-input-mask>
      <app-material-input formControlName="organisation_website" [placeholder]="'Сайт организации'">
        Сайт организации
      </app-material-input>
      <app-material-textarea formControlName="organisation_notes" [placeholder]="'Заметки'">
        Заметки
      </app-material-textarea>
      <app-material-select2
        formControlName="organisation_manager_id"
        [require]="false"
        [caption]="'Персональный менеджер'"
        [placeholder]="'-- Не выбрано --'">
        <option [value]="''">-- Не выбрано --</option>
        <option *ngFor="let manager of managers" [value]="manager.manager_id">
          {{manager.manager_fio}}
        </option>
      </app-material-select2>
      <p class="caption-sm mt-4">Телефоны</p>

      <div formArrayName="organisation_phones"
           *ngFor="let item of phones.controls; index as i;">
        <div [formGroupName]="i" class="physical_phones">
          <label class="form__checkbox form__checkbox_icon_done_mark" (click)="toggleMainPhone(i)">
            <input type="checkbox" [checked]="item.value['is_main']">
            <span class="form__checkbox__wrapper">
              <span class="form__checkbox__wrapper__toggler icon"></span>
            </span>
          </label>
          <app-material-input-mask
            [mask]="'+7 (000) 000-00-00'"
            [placeholder]="'+7 (___) ___-__-__'"
            formControlName="phone">
          </app-material-input-mask>
          <a class="ml-3 button button_icon button_icon_sub" (click)="deletePhone(i)">
            <i class="fa fa-trash-o c-white"></i>
          </a>
        </div>
      </div>

      <p><a href="javascript:void(0)" (click)="appendPhone()">Добавить телефон</a></p>
    </div>
  </div>
</form>
