import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SimpleTableComponent} from '../../../modules/@simple-table/simple-table.component';
import {HttpClient} from '../../../services/http.service';
import {OverlayWindowService} from '../../../_crm/single-window/shared/overlay-window.service';

@Component({
  templateUrl: './calendar-create-invites.component.html',
  styleUrls: ['./calendar-create-invites.component.scss']
})
export class CalendarCreateInvitesComponent implements OnInit {

  private readonly limit = 15;

  private _rowsCount: number;
  private _searchString: string;

  private currentPage = 0;

  public rows: Array<{[p: string]: string | number}> = [];
  public invitesUsers: Array<{[p: string]: string | number}> = [];

  @ViewChild(SimpleTableComponent, {static: true}) private table: SimpleTableComponent;
  @Input() public event_data;
  @Output() public invitesEmitter: EventEmitter<any> = new EventEmitter();

  public get rowsCount(): number {
    return this._rowsCount;
  }

  public set rowsCount(value: number) {
    this._rowsCount = value;
    this.table.setTotalRows(value);
  }

  public get searchString(): string {
    return this._searchString;
  }

  public set searchString(value: string) {
    this._searchString = value;
    this.currentPage = 0;
    this.loadList();
  }

  constructor(private http: HttpClient, private overlayWindowService: OverlayWindowService) { }

  ngOnInit() {
    this.loadList();
  }

  loadList() {
    const excluded = this.invitesUsers.map(x => x['physical_id']),
      options = {'limit': this.limit, 'offset': this.currentPage * this.limit,
      'filter': {'global_search_string': this.searchString}, 'sort': {}, 'excluded': excluded};

    this.http.post('Search.dt_subagents', options).subscribe(x => {
      this.rows = x.rows;
      this.rowsCount = x.count;
    });
  }

  onPageSet(page) {
    this.currentPage = page;
    this.loadList();
  }

  append(row) {
    const index = this.invitesUsers.findIndex(x => x.physical_id === row.physical_id);

    if (-1 !== index) {
      return;
    }

    this.invitesUsers.push(row);
    this.loadList();
  }

  dropInvite(row) {
    const index = this.invitesUsers.findIndex(x => x.physical_id === row.physical_id);

    if (-1 === index) {
      return;
    }

    this.invitesUsers.splice(index, 1);
    this.loadList();
  }

  submit() {
    this.invitesEmitter.emit(this.invitesUsers);
    this.overlayWindowService.closeHost();
  }

}
