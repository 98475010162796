import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialInputModule } from '../../modules/@material-input';
import {EstCardLOgComponent} from './est-card-log.component';
import {ModalModule} from '../../modules/@modal';
import {PaginatorModule} from '../../shared/housing-modules/modules';
import {PopoverModule} from '../../modules/@popover/popober.module';
import {RouterModule} from '@angular/router';
import {EstCardLOgResolve} from './est-card-log.resolve';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialInputModule,
    FormsModule,
    PaginatorModule,
    ReactiveFormsModule,
    ModalModule,
    PopoverModule,
  ],
  declarations: [
    EstCardLOgComponent,
  ],
  exports: [
    EstCardLOgComponent
  ],
  providers: [
    EstCardLOgResolve,
  ]
})
export class EstCardModule {
}
