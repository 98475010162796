import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import { HttpClient } from '../../../../services/http.service';
import { OverlayWindowService } from '../../../single-window/shared/overlay-window.service';
import { NotificationService } from '../../../../services/notification.service';
import {JsonClientService} from '../../../../shared/json-client';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-entity-additionally-fields',
  templateUrl: './entity-additionally-fields.component.html',
  styleUrls: ['./entity-additionally-fields.component.scss']
})
export class EntityAdditionallyFieldsComponent implements OnInit {

  @Input('data') public  data: any = null;
  @Input('nalogForms') public  nalogForms: any;
  @Input('form') public  form: any;

  @Output('formChange') public formChange: EventEmitter<any> = new EventEmitter<any>();
  @Output('postDataChange') public postDataChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private http: HttpClient, private overlayWindowService: OverlayWindowService,
              private fb: UntypedFormBuilder, private j: JsonClientService) {
  }
  ngOnInit() {
    if (this.data) {
      this.form.addControl('organisation_bank_bik', this.fb.control(this.data['organisation_bank_bik'], Validators.required));
      this.form.addControl('organisation_bank_scheta', this.fb.control(this.data['organisation_bank_scheta'], Validators.required));
      this.form.addControl('organisation_inn', this.fb.control(this.data['organisation_inn'], Validators.required));
      this.form.addControl('organisation_kor_schet', this.fb.control(this.data['organisation_kor_schet'], Validators.required));
      this.form.addControl('organisation_kpp', this.fb.control(this.data['organisation_kpp']));
      this.form.addControl('organisation_ogrn', this.fb.control(this.data['organisation_ogrn']));
      this.form.addControl('organisation_okpo', this.fb.control(this.data['organisation_okpo']));
      this.form.addControl('organisation_okved', this.fb.control(this.data['organisation_okved']));
      this.form.addControl('organisation_rachetniy_schet', this.fb.control(this.data['organisation_rachetniy_schet'], Validators.required));
      this.form.addControl('organisation_register_date', this.fb.control(this.data['organisation_register_date'], Validators.required));
      this.form.addControl('organisation_tax_id', this.fb.control((this.data['organisation_tax_id']) ? '' + this.data['organisation_tax_id'] : '', Validators.required));
      this.form.addControl('organisation_who_register', this.fb.control(this.data['organisation_who_register'], Validators.required));
    } else {
      this.form.addControl('organisation_bank_bik', this.fb.control('', Validators.required));
      this.form.addControl('organisation_bank_scheta', this.fb.control('', Validators.required));
      this.form.addControl('organisation_inn', this.fb.control('', Validators.required));
      this.form.addControl('organisation_kor_schet', this.fb.control('', Validators.required));
      this.form.addControl('organisation_kpp', this.fb.control(''));
      this.form.addControl('organisation_ogrn', this.fb.control(''));
      this.form.addControl('organisation_okpo', this.fb.control(''));
      this.form.addControl('organisation_okved', this.fb.control(''));
      this.form.addControl('organisation_rachetniy_schet', this.fb.control('', Validators.required));
      this.form.addControl('organisation_register_date', this.fb.control('', Validators.required));
      this.form.addControl('organisation_tax_id', this.fb.control('', Validators.required));
      this.form.addControl('organisation_who_register', this.fb.control('', Validators.required));
    }
    this.formChange.next(this.form);

    this.postDataChange.next(this.prepareData());
    this.form.valueChanges.subscribe((value) => {
      this.postDataChange.next(this.prepareData());
      this.formChange.next(this.form);
    });
  }

  prepareData() {
    const data = {
      'organisation_inn': this.form.get('organisation_inn').value,
      'organisation_ogrn': this.form.get('organisation_ogrn').value,
      'organisation_who_register': this.form.get('organisation_who_register').value,
      'organisation_register_date': this.form.get('organisation_register_date').value,
      'organisation_kpp': this.form.get('organisation_kpp').value,
      'organisation_okpo': this.form.get('organisation_okpo').value,
      'organisation_okved': this.form.get('organisation_okved').value,
      'organisation_bank_bik': this.form.get('organisation_bank_bik').value,
      'organisation_bank_scheta': this.form.get('organisation_bank_scheta').value,
      'organisation_rachetniy_schet': this.form.get('organisation_rachetniy_schet').value,
      'organisation_kor_schet': this.form.get('organisation_kor_schet').value,
      'organisation_tax_id': +this.form.get('organisation_tax_id').value,
    };
    return data;
  }
}
