<form [formGroup]="form">
  <div formGroupName="common" class="row">
    <div class="col-6">
      <app-material-input formControlName="physical_fam" [placeholder]="'Фамилия'" [require]="true">Фамилия
      </app-material-input>
      <app-material-input formControlName="physical_name" [placeholder]="'Имя'" [require]="true">Имя
      </app-material-input>
      <app-material-input formControlName="physical_otch" [placeholder]="'Отчество'" [require]="true">Отчество
      </app-material-input>
      <app-material-input-datepicker formControlName="physical_data_rozhd" [require]="false">
        Дата рождения
      </app-material-input-datepicker>
      <app-material-input formControlName="physical_email" [placeholder]="'Email'" [require]="false">
        Email
      </app-material-input>
      <app-material-select formControlName="physical_gender" [require]="false" [caption]="'Пол'">
        <option [value]="''">-- Не выбрано --</option>
        <option [value]="'m'">Мужчина</option>
        <option [value]="'f'">Женщина</option>
      </app-material-select>
    </div>
    <div class="col-6">
      <p class="caption-sm mt-4">Телефоны</p>

      <div formArrayName="physical_phones"
           *ngFor="let item of phones.controls; index as i;">
        <div [formGroupName]="i" class="physical_phones">
          <label class="form__checkbox form__checkbox_icon_done_mark" (click)="toggleMainPhone(i)">
            <input type="checkbox" [checked]="item.value['is_main']">
            <span class="form__checkbox__wrapper">
              <span class="form__checkbox__wrapper__toggler icon"></span>
            </span>
          </label>
          <app-material-input-mask
            [mask]="'+7 (000) 000-00-00'"
            [placeholder]="'+7 (___) ___-__-__'"
             formControlName="phone">
          </app-material-input-mask>
          <a class="ml-3 button button_icon button_icon_sub" (click)="deletePhone(i)">
            <i class="fa fa-trash-o c-white"></i>
          </a>
        </div>
      </div>

      <p><a href="javascript:void(0)" (click)="appendPhone()">Добавить телефон</a></p>
    </div>
  </div>
</form>
