<div class="form__group" [ngClass]="cssClass">
  <div class="form__label" [class.form__label_require]="require && !disabled" [class.form__label_error]="error">
    <ng-content></ng-content>
  </div>
  <div class="form__input" [class.form__input_disabled]="disabled" [class.form__input_focus]="focus"
       [class.form__input_error]="error">
    <textarea class="textarea" [rows]="rows" [value]="current_value" [disabled]="disabled"
            (focus)="focus = true"
            (blur)="focus = false"
            (keyup)="onChanges($event.target)" [placeholder]="placeholder"
            [ngClass]="{'md-valid': current_value && (current_value.length || current_value > 0)}"></textarea>
  </div>
</div>
