import { NgModule } from '@angular/core';
import { MaterialInputComponent } from './component/material-input.component';
import { CommonModule } from '@angular/common';
import { MaterialInputIconComponent } from './component/material-input-icon.component';
import { MaterialInputDatePickerComponent } from './component/material-input-datepicker.component';
import { MaterialInputDatePickerDirective } from './component/material-input-datepicker.directive';
import { MaterialInputFileComponent } from './component/material-input-file.component';
import { MaterialInputMaskComponent } from './component/material-input-mask.component';
import { MaterialInputIconMaskComponent } from './component/material-input-icon-mask.component';
import { MaterialInputDropzoneComponent } from './component/material-input-dropzone.component';
import { MaterialInputDropzoneTableComponent } from './component/material-input-dropzone-table.component';
import { MaterialSelect2Component } from './component/material-select2.component';
import { MaterialSelect2Directive } from './component/material-select2.directive';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MaterialInputAutocompleteComponent } from './component/material-input-autocomplete.component';
import { MaterialInputAutocompleteDirective } from './component/material-input-autocomplete.directive';
import { MaterialInputCheckboxComponent } from './component/material-input-checkbox.component';
import { SharedModule } from '../../shared/shared.module';
import { MaterialSelectComponent } from './component/material-select.component';
import { MaterialTextareaComponent } from './component/material-textarea.component';
import { MaterialInputAutocompleteCustomComponent } from './component/material-input-autocomplete-custom.component';
import { MaterialInputAutocompleteCustomDirective } from './component/material-input-autocomplete-custom.directive';
import { MaterialInputDropimageComponent } from './component/material-input-dropimage.component';
import { AbleproDirectivesModule } from '../../_front/core/ablepro-directives.module';
import { MaterialInputFiasAddressComponent } from './component/material-input-fias-address.component';
import { MaterialInputFiasAddressDirective } from './component/material-input-fias-address.directive';
import { MaterialWysiwygComponent } from './component/material-wysiwyg.component';
import { MaterialWysiwygDirective } from './component/material-wysiwyg.directive';
import { MaterialSelect2AutocompleteComponent } from './component/material-select2-autocomplete.component';
import { MaterialSelect2AutocompleteDirective } from './component/material-select2-autocomplete.directive';
import { MaterialInputRadioComponent } from './component/material-input-radio.component';
import { MaterialSelect2Service } from './component/material-select2.service';
import {MaterialInputDigitsComponent} from './component/material-input-digits.component';
import { MaterialInputDropzoneBlockComponent } from './component/material-input-dropzone-block.component';
import { MaterialInputDropzoneListComponent } from './component/material-input-dropzone-list.component';
import { InputMaskDirective } from './component/material-input-mask.directive';
import { MaterialInputSearchComponent } from './component/material-input-search.component';
import { MaterialDropdownComponent } from './component/material-dropdown.component';
import { MaterialInputRadioButtonComponent } from './component/material-input-radio-button.component';
import {EasyDropdownComponent} from './component/easy-dropdown.component';
import {MaterialInputGetAddressComponent} from './component/get-address/material-input-get-address.component';
import {MaterialInputGetAddressDirective} from './component/get-address/material-input-get-address.directive';
import {MaterialInputCheckboxSqComponent} from './component/material-input-checkbox-sq.component';
import {MaterialInputDropzoneSortableComponent} from './component/material-input-dropzone-sortable.component';
import {NgxMaskDirective, provideNgxMask} from 'ngx-mask';
import {PopoverModule} from '../@popover/popober.module';
import {AngularDraggableModule} from 'angular2-draggable';
import {MaterialInputFiasAddressEventDirective} from './component/material-input-fias-address-event.directive';
import {MaterialInputFiasAddressEventComponent} from './component/material-input-fias-address-event.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AbleproDirectivesModule,
    NgxMaskDirective,
    ReactiveFormsModule,
    PopoverModule,
    AngularDraggableModule,
  ],
  declarations: [
    MaterialInputComponent,
    MaterialInputMaskComponent,
    MaterialInputIconComponent,
    MaterialInputIconMaskComponent,
    MaterialInputDatePickerComponent,
    MaterialInputDatePickerDirective,
    MaterialInputFileComponent,
    MaterialInputDropzoneTableComponent,
    MaterialInputDropzoneComponent,
    MaterialInputDropzoneSortableComponent,
    MaterialSelect2Component,
    MaterialSelect2Directive,
    MaterialSelect2AutocompleteComponent,
    MaterialSelect2AutocompleteDirective,
    MaterialInputAutocompleteComponent,
    MaterialInputAutocompleteDirective,
    MaterialInputCheckboxComponent,
    MaterialSelectComponent,
    MaterialTextareaComponent,
    MaterialInputAutocompleteCustomComponent,
    MaterialInputAutocompleteCustomDirective,
    MaterialInputDropimageComponent,
    MaterialInputRadioComponent,
    MaterialInputDigitsComponent,
    MaterialInputDropzoneBlockComponent,
    MaterialInputDropzoneListComponent,
    MaterialInputCheckboxSqComponent,
    MaterialInputSearchComponent,
    InputMaskDirective,
    MaterialDropdownComponent,
    MaterialInputRadioButtonComponent,
    /* CRM-specific fields */
    MaterialInputFiasAddressComponent,
    MaterialInputFiasAddressDirective,

    MaterialInputGetAddressComponent,
    MaterialInputGetAddressDirective,

    MaterialWysiwygComponent,
    MaterialWysiwygDirective,
    EasyDropdownComponent,

    MaterialInputFiasAddressEventDirective,
    MaterialInputFiasAddressEventComponent,
  ],
  exports: [
    MaterialInputComponent,
    MaterialInputMaskComponent,
    MaterialInputIconComponent,
    MaterialInputIconMaskComponent,
    MaterialInputDatePickerComponent,
    MaterialInputFileComponent,
    MaterialInputDropzoneComponent,
    MaterialInputDropzoneSortableComponent,
    MaterialSelect2Component,
    MaterialSelect2AutocompleteComponent,
    MaterialInputAutocompleteComponent,
    MaterialInputCheckboxComponent,
    MaterialSelectComponent,
    MaterialTextareaComponent,
    MaterialInputAutocompleteCustomComponent,
    MaterialInputDropimageComponent,
    MaterialInputDatePickerDirective,
    MaterialInputRadioComponent,
    MaterialInputDigitsComponent,
    MaterialInputDropzoneTableComponent,
    MaterialInputDropzoneBlockComponent,
    MaterialInputDropzoneListComponent,
    MaterialInputSearchComponent,
    InputMaskDirective,
    MaterialDropdownComponent,
    MaterialInputAutocompleteDirective,
    MaterialSelect2AutocompleteDirective,
    MaterialInputRadioButtonComponent,
    MaterialInputCheckboxSqComponent,
    /* CRM-specific fields */
    MaterialInputFiasAddressComponent,
    MaterialInputFiasAddressDirective,

    MaterialInputGetAddressComponent,
    MaterialInputGetAddressDirective,

    MaterialWysiwygComponent,
    EasyDropdownComponent,

    MaterialInputFiasAddressEventDirective,
    MaterialInputFiasAddressEventComponent,
  ],
  providers: [
    MaterialSelect2Service, provideNgxMask()
  ]
})
export class MaterialInputModule {
}
