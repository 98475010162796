Всего: <b>{{count}}</b>
<!--<div class="mb-2">
  <div class="row no-gutters align-items-center m-0">
    <div class="col-md-6 col-lg-6 col-xl-4">
      <div class="form__group">
        <div class="form__input">
          <input class="input" type="text" placeholder="Поиск"
                 [ngModel]="filter" (ngModelChange)="onFilterChanges($event)">
          <div class="form__input_icon icon icon_Small_Search c-pointer ng-star-inserted"></div>
        </div>
      </div>
    </div>
  </div>
</div>-->

<div class="material-datatable-wrapper table-color">
  <div class="table-responsive">
    <table class="table">
      <tr>
        <ng-container *ngFor="let el of head">
          <ng-container *ngIf="el.realty_type.indexOf(realty_type) !== -1">
            <th [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
              <div [formGroup]="form">
                <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
                  <div class="col table__input">
                    {{el.caption[realty_type]}}
                  </div>
                  <div class="col-auto" *ngIf="el.sort">
                    <a href="javascript:void(0)" (click)="sortChange(el.name)">
                      <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                        'icon_new-sort-down': 'asc' == sort[el.name],
                                        'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                    </a>
                  </div>
                </div>
              </div>
            </th>
          </ng-container>
        </ng-container>
      </tr>
      <tr *ngFor="let row of rows; let ind = index">
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'realty_caption-' + ind}">
            <ng-container *ngIf="realty_type === 'housing_complex'">
              <a href="/housing2/complex/{{row.realty_id}}"
                 (click)="goBack.newLink('/housing2/complex/' + row.realty_id, 'realty_caption-' + ind, $event)">
                {{row.realty_caption}}
              </a>
            </ng-container>
            <ng-container *ngIf="realty_type === 'housing'">
              <a href="/housing2/house/{{row.realty_id}}"
                 (click)="goBack.newLink('/housing2/house/' + row.realty_id, 'realty_caption-' + ind, $event)">
                {{row.realty_caption}}
              </a>
            </ng-container>
            <ng-container *ngIf="realty_type === 'flat'">
              <a href="/housing2/flat/{{row.realty_id}}"
                 (click)="goBack.newLink('/housing2/flat/' + row.realty_id, 'realty_caption-' + ind, $event)">
                {{row.realty_caption}}
              </a>
            </ng-container>

          </div>
        </td>
      </tr>
      </table>
  </div>
</div>

<div class="alt-pagination">
  <app-paginator
    [queryParams]="filter_columns"
    [itemsPerPage]="20"
    [itemCount]="count"
    [currentPage]="current_page"
    [baseUrl]="'/analytic/realty-summary/table'">
  </app-paginator>
</div>
