import { AfterContentInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { HttpClient } from '../../../services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableParamComponent } from '../../../modules/@datatable/components/datatable-param.component';
import { SingleWindowService } from '../../../_crm/single-window/shared/single-window.service';
import { BackrefWindowService } from '../../../_crm/single-window/shared/backref-window.service';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CalendarCertificateComponent extends DataTableParamComponent implements OnInit, AfterContentInit, OnDestroy {

  @ViewChild('organisation_caption', {static: true}) private organisation_caption;
  @ViewChild('author_caption', {static: true}) private author_caption;
  @ViewChild('physical_caption', {static: true}) private physical_caption;
  @ViewChild('button', {static: true}) private button;

  public columns;
  public api_action = 'Calendar.dt_training_certificates';
  public url: any;

  constructor(public http: HttpClient, private fb: UntypedFormBuilder, public router: Router,
              public activatedRoute: ActivatedRoute,
              public singleWindowService: SingleWindowService, public backrefWindowService: BackrefWindowService) {
    super(http, activatedRoute, router);
    this.backrefWindowService.routerInit();
  }

  ngOnInit() {
    this.columns = [
      {'name': 'cert_num', 'caption': '№', 'sortable': true},
      {'name': 'cert_date', 'caption': 'Дата выдачи', 'sortable': true},
      {'name': 'event_caption', 'caption': 'Событие', 'sortable': true},
      {'name': 'org_socrachcaption', 'caption': 'Организация', 'sortable': true, 'template': this.organisation_caption},
      {'name': 'physical_fio', 'caption': 'Сотрудник', 'sortable': true, 'template': this.physical_caption},
      {'name': 'author_fio', 'caption': 'Автор', 'sortable': true, 'template': this.author_caption},
      {'name': '', 'template': this.button},
    ];

    super.ngOnInit();
  }

  public prepareQuery() {
    const query = super.prepareQuery();
    // query['filter']['event_date_from'] = this.date_from;

    return query;
  }

  public loadList() {
    super.loadList();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.backrefWindowService.routerDestroy();
  }

  ngAfterContentInit() {

  }

}
