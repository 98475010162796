import { Component, ComponentRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DataTableParamComponent } from '../../modules/@datatable/components/datatable-param.component';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder} from '@angular/forms';
import {GoBackButtonService} from '../../services/go-back-button.service';
import {Subscription} from 'rxjs';
import {OverlayWindowService} from '../single-window/shared/overlay-window.service';
import {StartSalesCreateComponent} from './start-sales-create/start-sales-create.component';
import {StartSalesEditComponent} from './start-sales-edit/start-sales-edit.component';
import {HttpClient} from '../../services/http.service';
import {NotificationService} from '../../services/notification.service';
import {FancyboxService} from '../../shared/fancybox.service';
import {StartSalesMembersComponent} from './start-sales-members/start-sales-members.component';
import {environment} from '../../../environments/environment';


@Component({
  templateUrl: './start-sales.component.html',
  styleUrls: [
    './start-sales.component.scss'
  ]
})
export class StartSalesComponent extends DataTableParamComponent implements OnInit, OnDestroy {

  public api_action = 'StartOfSales.get_table';
  public columns: Array<{ [key: string]: any }>;
  public createSubscription: Subscription;
  public editSubscription: Subscription;
  public dataModal = null;
  public modal_show = false;
  public urlExcel = environment.apiServer + 'restful/start-of-sales-member';
  private _is_archive = false;


  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router,
    public fb: UntypedFormBuilder, private overlayWindowService: OverlayWindowService, private goBack: GoBackButtonService,
    public notify: NotificationService, private lightbox: FancyboxService) {
    super(http, route, router);
  }

  @ViewChild('buttonTmpl', {static: true}) private buttonTmpl: TemplateRef<any>;
  @ViewChild('endTmpl', {static: true}) private endTmpl: TemplateRef<any>;
  @ViewChild('complTmpl', {static: true}) private complTmpl: TemplateRef<any>;
  @ViewChild('authorTmpl', {static: true}) private authorTmpl: TemplateRef<any>;
  @ViewChild('membersTmpl', {static: true}) private membersTmpl: TemplateRef<any>;

  ngOnInit() {
    this.goBack.header = '<h1>Старты продаж</h1>';
    this.columns = [
      {'caption': 'Дата создания', 'name': 'create_time', 'sortable': true},
      {'caption': 'Название ЖК', 'name': 'complex_caption', 'sortable': true, template: this.complTmpl},
      {'caption': 'Застройщик', 'name': 'developer_caption', 'sortable': true},
      {'caption': 'Начало старта продаж', 'name': 'date_start', 'sortable': true},
      {'caption': 'Окончание старта продаж', 'sortable': true, template: this.endTmpl},
      {'caption': 'Автор', 'name': 'author_fio', 'sortable': false, template: this.authorTmpl},
      {'caption': 'Кол-во записей', 'name': 'members_count', 'sortable': false, template: this.membersTmpl},
      {'caption': '', 'template': this.buttonTmpl}
    ];
    super.ngOnInit();
  }

  ngOnDestroy() {
  }

  public get is_archive() {
    return this._is_archive;
  }

  public set is_archive(value: any) {
    this._is_archive = !(value === 'false' || value === false);
    this.loadList();
  }

  prepareSort(column_name: string) {
    for (const key in this.sort) {
      if (column_name !== key) {
        delete this.sort[key];
      }
    }
  }

  prepareQuery() {
    const result = super.prepareQuery();
    result['filter']['fields'] = {
      'is_archive': this._is_archive
    };
    return result;
  }

  sortChange(column_name: string) {
    this.prepareSort(column_name);
    this.sort[column_name] = !this.sort[column_name] || ('desc' === this.sort[column_name]) ? 'asc' : 'desc';
  }

  createStartSales() {
    this.overlayWindowService.openComponentInPopup(StartSalesCreateComponent)
      .then((factory: ComponentRef<StartSalesCreateComponent>) => {
        this.createSubscription = factory.instance.onCreate.subscribe(_ => this.loadList());
      });
  }

  editStartSales(row) {
    this.overlayWindowService.openComponentInPopup(StartSalesEditComponent, row)
      .then((factory: ComponentRef<StartSalesEditComponent>) => {
        this.createSubscription = factory.instance.onEdit.subscribe(_ => this.loadList());
      });
  }

  participantsStartSales(row) {
    this.overlayWindowService.openComponentInPopup(StartSalesMembersComponent, row)
      .then((factory: ComponentRef<StartSalesMembersComponent>) => {
      });
  }

  deleteStartSales(start_of_sales_id) {
    NotificationService.swalConfirm('Вы уверены?', 'Выбранный старт продаж будет перемещен в архив')
      .then(() => {
        this.http.post('StartOfSales.update',
          {'start_of_sales_id': start_of_sales_id,
                  'data': {
                          'is_archive': true
                  }
          }
        ).subscribe(
          (response) => {
            this.notify.notifySuccess('Старт продаж успешно перемещен в архив', '', 2000);
            this.loadList();
          },
          (error) => this.notify.notifyError('Ошибка', error, 5000)
        );
      }).catch(() => false);
  }
}
