import {AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[textPreview]',
})
export class TextPreviewDirective implements AfterViewInit, OnInit {

  private icon: ElementRef;
  private mark: ElementRef;
  private blockHeight = 0;
  @Input() private textPreview = 200;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.blockHeight = this.elementRef.nativeElement.offsetHeight;
    this.renderer.setStyle(this.elementRef.nativeElement, 'max-height', this.textPreview + 'px');
    this.renderer.addClass(this.elementRef.nativeElement, 'text-preview');

    if (this.blockHeight > this.textPreview) {
      this.icon = this.renderer.createElement('div');
      this.renderer.addClass(this.icon, 'text-preview__drop');

      this.mark = this.renderer.createElement('i');
      this.renderer.addClass(this.mark, 'icon');
      this.renderer.addClass(this.mark, 'icon_Small_Chevron-Down');
      this.elementRef.nativeElement.appendChild(this.icon);
      this.renderer.appendChild(this.icon, this.mark);

      this.renderer.listen(this.icon, 'click', () => {
        if (this.elementRef.nativeElement.classList.contains('active')) {
          this.renderer.removeClass(this.elementRef.nativeElement, 'active');
        } else {
          this.renderer.addClass(this.elementRef.nativeElement, 'active');
        }
      });
    }
  }

}
