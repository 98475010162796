<div class="material-datatable-wrapper">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)">
  </app-datatable-headline>
  <div class="table-responsive">
    <table class="table table-striped datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>

<ng-template #fileIconTmpl  let-row="row">
  <div class="holiday__banner"  (click)="displayImage(row.icon_url)"
       style="width: 50px; height: 50px"
       [style.background-image]="'url('+row.icon_url+')'" ></div>
</ng-template>

<ng-template #fileTmpl  let-row="row">
  <div class="holiday__banner"  (click)="displayImage(row.banner_url)"
       style="width: 50px; height: 50px"
       [style.background-image]="'url('+row.banner_url+')'"></div>
</ng-template>

<ng-template #buttonTmpl let-row="row">
  <div class="button button_icon" (click)="restoreArchive(row.id)">
    <i class="fa fa-refresh"></i>
  </div>
</ng-template>
