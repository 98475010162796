import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {UsageVariant} from '../../../models/usage_variants';

declare const tinymce: any, jQuery: any;

@Directive({
  selector: '[appWysiwygEditor]'
})
export class MaterialWysiwygDirective implements AfterViewInit {

  private _value: string;
  private _editor: Promise<any>;
  private _editorResolver: (value: any) => void;

  @Output() public value_emitter: EventEmitter<string> = new EventEmitter();
  @Input() private elementId: string;
  @Input() public rows = 20;

  public get value(): string {
    return this._value;
  }

  @Input('appWysiwygEditor')
  public set value(value: string) {
    this._value = value;
  }

  constructor(private elementRef: ElementRef) {
    this._editor = new Promise<any>((resolve) => this._editorResolver = resolve);
  }

  public loadEditor() {
    const assets_url = environment.usage === UsageVariant.CRM ? '/assets_crm' : '/assets';

    tinymce.PluginManager.load('lists', `${assets_url}/tinymce/plugins/lists/plugin.min.js`);
    tinymce.PluginManager.load('advlist', `${assets_url}/tinymce/plugins/advlist/plugin.min.js`);
    tinymce.PluginManager.load('code', `${assets_url}/tinymce/plugins/code/plugin.min.js`);
    tinymce.PluginManager.load('table', `${assets_url}/tinymce/plugins/table/plugin.min.js`);

    if ('tinyFiles24' === environment.tinyMcePluginName) {
      tinymce.PluginManager.load(environment.tinyMcePluginName, `${assets_url}/tinymce-spn24/tiny-files-spn24/plugin.js`);
    }

    if ('tinyFilesDev' === environment.tinyMcePluginName) {
      tinymce.PluginManager.load(environment.tinyMcePluginName, `${assets_url}/tinymce-spn24/tiny-files-dev/plugin.js`);
    }

    if ('tinyFilesTest' === environment.tinyMcePluginName) {
      tinymce.PluginManager.load(environment.tinyMcePluginName, `${assets_url}/tinymce-spn24/tiny-files-test/plugin.js`);
    }


    tinymce.init({
      selector: '#' + this.elementId,
      skin_url: assets_url + '/tinymce/themes/lightgray',
      language: 'ru',
      language_url: assets_url + '/tinymce/languages/ru.js',
      allow_script_urls: true,
      relative_urls: true,
      height: this.rows * 16,
      image_advtab: true,
      plugins: `${environment.tinyMcePluginName}, link, image, code, lists, advlist, table`,
      toolbar1: 'table | undo redo | bullist numlist | styleselect formatselect fontselect fontsizeselect',
      toolbar2: 'cut copy paste | bold italic underline removeformat | alignleft aligncenter alignright alignjustify | outdent indent',
      toolbar3: 'spnGallery | link unlink image | code',

      style_formats: [
        {title: 'Image Left', selector: 'img', styles: {
            'float' : 'left',
            'margin': '0 10px 0 10px'
          }},
        {title: 'Image Right', selector: 'img', styles: {
            'float' : 'right',
            'margin': '0 10px 0 10px'
          }}
      ],

      extended_valid_elements: 'div[*],i[*],a[*]',
      setup: (ed) => {
        ed.on('change', (e) => {
          if (jQuery(this.elementRef.nativeElement).tinymce()) {
            this.value_emitter
              .emit(jQuery(this.elementRef.nativeElement).tinymce().getContent());
          }
        });
        ed.on('KeyUp', (e) => this.value_emitter
          .emit(jQuery(this.elementRef.nativeElement).tinymce().getContent()));

        ed.on('init', (args) => ed.setContent(this._value));
      }
    });
    jQuery(this.elementRef.nativeElement).parent().find('.mce-tinymce.mce-container').show();
  }

  ngAfterViewInit() {
    this.loadEditor();
  }

}
