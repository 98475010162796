import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {HttpClient} from '../../../services/http.service';
import {Observable} from 'rxjs';

@Injectable()
export class SettingsRolesResolve implements Resolve<any> {

  constructor(private http: HttpClient) { }

  resolve(): Promise<any> {

    return new Promise((resolve) => {
      this.http.post('Roles.list', {'filter': ''})
        .subscribe(x => resolve({
          'roles': x,
        }));
    });
  }

}
