import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {JsonClientService} from '../../../../shared/json-client';

declare const moment;

@Injectable()
export class ReportKpiResolve {

  constructor(private j: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot, ...args): Promise<any> | any {
    const options = {
      'date_start': route.params['start'] || moment()
        .startOf('year').format('DD.MM.YYYY'),

      'date_end': route.params['stop'] || moment()
        .endOf('month').format('DD.MM.YYYY')
    };
    return this.j.post('Analytics.get_attraction_kpi', options);
  }

}
