<div class="material-datatable-wrapper">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-md-4">
        <app-material-input formControlName="search_string"
                            placeholder="Поиск по субагенту"></app-material-input>
      </div>
      <div class="col-md-4">
        <app-material-input formControlName="complex_or_developer_caption"
                            placeholder="Застройщик / Название ЖК"></app-material-input>
      </div>
    </div>
  </form>
  <div class="table-responsive">
    <table class="table datatable" [ngClass]="cssClass">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>

