import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttractionNotRegisteredComponent } from './attraction-not-registered/attraction-not-registered.component';
import { MaterialInputModule } from '../../modules/@material-input/material-input.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PreloaderModule } from '../../modules/@preloader/preloader.module';
import { TabsModule } from '../../modules/@tabs/tabs.module';
import { RouterModule } from '@angular/router';
import { SimpleTableModule } from '../../modules/@simple-table/simple-table.module';
import { DataTableModule } from '../../modules/@datatable/datatables.module';
import { SharedModule } from '../../shared/shared.module';
import {AttractionSubagentsComponent} from './attraction-subagents/attraction-subagents.component';
import {AttractionStatusCreatedComponent} from './attraction-status-created/attraction-status-created.component';
import {AttractionStatusChangeComponent} from './attraction-status-change/attraction-status-change.component';
import {AttractionStatusPassedComponent} from './attraction-status-passed/attraction-status-passed.component';
import {AttractionLeadModule} from './attraction-lead/attraction-lead.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DataTableModule,
    TabsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
    SharedModule,
    PreloaderModule,
    SimpleTableModule,
    AttractionLeadModule,
  ],
  declarations: [
    AttractionNotRegisteredComponent,
    AttractionSubagentsComponent,
    AttractionStatusCreatedComponent,
    AttractionStatusChangeComponent,
    AttractionStatusPassedComponent,
  ]
})
export class AttractionModule { }
