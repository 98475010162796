import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../services/notification.service';
import { OverlayWindowService } from '../../single-window/shared/overlay-window.service';
import { HttpClient } from '../../../services/http.service';


@Component({
  selector: 'app-edit-holiday',
  templateUrl: './holiday-edit.component.html',
  styleUrls: ['./holiday-edit.component.scss']
})
export class HolidayEditComponent implements OnInit {

  @Input() public time_start = '';
  @Input() public id;
  @Input() public time_stop = '';
  @Input() public dates_string = '';
  @Input() public is_archive = '';
  @Input() public file_id = '';
  @Input() public image = '';
  @Input() public image_icon = '';
  @Input() public margin_top = 0;
  @Input() public margin_left = 0;
  public marginLeft = 0;
  public marginTop = 0;
  @Output() public onEdit: EventEmitter<any> = new EventEmitter<any>();

  public form: UntypedFormGroup;

  public new_dates = (value) => {
    let new_value = value;
    if (value !== '') {
      const splitted = value.split(' - ');
      if (splitted[0] === splitted[1]) {
        new_value = splitted[0];
      }
    }
    return new_value;
  }

  constructor(private http: HttpClient, private overlayWindowService: OverlayWindowService,
              private fb: UntypedFormBuilder, private notify: NotificationService) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      'dates_string': [this.dates_string, Validators.required],
      'time_start': [this.time_start, Validators.required],
      'time_stop': [this.time_stop, Validators.required],
      'image': [this.image, Validators.required],
      'image_icon': [this.image_icon, Validators.required],
      'is_archive': [this.is_archive, Validators.required],
      'margin_top': [this.margin_top, Validators.required],
      'margin_left': [this.margin_left, Validators.required],
    });
    this.marginLeft = this.margin_left;
    this.marginTop = this.margin_top;
  }

  replaceMarginLeft(value) {
    this.marginLeft = value.value.value;
  }

  replaceMarginTop(value) {
    this.marginTop = value.value.value * -1;
  }

  onSubmit() {
    const data = {
      'id': this.id,
      'data': {
        'time_start': this.form.value.time_start,
        'time_stop': this.form.value.time_stop,
        'dates_string': this.form.value.dates_string,
        'image': this.form.value.image,
        'image_icon': this.form.value.image_icon,
        'is_archive': this.form.value.is_archive,
        'margin_top': this.marginTop,
        'margin_left': this.marginLeft,
      }
    };
    this.http.post('Holiday.edit', data).subscribe(
      response => {
        const title = this.form.value.holiday_item;
        this.notify.notifySuccess('График работы успешно отредактирован', title, 5000);
        this.onEdit.emit({'value': title});
        this.overlayWindowService.closeHost();
      },
      error => this.notify.notifyError('Ошибка', error, 5000)
    );
  }

}
