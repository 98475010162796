import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HousingOrderComponent} from './housing-order.component';
import {AngularDraggableModule} from 'angular2-draggable';
import {HousingOrderResolve} from './housing-order.resolve';
import {FormsModule} from '@angular/forms';
import {StockBadgeModule} from '../../modules/@stock-badge';


@NgModule({
  imports: [
    CommonModule,
    AngularDraggableModule,
    FormsModule,
    StockBadgeModule
  ],
  declarations: [
    HousingOrderComponent,
  ],
  providers: [
    HousingOrderResolve,
  ]
})
export class HousingOrderModule { }
