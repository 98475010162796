
import {from as observableFrom, BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';


@Injectable()
export class OutdateVersionService {

  private _version_emitter: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public readonly version_emitter: Observable<string> = observableFrom(this._version_emitter);

  /*
   * Getter and setter for version_id variable
   */

  public set version_id(value) {
    this._version_emitter.next(value);
  }

  public get version_id() {
    return this._version_emitter.getValue();
  }

}
