import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CrmCalendarComponent} from './crm-calendar.component';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormWizardModule } from '../../modules/@wizard-form/wizard.module';
import { MaterialInputModule } from '../../modules/@material-input/material-input.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
    FormWizardModule,
  ],
  declarations: [
    CrmCalendarComponent,
  ],
  exports: [
    CrmCalendarComponent,
  ]
})
export class CrmCalendarModule {

}
