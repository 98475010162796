Всего: <b>{{count}}</b>
<div class="mb-2">
  <div class="row no-gutters align-items-center m-0">
    <div class="col-md-6 col-lg-6 col-xl-4">
      <div class="form__group">
        <div class="form__input">
          <input class="input" type="text" placeholder="Поиск"
                 [ngModel]="filter" (ngModelChange)="onFilterChanges($event)">
          <div class="form__input_icon icon icon_Small_Search c-pointer ng-star-inserted"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="material-datatable-wrapper table-color">
  <div class="table-responsive">
    <table class="table">
      <tr>
        <ng-container *ngFor="let el of head" >
          <th [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
            <div [formGroup]="form">
              <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
                <div class="col table__input">
                  <app-material-input
                    [placeholder] = "el.caption"
                    [formControlName] = "el.name"
                    [cssClass]="'mb-0'">
                  </app-material-input>
                </div>
                <div class="col-auto" *ngIf="el.sort">
                  <a href="javascript:void(0)" (click)="sortChange(el.name)">
                    <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                        'icon_new-sort-down': 'asc' == sort[el.name],
                                        'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                  </a>
                </div>
              </div>
            </div>
          </th>
        </ng-container>
      </tr>
      <tr *ngFor="let row of rows; let ind = index">
        <td>
          {{row.physical_author}}
        </td>
        <td>
          <div [innerHtml]="row.org2phy_caption"></div>
        </td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'physical_fio-' + ind}">
            <a href="/physical/edit/{{row.physical_id}}/common"
               (click)="goBack.newLink('/physical/edit/' + row.physical_id + '/common', 'physical_fio-' + ind, $event)">
              {{row.physical_fio}}
            </a>
          </div>
        </td>
        <td>
          {{row.physical_phone}}
        </td>
        <td>
          {{row.physical_email}}
        </td>
        <td>
          <div [ngClass]="{'activeBox': goBack.activeHash === 'event_caption-' + ind}">
            <a href="/calendar/edit/{{row.event_id}}"
               (click)="goBack.newLink('/calendar/edit/' + row.event_id, 'event_caption-' + ind, $event)">
              {{row.event_caption}}
            </a>
          </div>
        </td>
      </tr>
      </table>
  </div>
</div>

<div class="alt-pagination">
  <app-paginator
    [queryParams]="filter_columns"
    [itemsPerPage]="20"
    [itemCount]="count"
    [currentPage]="current_page"
    [baseUrl]="'/analytic/calendar-summary/table'">
  </app-paginator>
</div>
