import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {AlternativeDatatablesInterface} from '../../../../../shared/alternative-datatables/alternative-datatables.interface';
import {AlternativeDatatablesComponent} from '../../../../../shared/alternative-datatables/alternative-datatables.component';
import {JsonClientService} from '../../../../../shared/json-client';
import {GoBackButtonService} from '../../../../../services/go-back-button.service';
import {BreadcrumbsService} from '../../../../../shared/breadcrumbs/services';

@Component({
  templateUrl: './report-kpi-table.component.html',
  styleUrls: [
    './report-kpi-table.component.scss'
  ]
})
export class ReportKpiTableComponent
  extends AlternativeDatatablesComponent
  implements AlternativeDatatablesInterface, OnInit, OnDestroy, AfterViewInit {

  public readonly head = [
    {'name': 'stage_change_time', 'value': '', 'caption': 'Дата изменения', 'sort': true},
    {'name': 'organisation_caption', 'value': '', 'caption': 'Организация', 'width': '', 'sort': true},
    {'name': 'subagent_user_fio', 'value': '', 'caption': 'Субагент', 'width': '', 'sort': true},
    {'name': 'subagent_phone', 'value': '', 'caption': 'Тел. субагента', 'width': '', 'sort': true},
    {'name': 'fio', 'value': '', 'caption': 'Лид', 'width': '', 'sort': true},
    {'name': 'lead_phone', 'value': '', 'caption': 'Тел. клиента', 'width': '', 'sort': true},
    {'name': 'object_caption', 'value': '', 'caption': 'Объект', 'width': '', 'sort': true},
    {'name': 'physical_manager_fio', 'value': '', 'caption': 'Ответственный', 'width': '', 'sort': true},
    {'name': 'stage_caption', 'value': '', 'caption': 'Этап', 'width': '', 'sort': true},
  ];
  public filter_columns = {};
  public form: UntypedFormGroup;
  public filter: string;

  private parasites = true;

  public filterSubject: Subject<void> = new Subject<void>();
  private filterSubscription: Subscription;
  private formSubscription: Subscription;

  public current_page = 1;

  public count;
  public rows;
  public thisDate = new Date();

  public sort: {[key: string]: string} = {};

  private default_query = {};

  public set data(value) {
    this.count = value['count'];
    this.rows = value['rows'];
  }

  constructor(private router: Router, private fb: UntypedFormBuilder, public activatedRoute: ActivatedRoute,
              public goBack: GoBackButtonService, private b: BreadcrumbsService,
              public http: JsonClientService) {
    super(router, activatedRoute, fb, http);

    this.filterSubscription = this.filterSubject
      .pipe(debounceTime(500))
      .subscribe(_ => this.onFilterChanges());

    this.head = this.head.map(x => {
      x.value = this.activatedRoute.snapshot.queryParams[x.name] || x.value;
      return x;
    });
    if (this.activatedRoute.snapshot.queryParams['sort_name']) {
      this.sort[this.activatedRoute.snapshot.queryParams['sort_name']] = this.activatedRoute.snapshot.queryParams['sort_key'];
    }
  }

  ngOnInit() {
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/analytic/index', caption: 'Аналитика'},
    ]);
    this.goBack.header = '<h1>KPI отдела привлечения по закрытым сделкам</h1>';

    this.default_query = {
      'month': this.activatedRoute.snapshot.queryParams['month'],
      'date_start': this.activatedRoute.snapshot.queryParams['date_start'],
      'date_end': this.activatedRoute.snapshot.queryParams['date_end'],
      'user_id': parseInt(this.activatedRoute.snapshot.queryParams['user_id'], 10),
    };
    this.filter_columns = Object.assign({}, this.default_query);

    this.form = this.fb.group({});

    this.formSubscription = this.form.valueChanges
      .subscribe(_ => this.filterSubject.next());

    for (const el of this.head) {
      this.form.addControl(el.name, new UntypedFormControl(el.value));
    }

    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.onInitCall();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }

    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  onFilterChanged(): void {
    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.data = this.activatedRoute.snapshot.data['data'];
    this.current_page = +this.activatedRoute.snapshot.queryParams['page'] || 1;
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/analytic/index', caption: 'Аналитика'},
    ]);
  }

  onFilterChanges(text = null) {

    if (text !== null) {
      this.filter = text;
    }

    this.filter_columns = Object.assign({}, this.default_query);
    for (const el of this.head) {
      if (!this.form.get(el.name).value.length) {
        continue;
      }

      this.filter_columns[el.name] = this.form.get(el.name).value;
    }
    if (this.filter !== '') {
      this.filter_columns['global_search_string'] = this.filter;
    }
    if (this.sort) {
      this.filter_columns['sort_name'] = Object.keys(this.sort)[0];
      this.filter_columns['sort_key'] = this.sort[this.filter_columns['sort_name']];
    }
    if (this.parasites) {
      return this.parasites = !this.parasites;
    }

    this.router.navigate(['analytic', 'report-kpi', 'table'], {
      queryParams: this.getQueryParams(),
    });
  }

  getQueryParams() {
    const query_params = Object.assign({}, this.default_query);
    for (const el of this.head) {
      if (!this.form.get(el.name).value.length) {
        continue;
      }
      query_params[el.name] = this.form.get(el.name).value;
    }
    if (this.sort) {
      query_params['sort_name'] = Object.keys(this.sort)[0];
      query_params['sort_key'] = this.sort[query_params['sort_name']];
    }
    if (this.filter !== '') {
      query_params['global_search_string'] = this.filter;
    }
    return query_params;
  }

  prepareSort(column_name: string) {
    for (const key in this.sort) {
      if (column_name !== key) {
        delete this.sort[key];
      }
    }
  }

  sortChange(column_name: string) {
    this.prepareSort(column_name);
    this.sort[column_name] = !this.sort[column_name] || ('desc' === this.sort[column_name]) ? 'asc' : 'desc';
    this.onFilterChanges();
  }
  ngAfterViewInit() {
    this.goBack.goScroll();
  }
  datediff(newdate) {
    const oneDay = 1000 * 60 * 60 * 24;
    const [day, month, year] = newdate.split('.');
    const dayTill = Math.round((new Date(year, month - 1, day).getTime() - this.thisDate.getTime()) / oneDay) + 1;
    if (dayTill === 0) {
      return 'Контакт сегодня';
    } else if (dayTill > 0) {
      return 'Дней до контакта (' + dayTill + ')';
    } else if (dayTill < 0) {
      return 'Контакт просрочен';
    }
  }
}
