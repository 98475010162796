<section class="main-wrapper login" *ngIf="display" id="locker-wrapper">
  <a href="javascript: void(0)" routerLink="/" class="logo logo_spn"></a>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <form class="md-float-material" [formGroup]="form" novalidate (submit)="onSubmit()">
          <p class="text-center">Сессия заблокирована</p>
          <div class="md-group">
            <app-material-input formControlName="password" [type]="'password'">Пароль</app-material-input>
          </div>
          <div class="unlock">
            <button type="submit" class="button button_main" [disabled]="!form.valid"> Войти</button>
            <p class="f-24 pull-right mb-0"><a class="f-15" href="javascript: void(0)" (click)="onChangeUser()"> Другой пользователь?</a></p>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
