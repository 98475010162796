import {Injectable} from "@angular/core";
import {JsonClientService} from "../../../json-client";

@Injectable()
export class FieldSubwayService {

  constructor(private j: JsonClientService) { }

  loadHelpers() {
    return Promise.all([
      this.j.post('Dict.get_subway'),
    ]);
  }

}
