import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-accordion-element',
  templateUrl: './accordion-element.component.html',
  styleUrls: ['./accordion-element.component.scss']
})
export class AccordionElementComponent {

  @Input() public caption = '';
  @Output() public activate: EventEmitter<boolean> = new EventEmitter();

  private _is_active = false;

  public get is_active(): boolean {
    return this._is_active;
  }

  public set is_active(value: boolean) {
    this._is_active = value;
    this.activate.emit(this._is_active);
  }

  public set_inactive() {
    this._is_active = false;
  }

  public set_active() {
    this._is_active = true;
  }

}
