import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {AlternativeDatatablesComponent} from '../../shared/alternative-datatables/alternative-datatables.component';
import {AlternativeDatatablesInterface} from '../../shared/alternative-datatables/alternative-datatables.interface';
import {GoBackButtonService} from '../../services/go-back-button.service';
import {JsonClientService} from '../../shared/json-client';
import {AuthenticateService} from '../../services/authenticate.service';
import {environment} from '../../../environments/environment';
declare const moment;

@Component({
  templateUrl: './estatet.component.html',
  styleUrls: [
    './estatet.component.scss'
  ]
})
export class EstatetComponent
  extends AlternativeDatatablesComponent
  implements AlternativeDatatablesInterface, OnInit, OnDestroy, AfterViewInit {

  public queryLink = '';
  public subscribtionUser: Subscription;
  public user = null;
  public urlIntegration = environment.urlIntegration;

  public readonly head = [
    {'name': 'date_start', 'value': '05.10.2018', 'caption': 'none', 'width': '', 'sort': true},
    {'name': 'date_end', 'value': moment().format('DD.MM.YYYY'), 'caption': 'none', 'width': '', 'sort': true},
    {'name': 'spn_requests', 'value': '', 'caption': 'SPN24->EAT', 'width': '', 'sort': true},
    {'name': 'eat_requests', 'value': '', 'caption': 'EAT->SPN24', 'width': '', 'sort': true},
    {'name': 'time_to_answer', 'value': '', 'caption': 'Время ожидания', 'width': '', 'sort': true},
    {'name': 'next_contact_date', 'value': '', 'caption': 'След. контакт (созд./изм.)', 'sort': true},
    {'name': 'organisation_caption', 'value': '', 'caption': 'Организация', 'width': '', 'sort': true},
    {'name': 'subagent_user_fio', 'value': '', 'caption': 'Субагент', 'width': '', 'sort': true},
    {'name': 'subagent_phone', 'value': '', 'caption': 'Тел. субагента', 'width': '', 'sort': true},
    {'name': 'fio', 'value': '', 'caption': 'Клиент', 'width': '', 'sort': true},
    {'name': 'lead_phone', 'value': '', 'caption': 'Тел. клиента', 'width': '', 'sort': true},
    {'name': 'object_caption', 'value': '', 'caption': 'Объект', 'width': '', 'sort': true},
    {'name': 'sales_manager_fio', 'value': '', 'caption': 'Ответственный', 'width': '', 'sort': true},
    {'name': 'stage_caption', 'value': '', 'caption': 'Этап', 'width': '', 'sort': true},
    {'name': 'lead_status', 'value': 'new||success||prolongation||roll_back', 'caption': 'Этап', 'width': '150px', 'sort': true},
  ];
  public readonly head_display = [
    {'name': 'spn_requests', 'value': '', 'caption': 'SPN24->EAT', 'width': '', 'sort': true},
    {'name': 'eat_requests', 'value': '', 'caption': 'EAT->SPN24', 'width': '', 'sort': true},
    {'name': 'time_to_answer', 'value': '', 'caption': 'Время ожидания', 'width': '', 'sort': true},
    {'name': 'next_contact_date', 'value': '', 'caption': 'След. контакт (созд./изм.)', 'sort': true},
    {'name': 'organisation_caption', 'value': '', 'caption': 'Организация', 'width': '', 'sort': true},
    {'name': 'subagent_user_fio', 'value': '', 'caption': 'Субагент', 'width': '', 'sort': true},
    {'name': 'subagent_phone', 'value': '', 'caption': 'Тел. субагента', 'width': '', 'sort': true},
    {'name': 'fio', 'value': '', 'caption': 'Клиент', 'width': '', 'sort': true},
    {'name': 'lead_phone', 'value': '', 'caption': 'Тел. клиента', 'width': '', 'sort': true},
    {'name': 'object_caption', 'value': '', 'caption': 'Объект', 'width': '', 'sort': true},
    {'name': 'sales_manager_fio', 'value': '', 'caption': 'Ответственный', 'width': '', 'sort': true},
    {'name': 'stage_caption', 'value': '', 'caption': 'Этап', 'width': '', 'sort': true},
    {'name': 'lead_status', 'value': 'new||success||prolongation||roll_back', 'caption': 'Этап', 'width': '150px', 'sort': true},
  ];
  public filter_columns = {};
  public form: UntypedFormGroup;
  public filter: string;

  private parasites = true;

  public filterSubject: Subject<void> = new Subject<void>();
  private filterSubscription: Subscription;
  private formSubscription: Subscription;

  public current_page = 1;

  public count;
  public count_all;
  public count_answered;
  public count_not_answered;
  public tta_min_caption;
  public tta_max_caption;
  public tta_avg_caption;
  public rows;
  public thisDate = new Date();

  public sort: {[key: string]: string} = {};


  public set data(value) {
    this.count = value['count'];
    this.count_all = value['count_all'];
    this.count_answered = value['count_answered'];
    this.count_not_answered = value['count_not_answered'];
    this.tta_min_caption = value['tta_min_caption'];
    this.tta_max_caption = value['tta_max_caption'];
    this.tta_avg_caption = value['tta_avg_caption'];
    this.rows = value['rows'];
    const obj = {
      'fields': value['fields'],
      'global_search_string': value['global_search_string']
    };

    this.queryLink = '?filter=' + encodeURI(JSON.stringify(obj));
  }

  constructor(private router: Router, private fb: UntypedFormBuilder, public activatedRoute: ActivatedRoute,
              public goBack: GoBackButtonService,
              public http: JsonClientService, private auth: AuthenticateService) {
    super(router, activatedRoute, fb, http);

    this.filterSubscription = this.filterSubject
      .pipe(debounceTime(500))
      .subscribe(_ => this.onFilterChanges());

    this.head = this.head.map(x => {
      x.value = this.activatedRoute.snapshot.queryParams[x.name] || x.value;
      return x;
    });
    if (this.activatedRoute.snapshot.queryParams['sort_name']) {
      this.sort[this.activatedRoute.snapshot.queryParams['sort_name']] = this.activatedRoute.snapshot.queryParams['sort_key'];
    }
  }

  ngOnInit() {
    this.goBack.header = '<h1>Логи интеграции Estatet</h1>';
    this.subscribtionUser = this.auth.emit_user.subscribe((user) => this.user = user);


    this.filter_columns = {};

    this.form = this.fb.group({});

    this.formSubscription = this.form.valueChanges
      .subscribe(_ => this.filterSubject.next());

    for (const el of this.head) {
      this.form.addControl(el.name, new UntypedFormControl(el.value));
    }
    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.onInitCall();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.subscribtionUser) {
      this.subscribtionUser.unsubscribe();
    }

    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }

    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  onFilterChanged(): void {
    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.data = this.activatedRoute.snapshot.data['data'];
    this.current_page = +this.activatedRoute.snapshot.queryParams['page'] || 1;
  }

  onFilterChanges(text = null) {

    if (text !== null) {
      this.filter = text;
    }

    this.filter_columns = {};
    for (const el of this.head) {
      if (!this.form.get(el.name).value.length) {
        continue;
      }

      this.filter_columns[el.name] = this.form.get(el.name).value;
    }
    if (this.filter !== '') {
      this.filter_columns['global_search_string'] = this.filter;
    }
    if (this.sort) {
      this.filter_columns['sort_name'] = Object.keys(this.sort)[0];
      this.filter_columns['sort_key'] = this.sort[this.filter_columns['sort_name']];
    }
    if (this.parasites) {
      return this.parasites = !this.parasites;
    }

    this.router.navigate(['estatet'], {
      queryParams: this.getQueryParams(),
    });
  }

  getQueryParams() {
    const query_params = {};
    for (const el of this.head) {
      if (!this.form.get(el.name).value.length) {
        continue;
      }
      query_params[el.name] = this.form.get(el.name).value;
    }
    if (this.sort) {
      query_params['sort_name'] = Object.keys(this.sort)[0];
      query_params['sort_key'] = this.sort[query_params['sort_name']];
    }
    if (this.filter !== '') {
      query_params['global_search_string'] = this.filter;
    }
    return query_params;
  }

  prepareSort(column_name: string) {
    for (const key in this.sort) {
      if (column_name !== key) {
        delete this.sort[key];
      }
    }
  }

  sortChange(column_name: string) {
    this.prepareSort(column_name);
    this.sort[column_name] = !this.sort[column_name] || ('desc' === this.sort[column_name]) ? 'asc' : 'desc';
    this.onFilterChanges();
  }
  ngAfterViewInit() {
    this.goBack.goScroll();
  }
  datediff(newdate) {
    const oneDay = 1000 * 60 * 60 * 24;
    const [day, month, year] = newdate.split('.');
    const dayTill = Math.round((new Date(year, month - 1, day).getTime() - this.thisDate.getTime()) / oneDay) + 1;
    if (dayTill === 0) {
      return 'Контакт сегодня';
    } else if (dayTill > 0) {
      return 'Дней до контакта (' + dayTill + ')';
    } else if (dayTill < 0) {
      return 'Контакт просрочен';
    }
  }
}
