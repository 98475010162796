import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient} from '../../services/http.service';

@Injectable()
export class PhyToOrganisationResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Organisation.get_phy_to_organisation',
      {'organisation_id': +route.params['id']});
  }
}

@Injectable()
export class PhyToOrganisationNotCurrentPhyResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.http.post('Subagents.workers_list_from_transfer',
      {'worker_id': +route.params['id']});
  }
}
