import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {JsonClientService} from '../../shared/json-client';
import {NotificationService} from '../../services/notification.service';
import {GoBackButtonService} from '../../services/go-back-button.service';

@Component({
  selector: 'app-housing-order',
  templateUrl: './housing-order.component.html',
  styleUrls: ['./housing-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HousingOrderComponent implements OnInit, OnDestroy {

  public el_height = 55;
  public position = [];
  public complexes = [];

  constructor(private activatedRoute: ActivatedRoute, private http: JsonClientService,
              private notify: NotificationService, private cd: ChangeDetectorRef,
              private goBack: GoBackButtonService) {

  }

  ngOnInit() {
    this.goBack.header = '<h1>Сортировка ЖК</h1>';
    this.complexes = this.activatedRoute.snapshot.data['data'];
    this.refreshPosition();
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }
  ngOnDestroy() {
    if (!this.cd['destroyed']) {
      this.cd.detach();
    }
  }
  refreshPosition() {
    this.position = [];
    this.complexes.forEach((x, index) => {
      this.position.push({
        y: index * this.el_height,
        x: 0
      });
    });
  }

  onMoving(event, index) {

    const boxHeight = this.el_height;
    let repElement;
    let temp;
    temp = index;
    this.position.forEach((el, i) => {
      if (event.y < el.y &&
        event.y + boxHeight >= el.y + boxHeight / 2) {
        repElement = i;
      } else if (event.y >= el.y &&
        event.y <= el.y + boxHeight / 2 &&
        event.y + boxHeight >= el.y) {
        repElement = i;
      }
      if (repElement > -1) {
        let fixPlus = true;
        const bugStep = Math.abs(repElement - temp);
        if (repElement - temp < 0) {
          fixPlus = false;
        }
        const fixTemp = temp;
        for (let ibug = 1; ibug <= bugStep; ibug++) {
          if (!fixPlus) {
            repElement = fixTemp - ibug;
          } else {
            repElement = fixTemp + ibug;
          }
          const eltemp = this.complexes[temp];
          this.complexes[temp] = this.complexes[repElement];
          this.complexes[repElement] = eltemp;
          if (this.complexes[repElement + 1] && this.complexes[repElement + 1]['pinned']) {
            if (this.complexes[repElement]) {
              this.complexes[repElement]['pinned'] = true;
            }
          } else if (this.complexes[repElement - 1] && !this.complexes[repElement - 1]['pinned']) {
            if (this.complexes[repElement]) {
              this.complexes[repElement]['pinned'] = false;
            }
          }
          temp = repElement;
        }
      }
    });

    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }

  }


  onMoveEnd(event) {

  }

  moveUp(index) {
    const item = this.complexes[index];
    this.complexes.splice(index, 1);
    this.complexes.unshift(item);
    if (this.complexes[1]['pinned']) {
      this.complexes[0]['pinned'] = true;
    }
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  moveDown(index) {
    const item = this.complexes[index];
    item['pinned'] = false;
    this.complexes.splice(index, 1);
    this.complexes.push(item);
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }
  onSave() {
    const order = this.complexes.map(el => {
      return {
        'id': el.id,
        'pinned': el.pinned,
      };
    });
     this.http.post('Ordering.set_complexes', {'data': order}).then(
      (response) => {
        this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
      },
      (error) => { }
    );
  }
}
