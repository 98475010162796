export default {
  'new-employee': 'register-wizard/new-employee',
  'step_1': 'register-wizard/company/requisites',
  'step_2': 'register-wizard/persons',
  'step_3': 'register-wizard/requisites',
  'step_4': 'register-wizard/contacts',
  'step_5': 'register-wizard/documents',
  'step_6': 'register-wizard/final',
  'step_7': 'register-wizard/congrats',
};
