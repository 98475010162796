import {Component, forwardRef, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MaterialWysiwygDirective} from './material-wysiwyg.directive';
import {Md5} from 'ts-md5/dist/md5';

declare const tinymce: any, jQuery: any;

@Component({
  selector: 'app-wysiwyg-editor',
  templateUrl: './material-wysiwyg.component.html',
  styleUrls: ['./material-wysiwyg.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialWysiwygComponent),
      multi: true,
    }
  ]
})
export class MaterialWysiwygComponent implements ControlValueAccessor {

  @Input() public rows = 20;
  @Input() public placeholder = '';
  @Input() public require = false;

  @ViewChild(MaterialWysiwygDirective, {static: true}) public directive;

  public disabled = false;
  public id = Md5.hashStr('' + new Date() + Math.random());

  private _current_value;

  public get current_value(): any {
    return this._current_value;
  }

  public reloadEditor() {
    this.directive.loadEditor();
  }

  public set current_value(value: any) {
    this._current_value = value;
    this.propagateChange(this._current_value);
  }

  propagateChange = (_: any) => {};

  onChange(value: any) {
    this.current_value = value;
  }

  writeValue(value: any) {
    this.current_value = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
  }

  onChanges(target) {
    this.current_value = target.value;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  onWysiwygChange(value) {
    this.current_value = value;
  }

}
