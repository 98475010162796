import {AfterViewInit, Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '../../../services/http.service';
import {BreadcrumbsService} from '../../../shared/breadcrumbs/services';
import {GoBackButtonService} from '../../../services/go-back-button.service';

@Component({
  selector: 'app-entity-edit-certificate',
  templateUrl: './entity-edit-certificate.component.html',
  styleUrls: ['./entity-edit-certificate.component.scss']
})
export class EntityEditCertificateComponent implements OnInit, AfterViewInit {
  public data = null;
  public org_id = null;

  constructor(public activatedRoute: ActivatedRoute, public http: HttpClient, private b: BreadcrumbsService, public goBack: GoBackButtonService) {
    this.org_id = this.activatedRoute.snapshot.parent.params['id'];
    this.http.post('Calendar.get_certificate_training_company', {'org_id': this.org_id})
      .subscribe(data => this.data = data);
  }

  ngOnInit() {
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/physical/list', caption: 'Юридические лица'},
    ]);
  }
  ngAfterViewInit() {
    this.goBack.goScroll();
  }
}
