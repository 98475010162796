import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataTableParamComponent} from '../../../modules/@datatable/components/datatable-param.component';
import {HttpClient} from '../../../services/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BackrefWindowService} from '../../single-window/shared/backref-window.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {OverlayWindowService} from '../../single-window/shared/overlay-window.service';
import {debounceTime} from 'rxjs/operators';
import {NotificationService} from '../../../services/notification.service';
import {Subscription} from 'rxjs';
import {GoBackButtonService} from '../../../services/go-back-button.service';

@Component({
  templateUrl: './attraction-subagents.component.html',
  styleUrls: [
    './attraction-subagents.component.scss'
  ]
})
export class AttractionSubagentsComponent extends DataTableParamComponent implements OnInit, OnDestroy {

  public api_action = 'Organisation.get_data_subagent';
  public columns: Array<{ [key: string]: any }>;

  public opened = [];
  public subagent_id = null;
  public subagent_caption: string;
  public subagent_type: string;

  public window_caption: string;
  public window_text: string;

  public formGroup: UntypedFormGroup;


  private subscription: Subscription;
  private firstLoad = true;

  @ViewChild('orgIdHeadTmpl', {static: true}) private orgIdHeadTmpl: TemplateRef<any>;
  @ViewChild('orgCaptionHeadTmpl', {static: true}) private orgCaptionHeadTmpl: TemplateRef<any>;
  @ViewChild('orgPhysicalFioTmpl', {static: true}) private orgPhysicalFioTmpl: TemplateRef<any>;
  @ViewChild('orgPhysicalPhoneTmpl', {static: true}) private orgPhysicalPhoneTmpl: TemplateRef<any>;

  @ViewChild('physicalFio', {static: true}) private physicalFio: TemplateRef<any>;
  @ViewChild('entityCaption', {static: true}) private entityCaption: TemplateRef<any>;
  @ViewChild('access_idHead', {static: true}) private access_idHead: TemplateRef<any>;
  @ViewChild('access_idTmpl', {static: true}) private access_idTmpl: TemplateRef<any>;

  @ViewChild('partnerCertTmpl', {static: true}) private partnerCertTmpl: TemplateRef<any>;
  @ViewChild('resendPackageTmpl', {static: true}) private resendPackageTmpl: TemplateRef<any>;
  @ViewChild('docsPackTmpl', {static: true}) private docsPackTmpl: TemplateRef<any>;
  @ViewChild('typeHead', {static: true}) private typeHead: TemplateRef<any>;
  @ViewChild('typeTmpl', {static: true}) private typeTmpl: TemplateRef<any>;
  @ViewChild('commentHead', {static: true}) private commentHead: TemplateRef<any>;
  @ViewChild('commentTmpl', {static: true}) private commentTmpl: TemplateRef<any>;

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router,
              public fb: UntypedFormBuilder, public backrefWindowService: BackrefWindowService, private goBack: GoBackButtonService,
              private notify: NotificationService) {
    super(http, route, router);

    this.formGroup = this.fb.group({
      'org_id': [''],
      'org_caption': [''],
      'access_id': [''],
      'org_create': [''],
      'org_confirmed': [''],
      'notice': [''],
      'physical_fio': [''],
      'physical_phone': [''],
    });

    this.subscription = this.formGroup.valueChanges.pipe(debounceTime(500))
    .subscribe((data) => {
      this.router_param['page'] = 1;
      this.current_page = 1;
      if (this.firstLoad) {
        this.firstLoad = false;
      } else {
        this.changeForm(this.formGroup);
      }
    });

    this.updateForm(this.formGroup);
    this.backrefWindowService.routerInit();

  }

  ngOnInit() {
    this.goBack.header = '<h1>Субагенты</h1>';
    this.columns = [
      {'caption': '№', 'name': 'org_id', 'sortable': true, 'width': '90px',
        'templateHead': this.orgIdHeadTmpl},
      {'caption': 'Название орг-ции', 'name': 'org_caption', 'sortable': true,
        'templateHead': this.orgCaptionHeadTmpl, 'template': this.entityCaption},
      {'caption': 'Статус', 'name': 'access_id', 'sortable': true,
        'templateHead': this.access_idHead, 'template': this.access_idTmpl},
      {'caption': 'Дата создания', 'name': 'org_create', 'sortable': true},
      {'caption': 'Дата активации', 'name': 'org_confirmed', 'sortable': true},
      {'caption': 'Руководитель', 'name': 'physical_fio', 'sortable': true,
        'templateHead': this.orgPhysicalFioTmpl, 'template': this.physicalFio},
      {'caption': 'Телефон', 'name': 'physical_phone', 'sortable': true,
        'templateHead': this.orgPhysicalPhoneTmpl},
      {'caption': 'Сертификат', 'name': 'partner_cert', 'sortable': true,
        'template': this.partnerCertTmpl},
      {'caption': 'Отправить документы', 'name': 'partner_cert', 'sortable': false,
        'template': this.resendPackageTmpl},
      {'caption': 'Пакет документов', 'name': 'docs', 'sortable': false,
        'template': this.docsPackTmpl},
      {'caption': 'Комментарий', 'name': 'notice', 'sortable': true,
        'template': this.typeTmpl, 'templateHead': this.typeHead},
      {'caption': 'Статус', 'name': 'docs',
        'template': this.commentTmpl},
    ];

    super.ngOnInit();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    super.ngOnDestroy();
    this.backrefWindowService.routerDestroy();
  }

  loadData(id, type, caption, window_caption, window_text) {

    this.opened = [];
    this.subagent_id = id;
    this.subagent_caption = caption;
    this.subagent_type = type;
    this.window_caption = window_caption;
    this.window_text = window_text;

    this.opened[id] = true;

  }

  saveData(row) {

    const method = 'Attraction.update_notice';

    const options = {
      'subagent_id': row.org_id,
      'text': row.notice
    };

    this.http.post(method, options)
      .subscribe(_ => this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000));
  }

  prepareQuery() {
    const result = super.prepareQuery();

    result['filter']['fields'] = {
      'org_id': this.formGroup.value.org_id || null,
      'org_caption': this.formGroup.value.org_caption || null,
      'access_id': this.formGroup.value.access_id || null,
      'org_create': this.formGroup.value.org_create || null,
      'notice': this.formGroup.value.notice || null,
      'physical_fio': this.formGroup.value.physical_fio || null,
      'physical_phone': this.formGroup.value.physical_phone || null,
    };

    return result;
  }

  public resendPackage(org_id) {
    NotificationService.swalConfirm('Вы уверены?', 'Документы будут сформированы и отправлены еще раз. Продолжить?')
      .then(_ => {
        this.http.post('Organisation.resending_reg_docs', {'org_id': org_id})
          .subscribe(
            (_) => this.notify.notifySuccess('Поздравляем', 'Документы успешно отправлены', 3000),
            (error) => this.notify.notifyError('Ошибка', 'Не удалось отправить документы', 5000)
          );
      })
      .catch(_ => false);
  }


  WhenDataClosed() {

    this.subagent_id = null;
    this.opened = [];

    this.loadList();

  }

}
