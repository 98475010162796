import {AfterViewInit, Component, ContentChild, Input, Output, Renderer2, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-easy-dropdown',
  templateUrl: './easy-dropdown.component.html',
  styleUrls: [
    './easy-dropdown.component.scss'
  ],
})
export class EasyDropdownComponent {

  @Input() public cssClass = '';
  @Input() public titleText = '';
  public _activeDropdown = false;
  @Input('activeDropdown') set activeDropdown(value: boolean) {
    this._activeDropdown = value;
  }


  @Input() public minusPadding = false;
  @ContentChild(TemplateRef) templateRef;
  @ContentChild('header') header: TemplateRef<any>;


  constructor() {

  }

  trigger() {
    this.activeDropdown = !this._activeDropdown;
  }

}
