import {AfterContentInit, Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {HttpClient} from '../../../services/http.service';

declare const jQuery: any;

@Directive({
  selector: '[appMaterialInputFiasAddressEventDirective]'
})
export class MaterialInputFiasAddressEventDirective implements AfterContentInit {

  @Input('appMaterialInputFiasAddressEventDirective') private appMaterialInputFiasAddressEventDirective = '';
  @Input('nameValue') private nameValue = '';
  @Input('nameLabel') private nameLabel = '';
  @Output() public autocompleteChange: EventEmitter<string> = new EventEmitter();

  constructor(private elementRef: ElementRef, private http: HttpClient) { }

  ngAfterContentInit() {
    jQuery(this.elementRef.nativeElement).autocomplete({
      appendTo: this.elementRef.nativeElement.parentNode,
      select: (ux, obj) => {
        this.autocompleteChange.emit(obj.item);
      },
      source: (request, response) => {
        const data = {'value': jQuery(this.elementRef.nativeElement).val(), 'limit': 10};
        this.http.post(this.appMaterialInputFiasAddressEventDirective, data)
          .subscribe((r: Array<any>) => {
            if (!r.length) {
              response([{
                  'label': jQuery(this.elementRef.nativeElement).val(),
                  'value': '',
                  'DDD': '',
                  'XA': '',
                  'XCC': '',
                  'XGGG': '',
                  'XGGG2': '',
                  'XPPP': '',
                  'XRRR': '',
                  'XUUUU': '',
                  'XUUUU2': '',
                  'XUUUU3': '',
                  'lat': '',
                  'lon': '',
              }]
              );
            } else {
              response(r
                .map(x => {
                  return {
                    'label': x['label'] || jQuery(this.elementRef.nativeElement).val(),
                    'value': x['label'] || '',
                    'DDD': x['DDD'] || '',
                    'XA': x['XA'] || '',
                    'XCC': x['XCC'] || '',
                    'XGGG': x['XGGG'] || '',
                    'XGGG2': x['XGGG2'] || '',
                    'XPPP': x['XPPP'] || '',
                    'XRRR': x['XRRR'] || '',
                    'XUUUU': x['XUUUU'] || '',
                    'XUUUU2': x['XUUUU2'] || '',
                    'XUUUU3': x['XUUUU3'] || '',
                    'lat': x['lat'] || '',
                    'lon': x['lon'] || '',
                  };
                }));
            }
          });
      }
    });
  }
}
