import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MaterialInputModule} from '../../../modules/@material-input';
import {SharedModule} from '../../../shared/shared.module';
import {DataTableModule} from '../../../modules/@datatable/datatables.module';
import {AttractionLeadComponent} from './attraction-lead.component';
import {AttractionLeadPageCreateComponent} from './attraction-lead-page-create/attraction-lead-page-create.component';
import {AttractionLeadPageEditComponent} from './attraction-lead-page-edit/attraction-lead-page-edit.component';
import {AttractionLeadPageSubstepEditComponent} from './attraction-lead-page-edit/attraction-lead-page-substep-edit/attraction-lead-page-substep-edit.component';
import {AttractionLeadPageEditResolve} from './attraction-lead-page-edit/attraction-lead-page-edit.resolve';
import {RightWindowModule} from '../../../shared/right-window/right-window.module';
import {AttractionLeadInfoTodayResolve} from './attraction-lead-info/attraction-lead-info-today/attraction-lead-info-today.resolve';
import {AttractionLeadInfoAllResolve} from './attraction-lead-info/attraction-lead-info-all/attraction-lead-info-all.resolve';
import {AttractionLeadInfoResolve} from './attraction-lead-info/attraction-lead-info.resolve';
import {AttractionLeadInfoTodayComponent} from './attraction-lead-info/attraction-lead-info-today/attraction-lead-info-today.component';
import {AttractionLeadInfoComponent} from './attraction-lead-info/attraction-lead-info.component';
import {AttractionLeadInfoAllComponent} from './attraction-lead-info/attraction-lead-info-all/attraction-lead-info-all.component';
import {EntitySharedModule} from '../../entity/entity-shared.module';
import {PhysicalSharedModule} from '../../physical/physical-shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DataTableModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
    SharedModule,
    RightWindowModule,
    EntitySharedModule,
    PhysicalSharedModule,
  ],
  declarations: [
    AttractionLeadComponent,
    AttractionLeadPageCreateComponent,
    AttractionLeadPageEditComponent,
    AttractionLeadPageSubstepEditComponent,
    AttractionLeadInfoComponent,
    AttractionLeadInfoTodayComponent,
    AttractionLeadInfoAllComponent,
  ],
  providers: [
    AttractionLeadPageEditResolve,
    AttractionLeadInfoResolve,
    AttractionLeadInfoAllResolve,
    AttractionLeadInfoTodayResolve,
  ]
})
export class AttractionLeadModule { }
