<div class="filter_state">
  <div class="filter_state__tab all_work" (click)="filter_state('all_work')" [ngClass]="{'active': formGroup.value.tab === 'all_work'}">
    Все в работе ({{data.all_work}})
  </div>
  <div class="filter_state__tab done" (click)="filter_state('done')" [ngClass]="{'active': formGroup.value.tab === 'done'}">
    Подтверждены ({{data.done}})
  </div>
  <div class="filter_state__tab fail mr-2r" (click)="filter_state('fail')" [ngClass]="{'active': formGroup.value.tab === 'fail'}">
    Отклонены ({{data.fail}})
  </div>
  <div class="filter_state__create mr-2r">
    <form [formGroup]="formGroup" novalidate>
      <div class="row no-gutters gutters-0">
        <div class="col-sm-6">
          <app-material-input-datepicker formControlName="date_start" [cssClass]="'mb-0'" [placeholder]="'Уникальность с'">
          </app-material-input-datepicker>
        </div>
        <div class="col-sm-6">
          <app-material-input-datepicker formControlName="date_end" [cssClass]="'mb-0'" [placeholder]="'Уникальность до'">
          </app-material-input-datepicker>
        </div>
      </div>
    </form>
  </div>
  <div class="filter_state__create">
    <form [formGroup]="formGroup" novalidate>
      <div class="row no-gutters gutters-0">
        <div class="col-sm-6">
          <app-material-input-datepicker formControlName="date_change_start" [cssClass]="'mb-0'" [placeholder]="'Изменён с'">
          </app-material-input-datepicker>
        </div>
        <div class="col-sm-6">
          <app-material-input-datepicker formControlName="date_change_end" [cssClass]="'mb-0'" [placeholder]="'Изменён до'">
          </app-material-input-datepicker>
        </div>
      </div>
    </form>
  </div>
</div>

Всего: <b>{{theadline.showed_lines}}</b>
<div class="material-datatable-wrapper table-color">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)">
  </app-datatable-headline>
  <div class="table-responsive">
    <table class="table datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns"
             [rowClass]="'tab'">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>

<ng-template #subagent_physical_fioHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="subagent_physical_fio" [cssClass]="'mb-0'" [placeholder]="'Субагент'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #subagent_physical_fioTmpl let-row="row" let-ind="row_index">
  <div [ngClass]="{'activeBox': goBack.activeHash === 'subagent_physical_fio-' + ind}">
    <a href="/physical/edit/{{row.subagent_physical_id}}"
       (click)="goBack.newLink('/physical/edit/' + row.subagent_physical_id, 'subagent_physical_fio-' + ind, $event)">
      {{row.subagent_physical_fio}}
    </a>
  </div>
</ng-template>

<ng-template #subagent_physical_phoneHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="subagent_physical_phone" [cssClass]="'mb-0'" [placeholder]="'Тел. субагента'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #lead_physical_phoneHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="lead_physical_phone" [cssClass]="'mb-0'" [placeholder]="'Тел. клиента'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #lead_physical_fioHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="lead_physical_fio" [cssClass]="'mb-0'" [placeholder]="'Клиент'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #lead_physical_fioTmpl let-row="row" let-ind="row_index">
  <ng-container *ngIf="row.lead_id !== ''">
    <div [ngClass]="{'activeBox': goBack.activeHash === 'lead_physical_fio-' + ind}">
      <a href="/reservation/lead-info/{{row.lead_id}}"
         (click)="goBack.newLink('/reservation/lead-info/' + row.lead_id, 'lead_physical_fio-' + ind, $event)">
        {{row.lead_physical_fio}}
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="row.lead_id === ''">
    {{row.lead_physical_fio}}
  </ng-container>
</ng-template>

<ng-template #object_captionHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="object_caption" [cssClass]="'mb-0'" [placeholder]="'Объект'">
    </app-material-input>
  </form>
</ng-template>
<ng-template #object_captionTmpl let-row="row" let-ind="row_index">
  <ng-container *ngIf="row.flat_id">
    <div [ngClass]="{'activeBox': goBack.activeHash === 'object_caption-' + ind}">
      <a href="/housing2/flat/{{row.flat_id}}"
         (click)="goBack.newLink('/housing2/flat/' + row.flat_id, 'object_caption-' + ind, $event)">
        {{row.object_caption}}
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="!row.flat_id">
    <div [ngClass]="{'activeBox': goBack.activeHash === 'object_caption-' + ind}">
      <a href="/housing/house/{{row.housing_id}}"
         (click)="goBack.newLink('/housing/house/' + row.housing_id, 'object_caption-' + ind, $event)">
        {{row.object_caption}}
      </a>
    </div>
  </ng-container>
</ng-template>

<ng-template #manager_fioHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="manager_fio" [cssClass]="'mb-0'" [placeholder]="'Ответственный'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #manager_fioTmpl let-row="row" let-ind="row_index">
  <div [ngClass]="{'activeBox': goBack.activeHash === 'manager_fio-' + ind}">
    <a href="/managers/edit/{{row.manager_id}}"
       (click)="goBack.newLink('/managers/edit/' + row.manager_id, 'manager_fio-' + ind, $event)">
      {{row.manager_fio}}
    </a><br>
    {{row.manager_phone}}
  </div>
</ng-template>

<ng-template #subagent_organisation_captionTmpl let-row="row" let-ind="row_index">
  <div [ngClass]="{'activeBox': goBack.activeHash === 'subagent_organisation_caption-' + ind}">
    <a href="/entity/edit/{{row.subagent_organisation_id}}"
       (click)="goBack.newLink('/entity/edit/' + row.subagent_organisation_id, 'subagent_organisation_caption-' + ind, $event)">
      {{row.subagent_organisation_caption}}
    </a>
  </div>
</ng-template>

<ng-template #stage_captionHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="stage_caption" [cssClass]="'mb-0'" [placeholder]="'Этап'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #stage_captionTmpl let-row="row">
  <app-stages-dots [data]="row.stage_information"></app-stages-dots>
</ng-template>

<ng-template #subagent_organisation_captionHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="subagent_organisation_caption" [cssClass]="'mb-0'" [placeholder]="'Организация'">
    </app-material-input>
  </form>
</ng-template>
