<form [formGroup]="form" *ngIf="posts && form">
  <div formGroupName="organisations" class="relationship__list">
    <div formArrayName="relationships">
      <div
        class="relationship__element"
        [formGroupName]="i"
        *ngFor="let item of relationships.controls; index as i">
        <div class="row">
          <div class="col-6">
            <app-material-select2-autocomplete
              formControlName="organisation_id"
              [require]="true"
              [placeholder]="'Название организации'"
              [caption]="'Организация'"
              [api_action]="'Organisation.search'">
            </app-material-select2-autocomplete>
          </div>
          <div class="col-6">
            <app-material-select2
              formControlName="post_id"
              [caption]="'Должность'"
              [require]="true">
              <option [value]="post.post_id" *ngFor="let post of posts">
                {{post.post_caption}}
              </option>
            </app-material-select2>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<br>
<button class="button button_sm" (click)="appendRelationship()">Добавить связь</button>
