import {Injectable} from '@angular/core';
import {HttpClient} from '../../../services/http.service';


@Injectable()
export class ReglTableService {

  constructor(private http: HttpClient) { }

  public saveFiles(complex_ids, images, type_id) {
    let resolver = (...args) => null;
    let rejecter = (...args) => null;

    const promise = new Promise<any>((resolve, reject) => {
      resolver = resolve;
      rejecter = reject;
    });

    const request = {'complex_ids': complex_ids, 'files': images, 'type_id': type_id, };

    this.http.post('Files.upload_reglaments_acts_realty', request)
      .subscribe(
        response => resolver(response),
        error => rejecter(error),
      );

    return promise;
  }

}
