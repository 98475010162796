import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared.module';
import { MaterialInputModule } from '../../../../modules/@material-input';
import { PopoverModule } from '../../../../modules/@popover/popober.module';
import { TabsModule } from '../../../../modules/@tabs/tabs.module';
import { AlternativeModule } from '../../../alternative/alternative.module';
import { PreloaderModule } from '../../../../modules/@preloader/preloader.module';
import { AbleproDirectivesModule } from '../../../../_front/core/ablepro-directives.module';
import { FlatComponent } from './flat.component';
import { FlatLayoutComponent } from './flat-layout.component';
import { FlatOperationsComponent } from './flat-operations.component';
import { FlatCommonComponent } from './flat-common.component';
import { FlatFloorsComponent } from './flat-floors.component';
import { FlatImagesComponent } from './flat-images.component';
import { FlatAdvancedComponent } from './flat-advanced.component';
import { FlatProprietorsComponent } from './flat-proprietors.component';
import { FlatConditionsComponent } from './flat-conditions.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
    PopoverModule,
    TabsModule,
    AlternativeModule,
    PreloaderModule,
    AbleproDirectivesModule,
  ],
  declarations: [
    FlatComponent,
    FlatCommonComponent,
    FlatAdvancedComponent,
    FlatProprietorsComponent,
    FlatLayoutComponent,
    FlatFloorsComponent,
    FlatImagesComponent,
    FlatOperationsComponent,
    FlatConditionsComponent
  ],
  exports: [
    FlatComponent,
    FlatCommonComponent,
    FlatAdvancedComponent,
    FlatProprietorsComponent,
    FlatLayoutComponent,
    FlatFloorsComponent,
    FlatImagesComponent,
    FlatOperationsComponent,
    FlatConditionsComponent
  ]
})
export class FlatModule {
}
