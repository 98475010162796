import { NgModule } from '@angular/core';

import { NotificationAlertComponent } from './notification-alert.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [],
  declarations: [NotificationAlertComponent],
  providers: [],
})
export class NotificationAlertModule {
}
