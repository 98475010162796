import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {JsonClientService} from '../json-client';

function getOptions(route: ActivatedRouteSnapshot) {

  const filter_columns = [
    'author_fio',
    'author_organisation',
    'owner_fio',
    'owner_organisation',
    'cause_caption',
    'resale_realty_caption',
    'new',
    'is_archive'
  ];

  const prepared_filter = {
    };
    const prepared_filter_keys = filter_columns.filter(x => !!route.parent.queryParams[x]);
    const sort = {};

    prepared_filter_keys.forEach(x => {
      let _x = route.parent.queryParams[x];
      if (_x === 'true' || _x === 'false') {
        _x = _x === 'true';
      }
      prepared_filter[x] = _x;
    });

    if (!!route.queryParams['sort_name']) {
      sort[route.queryParams['sort_name']] = route.queryParams['sort_key'] || 'asc';
    } else {
      sort['create_date'] = 'desc';
    }

    return {
      'limit': 50,
      'offset': (+(route.queryParams['page'] || 1) - 1) * 50,
      '_filter': {
        'fields': prepared_filter,
      },
      'sort': sort,
      'is_subagent': null
    };
}

@Injectable()
export class ComplaintsDatatableResolve {
  constructor(public jsonRpc: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {

    const options = getOptions(route);

    return this.jsonRpc.post('Data_tables.get_complaints', options);
  }
}

@Injectable()
export class ComplaintsDatatableSubagentResolve extends ComplaintsDatatableResolve {

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    const options = getOptions(route);
    options.is_subagent = true;
    return this.jsonRpc.post('Data_tables.get_complaints', options);
  }

}

@Injectable()
export class CauseDatatableResolve {

  constructor(private jsonRpc: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    return this.jsonRpc.post('Dict.get_cause', {});
  }
}

@Injectable()
export class ComplaintsCrmPartTrue {

  constructor() { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    return true;
  }
}

@Injectable()
export class ComplaintsCrmPartFalse {

  constructor() { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    return false;
  }
}
