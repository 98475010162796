import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HelpWindowComponent} from './components/common/help-window.component';
import {HelpDealsComponent} from './components/extends/deals/help-deals.component';
import {PageSliderModule} from '../../modules/@page-slider/page-slider.module';
import {HelpDealsViewsComponent} from './components/extends/deals-views/help-deals-views.component';
import {ModalHelpModule} from '../../modules/@modal-help/modal-help.module';
import {HelpWindowService} from './services/help-window.service';
import {HelpDealsQueuesComponent} from './components/extends/deals-queues/help-deals-queues.component';
import {HelpDealsReservesComponent} from './components/extends/deals-reserves/help-deals-reserves.component';
import {HelpDealsClientsComponent} from './components/extends/deals-clients/help-deals-clients.component';
import {HelpSubagentOrganisationComponent} from './components/extends/subagent_organisation/help-subagent-organisation.component';
import {HelpSubagentWorkersComponent} from './components/extends/subagent_workers/help-subagent-workers.component';
import {HelpSubagentTariffComponent} from './components/extends/subagent_tariff/help-subagent-tariff.component';
import {HelpSubagentActionsComponent} from './components/extends/subagent_actions/help-subagent-actions.component';
import {HelpCalendarListComponent} from './components/extends/calendar_list/help-calendar-list.component';
import {HelpIntegrationIndexComponent} from './components/extends/integration_index/help-integration-index.component';
import {HelpIframeListComponent} from './components/extends/iframe_list/help-iframe-list.component';
import {HelpAdListComponent} from './components/extends/ad_list/help-ad-list.component';
import {HelpSettingsIndexComponent} from './components/extends/settings_index/help-settings-index.component';
import {HelpSupportIndexComponent} from './components/extends/support_index/help-support-index.component';
import {HelpWorkersProfileComponent} from './components/extends/workers_profile/help-workers-profile.component';
import {HelpObjectsHousingRadarComponent} from './components/extends/objects_housing_radar/help-objects-housing-radar.component';
import {HelpHousingFacadesComponent} from './components/extends/housing_facades/help-housing-facades.component';
import {HelpHousingMapComponent} from './components/extends/housing_map/help-housing-map.component';
import {HelpDealsUniqueComponent} from './components/extends/deals-unique/help-deals-unique.component';
import {HelpResaleComponent} from './components/extends/resale/help-resale.component';

@NgModule({
  imports: [
    CommonModule,
    PageSliderModule,
    ModalHelpModule,
  ],
  declarations: [
    HelpWindowComponent,
    HelpDealsComponent,
    HelpDealsUniqueComponent,
    HelpDealsViewsComponent,
    HelpDealsQueuesComponent,
    HelpDealsReservesComponent,
    HelpDealsClientsComponent,
    HelpSubagentOrganisationComponent,
    HelpSubagentWorkersComponent,
    HelpSubagentTariffComponent,
    HelpSubagentActionsComponent,
    HelpCalendarListComponent,
    HelpIntegrationIndexComponent,
    HelpIframeListComponent,
    HelpHousingFacadesComponent,
    HelpHousingMapComponent,
    HelpAdListComponent,
    HelpSettingsIndexComponent,
    HelpSupportIndexComponent,
    HelpWorkersProfileComponent,
    HelpObjectsHousingRadarComponent,
    HelpResaleComponent,
  ],
  providers: [
    // HelpWindowService,
  ],
  exports: [
    HelpDealsComponent,
    HelpDealsUniqueComponent,
    HelpDealsViewsComponent,
    HelpDealsQueuesComponent,
    HelpDealsReservesComponent,
    HelpDealsClientsComponent,
    HelpSubagentOrganisationComponent,
    HelpSubagentWorkersComponent,
    HelpSubagentTariffComponent,
    HelpSubagentActionsComponent,
    HelpCalendarListComponent,
    HelpIntegrationIndexComponent,
    HelpIframeListComponent,
    HelpHousingFacadesComponent,
    HelpHousingMapComponent,
    HelpAdListComponent,
    HelpSettingsIndexComponent,
    HelpSupportIndexComponent,
    HelpWorkersProfileComponent,
    HelpObjectsHousingRadarComponent,
    HelpResaleComponent,
  ]
})
export class HelpWindowModule { }
