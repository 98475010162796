<div class="pb-4">
  <div class="housing-2__content-wrapper-element">
    <h2>Расположение</h2>
    <p>Укажите адрес и убедитесь, что точка окажется в правильном месте.
      Если необходимо, точку можно перемещать мышью. При публикации именно
      эти точка на карте и адрес будут показываться на объекте.</p>

    <div [formGroup]="form">
      <div class="row">
          <div class="col-md-12">
            <app-input-get-address
              formControlName="address"
              [required]="true">
              Адрес
            </app-input-get-address>
          </div>
      </div>
    </div>

    <ng-container *ngIf="form.value['address'].label">
      <div class="row">
        <div class="col-md-12 d-none">
          <div class="form__group">
            <div class="form__input">
              <input class="input" name="city" [(ngModel)]="form.value['address'].city" placeholder="Город">
            </div>
          </div>
        </div>
        <ng-container *ngIf="districts">
          <div [formGroup]="form" class="col-md-12" [class.d-none]="without_districts">
            <app-material-select2 [caption]="'Административный округ'" formControlName="city_admin_area_id">
              <option [value]="''">-- Не выбрано --</option>
              <optgroup label = "{{obj.caption}}" *ngFor="let obj of districts">
                <option *ngFor="let el of obj.areas" [value]="el.id">
                  {{el.caption}} - {{obj.short_caption}}</option>
              </optgroup>
            </app-material-select2>
          </div>
        </ng-container>
        <div class="col-md-12 d-none">
          <div class="form__group">
            <div class="form__input">
              <input class="input" name="district" [(ngModel)]="form.value['address'].district" placeholder="Район">
            </div>
          </div>
        </div>
        <div class="col-md-12 d-none">
          <div class="form__group">
            <div class="form__input">
              <input class="input" name="region" [(ngModel)]="form.value['address'].region" placeholder="Регион">
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div [formGroup]="form">
    <div>
      <ng-container *ngIf="resetMapVal">
        <osm-map [center]="[latitude, longitude]">
          <osm-drawer
            *ngIf="drawable"
            [polygons]="form.value['address']['advance_address_json']"
            (onCreate)="polygonCreate($event)">
          </osm-drawer>
          <osm-placemark
            *ngIf="latitude && longitude"
            [coordinates]="[latitude, longitude]"
            [draggable]="true"
            (drag)="onPlacemarkDrag($event)">
          </osm-placemark>
        </osm-map>

      </ng-container>
    </div>
  </div>

  <div class="housing-2__content-wrapper-element mt-2r">
    <h2 class="subway-h2">Ближайшие метро</h2>
    <p>При публикации объекта, данные станции будут использоваться при поиске.</p>
    <div [formGroup]="form">
      <field-housing-subway
        [form]="form"
        [field_name]="'subways'"
        [values]="subways">
      </field-housing-subway>
    </div>
  </div>
  <div class="housing-2__content-wrapper-element mt-2r">
    <h2 class="highway-h2">Шоссе</h2>
    <div [formGroup]="form">
      <field-housing-highway
        [form]="form"
        [field_name]="'highways'"
        [values]="highways">
      </field-housing-highway>
    </div>
  </div>
</div>
