import {Component, ComponentRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {OverlayWindowService} from '../../../_crm/single-window/shared/overlay-window.service';
import {CalendarTeachingUsersComponent} from './calendar-teaching-users.component';
import {CalendarTeachingPopupComponent} from './calendar-teaching-popup.component';
import {GoBackButtonService} from '../../../services/go-back-button.service';

@Component({
  templateUrl: './calendar-teaching.component.html',
  styleUrls: ['./calendar-teaching.component.scss']
})
export class CalendarTeachingComponent implements OnInit {

  private _statistic2;

  public statistic;
  public api_hostname = environment.apiServer;

  public chart_organisations = [ ];
  public chart_users = [ ];

  public get statistic2() {
    return this._statistic2;
  }

  public set statistic2(value) {
    this._statistic2 = value;

    this.chart_organisations = [
      ['Статус', 'Количество'],
      ['Компании, прошедшие обучение', value['all_organisation']['cnt_fact_study_organisations']],
      ['Компании, не прошедшие обучение', value['all_organisation']['cnt_fact_all_organisations'] -
        value['all_organisation']['cnt_fact_study_organisations']],
    ];

    this.chart_users = [
      ['Статус', 'Количество'],
      ['Пользователи, прошедшие обучение', value['all_organisation']['cnt_fact_approve_users']],
      ['Пользователи, не прошедшие обучение', value['all_organisation']['cnt_fact_all_users'] -
        value['all_organisation']['cnt_fact_approve_users']],
    ];
  }

  @ViewChild('staffList', {static: true}) private staffList;
  @ViewChild('teachedList', {static: true}) private teachedList;
  @ViewChild('refusalList', {static: true}) private refusalList;

  public columns1;

  public columns2;

  constructor(private activatedRoute: ActivatedRoute, private overlayWindowService: OverlayWindowService,
              private goBack: GoBackButtonService) { }

  ngOnInit() {
    this.goBack.header = '<h1>Обучение пользователей</h1>';
    this.statistic = this.activatedRoute.snapshot.data['statistic'];
    this.statistic2 = this.activatedRoute.snapshot.data['statistic2'];

    this.columns1 = [
      {'caption': 'Организация', 'name': 'organisation_socrachcaption', 'sortable': false},
      {'caption': 'ФИО директора', 'name': 'physical_fio', 'sortable': false},
      {'caption': 'Телефон', 'name': 'physical_phone', 'sortable': false},
      {'caption': 'Эл. почта', 'name': 'physical_email', 'sortable': false},
      {'caption': 'Пользователей', 'name': 'cnt_staff', 'sortable': false, 'template': this.staffList},
      {'caption': 'Обучено', 'name': 'cnt_approve', 'sortable': false, 'template': this.teachedList},
      {'caption': 'Необучено', 'name': 'cnt_approve', 'sortable': false, 'template': this.refusalList},
    ];

    this.columns2 = [
      {'caption': 'ФИО', 'name': 'physical_fio', 'sortable': true},
      {'caption': 'Организация', 'name': 'organisation_socrachcaption', 'sortable': true},
      {'caption': 'Телефон', 'name': 'physical_phone', 'sortable': true},
      {'caption': 'Эл. почта', 'name': 'physical_email', 'sortable': true},
      {'caption': 'Пользователей', 'name': 'cnt_staff', 'sortable': false, 'template': this.staffList},
      {'caption': 'Обучено', 'name': 'cnt_approve', 'sortable': false, 'template': this.teachedList},
      {'caption': 'Необучено', 'name': 'cnt_approve', 'sortable': false, 'template': this.refusalList},
      {'caption': '% Обученных', 'name': 'approve_users_perc', 'sortable': false},
    ];
  }

  displayStaff(row) {
    this.displayWindow(row['staff_list'], 'staff');
  }

  displayTeached(row) {
    this.displayWindow(row['approve_list'], 'teached');
  }

  displayRefusal(row) {
    this.displayWindow(row['refusal_list'], 'refusal');
  }

  chartCompanySelection(value) {

    if (!value) {
      return;
    }

    // 0 - Прошедшие обучение
    // 1 - НЕ прошедшие обучение
    this.overlayWindowService.openComponentInPopup(CalendarTeachingPopupComponent)
      .then((componentRef: ComponentRef<CalendarTeachingPopupComponent>) => {
        componentRef.instance.teached = 0 === value['row'];
      });
  }

  private displayWindow(list, type) {
    this.overlayWindowService.openComponentInPopup(CalendarTeachingUsersComponent)
      .then((componentRef: ComponentRef<CalendarTeachingUsersComponent>) => {
         componentRef.instance.list = list;
         componentRef.instance.type = type;
      });
  }

}
