import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient} from '../../../services/http.service';

@Injectable()
export class HousingPhotoreportsResolve {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const realty_id = +route.parent.params['id'];
    return this.http.post('Photoreport.get_by_realty', {'realty_id': realty_id});
  }
}
