import {NgModule} from '@angular/core';
import {SettingsConstraintsComponent} from './settings-constraints.component';
import {CommonModule} from '@angular/common';
import {DataTableModule} from '../../../modules/@datatable/datatables.module';
import {SettingsConstraintsTableComponent} from './settings-constraints-table.component';
import {SettingsConstraintsElementComponent} from './settings-constraints-element.component';
import {SettingsConstraintsSubagentsComponent} from './settings-constraints-subagents.component';
import {RouterModule} from '@angular/router';
import {
  SettingsConstraintsConsolidatedResolve, SettingsConstraintsElementResolve
} from './settings-constraints-element.resolve';
import {SimpleTableModule} from '../../../modules/@simple-table/simple-table.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialInputModule} from '../../../modules/@material-input';
import {SettingsConstraintsElementPipe} from './settings-constraints-element.pipe';
import {PreloaderModule} from '../../../modules/@preloader/preloader.module';
import {SettingsIndexComponent} from '../index/settings-index.component';
import {SettingsRolesComponent} from '../roles/settings-roles.component';
import {SettingsRolesResolve} from '../roles/settings-roles.resolve';
import {ControlModule} from '../../../_crm/control/control.module';


@NgModule({
  imports: [
    CommonModule,
    DataTableModule,
    RouterModule,
    SimpleTableModule,
    FormsModule,
    PreloaderModule,
    ReactiveFormsModule,
    MaterialInputModule,
    ControlModule,
  ],
  declarations: [
    SettingsConstraintsComponent,
    SettingsConstraintsTableComponent,
    SettingsConstraintsElementComponent,
    SettingsConstraintsElementPipe,
    SettingsConstraintsSubagentsComponent,
    SettingsIndexComponent,
    SettingsRolesComponent,
  ],
  exports: [
    SettingsConstraintsComponent,
    SettingsConstraintsElementComponent,
    SettingsConstraintsSubagentsComponent,
  ],
  providers: [
    SettingsConstraintsElementResolve,
    SettingsConstraintsConsolidatedResolve,
    SettingsRolesResolve,
  ],
})
export class SettingsConstraintsModule { }
