<div class="analytic__filter">
  <form class="row align-items-center  no-gutters gutters-0" [formGroup]="form" (submit)="submit()">
    <div class="col-12 col-md-auto row no-gutters gutters-0">
      <div class="col-md-6">
        <app-material-input-datepicker
          formControlName="start">
          Дата начала
        </app-material-input-datepicker>
      </div>
      <div class="col-md-6">
        <app-material-input-datepicker
          formControlName="end">
          Дата окончания
        </app-material-input-datepicker>
      </div>
    </div>
    <ng-container *ngIf="lock_type === 'common_attraction'">
      <div class="col-12"></div>
      <div class="col-12 col-md-5">
        <app-material-select2 formControlName="bp_source" [caption]="'Источник лида'">
          <option [value]="null">-- Не выбрано --</option>
          <option *ngFor="let option of leadSources" [value]="option.id">
            {{option.caption}}
          </option>
        </app-material-select2>
      </div>
    </ng-container>
    <ng-container *ngIf="lock_type === 'report-get-sub-activity'">
      <div class="col-12"></div>
      <div class="col-12 col-md-auto mt-3">
        <app-material-input-radio formControlName="result_type"
                                  [cssClass]="'housing'"
                                  [data]="{'months': 'Месяцы', 'weeks': 'Недели'}"
                                  [suppressLabel]="true"
        ></app-material-input-radio>
      </div>
      <div class="col-12 col-md-auto">
        <app-material-input-datepicker
          formControlName="sub_date_from">
          Новые субагенты начиная с
        </app-material-input-datepicker>
      </div>
    </ng-container>
    <ng-container *ngIf="lock_type === 'report-get-sub-user-activity'">
      <div class="col-12"></div>
      <div class="col-12 col-md-auto mt-3">
        <app-material-input-radio formControlName="result_type"
                                  [cssClass]="'housing'"
                                  [data]="{'months': 'Месяцы', 'weeks': 'Недели'}"
                                  [suppressLabel]="true"
        ></app-material-input-radio>
      </div>
      <div class="col-12 col-md-auto">
        <app-material-input-datepicker [disabled]="true"
          formControlName="sub_date_from">
          Новые субагенты начиная с
        </app-material-input-datepicker>
      </div>
    </ng-container>
    <ng-container *ngIf="lock_type === 'report-get-activity-complex-id'">
      <div class="col-12"></div>
      <div class="col-12 col-md-auto mt-3">
        <app-material-input-radio formControlName="result_type"
                                  [cssClass]="'housing'"
                                  [data]="{'months': 'Месяцы', 'weeks': 'Недели'}"
                                  [suppressLabel]="true"
        ></app-material-input-radio>
      </div>
    </ng-container>
    <ng-container *ngIf="lock_type === 'report-get-complex-activity'">
      <div class="col-12"></div>
      <div class="col-12 col-md-auto mt-3">
        <app-material-input-radio formControlName="result_type"
                                  [cssClass]="'housing'"
                                  [data]="{'months': 'Месяцы', 'weeks': 'Недели'}"
                                  [suppressLabel]="true"
        ></app-material-input-radio>
      </div>
      <div class="col-12 col-md-3">
        <app-material-select2 [caption]="'Тип комплекса'" formControlName="complex_type">
          <option [value]="'all'">все ЖК</option>
          <option [value]="'internal'">только внутренние ЖК (пришедшие из импорта estatet)</option>
          <option [value]="'external'">только внешние ЖК (не из импорта estatet)</option>
        </app-material-select2>
      </div>
      <div class="col-12 col-md-auto mt-3">
        <app-material-input-checkbox formControlName="events">Только с событиями за период</app-material-input-checkbox>
      </div>
      <div class="col-12 col-md-auto mt-3">
        <app-material-input-checkbox formControlName="vitrina">ЖК только на витрине</app-material-input-checkbox>
      </div>
    </ng-container>

    <div class="col-auto mt-2">
      <button
        type="submit"
        class="button button_crm"
        [disabled]="!form.valid">
          Показать
      </button>
    </div>
  </form>
</div>
