import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {OverlayWindowService} from '../../single-window/shared/overlay-window.service';
import { ActivatedRoute, Router } from '@angular/router';
import {BackrefWindowService} from '../../single-window/shared/backref-window.service';
import {HttpClient} from '../../../services/http.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {DataTableParamComponent} from '../../../modules/@datatable/components/datatable-param.component';
import {NotificationService} from '../../../services/notification.service';
import {GoBackButtonService} from '../../../services/go-back-button.service';

@Component({
  selector: 'app-attraction-status-created',
  templateUrl: './attraction-status-created.component.html',
  styleUrls: [
    './attraction-status-created.component.scss'
  ]
})
export class AttractionStatusCreatedComponent extends DataTableParamComponent implements OnInit, OnDestroy {

  public api_action = 'Data_tables.attraction_subagents_status_1';
  public columns: Array<{ [key: string]: any }>;
  public formGroup: UntypedFormGroup;
  public createSubscription: Subscription;
  public opened = [];
  public subagent_id = null;
  public subagent_caption: string;
  public subagent_type: string;

  public window_caption: string;
  public window_text: string;

  @ViewChild('IdHead', {static: true}) private IdHead: TemplateRef<any>;
  @ViewChild('timeHead', {static: true}) private timeHead: TemplateRef<any>;
  @ViewChild('regHead', {static: true}) private regHead: TemplateRef<any>;
  @ViewChild('orgHead', {static: true}) private orgHead: TemplateRef<any>;
  @ViewChild('orgTmpl', {static: true}) private orgTmpl: TemplateRef<any>;
  @ViewChild('managerHead', {static: true}) private managerHead: TemplateRef<any>;
  @ViewChild('managerTmpl', {static: true}) private managerTmpl: TemplateRef<any>;
  @ViewChild('phoneHead', {static: true}) private phoneHead: TemplateRef<any>;
  @ViewChild('emailHead', {static: true}) private emailHead: TemplateRef<any>;
  @ViewChild('entityHead', {static: true}) private entityHead: TemplateRef<any>;
  @ViewChild('typeHead', {static: true}) private typeHead: TemplateRef<any>;
  @ViewChild('typeTmpl', {static: true}) private typeTmpl: TemplateRef<any>;
  @ViewChild('commentTmpl', {static: true}) private commentTmpl: TemplateRef<any>;

  private subscription: Subscription;
  private firstLoad = true;

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router, private goBack: GoBackButtonService,
              public fb: UntypedFormBuilder, private overlayWindowService: OverlayWindowService, public notify: NotificationService,
              public backrefWindowService: BackrefWindowService) {
    super(http, route, router);
    this.formGroup = this.fb.group({
      'subagent_boss_id': [''],
      'status_modified_time': [''],
      'create_date': [''],
      'subagent_caption': [''],
      'subagent_boss_fio': [''],
      'subagent_boss_phone': [''],
      'caption': [''],
      'subagent_boss_email': [''],
      'subagent_address': [''],
      'notice': [''],
    });
    this.subscription = this.formGroup.valueChanges.pipe(debounceTime(500))
      .subscribe((data) => {
        this.router_param['page'] = 1;
        this.current_page = 1;
        if (this.firstLoad) {
          this.firstLoad = false;
        } else {
          this.changeForm(this.formGroup);
        }
      });

    this.updateForm(this.formGroup);
    this.backrefWindowService.routerInit();
  }

  ngOnInit() {
    this.goBack.header = '<h1>Создан. Ожидается заполнение мастера</h1>';
    this.columns = [
      {'caption': '№', 'name': 'subagent_boss_id', 'sortable': true, 'width': '90px',
        'templateHead': this.IdHead},
      {'caption': '№', 'name': 'status_modified_time', 'sortable': true, 'width': '90px',
        'templateHead': this.timeHead},
      {'caption': 'Регистрация', 'name': 'create_date', 'sortable': true, 'width': '90px',
        'templateHead': this.regHead},
      {'caption': 'Организация', 'name': 'subagent_caption', 'sortable': true, 'width': '140px',
        'template': this.orgTmpl, 'templateHead': this.orgHead},
      {'caption': 'Руководитель', 'name': 'subagent_boss_fio', 'sortable': true,
        'template': this.managerTmpl, 'templateHead': this.managerHead},
      {'caption': 'Телефон', 'name': 'subagent_boss_phone', 'sortable': true,
        'templateHead': this.phoneHead},
      {'caption': 'Email', 'name': 'subagent_boss_email', 'sortable': true,
        'templateHead': this.emailHead},
      {'caption': 'Юр. адреса', 'name': 'subagent_address', 'sortable': true,
        'templateHead': this.entityHead},
      {'caption': 'Комментарий', 'name': 'notice', 'sortable': true,
        'template': this.typeTmpl, 'templateHead': this.typeHead},
      {'caption': 'Статус', 'name': 'status_caption',
        'template': this.commentTmpl},
    ];
    super.ngOnInit();
  }

  saveData(row) {

    const method = 'Attraction.update_notice';

    const options = {
      'subagent_id': row.subagent_id,
      'text': row.notice
    };

    this.http.post(method, options)
      .subscribe(_ => this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000));
  }

  loadData(id, type, caption, window_caption, window_text) {

    this.opened = [];
    this.subagent_id = id;
    this.subagent_caption = caption;
    this.subagent_type = type;
    this.window_caption = window_caption;
    this.window_text = window_text;

    this.opened[id] = true;

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    super.ngOnDestroy();
    this.backrefWindowService.routerDestroy();
  }

  prepareQuery() {
    const result = super.prepareQuery();
    result['filter']['fields'] = {
      'subagent_boss_id': this.formGroup.value.subagent_boss_id,
      'status_modified_time': this.formGroup.value.status_modified_time,
      'create_date': this.formGroup.value.create_date,
      'subagent_caption': this.formGroup.value.subagent_caption,
      'subagent_boss_fio': this.formGroup.value.subagent_boss_fio,
      'subagent_boss_phone': this.formGroup.value.subagent_boss_phone,
      'caption': this.formGroup.value.caption,
      'subagent_boss_email': this.formGroup.value.subagent_boss_email,
      'subagent_address': this.formGroup.value.subagent_address,
      'notice': this.formGroup.value.notice,
    };

    return result;
  }

  WhenDataClosed() {

    this.subagent_id = null;
    this.opened = [];

    this.loadList();

  }

}
