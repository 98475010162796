import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

declare const moment: any;


class Day {

  constructor(public day: any, public is_current = true, public event_id: string = null,
              public subscribe: boolean = false, public event_types = []) { }

  public readonly month_name = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];

  public get num() {
    return this.day.format('D');
  }

  public get month() {
    return this.month_name[this.day.format('M') - 1];
  }

  public get digit() {
    return this.day.format('D');
  }

  public get is_holiday() {
    return this.day.isoWeekday() === 6 || this.day.isoWeekday() === 7;
  }
}

class Week {

  public days: Array<Day> = [];

  constructor(public week_number) { }

  public append(day: Day) {
    this.days.push(day);
  }
}

@Component({
  selector: 'app-calendar-widget',
  templateUrl: './calendar-list-calendar.component.html',
  styleUrls: ['./calendar-list-calendar.component.scss']
})
export class CalendarListCalendarComponent implements OnInit {

  public readonly days_of_the_week = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];
  public readonly month_name = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];

  private _current_moment = moment();
  private _date = null;
  private _pos_top = null;

  public fixed = false;
  public calendar: Array<Day>;

  @Output('choose') public choose: EventEmitter<string> = new EventEmitter();
  @Input('month_events') public month_events;
  @Input('follow_the_white_rabbit') public follow_the_white_rabbit = true;

  @Input('date') public set date(value) {
    this._date = value;

    if (!value || -1 === Object.keys(value).indexOf('month')) {
      this.current_moment = moment();
    } else {
      this.current_moment = moment(`${value['year']}/${value['month']}`, 'YYYY/MM');
    }
  }

  public get date() {
    return this._date;
  }

  public get month() {
    return this.current_moment.format('MMMM');
  }

  public get year() {
    return this.current_moment.format('YYYY');
  }

  public get nextUrl() {
    const next_moment = this.current_moment.clone().add(1, 'month');
    return {
      'year': next_moment.format('YYYY'),
      'month': next_moment.format('MM'),
    };
  }

  public get prevUrl() {
    const next_moment = this.current_moment.clone().add(-1, 'month');
    return {
      'year': next_moment.format('YYYY'),
      'month': next_moment.format('MM'),
    };
  }

  private get current_moment(): any {
    return this._current_moment;
  }

  private set current_moment(value: any) {
    this._current_moment = value;
    this.reloadCalendar();
  }

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private elementRef: ElementRef) { }

  ngOnInit() {
    this._pos_top = this.elementRef.nativeElement.getBoundingClientRect().top - 32;
  }

  private reloadCalendar() {
    this.calendar = [];
    const today = this.current_moment.format('D-MM-YYYY');

    for (let day_num = -6; day_num < 50; day_num++) {
      const date = this.current_moment.clone().add(day_num, 'days'),
        event_key_index = Object.keys(this.month_events || []).indexOf(date.format('M') + ':' + date.format('D')),
        current = date.format('D-MM-YYYY') === today,
        event_key = -1 !== event_key_index ? Object.keys(this.month_events)[event_key_index] : null,
        event = event_key !== null ? this.month_events[event_key].first_event_id : null,
        event_types = event_key !== null ? this.month_events[event_key].event_types : null,
        self = event_key !== null ? this.month_events[event_key].self : null,
        staff = event_key !== null ? this.month_events[event_key].staff : null,
        subscribe = self || staff,
        day = new Day(date, current, event, subscribe, event_types);
      this.calendar.push(day);
    }
  }

  // @HostListener('window:scroll', [])
  // private onScroll() {
  //   if (window.pageYOffset > this._pos_top && !this.fixed) {
  //     this.fixed = true;
  //   }
  //
  //   if (window.pageYOffset < this._pos_top && this.fixed) {
  //     this.fixed = false;
  //   }
  // }

}
