import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialInputModule } from '../../modules/@material-input/material-input.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DataTableModule } from '../../modules/@datatable/datatables.module';
import { SharedModule } from '../../shared/shared.module';
import {AbleproDirectivesModule} from '../../_front/core/ablepro-directives.module';
import {PaginatorModule} from '../../shared/housing-modules/modules';
import {HousingControlComponent} from './housing.component';
import {HousingControlResolve} from './housing.resolve';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DataTableModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialInputModule,
    SharedModule,
    AbleproDirectivesModule,
    PaginatorModule,
  ],
  declarations: [
    HousingControlComponent,
  ],
  exports: [
    HousingControlComponent,
  ],
  providers: [
    HousingControlResolve,
  ]
})
export class HousingControlModule { }
