import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '../../../services/http.service';
import { NotificationService } from '../../../services/notification.service';
import {BreadcrumbsService} from '../../../shared/breadcrumbs/services';

@Component({
  selector: 'app,yamap-entitie-edit-physical',
  templateUrl: './entity-edit-physical.component.html',
  styleUrls: ['./entity-edit-physical.component.scss']
})
export class EntityEditPhysicalComponent implements OnInit {

  public data: any = null;
  public about: any = null;
  public posts: any = null;
  public formGroup: UntypedFormGroup;
  public entityId = null;
  public isSubagent = false;

  public get physicals(): UntypedFormArray {
    return this.formGroup.get('physicals') as UntypedFormArray;
  }

  public get check_boss(): boolean {
    return !!(this.physicals.controls.filter(physical => physical.value.is_boss == true).length);
  }

  constructor(public activeRoute: ActivatedRoute, public fb: UntypedFormBuilder, public http: HttpClient,
              public notify: NotificationService, private b: BreadcrumbsService) {
  }

  ngOnInit() {
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/entity/list', caption: 'Юридические лица'},
    ]);
    this.data = this.activeRoute.snapshot.data['data'];
    this.about = this.activeRoute.snapshot.parent.data['about'];
    if (this.about['organisation_is_subagent']) {
      this.isSubagent = true;
    }
    this.posts = this.activeRoute.snapshot.data['posts'];
    this.entityId = this.activeRoute.snapshot.parent.params['id'];
    this.formGroup = this.fb.group({
      'physicals': this.fb.array(this.data.map((org) => {
        return this.fb.group({
          'physical_fio': [{value: org['physical_fio'], disabled: true}],
          'physical_fio_text': org['physical_fio'],
          'physical_id': org['physical_id'],
          'post_caption': org['post_caption'],
          'post_id': '' + org['post_id'],
          'is_boss': org['is_boss'],
          'is_admin': org['is_admin'],
          'is_spn': org['is_spn'],
          'is_show_tarif': org['is_show_tarif'],
          'edit': false
        });
      }))
    });
  }

  onChangeBoss(physical, is_boss) {
    NotificationService.swalConfirm('Изменить руководителя?', 'Выбранное физ. лицо будет назначено руководителем огранизации, продолжить?')
      .then(() => {
        this.http.post('Organisation.set_is_boss', {
          'organisation_id': +this.entityId, 'physical_id': physical.value.physical_id, 'is_boss': is_boss
        }).subscribe(
          (response) => {
            this.onSuccessSub('Руководитель успешно изменен');
            if (is_boss) {
              this.physicals.controls.map(physical => {
                if (physical.value.is_boss) {
                  physical.get('is_boss').setValue(!is_boss);
                }
              });
            }
            physical.patchValue({'is_boss': is_boss})
          },
          (error) => {
            this.onError(error);
          }
        );
      }).catch(() => false);
  }

  onChangeSpn(physical, is_spn) {
    NotificationService.swalConfirm('Изменить ответственного за новостройки?', 'Выбранное физ. лицо будет назначено ответственным за новостройки огранизации, вместо текущего, продолжить?')
      .then(() => {
        this.http.post('Organisation.set_is_spn', {
          'organisation_id': +this.entityId, 'physical_id': physical.value.physical_id, 'is_spn': is_spn
        }).subscribe(
          (response) => {
            this.onSuccessSub('Ответственный за новостройки успешно изменен');
            if (is_spn) {
              this.physicals.controls.map(physical => {
                if (physical.value.is_spn) {
                  physical.get('is_spn').setValue(!is_spn);
                }
              });
            }
            physical.patchValue({'is_spn': is_spn})
          },
          (error) => {
            this.onError(error);
          }
        );
      }).catch(() => false);
  }

  onChangeAdmin(physical, is_admin) {
    this.http.post('Organisation.set_is_admin', {
      'organisation_id': +this.entityId, 'physical_id': physical.value.physical_id, 'is_admin': is_admin
    }).subscribe(
      (response) => {
        this.onSuccessSub('Админ успешно изменен');
        physical.patchValue({'is_admin': is_admin})
      },
      (error) => {
        this.onError(error);
      }
    );
  }

  onChangeIsShowTarif(physical, is_show_tarif){
    this.http.post('Organisation.set_is_show_tarif', {
      'organisation_id': +this.entityId, 'physical_id': physical.value.physical_id, 'is_show_tarif': is_show_tarif
    }).subscribe(
      (response) => {
        this.onSuccessSub('Видимость ТК успешно изменена');
        physical.patchValue({'is_show_tarif': is_show_tarif})
      },
      (error) => {
        this.onError(error);
      }
    );
  }

  relationEdit(relation) {
    relation.get('edit').setValue(!relation.value.edit);
  }

  relationChange(relation) {
    this.http.post('Organisation.update_organisation_physical',
      {'organisation_id': +this.entityId,
        'physical_id': +relation.value.physical_id,
        'post_id': +relation.value.post_id}
    ).subscribe(
      (response) => {
        this.onSuccess(response);
        relation.patchValue({
          'physical_id': relation.value.physical_id,
          'post_id': '' + relation.value.post_id,
          'physical_fio': response['physical_fio'],
          'physical_fio_text': response['physical_fio'],
          'post_caption': response['post_caption'],
          'edit': false
        });
      },
      (error) => this.onError(error)
    );
    relation.get('edit').setValue(!relation.value.edit);
  }

  relationDelete(relation) {
    NotificationService.swalConfirm('Вы уверены?', 'Выбранная связь будет безвозвратно удалена!')
      .then(() => {
        this.http.post('Organisation.del_organisation_physical',
          {'organisation_id': +this.entityId, 'physical_id': +relation.value.physical_id}
        ).subscribe(
          (response) => {
            this.onSuccess(response);
            this.physicals.removeAt(this.physicals.controls.indexOf(relation));
          },
          (error) => this.onError(error)
        );
      }).catch(() => false);
  }

  transformationOrg() {
    NotificationService.swalConfirm('Вы уверены?', 'Все действия и статусы субагента будут удалены!')
      .then(() => {
        this.http.post('Subagent.transformation_to_organisation',
          {'subagent_id': +this.entityId})
          .subscribe(
            (response) => {
              this.onSuccessSub('Субагент преобразован');
              this.isSubagent = false;
              this.about.lead_id = false;
            },
            (error) => this.onError(error)
          );
      }).catch(() => false);
  }
  transformationSub() {
    NotificationService.swalConfirm('Вы уверены?', 'Будет создан новый лид по привлечению и выбранное юр. лицо будет преобразовано в субагента!\nЕсли Вы хотите назначить это Юр лицо на существующий лид, сделайте это через редактирование лида по привлечению.')
      .then(() => {
        this.http.post('Subagent.transformation_to_subagent',
          {'organisation_id': +this.entityId})
          .subscribe(
            (response) => {
              this.onSuccessSub('Юр.лицо преобразовано');
              this.isSubagent = true;
              this.about.lead_id = response;
            },
            (error) => this.onError(error)
          );
      }).catch(() => false);
  }

  onSuccess(response) {
    this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
  }

  onSuccessSub(body) {
    this.notify.notifySuccess('Поздравляем', body, 3000);
  }

  onError(error) { }

}
