<form [formGroup]="form" *ngIf="formadded" novalidate>
<div class="row no-gutters gutters-4">
  <div class="col-6">
    <app-material-input formControlName="organisation_caption"
                        [placeholder]="'Наименование организации'"
                        [require]="true">
      Наименование организации</app-material-input>
  </div>
  <div class="col-6">
    <app-material-input formControlName="organisation_socrachcaption"
                        [placeholder]="'Сокращенное наименование организации'">
      Сокращенное наименование организации</app-material-input>
  </div>
  <div class="col-6">
    <app-material-input formControlName="organisation_komm_caption"
                        [placeholder]="'Коммерческое наименование'">
      Коммерческое наименование</app-material-input>
  </div>
  <div class="col-6">
    <div *ngIf="types">
      <app-material-select formControlName="organisation_type_id" [require]="true" [caption]="'Тип организации'">
        <option [value]="''">-- Не выбрано --</option>
        <option *ngFor="let type of types" [value]="type.organisation_types_id">
          {{type.organisation_types_caption}}
        </option>
      </app-material-select>
    </div>
  </div>
  <div class="col-12"></div>
  <div class="col-12">
    <app-material-input-checkbox
      *ngIf="form.get('organisation_type_id').value === '4'"
      formControlName="organisation_overall_unique_is"
    >
      Общая проверка на уникальность всех ЖК данного застройщика
    </app-material-input-checkbox>
    <div *ngIf="forms">
      <app-material-select formControlName="organisation_form_id" [require]="true" [caption]="'Форма предприятия'">
        <option [value]="''">-- Не выбрано --</option>
        <option *ngFor="let form of forms" [value]="form.organisation_form_id">
          {{form.organisation_form_caption}}
        </option>
      </app-material-select>
    </div>
  </div>
</div>
</form>
<div class="mt-3">
  <div class="row no-gutters gutters-4">
    <div class="col-md-6">
      <div class="row align-items-end">
        <div class="col-md-6">
          <form [formGroup]="form" *ngIf="formadded" novalidate>
            <app-material-input formControlName="organisation_address"
                                [placeholder]="'Юридический адрес:'"
                                [require]="true">
              Юридический адрес:</app-material-input>
          </form>
        </div>
        <div class="col-md-6">
          <label class="form__checkbox mb-3 form__checkbox_icon_done_mark">
            <input type="checkbox" [(ngModel)]="sameAdress">
            <span class="form__checkbox__wrapper">
                <span class="form__checkbox__wrapper__toggler icon icon_done_mark"></span>
            </span>
            <span class="form__checkbox__content">Совпадает с физическим</span>
          </label>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <form [formGroup]="form" *ngIf="formadded" novalidate>
        <app-material-input formControlName="organisation_fact_addr" [disabled]="sameAdress"
                            [placeholder]="'Фактический адрес'">
          Фактический адрес</app-material-input>
      </form>
    </div>
</div>
<form [formGroup]="form" *ngIf="formadded" novalidate>
  <div class="row no-gutters gutters-4">
  <div class="col-6">
    <app-material-input formControlName="organisation_post_address"
                        [placeholder]="'Почтовый адрес'">
      Почтовый адрес</app-material-input>
  </div>
  <div class="col-6">
    <app-material-input formControlName="organisation_email" [placeholder]="'E-mail'">E-mail</app-material-input>
  </div>
  <div class="col-6">
    <app-material-input-mask formControlName="organisation_phone" [mask]="'+7 (000) 000-00-00'" [require]="true"
                             [placeholder]="'+7 (___) ___-__-__'">
      Телефон</app-material-input-mask>
  </div>
</div>
</form>
