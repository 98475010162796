
  <div class="row mt-4 mb-2" [formGroup]="form">
    <div class="col-6 col-md-4 order-lg-2 col-lg-2">
      <p class="teaching__digits">
        {{statistics['agency_all'] || 0}}
        / {{statistics['agency_with_access'] || 0}}
        / {{statistics['agency_active'] || 0}}
      </p>
      <p class="teaching__legend">Агентства</p>
      <p class="teaching__legend_mute">Всего / есть доступ / есть заявки</p>
    </div>
    <div class="col-6 col-md-8 order-lg-3 col-lg-2">
      <p class="teaching__digits">
        {{statistics['requests_all'] || 0}}
        / {{statistics['requests_last_month'] || 0}}
      </p>
      <p class="teaching__legend c-pointer" title="Заявки считаются только на активных субагентах"
      >Заявки <i class="fa fa-question-circle"></i></p>
      <p class="teaching__legend_mute">Всего / последний месяц</p>
    </div>
    <div class="col-6 col-md-8 order-lg-3 col-lg-2">
      <p class="teaching__digits">
        {{statistics['issued_loans_all'] || 0}}
        / {{statistics['issued_loans_all_last_month'] || 0}}
      </p>
      <p class="teaching__legend">Выдано кредитов</p>
      <p class="teaching__legend_mute">Всего / последний месяц</p>
    </div>
    <div class="col-2 col-md-3 col-lg-1 pt-4">
      Всего: <b>{{count}}</b>
    </div>
    <div class="col-2 col-md-3 col-lg-1">
      <a [href]="urlLead"
         class="button mt-3" target="_blank">
        Скачать</a>
    </div>
    <div class="col-4 col-md-3 col-lg-2">
      <app-material-input-datepicker [cssClass]="'mb-0'" formControlName="date_from">Создана с</app-material-input-datepicker>
    </div>
    <div class="col-4 col-md-3 col-lg-2">
      <app-material-input-datepicker [cssClass]="'mb-0'" formControlName="date_to">Создана по</app-material-input-datepicker>
    </div>
  </div>
  <div class="material-datatable-wrapper">
    <div class="table-responsive">
      <table class="table">
        <tr>
          <th *ngFor="let el of head" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
            <div [formGroup]="form">
              <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
                <div class="col table__input">
                  <ng-container *ngIf="el.input">
                    <app-material-input
                      [placeholder] = "el.caption"
                      [formControlName] = "el.name"
                      [cssClass]="'mb-0'">
                    </app-material-input>
                  </ng-container>
                  <ng-container *ngIf="el.select">
                    <app-material-select
                      [formControlName]="el.name"
                      [cssClass]="'mb-0'">
                      <option *ngFor="let opt of el.select" [value]="opt.id">{{opt.caption}}</option>
                    </app-material-select>
                  </ng-container>
                  <ng-container *ngIf="!el.input && !el.select">
                    {{el.caption}}
                  </ng-container>
                </div>
                <div class="col-auto mb-2" *ngIf="el.select">
                  <popover-content #popoverCian [title]="'Cтатусы ИНСТАТЕКА / СПН24'"
                                   [placement]="'left'" [closeOnClickOutside]="false"
                                   [closeOnMouseOutside]="false">
                    Сделка завершена - Кредит выдан<br>
                    Кредит выдан - Кредит выдан<br>
                    На подписании  - В работе<br>
                    Объект одобрен - Одобрено<br>
                    Доработка по объекту - В работе<br>
                    Рассмотрение объекта - В работе<br>
                    Заемщик одобрен - Одобрено<br>
                    Возврат на доработку - В работе<br>
                    Рассмотрение заемщика - В работе<br>
                    Отказ по объекту - Отказ<br>
                    Отказ по заемщику - Отказ<br>
                    Отказ клиента - Отказ<br>
                    Ошибка - В работе<br>
                    Черновик - В работе<br>
                    Заполнение анкеты - В работе
                  </popover-content>
                  <div [popover]="popoverCian" class="mt-2">
                    <i class="fa fa-2x fa-fw fa-question-circle c-pointer"></i>
                  </div>
                </div>
                <div class="col-auto" *ngIf="el.sort">
                  <a href="javascript:void(0)" (click)="sortChange(el.name)">
                    <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                      'icon_new-sort-down': 'asc' == sort[el.name],
                                      'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                  </a>
                </div>
              </div>
            </div>
          </th>
        </tr>
        <!-- // -->
        <tr *ngFor="let row of rows; let row_index = index">
          <td>
            {{row['inst_id']}}
          </td>
          <td>
            {{row['time_created']}}
          </td>
          <td>
            <a [routerLink]="['/entity/edit/', row['sa_id'], 'common']">{{row['sa_caption']}}</a>
          </td>
          <td>
            <a [routerLink]="['/physical/edit/', row['author_id'], 'common']">{{row['author_fio']}}</a>
          </td>
          <td>
            <a [routerLink]="['/physical/edit/', row['client_id'], 'common']">{{row['fio']}}</a>
          </td>
          <td>
            {{row['value']}}
          </td>
          <td>
            {{row['last_update']}}
          </td>
          <td>
            {{row['status_name']}}
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div class="alt-pagination">
    <app-paginator
      [queryParams]="filter_columns"
      [itemsPerPage]="50"
      [itemCount]="count"
      [currentPage]="current_page"
      [baseUrl]="'/instateka/requests'">
    </app-paginator>
  </div>
