import {Injectable} from '@angular/core';
import {DictionariesService} from '../../services/dictionaries.service';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable ()
export class DictionaryDevelopmentsResolver {

  constructor (private dict_helper: DictionariesService) {}

  resolve(router: ActivatedRouteSnapshot) {
    return this.dict_helper.get('Dict.get_developments');
  }

}
