import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '../../../services/http.service';
import { NotificationService } from '../../../services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription ,  interval } from 'rxjs';
import {GoBackButtonService} from '../../../services/go-back-button.service';
import {BreadcrumbsService} from '../../breadcrumbs/services';
import {ImportEditService} from './import-edit.service';

@Component({
  templateUrl: './import-edit.component.html',
  styleUrls: ['./import-edit.component.scss']
})
export class ImportEditComponent implements OnInit, OnDestroy {

  public formGroup: UntypedFormGroup;
  public feed: any;
  public date_first: any;
  public date_last: any;
  public view = false;
  public error: any;
  public _now_in_update = false;
  public feed_id: any;
  public modifications = 0;
  public rejects = 0;
  public subscribtion: Subscription;
  public subscription: Subscription = new Subscription();
  public routerSubscription: Subscription;
  analyzeShown = false;

  public get now_in_update() {
    return this._now_in_update;
  }

  public set now_in_update(value) {
    if (value === false && this._now_in_update === true) {
      const currUrl = this.router.url.split('?')[0];
      this.router.navigate(['/blank'], {replaceUrl: true}).then(() => {
        this.router.navigate([currUrl], {replaceUrl: true});
      });
    }
    this._now_in_update = value;

    if (value && !this.subscribtion) {
      const myObservable = interval(2000);
      this.subscribtion = myObservable.subscribe(response => this.onUpdate());
    }
    if (!value && this.subscribtion) {
      this.subscribtion.unsubscribe();
      this.subscribtion = null;
    }
  }

  constructor(public http: HttpClient, public fb: UntypedFormBuilder, private goBack: GoBackButtonService, private b: BreadcrumbsService,
              public notify: NotificationService, public router: Router, public activatedRoute: ActivatedRoute,
              private importEditService: ImportEditService) {
    this.feed = this.activatedRoute.snapshot.data['feed'];
    this.feed_id = activatedRoute.snapshot.params['id'];
    this.formGroup = this.fb.group({
      'caption': [this.feed['caption'], Validators.required],
      'url': [this.feed['url'], Validators.required],
      'specification_caption': [this.feed['specification_caption'], Validators.required],
    });
    this.formGroup.disable();
    this.now_in_update = this.feed['in_progress'];
    this.modifications = this.feed['counts']['modifications'];
    this.rejects  = this.feed['counts']['rejects'];
    this.date_first = this.feed['time_created'];
    this.date_last = this.feed['last_imported'];
  }

  ngOnInit() {
    this.goBack.header = '<h1>Редактор фида</h1>';
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/import', caption: 'Автоматический импорт'},
    ]);
    this.routerSubscription = this.router.events.subscribe((val) => {
      this.goBack.header = '<h1>Редактор фида</h1>';
      this.b.set([
        {url: '/', caption: 'Главная'},
        {url: '/import', caption: 'Автоматический импорт'},
      ]);
    });
    this.subscription.add(this.importEditService.onModificationsErase.subscribe(() => {
      this.modifications = 0;
    }, (error) => false));
    this.subscription.add(this.importEditService.onLogFileLoad.subscribe(logId => {
      this.now_in_update = true;

      this.http.post('Import.log_run_celery', {'log_id': logId}).subscribe(
        (response) => {
          this.now_in_update = true;
        },
        (error) => {
          this.onError(error);
          this.now_in_update = true;
        }
      );
    }));
  }

  onUpdate() {
    this.http.post('Import.setting_get', {'setting_id': this.feed_id}).subscribe(
      (response) => {
        this.now_in_update = response['in_progress'];
        this.modifications = response['counts']['modifications'];
        this.rejects  = response['counts']['rejects'];
        this.date_last = response['last_imported'];
        this.feed = response;
      },
      (error) => {
        this.now_in_update = true;
      }
    );
  }

  onRefresh() {
    this.now_in_update = true;

    this.http.post('Import.setting_run_celery', {'setting_id': this.feed_id}).subscribe(
      (response) => {
        this.now_in_update = true;
      },
      (error) => {
        this.onError(error);
        this.now_in_update = true;
      }
    );
  }

  onSuccess(response?) {
    this.notify.notifySuccess('Поздравляем', 'Фид успешно обновился', 3000);
  }

  onError(error) {
    this.notify.notifyError('Ошибка', error, 5000);
  }

  ngOnDestroy() {
    if (this.subscribtion) {
      this.subscribtion.unsubscribe();
    }
    this.subscription.unsubscribe();
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

}
