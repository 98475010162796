import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {AlternativeDatatablesInterface} from '../../shared/alternative-datatables/alternative-datatables.interface';
import {AlternativeDatatablesComponent} from '../../shared/alternative-datatables/alternative-datatables.component';
import {GoBackButtonService} from '../../services/go-back-button.service';
import {BreadcrumbsService} from '../../shared/breadcrumbs/services';
import {JsonClientService} from '../../shared/json-client';
import { merge } from 'rxjs';

@Component({
  templateUrl: './realty-show-logs.component.html',
  styleUrls: [
    './realty-show-logs.component.scss'
  ]
})
export class RealtyShowLogsComponent
  extends AlternativeDatatablesComponent
  implements AlternativeDatatablesInterface, OnInit, OnDestroy, AfterViewInit {

  public head;

  public filter_columns = {};
  public form: UntypedFormGroup;
  public filter: string;

  public housing_id = null;
  private parasites = true;

  public filterSubject: Subject<void> = new Subject<void>();
  private inner_subscription: Subscription = new Subscription();

  public current_page = 1;

  public count;
  public rows;

  public sort: {[key: string]: string} = {};

  public current_flat: any = null;

  public set data(value) {
    this.count = value['count'];
    this.rows = value['rows'];
  }

  public module_source;
  public module_source_dict = {};
  public realty_link_dict = {
    'housing': '/housing2/house',
    'housing_complex': '/housing2/complex',
    'flat': '/housing2/flat',
  };

  constructor(private router: Router, private fb: UntypedFormBuilder, public activatedRoute: ActivatedRoute,
              public goBack: GoBackButtonService, private b: BreadcrumbsService,
              public http: JsonClientService) {
    super(router, activatedRoute, fb, http);

    this.inner_subscription.add(this.filterSubject
      .pipe(debounceTime(500))
      .subscribe(_ => this.onFilterChanges()));

    this.housing_id = this.activatedRoute.snapshot.params['housing_id'];
  }

  bSet() {
    this.goBack.header = '<h1>Логи публикации проектов</h1>';
    // this.b.set([
    //   {url: '/', caption: 'Главная'},
    //   {caption: 'Логи проектов'},
    // ]);
  }

  ngOnInit() {
    this.bSet();
    this.form = this.fb.group({});
    this.module_source = this.activatedRoute.snapshot.data['module_source'];
    this.module_source.forEach(value => this.module_source_dict[value.id] = value.caption);

    this.head = [
      {'name': 'date_time', 'value': '', 'caption': 'Дата действия', 'sort': true},
      {'name': 'visible_status', 'value': '', 'caption': 'Тип (Все)', 'sort': false, 'select': [
          {
            'id': true,
            'caption': 'Опубликован',
          },
          {
            'id': false,
            'caption': 'Снято с публ.',
          }
        ]},
      {'name': 'realty_caption', 'value': '', 'caption': 'Объект', 'sort': true, 'input': true},
      {'name': 'module_source', 'value': '', 'caption': 'Источник (Все)', 'sort': false, 'select': this.module_source},
      {'name': 'author_fio', 'value': '', 'caption': 'Автор', 'sort': false, 'input': true},
    ];
    this.head = this.head.map(x => {
      x.value = this.activatedRoute.snapshot.queryParams[x.name] || x.value;
      return x;
    });
    if (this.activatedRoute.snapshot.queryParams['sort_name']) {
      this.sort[this.activatedRoute.snapshot.queryParams['sort_name']] = this.activatedRoute.snapshot.queryParams['sort_key'];
    } else {
      this.sort['date_time'] = 'desc';
    }

    this.inner_subscription.add(this.form.valueChanges
      .subscribe(_ => this.filterSubject.next()));

    for (const el of this.head) {
      this.form.addControl(el.name, new UntypedFormControl(el.value));
    }
    const flatControl = new UntypedFormControl(this.activatedRoute.snapshot.queryParams['flat'] === 'true'),
      complexControl = new UntypedFormControl(this.activatedRoute.snapshot.queryParams['complex'] === 'true');
    this.form.addControl('flat', flatControl);
    this.form.addControl('complex', complexControl);
    this.inner_subscription.add(
      complexControl.valueChanges
        .subscribe(v => {
          if (v === true && flatControl.value === true) {
            flatControl.patchValue(false, {onlySelf: true, emitEvent: false});
          }
        })
    );
    this.inner_subscription.add(
      flatControl.valueChanges
        .subscribe(v => {
          if (v === true && complexControl.value === true) {
            complexControl.patchValue(false, {onlySelf: true, emitEvent: false});
          }
        })
    );

    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.onInitCall();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.inner_subscription.unsubscribe();
  }

  onFilterChanged(): void {
    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.data = this.activatedRoute.snapshot.data['data'];
    this.current_page = +this.activatedRoute.snapshot.queryParams['page'] || 1;
  }

  onFilterChanges(text = null) {

    if (text !== null) {
      this.filter = text;
    }

    this.filter_columns = {};
    for (const el of this.head) {
      if (!this.form.get(el.name).value.length) {
        continue;
      }
      this.filter_columns[el.name] = this.form.get(el.name).value;
    }
    this.filter_columns['flat'] = this.form.get('flat').value;
    this.filter_columns['complex'] = this.form.get('complex').value;
    if (this.filter !== '') {
      this.filter_columns['global_search_string'] = this.filter;
    }
    if (this.sort) {
      this.filter_columns['sort_name'] = Object.keys(this.sort)[0];
      this.filter_columns['sort_key'] = this.sort[this.filter_columns['sort_name']];
    }
    if (this.parasites) {
      return this.parasites = !this.parasites;
    }
    this.bSet();
    this.router.navigate(['realty-show-logs'], {
      queryParams: this.getQueryParams(),
    });
  }

  getQueryParams() {
    const query_params = {};

    for (const el of this.head) {
      let _val = this.form.get(el.name).value;
      if (!_val.length) {
        continue;
      }
      if (_val === 'true' || _val === 'false') {
        _val = _val === 'true';
      }
      query_params[el.name] = _val;
    }
    query_params['flat'] = this.form.get('flat').value;
    query_params['complex'] = this.form.get('complex').value;
    if (this.sort) {
      query_params['sort_name'] = Object.keys(this.sort)[0];
      query_params['sort_key'] = this.sort[query_params['sort_name']];
    }
    if (this.filter !== '') {
      query_params['global_search_string'] = this.filter;
    }
    return query_params;
  }

  prepareSort(column_name: string) {
    for (const key in this.sort) {
      if (column_name !== key) {
        delete this.sort[key];
      }
    }
  }

  sortChange(column_name: string) {
    this.prepareSort(column_name);
    this.sort[column_name] = !this.sort[column_name] || ('desc' === this.sort[column_name]) ? 'asc' : 'desc';
    this.onFilterChanges();
  }
  ngAfterViewInit() {
    this.goBack.goScroll();
  }
}
