import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {JsonClientService} from '../../shared/json-client';


@Injectable()
export class ImportXlsxResolve {

  constructor(private http: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.http.post('Housing.get_import_xlsx_uploads');
  }

}

@Injectable()
export class ImportXlsxDictResolve {

  constructor(private http: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.http.post('Import.get_category_import');
  }

}
