import { NgModule } from '@angular/core';
import { CalendarComponent } from './calendar.component';
import { CalendarEventComponent } from './event/calendar-event.component';
import { RouterModule } from '@angular/router';
import { CalendarInvitesComponent } from './invites/calendar-invites.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialInputModule } from '../../modules/@material-input';
import { CalendarInviteComponent } from './invites/calendar-invite-component';
import { CalendarCreateResolve, CalendarCreateResolveInvites } from './event/calendar-event.resolve';
import { ModalModule } from '../../modules/@modal';
import { SimpleTableModule } from '../../modules/@simple-table/simple-table.module';
import { CalendarCreateInvitesComponent } from './shared/calendar-create-invites.component';

import * as list from './list';
import { CalendarEditResolve } from './event/calendar-edit.resolve';
import { PreloaderModule } from '../../modules/@preloader/preloader.module';
import { CalendarTeachingComponent } from './teaching/calendar-teaching.component';
import { CalendarTeachingResolve, CalendarTeachingResolveStatistic } from './teaching/calendar-teaching.resolve';
import { AllEventsComponent } from './all-events/all-events.component';
import { AllEventsResolve } from './all-events/all-events.resolve';
import { AllEventsTableComponent } from './all-events/all-events-table.component';
import { DataTableModule } from '../../modules/@datatable/datatables.module';
import { AllEventsParticipantsComponent } from './all-events/all-events-participants.component';
import { AllEventsParticipantsTableComponent } from './all-events/all-events-participants-table.component';
import { CalendarTeachingUsersComponent } from './teaching/calendar-teaching-users.component';
import { CalendarStatisticModule } from './statistic/calendar-statistic.module';
import { CalendarInviteBindService } from './invites/calendar-invite-bind.service';
import { GoogleChartsModule } from '../../modules/@google-charts/google-charts.module';
import { CalendarTeachingPopupComponent } from './teaching/calendar-teaching-popup.component';
import { CalendarTeachingPopupPipe } from './teaching/calendar-teaching-popup.pipe';
import { CalendarMapComponent } from './map/calendar-map.component';
import { CalendarMapTablesComponent } from './map/map-tables/calendar-map-tables.component';
import { CalendarPhysicalTableComponent } from './map/map-tables/physical-table/calendar-physical-table.component';
import { CalendarSubagentTableComponent } from './map/map-tables/subagent-table/calendar-subagent-table.component';
import { CalendarMapService } from './map/calendar-map.service';
import { SharedModule } from '../shared.module';
import { CalendarCertificateComponent } from './certificate/certificate.component';
import { CalendarUserListComponent } from './user-list/calendar-user-list.component';
import {HelpWindowModule} from '../help-window';
import {OsmModule} from '../osm';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialInputModule,
        ModalModule,
        SimpleTableModule,
        PreloaderModule,
        DataTableModule,
        CalendarStatisticModule,
        GoogleChartsModule,
        SharedModule,
        HelpWindowModule,
        OsmModule,
    ],
    providers: [
        list.CalendarListResolve,
        CalendarCreateResolve,
        CalendarCreateInvitesComponent,
        CalendarCreateResolveInvites,
        CalendarEditResolve,
        list.CalendarListService,
        CalendarTeachingResolve,
        AllEventsResolve,
        CalendarInviteBindService,
        CalendarTeachingResolveStatistic,
        CalendarMapService,
    ],
    declarations: [
        CalendarComponent,
        CalendarEventComponent,
        list.CalendarListComponent,
        CalendarInvitesComponent,
        CalendarInviteComponent,
        CalendarCreateInvitesComponent,
        CalendarTeachingComponent,
        AllEventsComponent,
        AllEventsTableComponent,
        AllEventsParticipantsComponent,
        AllEventsParticipantsTableComponent,
        list.CalendarListWrapperComponent,
        list.CalendarListCalendarComponent,
        list.CalendarListEventComponent,
        list.CalendarListCalendarSmComponent,
        CalendarTeachingUsersComponent,
        CalendarTeachingPopupComponent,
        CalendarTeachingPopupPipe,
        CalendarMapComponent,
        CalendarMapTablesComponent,
        CalendarPhysicalTableComponent,
        CalendarSubagentTableComponent,
        CalendarCertificateComponent,
        CalendarUserListComponent,
    ],
    exports: [
        CalendarComponent,
        CalendarEventComponent,
        list.CalendarListComponent,
        CalendarInvitesComponent,
        CalendarInviteComponent,
        AllEventsComponent,
        AllEventsParticipantsComponent,
        AllEventsParticipantsTableComponent,
        list.CalendarListWrapperComponent,
        list.CalendarListCalendarComponent,
        CalendarStatisticModule,
        CalendarUserListComponent,
    ]
})
export class CalendarModule {
}
