import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityListComponent } from './entity-list.component';
import { MaterialInputModule } from '../../modules/@material-input/material-input.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from '../../modules/@tabs/tabs.module';
import { DataTableModule } from '../../modules/@datatable/datatables.module';
import { RouterModule } from '@angular/router';
import { EntityEditComponent } from './entity-edit/entity-edit.component';
import { EntityEditCommonComponent } from './entity-edit/entity-edit-common.component';
import { EntityEditAdditionallyComponent } from './entity-edit/entity-edit-additionally.component';
import { EntityEditPhysicalComponent } from './entity-edit/entity-edit-physical.component';
import { EntityEditDocumentsComponent } from './entity-edit/entity-edit-documents.component';
import { SharedModule } from '../../shared/shared.module';
import { EntityCreateComponent } from './entity-create/entity-create.component';
import {
  EntityAboutResolve,
  EntityAdditionallyResolve,
  EntityCommonResolve,
  EntityDocumentsResolve,
  EntityPhysicalResolve,
  EntityReglamentResolve,
  PhyToEntityResolve
} from './shared/entity.resolve';
import {EntitySharedModule} from './entity-shared.module';
import {EntityEditAddressComponent} from './entity-edit/entity-edit-address.component';
import {AddressComponentModule} from '../../shared/address-component/address-component.module';
import {EntityEditAddressResolve} from './entity-edit/entity-edit-address.resolve';
import {EntityEditCertificateComponent} from './entity-edit/entity-edit-certificate.component';
import {SimpleTableModule} from '../../modules/@simple-table/simple-table.module';
import {PreloaderModule} from '../../modules/@preloader/preloader.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DataTableModule,
        TabsModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialInputModule,
        SharedModule,
        EntitySharedModule,
        AddressComponentModule,
        SimpleTableModule,
        PreloaderModule,
    ],
    declarations: [
        EntityListComponent,
        EntityEditComponent,
        EntityEditCommonComponent,
        EntityEditAdditionallyComponent,
        EntityEditPhysicalComponent,
        EntityEditDocumentsComponent,
        EntityEditAddressComponent,
        EntityEditCertificateComponent,
    ],
    exports: [
        EntityListComponent,
        EntityEditComponent,
        EntityEditCommonComponent,
        EntityEditAdditionallyComponent,
        EntityEditPhysicalComponent,
        EntityEditDocumentsComponent,
        EntityEditAddressComponent,
        EntityEditCertificateComponent,
    ],
    providers: [
        EntityAboutResolve,
        EntityCommonResolve,
        EntityAdditionallyResolve,
        EntityPhysicalResolve,
        EntityDocumentsResolve,
        EntityReglamentResolve,
        PhyToEntityResolve,
        EntityEditAddressResolve,
    ]
})
export class EntitiesModule {
}
