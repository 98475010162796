<div class="event-wrapper" [ngClass]="{'event-wrapper_gray': data['is_subscribed']}">
  <div class="row m-0">
    <div class="col-3 pl-0 caption-sm">
      {{data['event_caption']}}
    </div>
    <div class="col-9 pr-0 pre-line">
      <div [innerHtml]="data['event_description']"></div>
      <div [hidden]="!displayMoreInfo">
        <p>{{data['event_extend_description']}}</p>
        <p *ngIf="data['event_placement']" class="mt-4 mb-2">Место проведения: {{data['event_placement']}}</p>
        <p *ngIf="data['event_placement_description']">{{data['event_placement_description']}}</p>
        <!--<p *ngIf="data['placement_map']">-->
          <!--<a [href]="data['placement_map']['url']" target="_blank">-->
            <!--<i class="fa fa-download"></i> Скачать план проезда-->
          <!--</a>-->
        <!--</p>-->
      </div>

      <div *ngIf="(data['images'] && data['images'].length) || (data['placement_map'] && data['placement_map']['url'])"
           class="event-wrapper__body__images">
        <div *ngFor="let img of data['images']" class="event-wrapper__body__image" (click)="displayImage(img)"
          [style.background-image]="'url('+img['url']+')'"></div>
        <div *ngIf="data['placement_map'] && data['type_id'] !== '3'" class="event-wrapper__body__image" (click)="displayPlacement()"
          [style.background-image]="'url('+data['placement_map']['url']+')'"></div>
      </div>

    </div>
  </div>
  <div class="row m-0 align-items-end mt-3">
    <div class="col-8 pl-0">
      <div class="row no-gutters gutters-2">
        <div class="col-auto mb-2" *ngIf="usageCrm && sys['editable']">
          <span class="event-wrapper__heading__edit">
            <a [routerLink]="['/calendar/edit', data['event_id']]" class="c-red no-decortion">
              <i class="fa fa-pencil"></i> Редактировать</a>
          </span>
        </div>
        <div class="col-auto mb-2" *ngIf="usageCrm && sys['editable']">
          <span class="event-wrapper__heading__edit">
            <a href="javascript:void(0)" (click)="removeEvent(data['event_id'])" class="c-red no-decortion">
              <i class="fa fa-remove"></i> Удалить</a>
          </span>
        </div>
        <div class="col-auto mb-2">
          <i class="icon icon_md icon_line icon_Small_Time"></i>
          {{data['time_begin']}} <ng-container *ngIf="data['time_end']">&mdash; {{data['time_end']}}</ng-container>
        </div>
        <div class="col-auto mb-2">
          <i class="icon icon_md icon_line" [ngClass]="{
            'icon_Small_Lesson': data['type_id'] == 1,
            'icon_Small_Presentation': (data['type_id'] == 5) || (data['type_id'] == 7),
            'icon_Small_Webinar': data['type_id'] == 3,
            'icon_Small_Broker-Tour': (data['type_id'] == 6) || (data['type_id'] == 6),
            'icon_Small_Info-Tour': data['type_id'] == 2
          }"></i>
          <span>{{data['type_caption']}}</span>
        </div>
        <div class="col-auto mb-2">
          <i class="icon icon_md icon_line icon_Small_Crowd"></i>
          <ng-container *ngIf="!data['event_register_finish']">
            <ng-container *ngIf="data['places_free'] <= 0">
              Мест не осталось
            </ng-container>
            <ng-container *ngIf="data['places_free'] > 0">
              Осталось мест: {{data['places_free']}}
            </ng-container>
          </ng-container>
          <ng-container *ngIf="data['event_register_finish']">
            Регистрация завершена
          </ng-container>
        </div>
        <div class="col-auto mb-2">
          <a href="javascript:void(0)" class="c-red no-decortion" [hidden]="displayMoreInfo" (click)="displayInfo()">
            <i class="icon icon_md icon_line icon_Small_Chevron-Down"></i> Подробнее</a>
          <a href="javascript:void(0)" class="c-red no-decortion" [hidden]="!displayMoreInfo" (click)="hideInfo()">
            <i class="icon icon_md icon_line icon_Small_Chevron-Down-Copy"></i> Закрыть</a>
        </div>
      </div>
    </div>
    <div class="col-4 pr-0"  *ngIf="!subscriptionDisplay">
      <div class="event-wrapper__subscription-buttons text-right" [hidden]="subscriptionDisplay"
           *ngIf="(data['places_free'] || (!data['places_free'] && subscriptionIsAdmin)) && !usageCrm && sys['subscribable']">
        <!-- Subscription for single user -->
        <ng-container *ngIf="!subscriptionIsAdmin && !data['is_subscribed']">
          <button class="button button_main" (click)="subscribeSelf()">Записаться</button>
        </ng-container>
        <ng-container *ngIf="!subscriptionIsAdmin && data['is_subscribed']">
          <a class="ics-file d-block mb-2" target="_blank" [href]="url">
            <i class="fa fa-calendar"></i> Добавить в свой календарь</a>
          <button class="button button_main" (click)="unsubscribeSelf()">Отказаться</button>
        </ng-container>
        <!-- Subscription for all companies user -->
        <ng-container *ngIf="subscriptionIsAdmin">
          <a class="ics-file d-block mb-2" target="_blank" [href]="url">
            <i class="fa fa-calendar"></i> Добавить в свой календарь</a>
          <button class="button button_main" (click)="displaySubscriptionSection()">Записаться</button>
        </ng-container>
        <!-- Section for multiple subscription -->
      </div>
    </div>
    <ng-template #templateSubscribe>
      <div>
        <div [hidden]="userForSubscription.length || userSubscribed.length">
          <app-preloader></app-preloader>
          <div class="clearfix"></div>
        </div>
        <div [hidden]="!userForSubscription.length && !userSubscribed.length">
          <p class="mb-2">
            <ng-container *ngIf="free_places_count <= 0">
              Мест не осталось
            </ng-container>
            <ng-container *ngIf="free_places_count > 0">
              Осталось мест: {{free_places_count}}
            </ng-container>
          </p>
          <p [hidden]="!userForSubscription.length || free_places_count < 0">
            Выберите сотрудников:</p>
          <div [hidden]="!userForSubscription.length || free_places_count < 0" class="row pl-3 pr-3">
            <div class="col-6" *ngFor="let user of userForSubscription">
              <label class="form__checkbox" [ngClass]="'form__checkbox_icon_done_mark'" (click)="subscribeUser(user)">
                <input type="checkbox" (change)="subscribeUser(user)" [checked]="user.event_subscribed">
                <span class="form__checkbox__wrapper">
                  <span class="form__checkbox__wrapper__toggler icon" [ngClass]="'icon_done_mark'"></span>
                </span>
                <span class="form__checkbox__content">{{user['physical_fio']}}</span>
              </label>
            </div>
          </div>
          <div class="clearfix mt-4">
            <button class="button float-right " type="button" (click)="displaSubscribe = false">Закрыть</button>
            <button class="button button_main float-right pr-2r pl-2r mr-3" type="button" (click)="safeEventSubscription()">Ок</button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<app-modal *ngIf="displayMap && latitude && longitude" [closeOutside]="false" (onClosed)="displayMap = false">
  <div class="map-wrapper">
    <!-- default map -->
<!--    <osm-map [center]="[latitude, longitude]">-->
<!--      <osm-placemark [coordinates]="[latitude, longitude]"></osm-placemark>-->
<!--    </osm-map>-->
  </div>
</app-modal>

<app-modal *ngIf="displaSubscribe" [closeOutside]="false" (onClosed)="displaSubscribe = false"
           [caption]="'Участие в мероприятиях'">
  <ng-template [ngTemplateOutlet]="templateSubscribe"></ng-template>
</app-modal>
