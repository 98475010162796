<ng-container *ngIf="lock_type !== 'common_realty' && lock_type !== 'on_moment'">
<div class="row form_crm">
  <div class="col-md-8">
    <app-analytic-filter
      [base_url]="base_url"
      [leadSources]="leadSources"
      [lock_type]="lock_type">
    </app-analytic-filter>
  </div>
</div>
</ng-container>

<ng-container *ngIf="lock_type === 'on_moment'">
  <div class="row form_crm">
    <div class="col-md-12">
      <p class="caption">Данные представлены начиная c {{data['get_lead_statistic'][0]['first_date']}}</p>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="lock_type === 'common_attraction'">
  <div class="mb-5">
      <a href="{{server}}restful/get_attraction?date_start={{date_start}}&date_end={{date_end}}&bp_source={{a.snapshot.queryParams['bp_source']}}" target="_blank" class="button float-right">Скачать отчёт</a>
  </div>
</ng-container>


<div style="position: relative">
  <ng-container *ngIf="lock_type !== 'common_realty' && lock_type !== 'on_period' && lock_type !== 'on_moment'">
  <div class="pb-4 max-overflow">
    <table class="table">
      <thead>
      <tr>
        <th *ngFor="let th of head">{{th}}</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngIf="lock_type === null || lock_type === 'common_attraction'">
        <tr class="head-big">
          <td [attr.colspan]="colspan">Отдел привлечения</td>
        </tr>
        <tr class="head-sm">
          <td [attr.colspan]="colspan">Субагенты (ю.л./ИП)</td>
        </tr>


        <tr>
          <td>Первичная регистрация пользователя субагента (Пользователь нажал на кнопку регистрации на landing page)</td>
          <td *ngFor="let el of statistic['attraction_cnt_org_register']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_org_register-' + ind}">
                <a href="/analytic/inner-lead/table?method=get_summary_statistic&locked=cnt_org_register&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                   (click)="goBack.newLink('/analytic/inner-lead/table?method=get_summary_statistic&locked=cnt_org_register'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_org_register-' + ind, $event)">
                  {{el}}
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              {{el}}
            </ng-container>
          </td>
        </tr>
        <tr class="head-red">
          <td>Отклонение первичной регистрация пользователя субагента</td>
          <td *ngFor="let el of statistic['attraction_cnt_org_register_fail']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_org_register_fail-' + ind}">
                <a href="/analytic/inner-lead/table?method=get_summary_statistic&locked=cnt_org_register_fail&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                   (click)="goBack.newLink('/analytic/inner-lead/table?method=get_summary_statistic&locked=cnt_org_register_fail'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_org_register_fail-' + ind, $event)">
                  {{el}}
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              {{el}}
            </ng-container>
          </td>
        </tr>

        <tr>
          <td>Заполнение мастера субагентом (Пользователь подтвердил что он - руководитель и выбрал организацию)</td>
          <td *ngFor="let el of statistic['attraction_cnt_org_register_not_approve']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_org_register_not_approve-' + ind}">
                <a href="/analytic/inner-lead/table?method=get_summary_statistic&locked=cnt_org_register_not_approve&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                   (click)="goBack.newLink('/analytic/inner-lead/table?method=get_summary_statistic&locked=cnt_org_register_not_approve'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_org_register_not_approve-' + ind, $event)">
                  {{el}}
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              {{el}}
            </ng-container>
          </td>
        </tr>
        <tr class="head-red">
          <td>Отклонение заполнения мастера субагентом</td>
          <td *ngFor="let el of statistic['attraction_cnt_org_master_fail']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_org_master_fail-' + ind}">
                <a href="/analytic/inner-lead/table?method=get_summary_statistic&locked=cnt_org_master_fail&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                   (click)="goBack.newLink('/analytic/inner-lead/table?method=get_summary_statistic&locked=cnt_org_master_fail'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_org_master_fail-' + ind, $event)">
                  {{el}}
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              {{el}}
            </ng-container>
          </td>
        </tr>

        <tr>
          <td>Акцепт оферты субагентом (Пользователь подтвердил оферту и ожидает подтверждение менеджером, чтобы начать работу с системой)</td>
          <td *ngFor="let el of statistic['attraction_cnt_org_register_approve']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_org_register_approve-' + ind}">
                <a href="/analytic/inner-lead/table?method=get_summary_statistic&locked=cnt_org_register_approve&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                   (click)="goBack.newLink('/analytic/inner-lead/table?method=get_summary_statistic&locked=cnt_org_register_approve'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_org_register_approve-' + ind, $event)">
                  {{el}}
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              {{el}}
            </ng-container>
          </td>
        </tr>
        <tr class="head-red">
          <td>Отклонение акцепта оферты субагентом</td>
          <td *ngFor="let el of statistic['attraction_cnt_org_register_approve_fail']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_org_register_approve_fail-' + ind}">
                <a href="/analytic/inner-lead/table?method=get_summary_statistic&locked=cnt_org_register_approve_fail&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                   (click)="goBack.newLink('/analytic/inner-lead/table?method=get_summary_statistic&locked=cnt_org_register_approve_fail'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_org_register_approve_fail-' + ind, $event)">
                  {{el}}
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              {{el}}
            </ng-container>
          </td>
        </tr>

        <tr>
          <td>Подтверждение субагента менеджером (Подтверждение субагента произошло, субагент наделен полными правами)</td>
          <td *ngFor="let el of statistic['attraction_cnt_org_approve']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_org_approve-' + ind}">
                <a href="/analytic/inner-lead/table?method=get_summary_statistic&locked=cnt_org_approve&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                   (click)="goBack.newLink('/analytic/inner-lead/table?method=get_summary_statistic&locked=cnt_org_approve'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_org_approve-' + ind, $event)">
                  {{el}}
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              {{el}}
            </ng-container>
          </td>
        </tr>

        <tr>
          <td>Кол-во Субагентов нарастающим итогом </td>
          <td *ngFor="let el of statistic['attraction_cnt_org_register_summary']">
            {{el}}
          </td>
        </tr>

        <tr class="head-red">
          <td>Отклонение подтверждённых субагентских организаций менеджером</td>
          <td *ngFor="let el of statistic['attraction_cnt_org_fail']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_org_approve_fail-' + ind}">
                <a href="/analytic/inner-lead/table?method=get_summary_statistic&locked=cnt_org_approve_fail&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                   (click)="goBack.newLink('/analytic/inner-lead/table?method=get_summary_statistic&locked=cnt_org_approve_fail'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_org_approve_fail-' + ind, $event)">
                  {{el}}
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              {{el}}
            </ng-container>
          </td>
        </tr>

        <tr>
          <td class="font-weight-bold">Кол-во активных субагентов</td>
          <td *ngFor="let el of statistic['attraction_cnt_active_subagents']">
            {{el}}
          </td>
        </tr>

        <tr class="head-sm">
          <td [attr.colspan]="colspan">Сотрудники субагентов</td>
        </tr>
        <tr>
          <td>Кол-во новых сотрудников</td>
          <td *ngFor="let el of statistic['attraction_cnt_new_workers']">
            {{el}}
          </td>
        </tr>
        <tr>
          <td class="font-weight-bold">Кол-во сотрудников, нарастающим итогом</td>
          <td *ngFor="let el of statistic['attraction_cnt_workers_summary']">
            {{el}}
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="lock_type === null || lock_type === 'common_events'">
      <tr class="head-big">
        <td [attr.colspan]="colspan">Обучение</td>
      </tr>
      <tr class="head-sm">
        <td><strong>Мероприятий (всех типов) проведено</strong></td>
        <td *ngFor="let el of statistic['calendar_events_approve']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'calendar_events_approve-' + ind}">
              <a href="/calendar/statistic/{{(ind ? '01.' + months[ind] : date_start)}}/{{(((ind+2) !== head.length) ? months_lastday[ind] : date_end)}}/any"
                 (click)="goBack.newLink('/calendar/statistic/' + (ind ? '01.' + months[ind] : date_start) + '/' + (((ind+2) !== head.length) ? months_lastday[ind] : date_end) + '/any', 'calendar_events_approve-' + ind, $event)">
                {{el}}
              </a>
            </div>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <tr class="head-sm">
        <td [attr.colspan]="colspan">Неуникальные сотрудники на мероприятиях в месяц</td>
      </tr>
      <tr>
        <td>Записавшиеся на все типы мероприятий</td>
        <td *ngFor="let el of statistic['calendar_staff_events_subscribe']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_staff_events_subscribe-' + ind}">
              <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_staff_events_subscribe&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_staff_events_subscribe'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_staff_events_subscribe-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['total_new_users_subscribe'][ind]">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'total_new_users_subscribe-' + ind}">
                из них новых
                <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_staff_events_subscribe&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                   (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_staff_events_subscribe'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'total_new_users_subscribe-' + ind, $event)">
                  {{statistic['total_new_users_subscribe'][ind]}}
                </a>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
        <tr>
          <td>Посетившие все типы мероприятий</td>
          <td *ngFor="let el of statistic['calendar_staff_events_visit']; let ind=index">
            <ng-container *ngIf="el && el !== '-'">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_staff_events_visit-' + ind}">
                <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_staff_events_visit&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                   (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_staff_events_visit'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_staff_events_visit-' + ind, $event)">
                  {{el}}
                </a>
              </div>
              <ng-container *ngIf="statistic['total_new_users_visit'][ind]">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'total_new_users_visit-' + ind}">
                  из них новых
                  <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_staff_events_visit&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                     (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_staff_events_visit'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'total_new_users_visit-' + ind, $event)">
                    {{statistic['total_new_users_visit'][ind]}}
                  </a>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!el || el === '-'">
              {{el}}
            </ng-container>
          </td>
        </tr>
      <tr>
        <td>Отсуствующие на мероприятиях</td>
        <td *ngFor="let el of statistic['calendar_staff_events_not_visit']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_staff_events_not_visit-' + ind}">
              <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_staff_events_not_visit&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_staff_events_not_visit'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_staff_events_not_visit-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['total_new_users_not_visit'][ind]">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'total_new_users_not_visit-' + ind}">
                из них новых
                <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_staff_events_not_visit&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                   (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_staff_events_not_visit'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'total_new_users_not_visit-' + ind, $event)">
                  {{statistic['total_new_users_not_visit'][ind]}}
                </a>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <tr class="head-sm">
        <td [attr.colspan]="colspan">Юр. лица на мероприятиях в месяц</td>
      </tr>
      <tr>
        <td>Записавшиеся на все типы мероприятий</td>
        <td *ngFor="let el of statistic['calendar_orgs_events_subscribe']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_orgs_events_subscribe-' + ind}">
              <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_orgs_events_subscribe&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_orgs_events_subscribe'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_orgs_events_subscribe-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['total_new_org_subscribe_on_events'][ind] || statistic['total_resale_org_subscribe_on_events'][ind]">
              <div class="row">
                <div class="col">
                  из них:
                  <div class="row" [ngClass]="{'activeBox': goBack.activeHash === 'total_new_org_subscribe_on_events-' + ind}">
                    <div *ngIf="statistic['total_new_org_subscribe_on_events'][ind]" class="col">
                      - новых
                      <a
                        href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_orgs_events_subscribe&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                        (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_orgs_events_subscribe'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'total_new_org_subscribe_on_events-' + ind, $event)">
                        {{statistic['total_new_org_subscribe_on_events'][ind]}}
                      </a>
                    </div>
                  </div>
                  <div class="row" [ngClass]="{'activeBox': goBack.activeHash === 'total_resale_org_subscribe_on_events-' + ind}">
                    <div class="col" *ngIf="statistic['total_resale_org_subscribe_on_events'][ind]">
                      - вторичка
                      <a
                        href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_orgs_events_subscribe&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&resale=true"
                        (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_orgs_events_subscribe'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&resale=true'
                 + '&month=' + months[ind],
                 'total_resale_org_subscribe_on_events-' + ind, $event)">
                        {{statistic['total_resale_org_subscribe_on_events'][ind]}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
        <tr>
          <td>Посетившие все типы мероприятий</td>
          <td *ngFor="let el of statistic['calendar_orgs_events_visit']; let ind=index">
            <ng-container *ngIf="el && el !== '-'">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_orgs_events_visit-' + ind}">
                <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_orgs_events_visit&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                   (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_orgs_events_visit'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_orgs_events_visit-' + ind, $event)">
                  {{el}}
                </a>
              </div>
              <ng-container
                *ngIf="statistic['total_new_org_visit_events'][ind] || statistic['total_resale_org_visit_events'][ind]">
                <div class="row">
                  <div class="col">
                    из них:
                    <div class="row" *ngIf="statistic['total_new_org_visit_events'][ind]">
                      <div class="col"
                           [ngClass]="{'activeBox': goBack.activeHash === 'total_new_org_visit_events-' + ind}">
                        - новых
                        <a
                          href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_orgs_events_visit&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                          (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_orgs_events_visit'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'total_new_org_visit_events-' + ind, $event)">
                          {{statistic['total_new_org_visit_events'][ind]}}
                        </a>
                      </div>
                    </div>
                    <div class="row" *ngIf="statistic['total_resale_org_visit_events'][ind]">
                      <div class="col"
                           [ngClass]="{'activeBox': goBack.activeHash === 'total_resale_org_visit_events-' + ind}">
                        - вторичка
                        <a
                          href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_orgs_events_visit&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&resale=true"
                          (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_orgs_events_visit'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&resale=true'
                 + '&month=' + months[ind],
                 'total_resale_org_visit_events-' + ind, $event)">
                          {{statistic['total_resale_org_visit_events'][ind]}}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!el || el === '-'">
              {{el}}
            </ng-container>
          </td>
        </tr>
      <tr>
        <td>Отсуствующие на мероприятии</td>
        <td *ngFor="let el of statistic['calendar_orgs_events_not_visit']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_orgs_events_not_visit-' + ind}">
              <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_orgs_events_not_visit&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_orgs_events_not_visit'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_orgs_events_not_visit-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container
              *ngIf="statistic['total_new_org_not_visit_events'][ind] || statistic['total_resale_org_not_visit_events'][ind]">
              <div class="row">
                <div class="col">
                  из них:
                  <div class="row" *ngIf="statistic['total_new_org_not_visit_events'][ind]">
                    <div class="col"
                         [ngClass]="{'activeBox': goBack.activeHash === 'total_new_org_not_visit_events-' + ind}">
                      - новых
                      <a
                        href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_orgs_events_not_visit&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                        (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_orgs_events_not_visit'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'total_new_org_not_visit_events-' + ind, $event)">
                        {{statistic['total_new_org_not_visit_events'][ind]}}
                      </a>
                    </div>
                  </div>
                  <div class="row" *ngIf="statistic['total_resale_org_not_visit_events'][ind]">
                    <div class="col"
                         [ngClass]="{'activeBox': goBack.activeHash === 'total_resale_org_not_visit_events-' + ind}">
                      - вторичка
                      <a
                        href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_orgs_events_not_visit&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&resale=true"
                        (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_orgs_events_not_visit'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&resale=true'
                 + '&month=' + months[ind],
                 'total_resale_org_not_visit_events-' + ind, $event)">
                        {{statistic['total_resale_org_not_visit_events'][ind]}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <tr class="head-big">
        <td [attr.colspan]="colspan">В том числе</td>
      </tr>
      <!-- -->
      <tr class="head-sm">
        <td><strong>Обучающий семинар</strong></td>
        <td *ngFor="let el of statistic['calendar_training_seminar']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'calendar_training_seminar-' + ind}">
              <a href="/calendar/statistic/{{(ind ? '01.' + months[ind] : date_start)}}/{{(((ind+2) !== head.length) ? months_lastday[ind] : date_end)}}/1"
                 (click)="goBack.newLink('/calendar/statistic/' + (ind ? '01.' + months[ind] : date_start) + '/' + (((ind+2) !== head.length) ? months_lastday[ind] : date_end) + '/1', 'calendar_training_seminar-' + ind, $event)">
                {{el}}
              </a>
            </div>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>



      <tr class="head-sm">
        <td [attr.colspan]="colspan">Неуникальные сотрудники на семинарах в месяц</td>
      </tr>
      <tr>
        <td>Записавшиеся на семинары</td>
        <td *ngFor="let el of statistic['calendar_user_subscribe_training']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_user_subscribe_training-' + ind}">
              <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_subscribe_training&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_subscribe_training'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_user_subscribe_training-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['new_users_subscription_training'][ind]">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'new_users_subscription_training-' + ind}">
                из них новых
                <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_subscribe_training&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                   (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_subscribe_training'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_users_subscription_training-' + ind, $event)">
                  {{statistic['new_users_subscription_training'][ind]}}
                </a>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <tr>
        <td>Посетившие семинары</td>
        <td *ngFor="let el of statistic['calendar_user_visit_training']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_user_visit_training-' + ind}">
              <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_visit_training&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_visit_training'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_user_visit_training-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['new_users_visit_training'][ind]">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'new_users_visit_training-' + ind}">
                из них новых
                <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_visit_training&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                   (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_visit_training'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_users_visit_training-' + ind, $event)">
                  {{statistic['new_users_visit_training'][ind]}}
                </a>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <tr>
        <td>Отсуствующие на семинарах</td>
        <td *ngFor="let el of statistic['calendar_user_not_visit_training']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_user_not_visit_training-' + ind}">
              <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_not_visit_training&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_not_visit_training'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_user_not_visit_training-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['new_users_not_visit_training'][ind]">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'new_users_not_visit_training-' + ind}">
                из них новых
                <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_not_visit_training&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                   (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_not_visit_training'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_users_not_visit_training-' + ind, $event)">
                  {{statistic['new_users_not_visit_training'][ind]}}
                </a>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <tr class="head-sm">
        <td [attr.colspan]="colspan">Юр. лица на семинарах в месяц</td>
      </tr>
      <tr>
        <td>Записавшиеся на семинары</td>
        <td *ngFor="let el of statistic['calendar_org_subscribe_training']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_org_subscribe_training-' + ind}">
              <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_training&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_training'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_org_subscribe_training-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container
              *ngIf="statistic['new_org_subscription_training'][ind]">
              <div class="row">
                <div class="col">
                  из них:
                  <div class="row" *ngIf="statistic['new_org_subscription_training'][ind] || statistic['resale_org_subscription_training'][ind]">
                    <div class="col"
                         [ngClass]="{'activeBox': goBack.activeHash === 'new_org_subscription_training-' + ind}">
                      - новых
                      <a
                        href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_training&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                        (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_training'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_org_subscription_training-' + ind, $event)">
                        {{statistic['new_org_subscription_training'][ind]}}
                      </a>
                    </div>
                  </div>
                  <div class="row" *ngIf="statistic['resale_org_subscription_training'][ind]">
                    <div class="col"
                         [ngClass]="{'activeBox': goBack.activeHash === 'resale_org_subscription_training-' + ind}">
                      - вторичка
                      <a
                        href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_training&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&resale=true"
                        (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_training'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&resale=true'
                 + '&month=' + months[ind],
                 'resale_org_subscription_training-' + ind, $event)">
                        {{statistic['resale_org_subscription_training'][ind]}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <tr>
        <td>Посетившие семинары</td>
        <td *ngFor="let el of statistic['calendar_org_visit_training']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_org_visit_training-' + ind}">
              <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_training&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_training'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_org_visit_training-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['new_org_visit_training'][ind] || statistic['resale_org_visit_training'][ind]">
              <div class="row">
                <div class="col">из них:</div>
                <div class="row" *ngIf="statistic['new_org_visit_training'][ind]">
                  <div class="col" [ngClass]="{'activeBox': goBack.activeHash === 'new_org_visit_training-' + ind}">
                    - новых
                    <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_training&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                      (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_training'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_org_visit_training-' + ind, $event)">
                      {{statistic['new_org_visit_training'][ind]}}
                    </a>
                  </div>
                </div>
                <div class="row" *ngIf="statistic['resale_org_visit_training'][ind]">
                  <div class="col" [ngClass]="{'activeBox': goBack.activeHash === 'resale_org_visit_training-' + ind}">
                    - вторичка
                    <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_training&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&resale=true"
                      (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_training'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&resale=true'
                 + '&month=' + months[ind],
                 'resale_org_visit_training-' + ind, $event)">
                      {{statistic['resale_org_visit_training'][ind]}}
                    </a>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <tr>
        <td>Отсуствующие на семинарах</td>
        <td *ngFor="let el of statistic['calendar_org_not_visit_training']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_org_not_visit_training-' + ind}">
              <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_training&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_training'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_org_not_visit_training-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['new_org_not_visit_training'][ind] || statistic['resale_org_not_visit_training'][ind]">
              <div class="row">
                <div class="row">
                  <div class="col">
                    из них:
                    <div class="row" *ngIf="statistic['new_org_not_visit_training'][ind]">
                      <div class="col" [ngClass]="{'activeBox': goBack.activeHash === 'new_org_not_visit_training-' + ind}">
                        - новых
                        <a
                          href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_training&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                          (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_training'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_org_not_visit_training-' + ind, $event)">
                          {{statistic['new_org_not_visit_training'][ind]}}
                        </a>
                      </div>
                    </div>
                    <div class="row" *ngIf="statistic['resale_org_not_visit_training'][ind]">
                      <div class="col" [ngClass]="{'activeBox': goBack.activeHash === 'resale_org_not_visit_training-' + ind}">
                        - вторичка
                        <a
                          href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_training&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&resale=true"
                          (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_training'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&resale=true'
                 + '&month=' + months[ind],
                 'resale_org_not_visit_training-' + ind, $event)">
                          {{statistic['resale_org_not_visit_training'][ind]}}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <!-- -->

      <tr class="head-sm">
        <td><strong>Вебинар</strong></td>
        <td *ngFor="let el of statistic['calendar_webinar']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'calendar_webinar-' + ind}">
              <a href="/calendar/statistic/{{(ind ? '01.' + months[ind] : date_start)}}/{{(((ind+2) !== head.length) ? months_lastday[ind] : date_end)}}/3"
                 (click)="goBack.newLink('/calendar/statistic/' + (ind ? '01.' + months[ind] : date_start) + '/' + (((ind+2) !== head.length) ? months_lastday[ind] : date_end) + '/3', 'calendar_webinar-' + ind, $event)">
                {{el}}
              </a>
            </div>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>

      <tr class="head-sm">
        <td [attr.colspan]="colspan">Неуникальные сотрудники на вебинарах в месяц</td>
      </tr>
      <tr>
        <td>Записавшиеся на вебинары</td>
        <td *ngFor="let el of statistic['calendar_user_subscribe_webinar']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_user_subscribe_webinar-' + ind}">
              <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_subscribe_webinar&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_subscribe_webinar'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_user_subscribe_webinar-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['new_users_subscription_webinar'][ind]">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'new_users_subscription_webinar-' + ind}">
                из них новых
                <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_subscribe_webinar&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                   (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_subscribe_webinar'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_users_subscription_webinar-' + ind, $event)">
                  {{statistic['new_users_subscription_webinar'][ind]}}
                </a>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <tr>
        <td>Посетившие вебинары</td>
        <td *ngFor="let el of statistic['calendar_user_visit_webinar']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_user_visit_webinar-' + ind}">
              <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_visit_webinar&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_visit_webinar'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_user_visit_webinar-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['new_users_visit_webinar'][ind]">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'new_users_visit_webinar-' + ind}">
                из них новых
                <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_visit_webinar&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                   (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_visit_webinar'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_users_visit_webinar-' + ind, $event)">
                  {{statistic['new_users_visit_webinar'][ind]}}
                </a>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <tr>
        <td>Отсуствующие на вебинарах</td>
        <td *ngFor="let el of statistic['calendar_user_not_visit_webinar']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_user_not_visit_webinar-' + ind}">
              <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_not_visit_webinar&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_not_visit_webinar'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_user_not_visit_webinar-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['new_users_not_visit_webinar'][ind]">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'new_users_not_visit_webinar-' + ind}">
                из них новых
                <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_not_visit_webinar&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                   (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_not_visit_webinar'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_users_not_visit_webinar-' + ind, $event)">
                  {{statistic['new_users_not_visit_webinar'][ind]}}
                </a>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <tr class="head-sm">
        <td [attr.colspan]="colspan">Юр. лица на вебинарах в месяц</td>
      </tr>
      <tr>
        <td>Записавшиеся на вебинары</td>
        <td *ngFor="let el of statistic['calendar_org_subscribe_webinar']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_org_subscribe_webinar-' + ind}">
              <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_webinar&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_webinar'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_org_subscribe_webinar-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['new_org_subscription_webinar'][ind] || statistic['resale_org_subscription_webinar'][ind]">
              <div class="row" >
                <div class="col">из них:
                  <div class="row" *ngIf="statistic['new_org_subscription_webinar'][ind]">
                    <div class="col" [ngClass]="{'activeBox': goBack.activeHash === 'new_org_subscription_webinar-' + ind}">
                      - новых
                      <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_webinar&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                        (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_webinar'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_org_subscription_webinar-' + ind, $event)">
                        {{statistic['new_org_subscription_webinar'][ind]}}
                      </a>
                    </div>
                  </div>
                  <div class="row" *ngIf="statistic['resale_org_subscription_webinar'][ind]">
                    <div class="col" [ngClass]="{'activeBox': goBack.activeHash === 'resale_org_subscription_webinar-' + ind}">
                      - вторичка
                      <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_webinar&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&resale=true"
                        (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_webinar'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&resale=true'
                 + '&month=' + months[ind],
                 'resale_org_subscription_webinar-' + ind, $event)">
                        {{statistic['resale_org_subscription_webinar'][ind]}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <tr>
        <td>Посетившие вебинары</td>
        <td *ngFor="let el of statistic['calendar_org_visit_webinar']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_org_visit_webinar-' + ind}">
              <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_webinar&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_webinar'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_org_visit_webinar-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['new_org_visit_webinar'][ind] || statistic['resale_org_visit_webinar'][ind]">
              <div class="row">
                <div class="col">
                  из них:
                  <div class="row" *ngIf="statistic['new_org_visit_webinar'][ind]">
                    <div class="col" [ngClass]="{'activeBox': goBack.activeHash === 'new_org_visit_webinar-' + ind}">
                      - новых
                      <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_webinar&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                        (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_webinar'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_org_visit_webinar-' + ind, $event)">
                        {{statistic['new_org_visit_webinar'][ind]}}
                      </a>
                    </div>
                  </div>
                  <div class="row" *ngIf="statistic['resale_org_visit_webinar'][ind]">
                    <div class="col" [ngClass]="{'activeBox': goBack.activeHash === 'resale_org_visit_webinar-' + ind}">
                      - вторичка
                      <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_webinar&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&resale=true"
                        (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_webinar'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&resale=true'
                 + '&month=' + months[ind],
                 'resale_org_visit_webinar-' + ind, $event)">
                        {{statistic['resale_org_visit_webinar'][ind]}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <tr>
        <td>Отсуствующие на вебинарах</td>
        <td *ngFor="let el of statistic['calendar_org_not_visit_webinar']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_org_not_visit_webinar-' + ind}">
              <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_webinar&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_webinar'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_org_not_visit_webinar-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['new_org_not_visit_webinar'][ind] || statistic['resale_org_not_visit_webinar'][ind]">
              <div class="row">
                <div class="col">из них:
                  <div class="row" *ngIf="statistic['new_org_not_visit_webinar'][ind]">
                    <div class="col"
                         [ngClass]="{'activeBox': goBack.activeHash === 'new_org_not_visit_webinar-' + ind}">
                      - новых
                      <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_webinar&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                        (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_webinar'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_org_not_visit_webinar-' + ind, $event)">
                        {{statistic['new_org_not_visit_webinar'][ind]}}
                      </a>
                    </div>
                  </div>
                  <div class="row" *ngIf="statistic['resale_org_not_visit_webinar'][ind]">
                    <div class="col"
                         [ngClass]="{'activeBox': goBack.activeHash === 'resale_org_not_visit_webinar-' + ind}">
                      - вторичка
                      <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_webinar&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&resale=true"
                        (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_webinar'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&resale=true'
                 + '&month=' + months[ind],
                 'resale_org_not_visit_webinar-' + ind, $event)">
                        {{statistic['resale_org_not_visit_webinar'][ind]}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>

      <!-- -->
      <tr class="head-sm">
        <td><strong>Инфо-тур/Брокер-тур</strong></td>
        <td *ngFor="let el of statistic['calendar_info_tour']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'calendar_info_tour-' + ind}">
              <a href="/calendar/statistic/{{(ind ? '01.' + months[ind] : date_start)}}/{{(((ind+2) !== head.length) ? months_lastday[ind] : date_end)}}/2"
                 (click)="goBack.newLink('/calendar/statistic/' + (ind ? '01.' + months[ind] : date_start) + '/' + (((ind+2) !== head.length) ? months_lastday[ind] : date_end) + '/2', 'calendar_info_tour-' + ind, $event)">
                {{el}}
              </a>
            </div>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <tr class="head-sm">
        <td [attr.colspan]="colspan">Неуникальные сотрудники на мероприятиях в месяц</td>
      </tr>
      <tr>
        <td>Записавшиеся на мероприятия</td>
        <td *ngFor="let el of statistic['calendar_user_subscribe_info_tour']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_user_subscribe_info_tour-' + ind}">
              <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_subscribe_info_tour&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_subscribe_info_tour'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_user_subscribe_info_tour-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['new_users_subscription_infotur'][ind]">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'new_users_subscription_infotur-' + ind}">
                из них новых
                <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_subscribe_info_tour&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                   (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_subscribe_info_tour'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_users_subscription_infotur-' + ind, $event)">
                  {{statistic['new_users_subscription_infotur'][ind]}}
                </a>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <tr>
        <td>Посетившие мероприятия</td>
        <td *ngFor="let el of statistic['calendar_user_visit_info_tour']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_user_visit_info_tour-' + ind}">
              <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_visit_info_tour&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_visit_info_tour'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_user_visit_info_tour-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['new_users_visit_infotur'][ind]">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'new_users_visit_infotur-' + ind}">
                из них новых
                <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_visit_info_tour&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                   (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_visit_info_tour'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_users_visit_infotur-' + ind, $event)">
                  {{statistic['new_users_visit_infotur'][ind]}}
                </a>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <tr>
        <td>Отсуствующие на мероприятиях</td>
        <td *ngFor="let el of statistic['calendar_user_not_visit_info_tour']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_user_not_visit_info_tour-' + ind}">
              <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_not_visit_info_tour&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_not_visit_info_tour'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_user_not_visit_info_tour-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['new_users_not_visit_infotur'][ind]">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'new_users_not_visit_infotur-' + ind}">
                из них новых
                <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_not_visit_info_tour&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                   (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_not_visit_info_tour'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_users_not_visit_infotur-' + ind, $event)">
                  {{statistic['new_users_not_visit_infotur'][ind]}}
                </a>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <tr class="head-sm">
        <td [attr.colspan]="colspan">Юр. лица на мероприятиях в месяц</td>
      </tr>
      <tr>
        <td>Записавшиеся на мероприятия</td>
        <td *ngFor="let el of statistic['calendar_org_subscribe_info_tour']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_org_subscribe_info_tour-' + ind}">
              <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_info_tour&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_info_tour'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_org_subscribe_info_tour-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['new_org_subscription_infotur'][ind] || statistic['resale_org_subscription_infotur'][ind]">
              <div class="row" [ngClass]="{'activeBox': goBack.activeHash === 'new_org_subscription_infotur-' + ind}">
                <div class="col">
                из них:
                  <div class="row" *ngIf="statistic['new_org_subscription_infotur'][ind]">
                    <div class="col" [ngClass]="{'activeBox': goBack.activeHash === 'new_org_subscription_infotur-' + ind}">
                      - новых
                      <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_info_tour&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                        (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_info_tour'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_org_subscription_infotur-' + ind, $event)">
                        {{statistic['new_org_subscription_infotur'][ind]}}
                      </a>
                    </div>
                  </div>
                  <div class="row" *ngIf="statistic['resale_org_subscription_infotur'][ind]">
                    <div class="col" [ngClass]="{'activeBox': goBack.activeHash === 'resale_org_subscription_infotur-' + ind}">
                      - вторичка
                      <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_info_tour&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&resale=true"
                        (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_info_tour'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&resale=true'
                 + '&month=' + months[ind],
                 'resale_org_subscription_infotur-' + ind, $event)">
                        {{statistic['resale_org_subscription_infotur'][ind]}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <tr>
        <td>Посетившие мероприятия</td>
        <td *ngFor="let el of statistic['calendar_org_visit_info_tour']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_org_visit_info_tour-' + ind}">
              <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_info_tour&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_info_tour'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_org_visit_info_tour-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['new_org_visit_infotur'][ind] || statistic['resale_org_visit_infotur'][ind]">
              <div class="row" [ngClass]="{'activeBox': goBack.activeHash === 'new_org_visit_infotur-' + ind}">
                <div class="col">из них:
                  <div class="row" *ngIf="statistic['new_org_visit_infotur'][ind]">
                    <div class="col" [ngClass]="{'activeBox': goBack.activeHash === 'new_org_visit_infotur-' + ind}">
                      - новых
                      <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_info_tour&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                        (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_info_tour'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_org_visit_infotur-' + ind, $event)">
                        {{statistic['new_org_visit_infotur'][ind]}}
                      </a>
                    </div>
                  </div>
                  <div class="row" *ngIf="statistic['resale_org_visit_infotur'][ind]">
                    <div class="col" [ngClass]="{'activeBox': goBack.activeHash === 'resale_org_visit_infotur-' + ind}">
                      - вторичка
                      <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_info_tour&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&resale=true"
                        (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_info_tour'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&resale=true'
                 + '&month=' + months[ind],
                 'resale_org_visit_infotur-' + ind, $event)">
                        {{statistic['resale_org_visit_infotur'][ind]}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>
      <tr>
        <td>Отсуствующие на мероприятиях</td>
        <td *ngFor="let el of statistic['calendar_org_not_visit_info_tour']; let ind=index">
          <ng-container *ngIf="el && el !== '-'">
            <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_org_not_visit_info_tour-' + ind}">
              <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_info_tour&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_info_tour'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'cnt_org_not_visit_info_tour-' + ind, $event)">
                {{el}}
              </a>
            </div>
            <ng-container *ngIf="statistic['new_org_not_visit_infotur'][ind] || statistic['resale_org_not_visit_infotur'][ind]">
              <div class="row" >
                <div class="col">из них:
                  <div class="row" *ngIf="statistic['new_org_not_visit_infotur'][ind]">
                    <div class="col" [ngClass]="{'activeBox': goBack.activeHash === 'new_org_not_visit_infotur-' + ind}">
                      - новых
                      <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_info_tour&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                        (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_info_tour'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_org_not_visit_infotur-' + ind, $event)">
                        {{statistic['new_org_not_visit_infotur'][ind]}}
                      </a>
                    </div>
                  </div>
                  <div class="row" *ngIf="statistic['resale_org_not_visit_infotur'][ind]">
                    <div class="col" [ngClass]="{'activeBox': goBack.activeHash === 'resale_org_not_visit_infotur-' + ind}">
                      - вторичка
                      <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_info_tour&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&resale=true"
                        (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_info_tour'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&resale=true'
                 + '&month=' + months[ind],
                 'resale_org_not_visit_infotur-' + ind, $event)">
                        {{statistic['resale_org_not_visit_infotur'][ind]}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!el || el === '-'">
            {{el}}
          </ng-container>
        </td>
      </tr>

        <!-- meeting_developer -->
        <tr class="head-sm">
          <td><strong>Встреча с застройщиком</strong></td>
          <td *ngFor="let el of statistic['calendar_meeting_developer']; let ind=index">
            <ng-container *ngIf="el && el !== '-'">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'calendar_meeting_developer-' + ind}">
                <a href="/calendar/statistic/{{(ind ? '01.' + months[ind] : date_start)}}/{{(((ind+2) !== head.length) ? months_lastday[ind] : date_end)}}/4"
                   (click)="goBack.newLink('/calendar/statistic/' + (ind ? '01.' + months[ind] : date_start) + '/' + (((ind+2) !== head.length) ? months_lastday[ind] : date_end) + '/4', 'calendar_meeting_developer-' + ind, $event)">
                  {{el}}
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el || el === '-'">
              {{el}}
            </ng-container>
          </td>
        </tr>
        <tr class="head-sm">
          <td [attr.colspan]="colspan">Неуникальные сотрудники на мероприятиях в месяц</td>
        </tr>
        <tr>
          <td>Записавшиеся на мероприятия</td>
          <td *ngFor="let el of statistic['calendar_user_subscribe_meeting_developer']; let ind=index">
            <ng-container *ngIf="el && el !== '-'">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'calendar_user_subscribe_meeting_developer-' + ind}">
                <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_subscribe_meeting_developer&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                   (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_subscribe_meeting_developer'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'calendar_user_subscribe_meeting_developer-' + ind, $event)">
                  {{el}}
                </a>
              </div>
              <ng-container *ngIf="statistic['new_users_subscription_meeting_developer'][ind]">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'new_users_subscription_meeting_developer-' + ind}">
                  из них новых
                  <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_subscribe_meeting_developer&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                     (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_subscribe_meeting_developer'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_users_subscription_meeting_developer-' + ind, $event)">
                    {{statistic['new_users_subscription_meeting_developer'][ind]}}
                  </a>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!el || el === '-'">
              {{el}}
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Посетившие мероприятия</td>
          <td *ngFor="let el of statistic['calendar_user_visit_meeting_developer']; let ind=index">
            <ng-container *ngIf="el && el !== '-'">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'calendar_user_visit_meeting_developer-' + ind}">
                <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_visit_meeting_developer&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                   (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_visit_meeting_developer'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'calendar_user_visit_meeting_developer-' + ind, $event)">
                  {{el}}
                </a>
              </div>
              <ng-container *ngIf="statistic['new_users_visit_meeting_developer'][ind]">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'new_users_visit_meeting_developer-' + ind}">
                  из них новых
                  <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_visit_meeting_developer&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                     (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_visit_meeting_developer'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_users_visit_meeting_developer-' + ind, $event)">
                    {{statistic['new_users_visit_meeting_developer'][ind]}}
                  </a>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!el || el === '-'">
              {{el}}
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Отсуствующие на мероприятиях</td>
          <td *ngFor="let el of statistic['calendar_user_not_visit_meeting_developer']; let ind=index">
            <ng-container *ngIf="el && el !== '-'">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'calendar_user_not_visit_meeting_developer-' + ind}">
                <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_not_visit_meeting_developer&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                   (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_not_visit_meeting_developer'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'calendar_user_not_visit_meeting_developer-' + ind, $event)">
                  {{el}}
                </a>
              </div>
              <ng-container *ngIf="statistic['new_users_not_visit_meeting_developer'][ind]">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'new_users_not_visit_infotur-' + ind}">
                  из них новых
                  <a href="/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_not_visit_meeting_developer&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                     (click)="goBack.newLink('/analytic/calendar-summary/table?method=get_calendar_summary&stage=cnt_user_not_visit_meeting_developer'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_users_not_visit_meeting_developer-' + ind, $event)">
                    {{statistic['new_users_not_visit_meeting_developer'][ind]}}
                  </a>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!el || el === '-'">
              {{el}}
            </ng-container>
          </td>
        </tr>
        <tr class="head-sm">
          <td [attr.colspan]="colspan">Юр. лица на мероприятиях в месяц</td>
        </tr>
        <tr>
          <td>Записавшиеся на мероприятия</td>
          <td *ngFor="let el of statistic['calendar_org_subscribe_meeting_developer']; let ind=index">
            <ng-container *ngIf="el && el !== '-'">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'calendar_org_subscribe_meeting_developer-' + ind}">
                <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_meeting_developer&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                   (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_meeting_developer'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'calendar_org_subscribe_meeting_developer-' + ind, $event)">
                  {{el}}
                </a>
              </div>
              <ng-container
                *ngIf="statistic['new_org_subscription_meeting_developer'][ind] || statistic['resale_org_subscription_meeting_developer'][ind]">
                <div class="row">
                  <div class="col">из них:
                    <div class="row" *ngIf="statistic['new_org_subscription_meeting_developer'][ind]">
                      <div class="col"
                           [ngClass]="{'activeBox': goBack.activeHash === 'new_org_subscription_meeting_developer-' + ind}">
                        - новых
                        <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_meeting_developer&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                          (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_meeting_developer'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_org_subscription_meeting_developer-' + ind, $event)">
                          {{statistic['new_org_subscription_meeting_developer'][ind]}}
                        </a>
                      </div>
                    </div>
                    <div class="row" *ngIf="statistic['resale_org_subscription_meeting_developer'][ind]">
                      <div class="col"
                           [ngClass]="{'activeBox': goBack.activeHash === 'resale_org_subscription_meeting_developer-' + ind}">
                        - вторичка
                        <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_meeting_developer&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&resale=true"
                          (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_subscribe_meeting_developer'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&resale=true'
                 + '&month=' + months[ind],
                 'resale_org_subscription_meeting_developer-' + ind, $event)">
                          {{statistic['resale_org_subscription_meeting_developer'][ind]}}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!el || el === '-'">
              {{el}}
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Посетившие мероприятия</td>
          <td *ngFor="let el of statistic['calendar_org_visit_meeting_developer']; let ind=index">
            <ng-container *ngIf="el && el !== '-'">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_org_visit_info_tour-' + ind}">
                <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_meeting_developer&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                   (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_meeting_developer'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'calendar_org_visit_meeting_developer-' + ind, $event)">
                  {{el}}
                </a>
              </div>
              <ng-container *ngIf="statistic['new_org_visit_meeting_developer'][ind] || statistic['resale_org_visit_meeting_developer'][ind]">
                <div class="row">
                  <div class="col">из них:
                    <div class="row" *ngIf="statistic['new_org_visit_meeting_developer'][ind]">
                      <div class="col" [ngClass]="{'activeBox': goBack.activeHash === 'new_org_visit_infotur-' + ind}">
                        - новых
                        <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_meeting_developer&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                          (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_meeting_developer'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_org_visit_meeting_developer-' + ind, $event)">
                          {{statistic['new_org_visit_meeting_developer'][ind]}}
                        </a>
                      </div>
                    </div>
                    <div class="row" *ngIf="statistic['resale_org_visit_meeting_developer'][ind]">
                      <div class="col" [ngClass]="{'activeBox': goBack.activeHash === 'resale_org_visit_infotur-' + ind}">
                        - новых
                        <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_meeting_developer&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&resale=true"
                          (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_visit_meeting_developer'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&resale=true'
                 + '&month=' + months[ind],
                 'resale_org_visit_meeting_developer-' + ind, $event)">
                          {{statistic['resale_org_visit_meeting_developer'][ind]}}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!el || el === '-'">
              {{el}}
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Отсуствующие на мероприятиях</td>
          <td *ngFor="let el of statistic['calendar_org_not_visit_meeting_developer']; let ind=index">
            <ng-container *ngIf="el && el !== '-'">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'cnt_org_not_visit_info_tour-' + ind}">
                <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_meeting_development&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                   (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_meeting_development'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'calendar_org_not_visit_meeting_developer-' + ind, $event)">
                  {{el}}
                </a>
              </div>
              <ng-container *ngIf="statistic['new_org_not_visit_meeting_developer'][ind] || statistic['resale_org_not_visit_meeting_developer'][ind]">
                <div class="row">
                  <div class="col">из них:
                    <div class="row" *ngIf="statistic['new_org_not_visit_meeting_developer'][ind]">
                      <div class="col" [ngClass]="{'activeBox': goBack.activeHash === 'new_org_not_visit_infotur-' + ind}">
                        - новых
                        <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_meeting_development&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&new=true"
                          (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_meeting_development'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&new=true'
                 + '&month=' + months[ind],
                 'new_org_not_visit_meeting_developer-' + ind, $event)">
                          {{statistic['new_org_not_visit_meeting_developer'][ind]}}
                        </a></div>
                    </div>
                    <div class="row" *ngIf="statistic['resale_org_not_visit_meeting_developer'][ind]">
                      <div class="col" [ngClass]="{'activeBox': goBack.activeHash === 'resale_org_not_visit_infotur-' + ind}">
                        - вторичка
                        <a href="/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_meeting_development&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&resale=true"
                          (click)="goBack.newLink('/analytic/calendar-summary-organisation/table?method=get_calendar_summary_organisation&stage=cnt_org_not_visit_meeting_development'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&resale=true'
                 + '&month=' + months[ind],
                 'resale_org_not_visit_meeting_developer-' + ind, $event)">
                          {{statistic['resale_org_not_visit_meeting_developer'][ind]}}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!el || el === '-'">
              {{el}}
            </ng-container>
          </td>
        </tr>

      <tr class="head-sm">
        <td><strong>Иные мероприятия (в т.ч. выездное обучение)</strong></td>
        <td *ngFor="let el of statistic['calendar_other']">
          {{el}}
        </td>
      </tr>
      <!-- -->
      </ng-container>
      <ng-container *ngIf="lock_type === null || lock_type === 'common_events_realty'">
        <tr class="head-big">
          <td [attr.colspan]="colspan">Обучение</td>
        </tr>
        <tr class="head-sm">
          <td><strong>Мероприятий (всех типов) проведено</strong></td>
          <td *ngFor="let el of statistic['calendar_events_approve']; let ind=index">
            <ng-container *ngIf="el && el !== '-'">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'calendar_events_approve-' + ind}">
                <a href="/calendar/statistic/{{(ind ? '01.' + months[ind] : date_start)}}/{{(((ind+2) !== head.length) ? months_lastday[ind] : date_end)}}/any"
                   (click)="goBack.newLink('/calendar/statistic/' + (ind ? '01.' + months[ind] : date_start) + '/' + (((ind+2) !== head.length) ? months_lastday[ind] : date_end) + '/any', 'calendar_events_approve-' + ind, $event)">
                  {{el}}
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el || el === '-'">
              {{el}}
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Список комплексов</td>
          <td *ngFor="let el of statistic['calendar_all_complex']; let ind=index">
            <ng-container *ngIf="el !== '-'">
              <table>
                <tr>
                  <th>ЖК</th>
                  <th>Лидов</th>
                  <th>Броней</th>
                </tr>
                <tr *ngFor="let obj of el; let indComp=index">
                  <td>
                    <div class="d-flex" [ngClass]="{'activeBox': goBack.activeHash === 'all_complex-' + ind + indComp}">
                      <a class="complex-max" title="{{obj.caption}}" href="/housing2/complex/{{obj.id}}"
                         (click)="goBack.newLink('/housing2/complex/' + obj.id, 'all_complex-' + ind + indComp, $event)">
                        {{obj.caption}}
                      </a>
                    </div>
                  </td>
                  <td>
                    <b>{{obj.count_lead}}</b>
                  </td>
                  <td>
                    <b>{{obj.lead_db}}</b>
                  </td>
                </tr>
              </table>

            </ng-container>
          </td>
        </tr>
        <!-- -->
        <tr class="head-big">
          <td [attr.colspan]="colspan">В том числе</td>
        </tr>
        <tr class="head-sm">
          <td><strong>Обучающий семинар</strong></td>
          <td *ngFor="let el of statistic['calendar_training_seminar']; let ind=index">
            <ng-container *ngIf="el && el !== '-'">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'calendar_training_seminar-' + ind}">
                <a href="/calendar/statistic/{{(ind ? '01.' + months[ind] : date_start)}}/{{(((ind+2) !== head.length) ? months_lastday[ind] : date_end)}}/1"
                   (click)="goBack.newLink('/calendar/statistic/' + (ind ? '01.' + months[ind] : date_start) + '/' + (((ind+2) !== head.length) ? months_lastday[ind] : date_end) + '/1', 'calendar_training_seminar-' + ind, $event)">
                  {{el}}
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el || el === '-'">
              {{el}}
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Список комплексов</td>
          <td *ngFor="let el of statistic['calendar_complex_training']; let ind=index">
            <ng-container *ngIf="el !== '-'">
              <div *ngFor="let obj of el; let indComp=index" [ngClass]="{'activeBox': goBack.activeHash === 'complex_training-' + ind + indComp}">
                <a href="/housing2/complex/{{obj.id}}"
                   (click)="goBack.newLink('/housing2/complex/' + obj.id, 'complex_training-' + ind + indComp, $event)">
                  {{obj.caption}}
                </a>
              </div>
            </ng-container>
          </td>
        </tr>
        <!-- -->

        <tr class="head-sm">
          <td><strong>Вебинар</strong></td>
          <td *ngFor="let el of statistic['calendar_webinar']; let ind=index">
            <ng-container *ngIf="el && el !== '-'">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'calendar_webinar-' + ind}">
                <a href="/calendar/statistic/{{(ind ? '01.' + months[ind] : date_start)}}/{{(((ind+2) !== head.length) ? months_lastday[ind] : date_end)}}/3"
                   (click)="goBack.newLink('/calendar/statistic/' + (ind ? '01.' + months[ind] : date_start) + '/' + (((ind+2) !== head.length) ? months_lastday[ind] : date_end) + '/3', 'calendar_webinar-' + ind, $event)">
                  {{el}}
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el || el === '-'">
              {{el}}
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Список комплексов</td>
          <td *ngFor="let el of statistic['calendar_complex_webinar']; let ind=index">
            <ng-container *ngIf="el !== '-'">
              <div *ngFor="let obj of el; let indComp=index" [ngClass]="{'activeBox': goBack.activeHash === 'complex_webinar-' + ind + indComp}">
                <a href="/housing2/complex/{{obj.id}}"
                   (click)="goBack.newLink('/housing2/complex/' + obj.id, 'complex_webinar-' + ind + indComp, $event)">
                  {{obj.caption}}
                </a>
              </div>
            </ng-container>
          </td>
        </tr>

        <!-- -->
        <tr class="head-sm">
          <td><strong>Инфо-тур/Брокер-тур</strong></td>
          <td *ngFor="let el of statistic['calendar_info_tour']; let ind=index">
            <ng-container *ngIf="el && el !== '-'">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'calendar_info_tour-' + ind}">
                <a href="/calendar/statistic/{{(ind ? '01.' + months[ind] : date_start)}}/{{(((ind+2) !== head.length) ? months_lastday[ind] : date_end)}}/2"
                   (click)="goBack.newLink('/calendar/statistic/' + (ind ? '01.' + months[ind] : date_start) + '/' + (((ind+2) !== head.length) ? months_lastday[ind] : date_end) + '/2', 'calendar_info_tour-' + ind, $event)">
                  {{el}}
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el || el === '-'">
              {{el}}
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Список комплексов</td>
          <td *ngFor="let el of statistic['calendar_complex_infotur']; let ind=index">
            <ng-container *ngIf="el !== '-'">
              <div *ngFor="let obj of el; let indComp=index" [ngClass]="{'activeBox': goBack.activeHash === 'complex_infotur-' + ind + indComp}">
                <a href="/housing2/complex/{{obj.id}}"
                   (click)="goBack.newLink('/housing2/complex/' + obj.id, 'complex_infotur-' + ind + indComp, $event)">
                  {{obj.caption}}
                </a>
              </div>
            </ng-container>
          </td>
        </tr>

        <tr class="head-sm">
          <td><strong>Иные мероприятия (в т.ч. выездное обучение)</strong></td>
          <td *ngFor="let el of statistic['calendar_other']">
            {{el}}
          </td>
        </tr>
        <!-- -->
      </ng-container>
      <ng-container *ngIf="lock_type === null || lock_type === 'common_reserved'">
        <tr class="head-big">
          <td [attr.colspan]="colspan">Бронирование</td>
        </tr>
        <tr class="head-sm">
          <td>Кол-во обращений по типам операций (кол-во лидов поступивших в мес.)</td>
          <td *ngFor="let el of statistic['reserved_appeals_all_on_month']">
            {{el}}
          </td>
        </tr>
        <tr>
          <td>Вх./Исх. Звонок</td>
          <td *ngFor="let el of statistic['appeals_cnt_sale_call']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_call-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_call&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_call'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'sale_call-' + ind, $event)">
                {{el}}
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Проверка Уникальности</td>
          <td *ngFor="let el of statistic['reserved_check_unique']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_unique_check-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_unique_check&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_unique_check'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'sale_unique_check-' + ind, $event)">
                {{el}}
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Назначение просмотра</td>
          <td *ngFor="let el of statistic['reserved_views_subscribe']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_preview-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_preview&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_preview'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'sale_preview-' + ind, $event)">
                {{el}}
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Проведение просмотра</td>
          <td *ngFor="let el of statistic['reserved_views_success']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_view_processing-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_view_processing&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_view_processing'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'sale_view_processing-' + ind, $event)">
                {{el}}
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Ожидание резерва</td>
          <td *ngFor="let el of statistic['appeals_cnt_sale_pre_reserve']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_pre_reserve-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_pre_reserve&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_pre_reserve'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'sale_pre_reserve-' + ind, $event)">
                {{el}}
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Ожидание ДБ</td>
          <td *ngFor="let el of statistic['reserved_on_month']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_reserve-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_reserve&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_reserve'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'sale_reserve-' + ind, $event)">
                {{el}}
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr class="head-sm">
          <td>Кол-во расторжений - пресейл</td>
          <td *ngFor="let el of statistic['reserved_pre_sale_stages_fail']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'pre_sale_stages_fail-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_reserved_summary&stage=pre_sale_stages_fail&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_reserved_summary&stage=pre_sale_stages_fail'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'pre_sale_stages_fail-' + ind, $event)">
                {{el}}
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>ДБ, Получение док-тов</td>
          <td *ngFor="let el of statistic['reserved_successed_month']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_db-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_db&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_db'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'sale_db-' + ind, $event)">
                {{el}}
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>

        <tr>
          <td>ДДУ Подписание</td>
          <td *ngFor="let el of statistic['appeals_cnt_sale_ddu']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_ddu-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_ddu&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_ddu'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'sale_ddu-' + ind, $event)">
                {{el}}
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>ДДУ Оплата</td>
          <td *ngFor="let el of statistic['appeals_cnt_sale_ddu_payment']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_ddu_payment-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_ddu_payment&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_ddu_payment'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'sale_ddu_payment-' + ind, $event)">
                {{el}}
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>ПД-З Оплата</td>
          <td *ngFor="let el of statistic['appeals_cnt_sale_developer_payment']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_developer_payment-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_developer_payment&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_developer_payment'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'sale_developer_payment-' + ind, $event)">
                {{el}}
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>ПД-З Получена</td>
          <td *ngFor="let el of statistic['appeals_cnt_sale_developer_docs']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_developer_docs-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_developer_docs&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_developer_docs'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'sale_developer_docs-' + ind, $event)">
                {{el}}
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>ПД-С Получение док-тов</td>
          <td *ngFor="let el of statistic['appeals_cnt_sale_subagent_docs']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_subagent_docs-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_subagent_docs&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_subagent_docs'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'sale_subagent_docs-' + ind, $event)">
                {{el}}
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>ПД-С Оплата</td>
          <td *ngFor="let el of statistic['appeals_cnt_sale_subagent_payment']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_subagent_payment-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_subagent_payment&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_subagent_payment'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'sale_subagent_payment-' + ind, $event)">
                {{el}}
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Архив</td>
          <td *ngFor="let el of statistic['reserved_success_deals']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_archive-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_archive&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_archive'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'sale_archive-' + ind, $event)">
                {{el}}
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr class="head-sm">
          <td>Кол-во расторжений сделка</td>
          <td *ngFor="let el of statistic['reserved_sale_stages_fail']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_stages_fail-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_stages_fail&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_reserved_summary&stage=sale_stages_fail'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind],
                 'sale_stages_fail-' + ind, $event)">
                {{el}}
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
      </ng-container>

      <ng-container *ngIf="lock_type === null || lock_type === 'on_month'">
        <tr class="head-big">
          <td>Количество поступивших обращений</td>
          <td *ngFor="let el of statistic['on_month_total']">
            {{el}}
          </td>
        </tr>
        <tr class="head-sm">
          <td [attr.colspan]="colspan">В том числе</td>
        </tr>
        <tr>
          <td>Вх./Исх. Звонок</td>
          <td *ngFor="let el of statistic['on_month_sale_call']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_call-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_call&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&flag=on_month"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_call'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind]
                 + '&flag=on_month',
                 'sale_call-' + ind, $event)" [innerHtml]="el">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Проверка Уникальности</td>
          <td *ngFor="let el of statistic['on_month_sale_unique_check']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_unique_check-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_unique_check&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&flag=on_month"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_unique_check'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind]
                 + '&flag=on_month',
                 'sale_unique_check-' + ind, $event)" [innerHtml]="el">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Назначение просмотра</td>
          <td *ngFor="let el of statistic['on_month_sale_preview']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_preview-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_preview&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&flag=on_month"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_preview'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind]
                 + '&flag=on_month',
                 'sale_preview-' + ind, $event)" [innerHtml]="el">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Подтверждение просмотра</td>
          <td *ngFor="let el of statistic['on_month_sale_view']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_view-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_view&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&flag=on_month"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_view'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind]
                 + '&flag=on_month',
                 'sale_view-' + ind, $event)" [innerHtml]="el">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Проведение просмотра</td>
          <td *ngFor="let el of statistic['on_month_sale_view_processing']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_view_processing-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_view_processing&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&flag=on_month"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_view_processing'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind]
                 + '&flag=on_month',
                 'sale_view_processing-' + ind, $event)" [innerHtml]="el">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Ожидание резерва</td>
          <td *ngFor="let el of statistic['on_month_sale_pre_reserve']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_pre_reserve-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_pre_reserve&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&flag=on_month"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_pre_reserve'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind]
                 + '&flag=on_month',
                 'sale_pre_reserve-' + ind, $event)" [innerHtml]="el">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Резерв</td>
          <td *ngFor="let el of statistic['on_month_sale_reserve']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_reserve-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_reserve&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&flag=on_month"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_reserve'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind]
                 + '&flag=on_month',
                 'sale_reserve-' + ind, $event)" [innerHtml]="el">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>ДБ, Получение док-тов</td>
          <td *ngFor="let el of statistic['on_month_sale_db']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_db-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_db&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&flag=on_month"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_db'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind]
                 + '&flag=on_month',
                 'sale_db-' + ind, $event)" [innerHtml]="el">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>ДДУ Подписание </td>
          <td *ngFor="let el of statistic['on_month_sale_ddu']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_ddu-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_ddu&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&flag=on_month"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_ddu'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind]
                 + '&flag=on_month',
                 'sale_ddu-' + ind, $event)" [innerHtml]="el">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>ДДУ Оплата </td>
          <td *ngFor="let el of statistic['on_month_sale_ddu_payment']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_ddu_payment-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_ddu_payment&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&flag=on_month"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_ddu_payment'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind]
                 + '&flag=on_month',
                 'sale_ddu_payment-' + ind, $event)" [innerHtml]="el">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>ПД-З Оплата </td>
          <td *ngFor="let el of statistic['on_month_sale_developer_payment']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_developer_payment-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_developer_payment&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&flag=on_month"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_developer_payment'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind]
                 + '&flag=on_month',
                 'sale_developer_payment-' + ind, $event)" [innerHtml]="el">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>ПД-З Получена </td>
          <td *ngFor="let el of statistic['on_month_sale_developer_docs']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_developer_docs-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_developer_docs&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&flag=on_month"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_developer_docs'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind]
                 + '&flag=on_month',
                 'sale_developer_docs-' + ind, $event)" [innerHtml]="el">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>ПД-С Получение док-тов </td>
          <td *ngFor="let el of statistic['on_month_sale_subagent_docs']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_subagent_docs-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_subagent_docs&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&flag=on_month"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_subagent_docs'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind]
                 + '&flag=on_month',
                 'sale_subagent_docs-' + ind, $event)" [innerHtml]="el">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>ПД-С Оплата </td>
          <td *ngFor="let el of statistic['on_month_sale_subagent_payment']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_subagent_payment-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_subagent_payment&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&flag=on_month"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_subagent_payment'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind]
                 + '&flag=on_month',
                 'sale_subagent_payment-' + ind, $event)" [innerHtml]="el">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Архив</td>
          <td *ngFor="let el of statistic['on_month_sale_archive']; let ind = index">
            <ng-container *ngIf="el">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_archive-' + ind}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_archive&date_start={{date_start}}&date_end={{date_end}}&month={{months[ind]}}&flag=on_month"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_archive'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&month=' + months[ind]
                 + '&flag=on_month',
                 'sale_archive-' + ind, $event)" [innerHtml]="el">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!el">
              0
            </ng-container>
          </td>
        </tr>
      </ng-container>

      </tbody>
    </table>
  </div>
  </ng-container>
  <ng-container *ngIf="!lock_type || lock_type === 'common_realty'">
    <div class="pb-4">
      <table class="table">
        <thead>
        <tr>
          <th *ngFor="let th of head_project">{{th}}</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngIf="lock_type === null || lock_type === 'common_realty'">
          <tr class="head-big">
            <td [attr.colspan]="4">Отдел проектов</td>
          </tr>
          <tr>
            <td><strong>Средняя стоимость</strong></td>
            <td [innerHTML]="statistic['common_realty_est_avg_price'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHTML]="statistic['common_realty_spn24_avg_price'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHTML]="statistic['common_realty_total_avg_price'] | priceRank:'':'':'':'':'':true"></td>
          </tr>

          <tr>
            <td><strong>Среднее вознаграждение</strong></td>
            <td [innerHTML]="statistic['common_realty_est_avg_reward'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHTML]="statistic['common_realty_spn24_avg_reward'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHTML]="statistic['common_realty_total_avg_reward'] | priceRank:'':'':'':'':'':true"></td>
          </tr>
          <tr>
            <td><strong>Средняя площадь</strong></td>
            <td [innerHtml]="statistic['common_realty_est_avg_square'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHtml]="statistic['common_realty_spn24_avg_square'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHtml]="statistic['common_realty_total_avg_square'] | priceRank:'':'':'':'':'':true"></td>
          </tr>
          <tr>
            <td><strong>Всего комплексов</strong></td>
            <td>
              <ng-container *ngIf="statistic['common_realty_est_complex']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'est_complex'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=est_complex&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=est_complex'
                 + '&date_end=' + date_end,
                 'est_complex', $event)" [innerHtml]="statistic['common_realty_est_complex'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_est_complex']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_spn24_complex']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'spn24_complex'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_complex&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_complex'
                 + '&date_end=' + date_end,
                 'spn24_complex', $event)" [innerHtml]="statistic['common_realty_spn24_complex'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_spn24_complex']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_total_complex']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'total_complex'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=total_complex&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=total_complex'
                 + '&date_end=' + date_end,
                 'total_complex', $event)" [innerHtml]="statistic['common_realty_total_complex'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_total_complex']">
                0
              </ng-container>
            </td>
          </tr>
          <tr>
            <td><strong>Комплексов в Москве</strong></td>
            <td>
              <ng-container *ngIf="statistic['common_realty_est_complex_moscow']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'est_complex_moscow'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=est_complex_moscow&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=est_complex_moscow'
                 + '&date_end=' + date_end,
                 'est_complex_moscow', $event)" [innerHtml]="statistic['common_realty_est_complex_moscow'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_est_complex_moscow']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_spn24_complex_moscow']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'spn24_complex_moscow'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_complex_moscow&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_complex_moscow'
                 + '&date_end=' + date_end,
                 'spn24_complex_moscow', $event)" [innerHtml]="statistic['common_realty_spn24_complex_moscow'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_spn24_complex_moscow']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_total_complex_moscow']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'total_complex_moscow'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=total_complex_moscow&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=total_complex_moscow'
                 + '&date_end=' + date_end,
                 'total_complex_moscow', $event)" [innerHtml]="statistic['common_realty_total_complex_moscow'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_total_complex_moscow']">
                0
              </ng-container>
            </td>
          </tr>
          <tr>
            <td><strong>Комплексов не в Москве</strong></td>
            <td>
              <ng-container *ngIf="statistic['common_realty_est_complex_not_moscow']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'est_complex_not_moscow'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=est_complex_not_moscow&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=est_complex_not_moscow'
                 + '&date_end=' + date_end,
                 'est_complex_not_moscow', $event)" [innerHtml]="statistic['common_realty_est_complex_not_moscow'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_est_complex_not_moscow']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_spn24_complex_not_moscow']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'spn24_complex_not_moscow'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_complex_not_moscow&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_complex_not_moscow'
                 + '&date_end=' + date_end,
                 'spn24_complex_not_moscow', $event)" [innerHtml]="statistic['common_realty_spn24_complex_not_moscow'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_spn24_complex_not_moscow']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_total_complex_not_moscow']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'total_complex_not_moscow'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=total_complex_not_moscow&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=total_complex_not_moscow'
                 + '&date_end=' + date_end,
                 'total_complex_not_moscow', $event)" [innerHtml]="statistic['common_realty_total_complex_not_moscow'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_total_complex_not_moscow']">
                0
              </ng-container>
            </td>
          </tr>
          <tr>
            <td><strong>Всего корпусов</strong></td>
            <td>
              <ng-container *ngIf="statistic['common_realty_est_housings']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'est_housings'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=est_housings&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=est_housings'
                 + '&date_end=' + date_end,
                 'est_housings', $event)" [innerHtml]="statistic['common_realty_est_housings'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_est_housings']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_spn24_housings']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'spn24_housings'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_housings&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_housings'
                 + '&date_end=' + date_end,
                 'spn24_housings', $event)" [innerHtml]="statistic['common_realty_spn24_housings'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_spn24_housings']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_total_housings']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'total_housings'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=total_housings&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=total_housings'
                 + '&date_end=' + date_end,
                 'total_housings', $event)" [innerHtml]="statistic['common_realty_total_housings'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_total_housings']">
                0
              </ng-container>
            </td>
          </tr>

          <tr>
            <td><strong>Всего квартир</strong></td>
            <td>
              <ng-container *ngIf="statistic['common_realty_est_flats']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'est_flats'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=est_flats&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=est_flats'
                 + '&date_end=' + date_end,
                 'est_flats', $event)" [innerHtml]="statistic['common_realty_est_flats'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_est_flats']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_spn24_flats']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'spn24_flats'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_flats&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_flats'
                 + '&date_end=' + date_end,
                 'spn24_flats', $event)" [innerHtml]="statistic['common_realty_spn24_flats'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_spn24_flats']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_total_flats']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'total_flats'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=total_flats&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=total_flats'
                 + '&date_end=' + date_end,
                 'total_flats', $event)" [innerHtml]="statistic['common_realty_total_flats'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_total_flats']">
                0
              </ng-container>
            </td>
          </tr>

          <tr>
            <td><strong>Всего 1-комнатных</strong></td>
            <td>
              <ng-container *ngIf="statistic['common_realty_est_rooms_1']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'est_rooms_1'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_1&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_1'
                 + '&date_end=' + date_end,
                 'est_rooms_1', $event)" [innerHtml]="statistic['common_realty_est_rooms_1'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_est_rooms_1']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_spn24_rooms_1']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'spn24_rooms_1'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_1&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_1'
                 + '&date_end=' + date_end,
                 'spn24_rooms_1', $event)" [innerHtml]="statistic['common_realty_spn24_rooms_1'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_spn24_rooms_1']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_total_rooms_1']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'total_rooms_1'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_1&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_1'
                 + '&date_end=' + date_end,
                 'total_rooms_1', $event)" [innerHtml]="statistic['common_realty_total_rooms_1'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_total_rooms_1']">
                0
              </ng-container>
            </td>
          </tr>
          <tr>
            <td><span class="pl-3">Из них апартаментов</span></td>
            <td>
              <ng-container *ngIf="statistic['common_realty_est_rooms_1_apartment']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'est_rooms_1_apartment'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_1_apartment&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_1_apartment'
                 + '&date_end=' + date_end,
                 'est_rooms_1_apartment', $event)" [innerHtml]="statistic['common_realty_est_rooms_1_apartment'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_est_rooms_1_apartment']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_spn24_rooms_1_apartment']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'spn24_rooms_1_apartment'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_1_apartment&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_1_apartment'
                 + '&date_end=' + date_end,
                 'spn24_rooms_1_apartment', $event)" [innerHtml]="statistic['common_realty_spn24_rooms_1_apartment'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_spn24_rooms_1_apartment']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_total_rooms_1_apartment']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'total_rooms_1_apartment'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_1_apartment&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_1_apartment'
                 + '&date_end=' + date_end,
                 'total_rooms_1_apartment', $event)" [innerHtml]="statistic['common_realty_total_rooms_1_apartment'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_total_rooms_1_apartment']">
                0
              </ng-container>
            </td>
          </tr>
          <tr>
            <td><span class="pl-3">Из них студий</span></td>
            <td>
              <ng-container *ngIf="statistic['common_realty_est_rooms_1_studio']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'est_rooms_1_studio'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_1_studio&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_1_studio'
                 + '&date_end=' + date_end,
                 'est_rooms_1_studio', $event)" [innerHtml]="statistic['common_realty_est_rooms_1_studio'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_est_rooms_1_studio']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_spn24_rooms_1_studio']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'spn24_rooms_1_studio'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_1_studio&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_1_studio'
                 + '&date_end=' + date_end,
                 'spn24_rooms_1_studio', $event)" [innerHtml]="statistic['common_realty_spn24_rooms_1_studio'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_spn24_rooms_1_studio']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_total_rooms_1_studio']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'total_rooms_1_studio'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_1_studio&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_1_studio'
                 + '&date_end=' + date_end,
                 'total_rooms_1_studio', $event)" [innerHtml]="statistic['common_realty_total_rooms_1_studio'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_total_rooms_1_studio']">
                0
              </ng-container>
            </td>
          </tr>

          <tr>
            <td><strong>Всего 2-комнатных</strong></td>
            <td>
              <ng-container *ngIf="statistic['common_realty_est_rooms_2']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'est_rooms_2'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_2&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_2'
                 + '&date_end=' + date_end,
                 'est_rooms_2', $event)" [innerHtml]="statistic['common_realty_est_rooms_2'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_est_rooms_2']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_spn24_rooms_2']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'spn24_rooms_2'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_2&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_2'
                 + '&date_end=' + date_end,
                 'spn24_rooms_2', $event)" [innerHtml]="statistic['common_realty_spn24_rooms_2'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_spn24_rooms_2']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_total_rooms_2']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'total_rooms_2'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_2&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_2'
                 + '&date_end=' + date_end,
                 'total_rooms_2', $event)" [innerHtml]="statistic['common_realty_total_rooms_2'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_total_rooms_2']">
                0
              </ng-container>
            </td>
          </tr>
          <tr>
            <td><span class="pl-3">Из них апартаментов</span></td>
            <td>
              <ng-container *ngIf="statistic['common_realty_est_rooms_2_apartment']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'est_rooms_2_apartment'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_2_apartment&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_2_apartment'
                 + '&date_end=' + date_end,
                 'est_rooms_2_apartment', $event)" [innerHtml]="statistic['common_realty_est_rooms_2_apartment'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_est_rooms_2_apartment']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_spn24_rooms_2_apartment']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'spn24_rooms_2_apartment'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_2_apartment&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_2_apartment'
                 + '&date_end=' + date_end,
                 'spn24_rooms_2_apartment', $event)" [innerHtml]="statistic['common_realty_spn24_rooms_2_apartment'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_spn24_rooms_2_apartment']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_total_rooms_2_apartment']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'total_rooms_2_apartment'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_2_apartment&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_2_apartment'
                 + '&date_end=' + date_end,
                 'total_rooms_2_apartment', $event)" [innerHtml]="statistic['common_realty_total_rooms_2_apartment'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_total_rooms_2_apartment']">
                0
              </ng-container>
            </td>
          </tr>
          <tr>
            <td><span class="pl-3">Из них студий</span></td>
            <td>
              <ng-container *ngIf="statistic['common_realty_est_rooms_2_studio']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'est_rooms_2_studio'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_2_studio&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_2_studio'
                 + '&date_end=' + date_end,
                 'est_rooms_2_studio', $event)" [innerHtml]="statistic['common_realty_est_rooms_2_studio'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_est_rooms_2_studio']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_spn24_rooms_2_studio']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'spn24_rooms_2_studio'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_2_studio&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_2_studio'
                 + '&date_end=' + date_end,
                 'spn24_rooms_2_studio', $event)" [innerHtml]="statistic['common_realty_spn24_rooms_2_studio'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_spn24_rooms_2_studio']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_total_rooms_2_studio']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'total_rooms_2_studio'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_2_studio&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_2_studio'
                 + '&date_end=' + date_end,
                 'total_rooms_2_studio', $event)" [innerHtml]="statistic['common_realty_total_rooms_2_studio'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_total_rooms_2_studio']">
                0
              </ng-container>
            </td>
          </tr>


          <tr>
            <td><strong>Всего 3-комнатных</strong></td>
            <td>
              <ng-container *ngIf="statistic['common_realty_est_rooms_3']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'est_rooms_3'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_3&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_3'
                 + '&date_end=' + date_end,
                 'est_rooms_3', $event)" [innerHtml]="statistic['common_realty_est_rooms_3'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_est_rooms_3']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_spn24_rooms_3']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'spn24_rooms_3'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_3&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_3'
                 + '&date_end=' + date_end,
                 'spn24_rooms_3', $event)" [innerHtml]="statistic['common_realty_spn24_rooms_3'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_spn24_rooms_3']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_total_rooms_3']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'total_rooms_3'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_3&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_3'
                 + '&date_end=' + date_end,
                 'total_rooms_3', $event)" [innerHtml]="statistic['common_realty_total_rooms_3'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_total_rooms_3']">
                0
              </ng-container>
            </td>
          </tr>
          <tr>
            <td><span class="pl-3">Из них апартаментов</span></td>
            <td [innerHtml]="statistic['common_realty_est_rooms_3_apartment'] | priceRank:'':'':'':'':'':true">
              <ng-container *ngIf="statistic['common_realty_est_rooms_3_apartment']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'est_rooms_3_apartment'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_3_apartment&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_3_apartment'
                 + '&date_end=' + date_end,
                 'est_rooms_3_apartment', $event)" [innerHtml]="statistic['common_realty_est_rooms_3_apartment'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_est_rooms_3_apartment']">
                0
              </ng-container>
            </td>
            <td [innerHtml]="statistic['common_realty_spn24_rooms_3_apartment'] | priceRank:'':'':'':'':'':true">
              <ng-container *ngIf="statistic['common_realty_spn24_rooms_3_apartment']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'spn24_rooms_3_apartment'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_3_apartment&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_3_apartment'
                 + '&date_end=' + date_end,
                 'spn24_rooms_3_apartment', $event)" [innerHtml]="statistic['common_realty_spn24_rooms_3_apartment'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_spn24_rooms_3_apartment']">
                0
              </ng-container>
            </td>
            <td [innerHtml]="statistic['common_realty_total_rooms_3_apartment'] | priceRank:'':'':'':'':'':true">
              <ng-container *ngIf="statistic['common_realty_total_rooms_3_apartment']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'total_rooms_3_apartment'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_3_apartment&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_3_apartment'
                 + '&date_end=' + date_end,
                 'total_rooms_3_apartment', $event)" [innerHtml]="statistic['common_realty_total_rooms_3_apartment'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_total_rooms_3_apartment']">
                0
              </ng-container>
            </td>
          </tr>
          <tr>
            <td><span class="pl-3">Из них студий</span></td>
            <td [innerHtml]="statistic['common_realty_est_rooms_3_studio'] | priceRank:'':'':'':'':'':true">
              <ng-container *ngIf="statistic['common_realty_est_rooms_3_studio']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'est_rooms_3_studio'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_3_studio&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_3_studio'
                 + '&date_end=' + date_end,
                 'est_rooms_3_studio', $event)" [innerHtml]="statistic['common_realty_est_rooms_3_studio'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_est_rooms_3_studio']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_spn24_rooms_3_studio']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'spn24_rooms_3_studio'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_3_studio&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_3_studio'
                 + '&date_end=' + date_end,
                 'spn24_rooms_3_studio', $event)" [innerHtml]="statistic['common_realty_spn24_rooms_3_studio'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_spn24_rooms_3_studio']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_total_rooms_3_studio']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'total_rooms_3_studio'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_3_studio&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_3_studio'
                 + '&date_end=' + date_end,
                 'total_rooms_3_studio', $event)" [innerHtml]="statistic['common_realty_total_rooms_3_studio'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_total_rooms_3_studio']">
                0
              </ng-container>
            </td>
          </tr>



          <tr>
            <td><strong>Всего 4+-комнатных</strong></td>
            <td>
              <ng-container *ngIf="statistic['common_realty_est_rooms_4+']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'est_rooms_4+'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_4%2B&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_4%2B'
                 + '&date_end=' + date_end,
                 'est_rooms_4+', $event)" [innerHtml]="statistic['common_realty_est_rooms_4+'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_est_rooms_4+']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_spn24_rooms_4+']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'spn24_rooms_4+'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_4%2B&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_4%2B'
                 + '&date_end=' + date_end,
                 'spn24_rooms_4+', $event)" [innerHtml]="statistic['common_realty_spn24_rooms_4+'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_spn24_rooms_4+']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_total_rooms_4+']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'total_rooms_4+'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_4%2B&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_4%2B'
                 + '&date_end=' + date_end,
                 'total_rooms_4+', $event)" [innerHtml]="statistic['common_realty_total_rooms_4+'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_total_rooms_4+']">
                0
              </ng-container>
            </td>
          </tr>
          <tr>
            <td><span class="pl-3">Из них апартаментов</span></td>
            <td>
              <ng-container *ngIf="statistic['common_realty_est_rooms_4+_apartment']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'est_rooms_4+_apartment'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_4%2B_apartment&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_4%2B_apartment'
                 + '&date_end=' + date_end,
                 'est_rooms_4+_apartment', $event)" [innerHtml]="statistic['common_realty_est_rooms_4+_apartment'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_est_rooms_4+_apartment']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_spn24_rooms_4+_apartment']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'spn24_rooms_4+_apartment'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_4%2B_apartment&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_4%2B_apartment'
                 + '&date_end=' + date_end,
                 'spn24_rooms_4+_apartment', $event)" [innerHtml]="statistic['common_realty_spn24_rooms_4+_apartment'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_spn24_rooms_4+_apartment']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_total_rooms_4+_apartment']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'total_rooms_4+_apartment'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_4%2B_apartment&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_4%2B_apartment'
                 + '&date_end=' + date_end,
                 'total_rooms_4+_apartment', $event)" [innerHtml]="statistic['common_realty_total_rooms_4+_apartment'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_total_rooms_4+_apartment']">
                0
              </ng-container>
            </td>
          </tr>
          <tr>
            <td><span class="pl-3">Из них студий</span></td>
            <td>
              <ng-container *ngIf="statistic['common_realty_est_rooms_4+_studio']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'est_rooms_4+_studio'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_4%2B_studio&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=est_rooms_4%2B_studio'
                 + '&date_end=' + date_end,
                 'est_rooms_4+_studio', $event)" [innerHtml]="statistic['common_realty_est_rooms_4+_studio'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_est_rooms_4+_studio']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_spn24_rooms_4+_studio']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'spn24_rooms_4+_studio'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_4%2B_studio&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=spn24_rooms_4%2B_studio'
                 + '&date_end=' + date_end,
                 'spn24_rooms_4+_studio', $event)" [innerHtml]="statistic['common_realty_spn24_rooms_4+_studio'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_spn24_rooms_4+_studio']">
                0
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="statistic['common_realty_total_rooms_4+_studio']">
                <div [ngClass]="{'activeBox': goBack.activeHash === 'total_rooms_4+_studio'}">
                <a href="/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_4%2B_studio&date_end={{date_end}}"
                   (click)="goBack.newLink('/analytic/realty-summary/table?method=get_realty_summary&query_type=total_rooms_4%2B_studio'
                 + '&date_end=' + date_end,
                 'total_rooms_4+_studio', $event)" [innerHtml]="statistic['common_realty_total_rooms_4+_studio'] | priceRank:'':'':'':'':'':true">
                </a>
                </div>
              </ng-container>
              <ng-container *ngIf="!statistic['common_realty_total_rooms_4+_studio']">
                0
              </ng-container>
            </td>
          </tr>

          <tr>
            <td><strong>Максимальная цена</strong></td>
            <td [innerHtml]="statistic['common_realty_est_max_price'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHtml]="statistic['common_realty_spn24_max_price'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHtml]="statistic['common_realty_total_max_price'] | priceRank:'':'':'':'':'':true"></td>
          </tr>

          <tr>
            <td><strong>Максимальное вознаграждение</strong></td>
            <td [innerHtml]="statistic['common_realty_est_max_reward'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHtml]="statistic['common_realty_spn24_max_reward'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHtml]="statistic['common_realty_total_max_reward'] | priceRank:'':'':'':'':'':true"></td>
          </tr>

          <tr>
            <td><strong>Максимальная площадь</strong></td>
            <td [innerHtml]="statistic['common_realty_est_max_square'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHtml]="statistic['common_realty_spn24_max_square'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHtml]="statistic['common_realty_total_max_square'] | priceRank:'':'':'':'':'':true"></td>
          </tr>

          <tr>
            <td><strong>Минимальная цена</strong></td>
            <td [innerHtml]="statistic['common_realty_est_min_price'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHtml]="statistic['common_realty_spn24_min_price'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHtml]="statistic['common_realty_total_min_price'] | priceRank:'':'':'':'':'':true"></td>
          </tr>

          <tr>
            <td><strong>Минимальное вознаграждение</strong></td>
            <td [innerHtml]="statistic['common_realty_est_min_reward'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHtml]="statistic['common_realty_spn24_min_reward'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHtml]="statistic['common_realty_total_min_reward'] | priceRank:'':'':'':'':'':true"></td>
          </tr>

          <tr>
            <td><strong>Минимальная площадь</strong></td>
            <td [innerHtml]="statistic['common_realty_est_min_square'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHtml]="statistic['common_realty_spn24_min_square'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHtml]="statistic['common_realty_total_min_square'] | priceRank:'':'':'':'':'':true"></td>
          </tr>

          <tr>
            <td><strong>Общая площадь помещений</strong></td>
            <td [innerHtml]="statistic['common_realty_est_square'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHtml]="statistic['common_realty_spn24_square'] | priceRank:'':'':'':'':'':true"></td>
            <td [innerHtml]="statistic['common_realty_total_square'] | priceRank:'':'':'':'':'':true"></td>
          </tr>

        </ng-container>
        </tbody>
      </table>
    </div>
  </ng-container>


  <ng-container *ngIf="!lock_type || lock_type === 'on_period'">
    <div class="pb-4">
      <table class="table">
        <thead>
        <tr>
          <th>Значение</th>
          <th>Кол-во</th>
          <th>Процент от общего</th>
        </tr>
        </thead>
        <tbody>
        <tr class="head-big">
          <td>Количество поступивших обращений</td>
          <td colspan="2" [innerHTML]="data['get_lead_statistic'][0]['total'] | priceRank:'':'':'':'':'':true"></td>
        </tr>
        <tr class="head-sm">
          <td [attr.colspan]="3">В том числе</td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['sale_call']">
          <td>Вх./Исх. Звонок	</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('sale_call')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_call'}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_call&date_start={{date_start}}&date_end={{date_end}}&flag=on_period"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_call'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&flag=on_period',
                 'sale_call', $event)" [innerHtml]="data['get_lead_statistic'][0]['sale_call'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('sale_call')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['sale_call'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['sale_unique_check']">
          <td>Проверка Уникальности</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('sale_unique_check')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_unique_check'}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_unique_check&date_start={{date_start}}&date_end={{date_end}}&flag=on_period"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_unique_check'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&flag=on_period',
                 'sale_unique_check', $event)" [innerHtml]="data['get_lead_statistic'][0]['sale_unique_check'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('sale_unique_check')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['sale_unique_check'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['sale_preview']">
          <td>Назначение просмотра</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('sale_preview')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_preview'}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_preview&date_start={{date_start}}&date_end={{date_end}}&flag=on_period"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_preview'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&flag=on_period',
                 'sale_preview', $event)" [innerHtml]="data['get_lead_statistic'][0]['sale_preview'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('sale_preview')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['sale_preview'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['sale_view']">
          <td>Подтверждение просмотра</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('sale_view')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_view'}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_view&date_start={{date_start}}&date_end={{date_end}}&flag=on_period"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_view'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&flag=on_period',
                 'sale_view', $event)" [innerHtml]="data['get_lead_statistic'][0]['sale_view'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('sale_view')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['sale_view'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['sale_view_processing']">
          <td>Проведение просмотра</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('sale_view_processing')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_view_processing'}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_view_processing&date_start={{date_start}}&date_end={{date_end}}&flag=on_period"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_view_processing'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&flag=on_period',
                 'sale_view_processing', $event)" [innerHtml]="data['get_lead_statistic'][0]['sale_view_processing'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('sale_view_processing')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['sale_view_processing'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['sale_pre_reserve']">
          <td>Ожидание резерва</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('sale_view_processing')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_view_processing'}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_view_processing&date_start={{date_start}}&date_end={{date_end}}&flag=on_period"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_view_processing'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&flag=on_period',
                 'sale_view_processing', $event)" [innerHtml]="data['get_lead_statistic'][0]['sale_view_processing'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('sale_view_processing')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['sale_pre_reserve'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['sale_reserve']">
          <td>Резерв</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('sale_reserve')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_reserve'}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_reserve&date_start={{date_start}}&date_end={{date_end}}&flag=on_period"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_reserve'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&flag=on_period',
                 'sale_reserve', $event)" [innerHtml]="data['get_lead_statistic'][0]['sale_reserve'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('sale_reserve')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['sale_reserve'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['sale_db']">
          <td>ДБ, Получение док-тов</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('sale_db')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_db'}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_db&date_start={{date_start}}&date_end={{date_end}}&flag=on_period"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_db'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&flag=on_period',
                 'sale_db', $event)" [innerHtml]="data['get_lead_statistic'][0]['sale_db'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('sale_db')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['sale_db'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['sale_ddu']">
          <td>ДДУ Подписание </td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('sale_ddu')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_ddu'}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_ddu&date_start={{date_start}}&date_end={{date_end}}&flag=on_period"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_ddu'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&flag=on_period',
                 'sale_ddu', $event)" [innerHtml]="data['get_lead_statistic'][0]['sale_ddu'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('sale_ddu')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['sale_ddu'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['sale_ddu_payment']">
          <td>ДДУ Оплата </td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('sale_ddu_payment')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_ddu_payment'}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_ddu_payment&date_start={{date_start}}&date_end={{date_end}}&flag=on_period"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_ddu_payment'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&flag=on_period',
                 'sale_ddu_payment', $event)" [innerHtml]="data['get_lead_statistic'][0]['sale_ddu_payment'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('sale_ddu_payment')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['sale_ddu_payment'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['sale_developer_payment']">
          <td>ПД-З Оплата </td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('sale_developer_payment')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_developer_payment'}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_developer_payment&date_start={{date_start}}&date_end={{date_end}}&flag=on_period"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_developer_payment'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&flag=on_period',
                 'sale_developer_payment', $event)" [innerHtml]="data['get_lead_statistic'][0]['sale_developer_payment'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('sale_developer_payment')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['sale_developer_payment'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['sale_developer_docs']">
          <td>ПД-З Получена </td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('sale_developer_docs')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_developer_docs'}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_developer_docs&date_start={{date_start}}&date_end={{date_end}}&flag=on_period"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_developer_docs'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&flag=on_period',
                 'sale_developer_docs', $event)" [innerHtml]="data['get_lead_statistic'][0]['sale_developer_docs'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('sale_developer_docs')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['sale_developer_docs'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['sale_subagent_docs']">
          <td>ПД-С Получение док-тов </td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('sale_subagent_docs')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_subagent_docs'}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_subagent_docs&date_start={{date_start}}&date_end={{date_end}}&flag=on_period"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_subagent_docs'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&flag=on_period',
                 'sale_subagent_docs', $event)" [innerHtml]="data['get_lead_statistic'][0]['sale_subagent_docs'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('sale_subagent_docs')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['sale_subagent_docs'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['sale_subagent_payment']">
          <td>ПД-С Оплата </td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('sale_subagent_payment')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_subagent_payment'}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_subagent_payment&date_start={{date_start}}&date_end={{date_end}}&flag=on_period"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_subagent_payment'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&flag=on_period',
                 'sale_subagent_payment', $event)" [innerHtml]="data['get_lead_statistic'][0]['sale_subagent_payment'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('sale_subagent_payment')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['sale_subagent_payment'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['sale_archive']">
          <td>Архив</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('sale_archive')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_archive'}">
              <a href="/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_archive&date_start={{date_start}}&date_end={{date_end}}&flag=on_period"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_unique_leads_on_period&stage=sale_archive'
                 + '&date_start=' + date_start
                 + '&date_end=' + date_end
                 + '&flag=on_period',
                 'sale_archive', $event)" [innerHtml]="data['get_lead_statistic'][0]['sale_archive'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('sale_archive')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['sale_archive'].percent + '%'"></td>
        </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-container *ngIf="!lock_type || lock_type === 'on_moment'">
    <div class="pb-4">
      <table class="table">
        <thead>
        <tr>
          <th>Значение</th>
          <th>Кол-во</th>
          <th>Процент от общего</th>
        </tr>
        </thead>
        <tbody>
        <tr class="head-big">
          <td><strong>Количество поступивших обращений</strong></td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('total_leads')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'total_leads'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=total_leads"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=total_leads',
                 'total_leads', $event)" [innerHtml]="data['get_lead_statistic'][0]['total_leads'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('total_leads')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['total_leads'].percent + '%'"></td>
        </tr>
        <tr class="head-sm">
          <td>Пресейл в работе</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('pre_sale_leads')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'pre_sale_leads'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=pre_sale_leads"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=pre_sale_leads',
                 'pre_sale_leads', $event)" [innerHtml]="data['get_lead_statistic'][0]['pre_sale_leads'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('pre_sale_leads')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['pre_sale_leads'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['stage_call']">
          <td>Вх./Исх. Звонок	</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('stage_call')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'stage_call'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_call"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_call',
                 'stage_call', $event)" [innerHtml]="data['get_lead_statistic'][0]['stage_call'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('stage_call')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['stage_call'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['stage_unique']">
          <td>Уникальность</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('stage_unique')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'stage_unique'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_unique_check"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_unique_check',
                 'stage_unique', $event)" [innerHtml]="data['get_lead_statistic'][0]['stage_unique'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('stage_unique')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['stage_unique'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['stage_preview']">
          <td>Назначение просмотра</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('stage_preview')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'stage_preview'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_preview"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_preview',
                 'stage_preview', $event)" [innerHtml]="data['get_lead_statistic'][0]['stage_preview'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('stage_preview')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['stage_preview'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['stage_view']">
          <td>Подтверждение просмотра</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('stage_view')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'stage_view'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_view"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_view',
                 'stage_view', $event)" [innerHtml]="data['get_lead_statistic'][0]['stage_view'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('stage_view')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['stage_view'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['stage_view_processing']">
          <td>Проведение просмотра</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('stage_view_processing')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'stage_view_processing'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_view_processing"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_view_processing',
                 'stage_view_processing', $event)" [innerHtml]="data['get_lead_statistic'][0]['stage_view_processing'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('stage_view_processing')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['stage_view_processing'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['stage_pre_reserve']">
          <td>Ожидание резерва</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('stage_pre_reserve')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'stage_pre_reserve'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_pre_reserve"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_pre_reserve',
                 'stage_pre_reserve', $event)" [innerHtml]="data['get_lead_statistic'][0]['stage_pre_reserve'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('stage_pre_reserve')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['stage_pre_reserve'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['stage_reserve']">
          <td>Резерв</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('stage_reserve')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'stage_reserve'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_reserve"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_reserve',
                 'stage_reserve', $event)" [innerHtml]="data['get_lead_statistic'][0]['stage_reserve'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('stage_reserve')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['stage_reserve'].percent + '%'"></td>
        </tr>
        <tr>
          <td><strong>Дисквалификация</strong></td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('pre_sale_leads_fail')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'pre_sale_leads_fail'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=pre_sale_leads_fail"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=pre_sale_leads_fail',
                 'pre_sale_leads_fail', $event)" [innerHtml]="data['get_lead_statistic'][0]['pre_sale_leads_fail'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('pre_sale_leads_fail')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['pre_sale_leads_fail'].percent + '%'"></td>
        </tr>
        <tr class="head-big">
          <td><strong>Квалификация</strong></td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('sale_qualification')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_qualification'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_qualification"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_qualification',
                 'sale_qualification', $event)" [innerHtml]="data['get_lead_statistic'][0]['sale_qualification'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('sale_qualification')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['sale_qualification'].percent + '%'"></td>
        </tr>
        <tr class="head-sm">
          <td>Сделок в работе</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('sale_leads')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_leads'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_leads"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_leads',
                 'sale_leads', $event)" [innerHtml]="data['get_lead_statistic'][0]['sale_leads'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('sale_leads')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['sale_leads'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['stage_db']">
          <td>ДБ, Получение док-тов</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('stage_db')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'stage_db'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_db"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_db',
                 'stage_db', $event)" [innerHtml]="data['get_lead_statistic'][0]['stage_db'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('stage_db')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['stage_db'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['stage_ddu']">
          <td>ДДУ Подписание </td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('stage_ddu')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'stage_ddu'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_ddu"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_ddu',
                 'stage_ddu', $event)" [innerHtml]="data['get_lead_statistic'][0]['stage_ddu'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('stage_ddu')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['stage_ddu'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['stage_ddu_payment']">
          <td>ДДУ Оплата </td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('stage_ddu_payment')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'stage_ddu_payment'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_ddu_payment"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_ddu_payment',
                 'stage_ddu_payment', $event)" [innerHtml]="data['get_lead_statistic'][0]['stage_ddu_payment'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('stage_ddu_payment')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['stage_ddu_payment'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['stage_developer_payment']">
          <td>ПД-З Оплата </td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('stage_developer_payment')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'stage_developer_payment'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_developer_payment"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_developer_payment',
                 'stage_developer_payment', $event)" [innerHtml]="data['get_lead_statistic'][0]['stage_developer_payment'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('stage_developer_payment')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['stage_developer_payment'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['stage_developer_docs']">
          <td>ПД-З Получена </td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('stage_developer_docs')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'stage_developer_docs'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_developer_docs"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_developer_docs',
                 'stage_developer_docs', $event)" [innerHtml]="data['get_lead_statistic'][0]['stage_developer_docs'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('stage_developer_docs')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['stage_developer_docs'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['stage_subagent_docs']">
          <td>ПД-С Получение док-тов </td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('stage_subagent_docs')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'stage_subagent_docs'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_subagent_docs"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_subagent_docs',
                 'stage_subagent_docs', $event)" [innerHtml]="data['get_lead_statistic'][0]['stage_subagent_docs'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('stage_subagent_docs')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['stage_subagent_docs'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['stage_subagent_payment']">
          <td>ПД-С Оплата </td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('stage_subagent_payment')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'stage_subagent_payment'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_subagent_payment"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_subagent_payment',
                 'stage_subagent_payment', $event)" [innerHtml]="data['get_lead_statistic'][0]['stage_subagent_payment'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('stage_subagent_payment')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['stage_subagent_payment'].percent + '%'"></td>
        </tr>
        <tr *ngIf="data['get_lead_statistic'][0]['stage_archive']">
          <td>Архив</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('stage_archive')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'stage_archive'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_archive"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_archive',
                 'stage_archive', $event)" [innerHtml]="data['get_lead_statistic'][0]['stage_archive'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('stage_archive')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['stage_archive'].percent + '%'"></td>
        </tr>
        <tr>
          <td><strong>Дисквалификация</strong></td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('sale_leads_fail')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'sale_leads_fail'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_leads_fail"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=sale_leads_fail',
                 'sale_leads_fail', $event)" [innerHtml]="data['get_lead_statistic'][0]['sale_leads_fail'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('sale_leads_fail')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['sale_leads_fail'].percent + '%'"></td>
        </tr>
        <tr class="head-sm">
          <td>Архив (СДЕЛКИ ЗАКРЫТЫ)</td>
          <td>
            <ng-container *ngIf="data['get_lead_statistic'][0].hasOwnProperty('total_complete')">
              <div [ngClass]="{'activeBox': goBack.activeHash === 'total_complete'}">
              <a href="/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=total_complete"
                 (click)="goBack.newLink('/analytic/inner-lead/table?method=get_stage_statistic_lead_on_moment&stage=total_complete',
                 'total_complete', $event)" [innerHtml]="data['get_lead_statistic'][0]['total_complete'].value | priceRank:'':'':'':'':'':true">
              </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!data['get_lead_statistic'][0].hasOwnProperty('total_complete')">
              0
            </ng-container>
          </td>
          <td [innerHTML]="data['get_lead_statistic'][0]['total_complete'].percent + '%'"></td>
        </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

</div>

<ng-container *ngIf="lineChartData.length > 0 && lineChartLabels.length > 0">
  <div class="d-none d-none-sm-fix d-md-block">
    <div class="row pb-5">
      <div class="mb-5 col-12 chart-holder" *ngFor="let data of lineChartData" [ngClass]="data.class ? data.class : ''">
        <h4 class="mb-3">{{data.caption}}</h4>
        <canvas baseChart height="350px" width="100%"
                [datasets]="data.value"
                [labels]="data.labels"
                [options]="lineChartOptions"
                [colors]="data.colors ? data.colors : null"
                [legend]="lineChartLegend"
                [chartType]="data.type"></canvas>
      </div>
    </div>
  </div>
</ng-container>



<!--<pre>{{statistic|json}}</pre>-->
<!--<hr>-->
<!--<pre>{{data|json}}</pre>-->

<!--'get_attraction_summary': 'ОТДЕЛ ПРИВЛЕЧЕНИЯ',-->
<!--'get_calendar_summary': 'ОБУЧЕНИЕ СУБАГЕНТОВ',-->
<!--'get_realty_summary': 'ОТДЕЛ ПРОЕКТОВ',-->
