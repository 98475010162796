import {AfterContentInit, Component, ContentChildren, EventEmitter, HostListener, Input, Output, QueryList} from '@angular/core';
import {MaterialWysiwygComponent} from '../../@material-input/component/material-wysiwyg.component';
import {FancyboxService} from '../../../shared/fancybox.service';

declare const tinymce: any;

class Image {
  constructor(public src, public caption, public thumb) { }
}

@Component({
  selector: 'app-tab-element',
  templateUrl: './tabs-element.component.html',
  styleUrls: ['./tabs-element.component.scss']
})
export class TabsElementComponent implements AfterContentInit {

  private _is_active: boolean;

  public ready: Promise<void>;
  private readyResolve: () => void;

  @Input() public caption;
  @Output() public activate = new EventEmitter<void>();

  @Input('is_active')
  public set is_active(value: boolean) {
    this._is_active = value;

    if (value) {
      this.activate.emit();
    }

    if (value && this.triggered_child && this.triggered_child.length) {
      this.triggered_child.forEach(x => x.onTabActivate());
    }

    if (value && this.editors && this.editors.length) {
      tinymce.remove();
      setTimeout(() => this.editors.first.reloadEditor(), 50);
    }
  }

  public get is_active(): boolean {
    return this._is_active;
  }

  @ContentChildren('triggered') private triggered_child: QueryList<any>;
  @ContentChildren(MaterialWysiwygComponent, {descendants: true}) private editors: QueryList<MaterialWysiwygComponent>;

  constructor(public lightbox: FancyboxService) {
    this.ready = new Promise<void>((resolve: () => void) => this.readyResolve = resolve);
  }

  @HostListener('click', ['$event.target'])
  private onClick(target) {
    if (
      target.tagName.toLocaleLowerCase() === 'img' && target.src && target.src.length
      && !target.classList.contains('no-auto-zoom-in') && !target.classList.contains('leaflet-marker-icon')
    ) {
      this.lightboxOpen(target.src);
    }
  }

  ngAfterContentInit() {
    this.readyResolve();
  }

  lightboxOpen(src: string) {
    this.lightbox.open([new Image(src, '', src)], 0);
  }

}
