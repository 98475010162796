<div class="row no-gutters gutters-4">
  <div class="col-sm-12">

    <div class="allDrop" (click)="activeDropdown = !activeDropdown">Схлопнуть / расхлопнуть всё</div>
    <ng-container *ngIf="data['sale_subagent_caption']">
      <p class="small"><span class="bold">{{data['sale_subagent_caption']}}</span></p>
    </ng-container>
    <ng-container *ngIf="data['sale_client_fio']">
      <p class="small"><span class="bold"> Клиент: </span><span class="mr-2r">{{data['sale_client_fio']}}</span>
        <ng-container *ngIf="data['stage_text_prolongation'] && data['stage_is_active']">
          <span class="small"><span class="bold"> Статус этапа: </span>{{data['stage_text_prolongation']}}</span>
        </ng-container>
      </p>
    </ng-container>
    <ng-container *ngIf="data['sale_lead_transfer']">
      <h3 class="highlight">Выбрана передача клиента (расширенное бронирование)</h3>
    </ng-container>

  </div>
  <div class="col-sm-12">
    <app-reserv-lead-page-substep-edit (closeEdit)="closeEdit($event)" [data]="data"></app-reserv-lead-page-substep-edit>
  </div>


  <div class="col-sm-12">
    <form [formGroup]="form" *ngIf="form" novalidate (submit)="onSubmit()">

      <div class="row no-gutters gutters-4 pb-5r">
        <!--Общая информация:-->
        <div class="col-sm-12 mt-1 mb-0">
          <app-easy-dropdown [cssClass]="'w100 max-height-none'" [activeDropdown]="activeDropdown"
                             [minusPadding]="true"
                             [titleText]="'Общая информация:'">
            <div class="mb-2r">


              <ng-container *ngIf="data.check_access_lead_for_act">
                <ng-container *ngIf="data.button_visible">
                  <a [href]="urlLead + user.session_id + buttonLink"
                     class="button mb-3 float-right" target="_blank">{{data.button_caption}}</a>
                </ng-container>
                <ng-container *ngIf="!data.button_visible && data.button_caption">
                  <div class="alert alert-warning d-flex w-100 align-items-center">
                    <div class="d-flex align-items-center">
                      {{data.button_caption}}
                    </div>
                    <a class="button float-right disabled text-white ml-auto">скачать</a>
                  </div>
                </ng-container>
              </ng-container>

              <p>
                <ng-container *ngIf="data['author_fio']">
                  <ng-template #header>
                    <span>Автор - </span>{{data['author_fio']}}
                  </ng-template>
                  <span class="d-inline-block mr-3">
                    <span class="bold">Автор: </span>
                    <a [routerLink]="['/physical/edit/', data['author_id']]">
                      {{data['author_fio']}}
                    </a>
                  </span>
                </ng-container>
                <ng-container *ngIf="data['create_time']">
                  <span class="d-inline-block"><span class="bold">Дата создания:</span> {{data['create_time']}}</span>
                </ng-container>
                <ng-container *ngIf="data['unique_until']">
                  <p class="mt-2"><span class="bold">Уникален до:</span> {{data['unique_until']}}</p>
                </ng-container>
              </p>
              <p>
                <ng-container *ngIf="data['author_phone']">
                  <span class="bold">Телефон:</span> {{data['author_phone']}}
                </ng-container>
              </p>

              <ng-container *ngIf="data['worker_id'] && data['worker_fio']">
                <p>
                  <span class="bold">Ответственный от spn24: </span>
                  <a [routerLink]="['/physical/edit/', data['worker_id']]">
                    {{data['worker_fio']}}
                  </a>
                </p>
              </ng-container>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <app-material-select2-autocomplete formControlName="worker_id"
                                                   [placeholder]="'Ответственный от spn24'"
                                                   [caption]="'Ответственный от spn24'" [api_action]="'Dict.bp_ci_worker_ac'">
                  <option [value]="form.value.worker_id">{{form.value.worker_fio}}</option>
                </app-material-select2-autocomplete>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <app-material-select2-autocomplete formControlName="sale_sales_manager_id"
                                                   [placeholder]="'Менеджер отдела бронирования (подбора)'"
                                                   [caption]="'Менеджер отдела бронирования (подбора)'" [api_action]="'Developer.managers_ac'">
                  <option [value]="form.value.sale_sales_manager_id">{{form.value.sale_sales_manager_fio}}</option>
                </app-material-select2-autocomplete>
              </div>
              <div class="col-sm-6">
                <app-material-input formControlName="sale_eat_id"
                                    [placeholder]="'Идентификатор EST-A-TET заявки'">
                  Идентификатор EST-A-TET</app-material-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <app-material-select2 formControlName="source_id" [require]="true" [caption]="'Источник лида'">
                  <option *ngFor="let option of leadSources" [value]="option.id">
                    {{option.caption}}
                  </option>
                </app-material-select2>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <app-material-select2 formControlName="sale_lead_transfer" [caption]="'Расширенное бронирование'">
                  <option [value]="false">Самостоятельная работа</option>
                  <option [value]="true">Расширенное бронирование (передача лида)</option>
                </app-material-select2>
              </div>
            </div>
            <ng-container *ngIf="data['stage_description']">
              <p class="mt-2"><span class="bold">Описание Этапа:</span> {{data['stage_description']}}</p>
            </ng-container>

          </app-easy-dropdown>
        </div>

        <ng-container *ngIf="data['sale_deal_data']">
        <!--Сделка:-->
        <div class="col-sm-12 mb-0">
          <app-easy-dropdown [cssClass]="'w100 max-height-none'" [activeDropdown]="activeDropdown"
                             [minusPadding]="true"
                             [titleText]="'Сделка:'">
            <div class="row">
              <div class="col-6">
                <app-material-input formControlName="flat_deal_num"
                                    [placeholder]="'Квартира'">
                  Квартира</app-material-input>
              </div>
              <div class="col-6">
                <app-material-input formControlName="square"
                                    [placeholder]="'Площадь'">
                  Площадь</app-material-input>
              </div>
              <div class="col-6">
                <app-material-input formControlName="price"
                                    [placeholder]="'Стоимость общая'">
                  Стоимость общая</app-material-input>
              </div>
              <div class="col-6">
                <app-material-input formControlName="commission_proc"
                                    [placeholder]="'% вознаграждения'">
                  % вознаграждения</app-material-input>
              </div>
              <div class="col-6">
                <app-material-input formControlName="commission"
                                    [placeholder]="'Вознаграждение'">
                  Вознаграждение</app-material-input>
              </div>
              <div class="col-6">
                <app-material-input formControlName="commission_subagent"
                                    [placeholder]="'Субагентские расходы'">
                  Субагентские расходы</app-material-input>
              </div>
              <div class="col-6">
                <app-material-input formControlName="commission_spn"
                                    [placeholder]="'КВ СПН'">
                  КВ СПН</app-material-input>
              </div>
              <div class="col-6">
                <app-material-input formControlName="annotation"
                                    [placeholder]="'Примечание'">
                  Примечание</app-material-input>
              </div>

              <div class="col-6">
                <app-material-input formControlName="db_num"
                                    [placeholder]="'№ ДБ'">
                  № ДБ</app-material-input>
              </div>
              <div class="col-6">
                <app-material-input formControlName="db_date"
                                    [placeholder]="'Дата ДБ'">
                  Дата ДБ</app-material-input>
              </div>
              <div class="col-6">
                <app-material-input formControlName="ddu_num"
                                    [placeholder]="'№ договора продажи'">
                  № договора продажи</app-material-input>
              </div>
              <div class="col-6">
                <app-material-input formControlName="ddu_date"
                                    [placeholder]="'Дата договора продажи'">
                  Дата договора продажи</app-material-input>
              </div>
              <div class="col-6">
                <app-material-input formControlName="commission_developer"
                                    [placeholder]="'% вознаграждения от застройщика'">
                  % вознаграждения от застройщика</app-material-input>
              </div>
              <div class="col-6">
                <app-material-input formControlName="ddu_payment_date"
                                    [placeholder]="'Дата оплаты договора продажи'">
                  Дата оплаты договора продажи</app-material-input>
              </div>
            </div>
          </app-easy-dropdown>
        </div>
        </ng-container>

        <!--Объект:-->
        <ng-container *ngIf="data['sale_realty_id']">
          <div class="col-sm-12 mb-0">
            <app-easy-dropdown [cssClass]="'w100 max-height-none'" [activeDropdown]="activeDropdown" [minusPadding]="true" [titleText]="'Объект:'">
              <span class="bold">Объект: </span>
              <ng-container *ngIf="data['sale_realty_type'] === 'flat'">
                <ng-template #header>
                  <span>Квартира - </span> {{data['sale_realty_caption']}}
                </ng-template>
                <span>Квартира - </span>
                <a [routerLink]="['/housing2/flat/', data['sale_realty_id']]">
                  {{data['sale_realty_caption']}}
                </a>
                <ng-container *ngIf="data['sale_realty_price']">
                  <span> - <span class="bold">Цена: </span><span [innerHtml]="data['sale_realty_price'] | priceRank"></span></span>
                </ng-container>
                <ng-container *ngIf="data['sale_realty_price_promo']">
                  <p class="mt-2"><span class="bold">Цена по акции: </span><span [innerHtml]="data['sale_realty_price_promo'] | priceRank"></span></p>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="data['sale_realty_type'] === 'housing'">
                <ng-template #header>
                  <span>Корпус/комплекс - </span> {{data['sale_realty_caption']}}
                </ng-template>
                <span>Корпус/комплекс - </span>
                <a [routerLink]="['/housing/house/', data['sale_realty_housing_id']]">
                  {{data['sale_realty_caption']}}
                </a>
              </ng-container>
              <div>
                <ng-container *ngIf="data['sale_realty_status_caption']">
                  <p class="mt-2"><span class="bold">Статус</span> - {{data['sale_realty_status_caption']}}</p>
                </ng-container>
                <ng-container *ngIf="data['sale_realty_commission']">
                  <p class="mt-2"><span class="bold">Аг.В (агентское вознаграждение)</span> - <span [innerHtml]="data['sale_realty_commission'] | priceRank"></span> - {{data['sale_realty_commission_percent'].toFixed(2)}}%</p>
                </ng-container>
                <ng-container *ngIf="data['sale_realty_subagent_commission']">
                  <p class="mt-2"><span class="bold">Суб. (субагентское вознаграждение)</span> -
                    <ng-container *ngIf="data['sale_realty_subagent_commission_old']">
                      <span class="line-through mr-2"><span [innerHtml]="data['sale_realty_subagent_commission_old'] | priceRank"></span> - {{data['sale_realty_subagent_commission_percent_old'].toFixed(2)}}%</span>
                    </ng-container>
                    <span class="bold"><span [innerHtml]="data['sale_realty_subagent_commission'] | priceRank"></span> - {{data['sale_realty_subagent_commission_percent'].toFixed(2)}}%</span>
                  </p>
                </ng-container>
              </div>
              <div class="mt-2r">
                <app-material-select2-autocomplete formControlName="sale_realty_id"
                                                   [placeholder]="'Квартира'"
                                                   [caption]="'Квартира'"
                                                   [api_options]="{'housing_id': data['sale_realty_housing_id']}"
                                                   [api_action]="'Dict.bp_sale_realty_ac'">
                  <option [value]="form.value.sale_realty_flat_id">{{form.value.sale_realty_flat_caption}}</option>
                </app-material-select2-autocomplete>
              </div>
            </app-easy-dropdown>
          </div>
        </ng-container>

        <!--Субагент:-->
        <div class="col-sm-12 mb-0">
          <app-easy-dropdown [cssClass]="'w100 max-height-none'" [activeDropdown]="activeDropdown"
                             [minusPadding]="true"
                             [titleText]="'Субагент:'">
            <ng-container *ngIf="data['sale_subagent_user_id']">
              <ng-template #header>
                {{data['sale_subagent_user_fio']}}
              </ng-template>
              <p>
                <span class="bold">ФИО: </span>
                <a [routerLink]="['/physical/edit/', data['sale_subagent_user_id']]">
                  {{data['sale_subagent_user_fio']}}
                </a>
              </p>
            </ng-container>
            <ng-container *ngIf="data['sale_subagent_caption']">
              <p>
                <span class="bold">Организация: </span>
                {{data['sale_subagent_caption']}}
              </p>
            </ng-container>
            <ng-container *ngIf="data['sale_subagent_user_phone']">
              <p>
                <span class="bold"> Телефон: </span>
                {{data['sale_subagent_user_phone']}}
              </p>
            </ng-container>
            <ng-container *ngIf="data['sale_subagent_user_email']">
              <p>
                <span class="bold">Email: </span>
                {{data['sale_subagent_user_email']}}
              </p>
            </ng-container>
            <div class="mt-2r">
              <app-material-select2-autocomplete formControlName="sale_subagent_user_id"
                                                 [placeholder]="'Введите субагента'"
                                                 [caption]="'Введите субагента'" [api_action]="'Dict.bp_sale_subagent_user_ac'">
                <option [value]="form.value.sale_subagent_user_id">{{data.sale_subagent_user_fio}} {{data.sale_subagent_caption}}</option>
              </app-material-select2-autocomplete>
            </div>
          </app-easy-dropdown>
        </div>

        <!--Клиент:-->
        <div class="col-sm-12 mb-0">
          <app-easy-dropdown [cssClass]="'w100 max-height-none'" [activeDropdown]="activeDropdown" [minusPadding]="true" [titleText]="'Клиент:'">
            <ng-container *ngIf="data['sale_client_fio']">
              <ng-template #header>
                {{data.sale_client_fio}}
              </ng-template>
              <div class="row mb-2r">
                <div class="col-sm-6">
                  <p><span class="bold">ФИО: </span>
                    <a [routerLink]="['/physical/edit/', data['sale_client_id']]">
                      {{data['sale_client_fio']}}
                    </a>
                  </p>
                  <ng-container *ngIf="data['sale_appeal_id']">
                    <p>
                      <span class="bold">Обращение: </span>
                      Обращение клиента {{data['sale_appeal_fio']}}
                    </p>
                  </ng-container>
                  <p><span class="bold">Email: </span>{{data['sale_client_email']}}</p>
                  <p><span class="bold">Телефон: </span>{{data['sale_client_phone']}}</p>
                  <p><span class="bold">Пол: </span>{{data['sale_client_sex']}}</p>
                  <p><span class="bold">ИНН: </span>{{data['sale_client_inn']}}</p>
                  <p><span class="bold">Место жительства факт.: </span>{{data['sale_client_mj_fakt']}}</p>
                  <p><span class="bold">Зарегистрирован: </span>{{data['sale_client_mj_reg']}}</p>
                </div>
                <div class="col-sm-6">
                  <p><span class="bold">Дата рождения: </span>{{data['sale_client_birth_date']}}</p>
                  <p><span class="bold">Национальность: </span>{{data['sale_client_nationality']}}</p>
                  <p><span class="bold">Дата выдачи паспорта: </span>{{data['sale_client_pasp_date']}}</p>
                  <p><span class="bold">Паспорт выдан: </span>{{data['sale_client_pasp_vidan']}}</p>
                  <p><span class="bold">Код подразделения: </span>{{data['sale_client_pasp_kod_podrazdel']}}</p>
                  <p><span class="bold">Серия паспорта: </span>{{data['sale_client_pasp_seriya']}}</p>
                  <p><span class="bold">Номер паспорта: </span>{{data['sale_client_pasp_num']}}</p>
                </div>
              </div>
            </ng-container>

            <div class="row">
              <div class="col-4">
                <app-material-input formControlName="lastName"
                                    [placeholder]="'Фамилия'">
                  Фамилия</app-material-input>
              </div>
              <div class="col-4">
                <app-material-input formControlName="firstName"
                                    [placeholder]="'Имя'"
                                    [require]="true">
                  Имя</app-material-input>
              </div>
              <div class="col-4">
                <app-material-input formControlName="middleName"
                                    [placeholder]="'Отчество'">
                  Отчество</app-material-input>
              </div>
              <div class="col-6">
                <app-material-input-mask formControlName="phone" [mask]="'+7 (000) 000-00-00'" [require]="true"
                                         [placeholder]="'+7 (___) ___-__-__'">
                  Телефон</app-material-input-mask>
              </div>
              <div class="col-6">
                <app-material-input formControlName="email"
                                    [placeholder]="'Email'">
                  Email</app-material-input>
              </div>
            </div>

          </app-easy-dropdown>
        </div>

        <!--Менеджеры застройщика:-->

          <div class="col-sm-12">
            <app-easy-dropdown [cssClass]="'w100 max-height-none'" [activeDropdown]="activeDropdown" [minusPadding]="true" [titleText]="'Менеджеры застройщика:'">
              <ng-template #header>
                {{data.sale_realty_developer_caption}}
              </ng-template>
              <div class="row">

                <div class="col-6">
                  <h6 class="mb-2">Менеджер</h6>
                  <ng-container *ngIf="data['sale_realty_developer_managers'].length">
                    <div class="table-responsive min-height-auto">
                      <table class="table table-striped datatable table-rwd small_table min-height-auto">
                        <tr>
                          <th class="position-sticky-fix">Менеджер</th>
                          <th class="position-sticky-fix">Email</th>
                          <th class="position-sticky-fix">Телефон</th>
                        </tr>
                        <tr [ngClass]="{'active': data['sale_developer_manager_id'] === manager.id}" *ngFor="let manager of data['sale_realty_developer_managers']">
                          <td>
                            <a [routerLink]="['/managers/edit/', manager['id']]">
                              {{manager['fio']}}
                            </a>
                            <ng-container *ngIf="data['sale_developer_manager_id'] === manager.id">
                              (выбранный менеджер)
                            </ng-container>
                          </td>
                          <td>
                            {{manager['email']}}
                          </td>
                          <td>
                            {{manager['phone']}}
                          </td>
                        </tr>
                      </table>
                    </div>

                  </ng-container>

                  <ng-container *ngIf="manager_show">

                    <app-material-select2-autocomplete #manager formControlName="sale_developer_manager_id"
                                                       [placeholder]="'Менеджер застройщика'"
                                                       [caption]="'Выберите Менеджера застройщика'" [api_action]="'Developer.managers_ac'">
                      <option [value]="form.value.sale_developer_manager_id">{{form.value.sale_developer_manager_fio}}</option>
                    </app-material-select2-autocomplete>
                  </ng-container>
                  <a (click)="addNewManager('manager')" href="javascript: void(0)" [ngClass]="{'disabled': rightWindow}" class="button mt-1 mb-2r">Добавить нового менеджера</a>
                </div>

                <div class="col-6">
                  <h6 class="mb-2">Руководитель менеджера</h6>
                  <ng-container *ngIf="data['sale_realty_developer_manager_bosses'].length">
                    <div class="table-responsive min-height-auto">
                      <table class="table table-striped datatable table-rwd small_table min-height-auto">
                        <tr>
                          <th class="position-sticky-fix">Менеджер</th>
                          <th class="position-sticky-fix">Email</th>
                          <th class="position-sticky-fix">Телефон</th>
                        </tr>
                        <tr [ngClass]="{'active': data['sale_developer_manager_boss_id'] === manager.id}" *ngFor="let manager of data['sale_realty_developer_manager_bosses']">
                          <td>
                            <a [routerLink]="['/managers/edit/', manager['id']]">
                              {{manager['fio']}}
                            </a>
                            <ng-container *ngIf="data['sale_developer_manager_boss_id'] === manager.id">
                              (выбранный менеджер)
                            </ng-container>
                          </td>
                          <td>
                            {{manager['email']}}
                          </td>
                          <td>
                            {{manager['phone']}}
                          </td>
                        </tr>
                      </table>
                    </div>

                  </ng-container>

                  <ng-container *ngIf="boss_show">
                    <app-material-select2-autocomplete formControlName="sale_developer_manager_boss_id"
                                                       [placeholder]="'Руководитель менеджера застройщика'"
                                                       [caption]="'Выберите руководителя менеджера застройщика'" [api_action]="'Developer.managers_ac'">
                      <option [value]="form.value.sale_developer_manager_boss_id">{{form.value.sale_developer_manager_boss_fio}}</option>
                    </app-material-select2-autocomplete>
                  </ng-container>
                  <a (click)="addNewManager('boss')" href="javascript: void(0)" [ngClass]="{'disabled': rightWindow}" class="button mt-1 mb-2r">Добавить нового руководителя менеджера</a>

                </div>

              </div>
            </app-easy-dropdown>
          </div>

        <!--Операции:-->
        <ng-container *ngIf="data['sale_appeal_operations'].length">
          <div class="col-sm-12 mb-0">
          <app-easy-dropdown [cssClass]="'w100 max-height-none'" [activeDropdown]="activeDropdown" [minusPadding]="true" [titleText]="'Операции:'">

            <div class="mb-2">
              <div class="nomax perfect-scrollbar">
                <div class="table-responsive min-height-auto">
                  <table class="table table-striped datatable table-rwd small_table min-height-auto">
                    <tr>
                      <th class="position-sticky-fix">Дата создания</th>
                      <th class="position-sticky-fix">Операция</th>
                      <th class="position-sticky-fix">Автор</th>
                      <th class="position-sticky-fix">Менеджер</th>
                      <th class="position-sticky-fix">Дата просмотра</th>
                      <th class="position-sticky-fix">Информация</th>
                    </tr>
                    <tr *ngFor="let operation of data['sale_appeal_operations']">
                      <td>
                        {{operation['operation_create_time']}}
                      </td>
                      <td>
                        {{operation['operation_type_caption']}}
                      </td>
                      <td>
                        <a [routerLink]="['/physical/edit/', operation['author_id']]">
                          {{operation['author_fio']}}
                        </a><br>{{operation['author_phone']}}
                      </td>
                      <td>
                        <a [routerLink]="['/physical/edit/', operation['manager_id']]">
                          {{operation['manager_fio']}}
                        </a><br>{{operation['manager_phone']}}
                      </td>
                      <td>
                        {{operation['view_date']}} {{operation['view_time']}}
                      </td>
                      <td>
                        <ng-container *ngIf="operation['estatet_unique'] === true"><strong>Уникальный клиент в estatet</strong></ng-container>
                        <ng-container *ngIf="operation['view_confirmed'] === true">
                          Просмотр подтвержден
                          <span *ngIf="operation['view_confirm_time']"> {{operation['view_confirm_time']}}</span>
                          <span *ngIf="operation['view_confirm_manager_id']"> {{operation['view_confirm_manager_fio']}}</span>

                        </ng-container>
                        <ng-container *ngIf="operation['view_confirmed'] === false">
                          Просмотр отклонен
                          <span *ngIf="operation['view_confirm_time']"> {{operation['view_confirm_time']}}</span>
                          <span *ngIf="operation['view_confirm_manager_id']"> {{operation['view_confirm_manager_fio']}}</span>
                        </ng-container>
                        <ng-container *ngIf="operation['view_confirmed'] === null && operation['operation_type_id'] !== 15">Подтверждение просмотра</ng-container>
                        <ng-container *ngIf="operation['operation_type_id'] === 15">Резерв до {{operation['reserve_end_time']}}</ng-container>
                        <ng-container *ngIf="operation['view_confirm_comment']">
                          <br><span>Комментарий прохождения этапа: <i>{{operation['view_confirm_comment']}}</i></span><br>
                        </ng-container>
                        <ng-container *ngIf="operation['view_comment']">
                          <br><span>Комментарий прохождения этапа: <i>{{operation['view_comment']}}</i></span><br>
                        </ng-container>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

          </app-easy-dropdown>
          </div>
        </ng-container>

        <!--лиды этого клиента:-->
        <ng-container *ngIf="data['other_leads'].length">
          <div class="col-sm-12 mb-0">
          <app-easy-dropdown [cssClass]="'w100 max-height-none'" [activeDropdown]="activeDropdown" [minusPadding]="true" [titleText]="'Лиды этого клиента:'">

            <div class="mb-2">
              <div class="nomax perfect-scrollbar">
                <div class="table-responsive min-height-auto">
                  <table class="table table-striped datatable table-rwd small_table min-height-auto">
                    <tr>
                      <th class="position-sticky-fix">Объект</th>
                      <th class="position-sticky-fix">Ответственный</th>
                      <th class="position-sticky-fix">Этап</th>
                    </tr>
                    <tr *ngFor="let leads of data['other_leads']">
                      <td>
                        <ng-container *ngIf="leads['object_type'] === 'flat'">
                          <span>Квартира - </span>
                          <a [routerLink]="['/housing2/flat/', leads['object_id']]">
                            {{leads['object_caption']}}
                          </a>
                        </ng-container>
                        <ng-container *ngIf="leads['object_type'] === 'housing'">
                          <span>Корпус/комплекс - </span>
                          <a [routerLink]="['/housing/house/', leads['object_id']]">
                            {{leads['object_caption']}}
                          </a>
                        </ng-container>
                      </td>
                      <td>
                        <a routerLink="/physical/edit/{{leads['worker_id']}}">{{leads['worker_fio']}}</a><br>{{leads['worker_phone']}}
                      </td>
                      <td>
                        <app-stages-dots [data]="leads.stage_information"></app-stages-dots><br>{{leads['author_phone']}}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

          </app-easy-dropdown>
          </div>
        </ng-container>
        <!--Объекты этого клиента:-->
        <ng-container *ngIf="data['other_leads_object'].length">
          <div class="col-sm-12 mb-2">
            <app-easy-dropdown [cssClass]="'w100 max-height-none'" [activeDropdown]="activeDropdown" [minusPadding]="true" [titleText]="'Лиды на этой квартире:'">

              <div class="mb-2">
                <div class="nomax perfect-scrollbar">
                  <div class="table-responsive min-height-auto">
                    <table class="table table-striped datatable table-rwd small_table min-height-auto">
                      <tr>
                        <th class="position-sticky-fix">Клиент</th>
                        <th class="position-sticky-fix">Ответственный</th>
                        <th class="position-sticky-fix">Субагент</th>
                        <th class="position-sticky-fix">Этап</th>
                      </tr>
                      <tr *ngFor="let leads of data['other_leads_object']">

                        <td>
                          <a routerLink="/physical/edit/{{leads['client_id']}}">{{leads['client_fio']}}</a><br>{{leads['client_phone']}}
                        </td>
                        <td>
                          <a routerLink="/physical/edit/{{leads['worker_id']}}">{{leads['worker_fio']}}</a><br>{{leads['worker_phone']}}
                        </td>
                        <td>

                          <a routerLink="/physical/edit/{{leads['subagent_physical_id']}}">{{leads['subagent_physical_fio']}} {{leads['subagent_physical_phone']}}</a>
                          <p>{{leads['subagent_caption']}}</p>
                        </td>
                        <td>
                          <app-stages-dots [data]="leads.stage_information"></app-stages-dots>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

            </app-easy-dropdown>
          </div>
        </ng-container>
        <div class="col-sm-12 mt-2r">
          <app-material-textarea [rows]="4" [placeholder]="'Комментарий для лида'"
                                 formControlName="notice">
            Комментарий для лида
          </app-material-textarea>
        </div>
        <div class="col-12">
          <app-material-input-dropzone formControlName="lead_files"
                                       [hasComments]="false" [ngClass]="'small_dropzone'" >
          </app-material-input-dropzone>
        </div>

        <div class="fixed_buttons_block">
          <button type="submit" class="button mr-2"  [disabled]="!form.valid">Изменить лид</button>
          <a href="javascript: void(0)" class="button button_main" (click)="deleteLead()">Удалить лид</a>
        </div>

      </div>
    </form>

  </div>
</div>

<app-right-window
  [data]="rightWindow"
  (onClosedRight)="onClosedRight()">
  <ng-container *ngIf="rightWindowType === 'boss' || rightWindowType === 'manager'">
    <app-managers-create (managerAdded)="managerAdded($event)">
    </app-managers-create>
  </ng-container>

  <ng-container *ngIf="rightWindowType === 'views'">
    <app-reserv-lead-page-edit-views
      [data] = 'viewObj'
      (viewChanged)="viewChanged($event)">
    </app-reserv-lead-page-edit-views>
  </ng-container>

</app-right-window>
