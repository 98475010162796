
<div class="mb-2r">

<app-easy-dropdown [cssClass]="'w100 max-height-none'" [activeDropdown]="activeDropdown"
                   [minusPadding]="true"
                   [titleText]="'Аналитика по лидам:'">

  <ng-container *ngIf="all_data">
    <a [href]="urlLead + user.session_id"
       class="button" target="_blank"
    >
      Скачать<span class="d-none d-md-inline"> все лиды</span>
    </a>
    <form [formGroup]="formGroup" novalidate>
      <div class="row">
        <div class="col-sm-12">
          <div class="info_group">
            <div class="info_group__element">
              <p><span class="bold work">{{all_data.count_in_work}}</span> / <span class="gray">{{all_data.count_all}}</span>
                <span class="percent" [innerHTML]="(all_data.count_all ? (all_data.count_in_work/all_data.count_all*100).toFixed(2) : 0) + '%'"></span>
              </p>
              Всего в работе
            </div>
            <div class="info_group__element">
              <p><span class="bold success">{{all_data.count_finish}}</span> / <span class="gray">{{all_data.count_all}}</span>
                <span class="percent" [innerHTML]="(all_data.count_all ? (all_data.count_finish/all_data.count_all*100).toFixed(2) : 0) + '%'"></span>
              </p>
              Всего успешно завершённых БП
            </div>
            <div class="info_group__element">
              <p><span class="bold fail">{{all_data.count_fail}}</span> / <span class="gray">{{all_data.count_all}}</span>
                <span class="percent" [innerHTML]="(all_data.count_fail ? (all_data.count_fail/all_data.count_all*100).toFixed(2) : 0) + '%'"></span>
              </p>
              Всего проваленных БП
            </div>
          </div>
        </div>
      </div>
      <div class="row timeform">
        <div class="col-sm-3">
          <app-material-input-datepicker formControlName="date_start" [cssClass]="'mb-0'" [placeholder]="'Создан с'">
            Создан с
          </app-material-input-datepicker>
        </div>
        <div class="col-sm-3">
          <app-material-input-datepicker formControlName="date_end" [cssClass]="'mb-0'" [placeholder]="'Создан до'">
            Создан до
          </app-material-input-datepicker>
        </div>
        <div class="col-sm-3">
          <app-material-input-datepicker formControlName="date_change_start" [cssClass]="'mb-0'" [placeholder]="'Изменён с'">
            Изменён с
          </app-material-input-datepicker>
        </div>
        <div class="col-sm-3">
          <app-material-input-datepicker formControlName="date_change_end" [cssClass]="'mb-0'" [placeholder]="'Изменён до'">
            Изменён до
          </app-material-input-datepicker>
        </div>
      </div>
    </form>
    <div class="charts">
      <div class="charts__block">
        <div *ngFor="let el of stages_data"
           class="charts__block__line"
           title="{{el.description}}"
           [ngStyle]="{'max-width': el.count_all/stages_size*100 + '%'}">
          <div class="charts__block__line__info">
            {{el.caption}} ({{el.count_all}})
          </div>
          <div class="charts__block__line__color">
            <div class="charts__block__line__color_work"
                 [ngStyle]="{'width': el.count_in_work/el.count_all*100 + '%'}"
                 [ngClass]="{'active': el.locked_func === formGroup.value.locked_func && formGroup.value.tab === 'in_work' }"
                 (click)="stageFilter(el.locked_func, 'in_work')">
              В работе ({{el.count_in_work}})
            </div>
            <div class="charts__block__line__color_success"
                 [ngStyle]="{'width': el.count_success/el.count_all*100 + '%'}"
                 [ngClass]="{'active': el.locked_func === formGroup.value.locked_func && formGroup.value.tab === 'success' }"
                 (click)="stageFilter(el.locked_func, 'success')">
              Успех ({{el.count_success}})
            </div>
            <div class="charts__block__line__color_fail"
                 [ngStyle]="{'width': el.count_fail/el.count_all*100 + '%'}"
                 [ngClass]="{'active': el.locked_func === formGroup.value.locked_func && formGroup.value.tab === 'fail' }"
                 (click)="stageFilter(el.locked_func, 'fail')">
              Отказ ({{el.count_fail}})
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</app-easy-dropdown>
</div>

<div class="material-datatable-wrapper table-color">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)">
  </app-datatable-headline>
  <div class="table-responsive">
    <table class="table datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>

<ng-template #organisation_captionHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="organisation_caption" [cssClass]="'mb-0'" [placeholder]="'Организация'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #organisation_captionTmpl let-row="row" let-ind="row_index">
  <div [ngClass]="{'activeBox': goBack.activeHash === 'organisation_caption-' + ind}">
    <a href="/entity/edit/{{row.organisation_id}}" (click)="goBack.newLink('/entity/edit/' + row.organisation_id, 'organisation_caption-' + ind, $event)">{{row.organisation_caption}}</a>
  </div>
</ng-template>

<ng-template #subagent_user_fioHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="subagent_user_fio" [cssClass]="'mb-0'" [placeholder]="'Субагент'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #subagent_user_fioTmpl let-row="row" let-ind="row_index">
  <a routerLink="/physical/edit/{{row.subagent_user_id}}">{{row.subagent_user_fio}}</a>
</ng-template>

<ng-template #subagent_phoneHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="subagent_phone" [cssClass]="'mb-0'" [placeholder]="'Телефон субагента'">
    </app-material-input>
  </form>
</ng-template>
<ng-template #lead_phoneHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="lead_phone" [cssClass]="'mb-0'" [placeholder]="'Телефон клиента'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #fioHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="fio" [cssClass]="'mb-0'" [placeholder]="'Клиент'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #fioTmpl let-row="row" let-ind="row_index">
  <ng-container *ngIf="row.fio">
    <a routerLink="/reservation/lead-info/{{row.id}}">{{row.fio}}</a>
  </ng-container>
  <ng-container *ngIf="!row.fio">
    <a routerLink="/reservation/lead-info/{{row.id}}">-</a>
  </ng-container>
</ng-template>

<ng-template #object_captionHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="object_caption" [cssClass]="'mb-0'" [placeholder]="'Объект'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #object_captionTmpl let-row="row" let-ind="row_index">
  <ng-container *ngIf="row.object_type === 'flat'">
    <a routerLink="/housing2/flat/{{row.object_id}}">{{row.object_caption}}</a>
  </ng-container>
  <ng-container *ngIf="row.object_type === 'housing'">
    <a routerLink="/housing/house/{{row.object_id}}">{{row.object_caption}}</a>
  </ng-container>
</ng-template>

<ng-template #next_contact_dateHead>
  <form [formGroup]="formGroup" novalidate>
    <app-material-input-datepicker formControlName="next_contact_date" [cssClass]="'mb-0'" [placeholder]="'След. контакт (созд./изм.)'">
    </app-material-input-datepicker>
  </form>
</ng-template>
<ng-template #next_contact_dateTmpl let-row="row">
  <p class="bold mb-1" *ngIf="row.next_contact_date">
    {{datediff(row.next_contact_date)}}<br>
    ({{row.next_contact_date}} {{row.next_contact_time}})
  </p>
  {{row.create_time}} / {{row.stage_change_time}}
</ng-template>

<ng-template #stage_captionTmpl let-row="row">
  <app-stages-dots [data]="row.stage_information"></app-stages-dots>
</ng-template>

