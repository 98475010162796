
import {from as observableFrom, BehaviorSubject, Observable} from 'rxjs';
import {EventEmitter, Injectable} from '@angular/core';
import {OsmClusterComponent} from './osm-cluster/osm-cluster.component';
import {OsmPlacemarkComponent} from './osm-placemark/osm-placemark.component';
import {OsmMapComponent} from './osm-map/osm-map.component';
import {OsmPolygonComponent} from './osm-polygon/osm-polygon.component';
import {OsmCircleComponent} from './osm-circle/osm-circle.component';
import {OsmDrawerComponent} from './osm-drawer/osm-drawer.component';

declare const L;


@Injectable()
export class OsmService {

  private subjMap = new BehaviorSubject<[any, any]>(null);
  public readonly $getMap = observableFrom(this.subjMap);

  public ready: Promise<any>;

  private mapCollection: Map<OsmMapComponent, any> = new Map();
  private drawCollection: Map<OsmDrawerComponent, any> = new Map();
  private circleCollection: Map<OsmCircleComponent, any> = new Map();
  private clusterCollection: Map<OsmClusterComponent, any> = new Map();
  private polygonCollection: Map<OsmPolygonComponent, any> = new Map();
  private placemarkCollection: Map<OsmPlacemarkComponent, any> = new Map();

  public lastElement: EventEmitter<boolean> = new EventEmitter();

  constructor() {
    this.ready = new Promise<any>(resolve => resolve(L));
  }

  registerMap(map, mapCls) {
    this.mapCollection.set(mapCls, map);
    this.subjMap.next(L);
  }

  unregisterDraw(draw, drawCls, mapCls) {
    const map = this.mapCollection.get(mapCls);
    map.removeControl(drawCls.controls);
  }

  registerDraw(draw, drawCls, mapCls) {
    const map = this.mapCollection.get(mapCls);

    map.addControl(drawCls.controls);
    map.addLayer(drawCls.layer);

    map.on('draw:created', (e) => {
      drawCls.layer.addLayer(e.layer);

      this.drawCollection.set(e.layer._leaflet_id, e.layer);
      drawCls.onCreate.emit(Array.from(this.drawCollection.values())
        .map(l => l.getLatLngs()));
    });

    map.on('draw:edited', (e) => {
      e.layers.eachLayer((l) => {
        this.drawCollection.set(l._leaflet_id, l);
      });

      drawCls.onCreate.emit(Array.from(this.drawCollection.values())
        .map(l => l.getLatLngs()));
    });

    map.on('draw:deleted', (e) => {
      e.layers.eachLayer((l) => {
        this.drawCollection.delete(l._leaflet_id);
      });

      drawCls.onCreate.emit(Array.from(this.drawCollection.values())
        .map(l => l.getLatLngs()));
    });
  }

  registerPolygonDraw(l) {
    this.drawCollection.set(l._leaflet_id, l);
  }

  registerPolygon(polygon, polygonCls, mapCls) {
    this.polygonCollection.set(polygonCls, polygon);

    const map = this.mapCollection.get(mapCls);

    polygon.addTo(map);
  }

  registerCircle(circle, circleCls, mapCls) {
    this.circleCollection.set(circleCls, circle);

    const map = this.mapCollection.get(mapCls);

    if (circle && map) {
      circle.addTo(map);
    }
  }

  registerPlacemark(placemark, placemarkCls, clusterCls = null, mapCls = null, lastElement = false) {
    this.placemarkCollection.set(placemarkCls, placemark);
    if (clusterCls) {
      const cluster = this.clusterCollection.get(clusterCls);
      cluster.addLayer(placemark);
      clusterCls.placemarks.push(cluster);
    } else if (mapCls) {
      const map = this.mapCollection.get(mapCls);
      map.addLayer(placemark);
      mapCls.placemarks.push(placemark);
    }
    if (lastElement) {
      this.lastElement.next(true);
    }
  }

  registerCluster(cluster, clusterCls, mapCls) {
    this.clusterCollection.set(clusterCls, cluster);

    const map = this.mapCollection.get(mapCls);
    if (!map) {
      return;
    }
    map.addLayer(cluster);
    mapCls.clusters.push(cluster);
  }

  dropMap(mapCls) {
    const map = this.mapCollection.get(mapCls);
    this.mapCollection.delete(mapCls);
    this.subjMap.next(null);
  }

  dropCluster(clusterCls, mapCls) {
    const map = this.mapCollection.get(mapCls);
    const cluster = this.clusterCollection.get(clusterCls);

    clusterCls.placemarks.forEach(layer => cluster.removeLayer(layer));
    map.removeLayer(cluster);
  }

  dropCircle(circleCls, mapCls) {
    const map = this.mapCollection.get(mapCls);
    const circle = this.circleCollection.get(circleCls);
    map.removeLayer(circle);
  }

  dropPlacemark(placemarkCls, clusterCls = null, mapCls = null) {
    if (clusterCls) {
      const cluster = this.clusterCollection.get(clusterCls);
      const placemark = this.placemarkCollection.get(placemarkCls);

      cluster.removeLayer(placemark);
    } else if (mapCls) {
      const map = this.mapCollection.get(mapCls);
      const placemark = this.placemarkCollection.get(placemarkCls);

      map.removeLayer(placemark);
    }
  }

  getPlacemarkClsByValue(placemark) {
    return Array.from(this.placemarkCollection.entries())
      .filter(({1: _placemark}) => placemark == _placemark)
      .map(([placemarkCls]) => placemarkCls);
  }

  displayPolygon(mapCls, polygon) {
    const map = this.mapCollection.get(mapCls);
    map.addLayer(polygon);
  }

  displaySemiCircle(mapCls, semicircle) {
    const map = this.mapCollection.get(mapCls);
    map.addLayer(semicircle);
  }

  dropSemiCircle(mapCls, semicircle) {
    const map = this.mapCollection.get(mapCls);
    map.removeLayer(semicircle);
  }

  getMap(mapCls) {
    return this.mapCollection.get(mapCls);
  }
}
