<div class="material-datatable-wrapper">
  <div class="material-datatable-wrapper__header">
    <form [formGroup]="form" class="form_crm" novalidate>
      <div class="row">
        <div class="col-md-3">
          <app-material-input formControlName="event_complexes" [placeholder]="'Введите ЖК'"></app-material-input>
        </div>
        <div class="col-md-3">
          <app-material-input-datepicker formControlName="event_date_from"></app-material-input-datepicker>
        </div>
        <div class="col-md-3">
          <app-material-input-datepicker formControlName="event_date_to"></app-material-input-datepicker>
        </div>
        <div class="col-md-3">
          <app-material-select formControlName="event_type">
            <option [value]="'any'">Любой тип</option>
            <option *ngFor="let type of types" [value]="type['type_id']">{{type['type_caption']}}</option>
          </app-material-select>
        </div>
        <div class="col-12 my-3">
          <button type="submit" (click)="submitForm()" [disabled]="!form.valid" class="button button_sm button_block">Искать</button>
        </div>
      </div>
    </form>
  </div>
  <div class="table-responsive">
    <table class="table datatable" [ngClass]="cssClass">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [loading]="true"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>
