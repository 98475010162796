import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {JsonClientService} from '../../../../shared/json-client';

@Injectable()
export class ReservLeadPageEditResolve {

  constructor(public http: JsonClientService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.http.post('Bp.lead_sale_get', {'lead_id': route.params['id']});
  }
}
