import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BreadcrumbsService} from '../../../../shared/breadcrumbs/services';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {GoBackButtonService} from '../../../../services/go-back-button.service';

declare const moment;
import {environment} from '../../../../../environments/environment';

@Component({
  templateUrl: './report-compare.component.html',
  styleUrls: [
    './report-compare.component.scss'
  ]
})
export class ReportCompareComponent implements OnInit, OnDestroy, AfterViewInit {


  public server = environment.apiServer;
  public leadSources: any;

  public lineChartData = [];
  public lineChartLabels = [];
  public lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
      labels: {
        usePointStyle: true,
      },
      fullWidth: true
    }
  };

  public lineChartLegend = true;

  public reports;
  public charts;
  public date_start = '';
  public date_end = '';


  private readonly date_format = 'MM.YYYY';
  private readonly table_keys = {
    'get_reserved_summary': 'Отдел бронирования',
    'get_attraction_summary': 'Отдел привлечения',
    'get_calendar_summary': 'Обучение субагентов',
    'get_realty_summary': 'Отдел проектов',
    'get_activity_users_summary': 'Активность субагентов',
    'get_lead_statistic': 'Статистика лида',
  };

  public base_url = '/analytic/report-common';

  public months;
  public months_lastday = [];

  public head;
  public head_project = ['Значение', 'Estatet', 'Spn24', 'Всего'];
  public data;

  public statistic;

  public colspan;
  public lock_type;

  private onDestroy = new Subject();

  constructor(public a: ActivatedRoute,
              private b: BreadcrumbsService,
              private r: Router,
              public goBack: GoBackButtonService) { }

  ngOnInit(): void {
    this.leadSources = this.a.snapshot.data['leadSources'];
    this.lock_type = this.a.snapshot.queryParams['lock'] || null;

    this.r.events.pipe(
      filter(x => x instanceof NavigationEnd),
      takeUntil(this.onDestroy)
    )
      .subscribe(_ => this.onRouteChange());

    this.onRouteChange();
  }

  onRouteChange() {
    this.lock_type = this.a.snapshot.queryParams['lock'] || null;
    this.data = this.a.snapshot.data['data'];
    this.captureData();

    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/analytic/index', caption: 'Аналитика'},
    ]);
    if (this.lock_type === null) {
      this.goBack.header = '<h2>Производственные отчеты - Сводный отчет</h2>';
    } else if (this.lock_type === 'common_reserved') {
      this.goBack.header = '<h2>Производственные отчеты - Сводный отчет, бронирование</h2>';
    } else if (this.lock_type === 'common_events') {
      this.goBack.header = '<h2>Производственные отчеты - Сводный отчет, обучение</h2>';
    } else if (this.lock_type === 'common_events_realty') {
      this.goBack.header = '<h2>Производственные отчеты - Сводный отчет, обучение - вид по ЖК</h2>';
    } else if (this.lock_type === 'common_attraction') {
      this.goBack.header = '<h2>Производственные отчеты - Сводный отчет, отдел привлечения</h2>';
    } else if (this.lock_type === 'common_realty') {
      this.goBack.header = '<h2>Производственные отчеты - Сводный отчет, отдел проектов</h2>';
    } else if (this.lock_type === 'on_period') {
      this.goBack.header = '<h2>Состав новых уникальных обращений</h2>';
    } else if (this.lock_type === 'on_month') {
      this.goBack.header = '<h2>Динамика по составу новых обращений помесячно</h2>';
    } else if (this.lock_type === 'on_moment') {
      this.goBack.header = '<h2>Текущее состояние всех обращений на момент запроса</h2>';
    }
  }

  private captureData() {
    this.lineChartData = [];
    this.lineChartLabels = [];
    this.months = this.getMonthsInterval();
    for (const month of this.months) {
        this.months_lastday.push(new Date(month.split('.')[1], month.split('.')[0], 0).getDate() + '.' + month);
    }

    this.colspan = this.months.length + 1;
    this.head = ['Наименование показателя',
      ...this.months.map(x => moment(x, this.date_format).format('MMM YYYY'))
    ];
    this.date_start = this.a.snapshot.params['start'] || moment()
      .startOf('year').format('DD.MM.YYYY');
    this.date_end = this.a.snapshot.params['stop'] || moment()
      .endOf('year').format('DD.MM.YYYY');

    this.lineChartLabels = this.months;


    if (this.lock_type !== 'on_period' && this.lock_type !== 'on_month' && this.lock_type !== 'on_moment') {
      this.statistic = {
        'attraction_cnt_org_register_fail': this.getStatisticRow(
          'get_attraction_summary', 'cnt_org_register_fail'),
        'attraction_cnt_org_master_fail': this.getStatisticRow(
          'get_attraction_summary', 'cnt_org_master_fail'),
        'attraction_cnt_org_register_approve_fail': this.getStatisticRow(
          'get_attraction_summary', 'cnt_org_register_approve_fail'),
        'attraction_cnt_org_approve_fail': this.getStatisticRow(
          'get_attraction_summary', 'cnt_org_approve_fail'),

        'attraction_cnt_org_register': this.getStatisticRow(
          'get_attraction_summary', 'cnt_org_register'),
        'attraction_cnt_org_approve': this.getStatisticRow(
          'get_attraction_summary', 'cnt_org_approve'),


        'attraction_cnt_org_register_not_approve': this.getStatisticRow(
          'get_attraction_summary', 'cnt_org_master'),
        'attraction_cnt_org_register_approve': this.getStatisticRow(
          'get_attraction_summary', 'cnt_org_register_approve'),
        'attraction_cnt_org_register_summary': this.getStatisticRow(
          'get_attraction_summary', 'cnt_org_approve_summary'),

        'attraction_cnt_org_fail': this.getStatisticRow(
          'get_attraction_summary', 'cnt_org_fail'),
        'attraction_cnt_org_fail_summary': this.getStatisticRow(
          'get_attraction_summary', 'cnt_org_fail_summary'),
        'attraction_cnt_active_subagents': this.getStatisticRow(
          'get_attraction_summary', 'cnt_active_subagents'),
        'attraction_cnt_workers_summary': this.getStatisticRow(
          'get_attraction_summary', 'cnt_workers_summary'),
        'attraction_cnt_org_lock': this.getStatisticRow(
          'get_attraction_summary', 'cnt_org_lock'),
        'attraction_cnt_org_lock_summary': this.getStatisticRow(
          'get_attraction_summary', 'cnt_org_lock_summary'),
        'attraction_cnt_org_constrains': this.getStatisticRow(
          'get_attraction_summary', 'cnt_org_constrains'),


        'attraction_cnt_new_workers': this.getStatisticRow(
          'get_attraction_summary', 'cnt_new_workers'),
        'attraction_cnt_new_workers_summary': this.getStatisticRow(
          'get_attraction_summary', 'cnt_new_workers_summary'),
        'calendar_events_approve': this.getStatisticRow(
          'get_calendar_summary', 'cnt_events_approve'),
        'calendar_staff_events_subscribe': this.getStatisticRow(
          'get_calendar_summary', 'cnt_staff_events_subscribe'),
        'calendar_staff_events_not_approve': this.getStatisticRow(
          'get_calendar_summary', 'cnt_staff_events_not_approve'),
        'calendar_staff_events_approve': this.getStatisticRow(
          'get_calendar_summary', 'cnt_staff_events_approve'),
        'calendar_sub_agents_events_subscribe': this.getStatisticRow(
          'get_calendar_summary', 'cnt_sub_agents_events_subscribe'),
        'calendar_sub_agents_events_not_approve': this.getStatisticRow(
          'get_calendar_summary', 'cnt_sub_agents_events_not_approve'),
        'calendar_sub_agents_events_approve': this.getStatisticRow(
          'get_calendar_summary', 'cnt_sub_agents_events_approve'),
        'calendar_all_complex': this.getStatisticRow(
          'get_calendar_summary', 'all_complex'),
        'calendar_complex_infotur': this.getStatisticRow(
          'get_calendar_summary', 'complex_infotur'),
        'calendar_complex_training': this.getStatisticRow(
          'get_calendar_summary', 'complex_training'),
        'calendar_complex_webinar': this.getStatisticRow(
          'get_calendar_summary', 'complex_webinar'),

        'calendar_events_approve_favourites': this.getStatisticRow(
          'get_calendar_summary', 'cnt_events_approve_favourites'),
        'calendar_staff_events_subscribe_favourites': this.getStatisticRow(
          'get_calendar_summary', 'cnt_staff_events_subscribe_favourites'),
        'calendar_events_not_visit_favourites': this.getStatisticRow(
          'get_calendar_summary', 'cnt_staff_events_not_visit_favourites'),
        'calendar_staff_events_not_visit': this.getStatisticRow(
          'get_calendar_summary', 'cnt_staff_events_not_visit'),
        'calendar_staff_events_visit': this.getStatisticRow(
          'get_calendar_summary', 'cnt_staff_events_visit'),
        'calendar_staff_events_visit_favourites': this.getStatisticRow(
          'get_calendar_summary', 'cnt_staff_events_visit_favourites'),
        'calendar_orgs_events_subscribe': this.getStatisticRow(
          'get_calendar_summary', 'cnt_orgs_events_subscribe'),

        'calendar_orgs_events_subscribe_favourites': this.getStatisticRow(
          'get_calendar_summary', 'cnt_orgs_events_subscribe_favourites'),
        'calendar_orgs_events_not_visit': this.getStatisticRow(
          'get_calendar_summary', 'cnt_orgs_events_not_visit'),
        'calendar_orgs_events_not_visit_favourites': this.getStatisticRow(
          'get_calendar_summary', 'cnt_orgs_events_not_visit_favourites'),
        'calendar_orgs_events_visit': this.getStatisticRow(
          'get_calendar_summary', 'cnt_orgs_events_visit'),
        'calendar_orgs_events_visit_favourites': this.getStatisticRow(
          'get_calendar_summary', 'cnt_orgs_events_visit_favourites'),
        'calendar_training_seminar': this.getStatisticRow(
          'get_calendar_summary', 'cnt_training_seminar'),
        'calendar_user_subscribe_training': this.getStatisticRow(
          'get_calendar_summary', 'cnt_user_subscribe_training'),
        'calendar_user_visit_training': this.getStatisticRow(
          'get_calendar_summary', 'cnt_user_visit_training'),
        'calendar_user_not_visit_training': this.getStatisticRow(
          'get_calendar_summary', 'cnt_user_not_visit_training'),
        'calendar_org_subscribe_training': this.getStatisticRow(
          'get_calendar_summary', 'cnt_org_subscribe_training'),
        'calendar_org_visit_training': this.getStatisticRow(
          'get_calendar_summary', 'cnt_org_visit_training'),
        'calendar_org_not_visit_training': this.getStatisticRow(
          'get_calendar_summary', 'cnt_org_not_visit_training'),
        'calendar_webinar': this.getStatisticRow(
          'get_calendar_summary', 'cnt_webinar'),
        'calendar_user_subscribe_webinar': this.getStatisticRow(
          'get_calendar_summary', 'cnt_user_subscribe_webinar'),
        'calendar_user_visit_webinar': this.getStatisticRow(
          'get_calendar_summary', 'cnt_user_visit_webinar'),
        'calendar_user_not_visit_webinar': this.getStatisticRow(
          'get_calendar_summary', 'cnt_user_not_visit_webinar'),
        'calendar_org_subscribe_webinar': this.getStatisticRow(
          'get_calendar_summary', 'cnt_org_subscribe_webinar'),
        'calendar_org_visit_webinar': this.getStatisticRow(
          'get_calendar_summary', 'cnt_org_visit_webinar'),
        'calendar_org_not_visit_webinar': this.getStatisticRow(
          'get_calendar_summary', 'cnt_org_not_visit_webinar'),
        'calendar_info_tour': this.getStatisticRow(
          'get_calendar_summary', 'cnt_info_tour'),
        'calendar_user_subscribe_info_tour': this.getStatisticRow(
          'get_calendar_summary', 'cnt_user_subscribe_info_tour'),
        'calendar_user_visit_info_tour': this.getStatisticRow(
          'get_calendar_summary', 'cnt_user_visit_info_tour'),
        'calendar_user_not_visit_info_tour': this.getStatisticRow(
          'get_calendar_summary', 'cnt_user_not_visit_info_tour'),
        'calendar_org_subscribe_info_tour': this.getStatisticRow(
          'get_calendar_summary', 'cnt_org_subscribe_info_tour'),
        'calendar_org_visit_info_tour': this.getStatisticRow(
          'get_calendar_summary', 'cnt_org_visit_info_tour'),
        'calendar_org_not_visit_info_tour': this.getStatisticRow(
          'get_calendar_summary', 'cnt_org_not_visit_info_tour'),
        'calendar_other': this.getStatisticRow(
          'get_calendar_summary', 'cnt_other'),
        // meeting developer
        'calendar_meeting_developer': this.getStatisticRow(
          'get_calendar_summary', 'cnt_meeting_developer'),
        'calendar_user_subscribe_meeting_developer': this.getStatisticRow(
          'get_calendar_summary', 'cnt_user_subscribe_meeting_developer'),
        'calendar_user_visit_meeting_developer': this.getStatisticRow(
          'get_calendar_summary', 'cnt_users_visit_meeting_developer'),
        'calendar_user_not_visit_meeting_developer': this.getStatisticRow(
          'get_calendar_summary', 'cnt_users_not_visit_meeting_developer'
        ),
        'calendar_org_subscribe_meeting_developer': this.getStatisticRow(
          'get_calendar_summary', 'cnt_org_subscription_meeting_developer'),
        'calendar_org_visit_meeting_developer': this.getStatisticRow(
          'get_calendar_summary', 'cnt_org_visit_meeting_developer'),
        'calendar_org_not_visit_meeting_developer': this.getStatisticRow(
          'get_calendar_summary', 'cnt_org_not_visit_meeting_developer'),

        'total_new_org_subscribe_on_events': this.getStatisticRow(
          'get_calendar_summary', 'total_new_org_subscribe_on_events'),
        'total_new_org_visit_events': this.getStatisticRow(
          'get_calendar_summary', 'total_new_org_visit_events'),
        'total_new_org_not_visit_events': this.getStatisticRow(
          'get_calendar_summary', 'total_new_org_not_visit_events'),
        'new_org_subscription_training': this.getStatisticRow(
          'get_calendar_summary', 'new_org_subscription_training'),
        'new_org_subscription_infotur': this.getStatisticRow(
          'get_calendar_summary', 'new_org_subscription_infotur'),
        'new_org_subscription_webinar': this.getStatisticRow(
          'get_calendar_summary', 'new_org_subscription_webinar'),
        'new_org_subscription_meeting_developer': this.getStatisticRow(
          'get_calendar_summary', 'new_org_subscription_meeting_developer'
        ),
        'new_org_visit_training': this.getStatisticRow(
          'get_calendar_summary', 'new_org_visit_training'),
        'new_org_visit_infotur': this.getStatisticRow(
          'get_calendar_summary', 'new_org_visit_infotur'),
        'new_org_visit_webinar': this.getStatisticRow(
          'get_calendar_summary', 'new_org_visit_webinar'),
        'new_org_visit_meeting_developer': this.getStatisticRow(
          'get_calendar_summary', 'new_org_visit_meeting_developer'),
        'new_org_not_visit_training': this.getStatisticRow(
          'get_calendar_summary', 'new_org_not_visit_training'),
        'new_org_not_visit_infotur': this.getStatisticRow(
          'get_calendar_summary', 'new_org_not_visit_infotur'),
        'new_org_not_visit_webinar': this.getStatisticRow(
          'get_calendar_summary', 'new_org_not_visit_webinar'),
        'new_org_not_visit_meeting_developer': this.getStatisticRow(
          'get_calendar_summary', 'new_org_not_visit_meeting_developer'),

        'total_resale_org_subscribe_on_events': this.getStatisticRow(
          'get_calendar_summary', 'total_resale_org_subscribe_on_events'),
        'total_resale_org_visit_events': this.getStatisticRow(
          'get_calendar_summary', 'total_resale_org_visit_events'),
        'total_resale_org_not_visit_events': this.getStatisticRow(
          'get_calendar_summary', 'total_resale_org_not_visit_events'),
        'resale_org_subscription_training': this.getStatisticRow(
          'get_calendar_summary', 'resale_org_subscription_training'),
        'resale_org_subscription_infotur': this.getStatisticRow(
          'get_calendar_summary', 'resale_org_subscription_infotur'),
        'resale_org_subscription_webinar': this.getStatisticRow(
          'get_calendar_summary', 'resale_org_subscription_webinar'),
        'resale_org_subscription_meeting_developer': this.getStatisticRow(
          'get_calendar_summary', 'resale_org_subscription_meeting_developer'
        ),
        'resale_org_visit_training': this.getStatisticRow(
          'get_calendar_summary', 'resale_org_visit_training'),
        'resale_org_visit_infotur': this.getStatisticRow(
          'get_calendar_summary', 'resale_org_visit_infotur'),
        'resale_org_visit_webinar': this.getStatisticRow(
          'get_calendar_summary', 'resale_org_visit_webinar'),
        'resale_org_visit_meeting_developer': this.getStatisticRow(
          'get_calendar_summary', 'resale_org_visit_meeting_developer'),
        'resale_org_not_visit_training': this.getStatisticRow(
          'get_calendar_summary', 'resale_org_not_visit_training'),
        'resale_org_not_visit_infotur': this.getStatisticRow(
          'get_calendar_summary', 'resale_org_not_visit_infotur'),
        'resale_org_not_visit_webinar': this.getStatisticRow(
          'get_calendar_summary', 'resale_org_not_visit_webinar'),
        'resale_org_not_visit_meeting_developer': this.getStatisticRow(
          'get_calendar_summary', 'resale_org_not_visit_meeting_developer'),

        'new_users_not_visit_infotur': this.getStatisticRow(
          'get_calendar_summary', 'new_users_not_visit_infotur'),
        'new_users_not_visit_training': this.getStatisticRow(
          'get_calendar_summary', 'new_users_not_visit_training'),
        'new_users_not_visit_webinar': this.getStatisticRow(
          'get_calendar_summary', 'new_users_not_visit_webinar'),
        'new_users_not_visit_meeting_developer': this.getStatisticRow(
          'get_calendar_summary', 'new_users_not_visit_meeting_developer'),
        'new_users_subscription_infotur': this.getStatisticRow(
          'get_calendar_summary', 'new_users_subscription_infotur'),
        'new_users_subscription_training': this.getStatisticRow(
          'get_calendar_summary', 'new_users_subscription_training'),
        'new_users_subscription_webinar': this.getStatisticRow(
          'get_calendar_summary', 'new_users_subscription_webinar'),
        'new_users_subscription_meeting_developer': this.getStatisticRow(
          'get_calendar_summary', 'new_users_subscription_meeting_developer'),
        'new_users_visit_infotur': this.getStatisticRow(
          'get_calendar_summary', 'new_users_visit_infotur'),
        'new_users_visit_training': this.getStatisticRow(
          'get_calendar_summary', 'new_users_visit_training'),
        'new_users_visit_webinar': this.getStatisticRow(
          'get_calendar_summary', 'new_users_visit_webinar'),
        'new_users_visit_meeting_developer': this.getStatisticRow(
          'get_calendar_summary', 'new_users_visit_meeting_developer'),
        'total_new_users_not_visit': this.getStatisticRow(
          'get_calendar_summary', 'total_new_users_not_visit'),
        'total_new_users_subscribe': this.getStatisticRow(
          'get_calendar_summary', 'total_new_users_subscribe'),
        'total_new_users_visit': this.getStatisticRow(
          'get_calendar_summary', 'total_new_users_visit'),


        'realty_complex_summary': this.getStatisticRow(
          'get_realty_summary', 'cnt_realty_complex_summary'),
        'realty_estatet': this.getStatisticRow(
          'get_realty_summary', 'cnt_realty_estatet'),
        'realty_spn24': this.getStatisticRow(
          'get_realty_summary', 'cnt_realty_spn24'),
        'realty_flats': this.getStatisticRow(
          'get_realty_summary', 'cnt_flats'),
        'realty_square': this.getStatisticRow(
          'get_realty_summary', 'cnt_square'),
        'reserved_appeals_all_on_month': this.getStatisticRow(
          'get_reserved_summary', 'new_appeals'),
        'reserved_appeals_system': this.getStatisticRow(
          'get_reserved_summary', 'cnt_appeals_system'),
        'reserved_appeals_calls': this.getStatisticRow(
          'get_reserved_summary', 'cnt_appeals_calls'),
        'reserved_appeals_email': this.getStatisticRow(
          'get_reserved_summary', 'cnt_appeals_email'),
        'reserved_appeals_iframe': this.getStatisticRow(
          'get_reserved_summary', 'cnt_appeals_iframe'),
        'reserved_views_subscribe': this.getStatisticRow(
          'get_reserved_summary', 'stage_preview'),
        'reserved_check_unique': this.getStatisticRow(
          'get_reserved_summary', 'stage_unique'),
        'reserved_views_success': this.getStatisticRow(
          'get_reserved_summary', 'stage_view_processing'),
        'reserved_appeals_office': this.getStatisticRow(
          'get_reserved_summary', 'cnt_appeals_office'),
        'reserved_on_month': this.getStatisticRow(
          'get_reserved_summary', 'stage_reserve'),
        'reserved_successed_month': this.getStatisticRow(
          'get_reserved_summary', 'stage_db'),
        'reserved_pre_sale_stages_fail': this.getStatisticRow(
          'get_reserved_summary', 'pre_sale_stages_fail'),
        'reserved_sale_stages_fail': this.getStatisticRow(
          'get_reserved_summary', 'sale_stages_fail'),
        'reserved_stage_reserve_fail': this.getStatisticRow(
          'get_reserved_summary', 'stage_reserve_fail'),
        'reserved_chancel_on_month': this.getStatisticRow(
          'get_reserved_summary', 'cnt_chancel_reserve_on_month'),
        'reserved_success_deals': this.getStatisticRow(
          'get_reserved_summary', 'stage_archive'),
        'reserved_views_all_on_month': this.getStatisticRow(
          'get_reserved_summary', 'cnt_views_all_on_month'),
        // --
        'appeals_cnt_sale_call': this.getStatisticRow(
          'get_reserved_summary', 'stage_call'),
        'appeals_cnt_sale_subagent_payment': this.getStatisticRow(
          'get_reserved_summary', 'stage_subagent_payment'),
        'appeals_cnt_sale_subagent_docs': this.getStatisticRow(
          'get_reserved_summary', 'stage_subagent_docs'),
        'appeals_cnt_sale_developer_docs': this.getStatisticRow(
          'get_reserved_summary', 'stage_developer_docs'),
        'appeals_cnt_sale_ddu': this.getStatisticRow(
          'get_reserved_summary', 'stage_ddu'),
        'appeals_cnt_sale_ddu_payment': this.getStatisticRow(
          'get_reserved_summary', 'stage_ddu_payment'),
        'appeals_cnt_sale_view': this.getStatisticRow(
          'get_reserved_summary', 'stage_view'),
        'appeals_cnt_sale_developer_payment': this.getStatisticRow(
          'get_reserved_summary', 'stage_developer_payment'),
        'appeals_cnt_sale_pre_reserve': this.getStatisticRow(
          'get_reserved_summary', 'stage_pre_reserve'),

        'common_realty_est_avg_price': this.data['get_realty_summary'][0]['est_avg_price'],
        'common_realty_est_avg_reward': this.data['get_realty_summary'][0]['est_avg_reward'],
        'common_realty_est_avg_square': this.data['get_realty_summary'][0]['est_avg_square'],
        'common_realty_est_complex': this.data['get_realty_summary'][0]['est_complex'],
        'common_realty_est_housings': this.data['get_realty_summary'][0]['est_housings'],
        'common_realty_est_flats': this.data['get_realty_summary'][0]['est_flats'],
        'common_realty_est_max_price': this.data['get_realty_summary'][0]['est_max_price'],
        'common_realty_est_max_reward': this.data['get_realty_summary'][0]['est_max_reward'],
        'common_realty_est_max_square': this.data['get_realty_summary'][0]['est_max_square'],
        'common_realty_est_min_price': this.data['get_realty_summary'][0]['est_min_price'],
        'common_realty_est_min_reward': this.data['get_realty_summary'][0]['est_min_reward'],
        'common_realty_est_min_square': this.data['get_realty_summary'][0]['est_min_square'],
        'common_realty_est_square': this.data['get_realty_summary'][0]['est_square'],
        'common_realty_spn24_avg_price': this.data['get_realty_summary'][0]['spn24_avg_price'],
        'common_realty_spn24_avg_reward': this.data['get_realty_summary'][0]['spn24_avg_reward'],
        'common_realty_spn24_avg_square': this.data['get_realty_summary'][0]['spn24_avg_square'],
        'common_realty_spn24_complex': this.data['get_realty_summary'][0]['spn24_complex'],
        'common_realty_spn24_housings': this.data['get_realty_summary'][0]['spn24_housings'],
        'common_realty_spn24_flats': this.data['get_realty_summary'][0]['spn24_flats'],
        'common_realty_spn24_max_price': this.data['get_realty_summary'][0]['spn24_max_price'],
        'common_realty_spn24_max_reward': this.data['get_realty_summary'][0]['spn24_max_reward'],
        'common_realty_spn24_max_square': this.data['get_realty_summary'][0]['spn24_max_square'],
        'common_realty_spn24_min_price': this.data['get_realty_summary'][0]['spn24_min_price'],
        'common_realty_spn24_min_reward': this.data['get_realty_summary'][0]['spn24_min_reward'],
        'common_realty_spn24_min_square': this.data['get_realty_summary'][0]['spn24_min_square'],
        'common_realty_spn24_square': this.data['get_realty_summary'][0]['spn24_square'],
        'common_realty_total_avg_price': this.data['get_realty_summary'][0]['total_avg_price'],
        'common_realty_total_avg_reward': this.data['get_realty_summary'][0]['total_avg_reward'],
        'common_realty_total_avg_square': this.data['get_realty_summary'][0]['total_avg_square'],
        'common_realty_total_complex': this.data['get_realty_summary'][0]['total_complex'],
        'common_realty_total_housings': this.data['get_realty_summary'][0]['total_housings'],
        'common_realty_total_flats': this.data['get_realty_summary'][0]['total_flats'],
        'common_realty_total_max_price': this.data['get_realty_summary'][0]['total_max_price'],
        'common_realty_total_max_reward': this.data['get_realty_summary'][0]['total_max_reward'],
        'common_realty_total_max_square': this.data['get_realty_summary'][0]['total_max_square'],
        'common_realty_total_min_price': this.data['get_realty_summary'][0]['total_min_price'],
        'common_realty_total_min_reward': this.data['get_realty_summary'][0]['total_min_reward'],
        'common_realty_total_min_square': this.data['get_realty_summary'][0]['total_min_square'],
        'common_realty_total_square': this.data['get_realty_summary'][0]['total_square'],
        'common_realty_est_complex_moscow': this.data['get_realty_summary'][0]['est_complex_moscow'],
        'common_realty_est_complex_not_moscow': this.data['get_realty_summary'][0]['est_complex_not_moscow'],
        'common_realty_est_rooms_1': this.data['get_realty_summary'][0]['est_rooms_1'],
        'common_realty_est_rooms_1_apartment': this.data['get_realty_summary'][0]['est_rooms_1_apartment'],
        'common_realty_est_rooms_1_studio': this.data['get_realty_summary'][0]['est_rooms_1_studio'],
        'common_realty_est_rooms_2': this.data['get_realty_summary'][0]['est_rooms_2'],
        'common_realty_est_rooms_2_apartment': this.data['get_realty_summary'][0]['est_rooms_2_apartment'],
        'common_realty_est_rooms_2_studio': this.data['get_realty_summary'][0]['est_rooms_2_studio'],
        'common_realty_est_rooms_3': this.data['get_realty_summary'][0]['est_rooms_3'],
        'common_realty_est_rooms_3_apartment': this.data['get_realty_summary'][0]['est_rooms_3_apartment'],
        'common_realty_est_rooms_3_studio': this.data['get_realty_summary'][0]['est_rooms_3_studio'],
        'common_realty_est_rooms_4+': this.data['get_realty_summary'][0]['est_rooms_4+'],
        'common_realty_est_rooms_4+_apartment': this.data['get_realty_summary'][0]['est_rooms_4+_apartment'],
        'common_realty_est_rooms_4+_studio': this.data['get_realty_summary'][0]['est_rooms_4+_studio'],
        'common_realty_spn24_complex_moscow': this.data['get_realty_summary'][0]['spn24_complex_moscow'],
        'common_realty_spn24_complex_not_moscow': this.data['get_realty_summary'][0]['spn24_complex_not_moscow'],
        'common_realty_spn24_rooms_1': this.data['get_realty_summary'][0]['spn24_rooms_1'],
        'common_realty_spn24_rooms_1_apartment': this.data['get_realty_summary'][0]['spn24_rooms_1_apartment'],
        'common_realty_spn24_rooms_1_studio': this.data['get_realty_summary'][0]['spn24_rooms_1_studio'],
        'common_realty_spn24_rooms_2': this.data['get_realty_summary'][0]['spn24_rooms_2'],
        'common_realty_spn24_rooms_2_apartment': this.data['get_realty_summary'][0]['spn24_rooms_2_apartment'],
        'common_realty_spn24_rooms_2_studio': this.data['get_realty_summary'][0]['spn24_rooms_2_studio'],
        'common_realty_spn24_rooms_3': this.data['get_realty_summary'][0]['spn24_rooms_3'],
        'common_realty_spn24_rooms_3_apartment': this.data['get_realty_summary'][0]['spn24_rooms_3_apartment'],
        'common_realty_spn24_rooms_3_studio': this.data['get_realty_summary'][0]['spn24_rooms_3_studio'],
        'common_realty_spn24_rooms_4+': this.data['get_realty_summary'][0]['spn24_rooms_4+'],
        'common_realty_spn24_rooms_4+_apartment': this.data['get_realty_summary'][0]['spn24_rooms_4+_apartment'],
        'common_realty_spn24_rooms_4+_studio': this.data['get_realty_summary'][0]['spn24_rooms_4+_studio'],
        'common_realty_total_complex_moscow': this.data['get_realty_summary'][0]['total_complex_moscow'],
        'common_realty_total_complex_not_moscow': this.data['get_realty_summary'][0]['total_complex_not_moscow'],
        'common_realty_total_rooms_1': this.data['get_realty_summary'][0]['total_rooms_1'],
        'common_realty_total_rooms_1_apartment': this.data['get_realty_summary'][0]['total_rooms_1_apartment'],
        'common_realty_total_rooms_1_studio': this.data['get_realty_summary'][0]['total_rooms_1_studio'],
        'common_realty_total_rooms_2': this.data['get_realty_summary'][0]['total_rooms_2'],
        'common_realty_total_rooms_2_apartment': this.data['get_realty_summary'][0]['total_rooms_2_apartment'],
        'common_realty_total_rooms_2_studio': this.data['get_realty_summary'][0]['total_rooms_2_studio'],
        'common_realty_total_rooms_3': this.data['get_realty_summary'][0]['total_rooms_3'],
        'common_realty_total_rooms_3_apartment': this.data['get_realty_summary'][0]['total_rooms_3_apartment'],
        'common_realty_total_rooms_3_studio': this.data['get_realty_summary'][0]['total_rooms_3_studio'],
        'common_realty_total_rooms_4+': this.data['get_realty_summary'][0]['total_rooms_4+'],
        'common_realty_total_rooms_4+_apartment': this.data['get_realty_summary'][0]['total_rooms_4+_apartment'],
        'common_realty_total_rooms_4+_studio': this.data['get_realty_summary'][0]['total_rooms_4+_studio'],
        // --
      };
    }
    if (this.lock_type === 'on_month') {
      this.statistic = {
        'on_month_sale_call': this.getStatisticRow('get_lead_statistic', 'sale_call'),
        'on_month_sale_unique_check': this.getStatisticRow('get_lead_statistic', 'sale_unique_check'),
        'on_month_sale_preview': this.getStatisticRow('get_lead_statistic', 'sale_preview'),
        'on_month_sale_view': this.getStatisticRow('get_lead_statistic', 'sale_view'),
        'on_month_sale_view_processing': this.getStatisticRow('get_lead_statistic', 'sale_view_processing'),
        'on_month_sale_pre_reserve': this.getStatisticRow('get_lead_statistic', 'sale_pre_reserve'),
        'on_month_sale_reserve': this.getStatisticRow('get_lead_statistic', 'sale_reserve'),
        'on_month_sale_db': this.getStatisticRow('get_lead_statistic', 'sale_db'),
        'on_month_sale_ddu': this.getStatisticRow('get_lead_statistic', 'sale_ddu'),
        'on_month_sale_ddu_payment': this.getStatisticRow('get_lead_statistic', 'sale_ddu_payment'),
        'on_month_sale_developer_payment': this.getStatisticRow('get_lead_statistic', 'sale_developer_payment'),
        'on_month_sale_developer_docs': this.getStatisticRow('get_lead_statistic', 'sale_developer_docs'),
        'on_month_sale_subagent_docs': this.getStatisticRow('get_lead_statistic', 'sale_subagent_docs'),
        'on_month_sale_subagent_payment': this.getStatisticRow('get_lead_statistic', 'sale_subagent_payment'),
        'on_month_sale_archive': this.getStatisticRow('get_lead_statistic', 'sale_archive'),
        'on_month_total': this.getStatisticRow('get_lead_statistic', 'total'),
      };
    }

    if (this.lock_type === null) {
    } else if (this.lock_type === 'on_moment') {
      this.lineChartData = [
        {
          labels: [
            'Пресейл в работе ' + this.data['get_lead_statistic'][0]['pre_sale_leads']['percent'] + '%',
            'Пресейл - дисквалификация ' + this.data['get_lead_statistic'][0]['pre_sale_leads_fail']['percent'] + '%',
            'Сделок в работе ' + this.data['get_lead_statistic'][0]['sale_leads']['percent'] + '%',
            'Сделки - дисквалификация ' + this.data['get_lead_statistic'][0]['sale_leads_fail']['percent'] + '%',
            'Архив (СДЕЛКИ ЗАКРЫТЫ) ' + this.data['get_lead_statistic'][0]['total_complete']['percent'] + '%',
          ],
          class: 'col-md-6',
          type: 'pie',
          caption: 'Cостояние всех обращений - общее',
          value: [{
            data: [
              this.data['get_lead_statistic'][0]['pre_sale_leads']['value'],
              this.data['get_lead_statistic'][0]['pre_sale_leads_fail']['value'],
              this.data['get_lead_statistic'][0]['sale_leads']['value'],
              this.data['get_lead_statistic'][0]['sale_leads_fail']['value'],
              this.data['get_lead_statistic'][0]['total_complete']['value'],
            ]},
          ]
        },
        {
          labels: [
            'Вх./Исх. Звонок ' + this.data['get_lead_statistic'][0]['stage_call']['percent'] + '%',
            'Уникальность ' + this.data['get_lead_statistic'][0]['stage_unique']['percent'] + '%',
            'Назначение просмотра ' + this.data['get_lead_statistic'][0]['stage_preview']['percent'] + '%',
            'Подтверждение просмотра ' + this.data['get_lead_statistic'][0]['stage_view']['percent'] + '%',
            'Проведение просмотра ' + this.data['get_lead_statistic'][0]['stage_view_processing']['percent'] + '%',
            'Ожидание резерва ' + this.data['get_lead_statistic'][0]['stage_pre_reserve']['percent'] + '%',
            'Резерв ' + this.data['get_lead_statistic'][0]['stage_reserve']['percent'] + '%',
            'Дисквалификация ' + this.data['get_lead_statistic'][0]['pre_sale_leads_fail']['percent'] + '%',
          ],
          class: 'col-md-6',
          type: 'pie',
          caption: 'Cостояние обращений - пресейл',
          value: [{
            data: [
              this.data['get_lead_statistic'][0]['stage_call']['value'],
              this.data['get_lead_statistic'][0]['stage_unique']['value'],
              this.data['get_lead_statistic'][0]['stage_preview']['value'],
              this.data['get_lead_statistic'][0]['stage_view']['value'],
              this.data['get_lead_statistic'][0]['stage_view_processing']['value'],
              this.data['get_lead_statistic'][0]['stage_pre_reserve']['value'],
              this.data['get_lead_statistic'][0]['stage_reserve']['value'],
              this.data['get_lead_statistic'][0]['pre_sale_leads_fail']['value'],
            ]},
          ]
        },
        {
          labels: [
            'ДБ, Получение док-тов ' + this.data['get_lead_statistic'][0]['stage_db']['percent'] + '%',
            'ДДУ Подписание ' + this.data['get_lead_statistic'][0]['stage_ddu']['percent'] + '%',
            'ДДУ Оплата ' + this.data['get_lead_statistic'][0]['stage_ddu_payment']['percent'] + '%',
            'ПД-З Оплата ' + this.data['get_lead_statistic'][0]['stage_developer_payment']['percent'] + '%',
            'ПД-З Получена ' + this.data['get_lead_statistic'][0]['stage_developer_docs']['percent'] + '%',
            'ПД-С Получение док-тов ' + this.data['get_lead_statistic'][0]['stage_subagent_docs']['percent'] + '%',
            'ПД-С Оплата ' + this.data['get_lead_statistic'][0]['stage_subagent_payment']['percent'] + '%',
            'Архив ' + this.data['get_lead_statistic'][0]['stage_archive']['percent'] + '%',
            'Дисквалификация ' + this.data['get_lead_statistic'][0]['sale_leads_fail']['percent'] + '%',
          ],
          class: 'col-md-6',
          type: 'pie',
          caption: 'Cостояние обращений - сделка',
          value: [{
            data: [
              this.data['get_lead_statistic'][0]['stage_db']['value'],
              this.data['get_lead_statistic'][0]['stage_ddu']['value'],
              this.data['get_lead_statistic'][0]['stage_ddu_payment']['value'],
              this.data['get_lead_statistic'][0]['stage_developer_payment']['value'],
              this.data['get_lead_statistic'][0]['stage_developer_docs']['value'],
              this.data['get_lead_statistic'][0]['stage_subagent_docs']['value'],
              this.data['get_lead_statistic'][0]['stage_subagent_payment']['value'],
              this.data['get_lead_statistic'][0]['stage_archive']['value'],
              this.data['get_lead_statistic'][0]['sale_leads_fail']['value'],
            ]},
          ]
        },
      ];
    } else if (this.lock_type === 'on_month') {
      this.lineChartData = [
        {
          labels: this.lineChartLabels,
          class: 'col-md-12',
          type: 'line',
          caption: 'Динамика по составу новых обращений помесячно (на конец месяца)',
          value: [
            {fill: false, data: this.lineReplace(this.statistic['on_month_sale_call']), label: 'Вх./Исх. Звонок'},
            {fill: false, data: this.lineReplace(this.statistic['on_month_sale_unique_check']), label: 'Проверка Уникальности'},
            {fill: false, data: this.lineReplace(this.statistic['on_month_sale_preview']), label: 'Назначение просмотра'},
            {fill: false, data: this.lineReplace(this.statistic['on_month_sale_view']), label: 'Подтверждение просмотра'},
            {fill: false, data: this.lineReplace(this.statistic['on_month_sale_view_processing']), label: 'Проведение просмотра'},
            {fill: false, data: this.lineReplace(this.statistic['on_month_sale_pre_reserve']), label: 'Ожидание резерва'},
            {fill: false, data: this.lineReplace(this.statistic['on_month_sale_reserve']), label: 'Резерв'},
            {fill: false, data: this.lineReplace(this.statistic['on_month_sale_db']), label: 'ДБ, Получение док-тов'},
            {fill: false, data: this.lineReplace(this.statistic['on_month_sale_ddu']), label: 'ДДУ Подписание '},
            {fill: false, data: this.lineReplace(this.statistic['on_month_sale_ddu_payment']), label: 'ДДУ Оплата '},
            {fill: false, data: this.lineReplace(this.statistic['on_month_sale_developer_payment']), label: 'ПД-З Оплата '},
            {fill: false, data: this.lineReplace(this.statistic['on_month_sale_developer_docs']), label: 'ПД-З Получена '},
            {fill: false, data: this.lineReplace(this.statistic['on_month_sale_subagent_docs']), label: 'ПД-С Получение док-тов '},
            {fill: false, data: this.lineReplace(this.statistic['on_month_sale_subagent_payment']), label: 'ПД-С Оплата '},
            {fill: false, data: this.lineReplace(this.statistic['on_month_sale_archive']), label: 'Архив'},
          ]
        },
      ];
    } else if (this.lock_type === 'common_reserved') {
      this.lineChartData = [
        {
          labels: this.lineChartLabels,
          class: 'col-md-12',
          type: 'line',
          caption: 'Пресейл',
          value: [
            {fill: false, data: this.lineReplace(this.statistic['appeals_cnt_sale_call']), label: 'Вх./Исх. Звонок'},
            {fill: false, data: this.lineReplace(this.statistic['reserved_check_unique']), label: 'Проверка Уникальности'},
            {fill: false, data: this.lineReplace(this.statistic['reserved_views_subscribe']), label: 'Назначение просмотра'},
            {fill: false, data: this.lineReplace(this.statistic['reserved_views_success']), label: 'Проведение просмотра'},
            {fill: false, data: this.lineReplace(this.statistic['appeals_cnt_sale_pre_reserve']), label: 'Ожидание резерва'},
            {fill: false, data: this.lineReplace(this.statistic['reserved_on_month']), label: 'Ожидание ДБ'},
            {fill: false, data: this.lineReplace(this.statistic['reserved_pre_sale_stages_fail']), label: 'Кол-во расторжений - пресейл'},
          ]
        },
        {
          labels: this.lineChartLabels,
          class: 'col-md-12',
          type: 'line',
          caption: 'Сделка',
          value: [
            {fill: false, data: this.lineReplace(this.statistic['reserved_successed_month']), label: 'ДБ, Получение док-тов'},
            {fill: false, data: this.lineReplace(this.statistic['appeals_cnt_sale_ddu']), label: 'ДДУ Подписание'},
            {fill: false, data: this.lineReplace(this.statistic['appeals_cnt_sale_ddu_payment']), label: 'ДДУ Оплата'},
            {fill: false, data: this.lineReplace(this.statistic['appeals_cnt_sale_developer_payment']), label: 'ПД-З Оплата'},
            {fill: false, data: this.lineReplace(this.statistic['appeals_cnt_sale_developer_docs']), label: 'ПД-З Получена'},
            {fill: false, data: this.lineReplace(this.statistic['appeals_cnt_sale_subagent_docs']), label: 'ПД-С Получение док-тов'},
            {fill: false, data: this.lineReplace(this.statistic['appeals_cnt_sale_subagent_payment']), label: 'ПД-С Оплата'},
            {fill: false, data: this.lineReplace(this.statistic['reserved_success_deals']), label: 'Архив'},
            {fill: false, data: this.lineReplace(this.statistic['reserved_sale_stages_fail']), label: 'Кол-во расторжений сделка'},
          ]
        },
      ];
    } else if (this.lock_type === 'common_events') {
      this.lineChartData = [
        {
          labels: this.lineChartLabels,
          class: 'col-md-8',
          type: 'line',
          caption: 'Сотрудники на мероприятиях всех типов',
          value: [
            {fill: false, data: this.lineReplace(this.statistic['calendar_staff_events_visit']), label: 'Посетившие все типы обучения'},
            {fill: false, data: this.lineReplace(this.statistic['calendar_staff_events_not_visit']), label: 'Отсуствующие на обучение'},
          ]
        },
        {
          labels: ['Посетившие', 'Отсуствующие'],
          class: 'col-md-4',
          type: 'pie',
          caption: 'Общие данные',
          value: [{
            data: [
              this.summArray(this.statistic['calendar_staff_events_visit']),
              this.summArray(this.statistic['calendar_staff_events_not_visit'])
            ]},
          ]
        },
        {
          labels: this.lineChartLabels,
          class: 'col-md-8',
          type: 'line',
          caption: 'Юр. лица на мероприятиях всех типов',
          value: [
            {fill: false, data: this.lineReplace(this.statistic['calendar_orgs_events_visit']), label: 'Посетившие все типы обучения'},
            {fill: false, data: this.lineReplace(this.statistic['calendar_orgs_events_not_visit']), label: 'Отсуствующие на обучение'},
          ]
        },
        {
          labels: ['Посетившие', 'Отсуствующие'],
          class: 'col-md-4',
          type: 'pie',
          caption: 'Общие данные',
          value: [{
            data: [
              this.summArray(this.statistic['calendar_orgs_events_visit']),
              this.summArray(this.statistic['calendar_orgs_events_not_visit'])
            ]},
          ]
        },
        {
          labels: this.lineChartLabels,
          class: 'col-md-8',
          type: 'line',
          caption: 'Сотрудники на обучающих семинарах',
          value: [
            {fill: false, data: this.lineReplace(this.statistic['calendar_user_visit_training']), label: 'Посетившие семинары'},
            {fill: false, data: this.lineReplace(this.statistic['calendar_user_not_visit_training']), label: 'Отсуствующие на семинарах'},
          ]
        },
        {
          labels: ['Посетившие', 'Отсуствующие'],
          class: 'col-md-4',
          type: 'pie',
          caption: 'Общие данные',
          value: [{
            data: [
              this.summArray(this.statistic['calendar_orgs_events_visit']),
              this.summArray(this.statistic['calendar_orgs_events_not_visit'])
            ]},
          ]
        },
        {
          labels: this.lineChartLabels,
          class: 'col-md-8',
          type: 'line',
          caption: 'Юр. лица на обучающих семинарах',
          value: [
            {fill: false, data: this.lineReplace(this.statistic['calendar_org_visit_training']), label: 'Посетившие семинары'},
            {fill: false, data: this.lineReplace(this.statistic['calendar_org_not_visit_training']), label: 'Отсуствующие на семинарах'},
          ]
        },
        {
          labels: ['Посетившие', 'Отсуствующие'],
          class: 'col-md-4',
          type: 'pie',
          caption: 'Общие данные',
          value: [{
            data: [
              this.summArray(this.statistic['calendar_org_visit_training']),
              this.summArray(this.statistic['calendar_org_not_visit_training'])
            ]},
          ]
        },
        {
          labels: this.lineChartLabels,
          class: 'col-md-8',
          type: 'line',
          caption: 'Сотрудники на вебинарах',
          value: [
            {fill: false, data: this.lineReplace(this.statistic['calendar_user_visit_webinar']), label: 'Посетившие вебинары'},
            {fill: false, data: this.lineReplace(this.statistic['calendar_user_not_visit_webinar']), label: 'Отсуствующие на вебинарах'},
          ]
        },
        {
          labels: ['Посетившие', 'Отсуствующие'],
          class: 'col-md-4',
          type: 'pie',
          caption: 'Общие данные',
          value: [{
            data: [
              this.summArray(this.statistic['calendar_user_visit_webinar']),
              this.summArray(this.statistic['calendar_user_not_visit_webinar'])
            ]},
          ]
        },
        {
          labels: this.lineChartLabels,
          class: 'col-md-8',
          type: 'line',
          caption: 'Юр. лица на вебинарах',
          value: [
            {fill: false, data: this.lineReplace(this.statistic['calendar_org_visit_webinar']), label: 'Посетившие вебинары'},
            {fill: false, data: this.lineReplace(this.statistic['calendar_org_not_visit_webinar']), label: 'Отсуствующие на вебинарах'},
          ]
        },
        {
          labels: ['Посетившие', 'Отсуствующие'],
          class: 'col-md-4',
          type: 'pie',
          caption: 'Общие данные',
          value: [{
            data: [
              this.summArray(this.statistic['calendar_org_visit_webinar']),
              this.summArray(this.statistic['calendar_org_not_visit_webinar'])
            ]},
          ]
        },
        {
          labels: this.lineChartLabels,
          class: 'col-md-8',
          type: 'line',
          caption: 'Сотрудники на Инфо-тур/Брокер-тур',
          value: [
            {fill: false, data: this.lineReplace(this.statistic['calendar_user_visit_info_tour']),
              label: 'Посетившие мероприятия'},
            {fill: false, data: this.lineReplace(this.statistic['calendar_user_not_visit_info_tour']),
              label: 'Отсуствующие на мероприятиях'},
          ]
        },
        {
          labels: ['Посетившие', 'Отсуствующие'],
          class: 'col-md-4',
          type: 'pie',
          caption: 'Общие данные',
          value: [{
            data: [
              this.summArray(this.statistic['calendar_user_visit_info_tour']),
              this.summArray(this.statistic['calendar_user_not_visit_info_tour'])
            ]},
          ]
        },
        {
          labels: this.lineChartLabels,
          class: 'col-md-8',
          type: 'line',
          caption: 'Юр. лица на Инфо-тур/Брокер-тур',
          value: [
            {fill: false, data: this.lineReplace(this.statistic['calendar_org_visit_info_tour']),
              label: 'Посетившие мероприятия'},
            {fill: false, data: this.lineReplace(this.statistic['calendar_org_not_visit_info_tour']),
              label: 'Отсуствующие на мероприятиях'},
          ]
        },
        {
          labels: ['Посетившие', 'Отсуствующие'],
          class: 'col-md-4',
          type: 'pie',
          caption: 'Общие данные',
          value: [{
            data: [
              this.summArray(this.statistic['calendar_org_visit_info_tour']),
              this.summArray(this.statistic['calendar_org_not_visit_info_tour'])
            ]},
          ]
        },
        {
          labels: this.lineChartLabels,
          class: 'col-md-8',
          type: 'line',
          caption: 'Сотрудники на Встрече с застройщиком',
          value: [
            {fill: false, data: this.lineReplace(this.statistic['calendar_user_visit_meeting_developer']),
              label: 'Посетившие мероприятия'},
            {fill: false, data: this.lineReplace(this.statistic['calendar_user_not_visit_meeting_developer']),
              label: 'Отсуствующие на мероприятиях'},
          ]
        },
        {
          labels: ['Посетившие', 'Отсуствующие'],
          class: 'col-md-4',
          type: 'pie',
          caption: 'Общие данные',
          value: [{
            data: [
              this.summArray(this.statistic['calendar_user_visit_meeting_developer']),
              this.summArray(this.statistic['calendar_user_not_visit_meeting_developer'])
            ]},
          ]
        },
        {
          labels: this.lineChartLabels,
          class: 'col-md-8',
          type: 'line',
          caption: 'Юр. лица на Встрече с застройщиком',
          value: [
            {fill: false, data: this.lineReplace(this.statistic['calendar_org_visit_meeting_developer']),
              label: 'Посетившие мероприятия'},
            {fill: false, data: this.lineReplace(this.statistic['calendar_org_not_visit_meeting_developer']),
              label: 'Отсуствующие на мероприятиях'},
          ]
        },
        {
          labels: ['Посетившие', 'Отсуствующие'],
          class: 'col-md-4',
          type: 'pie',
          caption: 'Общие данные',
          value: [{
            data: [
              this.summArray(this.statistic['calendar_org_visit_meeting_developer']),
              this.summArray(this.statistic['calendar_org_not_visit_meeting_developer'])
            ]},
          ]
        },
      ];
    } else if (this.lock_type === 'common_attraction') {
      this.lineChartData = [
        {
          labels: this.lineChartLabels,
          class: 'col-md-12',
          type: 'line',
          caption: 'Активность лидов по привлечению',
          colors: [
            {
              backgroundColor: 'rgb(245, 185, 34, 0.4)',
              borderColor: 'rgb(245, 185, 34)',
              pointBackgroundColor: 'rgb(245, 185, 34)',
            },
            {
              backgroundColor: 'rgb(68, 211, 81, 0.4)',
              borderColor: 'rgb(68, 211, 81)',
              pointBackgroundColor: 'rgb(68, 211, 81)',
            },
            {
              backgroundColor: 'rgb(221, 42, 0, 0.4)',
              borderColor: 'rgb(221, 42, 0)',
              pointBackgroundColor: 'rgb(221, 42, 0)',
            },
          ],
          value: [
            {fill: false, data: this.lineReplace(this.statistic['attraction_cnt_org_register']), label: 'Первичная регистрация пользователя субагента (Пользователь нажал на кнопку регистрации на landing page)'},
            {fill: false, data: this.lineReplace(this.statistic['attraction_cnt_org_approve']), label: 'Подтверждение субагента менеджером (Подтверждение субагента произошло, субагент наделен полными правами)'},
            {fill: false, data: this.lineReplace(this.statistic['attraction_cnt_org_fail']), label: 'Отклонение регистрации субагента менеджером'},
          ]
        },
        {
          labels: this.lineChartLabels,
          class: 'col-md-12',
          type: 'line',
          caption: 'Всего субагентских организаций',
          colors: [
            {
              backgroundColor: 'rgb(54, 162, 235, 0.4)',
              borderColor: 'rgb(54, 162, 235)',
              pointBackgroundColor: 'rgb(54, 162, 235)',
            },
            {
              backgroundColor: 'rgb(68, 211, 81, 0.4)',
              borderColor: 'rgb(68, 211, 81)',
              pointBackgroundColor: 'rgb(68, 211, 81)',
            },
          ],
          value: [
            {fill: false, data: this.lineReplace(this.statistic['attraction_cnt_org_register_summary']), label: 'Кол-во Субагентов нарастающим итогом'},
            {fill: false, data: this.lineReplace(this.statistic['attraction_cnt_active_subagents']), label: 'Кол-во активных субагентов по БП'},
          ],
        },
        {
          labels: this.lineChartLabels,
          class: 'col-md-12',
          type: 'line',
          caption: 'Сотрудники субагентов',
          colors: [
            {
              backgroundColor: 'rgb(68, 211, 81, 0.4)',
              borderColor: 'rgb(68, 211, 81)',
              pointBackgroundColor: 'rgb(68, 211, 81)',
            },
            {
              backgroundColor: 'rgb(54, 162, 235, 0.4)',
              borderColor: 'rgb(54, 162, 235)',
              pointBackgroundColor: 'rgb(54, 162, 235)',
            },
          ],
          value: [
            {fill: false, data: this.lineReplace(this.statistic['attraction_cnt_new_workers']), label: 'Кол-во новых сотрудников'},
            {fill: false, data: this.lineReplace(this.statistic['attraction_cnt_workers_summary']), label: 'Кол-во сотрудников, нарастающим итогом'},
          ],
        },
      ];
    } else if (this.lock_type === 'common_realty') {
      this.lineChartData = [
          {
            labels: [
              'Estatet ' + (100 * this.statistic['common_realty_est_complex']
                / (this.statistic['common_realty_spn24_complex'] + this.statistic['common_realty_est_complex']))
                .toFixed(2) + '%',
              'Spn24 ' + (100 * this.statistic['common_realty_spn24_complex']
                / (this.statistic['common_realty_spn24_complex'] + this.statistic['common_realty_est_complex']))
                .toFixed(2) + '%',
            ],
            class: 'col-md-6',
            type: 'pie',
            caption: 'Комплексов',
            value: [{
                data: [
                  this.statistic['common_realty_est_complex'],
                  this.statistic['common_realty_spn24_complex'],
                ]},
            ]
          },
          {
            labels: [
              'Estatet ' + (100 * this.statistic['common_realty_est_housings']
                / (this.statistic['common_realty_spn24_housings'] + this.statistic['common_realty_est_housings']))
                .toFixed(2) + '%',
              'Spn24 ' + (100 * this.statistic['common_realty_spn24_housings']
                / (this.statistic['common_realty_spn24_housings'] + this.statistic['common_realty_est_housings']))
                .toFixed(2) + '%',
            ],
            class: 'col-md-6',
            type: 'pie',
            caption: 'Корпусов',
            value: [{
                data: [
                  this.statistic['common_realty_est_housings'],
                  this.statistic['common_realty_spn24_housings']
                ]},
            ]
          },
          {
            labels: [
              'Estatet ' + (100 * this.statistic['common_realty_est_flats'] / (this.statistic['common_realty_spn24_flats'] + this.statistic['common_realty_est_flats'])).toFixed(2) + '%',
              'Spn24 ' + (100 * this.statistic['common_realty_spn24_flats'] / (this.statistic['common_realty_spn24_flats'] + this.statistic['common_realty_est_flats'])).toFixed(2) + '%',
            ],
            class: 'col-md-6',
            type: 'pie',
            caption: 'Квартир',
            value: [{
                data: [
                  this.statistic['common_realty_est_flats'],
                  this.statistic['common_realty_spn24_flats']
                ]},
            ]
          },
          {
            labels: [
              'Estatet ' + (100 * this.statistic['common_realty_est_square']
                / (this.statistic['common_realty_spn24_square'] + this.statistic['common_realty_est_square']))
                .toFixed(2) + '%',
              'Spn24 ' + (100 * this.statistic['common_realty_spn24_square']
                / (this.statistic['common_realty_spn24_square'] + this.statistic['common_realty_est_square']))
                .toFixed(2) + '%',
            ],
            class: 'col-md-6',
            type: 'pie',
            caption: 'Общая площадь помещений',
            value: [{
                data: [
                  this.statistic['common_realty_est_square'],
                  this.statistic['common_realty_spn24_square']
                ]},
            ]
          },
        ];
    } else if (this.lock_type === 'on_period') {
      const labels = [];
      const values = [];
      const dict = {
        'sale_call': 'Вх./Исх. Звонок',
        'sale_unique_check': 'Проверка Уникальности',
        'sale_preview': 'Назначение просмотра',
        'sale_view': 'Подтверждение просмотра',
        'sale_view_processing': 'Проведение просмотра',
        'sale_pre_reserve': 'Ожидание резерва',
        'sale_reserve': 'Резерв',
        'sale_db': 'ДБ, Получение док-тов',
        'sale_ddu': 'ДДУ Подписание ',
        'sale_ddu_payment': 'ДДУ Оплата ',
        'sale_developer_payment': 'ПД-З Оплата ',
        'sale_developer_docs': 'ПД-З Получена ',
        'sale_subagent_docs': 'ПД-С Получение док-тов ',
        'sale_subagent_payment': 'ПД-С Оплата ',
        'sale_archive': 'Архив',
      };
      for (const [key, value] of Object.entries(this.data['get_lead_statistic'][0])) {
        if (value['value']) {
          labels.push(dict[key] + ' ' + value['percent'] + '%');
          values.push(value['value']);
        }
      }

      this.lineChartData = [
        {
          labels: labels,
          class: 'col-md-6',
          type: 'pie',
          caption: 'Состав новых уникальных обращений',
          value: [{
            data: values
          }]
        },
      ];

    }


  }



  private getMonthsInterval() {
    let intervals = [];

    for (const key of Object.keys(this.table_keys)) {
      if (!this.data[key]) {
        continue;
      }
      intervals.push(...this.data[key]);
    }

    intervals = intervals
      .map(x => moment(x['the_date'], this.date_format).unix())
      .sort((a, b) => a - b);

    const unique_months = new Set<any>();
    const from$ = moment.unix(intervals[0]);
    const to$ = moment.unix(intervals[intervals.length - 1]);

    let end = 0;
    while (
        from$.unix() < to$.unix() || (from$.format('MMM') === to$.format('MMM')
        && from$.format('YYYY') === to$.format('YYYY')) && end < 100
      ) {
      if (unique_months.has(from$.format(this.date_format))) {
        continue;
      }

      unique_months.add(from$.format(this.date_format));
      from$.add(1, 'month');
      end++;
    }

    return Array.from(unique_months);
  }

  private getStatisticRow(key, subkey) {
    const data = [];
    const observe = this.data[key];
    for (const month of this.months) {
      const target = observe.filter(x => x['the_date'] === month).pop();
      if (target) {
        data.push(target[subkey]);
      } else {
        data.push('-');
      }
    }
    return data;

  }

  private lineReplace(arr) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === '-' || arr[i] === '' || !arr[i]) {
        arr[i] = 0;
      }
    }
    return arr;
  }
  private summArray(arr) {
    let total = 0;
    for (const el of arr) {
      total += el;
    }
    return total;
  }

  ngOnDestroy() {
    if (this.onDestroy) {
      this.onDestroy.next(false);
      this.onDestroy.unsubscribe();
    }
  }
  ngAfterViewInit() {
    this.goBack.goScroll();
  }
}
