<div class="table-responsive mb-4 min-height-auto">
  <table class="table min-w-500">
    <tbody>
      <tr *ngFor="let file of files; let i = index">
        <td class="td-img">
          <div class="button-picture">
            <a href="javascript: void(0)" (click)="lightbox.open([{'src': upload_url + file.filepath, 'thumb': upload_url + file.preview}], 0)"
               *ngIf="file.preview">
              <img [src]="upload_url + file.filepath" class="button-picture__img no-auto-zoom-in" alt="">
            </a>
            <a [href]="upload_url + file.filepath" *ngIf="!file.preview">
              <img [src]="'/assets_common/images/no_img.svg'" class="button-picture__img no-auto-zoom-in" alt="">
            </a>
            <a class="button-picture__icon" target="_blank" [href]="upload_url + file.filepath"></a>
            <a [href]="upload_url + file.filepath" target="_blank" class="button-picture__url" *ngIf="!file.preview"></a>
          </div>
        </td>
        <td class="">
          <div class="form__group">
            <div class="form__label" title="{{file['filename']}}">
              {{file['filename']}}
            </div>
            <div class="form__input" [class.form__input_disabled]="disable"  *ngIf="hasComments">
              <input [type]="'text'" class="input" [value]="file.comment" [disabled]="disable"
                     (keyup)="onCommentChange($event.target.value, file.filepath)"
                     [ngClass]="{'md-valid': file.comment && (file.comment.length || file.comment > 0)}"
                     [placeholder]="(!disable) ? 'Описание' : ''">
            </div>
          </div>
        </td>
        <td class="td-button" *ngIf="!disable">
          <button type="button" (click)="removeFile(file.filepath)" class="button">Удалить</button>
        </td>
      </tr>
    </tbody>
  </table>

</div>
