import {Component, Input} from '@angular/core';

@Component({
  templateUrl: './all-events-participants.component.html',
  styleUrls: ['./all-events-participants.component.scss']
})
export class AllEventsParticipantsComponent {

  @Input('event_id') public event_id;

}
