import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DataTableParamComponent } from '../../../../modules/@datatable/components/datatable-param.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpClient } from '../../../../services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../../services/notification.service';
import {ImportEditService} from '../import-edit.service';

@Component({
  templateUrl: './import-edit-history.component.html',
  styleUrls: ['./import-edit-history.component.scss']
})
export class ImportEditHistoryComponent extends DataTableParamComponent implements OnInit, OnDestroy {

  public api_action = 'Import.dt_logs';
  public columns: Array<{ [key: string]: any }>;
  public formGroup: UntypedFormGroup;
  public createSubscription: Subscription;
  public feed_id: any;

  @ViewChild('processTmpl', {static: true}) private processTmpl: TemplateRef<any>;
  @ViewChild('rejectTmpl', {static: true}) private rejectTmpl: TemplateRef<any>;
  @ViewChild('modifyTmpl', {static: true}) private modifyTmpl: TemplateRef<any>;
  @ViewChild('deleteTmpl', {static: true}) private deleteTmpl: TemplateRef<any>;
  @ViewChild('addTmpl', {static: true}) private addTmpl: TemplateRef<any>;
  @ViewChild('statusTmpl', {static: true}) private statusTmpl: TemplateRef<any>;
  @ViewChild('excelTmpl', {static: true}) private excelTmpl: TemplateRef<any>;
  @ViewChild('errorTmpl', {static: true}) private errorTmpl: TemplateRef<any>;

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router,
              public fb: UntypedFormBuilder, public notify: NotificationService, private importEditService: ImportEditService) {
    super(http, route, router);
    this.feed_id = this.route.snapshot.parent.params['id'];
  }

  ngOnInit() {
    this.columns = [
      {'caption': 'Обновление от', 'name': 'time', 'sortable': true},
      {'caption': 'Статус', 'name': '', 'sortable': false, 'template': this.statusTmpl},
      {'caption': 'Обработано', 'name': '', 'sortable': false, 'template': this.processTmpl},
      {'caption': 'Изменения', 'name': '', 'sortable': false, 'template': this.modifyTmpl},
      {'caption': 'Ошибки', 'name': '', 'sortable': false, 'template': this.errorTmpl},
      // {'caption': 'Добавлено', 'name': '', 'sortable': false, 'template': this.addTmpl},
      // {'caption': 'Отклонено', 'name': '', 'sortable': false, 'template': this.rejectTmpl},
      // {'caption': 'Удалено', 'name': '', 'sortable': false, 'template': this.deleteTmpl},
      {'caption': 'Отчет', 'name': '', 'sortable': false, 'template': this.excelTmpl, 'width': '100px'},
    ];
    super.ngOnInit();
  }

  public prepareQuery() {
    const query = super.prepareQuery();
    query['setting_id'] = this.feed_id;

    return query;
  }

  public loadList() {
    super.loadList();
  }

  saveForm() {
    this.router.navigateByUrl('/import');
  }

  ngOnDestroy() {
    if (this.createSubscription) {
      this.createSubscription.unsubscribe();
    }
  }

  onLogFileLoadClick(logId, logTime) {
    NotificationService.swalConfirm('Вы уверены?', 'Все объекты данного фида вернуться к неактуальному ' +
      'состоянию на дату ' + logTime + '!')
      .then(() => {
        this.importEditService.logFileLoad(logId);
      }).catch(() => false);
  }

}
