import {Component, forwardRef, Input, OnDestroy} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-input-fias-event',
  templateUrl: './material-input-fias-address-event.component.html',
  styleUrls: ['./material-input-fias-address-event.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialInputFiasAddressEventComponent),
      multi: true
    }
  ]
})
export class MaterialInputFiasAddressEventComponent implements ControlValueAccessor {

  @Input('required') public required = false;
  @Input('error') public error = false;
  @Input('placeholder') public placeholder = '';
  public disabled: boolean;

  private _current_value;

  public keyUp = new Subject();

  public get current_value(): any {
    return this._current_value || {};
  }

  public set current_value(value: any) {
    this._current_value = value;
    this.propagateChange(this._current_value);
  }

  constructor() { }

  propagateChange = (_: any) => {};

  onChange(value: any) {
    this.current_value = value;
  }

  writeValue(value: any) {
    this.current_value = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
  }

  onChanges(target) {
    this.current_value = target;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
