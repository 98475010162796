import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import { DataTableComponent } from '../../../modules/@datatable/components/datatable.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HttpClient } from '../../../services/http.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-settings-constraints-table',
  templateUrl: './settings-constraints-table.component.html',
  styleUrls: ['./settings-constraints-table.component.scss']
})
export class SettingsConstraintsTableComponent extends DataTableComponent implements OnInit, OnDestroy {

  public api_action = 'Data_tables.get_constrains_list';
  public form: UntypedFormGroup;

  private subscription: Subscription = new Subscription();

  constructor(public http: HttpClient, private fb: UntypedFormBuilder) {
    super(http);
  }

  @Input() whenDelete: EventEmitter<void>;

  prepareQuery() {
    const query = super.prepareQuery();

    if (this.form) {
      query['filter']['global_search_string'] = this.form.value['search_string'];
      query['filter']['complex_or_developer_caption'] = this.form.value['complex_or_developer_caption'];
    }

    return query;
  }

  ngOnInit() {
    super.ngOnInit();

    this.form = this.fb.group({
      'search_string': [''],
      'complex_or_developer_caption': [''],
    });

    this.subscription.add(this.form.valueChanges
      .pipe(debounceTime(500)).subscribe(value => this.loadList()));
    this.subscription.add(this.whenDelete.subscribe(_ => this.loadList()));
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.subscription.unsubscribe();
  }

}
