import {Component} from '@angular/core';


@Component({
  templateUrl: './wrap-tabs.component.html',
  styleUrls: [
    './wrap-tabs.component.scss'
  ]
})
export class MassWrapTabsComponent {

  constructor() { }

}
