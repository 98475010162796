import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BreadcrumbsService} from '../../../../shared/breadcrumbs/services';
import {GoBackButtonService} from '../../../../services/go-back-button.service';
import {Subscription} from 'rxjs';
import {ReportComponent} from '../report/report.component';
import {AuthenticateService} from '../../../../services/authenticate.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {JsonClientService} from '../../../../shared/json-client';
import {NotificationService} from '../../../../services/notification.service';
import {ReportCommonComponent} from '../report-common/report-common.component';

declare const moment;
import {environment} from '../../../../../environments/environment';

@Component({
  templateUrl: './get-activity.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: [
    './get-activity.component.scss'
  ]
})
export class GetActivityComponent extends ReportComponent implements OnInit, OnDestroy, AfterViewInit {

  public server = environment.apiServer;
  public routerSubscription: Subscription = null;
  public subscribtionUser: Subscription = null;

  public formGroup: UntypedFormGroup;
  public data: any;
  public org: any;
  public url_segment = null;
  public user = null;
  public activeDropdown = false;
  public date_options = {};
  public rightWindow = false;
  public rightWindowData = {};
  public preloader = true;
  public rightWindowComment = {
    'comment': false,
    'id': null,
    'employee_id': null,
  };
  public rightWindowListData = {};

  public summStatisticList = {};

  constructor(public r: Router,
              public a: ActivatedRoute,
              public b: BreadcrumbsService,
              public goBack: GoBackButtonService,
              private auth: AuthenticateService,
              private fb: UntypedFormBuilder,
              private j: JsonClientService,
              private cd: ChangeDetectorRef,
              private notify: NotificationService) {
    super(r, a, b);
  }

  public get base_url() {
    return '/analytic/' + this.url_segment + '/';
  }

  public get filter_url() {
    const a = [this.base_url];

    if (this.url_segment === 'report-get-sub-user-activity') {
      if (this.a.snapshot.params['subagent_id']) {
        a.push(this.a.snapshot.params['subagent_id']);
      }
    }

    if (this.a.snapshot.params['fact_type']) {
      a.push(this.a.snapshot.params['fact_type']);
    }

    if (this.a.snapshot.params['result_type']) {
      a.push(this.a.snapshot.params['result_type']);
    }

    if (this.url_segment === 'report-get-complex-activity') {
      if (this.a.snapshot.params['complex_type']) {
        a.push(this.a.snapshot.params['complex_type']);
      }

      if (this.a.snapshot.params['events']) {
        a.push(this.a.snapshot.params['events']);
      }

      if (this.a.snapshot.params['vitrina']) {
        a.push(this.a.snapshot.params['vitrina']);
      }
    }


    if (this.a.snapshot.params['start']) {
      a.push(this.a.snapshot.params['start']);
    }

    if (this.a.snapshot.params['stop']) {
      a.push(this.a.snapshot.params['stop']);
    }

    if (this.a.snapshot.params['sub_date_from']) {
      a.push(this.a.snapshot.params['sub_date_from']);
    }

    return a.join('/');
  }

  ngOnInit() {
    super.ngOnInit();
    this.formGroup = this.fb.group({
      'only_this_report': ['', Validators.required],
      'time_of_comment': ['', Validators.required],
      'color_active_status': ['green', Validators.required],
      'work_with_system_spn': [''],
      'work_with_system_status': [''],
      'service_staff': [''],
      'service_staff_status': [''],
      'developer_service': [''],
      'developer_service_status': [''],
      'payments': [''],
      'payments_status': [''],
      'comment': ['', Validators.required],
    });
    this.url_segment = this.a.snapshot.url[0].path;

    this.subscribtionUser = this.auth.emit_user.subscribe((user) => this.user = user);
    this.sort_direction = this.a.snapshot.queryParams['sort_direction'] || 'desc';
    this.sort_field = this.a.snapshot.queryParams['sort_field'] || null;
    this.data = ReportCommonComponent
      .resort(this.a.snapshot.data['data'], this.sort_field, this.sort_direction);
    this.org = this.a.snapshot.data['org'];

    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/analytic/index', caption: 'Аналитика'},
    ]);

    let naming = '';
    if (this.url_segment === 'report-get-sub-activity') {
      naming = 'Активность субагентских организаций';
    } else if (this.url_segment === 'report-get-complex-activity') {
      naming = 'Активность ЖК';
      this.summStatistic();
    } else if (this.url_segment === 'report-get-sub-user-activity') {
      naming = this.data.organisation_caption;
    }

    this.goBack.header = '<h2>' + naming + '</h2>';

    this.date_options['date_start'] = this.a.snapshot.params['start'] || moment().startOf('year').format('DD.MM.YYYY');
    this.date_options['date_end'] = this.a.snapshot.params['stop'] || moment().endOf('month').format('DD.MM.YYYY');
    this.preloader = false;
    this.routerSubscription = this.r.events.subscribe((event) => {
      this.preloader = true;
      if (event instanceof NavigationEnd) {
        this.goBack.header = '<h2>' + naming + '</h2>';
        this.preloader = false;
        if (!this.cd['destroyed']) {
          this.cd.detectChanges();
        }
      }
    });
  }

  whenUrlChange() {
    super.whenUrlChange();
    this.date_options['date_start'] = this.a.snapshot.params['start'] || moment().startOf('year').format('DD.MM.YYYY');
    this.date_options['date_end'] = this.a.snapshot.params['stop'] || moment().endOf('month').format('DD.MM.YYYY');
    this.data = ReportCommonComponent
      .resort(this.a.snapshot.data['data'], this.sort_field, this.sort_direction);
    this.org = this.a.snapshot.data['org'];
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/analytic/index', caption: 'Аналитика'},
    ]);
    this.summStatistic();

    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.subscribtionUser) {
      this.subscribtionUser.unsubscribe();
    }
    if (!this.cd['destroyed']) {
      this.cd.detach();
    }
  }
  rightWindowOpen(caption, id, data, employee = false) {
    this.rightWindowErase();
    if (!employee) {
      this.rightWindow = true;
      this.rightWindowData = {
        'caption': caption,
        'data': data,
        'id': id,
      };
    } else {
      const start = this.a.snapshot.params['stop'] ? moment(this.a.snapshot.params['stop'], 'DD.MM.YYYY') : moment();

      const options = {
        'date_start': start
          .subtract(1, 'months').startOf('month').format('DD.MM.YYYY'),
        'date_end': this.a.snapshot.params['stop'] || moment()
          .endOf('month').format('DD.MM.YYYY'),
        'fact_type': this.a.snapshot.params['fact_type'] || '',
        'result_type': 'months',
        'sub_date_from': this.a.snapshot.params['sub_date_from'] || '',
        'subagent_id': employee,
      };
      this.j.post('Analytics.get_sub_activity', options).then(res => {
        this.rightWindow = true;
        this.rightWindowData = {
          'caption': caption,
          'data': data,
          'id': id,
          'employee': res,
        };
      });
    }
  }
  addComent(id, employee_id = null) {
    this.rightWindowErase();
    this.rightWindow = true;
    this.rightWindowComment = {
      'comment': true,
      'id': id,
      'employee_id': employee_id,
    };
    this.formGroup.patchValue({
      'only_this_report': 'true',
      'time_of_comment': new DatePipe('en-US').transform(Date.now(), 'dd.MM.yyyy'),
      'work_with_system_spn': '',
      'work_with_system_status': '',
      'service_staff': '',
      'service_staff_status': '',
      'developer_service': '',
      'developer_service_status': '',
      'payments': '',
      'payments_status': '',
      'color_active_status': 'green',
      'comment': '',
    });
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }
  rightWindowCommentList(caption, data) {
    this.rightWindowErase();
    this.rightWindow = true;
    this.rightWindowListData = {
      'caption': caption,
      'data': data,
    };
  }
  commentListLoad(id, caption, employee_id = null) {
    this.j.post('Analytics.comment_list', {
      'organisation_id': id,
      'fact_type': this.a.snapshot.params['fact_type'],
      'employee_id': employee_id,
    }).then(x => {
      this.rightWindowErase();
      this.rightWindow = true;
      this.rightWindowListData = {
        'caption': caption,
        'data': x,
      };
      if (!this.cd['destroyed']) {
        this.cd.detectChanges();
      }
    }, error => { });
  }
  rightWindowClose() {
    this.rightWindow = false;
    this.rightWindowErase();
  }
  rightWindowErase() {
    this.rightWindowData = {};
    this.rightWindowComment = {
      'comment': false,
      'id': null,
      'employee_id': null,
    };
    this.rightWindowListData = {};
  }
  saveForm() {
    this.j.post('Report.add_comment', {
      'organisation_id': this.rightWindowComment.id,
      'employee_id': this.rightWindowComment.employee_id,
      'report_locked_func': this.a.snapshot.params['fact_type'],
      'data': this.formGroup.value,
    }).then(x => {
        this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
        this.dataReload();
      }, error => { });
  }
  commentDelete(id) {
    NotificationService.swalConfirm('Удалить?', 'Данные будут удалены безвозвратно, продолжить?')
      .then(_ => {
        this.j.post('Report.delete_comment', {
          'comment_id': parseInt(id, 10),
        }).then(x => {
          this.rightWindow = false;
          this.notify.notifySuccess('Поздравляем', 'Комментарий успешно удален', 3000);
          this.commReload(this.data['groups'], id);
          this.dataReload();
          if (this.org['groups']) {
            this.commReload(this.org['groups'], id);
          }
        }, error => { });
      }).catch(() => false);
  }
  dataReload() {
    this.rightWindow = false;
    const options = {
      'date_start': this.a.snapshot.params['start'] || moment()
        .startOf('year').format('DD.MM.YYYY'),
      'date_end': this.a.snapshot.params['stop'] || moment()
        .endOf('month').format('DD.MM.YYYY'),
      'fact_type': this.a.snapshot.params['fact_type'] || '',
      'result_type': this.a.snapshot.params['result_type'] || '',
      'sub_date_from': this.a.snapshot.params['sub_date_from'] || '',
      'only_subagent_id': null,
    };
    if (this.url_segment === 'report-get-sub-user-activity') {
      options['subagent_id'] = this.a.snapshot.params['subagent_id'] || '';
    }

    this.j.post('Analytics.get_sub_activity', options).then(res => {
      this.data = res;
    });
    if (this.url_segment === 'report-get-sub-user-activity') {
      options['subagent_id'] = null;
      options['only_subagent_id'] = this.a.snapshot.params['subagent_id'] || null;
      this.j.post('Analytics.get_sub_activity', options).then(res => {
        this.org = res;
      });
    }
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }
  commReload(data, id) {
    for (const [a, sources] of Object.entries(data)) {
      for (const [b, el] of Object.entries(sources['sources'])) {
        for (const [c, comms] of Object.entries(el['data_comments'])) {
          for (const [d, comm] of Object.entries(comms)) {
            if (comm.id === id) {
              data[a]['sources'][b]['data_comments'][c].splice(d, 1);
            }
          }
        }
      }
    }
  }
  summStatistic() {
    this.summStatisticList = {
      allComplex: 0,
      total_data: 0,
      total_event_data: 0,
      total_data_by_events: 0,
      sorted: [],
    };
    for (const obj of this.data.groups) {
      if (obj.sources.length > 0) {
        this.summStatisticList['sorted'].push({
          caption : obj.caption,
          allComplex: obj.sources.length,
          total_data: this.summStatisticHelper(obj.sources, 'total_data'),
          total_event_data: this.summStatisticHelper(obj.sources, 'total_event_data'),
          total_data_by_events: this.summStatisticHelper(obj.sources, 'total_data_by_events'),
        });
        this.summStatisticList['allComplex'] ++;
      }
    }
    this.summStatisticList['allComplex'] = this.summStatisticHelper(this.summStatisticList['sorted'], 'allComplex');
    this.summStatisticList['total_data'] = this.summStatisticHelper(this.summStatisticList['sorted'], 'total_data');
    this.summStatisticList['total_event_data'] = this.summStatisticHelper(this.summStatisticList['sorted'], 'total_data');
    this.summStatisticList['total_data_by_events'] = this.summStatisticHelper(this.summStatisticList['sorted'], 'total_data');
  }
  summStatisticHelper(obj, field) {
    return obj.reduce((a, b) => {
      return a + b[field];
    }, 0);
  }
  ngAfterViewInit() {
    this.goBack.goScroll();
  }
}
