import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '../../../services/http.service';
import {BreadcrumbsService} from '../../../shared/breadcrumbs/services';

@Component({
  selector: 'app,yamap-entitie-edit-additionally',
  templateUrl: './entity-edit-additionally.component.html',
  styleUrls: ['./entity-edit-additionally.component.scss']
})
export class EntityEditAdditionallyComponent implements OnInit {

  public data: any = null;
  public postData: any = null;
  public nalogForms: Array<any> = [];
  public formGroup: UntypedFormGroup;
  public entityId = null;

  constructor(public activeRoute: ActivatedRoute, public fb: UntypedFormBuilder, public http: HttpClient,
              public notify: NotificationService, private b: BreadcrumbsService) {
  }

  ngOnInit() {
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/entity/list', caption: 'Юридические лица'},
    ]);
    this.data = this.activeRoute.snapshot.data['data'];
    this.nalogForms = this.activeRoute.snapshot.data['nalogForms'];
    this.entityId = this.activeRoute.snapshot.parent.params['id'];

    this.formGroup = this.fb.group({});
  }

  saveForm() {
    if (!this.formGroup.valid) {
      this.onError('Заполните обязательные поля');
      return false;
    }

    this.http.post('Organisation.save_organisation_additionally',
      {'organisation_id': +this.entityId, 'data': this.postData}
    ).subscribe(
      (response) => this.onSuccess(response),
      (error) => this.onError(error)
    );
  }

  onSuccess(response) {
    this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
  }

  onError(error) { }

}
